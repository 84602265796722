export const ColorsValue = {
    "greyscale-0": "#ffffff",
    "greyscale-50": "#f3f6f9",
    "greyscale-100": "#e5eaef",
    "greyscale-200": "#c3cbd3",
    "greyscale-300": "#a6b0ba",
    "greyscale-400": "#7e8c9a",
    "greyscale-500": "#5d6d7c",
    "greyscale-600": "#415161",
    "greyscale-700": "#364452",
    "greyscale-800": "#2a343e",
    "greyscale-900": "#1d2732",
    "greyscale-1000": "#121a22",
    "red-brand-50": "#fae8eb",
    "red-brand-100": "#f0b8c2",
    "red-brand-200": "#e896a5",
    "red-brand-300": "#de667c",
    "red-brand-400": "#d84962",
    "red-brand-500": "#ce1b3b",
    "red-brand-600": "#bb1936",
    "red-brand-700": "#92132a",
    "red-brand-800": "#710f20",
    "red-brand-900": "#570b19",
    "blue-accent-50": "#e6f1fa",
    "blue-accent-100": "#b0d5f0",
    "blue-accent-200": "#8ac0e8",
    "blue-accent-300": "#54a4de",
    "blue-accent-400": "#3392d7",
    "blue-accent-500": "#0077cd",
    "blue-accent-600": "#006cbb",
    "blue-accent-700": "#005492",
    "blue-accent-800": "#004171",
    "blue-accent-900": "#003256",
    "green-success-50": "#e7f3ed",
    "green-success-100": "#b6d9c6",
    "green-success-200": "#92c7ab",
    "green-success-300": "#60ad84",
    "green-success-400": "#419d6d",
    "green-success-500": "#128548",
    "green-success-600": "#107942",
    "green-success-700": "#0d5e33",
    "green-success-800": "#0a4928",
    "green-success-900": "#08381e",
    "red-error-50": "#fbebec",
    "red-error-100": "#f2c2c3",
    "red-error-200": "#eba4a6",
    "red-error-300": "#e27b7d",
    "red-error-400": "#dd6164",
    "red-error-500": "#d43a3d",
    "red-error-600": "#c13538",
    "red-error-700": "#97292b",
    "red-error-800": "#752022",
    "red-error-900": "#59181a",
    "yellow-warning-50": "#fff8e8",
    "yellow-warning-100": "#feebb8",
    "yellow-warning-200": "#fee196",
    "yellow-warning-300": "#fdd366",
    "yellow-warning-400": "#fdca49",
    "yellow-warning-500": "#fcbd1b",
    "yellow-warning-600": "#e5ac19",
    "yellow-warning-700": "#b38613",
    "yellow-warning-800": "#8b680f",
    "yellow-warning-900": "#6a4f0b",
    "teal-50": "#eafdfa",
    "teal-100": "#befaf0",
    "teal-200": "#9ff7e8",
    "teal-300": "#73f4de",
    "teal-400": "#58f1d8",
    "teal-500": "#2eeece",
    "teal-600": "#2ad9bb",
    "teal-700": "#21a992",
    "teal-800": "#198371",
    "teal-900": "#136457",
    "blue-50": "#e6f8fe",
    "blue-100": "#b0e8fc",
    "blue-200": "#8addfa",
    "blue-300": "#54cdf8",
    "blue-400": "#33c4f6",
    "blue-500": "#00b5f4",
    "blue-600": "#00a5de",
    "blue-700": "#0081ad",
    "blue-800": "#006486",
    "blue-900": "#004c66",
    "pink-50": "#ffedf8",
    "pink-100": "#fec7e9",
    "pink-200": "#feacde",
    "pink-300": "#fd86cf",
    "pink-400": "#fd6fc6",
    "pink-500": "#fc4bb8",
    "pink-600": "#e544a7",
    "pink-700": "#b33583",
    "pink-800": "#8b2965",
    "pink-900": "#6a204d",
    "orange-50": "#fff5ea",
    "orange-100": "#ffe0be",
    "orange-200": "#ffd19f",
    "orange-300": "#ffbc73",
    "orange-400": "#ffaf58",
    "orange-500": "#ff9b2e",
    "orange-600": "#e88d2a",
    "orange-700": "#b56e21",
    "orange-800": "#8c5519",
    "orange-900": "#6b4113",
    "purple-50": "#f3eafb",
    "purple-100": "#dabff1",
    "purple-200": "#c8a0ea",
    "purple-300": "#af75e1",
    "purple-400": "#9f5adb",
    "purple-500": "#8731d2",
    "purple-600": "#7b2dbf",
    "purple-700": "#602395",
    "purple-800": "#4a1b74",
    "purple-900": "#391558",
    "palevioletred-50": "#faeef1",
    "palevioletred-100": "#f1c9d5",
    "palevioletred-200": "#eaafc0",
    "palevioletred-300": "#e08aa3",
    "palevioletred-400": "#da7391",
    "palevioletred-500": "#d15076",
    "palevioletred-600": "#be496b",
    "palevioletred-700": "#943954",
    "palevioletred-800": "#732c41",
    "palevioletred-900": "#582232",
    "darkturquoise-50": "#ebfafb",
    "darkturquoise-100": "#c0f0f3",
    "darkturquoise-200": "#a1e8ed",
    "darkturquoise-300": "#76dee5",
    "darkturquoise-400": "#5cd8e0",
    "darkturquoise-500": "#33ced8",
    "darkturquoise-600": "#2ebbc5",
    "darkturquoise-700": "#249299",
    "darkturquoise-800": "#1c7177",
    "darkturquoise-900": "#15575b",
    "mediumspringgreen-50": "#f1faf1",
    "mediumspringgreen-100": "#d5f1d2",
    "mediumspringgreen-200": "#c0eabd",
    "mediumspringgreen-300": "#a4e09f",
    "mediumspringgreen-400": "#92da8c",
    "mediumspringgreen-500": "#77d16f",
    "mediumspringgreen-600": "#6cbe65",
    "mediumspringgreen-700": "#54944f",
    "mediumspringgreen-800": "#41733d",
    "mediumspringgreen-900": "#32582f",
    "khaki-50": "#fcfaef",
    "khaki-100": "#f4eecc",
    "khaki-200": "#efe6b4",
    "khaki-300": "#e8da92",
    "khaki-400": "#e4d37d",
    "khaki-500": "#ddc85c",
    "khaki-600": "#c9b654",
    "khaki-700": "#9d8e41",
    "khaki-800": "#7a6e33",
    "khaki-900": "#5d5427",
};
