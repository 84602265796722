import React, { PureComponent } from "react";
import { Card, CardBody } from "reactstrap";

class CallLogs extends PureComponent {
  render() {
    return (
      <>
        <Card>
          <CardBody className="fts-listing-tab-wrapper">Call logs</CardBody>
        </Card>
      </>
    );
  }
}

export default CallLogs;
