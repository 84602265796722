import { call, put, takeEvery, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import FTSAxios from "../../../axios/fts.instance";
import {
  GET_LISTING_DATA_BY_ID_REQUEST,
  GET_LOCKED_LISTINGS_REQUEST,
  LOCK_LISTING_REQUEST,
  selectListingData,
  UNLOCK_LISTING_REQUEST,
  UPDATE_LISTING_DATA_BY_ID_REQUEST,
  UPDATE_LISTING_PUT_ON_HOLD_BY_ID_REQUEST,
} from "../../reducers/v2/listing";
import {
  getListingDataByIdFailed,
  getListingDataByIdSuccess,
  getLockedListingsFailed,
  getLockedListingsRequest,
  getLockedListingsSuccess,
  lockListingRequest,
  updateListingPutOnHoldByIdFailed,
  updateListingPutOnHoldByIdSuccess,
} from "../../actions/v2/listings";

import { selectRates } from "../../reducers/essentialLists";
import moment from "moment";
import { selectLoggedUser } from "../../reducers/auth/loginReducer";
import socketHandler from "../../../utility/socket";
import { handleError, handleResponseError } from "../../../handlers/error";
import {
  formatWorkingHoursFrom,
  formatWorkingHoursTo,
} from "../../../utility/formatWorkingHours";

// all sagas need to have Sata in suffix eg getListingDataByIdRequestSaga
function* getListingDataByIdRequestSaga(action) {
  try {
    const { data: listingData } = yield call(
      FTSAxios.get,
      `admin/listing/${action.payload}`,
    );

    yield put(getLockedListingsRequest());

    const listingId = listingData.listing.id;

    const { data: lockedListingsData } = yield call(
      FTSAxios.get,
      "admin/listings/locked",
    );
    yield put(getLockedListingsSuccess({ data: lockedListingsData }));
    const loggedUserData = yield select(selectLoggedUser);

    const { listings } = lockedListingsData;
    const { id } = loggedUserData;

    let lockedListing = listings[listingId];
    let isLockedWithAnotherAdmin = lockedListing && lockedListing.userId !== id;

    if (isLockedWithAnotherAdmin) {
      listingData.listing.lockedListingMessage = `${lockedListing.firstName} ${lockedListing.lastName} is currently editing this listing.`;
    }

    let adsRates = yield select(selectRates);

    if (!listingData.listing.adsRates.length) {
      listingData.listing.adsRates = adsRates
        .sort((a, b) => b.id - a.id)
        .map((e) => ({
          rate: e,
          rateId: e.id,
          value: "",
        }));
    }
    if (listingData.listing.workingHours) {
      const weekDays = moment.weekdays();
      for (let [key, val] of Object.entries(listingData.listing.workingHours)) {
        if (val === " - ") {
          listingData.listing.skipHours = true;
          // continue;
        }
        if (weekDays.find((e) => e.toLowerCase() === key.toLowerCase())) {
          listingData.listing.workingHours[`${key.slice(0, 3)}From`] = {
            label: val.split(" - ")[0],
            value: val.split(" - ")[0],
          };
          listingData.listing.workingHours[`${key.slice(0, 3)}To`] = {
            label: val.split(" - ")[1],
            value: val.split(" - ")[1],
          };
        }
      }
    }

    if (!listingData.listing.workingHours && listingData.listing.alwaysOpen) {
      listingData.listing.workingHours = {};
      const weekDays = moment.weekdays();
      weekDays.map((weekDay) => {
        listingData.listing.workingHours[
          `${weekDay.toLowerCase().slice(0, 3)}From`
        ] = {
          label: "",
          value: "",
        };
        listingData.listing.workingHours[
          `${weekDay.toLowerCase().slice(0, 3)}To`
        ] = {
          label: "",
          value: "",
        };
      });
    }

    yield put(getListingDataByIdSuccess({ data: listingData.listing }));

    if (!lockedListing) {
      yield put(lockListingRequest({ data: listingId }));
    }
  } catch (e) {
    console.log(e);
    const message = e.response
      ? e.response.data.message
      : "Error while getting listing";
    yield put(getListingDataByIdFailed(message));
    toast.error(message);
  }
}

const formatListingDataForUpdate = (listingData) => {
  const weekDays = moment.weekdays();

  let formatted = { ...listingData };
  formatted.adsSubCategories = listingData.adsSubCategories.map((e) => e.id);
  formatted.adsAmenities = listingData.adsAmenities.map((e) => e.id);
  formatted.adsServiceAmenities = listingData.adsServiceAmenities.map(
    (e) => e.id,
  );
  formatted.adsCategories = listingData.adsCategories.map((e) => e.id);
  const hoursOfOperation = {
    alwaysOpen: listingData.alwaysOpen,
    specialHours: {
      towingRecovery: !!listingData.workingHours.towingRecoveryOpen,
      mobileRoad: !!listingData.workingHours.mobileRoadServiceOpen,
    },
    weekDays: weekDays.map((day) => ({
      day,
      // "08:00 PM",
      from: formatWorkingHoursFrom(listingData, day.slice(0, 3).toLowerCase()),
      // "08:00 PM",
      to: formatWorkingHoursTo(listingData, day.slice(0, 3).toLowerCase()),
    })),
  };

  formatted.hoursOfOperation = hoursOfOperation;
  return formatted;
};

function* updateListingDataByIdRequestSaga(action) {
  try {
    const listingDataForUpdate = action.payload.data;
    const payload = formatListingDataForUpdate(listingDataForUpdate);

    const { data: updatedListingData } = yield call(
      FTSAxios.put,
      `admin/listings/${listingDataForUpdate.id}`,
      payload,
    );

    if (
      action.payload.onSuccess &&
      typeof action.payload.onSuccess === "function"
    ) {
      action.payload.onSuccess(updatedListingData);
    }

    if (listingDataForUpdate.doNotChangeUpdateDate)
      return toast.success(
        "Listings updated successfully - updated at not changed",
      );

    return toast.success(
      `Listings updated successfully - updated at set to ${moment().format(
        "ll",
      )}`,
    );
  } catch (e) {
    console.log(e);

    if (
      action.payload.onError &&
      typeof action.payload.onError === "function"
    ) {
      action.payload.onError(e);
    }
    const message = e.response
      ? e.response.data.message
      : "Error while getting listing";
    yield put(getListingDataByIdFailed(message));
    toast.error(message);
  }
}

function* updateListingPutOnHoldByIdRequestSaga(action) {
  try {
    const putOnHoldPayload = action.payload.data;
    const listingData = yield select(selectListingData);
    let onHoldCount = listingData.onHoldCount || 0;
    onHoldCount++;

    putOnHoldPayload.onHoldCount = onHoldCount;
    putOnHoldPayload.id = listingData.id;
    putOnHoldPayload.updatedAt = listingData.updatedAt;

    const { data: updatedListingData } = yield call(
      FTSAxios.post,
      "admin/updates/on-hold",
      putOnHoldPayload,
    );
    yield put(updateListingPutOnHoldByIdSuccess());
    if (
      action.payload.onSuccess &&
      typeof action.payload.onSuccess === "function"
    ) {
      action.payload.onSuccess(updatedListingData);
    }

    return toast.success(
      `Listings updated successfully - put on hold set to ${moment()
        .add(putOnHoldPayload.onHold, "days")
        .format("ll")}`,
    );
  } catch (e) {
    handleError(e);
    const message = handleResponseError(e, true);
    if (
      action.payload.onError &&
      typeof action.payload.onError === "function"
    ) {
      action.payload.onError(e);
    }

    yield put(updateListingPutOnHoldByIdFailed(message));
  }
}

function* getLockedListingsSaga() {
  try {
    const { data: lockedListingsData } = yield call(
      FTSAxios.get,
      "admin/listings/locked",
    );
    yield put(getLockedListingsSuccess({ data: lockedListingsData }));
  } catch (e) {
    handleError(e);
    yield put(getLockedListingsFailed());
    toast.error("Cannot get locked listings");
  }
}

function* lockListingSaga() {
  try {
    console.log("LOCK_LISTING_REQUEST: ");
    const loggedUserData = yield select(selectLoggedUser);
    const listingData = yield select(selectListingData);
    const { id, firstName, lastName } = loggedUserData;
    const { id: listingId } = listingData;

    socketHandler.handleSocket("subscribeToLockListing", {
      listingId: listingId,
      userId: id,
      firstName,
      lastName,
    });
  } catch (e) {
    handleError(e);
  }
}

function* unLockListingSaga() {
  try {
    const loggedUserData = yield select(selectLoggedUser);
    const listingData = yield select(selectListingData);
    const { id, firstName, lastName } = loggedUserData;
    const { id: listingId } = listingData;
    socketHandler.handleSocket("unSubscribeToLockListing", {
      listingId: listingId,
      userId: id,
      firstName,
      lastName,
    });
  } catch (e) {
    handleError(e);
  }
}

export default function* listingWatcher() {
  yield takeEvery(
    GET_LISTING_DATA_BY_ID_REQUEST,
    getListingDataByIdRequestSaga,
  );
  yield takeEvery(
    UPDATE_LISTING_DATA_BY_ID_REQUEST,
    updateListingDataByIdRequestSaga,
  );
  yield takeEvery(
    UPDATE_LISTING_PUT_ON_HOLD_BY_ID_REQUEST,
    updateListingPutOnHoldByIdRequestSaga,
  );
  yield takeEvery(GET_LOCKED_LISTINGS_REQUEST, getLockedListingsSaga);
  yield takeEvery(LOCK_LISTING_REQUEST, lockListingSaga);
  yield takeEvery(UNLOCK_LISTING_REQUEST, unLockListingSaga);
}
