import React, { Component } from "react";

import { connect } from "react-redux";
import { Button, Spinner } from "reactstrap";
import FTSModal from "../fts-modal";
import CloseButton from "../CloseButton";
import Icon from "../Icons";
import { updateSelectedListings } from "../../../redux/actions/ads";
import SearchInput from "../search-input";

import "../../../assets/scss/components/fts/generate-report-confirmation-modal.scss";
import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";
import { Check } from "react-feather";

class GenerateReportConfirmation extends Component {
  closeModal = () => {
    this.props.clearReportName && this.props.clearReportName();
    this.props.toggleGenerateReportConfirmationModal();
  };

  confirmModal = () => {
    this.props.confirmModal();
  };

  handleCloseButton = () => {
    if (this.props.inProgress) return;
    this.closeModal();
  };

  render() {
    const {
      isOpen,
      title,
      closeModal,
      onClose,
      reportName,
      handleReportName,
      inProgress,
    } = this.props;

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-reassign-modal"}
        centered={true}
        style={{ maxWidth: "665px" }}
        footerCTA={() => {
          return (
            <div className="update-listing-confrimation-modal-cta">
              <div className="d-flex align-items-center">
                <Checkbox
                  color="#626262"
                  icon={<Check className="vx-icon" size={16} />}
                  checked={this.props.includePaymentLink}
                  onChange={this.props.toggleIncludePaymentLink}
                />
                <p className={"ml-1 m-0"}>Include payment link</p>
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.confirmModal();
                  }}
                  color="primary"
                  disabled={reportName === "" || inProgress}
                >
                  Done
                </Button>
              </div>
            </div>
          );
        }}
      >
        <div className="reassign-modal-body">
          <span className="reassign-modal-body-wrapper">
            <span className="reassign-modal-title">
              <div className="d-flex update-listing-confirmation-title">
                <Icon name="ListingUpdate" size={56} />
                <div>
                  <span className="d-flex align-center">
                    <h3>Report name</h3>

                    {inProgress && (
                      <div className="confirmation-spinner-wrapper">
                        <Spinner size="sm" />
                      </div>
                    )}
                  </span>

                  <span>This name will appear on the actual report.</span>
                </div>
              </div>

              <CloseButton
                onClick={() => {
                  this.handleCloseButton();
                }}
              />
            </span>
          </span>
        </div>

        <div className="generate-report-confirmation-input">
          <SearchInput
            value={reportName}
            onInputUpdate={handleReportName}
            type="text"
            name="reportName"
            placeholder="Report name"
          />
        </div>
      </FTSModal>
    );
  }
}

export default connect(null, {
  updateSelectedListings,
})(GenerateReportConfirmation);
