import * as firebase from "firebase/app";
import { history } from "../../../history";
import "firebase/auth";
import "firebase/database";
import FTSAxios, { storeCookies } from "../../../axios/fts.instance";
import FTSCookies, { AUTH_KEY, REFRESH_KEY } from "../../../cookies";
import { config } from "../../../authServices/firebase/firebaseConfig";

// Init firebase if not already initialized
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

let firebaseAuth = firebase.auth();

// const initAuth0 = new auth0.WebAuth(configAuth)

export const refreshAuthToken = () => {
  return () => {
    const refreshToken = FTSCookies.get(REFRESH_KEY);
    return FTSAxios.post("refresh_token", { refreshToken }).then((response) => {
      const { data, status } = response;
      if (status === 201) {
        storeCookies(data);
      }
      return response;
    });
  };
};

export const submitLoginWithFireBase = (email, password, remember) => {
  return (dispatch) => {
    let userEmail = null,
      loggedIn = false;
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        firebaseAuth.onAuthStateChanged((user) => {
          result.user.updateProfile({
            displayName: "Admin",
          });
          let name = result.user.displayName;
          if (user) {
            userEmail = user.email;
            loggedIn = true;
            dispatch({
              type: "LOGIN_WITH_EMAIL",
              payload: {
                email: userEmail,
                name,
                isSignedIn: loggedIn,
                loggedInWith: "firebase",
              },
            });
          }
          if (user && remember) {
            firebase
              .auth()
              .setPersistence(firebase.auth.Auth.Persistence.SESSION)
              .then(() => {
                dispatch({
                  type: "LOGIN_WITH_EMAIL",
                  payload: {
                    email: userEmail,
                    name,
                    isSignedIn: loggedIn,
                    remember: true,
                    loggedInWith: "firebase",
                  },
                });
              });
          }
          history.push("/");
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const loginWithJWT = (user) => {
  return () =>
    FTSAxios.post("admin/login", {
      email: user.email,
      password: user.password,
    });
};
export const verifyMfaCode = (payload) => {
  return () => FTSAxios.post("admin/verify-mfa", payload);
};

export const logoutWithJWT = (tokenInvalid) => {
  const refreshToken = FTSCookies.get(REFRESH_KEY);
  if (!tokenInvalid) {
    FTSAxios.post("admin/logout", { refreshToken }).finally(() => {
      FTSCookies.remove(AUTH_KEY, { path: "/" });
      FTSCookies.remove(REFRESH_KEY, { path: "/" });
    });
  } else {
    FTSCookies.remove(AUTH_KEY, { path: "/" });
    FTSCookies.remove(REFRESH_KEY, { path: "/" });
  }
  return (dispatch) => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: null });
    dispatch({ type: "reminders/REMOVE_REMINDERS" });
    history.push("/login");
  };
};

export const logoutWithFirebase = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} });
    history.push("/login");
  };
};

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: "CHANGE_ROLE", userRole: role });
};
