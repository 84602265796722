/* eslint-disable indent */
import React from "react";
import classNames from "classnames";
import { Check } from "react-feather";
import { ErrorMessage } from "formik";
import Select from "react-dropdown-select";
import Checkbox from "../../../components/@vuexy/checkbox/CheckboxesVuexy";

import "../../../assets/scss/components/fts/account-emails-picker.scss";

class AccountEmailsPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      multi: true,
      loading: false,
      contentRenderer: true,
      dropdownRenderer: true,
      inputRenderer: false,
      itemRenderer: true,
      optionRenderer: false,
      noDataRenderer: false,
      selectValues: [],
      searchBy: "email",
      clearable: false,
      searchable: true,
      handle: true,
      labelField: "email",
      valueField: "id",
      color: "blue",
      keepSelectedInList: true,
      dropdownPosition: "bottom",
      direction: "ltr",
      dropdownHeight: "500px",
    };
  }

  selectRef = React.createRef();

  handleRemove = (id) => {
    let filteredValues = this.state.selectValues.filter((item) => {
      return item.id !== id;
    });
    this.setState({
      selectValues: filteredValues,
    });
  };

  setValues = (selectValues) => {
    this.setState({ selectValues });
  };

  noDataRenderer = () => {
    return (
      <p style={{ textAlign: "center" }}>
        <strong>Ooops!</strong> No data found
      </p>
    );
  };

  itemRenderer = ({ item, props, methods }) => (
    <div key={item[props.valueField]} onClick={() => methods.addItem(item)}>
      <div style={{ margin: "10px" }}>
        <input type="checkbox" checked={methods.isSelected(item)} />
        &nbsp;&nbsp;&nbsp;{item["email"]}
      </div>
    </div>
  );

  contentRenderer = ({ methods }) => (
    <div
      className="content"
      onClick={() => {
        this.selectRef.current.dropDown();
      }}
    >
      <div
        className={`placeholder ${
          this.state.selectValues.length !== 0 ? "isFocused" : ""
        }`}
      >
        {!this.props.isLoading ? "Select" : "Loading..."}
      </div>

      {this.state.selectValues.length !== 0 ? (
        <div
          className={classNames(
            "react-dropdown-select-content react-dropdown-select-type-multi css-v1jrxw-ContentComponent"
          )}
        >
          {this.state.selectValues.map((item, indx) => {
            return (
              <span className="option" key={indx}>
                <span className="option-label">{item.email}</span>
                <span
                  className="option-remove"
                  onClick={(event) => {
                    methods.removeItem(event, item, true);
                  }}
                >
                  ×
                </span>
              </span>
            );
          })}
        </div>
      ) : null}
    </div>
  );

  dropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search, "i");
    return (
      <div className="items">
        {props.options
          ?.filter((item) => regexp.test(item[props.searchBy] || item["email"]))
          .map((option) => {
            if (!this.state.keepSelectedInList && methods.isSelected(option)) {
              return null;
            }

            return (
              <div
                className="item"
                disabled={option.disabled}
                key={option["id"]}
                onClick={option.disabled ? null : () => methods.addItem(option)}
              >
                <Checkbox
                  className="account-emails-checkbox"
                  color="#647074"
                  icon={<Check className="vx-icon" size={16} />}
                  onChange={() => methods.addItem(option)}
                  checked={this.state.selectValues.indexOf(option) !== -1}
                />
                <div className="item-label">{option["email"]}</div>
              </div>
            );
          })}
      </div>
    );
  };

  optionRenderer = ({ option, methods }) => {
    return (
      <React.Fragment>
        <div onClick={(event) => methods.removeItem(event, option, true)}>
          {option.email}
        </div>
      </React.Fragment>
    );
  };

  componentDidUpdate() {}

  onOptionSelected = (values) => {
    this.props.onChange(values);
    this.setValues(values);
  };

  render() {
    return (
      <div className="account-emails-wrapper">
        <Select
          ref={this.selectRef}
          className="select"
          readOnly={true}
          searchBy={this.state.searchBy}
          separator={this.state.separator}
          clearable={this.state.clearable}
          searchable={this.state.searchable}
          dropdownHandle={this.state.handle}
          direction={this.state.direction}
          multi={this.state.multi}
          values={this.state.selectValues}
          labelField={this.state.labelField}
          valueField={this.state.valueField}
          options={this.props.list}
          dropdownGap={5}
          keepSelectedInList={this.state.keepSelectedInList}
          closeOnScroll={true}
          onDropdownOpen={() => undefined}
          onDropdownClose={() => undefined}
          onClearAll={() => undefined}
          onSelectAll={() => undefined}
          onChange={this.onOptionSelected}
          noDataLabel="No matches found"
          closeOnSelect={this.state.closeOnSelect}
          dropdownHeight={this.state.dropdownHeight}
          disabled={this.props.isLoading}
          noDataRenderer={
            this.state.noDataRenderer ? () => this.noDataRenderer() : undefined
          }
          dropdownPosition={this.state.dropdownPosition}
          itemRenderer={
            this.state.itemRenderer
              ? (item, itemIndex, props, state, methods) =>
                  this.itemRenderer(item, itemIndex, props, state, methods)
              : undefined
          }
          inputRenderer={
            this.state.inputRenderer
              ? (props, state, methods) =>
                  this.inputRenderer(props, state, methods)
              : undefined
          }
          optionRenderer={
            this.state.optionRenderer
              ? (option, props, state, methods) =>
                  this.optionRenderer(option, props, state, methods)
              : undefined
          }
          contentRenderer={
            this.state.contentRenderer
              ? (innerProps, innerState) =>
                  this.contentRenderer(innerProps, innerState)
              : undefined
          }
          dropdownRenderer={
            this.state.dropdownRenderer
              ? (innerProps, innerState, innerMethods) =>
                  this.dropdownRenderer(innerProps, innerState, innerMethods)
              : undefined
          }
        />
        <ErrorMessage
          name="users"
          render={(msg) => <div className="formik-validation-error">{msg}</div>}
        />
      </div>
    );
  }
}

export default AccountEmailsPicker;
