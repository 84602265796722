import React from "react";
import classnames from "classnames";
import FTSBadge from "../../fts/fts-badge";

class Avatar extends React.PureComponent {
  render() {
    const {
      color,
      size,
      badgeColor,
      badgeText,
      img,
      imgHeight,
      badgeUp,
      imgWidth,
      status,
      content,
      className,
      icon,
      fullName = "",
    } = this.props;
    return (
      <div
        title={fullName}
        className={classnames(
          `avatar no-pointer ${color ? `bg-${color}` : null}  ${
            className || ""
          }`,
          {
            "avatar-sm": size && size === "sm",
            "avatar-lg": size && size === "lg",
            "avatar-xl": size && size === "xl",
          }
        )}
        onClick={this.showAvatarProfile}
      >
        {img === false || img === undefined ? (
          <span
            style={{ cursor: "default" }}
            className={classnames("avatar-content", {
              "position-relative": badgeUp,
            })}
          >
            {content ? content : null}

            {icon ? <div className="avatar-icon">{icon}</div> : null}
            {badgeUp ? (
              <FTSBadge
                color={badgeColor ? badgeColor : "primary"}
                className="badge-sm badge-up"
                pill
              >
                {badgeText ? badgeText : "0"}
              </FTSBadge>
            ) : null}
          </span>
        ) : (
          <img
            src={img}
            alt="avatarImg"
            height={imgHeight && !size ? imgHeight : 32}
            width={imgWidth && !size ? imgWidth : 32}
          />
        )}
        {status ? <span className={`avatar-status-${status}`}></span> : null}
      </div>
    );
  }
}
export default Avatar;
