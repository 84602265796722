import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import "../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss";
import "../../../assets/scss/components/fts/form-fields.scss";
import "../../../assets/scss/pages/invoices-page.scss";
import "flatpickr/dist/themes/light.css";

import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";

import {
  setInvoicesListTest,
  setSelectedInvoices,
  setAllInvoicesCount,
  resetDiscount,
  setCreditCardData,
  setPaymentsListTest,
} from "../../../redux/actions/invoices";

import { forceCreatePayment } from "../../../redux/actions/payments";
import { setPromoCode } from "../../../redux/actions/promocodes";

import { setAccountSettingsState } from "../../../redux/actions/accounts";

import { defineListingColumns } from "./recurring-payments-coumns";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";

import ConfirmationModal from "../../../components/fts/confirmation-modal";
import { searchListings } from "../../../redux/actions/listings";

let searchFields = [
  "statusForString",
  "owner.assignedAdmins.firstName",
  "owner.assignedAdmins.lastName",
  "invoiceNumber",
  "totalForSort",
  "dueAtString",
  "paidAtString",
  "payments.paidMethod",
  "payments.sourceId",
  "owner.companyName",
  "ownerIdForSort",
  "owner.city",
  "owner.state",
  "listingIdForSort",
  "activities.activityNote",
];
let defaultSort = [{ colId: "payments.updatedAt", sort: "ASC" }];
class RecurringPaymentsList extends Component {
  state = {
    activeTab: "0-30",
    from: 0,
    to: 30,
    firstTabCount: 0,
    secondTabCount: 0,
    thirdTabCount: 0,
    inProgress: false,

    chipLoading: false,
    filter: {
      paymentSubscriptionId: true,
    },

    defaultColDef: {
      sortable: true,
    },
    overlayShown: false,

    searchVal: "",
    popoverTarget: "",
    popoverData: null,
    dateRange: null,
    apisReady: false,
    popoverOpen: false,
    excludeInactive: false,
    currentRow: null,
    isOpen: false,
    sidebar: false,
    action: "",
    actionItem: null,
    updating: false,

    listingsData: null,

    pageSize: 10,
    page: 1,
    pageCount: 0,
    totalRecords: 0,
    sort: defaultSort,
    columnDefs: [],
    totalPaymentsAmount: 0,
    statusFilter: [
      {
        bool: {
          should: [],
        },
      },
    ],
    searchFields: [...searchFields],

    paymentStep: "",
    lastPaymentModalTitle: "",
    isOpenPaymentModal: false,
  };

  pageSizes = [10, 25, 50, 100];
  tableGroupRef = createRef();
  sidebarChild = createRef();
  dateRef = createRef();
  invoicesRef = createRef();
  formikSidebarRef = createRef();
  detailsRef = createRef();

  setDetailsRef = (ref) => {
    this.detailsRef = ref;
  };

  openChangePaymentMethodSidebar = (data) => {
    this.callToAction("change_payment_method", false, data);
  };

  componentDidMount() {
    this.listingColumns = defineListingColumns(
      null,
      this.collectDataForCopy,
      null,
      null,
      this.openChangePaymentMethodSidebar,
    );
    this.setState({ inProgress: true });

    let searchPayload = {
      page: this.state.page,
      resultsPerPage: this.state.pageSize,
      searchString: this.state.searchVal,
      ...this.state.filter,
      //   sort: this.state.sort,
      name: "",
      statusFilter: this.state.statusFilter,
      //   searchFields: this.state.searchFields,
    };

    console.log(searchPayload, "SEARCHPAYLOAD");

    this.props
      .searchListings(searchPayload)
      .then((res) => {
        if (this.gridApi) {
          this.gridApi.hideOverlay();
        }

        this.setState({
          overlayShown: false,
          inProgress: false,
          listingsData: res.data?.result?.hits,
          totalRecords: res.data?.result?.nbHits,
          pageCount: Math.ceil(res?.data?.result?.nbHits / this.state.pageSize),
        });
      })
      .catch((err) => {
        console.log(err, "err");
      });

    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  filterSize = (type, val) => {
    this.setState(
      {
        pageSize: val,
        page: 1,
      },
      () => {
        this.onSubmitQuery();
      },
    );
  };

  updateSearchQuery = (type, val) => {
    clearTimeout(this.debounce);
    let newSearchFields = [...searchFields];
    if (this.props.accountId || this.props.listingId) {
      newSearchFields = newSearchFields.filter(
        (e) => e !== "owner.companyName",
      );
    }
    this.setState(
      {
        searchVal: val,
        page: 1,
        // statusFilter: [],
        searchFields: newSearchFields,
      },
      () => {
        this.debounce = setTimeout(() => this.onSubmitQuery(), 350);
      },
    );
  };

  formatNumber = (number) => {
    return Number(number.toFixed(2))
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // if (localStorage.getItem("invoicesColumnState") !== null) {
    //   this.gridColumnApi.setColumnState(
    //     JSON.parse(localStorage.getItem("invoicesColumnState"))
    //   );
    // }
  };

  resetApi = () => {
    this.gridApi = null;
  };

  onRowSelected = (event) => {
    console.log(event, "EVENT");
  };

  refreshTable = () => {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    this.setState({ overlayShown: true });
    setTimeout(() => {
      this.onSubmitQuery();
    }, 1000);
  };
  onSubmitQuery = () => {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    this.setState({ overlayShown: true });

    let searchPayload = {
      ...this.state.filter,
      page: this.state.page,
      resultsPerPage: this.state.pageSize,
      searchString: this.state.searchVal,
      name: "",
      //   sort: this.state.sort,
      statusFilter: this.state.statusFilter,
      //   searchFields: this.state.searchFields,
    };

    this.props
      .searchListings(searchPayload)
      .then((res) => {
        if (this.gridApi) {
          this.gridApi.hideOverlay();
        }

        this.setState({
          overlayShown: false,
          inProgress: false,
          listingsData: res.data?.result?.hits,
          totalRecords: res.data?.result?.nbHits,
          pageCount: Math.ceil(res?.data?.result?.nbHits / this.state.pageSize),
        });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  saveInvoicesColumns = () => {
    let invoicesColumnState = this.gridColumnApi.getColumnState();
    localStorage.setItem(
      "invoicesColumnState",
      JSON.stringify(invoicesColumnState),
    );
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      this.onSubmitQuery();
    });
  };

  openPaymentSidebar = (data) => {
    this.callToAction("payment_overview", false, data);
  };

  openReceiptSidebar = (data) => {
    this.callToAction("receipt_details", false, data);
  };

  openInvoiceSidebar = (data) => {
    this.callToAction("invoice_details", false, data);
  };

  callToAction = (action, update, item) => {
    if (!action) return;
    this.setState({
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
    });
  };

  handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (
      this.detailsRef?.state?.downloadingInProgress ||
      this.detailsRef?.state?.downloadingRecipientInProgress ||
      this.detailsRef?.state?.templateLoading
    ) {
      return;
    }
    if (this.state.action === "invoice_details") {
      this.unselectAllInvoices();
      this.props.setSelectedInvoices({
        selectedInvoices: [],
      });
    }
    if (bool === false && this.formikSidebarRef?.dirty && !closeWithoutPrompt) {
      return this.setState({ isOpen: true });
    } else {
      this.setState({ sidebar: bool, updating: !!update });
    }
  };

  closeSideOutAction = () => {
    this.setState({ sidebar: false, update: false, isOpen: false });
  };

  setPaymentStep = (paymentStep) => {
    this.setState({
      paymentStep: paymentStep,
    });
  };

  setLastPaymentModalTitle = (title) => {
    this.setState({
      lastPaymentModalTitle: title,
    });
  };

  togglePaymentModal = (check) => {
    this.props.setCreditCardData(null);
    if (!check) {
      this.setState({
        isOpenPaymentModal: !this.state.isOpenPaymentModal,
      });
    } else {
      this.confirmPaymentConfirmationModal();

      if (this.state.paymentStep === "paymentProccessed") {
        this.props.setSelectedInvoices({
          selectedInvoices: [],
        });
        this.unselectAllInvoices();
      }
    }
  };

  confirmPaymentConfirmationModal = () => {
    this.refreshTable();

    this.setState({
      paymentStep: null,
      isOpenPaymentModal: false,
    });
    this.props.setPromoCode(null);
    this.props.resetDiscount();
  };

  setFormikSidebarRef = (ref) => {
    this.formikSidebarRef = ref;
  };

  resetFormikForm = () => {
    this.formikSidebarRef.resetForm();
  };

  onRowClicked = ({ data }) => {
    console.log("data: ", data);
  };

  onSortChanged = (params) => {
    this.setState({ sort: params.api.getSortModel() });
    return this.onSubmitQuery();
  };
  render() {
    const {
      pageSize,
      page,
      pageCount,
      searchVal,
      defaultColDef,
      totalRecords,
      totalPaymentsAmount,
      inProgress,
      sidebar,
      action,
      updating,
      actionItem,
      isOpenPaymentModal,
      listingsData,
    } = this.state;

    const { accountId, listingId } = this.props;

    return (
      <div className="invoices-wrapper invoices-flex-wrapper">
        <Row className="app-user-list">
          <Col
            sm="12"
            style={{
              paddingLeft: 0,
            }}
          >
            <Card>
              {!inProgress ? (
                <CardBody>
                  {!accountId && !listingId ? (
                    <div className="invoices-date-filter">
                      <h2>Recurring payments</h2>
                    </div>
                  ) : (
                    <p className="result-title-payments">Payments</p>
                  )}
                  <FTSDataTable
                    noPagination={this.props.accountId || this.props.listingId}
                    totalAmount={this.formatNumber(totalPaymentsAmount)}
                    isSearch={false}
                    longSearch
                    accountId={accountId}
                    listingId={listingId}
                    getSelectedInvoices={this.getSelectedInvoices}
                    searchPlaceholder="Search listings..."
                    pageSizes={this.pageSizes}
                    totalRecords={totalRecords}
                    rowSelection="multiple"
                    currentPageSize={pageSize}
                    searchValType={"searchVal"}
                    pageSizePropName={"pageSize"}
                    searchVal={searchVal}
                    currentPage={page}
                    pageCount={pageCount}
                    rowData={listingsData}
                    onGridReadyInit={this.onGridReady}
                    saveState={this.saveInvoicesColumns}
                    filterSize={this.filterSize}
                    updateSearchQuery={this.updateSearchQuery}
                    onPageChange={this.onPageChanged}
                    resetApi={this.resetApi}
                    onRowClicked={this.onRowClicked}
                    columnDefs={this.listingColumns}
                    onSortChanged={this.onSortChanged}
                    tableName="invoices"
                    invoicesTab
                    withinCard
                    {...{
                      defaultColDef,
                    }}
                  />
                </CardBody>
              ) : (
                <Spinner size="sm" className="m-1" />
              )}
            </Card>
            <ContextMenu
              style={{ minWidth: 250, zIndex: 1000 }}
              id="contextMenuPayments"
              preventHideOnScroll={false}
            >
              <MenuItem
                onClick={() =>
                  navigator.clipboard.writeText(this.state.collectedDataForCopy)
                }
              >
                <FaRegCopy size={18} className="copy" />
                <span className={"ml-1"}>Copy</span>
              </MenuItem>
            </ContextMenu>

            <ActionSidebar
              setDetailsRef={this.setDetailsRef}
              openReceiptSidebar={this.openReceiptSidebar}
              invoicesRef={this.invoicesRef}
              gridApi={this.gridApi}
              show={sidebar}
              action={action}
              updating={updating}
              actionItem={actionItem}
              handleSidebar={this.handleSidebar}
              promotionType="accountPromotion"
              noMarker={
                action === "payment_overview" ||
                action === "change_payment_method"
              }
              callToAction={this.callToAction}
              setPaymentStep={this.setPaymentStep}
              setLastPaymentModalTitle={this.setLastPaymentModalTitle}
              onSubmitQuery={this.onSubmitQuery}
              accountData={this.props.accountData}
            />

            <ConfirmationModal
              title="Unsaved Changes"
              message="Are you sure you want to proceed with this action?"
              closeModal={() => {
                this.togglePaymentModal();
              }}
              confirmModal={this.confirmPaymentConfirmationModal}
              isOpen={isOpenPaymentModal}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    paymentsData: state.invoices.paymentsData,
    selectedInvoices: state.invoices.selectedInvoices,
    totalAmount: state.invoices.totalAmount,
    promoCode: state.promoCodes.promoCode,
    discountAmount: state.invoices.discountAmount,
  };
};

export default connect(mapStateToProps, {
  searchListings,
  setInvoicesListTest,
  setSelectedInvoices,
  setAccountSettingsState,
  setAllInvoicesCount,
  setPromoCode,
  resetDiscount,
  setCreditCardData,
  forceCreatePayment,
  setPaymentsListTest,
})(RecurringPaymentsList);
