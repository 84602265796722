import React from "react";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "../../../components/@vuexy/breadCrumbs/BreadCrumb";
import ApprovedViewConfig from "./ApprovedViewConfig";
import queryString from "query-string";

class ApprovedView extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Breadcrumbs breadCrumbTitle="Safe IP's" breadCrumbActive="Safe IP's" />
        <Row>
          <Col sm="12">
            <ApprovedViewConfig
              parsedFilter={queryString.parse(this.props.location.search)}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ApprovedView;
