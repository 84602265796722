import FTSAxios from "./fts.instance";
import { capitalizeFirstLetter } from "../utility/googleCalendar";

const collectEssentialData = (dispatch) => {
  const promises = [
    FTSAxios.get("admin/lists/amenities?status=1&status=2"),
    FTSAxios.get("admin/lists/service-amenities?status=1&status=2"),
    FTSAxios.get("admin/users/status-list"),
    FTSAxios.get("admin/users/leads-status-list"),
    FTSAxios.get("admin/lists/categories?status=1&status=2"),
    FTSAxios.get("admin/admin-users/all"),
    FTSAxios.get("admin/lists/subcategories?status=1&status=2"),
    FTSAxios.get("admin/todo/types"),
    FTSAxios.get("profile/states"),
    FTSAxios.get("admin/emails/types"),
    FTSAxios.get("admin/activity/getActivitiesTypes"),
    FTSAxios.get("admin/lists/permissions"),
    FTSAxios.get("rates"),
    FTSAxios.get("admin/lists/listing-statuses"),
    FTSAxios.get("admin/admin-users/all"),
  ];

  return Promise.all(promises).then(
    ([
      amenities,
      serviceAmenities,
      userStatuses,
      leadsStatuses,
      categories,
      employees,
      subCategories,
      types,
      states,
      emailTypes,
      activitiesTypes,
      permissions,
      rates,
      listingStatuses,
      adminUsers,
    ]) => {
      dispatch({
        type: "UPDATE_AMENITIES_LIST",
        data: amenities.data.sort(({ name: nameA }, { name: nameB }) =>
          ("" + nameA).localeCompare(nameB),
        ),
      });
      dispatch({
        type: "UPDATE_SERVICE_AMENITIES_LIST",
        data: serviceAmenities.data.sort(({ name: nameA }, { name: nameB }) =>
          ("" + nameA).localeCompare(nameB),
        ),
      });
      dispatch({
        type: "UPDATE_STATUSES_LIST",
        userStatuses: userStatuses.data,
      });
      dispatch({
        type: "UPDATE_LEADS_STATUSES_LIST",
        leadsStatuses: leadsStatuses.data,
      });
      dispatch({
        type: "UPDATE_STATES_LIST",
        data: states.data.map((st) => ({
          value: st.key,
          label: st.stateName,
        })),
      });
      dispatch({
        type: "UPDATE_CATEGORIES_LIST",
        data: categories.data,
      });
      dispatch({
        type: "UPDATE_SUBCATEGORIES_LIST",
        data: subCategories.data,
      });
      dispatch({
        type: "UPDATE_EMPLOYEES_LIST",
        data: employees.data.map((itm) => ({
          value: itm.id,
          label: `${itm.firstName} ${itm.lastName}`,
          status: itm.status,
        })),
      });
      dispatch({
        type: "UPDATE_TYPES_LIST",
        data: types.data,
      });

      dispatch({
        type: "UPDATE_EMAILS_TYPES",
        data: emailTypes.data.emailTypes,
      });

      dispatch({
        type: "UPDATE_ACTIVITIES_TYPES",
        data: activitiesTypes.data.activitiesTypes,
      });

      dispatch({
        type: "UPDATE_PERMISSIONS",
        data: permissions.data,
      });
      dispatch({
        type: "UPDATE_RATES",
        data: rates.data,
      });
      dispatch({
        type: "GET_LISTING_STATUSES_SUCCESS",
        data: transformListingStatuses(listingStatuses.data.data),
      });
      dispatch({
        type: "UPDATE_ADMIN_USERS",
        data: adminUsers.data.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        })),
      });
    },
  );
};

const transformListingStatuses = (listingStatuses) => {
  let newListingStatuses = [];

  for (let [key, val] of Object.entries(listingStatuses)) {
    let obj = { label: capitalizeFirstLetter(key.toLowerCase()), value: val };
    newListingStatuses.push(obj);
  }
  return newListingStatuses;
};
export default collectEssentialData;
