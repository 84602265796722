import React, { Component } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import classnames from "classnames";
import Spinner from "../../../components/@vuexy/spinner/Loading-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactPaginate from "react-paginate";
import { history } from "../../../history";
import {
  Edit,
  Trash,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
} from "react-feather";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  filterAmenities,
  getAmenitiesData,
  updateAmenity,
  deleteAmenity,
  createAmenity,
} from "../../../redux/actions/amenities";
import Sidebar from "./AmenitiesSidebar";

import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";
import "../../../assets/scss/plugins/extensions/react-paginate.scss";
import "../../../assets/scss/pages/data-list.scss";

const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: "rgba(115,103,240,.05)",
      color: "#7367F0 !important",
      boxShadow: "0 0 1px 0 #7367F0 !important",
      "&:hover": {
        transform: "translateY(0px) !important",
      },
    },
  },
};

const ActionsComponent = (props) => {
  return (
    <div className="data-list-action">
      <Edit
        className="cursor-pointer mr-1"
        size={20}
        onClick={() => {
          return props.currentData(props.row);
        }}
      />
      <Trash
        className="cursor-pointer"
        size={20}
        onClick={() => {
          props.deleteRow(props.row);
        }}
      />
    </div>
  );
};

const CustomHeader = (props) => {
  return (
    <>
      <Row className="justify-content-between">
        <Col xs="6" sm="6" md="6">
          <div className="filter-section">
            <Input type="text" onChange={(e) => props.handleFilter(e)} />
          </div>
        </Col>
        <Col xs="6" sm="6" md="6">
          <Button
            type="button"
            className="p-1 float-right"
            color="primary"
            onClick={() => props.handleSidebar(true, true)}
          >
            + New amenity
          </Button>
        </Col>
      </Row>
    </>
  );
};

class AmenitiesListingConfig extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.amenitiesList.data.length !== state.data.length ||
      state.currentPage !== props.parsedFilter.page
    ) {
      return {
        data: props.amenitiesList.data,
        allData: props.amenitiesList.filteredData,
        totalPages: props.amenitiesList.totalPages,
        currentPage: parseInt(props.parsedFilter.page) - 1,
        rowsPerPage: parseInt(props.parsedFilter.perPage),
        totalRecords: props.amenitiesList.totalRecords,
        sortIndex: props.amenitiesList.sortIndex,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  state = {
    statusOptions: ["Disabled", "Enabled", "Inactive"],
    defaultAlert: false,
    confirmAlert: false,
    cancelAlert: false,
    data: [],
    totalPages: 0,
    currentPage: 0,
    columns: [
      {
        name: "ID",
        selector: "id",
        maxWidth: "40px",
        sortable: true,
        cell: (row) => (
          <p title={row.id} className="text-truncate text-bold-500 mb-0">
            {row.id}
          </p>
        ),
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <p title={row.name} className="text-truncate text-bold-500 mb-0">
            {`${row.name}`}
          </p>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        maxWidth: "400px",
        cell: (row) => (
          <p title={row.status} className="text-truncate text-bold-500 mb-0">
            {this.state.statusOptions[row.status]}
          </p>
        ),
      },
      {
        name: "Actions",
        maxWidth: "100px",
        cell: (row) => (
          <ActionsComponent
            row={row}
            getData={this.props.getSubcategoriesData}
            parsedFilter={this.props.parsedFilter}
            currentData={this.handleCurrentData}
            deleteRow={this.handleDelete}
          />
        ),
      },
    ],
    allData: [],
    value: "",
    rowsPerPage: 20,
    sidebar: false,
    inProgress: false,
    currentData: null,
    selected: [],
    totalRecords: 0,
    sortIndex: [],
    addNew: "",
  };

  thumbView = this.props.thumbView;
  adTypeToDelete = null;

  componentDidMount() {
    this.refreshList();
  }

  componentDidUpdate() {}

  refreshList = () => {
    this.setState({ inProgress: true });
    this.props.getAmenitiesData("?status=0&status=1&status=2").finally(() => {
      this.setState({ inProgress: false });
    });
  };

  handleAlert = (state, value) => {
    this.setState({ [state]: value });
  };

  handleFilter = (e) => {
    this.setState({ value: e.target.value });
    this.props.filterAmenities(e.target.value);
  };

  handleRowsPerPage = (value) => {
    let { parsedFilter, getData } = this.props;
    let page = parsedFilter.page !== undefined ? parsedFilter.page : 1;
    history.push(`/data-list/list-view?page=${page}&perPage=${value}`);
    this.setState({ rowsPerPage: value });
    getData({ page: page, perPage: value });
  };

  handleSidebar = (boolean, addNew = false) => {
    this.setState({ sidebar: boolean });
    if (addNew === true) this.setState({ currentData: null, addNew: true });
  };

  handleDelete = (row) => {
    if (this.state.inProgress) {
      return;
    }
    this.serviceAmenityToDelete = row;
    this.handleAlert("defaultAlert", true);
  };

  deleteAmenity = () => {
    if (this.serviceAmenityToDelete) {
      this.setState({ inProgress: true, value: "" });
      this.props
        .deleteAmenity(this.serviceAmenityToDelete)
        .then(() => {
          this.serviceAmenityToDelete = null;
          this.handleAlert("confirmAlert", true);
          this.refreshList();
        })
        .catch((error) => {
          this.serviceAmenityToDelete = null;
          console.log("====================================");
          console.log("error = ", error);
          console.log("====================================");
        });
    }
  };

  handleCurrentData = (obj) => {
    this.setState({ currentData: obj });
    this.handleSidebar(true);
  };

  handlePagination = (page) => {
    let { parsedFilter, getCategoriesData } = this.props;
    let perPage =
      parsedFilter.perPage !== undefined ? parsedFilter.perPage : 20;
    let urlPrefix = "/categories";
    history.push(`${urlPrefix}?page=${page.selected + 1}&perPage=${perPage}`);
    this.setState({ inProgress: true });
    getCategoriesData("?status=1&status=2").finally(() => {
      this.setState({ inProgress: false, currentPage: page.selected });
    });
  };

  handleServiceAmenityUpdate = (obj) => {
    if (this.state.inProgress) {
      return;
    }

    const payload = {
      ...obj,
      status: obj.status.value,
      // categoryId: obj.categoryId.value,
    };
    this.setState({ inProgress: true });
    if (this.state.currentData) {
      this.props
        .updateAmenity(payload)
        .then(() => {
          toast.success("Service amenity successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.refreshList();
        })
        .catch((error) => {
          console.log("====================================");
          console.log("error = ", error);
          console.log("====================================");
        });
    } else {
      this.props
        .createAmenity(payload)
        .then(() => {
          toast.success("Service amenity successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.refreshList();
        })
        .catch((error) => {
          console.log("====================================");
          console.log("error = ", error);
          console.log("====================================");
        });
    }

    this.handleSidebar(false, true);
  };

  render() {
    let {
      columns,
      data,
      allData,
      totalPages,
      value,
      rowsPerPage,
      currentData,
      sidebar,
      totalRecords,
      sortIndex,
      inProgress,
    } = this.state;

    return inProgress ? (
      <div className="listingSpinner" style={{ marginTop: 150 }}>
        <Spinner />
      </div>
    ) : (
      <div
        className={`data-list ${
          this.props.thumbView ? "thumb-view" : "list-view"
        }`}
      >
        <DataTable
          columns={columns}
          data={value.length ? allData : data}
          pagination
          paginationServer
          paginationComponent={() => (
            <ReactPaginate
              previousLabel={<ChevronLeft size={15} />}
              nextLabel={<ChevronRight size={15} />}
              breakLabel="..."
              breakClassName="break-me"
              pageCount={totalPages}
              containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
              activeClassName="active"
              forcePage={
                this.props.parsedFilter.page
                  ? parseInt(this.props.parsedFilter.page - 1)
                  : 0
              }
              onPageChange={(page) => this.handlePagination(page)}
            />
          )}
          noHeader
          subHeader
          responsive
          pointerOnHover
          customStyles={selectedStyle}
          subHeaderComponent={
            <CustomHeader
              handleSidebar={this.handleSidebar}
              handleFilter={this.handleFilter}
              handleRowsPerPage={this.handleRowsPerPage}
              rowsPerPage={rowsPerPage}
              total={totalRecords}
              index={sortIndex}
            />
          }
          sortIcon={<ChevronDown />}
        />
        <Sidebar
          show={sidebar}
          data={currentData}
          updateData={this.props.updateData}
          addData={this.props.addData}
          handleSidebar={this.handleSidebar}
          onServiceAmenityUpdate={this.handleServiceAmenityUpdate}
          thumbView={this.props.thumbView}
          getData={this.props.getData}
          dataParams={this.props.parsedFilter}
          addNew={this.state.addNew}
        />
        <div
          className={classnames("data-list-overlay", {
            show: sidebar,
          })}
          onClick={() => this.handleSidebar(false, true)}
        />

        <SweetAlert
          title="Are you sure?"
          warning
          show={this.state.defaultAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => {
            this.handleAlert("defaultAlert", false);
            this.deleteAmenity();
          }}
          onCancel={() => {
            this.handleAlert("defaultAlert", false);
            this.handleAlert("cancelAlert", true);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>

        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="success"
          show={this.state.confirmAlert}
          onConfirm={() => {
            this.handleAlert("defaultAlert", false);
            this.handleAlert("confirmAlert", false);
          }}
        >
          <p className="sweet-alert-text">
            Your service amenity has been deleted.
          </p>
        </SweetAlert>

        <SweetAlert
          error
          title="Cancelled"
          confirmBtnBsStyle="success"
          show={this.state.cancelAlert}
          onConfirm={() => {
            this.handleAlert("defaultAlert", false);
            this.handleAlert("cancelAlert", false);
          }}
        >
          <p className="sweet-alert-text">
            Your service amenity data is safe :)
          </p>
        </SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    amenitiesList: state.amenitiesList,
  };
};

export default connect(mapStateToProps, {
  getAmenitiesData,
  updateAmenity,
  createAmenity,
  deleteAmenity,
  filterAmenities,
})(AmenitiesListingConfig);
