/* eslint-disable indent */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, FormGroup, Nav } from "reactstrap";
import { Field } from "formik";

import FormikInput from "../../../components/fts/formik/formik-input";
import EmployeePicker from "../../../components/fts/employee-picker";
import Icon from "../../../components/fts/Icons";
import TitleRow from "../../../components/fts/TitleRow";
import FormikConfirmSwitch from "../../../components/fts/formik-confirm-switch";
import InfoLabels from "../../../components/fts/info-labels";
import AccountStickyNotes from "../../../components/fts/account-sticky-notes";
import FTSMenu from "../../../components/fts/fts-menu";

import "../../../assets/scss/pages/account-listing-shared.scss";
import { checkPermissions } from "../../../utility/permissionsCheck";
import { toast } from "react-toastify";
import {
  USER_TYPES_NAMED,
  USER_TYPE_ICON,
  USER_STATUSES,
  USER_TYPE_COLOR,
  USER_TYPE_NAMES,
  activities,
} from "../../../constants";
import FTSBadge from "../../../components/fts/fts-badge";
import AccessReuqestedBadge from "../../../components/fts/access-requested-badge";
import AccessGrantedBadge from "../../../components/fts/access-granted-badge";
import { accountAccessRequestsStatuses } from "../../../utility/constants";
import { loginWithRemoteAccess } from "../../../redux/actions/accountAccessRequests";
import moment from "moment";

export const getStatusInfo = (status) => {
  let info = { status };

  switch (status) {
    case 1:
      info.label = "Active";
      break;

    case 2:
      info.label = "Inactive";
      break;

    default:
      info.label = "Disabled";
      break;
  }

  return info;
};
class AccountInfo_Header extends PureComponent {
  state = {
    isOpen: false,
  };

  options = this.getOptions();

  getOptions() {
    return [
      {
        id: "requestAccountAccess",
        componentLeft: <Icon name="Lock" />,
        text: "Request Account Access",
        action: () => {
          this.handleMenu();
          this.props.toggleAccountAccessRequestModal();
        },
        hide: this.props.accountAccessRequests,
      },
      {
        id: "disableAccountAccess",
        componentLeft: <Icon name="Lock" />,
        text: "Disable Account Access",
        action: () => {
          this.handleMenu();
          this.props.toggleDisableAccessModal();
        },
        hide:
          !checkPermissions(
            [{ id: 4, subCat: [75] }],
            this.props.adminPermissions,
          ) || !this.props.accountAccessRequests,
      },
      {
        id: "sendMultipleProofs",
        componentLeft: <Icon name="Proof" />,
        text: "Send multiple proofs",
        action: () => {
          // adminPermissions
          const havePermission = checkPermissions(
            [{ id: 3, subCat: [50] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            this.handleMenu();
            this.props.callToAction(
              "send_multiple_listings_proof",
              false,
              null,
            );
            return;
          }
          toast.error("You don't have permission for this action");
        },
      },
      {
        id: "convertToSub",
        componentLeft: <Icon name="Reassign" />,
        text: "Convert to Sub",
        action: () => {
          this.handleMenu();
          this.props.toggleReassignListingModal();
        },
      },
      {
        id: "updateHistory",
        componentLeft: <Icon name="UpdatesMenuItem" />,
        text: "Update history",
        action: () => {
          this.handleMenu();
          this.props.callToAction("account_update_history", false, null);
        },
      },

      {
        id: "requestPasswordChange",
        componentLeft: <Icon name="Email" />,
        text: "Request password change",
        disabled: !checkPermissions(
          [{ id: 4, subCat: [80] }],
          this.props.adminPermissions,
        ),
        action: () => this.props.onSendRequestChangePassword(),
      },
      {
        id: "removeAccount",
        componentLeft: <Icon name="Trash" />,
        text: "Remove account",
        red: true,
        disabled: !checkPermissions(
          [{ id: 4, subCat: [52] }],
          this.props.adminPermissions,
        ),
        action: () => this.props.onRemoveAccount(),
      },
    ];
  }

  handleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  extractStickyNotes = (list) => {
    return list?.filter((itm) => itm.isSticky);
  };

  componentDidUpdate() {
    this.options = this.getOptions();
  }

  componentDidMount() {
    let { adminPermissions } = this.props;

    let havePermissionForFeaturedAuthorizedVendorToggle = checkPermissions(
      [{ id: 4, subCat: [43] }],
      adminPermissions,
    );
    let havePermissionForAuthorizedVendorToggle = checkPermissions(
      [{ id: 4, subCat: [42] }],
      adminPermissions,
    );
    let havePermissionForMerchantToggle = checkPermissions(
      [{ id: 4, subCat: [41] }],
      adminPermissions,
    );
    let havePermissionForAutomaticDowngradesToggle = checkPermissions(
      [{ id: 4, subCat: [40] }],
      adminPermissions,
    );
    let havePermissionForRenewalEmailsToggle = checkPermissions(
      [{ id: 4, subCat: [39] }],
      adminPermissions,
    );

    let havePermissionForCorporateToggle = checkPermissions(
      [{ id: 4, subCat: [37] }],
      adminPermissions,
    );

    let havePermissionForEdit = checkPermissions(
      [{ id: 3, subCat: [25] }],
      adminPermissions,
    );

    let havePermissionForRemoteAccess = checkPermissions(
      [{ id: 4, subCat: [75] }],
      adminPermissions,
    );

    this.setState({
      havePermissionForCorporateToggle,
      havePermissionForFeaturedAuthorizedVendorToggle,
      havePermissionForAuthorizedVendorToggle,
      havePermissionForAutomaticDowngradesToggle,
      havePermissionForRenewalEmailsToggle,
      havePermissionForMerchantToggle,
      havePermissionForEdit,
      havePermissionForRemoteAccess,
    });
  }

  createRemoteAccessLoginActivity = async () => {
    let activityPayload = {
      status: 1,
      userId: this.props.accountData?.id,
      activityType: this.props.activitiesTypes?.REMOTE_ACCESS_LOGIN,
      activity: activities?.accountAccessLogin.replace(
        "{{admin_name}}",
        `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
      ),
      adminUserId: this.props.userData?.loggedInUser?.id,
      iconName: "AccountsActivity",
    };

    await this.props.createActivity(activityPayload);
  };

  handleClientRemoteLogin = () => {
    this.createRemoteAccessLoginActivity();

    this.props
      .loginWithRemoteAccess({
        refreshToken:
          this.props.accountData?.accountAccessRequests?.refreshToken,
      })
      .then(({ data: accessToken }) => {
        this.createRemoteAccessLoginActivity();
        window.open(
          `${process.env.REACT_APP_WEBSITE_URL}/dashboard/remote-access?accessToken=${accessToken}&refreshToken=${this.props.accountData?.accountAccessRequests?.refreshToken}`,
          "_blank",
        );
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  render() {
    const {
      onEditToggle,
      editMode,
      setFieldTouched,
      setFieldError,
      toggleEditConfirmationModal,
      isVendor,
      accountData,
      callToAction,
    } = this.props;
    const {
      havePermissionForMerchantToggle,
      havePermissionForCorporateToggle,
      havePermissionForAutomaticDowngradesToggle,
      havePermissionForRenewalEmailsToggle,
    } = this.state;
    const stickyNotesList = [];
    const { status } = getStatusInfo(accountData.status);

    if (accountData.isDeleted) {
      this.options[1] = {
        id: "enableAccount",
        componentLeft: <Icon name="Update" />,
        text: "Enable account",
        // red: true,
        action: () => this.props.onRemoveAccount(),
      };
    }

    return (
      <React.Fragment>
        <TitleRow
          rightSection={() =>
            !editMode ? (
              <Nav
                pills
                className={`nav-pill-${
                  status === 1 ? "success" : "danger"
                } mb-0 ml-1`}
              >
                <span className="acc-info-header">
                  <div className="account-type-info">
                    <Icon
                      name={USER_TYPE_ICON[accountData.userType]}
                      size={56}
                    />
                  </div>

                  <span>
                    <h2>
                      {accountData?.companyName || "NEW ACCOUNT"} - #
                      {accountData?.id}
                    </h2>
                    <div className="d-flex" style={{ gap: "1rem" }}>
                      <FTSBadge
                        style={{
                          background: USER_TYPE_COLOR[accountData?.userType],
                          color: "#32383A",
                        }}
                      >
                        {USER_TYPE_NAMES[accountData?.userType]}
                      </FTSBadge>
                      <div className="account-badges">
                        {accountData.isDeleted ? (
                          <div
                            className="d-flex align-items-center"
                            style={{
                              color: "#F36565",
                            }}
                          >
                            <Icon
                              name="DisabledUser"
                              style={{ marginRight: "5px" }}
                            />
                            Disabled
                          </div>
                        ) : (
                          <div
                            className="d-flex align-items-center"
                            style={{
                              color:
                                accountData?.status === 1
                                  ? "#57C990"
                                  : "#F36565",
                            }}
                          >
                            <Icon
                              name={`${USER_STATUSES[accountData?.status]}User`}
                              style={{ marginRight: "5px" }}
                            />
                            {USER_STATUSES[accountData?.status]}
                          </div>
                        )}
                        {accountData?.accountLink && (
                          <div className="d-flex align-items-center">
                            <Icon
                              name="Customers"
                              style={{ marginRight: "5px" }}
                            />
                            Sub Account
                          </div>
                        )}
                      </div>

                      {accountData?.accountAccessRequests?.status ===
                        accountAccessRequestsStatuses.pending &&
                        moment(accountData?.accountAccessRequests?.expiresAt) >
                          moment() && <AccessReuqestedBadge />}
                      {accountData?.accountAccessRequests?.status ===
                        accountAccessRequestsStatuses.granted &&
                        moment(accountData?.accountAccessRequests?.expiresAt) >
                          moment() &&
                        this.state.havePermissionForRemoteAccess && (
                          <AccessGrantedBadge
                            action={this.handleClientRemoteLogin}
                          />
                        )}
                    </div>
                  </span>
                </span>
              </Nav>
            ) : null
          }
          title={editMode ? "Edit account details" : ""}
          noMarker
          {...{ editMode }}
        >
          <Col xs="3" className="text-right d-flex justify-content-end">
            {!editMode && this.state.havePermissionForEdit && (
              <span
                style={{
                  display: "inline-block",
                  marginRight: "1rem",
                }}
              >
                <FTSMenu
                  options={this.options}
                  isOpen={this.state.isOpen}
                  handleMenu={this.handleMenu}
                />
              </span>
            )}
            {!editMode && (
              <span className="edit-btn-wrapper">
                <div
                  type="button"
                  color="transparent"
                  className="edit-btn d-flex items-center"
                  onClick={() => {
                    if (this.state.havePermissionForEdit) {
                      if (this.props.validForm) {
                        return onEditToggle();
                      }
                    }
                    toast.error("You don't have permission for this action");
                  }}
                >
                  <Icon name="Edit" size={20} color="#32383A" />
                  <span className="edit-btn-label">Edit</span>
                </div>
              </span>
            )}
            {editMode && (
              <>
                <Button
                  color="tertiary"
                  type="button"
                  className="preview-listing-btn account-listing-header-btn mr-1"
                  onClick={() => {
                    toggleEditConfirmationModal();
                  }}
                >
                  <span className="preview-listing-btn-label">Cancel</span>
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  className="account-listing-header-btn acc-list-primary-btn"
                  style={{
                    minWidth: "max-content",
                  }}
                >
                  Save changes
                </Button>
              </>
            )}
          </Col>
        </TitleRow>
        <hr className="extends-into-padding mt-0" />

        {!editMode && (
          <InfoLabels
            labels={[
              {
                label: "Type:",
                value: USER_TYPES_NAMED[accountData?.userType],
                type: "gray-box",
              },
              {
                label: "Label:",
                value: accountData?.adminStatus,
                type: "gray-box",
              },
              accountData?.adminStatus === 2 &&
                accountData?.leadStatus !== 0 && {
                  label: "Lead status:",
                  value: accountData?.leadStatus,
                  type: "gray-box",
                },
              {
                label: "Assigned to:",
                value: accountData?.assignedAdmins,
                type: "gray-box",
              },
              {
                label: "Merchant",
                value: accountData?.adminIsMerchant,
                type: "radio",
              },
              {
                label: "Corporate",
                value: accountData?.adminCorporateAccount,
                type: "radio",
              },
            ]}
          />
          // <FormikInput
          //   disabled
          //   label="Type"
          //   name="accountData?.userTypedName"
          // />
        )}

        <AccountStickyNotes
          accountId={accountData.id}
          list={stickyNotesList}
          {...{ callToAction }}
        />

        {editMode && (
          <Row className="account-info-first-row">
            <Col
              sm="6"
              md="6"
              lg="4"
              className={editMode ? "account-info-col-fifth" : ""}
            >
              <FormikInput
                disabled
                name="createdAt"
                label="Created on"
                labelId="createdAt"
              />
            </Col>
            <Col
              sm="6"
              md="6"
              lg="4"
              className={editMode ? "account-info-col-fifth" : ""}
            >
              <FormikInput
                disabled
                name="createdBy"
                label="Created by"
                labelId="createdBy"
              />
            </Col>
            <Col
              sm="6"
              md="6"
              lg="4"
              className={editMode ? "account-info-col-fifth" : ""}
            >
              <FormikInput
                disabled
                name="last_contact_date"
                label="Last contact date"
                labelId="last_contact_date"
              />
            </Col>
            <Col
              sm="6"
              md="6"
              lg="4"
              className={editMode ? "account-info-col-fifth" : ""}
            >
              <div className="form-group">
                <label htmlFor="last_contact_date" className="disabled">
                  Created from ip:
                </label>
                <div id="" className="formik-input-value undefined">
                  <a
                    rel="noreferrer"
                    href={`https://iplocation.io/ip/${accountData.ip}`}
                    target={"_blank"}
                  >
                    {accountData.ip}
                  </a>
                </div>
              </div>
            </Col>
            <Col
              sm="6"
              md="6"
              lg="4"
              className={editMode ? "account-info-col-fifth" : ""}
            >
              <FormikInput disabled name="id" label="Account" labelId="id" />
            </Col>
            <Col sm="12" md="12" lg="12">
              <hr />
            </Col>
            <Col sm="6" md="6" lg="4">
              <FormGroup className="mb-0">
                <FormikConfirmSwitch
                  fieldName="excludeRenewalEmails"
                  label="Exclude from renewal emails"
                  disabled={!havePermissionForRenewalEmailsToggle}
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="6" lg="8">
              <FormGroup className="mb-0">
                <FormikConfirmSwitch
                  fieldName="excludeDowngrades"
                  label="Exclude from automatic downgrades"
                  disabled={!havePermissionForAutomaticDowngradesToggle}
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="12" lg="12">
              <hr />
            </Col>
            {isVendor && (
              <>
                <Col md="12" lg="4">
                  <Row>
                    <Col
                      className="fts-reverse-switch-group"
                      md="6"
                      lg="12"
                      xl="8"
                    >
                      <Row>
                        <Col className="d-flex align-items-center pt-1">
                          <FormGroup className="mb-0 mr-1">
                            <FormikConfirmSwitch
                              fieldName="adminIsMerchant"
                              label="Merchant"
                              disabled={!havePermissionForMerchantToggle}
                            />
                          </FormGroup>
                          <FormGroup className="mb-0">
                            <FormikConfirmSwitch
                              fieldName="adminCorporateAccount"
                              label="Corporate"
                              disabled={!havePermissionForCorporateToggle}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
            <Col md="12" lg="8">
              <Row>
                <Col md="12" lg="5">
                  <FormGroup className="mb-0">
                    <Field name="assignedAdmins">
                      {({ meta, form, field }) => {
                        return (
                          <EmployeePicker
                            isDisabled={!editMode}
                            placeholder="Select assignee..."
                            name="assignedAdmins"
                            setFieldValue={form.setFieldValue}
                            onBlur={field.onBlur}
                            showAssigned={true}
                            hideDisabledUsers
                            {...{ meta, setFieldTouched, setFieldError }}
                          />
                        );
                      }}
                    </Field>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, { loginWithRemoteAccess })(
  AccountInfo_Header,
);
