import React, { Component } from "react";
import "../../../assets/scss/components/fts/primary-devider.scss";

class PrimaryDevider extends Component {
  render() {
    return (
      <div className="fts-primary-devider">
        <div>{this.props.text}</div>
        <div className="fts-primary-devider-line">
          <hr />
        </div>
      </div>
    );
  }
}

export default PrimaryDevider;
