import FTSAxios from "../../../axios/fts.instance";

export const filterTodoTypes = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_TODO_TYPES_DATA", value });
};

export const getTodoTypesData = () => {
  return (dispatch) => {
    return FTSAxios.get("admin/todo/types").then((response) => {
      dispatch({
        type: "GET_TODO_TYPES_DATA",
        data: response.data,
        totalPages: 1,
      });
    });
  };
};

export const updateTodoTypeData = (payload) => {
  return (dispatch) => {
    return FTSAxios.put(`admin/todo/type/${payload.id}`, payload).then(() => {
      FTSAxios.get("admin/todo/types").then((types) => {
        dispatch({
          type: "UPDATE_TYPES_LIST",
          data: types.data,
        });
      });
    });
  };
};

export const createTodoTypeData = (payload) => {
  return (dispatch) => {
    return FTSAxios.post("admin/todo/type", payload).then(() => {
      FTSAxios.get("admin/todo/types").then((types) => {
        dispatch({
          type: "UPDATE_TYPES_LIST",
          data: types.data,
        });
      });
    });
  };
};

export const deleteTodoTypeData = (payload) => {
  return (dispatch) => {
    return FTSAxios.delete(`admin/todo/type/${payload.id}`).then(() => {
      FTSAxios.get("admin/todo/types").then((types) => {
        dispatch({
          type: "UPDATE_TYPES_LIST",
          data: types.data,
        });
      });
    });
  };
};
