/* eslint-disable indent */
import * as Yup from "yup";
import { urlValidate } from "../utility/url-validator";

const pickerShape = Yup.object().shape({
  from: Yup.string().nullable(),
  to: Yup.string().nullable(),
});

const pickerShapeNullable = pickerShape.nullable();

const schemaShape = {
  name: Yup.string().required("This field is required."),
  interstate: Yup.string(),
  mileMarker: Yup.string(),
  geoLocation: Yup.object().shape({
    lat: Yup.number()
      .typeError("This field must be a number.")
      .required("This field is required.")
      .min(-85, "Minimum value for latitude is -85°.")
      .max(85, "Maximum value for latitude is 85°."),
    lng: Yup.number()
      .typeError("This field must be a number")
      .required("This field is required.")
      .min(-180, "Minimum value for longitude is -180°.")
      .max(180, "Maximum value for longitude is 180°."),
  }),
  status: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select listing status")
      : Yup.number()
          .typeError("This field must be a number")
          .nullable()
          .required("Select listing status"),
  ),
  priceId: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Listing type is required")
      : Yup.number()
          .typeError("This field must be a number")
          .nullable()
          .required("Listing type is required"),
  ),
  addressInfo: Yup.object().shape({
    address: Yup.string().required("This field is required."),
    city: Yup.string().required("Select city"),
    state: Yup.lazy((val) =>
      typeof val === "object" && val !== null
        ? Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .nullable()
            .required("Select state")
        : Yup.string().nullable().required("Select state"),
    ),
    zip: Yup.number()
      .typeError("This field must be a number.")
      .positive("This number can't be negative.")
      .required("This field is required."),
  }),

  // business section
  phoneNumber: Yup.string().required("This field is required."),
  afterHours: Yup.string(),
  website: urlValidate,
  website2: urlValidate,
  email: Yup.string().email("Invalid e-mail."),
  specials: Yup.string(),
  seoUrlIdentifier: Yup.string(),

  // description section
  description: Yup.string(),

  // note section
  adminNote: Yup.string(),
};

let schema = null;

const makeSchema = () => {
  const start = pickerShapeNullable;

  const end = pickerShapeNullable;

  const workingHours = {
    towingRecoveryOpen: Yup.boolean(),
    mobileRoadServiceOpen: Yup.boolean(),
    specialHours: Yup.string(),

    MondayFrom: start,
    MondayTo: end,
    TuesdayFrom: start,
    TuesdayTo: end,
    WednesdayFrom: start,
    WednesdayTo: end,
    ThursdayFrom: start,
    ThursdayTo: end,
    FridayFrom: start,
    FridayTo: end,
    SaturdayFrom: start,
    SaturdayTo: end,
    SundayFrom: start,
    SundayTo: end,
  };

  schemaShape.workingHours = Yup.object().shape(workingHours);

  schema = Yup.object().shape(schemaShape);
  return schema;
};

const formSchema = makeSchema();

export default formSchema;
