import React from "react";
import { formatRawDate } from "../../../configs/dateConfig";
import { ContextMenuTrigger } from "react-contextmenu";
import moment from "moment";
import { Col } from "reactstrap";
// import SalesEmployeePicker from "../../../components/fts/sales-employee-picker";
import PaidMethodPicker from "../../../components/fts/paid-method-picker";
import PaidSourcePicker from "../../../components/fts/paid-source-picker";
import { capitalize } from "lodash";

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
    </div>
  );
};

const getStatusColor = (status) => {
  let textColor;
  switch (status) {
    case "Paid":
      textColor = "#107942";
      break;

    case "Unsuccessful":
      textColor = "#647074";
      break;

    default:
      textColor = "#F99930";
      break;
  }

  return { textColor };
};

export const defineInvoicesColumns = (
  collectDataForCopy,
  accountId,
  listingId,
  refreshTable,
) => {
  let columnsInvoices = [
    {
      headerName: "Status",
      field: "statusForSort",
      sortable: true,
      unSortIcon: true,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let { textColor } = getStatusColor(params.data.statusForSort);

        return (
          <ContextMenuTrigger
            data={params.data.statusForSort}
            collect={collectDataForCopy}
            id="contextMenuPayments"
          >
            <span className="ml-1" style={{ color: textColor }}>
              {params.data.statusForSort}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Payment date",
      field: "payments.updatedAt",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data["payments.updatedAt"]).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuPayments"
          >
            <span>
              {params.data["payments.updatedAt"] ? (
                <TableDate date={params.data["payments.updatedAt"]} />
              ) : null}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "TID",
      field: "payments.sourceIdForSort",
      width: 270,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["payments.sourceId"]}
          collect={collectDataForCopy}
          id="contextMenuPayments"
        >
          <span>{params.data["payments.sourceId"]}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Amount",
      field: "total",
      width: 90,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.total}
          collect={collectDataForCopy}
          id="contextMenuPayments"
        >
          <span>${params.data.total}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Paid by",
      field: "payments.paidBy",
      width: 190,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["payments.paidBy"]}
          collect={collectDataForCopy}
          id="contextMenuPayments"
        >
          <span> {params.data["payments.paidBy"]}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Assigned to",
      field: "adminOwnerForSort",
      width: 170,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={
            params.data["owner.assignedAdmins.firstName"] +
            params.data["owner.assignedAdmins.lastName"]
          }
          collect={collectDataForCopy}
          id="contextMenuPayments"
        >
          <span>
            {params.data["owner.assignedAdmins.firstName"]}{" "}
            {params.data["owner.assignedAdmins.lastName"]}
          </span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Acc. Name",
      field: "owner.companyNameForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 250,
      maxWidth: 600,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["owner.companyName"]}
            collect={collectDataForCopy}
            id="contextMenuPayments"
          >
            <div
              style={{
                overflow: "auto",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginTop: "5%" }}>
                {params.data["owner.companyName"]}
              </span>
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Acc. ID",
      field: "owner.id",
      sortable: true,
      unSortIcon: true,
      width: 205,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["owner.id"]}
          collect={collectDataForCopy}
          id="contextMenuPayments"
        >
          <span>{params.data["owner.id"]}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Payment method",
      field: "payments.paidMethodForSort",
      minWidth: 260,
      cellRendererFramework: (params) => {
        if (params.data["payments.paidMethodForSort"] !== "unknown") {
          return (
            <ContextMenuTrigger
              data={params.data["payments.paidMethod"]}
              collect={collectDataForCopy}
              id="contextMenuPayments"
            >
              <span>
                {params.data["payments.paidMethod"] === "ach"
                  ? params.data["payments.paidMethod"].toUpperCase()
                  : capitalize(params.data["payments.paidMethod"])}
              </span>
            </ContextMenuTrigger>
          );
        }
        return (
          <Col
            style={{
              padding: "0",
            }}
          >
            <PaidMethodPicker
              placeholder={"Select payment method"}
              refreshTable={refreshTable}
              paymentId={params.data["payments.id"]}
            />
          </Col>
        );
      },
    },
    {
      headerName: "Payment source",
      field: "payments.paidSourceForSort",
      minWidth: 250,
      cellRendererFramework: (params) => {
        if (params.data["payments.paidSourceForSort"] !== "unknown") {
          return (
            <ContextMenuTrigger
              data={params.data["payments.paidSource"]}
              collect={collectDataForCopy}
              id="contextMenuPayments"
            >
              <span
                onClick={() => {
                  let url;
                  const paymentSource = params.data["payments.paidSource"];
                  if (paymentSource === "stripe") {
                    url = "https://dashboard.stripe.com/login";
                  } else if (paymentSource === "paypal") {
                    url = "https://www.paypal.com/us/signin";
                  } else if (paymentSource === "quickbooks") {
                    url =
                      "https://merchantcenter.intuit.com/msc/portal/login?service=https%3A%2F%2Fmerchantcenter.intuit.com%2Fmsc%2Fportal%2Fhome";
                  }

                  if (url) {
                    const win = window.open(url, "_blank");
                    win.focus();
                  }
                }}
              >
                {capitalize(params.data["payments.paidSource"])}
              </span>
            </ContextMenuTrigger>
          );
        }

        return (
          <Col className={"p-0"}>
            <PaidSourcePicker
              refreshTable={refreshTable}
              placeholder={"Select payment source"}
              paymentId={params.data["payments.id"]}
            />
          </Col>
        );
      },
    },
  ];

  if (accountId || listingId) {
    columnsInvoices = columnsInvoices.filter(
      (e) => e.field !== "owner.companyNameForSort",
    );
  }

  return columnsInvoices;
};
