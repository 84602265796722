import { TextStyle } from "../../FtsText";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsTagsSizes, FtsTagsVariants } from "@find-truck-service/types/ui/tags";
const defaultStyle = `
              display: flex;
              align-items: center;
              width: fit-content;
              height: fit-content;
              border-radius: ${Spacing["4xxl"]};`;
export const FtsTagsStyles = (props) => {
    let style = {
        [FtsTagsVariants.outlined]: {
            [FtsTagsSizes.sm]: `${defaultStyle}; padding: ${Spacing["2xs"]} ${Spacing["sm"]}; border: ${Spacing["4xs"]} ${props.color} solid;
      ${TextStyle({
                weight: WeightSize.medium,
                variant: VariantsValues["text-4xs"],
                color: props.textColor,
            })};`,
            [FtsTagsSizes.md]: `${defaultStyle}; padding: ${Spacing["sm"]} ${Spacing["md"]}; border: ${Spacing["4xs"]} ${props.color} solid;
      ${TextStyle({
                weight: WeightSize.medium,
                variant: VariantsValues["text-3xs"],
                color: props.textColor,
            })}; `,
            [FtsTagsSizes.lg]: `${defaultStyle}; padding: ${Spacing["sm"]} ${Spacing["md"]}; border: ${Spacing["4xs"]} ${props.color} solid;
      ${TextStyle({
                weight: WeightSize.medium,
                variant: VariantsValues["text-2xs"],
                color: props.textColor,
            })};`,
        },
        [FtsTagsVariants.contained]: {
            [FtsTagsSizes.sm]: `${defaultStyle}; padding:  ${Spacing["2xs"]} ${Spacing["sm"]}; background:${props.color};
      ${TextStyle({
                weight: WeightSize.medium,
                variant: VariantsValues["text-4xs"],
                color: props.textColor,
            })};`,
            [FtsTagsSizes.md]: `${defaultStyle}; padding: ${Spacing["sm"]} ${Spacing["md"]}; background:${props.color};
      ${TextStyle({
                weight: WeightSize.medium,
                variant: VariantsValues["text-3xs"],
                color: props.textColor,
            })};`,
            [FtsTagsSizes.lg]: `${defaultStyle}; padding: ${Spacing["sm"]} ${Spacing["md"]}; background:${props.color};
      ${TextStyle({
                weight: WeightSize.medium,
                variant: VariantsValues["text-2xs"],
                color: props.textColor,
            })};`,
        },
    };
    return style[props.variant][props.size];
};
