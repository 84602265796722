export const SET_USER_ACTIVITIES = "SET_USER_ACTIVITIES";
export const ADD_USER_ACTIVITY = "ADD_USER_ACTIVITY";
export const CLEAR_USER_ACTIVITY_LIST = "CLEAR_USER_ACTIVITY_LIST";

export const setUserActivities = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_ACTIVITIES,
      data,
    });
  };
};

export const addUserActivity = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_USER_ACTIVITY,
      data,
    });
  };
};

export const clearUserActivityList = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_ACTIVITY_LIST,
    });
  };
};
