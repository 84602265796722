/* eslint-disable indent */
/* eslint-disable react/display-name */
import React from "react";
import { formatRawDate, toMomentDate } from "../../../configs/dateConfig";
import { ContextMenuTrigger } from "react-contextmenu";
import moment from "moment";

export const adminStatusBasedOnID = (adminStatus) => {
  let label;
  switch (adminStatus) {
    case 1:
      label = "Free";
      break;
    case 2:
      label = "Lead";
      break;
    case 3:
      label = "Customer";
      break;
    case 4:
      label = "Trial";
      break;
    case 5:
      label = "Suspended";
      break;
    case 6:
      label = "Closed";
      break;
    case 7:
      label = "Spammer";
      break;
    case 8:
      label = "Paid";
      break;
    case 9:
      label = "Unpaid";
      break;
    case 10:
      label = "Project";
      break;
    case 11:
      label = "Other";
      break;

    case -1:
    default:
      label = "Not assigned";
      break;
  }
  return label;
};

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
      <div className="ag-date-table__time">
        {toMomentDate(date).format("[at] h:mm a")}
      </div>
    </div>
  );
};

export const defineListingColumns = (
  types,
  collectDataForCopy,
  activeTab,
  employees,
) => {
  let columns = [
    {
      headerName: "Renewal date",
      field: "renewalTimestamp",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment.unix(params.data.renewalTimestamp).format("ll")}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>
              {!!params.data.renewalTimestamp &&
                moment.unix(params.data.renewalTimestamp).format("ll")}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "CID (Account I#D)",
      field: "userId",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.userId}
          collect={collectDataForCopy}
          id="contextmenu"
        >
          <span>{params.data.userId}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Listing ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.id}
          collect={collectDataForCopy}
          id="contextmenu"
        >
          <span>{params.data.id}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Account owner",
      field: "adminAccountOwner",
      sortable: true,
      unSortIcon: true,
      width: 180,
      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data.adminAccountOwner,
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Unassigned"}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{admin?.label || "Unassigned"}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "nameForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.name}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.name}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.city}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email address",
      field: "emailForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.phoneNumber}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "After hours",
      field: "afterHoursForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.afterHours}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.afterHours || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
  ];

  if (activeTab === "upcoming") {
    columns = [
      ...columns.slice(0, 1),
      {
        headerName: "Renewed at",
        field: "renewedTimestamp",
        sortable: true,
        unSortIcon: true,
        width: 150,
        cellRendererFramework: (params) => {
          return (
            <ContextMenuTrigger
              data={moment.unix(params.data.renewedTimestamp).format("lll")}
              collect={collectDataForCopy}
              id="contextmenu"
            >
              <span>
                {!!params.data.renewedTimestamp && (
                  <TableDate
                    date={new Date(
                      params.data.renewedTimestamp * 1000,
                    ).toISOString()}
                  />
                )}
              </span>
            </ContextMenuTrigger>
          );
        },
      },
      ...columns.slice(1, 1000),
    ];
  }

  return columns;
};
