import React, { Component } from "react";

import { Button } from "reactstrap";
import FTSModal from "../../../../../components/fts/fts-modal";
import CloseButton from "../../../../../components/fts/CloseButton";
import Icon from "../../../../../components/fts/Icons";
import "../../../../../assets/scss/components/fts/payment-modals.scss";

class PaymentMethodModal extends Component {
  render() {
    const { isOpen, title, closeModal, onClose, onConfirmAchPayment, amount } =
      this.props;

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-payment-modal"}
        centered={true}
        style={{ maxWidth: "665px" }}
        footerCTA={() => {
          return (
            <div className="confirm-payment-modal-footer">
              <span className="mr-1">
                <Button onClick={closeModal} color="secondary">
                  Cancel
                </Button>
              </span>
              <Button onClick={onConfirmAchPayment} color="primary">
                Confirm
              </Button>
            </div>
          );
        }}
      >
        <div className="payment-modal-body">
          <span className="payment-modal-body-wrapper">
            <span className="payment-modal-title">
              <h3>Please confirm you received this payment.</h3>
              <CloseButton onClick={closeModal} />
            </span>

            <div className="confirm-payment-info">
              <span>
                <Icon name="InfoCircle" />
              </span>
              <span className="confirm-payment-info-message">
                <span className="amount">
                  $
                  {Number(parseFloat(amount).toFixed(2)).toLocaleString() + " "}
                </span>
                will be posted to the account. Are you sure you received this
                amount?
              </span>
            </div>
          </span>
        </div>
      </FTSModal>
    );
  }
}

export default PaymentMethodModal;
