import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Span } from "@find-truck-service/ui/src/FtsText";
import React from "react";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export const AccountAltContactWrapper = ({ children }) => {
  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} py={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          ALT CONTACTS
        </Span>
      </FtsColumn>
      {children}
    </FtsRow>
  );
};
