import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import Uppy from "@uppy/core";

import { DragDrop, Dashboard } from "@uppy/react";
import { Progress } from "reactstrap";
import { toast } from "react-toastify";

import appConfig from "../../../../configs/appConfig";
import { withTrailingSlash } from "../../../../utility/trailingSlash";
import { getAuthToken } from "../../../../cookies";

import { defaultOptions, dashboardDefaultOptions } from "./data";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import "../../../../assets/scss/components/fts/file-uploader.scss";
import { refreshAuthToken } from "../../../../redux/actions/auth/loginActions";

class FTSFileUploader extends Component {
  refreshingToken = createRef(false);
  failedQueue = createRef({});

  constructor(props) {
    super(props);

    this.refreshingToken.current = false;
    this.failedQueue.current = {};

    this.defaultOptions = {
      ...defaultOptions,
    };

    this.dashboardDefaultOptions = {
      ...dashboardDefaultOptions,
      doneButtonHandler: () => {
        this.uppy.reset();
      },
    };

    const { id, options } = this.props;

    this.uppy = new Uppy({
      ...this.defaultOptions,
      ...this.dashboardDefaultOptions,
      ...options,
      restrictions: {
        ...this.defaultOptions.restrictions,
        ...(options?.restrictions ?? {}),
      },
      id,
      onBeforeUpload: this.onBeforeUpload,
    })

      .on("files-added", this.onFilesAdded)
      .on("complete", this.onComplete)
      .on("upload-success", this.onSingleUploadSuccess)
      .on("upload-error", this.onSingleUploadError)
      .on("upload-retry", this.onUploadRetry);

    if (props.onRef) {
      props.onRef(this.uppy);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.endpoint !== this.props.endpoint &&
      this.props.endpointIsDynamic
    ) {
      const { xhrUpload } = this.uppy.getState();
      this.uppy.setState({
        xhrUpload: {
          ...xhrUpload,
          endpoint: `${withTrailingSlash(appConfig.apiURL)}${
            this.props.endpoint
          }`,
        },
      });
    }
  }

  componentWillUnmount() {
    this.uppy.close();
  }

  onBeforeUpload = () => {
    this.updateToken();
  };

  onUploadRetry = () => {
    this.updateToken();
  };

  /**
   * Updates internal uppy state with the new auth token in the 'Authorization' header
   */
  updateToken = () => {
    const { xhrUpload } = this.uppy.getState();

    if (xhrUpload?.headers?.Authorization === getAuthToken()) return;

    this.uppy.setState({
      xhrUpload: {
        ...xhrUpload,
        headers: {
          ...(xhrUpload?.headers ?? {}),
          Authorization: getAuthToken(),
        },
      },
    });
  };

  onFilesAdded = (...params) => {
    if (params[0].length > 1) {
      return toast.info("Please select only one file");
    }
    if (this.props.promotionsForm) {
      let spreadedItems = [...params].filter((item) => item)[0];
      this.props.onFilesAdded(spreadedItems, this.props.arrayName);
      return;
    }

    if (this.props.onFilesAdded) {
      this.props.onFilesAdded(...params);
    }
  };

  /**
   * Called when all uploads are finished (failed or successful)
   *
   * @param {*} result - Object contatining an array of 'failed' files, and an array of 'successful' files
   */
  onComplete = (result) => {
    const finishedAllFiles =
      Object.keys(this.failedQueue.current).filter(
        (key) => this.failedQueue.current[key] // filter out undefined values
      )?.length === 0;

    if (!finishedAllFiles) return;

    if (this.props.onUploadComplete && finishedAllFiles) {
      this.props.onUploadComplete(result);
      this.uppy.reset();
    }
  };

  /**
   * Add file to failed uploads queue. This queue only contains uploads that have failed due to expired auth token.
   *
   * @param {*} file - Uppy File Object containing information about the file that failed to be uploaded
   */
  addFailedFileToQueue = (file) => {
    Object.assign(this.failedQueue.current, { [file.id]: file });
  };

  /**
   * Attempts to re-upload each file in the 'failedQueue'
   */
  retryFailedUploads = () => {
    if (!this.failedQueue?.current) return;

    Object.keys(this.failedQueue.current).forEach((fileID) => {
      if (!this.failedQueue.current[fileID]) return;

      if (this.failedQueue.current[fileID].isUploading === true) return;

      this.failedQueue.current[fileID].isUploading = true;
      this.uppy.retryUpload(fileID);
    });
  };

  /**
   * Refreshes token and calls 'this.retryFailedUploads()' to re-upload any failed files
   */
  refreshToken = () => {
    if (this.refreshingToken.current === true) return;

    this.refreshingToken.current = true;

    this.props.refreshAuthToken().then(() => {
      this.updateToken();
      this.refreshingToken.current = false;

      this.retryFailedUploads();
    });
  };

  /**
   * Called when any single upload fails
   *
   * @param {*} file - file that wasn't uploaded successfully
   * @param {*} error - error message of why the upload failed
   * @param {*} response - full response object from the back-end endpoint
   */
  onSingleUploadError = (file, error, response) => {
    if (response?.status === 403) {
      this.addFailedFileToQueue(file);

      if (!this.refreshingToken.current) {
        this.refreshToken();
      }
      return;
    }

    toast.error(`Failed to upload file "${file?.name}". Reason: ${error}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (this.props.onSingleUploadError) {
      this.props.onSingleUploadError(file, error, response);
    }
  };

  /**
   * Called when any single upload is successful
   *
   * @param {*} file - file that was uploaded successfully
   * @param {*} response - full response object from the back-end endpoint
   */
  onSingleUploadSuccess = (file, response) => {
    toast.success(`Successfully uploaded file "${file?.name}".`, {
      position: toast.POSITION.TOP_RIGHT,
    });

    if (this.failedQueue.current && this.failedQueue.current[file.id]) {
      delete this.failedQueue.current[file.id];
    }

    if (this.props.onSingleUploadSuccess) {
      this.props.onSingleUploadSuccess(file, response);
    }
  };

  getResponseData = (response) => {
    let parsedRes;
    let formattedData;
    try {
      parsedRes = JSON.parse(response);
      if (!parsedRes?.error) {
        formattedData = {
          ...parsedRes,
          url: `${withTrailingSlash(appConfig.imageUrl)}${
            parsedRes?.image ?? ""
          }`,
        };
      }
    } catch (err) {
      formattedData = {
        ...parsedRes,
        url: `${withTrailingSlash(appConfig.imageUrl)}`,
      };
    }
    return formattedData;
  };

  render() {
    const {
      as,
      width,
      height,
      // hideStatusBar,
      dashboardProps: userDashboardProps,
      dragDropProps: userDragDropProps,
      // statusBarProps: userStatusBarProps,
      options,
      uploadingPercent = 0,
    } = this.props;

    let allowedFileTypes = "";

    if (options) {
      options.restrictions.allowedFileTypes.forEach((type) => {
        allowedFileTypes += type + ", ";
      });
    }

    allowedFileTypes = allowedFileTypes.slice(0, -2);

    const dashboardProps = {
      showProgressDetails: true,
      ...userDashboardProps,
      width: width ?? this.defaultOptions.width,
      height: height ?? this.defaultOptions.height,
      uppy: this.uppy,
    };

    const dragDropProps = {
      ...userDragDropProps,
      width: width ?? this.defaultOptions.width,
      height: height ?? this.defaultOptions.height,
      uppy: this.uppy,
      allowedFileTypes: allowedFileTypes,
    };
    //
    // const statusBarProps = {
    //   showProgressDetails: true,
    //   hideAfterFinish: true,
    //   ...userStatusBarProps,
    //   uppy: this.uppy,
    // };

    if (as === "dashboard" || as === "dragdrop") {
      if (as === "dashboard") {
        return (
          <>
            <Dashboard className="mb-1" {...dashboardProps} />
            <span>{allowedFileTypes}</span>
          </>
        );
      }

      if (as === "dragdrop") {
        return (
          <>
            <DragDrop className="mb-1" {...dragDropProps} />
            {uploadingPercent ? (
              <Progress
                value={uploadingPercent}
                animated
                style={{ height: 15 }}
              >
                Uploading...
              </Progress>
            ) : null}

            <span>{allowedFileTypes}</span>
          </>
        );
      }
    }

    return null;
  }
}

export default connect(null, { refreshAuthToken })(FTSFileUploader);
