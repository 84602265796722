/* eslint-disable indent */
import React from "react";

import Icon from "../Icons";
import { ErrorMessage } from "formik";
import Select from "react-dropdown-select";
import "../../../assets/scss/components/fts/timer-picker.scss";

class TimerPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      multi: false,
      loading: false,
      contentRenderer: false,
      dropdownRenderer: true,
      inputRenderer: false,
      itemRenderer: true,
      optionRenderer: false,
      noDataRenderer: false,
      selectValue: null,
      searchBy: "label",
      clearable: false,
      searchable: true,
      handle: true,
      labelField: "label",
      valueField: "id",
      color: "blue",
      keepSelectedInList: true,
      dropdownPosition: "bottom",
      direction: "ltr",
      dropdownHeight: "500px",
    };
  }

  selectRef = React.createRef();

  list = [
    { id: 1, label: "15 minutes", value: "15m" },
    { id: 2, label: "30 minutes", value: "30m" },
    { id: 3, label: "1 hour", value: "1h" },
    { id: 4, label: "1 day", value: "1d" },
    { id: 5, label: "1 week", value: "1w" },
    { id: 6, label: "Indefinite", value: "0" },
  ];

  setValue = (selectValue) => {
    this.setState({ selectValue: selectValue[0] });
  };

  noDataRenderer = () => {
    return (
      <p style={{ textAlign: "center" }}>
        <strong>Ooops!</strong> No data found
      </p>
    );
  };

  itemRenderer = ({ item, props, methods }) => (
    <div key={item[props.valueField]} onClick={() => methods.addItem(item)}>
      <div style={{ margin: "10px" }}>
        <input type="checkbox" checked={methods.isSelected(item)} />
        &nbsp;&nbsp;&nbsp;{item["label"]}
      </div>
    </div>
  );

  dropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search, "i");
    return (
      <div className="items">
        {props.options
          ?.filter((item) => regexp.test(item[props.searchBy] || item["label"]))
          .map((option) => {
            if (!this.state.keepSelectedInList && methods.isSelected(option)) {
              return null;
            }

            const selected = this.state.selectValue?.id === option["id"];

            return (
              <div
                className={`item ${selected && "item-selected"}`}
                disabled={option.disabled}
                key={option["id"]}
                onClick={option.disabled ? null : () => methods.addItem(option)}
              >
                <div className="d-flex align-items-center">
                  <Icon name="Clock" />
                  <div className="item-label">{option["label"]}</div>
                </div>
                {selected && <Icon name="Checked" />}
              </div>
            );
          })}
      </div>
    );
  };

  optionRenderer = ({ option, methods }) => {
    return (
      <React.Fragment>
        <div onClick={(event) => methods.removeItem(event, option, true)}>
          {option.label}
        </div>
      </React.Fragment>
    );
  };

  onOptionSelected = (value) => {
    this.props.onChange(value[0]);
    this.setValue(value);
  };

  render() {
    return (
      <div className="timer-picker">
        <Select
          ref={this.selectRef}
          className="select"
          placeholder={this.props.placeholder}
          readOnly={true}
          searchBy={this.state.searchBy}
          separator={this.state.separator}
          clearable={false}
          searchable={this.state.searchable}
          dropdownHandle={this.state.handle}
          direction={this.state.direction}
          values={this.state.selectedValue}
          labelField={this.state.labelField}
          valueField={this.state.valueField}
          options={this.list}
          dropdownGap={5}
          keepSelectedInList={this.state.keepSelectedInList}
          closeOnScroll={true}
          onDropdownOpen={() => undefined}
          onDropdownClose={() => undefined}
          onClearAll={() => undefined}
          onSelectAll={() => undefined}
          onChange={this.onOptionSelected}
          noDataLabel="No matches found"
          closeOnSelect={this.state.closeOnSelect}
          dropdownHeight={this.state.dropdownHeight}
          disabled={this.props.isLoading}
          noDataRenderer={
            this.state.noDataRenderer ? () => this.noDataRenderer() : undefined
          }
          dropdownPosition={this.state.dropdownPosition}
          itemRenderer={
            this.state.itemRenderer
              ? (item, itemIndex, props, state, methods) =>
                  this.itemRenderer(item, itemIndex, props, state, methods)
              : undefined
          }
          inputRenderer={
            this.state.inputRenderer
              ? (props, state, methods) =>
                  this.inputRenderer(props, state, methods)
              : undefined
          }
          optionRenderer={
            this.state.optionRenderer
              ? (option, props, state, methods) =>
                  this.optionRenderer(option, props, state, methods)
              : undefined
          }
          dropdownRenderer={
            this.state.dropdownRenderer
              ? (innerProps, innerState, innerMethods) =>
                  this.dropdownRenderer(innerProps, innerState, innerMethods)
              : undefined
          }
        />
        <ErrorMessage
          name={"expire"}
          render={(msg) => <div className="formik-validation-error">{msg}</div>}
        />
      </div>
    );
  }
}

export default TimerPicker;
