import React, { Component } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import PaymentTypePicker from "../../../../../components/fts/payment-type-picker";
import FormikInput from "../../../../../components/fts/formik/formik-input";

import { Button, FormGroup, Label } from "reactstrap";
import FTSModal from "../../../../../components/fts/fts-modal";
import CloseButton from "../../../../../components/fts/CloseButton";
import Icon from "../../../../../components/fts/Icons";
import "../../../../../assets/scss/components/fts/payment-modals.scss";

class ManuallyPaymentModal extends Component {
  state = {
    initialFormData: {
      paymentType: "",
      number: "",
      amount: this.props.total,
    },
  };

  label = "Check/Reference";
  placeholder = "Check/Reference Number";

  now = new Date();

  // componentDidUpdate(prevProps) {
  //   if (prevProps.total !== this.state.initialFormData.amount) {
  //     this.setState({
  //       initialFormData: {
  //         paymentType: "",
  //         number: "",
  //         amount: this.props.total,
  //       },
  //     });
  //   }
  // }

  updateActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  formatMoney = (number) => {
    return number
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  render() {
    const { isOpen, title, closeModal, onClose, setPaymentStep, onFormSubmit } =
      this.props;

    const formSchema = Yup.object().shape({
      paymentType: Yup.number().nullable().required("Payment type is required"),
      number: Yup.string()
        .nullable()
        .required(`${this.placeholder} is required`),
      amount: Yup.number()
        .nullable()
        .required("Amount is required")
        .min(
          this.props.total,
          "This amount does not match the amount on the payment overview.",
        )
        .max(
          this.props.total,
          "This amount does not match the amount on the payment overview.",
        ),
    });

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-payment-modal"}
        centered={true}
        style={{ maxWidth: "800px" }}
      >
        <Formik
          initialValues={this.state.initialFormData}
          validationSchema={formSchema}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={onFormSubmit}
        >
          {({ setFieldError, setFieldTouched }) => {
            return (
              <Form id="credit-card-form">
                <div className="payment-modal-body">
                  <span className="payment-modal-body-wrapper">
                    <span className="payment-modal-title">
                      <span className="d-flex align-items-center">
                        <span
                          className=" link-button"
                          onClick={() => {
                            setPaymentStep("paymentMethod");
                          }}
                        >
                          <Icon name="Link" size="24" />
                        </span>

                        <h3 className="mb-0">Log payment manually</h3>
                      </span>
                      <CloseButton onClick={closeModal} />
                    </span>
                    <div className="manual-payment-info">
                      <span>
                        <Icon name="InfoCircle" />
                      </span>
                      <span className="manual-payment-info-message">
                        If payment is received by check, please note received
                        amount and Check number/ID
                      </span>
                    </div>

                    <div className="manual-payment-form">
                      <div className="manual-payment-fields">
                        <FormGroup>
                          <Label
                            className="payment-type-label"
                            for="paymentType"
                          >
                            Payment Type *
                          </Label>
                          <Field name="paymentType">
                            {({ meta, form, field }) => (
                              <PaymentTypePicker
                                name="paymentType"
                                placeholder="ACH, Check or Quickbooks"
                                onChange={(option) => {
                                  if (option) {
                                    setFieldError("paymentType", undefined);
                                    this.setState({
                                      paymentType: option?.value,
                                    });

                                    if (option?.value === 1) {
                                      this.label = "Reference";
                                      this.placeholder = "Ref. Number";
                                    }
                                    if (option?.value === 2) {
                                      this.label = "Check";
                                      this.placeholder = "Check Number";
                                    }
                                    if (option?.value === 3) {
                                      this.label = "Quickbooks";
                                      this.placeholder = "TID";
                                    }
                                  }
                                  form.setFieldValue(
                                    "paymentType",
                                    option.value,
                                  );
                                  setTimeout(() => {
                                    setFieldTouched("paymentType", true, true);
                                  }, 0);
                                }}
                                onBlur={field.onBlur}
                                required
                                {...{ meta }}
                              />
                            )}
                          </Field>
                        </FormGroup>
                      </div>
                      <div className="manual-payment-fields">
                        <FormikInput
                          label={`${this.label} #`}
                          name="number"
                          labelId="number"
                          placeholder={this.placeholder}
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="manual-payment-fields">
                        <FormGroup>
                          <label htmlFor="amount">Amount ($)</label>
                          <p
                            style={{ marginTop: "0.5rem" }}
                            className="d-flex align-items-center"
                          >
                            ${this.formatMoney(this.props.total)}
                          </p>
                        </FormGroup>
                        {/*<FormikInput*/}
                        {/*  disabled={true}*/}
                        {/*  type="number"*/}
                        {/*  label="Amount ($)"*/}
                        {/*  name="amount"*/}
                        {/*  labelId="amount"*/}
                        {/*  placeholder="Enter amount paid"*/}
                        {/*  autoComplete="off"*/}
                        {/*  required*/}
                        {/*>*/}
                        {/*</FormikInput>*/}
                      </div>
                    </div>
                    <hr className="extends-into-padding" />
                    <div className="manually-payment-modal-footer">
                      <span className="mr-1">
                        <Button onClick={closeModal} color="secondary">
                          Cancel
                        </Button>
                      </span>
                      <Button type="submit" color="primary">
                        Mark as paid
                      </Button>
                    </div>
                  </span>
                </div>
              </Form>
            );
          }}
        </Formik>
      </FTSModal>
    );
  }
}

export default ManuallyPaymentModal;
