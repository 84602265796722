/* eslint-disable indent */
const initialState = {
  activitiesList: null,
};
const ActivitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVITIES_LIST":
      return {
        ...state,
        activitiesList: state.activitiesList
          ? [...state.activitiesList, action.payload]
          : action.payload,
      };
    default:
      return state;
  }
};

export default ActivitiesReducer;
