import React, { Component } from "react";
import { Label } from "reactstrap";
import { ErrorMessage } from "formik";

import { pickerStyles, controlStyles } from "../picker-style";
import Select from "../Select";
import { components } from "react-select";

const ValueContainer = ({ children, showInnerLabel, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {showInnerLabel && (
          <div
            style={{
              paddingRight: "0.5rem",

              color: "#424C54",
            }}
          >
            Status:
          </div>
        )}
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};
class TodoStatusPicker extends Component {
  state = {
    todoStatuses: [
      { label: "Active", value: "active" },
      { label: "Archived", value: "archived" },
    ],
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.isDisabled !== this.props.isDisabled ||
      (nextProps.meta && nextProps.meta.error !== this.props.meta.error) ||
      (!!this.props.meta &&
        nextProps.meta.touched !== this.props.meta.touched) ||
      this.state.todoStatuses !== nextState.todoStatuses ||
      nextProps.value !== this.props.value
    );
  }

  render() {
    let todoStatuses;
    if (this.props.todoStatuses) {
      todoStatuses = this.props.todoStatuses;
    } else {
      todoStatuses = this.state.todoStatuses;
    }
    const { label, meta, name, isDisabled, hasAll, value, ...rest } =
      this.props;

    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) => controlStyles(styles, state, {}, { meta }),
    };

    if (hasAll) {
      todoStatuses = [
        { color: "#7d8c91", type: "both", label: "All", value: "" },
        ...todoStatuses,
      ];
    }

    let defaultValue =
      meta && meta.initialValue
        ? todoStatuses.find((itm) => itm.value === meta.initialValue)
        : null;

    // if (taskManagement) {
    //   defaultValue = todoStatuses.find((itm) => itm.label === "Active");
    // } else {
    if (hasAll) {
      defaultValue = todoStatuses.find((itm) => itm.label === "All");
    }
    // }

    let valueInfo = typeof value !== "object" ? defaultValue : value;

    let isLoading = false;

    if (!todoStatuses?.length) {
      isLoading = true;
    }

    return (
      <>
        {!!label && (
          <Label
            className={isDisabled ? "disabled" : ""}
            for="activation-status"
          >
            {this.props.required && !isDisabled ? `${label} *` : label}
          </Label>
        )}
        <Select
          {...{ defaultValue, isDisabled, isLoading, label }}
          isSearchable={false}
          options={todoStatuses}
          styles={pickerStyle}
          name={name}
          components={{
            ValueContainer: (props) => (
              <ValueContainer showInnerLabel={hasAll} {...props} />
            ),
          }}
          value={valueInfo}
          {...rest}
        />
        <ErrorMessage
          name={name}
          render={(msg) => <div className="formik-validation-error">{msg}</div>}
        />
      </>
    );
  }
}

export default TodoStatusPicker;
