import React from "react";
import moment from "moment";
import { ContextMenuTrigger } from "react-contextmenu";
import FTSBadge from "../../../components/fts/fts-badge";
import { formatRawDate, toMomentDate } from "../../../configs/dateConfig";

const getListingType = (priceId) => {
  priceId = parseInt(priceId);
  if (priceId === 1) {
    return "Premium";
  } else if (priceId === 2) {
    return "Standard";
  } else if (priceId === 3) {
    return "Basic plus";
  } else if (priceId === 4) {
    return "Basic";
  } else if (priceId === 5) {
    return "Free";
  } else {
    return null;
  }
};

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
      <div className="ag-date-table__time">
        {toMomentDate(date).format("[at] h:mm a")}
      </div>
    </div>
  );
};

export const defineUpgradesColumns = (collectDataForCopy, employees) => {
  let columns = [
    {
      headerName: "Upgraded on",
      field: "upgradedAt",
      sortable: true,
      unSortIcon: true,
      width: 150,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.upgradedAt).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <div>
              <TableDate date={params.data.upgradedAt} />
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "CID",
      field: "userId",
      sortable: true,
      unSortIcon: true,
      width: 193,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.userId}
          collect={collectDataForCopy}
          id="contextMenuUpgrades"
        >
          <span>{params.data.userId}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Listing ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.id}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Old listing type",
      field: "oldListingType",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        let priceId = 0;
        if (params?.data?.update?.requestedUpdate) {
          priceId = JSON.parse(params.data.update.requestedUpdate).priceId;
        }
        let oldType = getListingType(priceId);

        return (
          <ContextMenuTrigger
            data={oldType}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <div>
              {oldType && <FTSBadge className="light-dark">{oldType}</FTSBadge>}
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "New listing type",
      field: "newListingType",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        let newType = getListingType(params.data.priceId);

        return (
          <ContextMenuTrigger
            data={newType}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <div>
              {newType && <FTSBadge className="light-dark">{newType}</FTSBadge>}
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Assigned to",
      field: "adminAccountOwner",
      sortable: true,
      unSortIcon: true,
      width: 193,
      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data.adminAccountOwner,
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Unassigned"}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{admin?.label || "Unassigned"}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "name",
      sortable: true,
      unSortIcon: true,
      width: 386,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpgrades"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.city}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "addressForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.address}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.address}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Upgraded by",
      field: "upgradedByForSort",
      sortable: true,
      unSortIcon: true,
      width: 193,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data?.upgradedByForSort || "Unassigned"}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data?.upgradedBy || "Unassigned"}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email",
      field: "emailForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuUpgrades"
          >
            <span>{params.data.phoneNumber}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "After hours",
      field: "afterHoursForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.afterHours}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.afterHours || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
  ];

  return columns;
};
