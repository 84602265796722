import React from "react";
import { formatRawDate, toMomentDate } from "../../../configs/dateConfig";
import { ContextMenuTrigger } from "react-contextmenu";
import FTSBadge from "../../../components/fts/fts-badge";
import moment from "moment";

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
      <div className="ag-date-table__time">
        {toMomentDate(date).format("[at] h:mm a")}
      </div>
    </div>
  );
};

export const definePromoCodesColumns = (collectDataForCopy) => {
  let columnsPromoCodes = [
    {
      headerName: "Status",
      field: "status",
      width: 200,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.discount}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <FTSBadge
            color={
              params.value === 1 && moment() < new Date(params.data.endAt)
                ? "success"
                : "danger"
            }
          >
            {getBadgeText(params.value, params.data.endAt)}
          </FTSBadge>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Discount amount",
      field: "discount",
      width: 200,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.discount}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          {params.data.discountType === "$" ? (
            <div>
              <span>{params.data.discountType}</span>
              <span>{params.data.discount}</span>
            </div>
          ) : (
            <div>
              <span>{params.data.discount}</span>
              <span>{params.data.discountType}</span>
            </div>
          )}
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Promo Code",
      field: "promoCode",
      width: 200,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.invoiceNumber}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.data.promoCode}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 170,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.description}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.data.description}</span>
        </ContextMenuTrigger>
      ),
    },

    {
      headerName: "Enabled for",
      field: "rules",
      minWidth: 400,
      flex: 1,
      cellRendererFramework: (params) => {
        const rulesArray = params.data.rules.split(" | ");

        return (
          <ContextMenuTrigger
            data={params.data.rules}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div
              className="d-flex flex-wrap"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {rulesArray.map((type) => {
                if (type !== "") {
                  return (
                    <FTSBadge
                      key={type}
                      style={{
                        backgroundColor: "#E5E8E9",
                        borderColor: "#E5E8E9",
                        color: "#242D34",
                        marginRight: "4px",
                        marginTop: "1px",
                        marginBottom: "1px",
                      }}
                    >
                      {type}
                    </FTSBadge>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Number of uses",
      field: "numberOfUses",
      minWidth: 170,
      flex: 1,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.numberOfUses}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.data.numberOfUses}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Start date",
      field: "startAt",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.startAt).format("MMM DD, YYYY")}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>
              <TableDate date={params.data.startAt} />
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "End date",
      field: "endAt",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.endAt).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>
              {params.data.endAt ? (
                <TableDate date={params.data.endAt} />
              ) : null}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
  ];

  return columnsPromoCodes;
};

const getBadgeText = (status, endAt) => {
  let text;
  if (status === 1) text = "Active";
  if (status === 0) text = "Inactive";
  if (status === 1 && new Date(endAt) < moment()) text = "Expired";

  return text;
};
