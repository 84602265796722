import FTSAxios from "../../../axios/fts.instance";
import axios from "axios";
import { getAuthToken } from "../../../cookies";

export const getFolderTemplates = (params) => {
  const { folderId, status, page, resultsPerPage, accountId } = params;
  return () => {
    return FTSAxios.get(
      `/admin/emails/getAllTemplates/${folderId}/${status}/${page}/${resultsPerPage}`,
      { params: { accountId } }
    );
  };
};
// FTSAxios.get("admin/emails/types"),
export const getTemplatesFolders = (params) => {
  const { status } = params;
  return () => {
    return FTSAxios.get("admin/emails/getTemplatesFolders", {
      params: { status },
    });
  };
};

export const getSystemTemplates = (params) => {
  const { page, resultsPerPage, accountId, emailType, status, sortModel } =
    params;
  return () => {
    return FTSAxios.post("/admin/emails/getSystemEmails", {
      page,
      resultsPerPage,
      accountId,
      emailType,
      status,
      sortModel,
    });
  };
};

export const getAllSystemEmails = (params) => {
  const { page, resultsPerPage, emailType } = params;
  return () => {
    return FTSAxios.get(
      `/admin/emails/getAllSystemEmails/${page}/${resultsPerPage}/${emailType}`
    );
  };
};

export const getTemplate = (templateName) => {
  return () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}admin/emails/getCampaignTemplate/${templateName}`,
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  };
};
export const getSystemTemplate = (id) => {
  return () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}admin/emails/getSystemEmailTemplate/${id}`,
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  };
};

export const resendSystemTemplate = (id) => {
  return () => {
    return FTSAxios.get(`/admin/emails/resend/${id}`);
  };
};

export const sendTemplate = (payload) => {
  return () => {
    return FTSAxios.post("/admin/emails/send", payload);
  };
};

export const updateComunicationsCounter = (payload) => {
  return (dispatch) =>
    dispatch({ type: "SET_COMMUNICATIONS_COUNTER", payload });
};

export const updateEmailsFolders = (payload) => {
  return (dispatch) =>
    dispatch({
      type: "UPDATE_EMAILS_FOLDERS_LIST",
      data: payload.folders,
    });
};

export const getSignature = (fileName) => {
  return () => {
    return axios.get(`${process.env.REACT_APP_API_URL}admin/emails/signature`, {
      params: { fileName },

      headers: {
        Authorization: getAuthToken(),
      },
    });
  };
};
