/* eslint-disable indent */
import React, { Component } from "react";
import { Label, FormGroup, Button } from "reactstrap";
import { X } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import { Formik, Field, Form } from "formik";
import { Spinner } from "reactstrap";
import * as Yup from "yup";
import axios from "axios";

import ActivationPicker from "../../../components/fts/activation-picker";
import { generateFileName, getUploadUrl } from "../../../utility/uploadFile";

const formSchema = Yup.object().shape({
  quickSearch: Yup.string().required("Required"),
  shortLabel: Yup.string().required("Required"),
  label: Yup.string().required("Required"),
  ticket: Yup.number().required("Required"),
});

class CategoriesListSidebar extends Component {
  state = {
    categoryImage: "",
    inProgress: false,
  };

  addNew = false;

  initialData = {
    label: "",
    icon: "",
    quickSearch: "",
    shortLabel: "",
    status: { label: "Disabled", value: 0 },
    tableLink: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== null && prevProps.data === null) {
      if (this.props.data.icon !== prevState.categoryImage) {
        this.setState({
          categoryImage: this.props.data.icon,
        });
      }
    }
    if (this.props.data === null && prevProps.data !== null) {
      this.setState({
        categoryImage: "",
        inProgress: false,
      });
    }
    this.addNew = false;
  }

  _onImageUpdate = async (e) => {
    const file = e?.target?.files[0];
    const mimeType = file.type;
    const fileName = generateFileName("category", file.type.split("/").pop());

    const url = await getUploadUrl({
      filePath: fileName,
      mimeType,
    });

    const arrayBuffer = await file.arrayBuffer();

    let blob = new Blob([new Uint8Array(arrayBuffer)], {
      type: file.type,
    });

    await axios.put(url, blob, {
      "Content-Type": mimeType,
      headers: {
        "Content-Type": mimeType,
      },
      contentType: mimeType,
    });

    this.setState({
      categoryImage: fileName,
      inProgress: false,
    });
  };

  handleSubmit = (values, actions) => {
    const payload = { ...values, icon: this.state.categoryImage };
    this.props.onCategoryUpdate(payload, actions);
  };

  render() {
    const { show, handleSidebar, data } = this.props;
    const { categoryImage, inProgress } = this.state;
    const statusOptions = [
      { label: "Disabled", value: 0 },
      { label: "Enabled", value: 1 },
      { label: "Inactive", value: 2 },
    ];

    const initValues = data
      ? {
          ...data,
          status: statusOptions.find((itm) => itm.value === data.status),
        }
      : this.initialData;

    return (
      <div
        className={classnames("data-list-sidebar", {
          show: show,
        })}
      >
        <div className="data-list-sidebar-header px-2 d-flex justify-content-between">
          <h4>{data !== null ? "Update category" : "Submit new category"}</h4>
          <X size={20} onClick={() => handleSidebar(false, true)} />
        </div>
        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          validationSchema={formSchema}
          initialValues={initValues}
        >
          {({ errors, touched }) => (
            <Form style={{ height: "100%" }}>
              <PerfectScrollbar
                className="data-list-fields px-2 mt-3"
                options={{ wheelPropagation: false }}
              >
                <FormGroup className="text-center">
                  {inProgress ? (
                    <Spinner color="primary" />
                  ) : (
                    categoryImage.length > 0 && (
                      <img
                        className="img-fluid"
                        src={`${process.env.REACT_APP_IMAGE_URL}/${categoryImage}`}
                        alt={initValues.label}
                      />
                    )
                  )}
                  <div className="d-flex flex-wrap justify-content-between mt-2">
                    <label
                      className="btn btn-flat-primary"
                      htmlFor="update-image"
                      color="primary"
                    >
                      Upload Image
                      <input
                        ref={(ref) => (this.fileInput = ref)}
                        type="file"
                        id="update-image"
                        hidden
                        onChange={this._onImageUpdate}
                      />
                    </label>
                    {categoryImage.length > 0 && (
                      <Button
                        color="flat-danger"
                        onClick={() => {
                          this.fileInput.value = "";
                          this.setState({ categoryImage: "" });
                        }}
                      >
                        Remove Image
                      </Button>
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="cat-id">ID</Label>
                  <Field
                    disabled
                    placeholder="eg. Towing"
                    name="id"
                    className="form-control"
                    id="cat-id"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-label">Label</Label>
                  <Field
                    name="label"
                    placeholder="eg. Towing"
                    id="cat-label"
                    className={`form-control ${
                      errors.label && touched.label && "is-invalid"
                    }`}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-quick">Quick Search Label</Label>
                  <Field
                    name="quickSearch"
                    placeholder="eg. Towing"
                    id="cat-quick"
                    className={`form-control ${
                      errors.quickSearch && touched.quickSearch && "is-invalid"
                    }`}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-quick">Ticket $</Label>
                  <Field
                    name="ticket"
                    placeholder="eg. Towing"
                    id="cat-ticket"
                    className={`form-control ${
                      errors.quickSearch && touched.quickSearch && "is-invalid"
                    }`}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-short">Short Label</Label>
                  <Field
                    name="shortLabel"
                    placeholder="eg. Towing"
                    id="cat-short"
                    className={`form-control ${
                      errors.shortLabel && touched.shortLabel && "is-invalid"
                    }`}
                  />
                </FormGroup>
                <FormGroup>
                  <Field name="status" id="cat-status">
                    {({ form, field: { value, name } }) => {
                      return (
                        <ActivationPicker
                          label="Status"
                          {...{ value, name }}
                          statusOptions={statusOptions}
                          onChange={(option) =>
                            form.setFieldValue("status", option)
                          }
                        />
                      );
                    }}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Label for="cat-table">Table Link</Label>
                  <Field
                    placeholder=""
                    disabled
                    name="tableLink"
                    id="cat-table"
                    className={`form-control ${
                      errors.tableLink && touched.tableLink && "is-invalid"
                    }`}
                  />
                </FormGroup>
              </PerfectScrollbar>
              <div className="data-list-sidebar-footer px-2 d-flex justify-content-end align-items-center mt-1">
                <Button color="primary" type="submit">
                  {data !== null ? "Update" : "Submit"}
                </Button>
                <Button
                  type="button"
                  className="ml-1"
                  color="danger"
                  outline
                  onClick={() => handleSidebar(false, true)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default CategoriesListSidebar;
