// for files filter and default
const fileTypes = [
  { label: "All", value: "" },
  { label: "Png", value: "png" },
  { label: "Jpg", value: "jpg" },
  { label: "Jpeg", value: "jpeg" },
  { label: "Psd", value: "psd" },
  { label: "Gif", value: "gif" },
  { label: "Pdf", value: "pdf" },
  { label: "Ai", value: "ai" },
];
export default fileTypes;

// for listing and promotion
export const listingPromotionFileTypes = [
  { label: "Jpeg", value: "jpeg" },
  { label: "Jpg", value: "jpg" },
  { label: "Png", value: "png" },
  { label: "Gif", value: "gif" },
];

export const accountFileTypes = [
  { label: "Jpeg", value: "jpeg" },
  { label: "Jpg", value: "jpg" },
  { label: "Png", value: "png" },
  { label: "Pdf", value: "pdf" },
  { label: "Psd", value: "psd" },
  { label: "Gif", value: "gif" },
];

// for account, todo and assets
export const otherFileTypes = [
  { label: "Jpeg", value: "jpeg" },
  { label: "Jpg", value: "jpg" },
  { label: "Gif", value: "gif" },
  { label: "Png", value: "png" },
  { label: "Pdf", value: "pdf" },
  { label: "Psd", value: "psd" },
];
