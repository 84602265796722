import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { connect } from "react-redux";
import LinkedLabel from "../linked-label";
import classnames from "classnames";

import { formatRawDate } from "../../../configs/dateConfig";
import { createNewNote } from "../../../redux/actions/notes";

import Avatar from "../../@vuexy/avatar/AvatarComponent";
import FTSBadge from "../fts-badge";

import "./sticky-note.scss";
import FormikInputValue from "../formik/formik-input-value";

const StickyNote = ({ item, types, nested, callToAction, isListing }) => {
  types = types.filter(function (obj) {
    return obj.type === "both" || obj.type === "note";
  });

  types.forEach((obj) => {
    obj.value = obj.description.toLowerCase();
    obj.label = obj.description;
  });

  const { adminOwner, owner, note } = item;
  const [showButton, setShowButton] = useState(false);
  const [showLess, setShowLess] = useState(false);
  const noteData = types.find((itm) => itm.value === item.noteType[0]);
  const noteOwner = item.adminOwner || item.owner;

  useEffect(() => {
    const isLargeText = item.note.length > 135;
    setShowButton(isLargeText);
    setShowLess(isLargeText);
  }, [item.note]);

  if (!item.note || !noteData) {
    return null;
  }

  const noteAdmin = adminOwner ?? owner;

  if (!noteAdmin || !note?.length) return null;

  const openNoteInfo = () => {
    if (!nested) {
      callToAction("note", true, item);
    }
  };

  const isLinked = item.toDoId || item.adId;
  if (isListing) return null;
  return (
    <div className="fts-sticky">
      <div className="fts-sticky__row mrb">
        <Col className="fts-sticky-small-left-col">
          <Avatar
            content={`${noteAdmin.firstName[0]}${noteAdmin.lastName[0]}`.toUpperCase()}
            color={noteData.color}
          />
        </Col>
        <div className="fts-sticky__block between">
          <div className="fts-sticky__name">{`${noteOwner.firstName} ${noteOwner.lastName}`}</div>
          <div className="fts-sticky__date">
            {formatRawDate(item.createdAt)}
          </div>
        </div>

        <div className="fts-sticky__block fts-sticky__right">
          <div className="fts-sticky__flex-label">
            <FTSBadge
              className="sticky-indicator-label ml-1"
              style={{ backgroundColor: "transparent" }}
            >
              Sticky
            </FTSBadge>
            <FTSBadge
              className="sticky-label ml-1"
              style={{ backgroundColor: noteData.color }}
            >
              {noteData.description}
            </FTSBadge>
            <LinkedLabel
              isLinked={isLinked}
              todo={item}
              isAdditionalData={false}
              isActivity={false}
              isListing={isListing}
              note={item.note}
              openSidebar={openNoteInfo}
              type=""
            />
          </div>
        </div>
      </div>

      <div className="fts-sticky__row">
        <div className={classnames({ "plaintext cursor-pointer": !nested })}>
          <span onClick={openNoteInfo}>
            {showLess ? (
              <FormikInputValue>{item.note.slice(0, 135)}...</FormikInputValue>
            ) : (
              <FormikInputValue>{item.note}</FormikInputValue>
            )}
          </span>

          {showButton && (
            <button
              className="more-button"
              type="button"
              onClick={() => setShowLess(!showLess)}
            >
              &nbsp;See {showLess ? "more" : "less"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { types: state.essentialLists.types };
}
export default React.memo(
  connect(mapStateToProps, { createNewNote })(StickyNote)
);
