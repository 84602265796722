/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "reactstrap";
import { MessageCircle, ChevronDown } from "react-feather";
import { connect } from "react-redux";
import classnames from "classnames";
import { toast } from "react-toastify";

import { formatRawDate } from "../../../configs/dateConfig";
import {
  createNewNote,
  deleteNote,
  unpinNote,
} from "../../../redux/actions/notes";
import {
  addUserNoteReply,
  unpinUserNote,
} from "../../../redux/actions/user-notes";
import { getUserData } from "../../../redux/actions/users";
import { createActivity } from "../../../redux/actions/activities";

import { activities } from "../../../constants";
import FTSBadge from "../fts-badge";
import Icon from "../Icons";

import Avatar from "../../@vuexy/avatar/AvatarComponent";
import Replies from "./Replies";
import NewReply from "./NewReply";

import "../../../assets/scss/components/fts/notes.scss";
import LinkedLabel from "../linked-label";
import FormikInputValue from "../formik/formik-input-value";
import moment from "moment";

import { checkPermissions } from "../../../utility/permissionsCheck";

let NOTE_EDITABLE_DAYS = 3;
const NoteSmall = ({
  isActivity,
  isListing,
  data,
  openSidebar,
  adminID,
  createNewNote,
  unpinNote,
  unpinUserNote,
  callToAction,
  reverseOrder,
  nested,
  isNotesList,
  long,
  types,
  additionalData,
  addUserNoteReply,
  handleTodoNoteReplyAdded,
  handleTodoNoteReplyRemoved,
  accountId,
  updateAccountCounts,
  updateTodo,
  userData,
  removeNoteFromList,
  activitiesTypes,
  createActivity,
  adminPermissions,
}) => {
  types = types.filter(function (obj) {
    return obj.type === "both" || obj.type === "note";
  });

  const type = types.filter((item) => {
    return item.value === data.noteType[0];
  });

  types.forEach((obj) => {
    obj.value = obj.description.toLowerCase();
    obj.label = obj.description;
  });
  const noteWrapperRef = useRef(null);
  const { id, adminOwner, owner, createdAt, note, replies } = data;
  const [showButton, setShowButton] = useState(false);
  const [showLess, setShowLess] = useState(false);
  const [replying, setReplying] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const noteData = types.find((itm) => itm.value === data.noteType[0]);

  useEffect(() => {
    const isLargeText = noteWrapperRef.current?.offsetHeight > 70;
    setShowButton(!!isLargeText);
    setShowLess(!!isLargeText);
    if (isLargeText && noteWrapperRef.current) {
      noteWrapperRef.current.firstChild.firstChild.style.height = "70px";
      noteWrapperRef.current.firstChild.firstChild.style.overflow = "hidden";
    }
  }, [note]);

  useEffect(() => {
    if (noteWrapperRef.current) {
      if (showLess && noteWrapperRef.current) {
        noteWrapperRef.current.firstChild.firstChild.style.height = "70px";
        noteWrapperRef.current.firstChild.firstChild.style.overflow = "hidden";
        return;
      }
      noteWrapperRef.current.firstChild.firstChild.style.height = "auto";
      noteWrapperRef.current.firstChild.firstChild.style.overflow = "hidden";
    }
  }, [showLess]);
  if (!data.note || !noteData) {
    return null;
  }

  const openNoteInfo = (noteData) => {
    setShowReplies(false);
    callToAction("note", true, noteData);
  };

  let createdDate = moment(data.createdAt);
  let now = moment();

  let permisionToEditNote =
    (data.adminOwner?.id === userData.id &&
      now.diff(createdDate, "days") < NOTE_EDITABLE_DAYS) ||
    checkPermissions([{ id: 4, subCat: [57] }], adminPermissions);

  const noteAdmin = adminOwner ?? owner;

  if (!noteAdmin || !note?.length) return null;

  const onSubmitReply = (values) => {
    if (inProgress) {
      return;
    }
    setInProgress(true);

    const payload = {
      note: values.reply,
      noteType: "",
      noteTypeId: null,
      parentId: id,
      adminOwnerId: adminID,
    };

    createNewNote(payload)
      .then(async ({ data: { note } }) => {
        setReplying(false);

        if (nested) {
          addUserNoteReply(note);
          handleTodoNoteReplyAdded(note);
        } else {
          addUserNoteReply(note);

          let activityPayload = {
            status: 1,
            noteId: note.id,
            userId: data.userId,
            activityType: activitiesTypes.POSTED_ACCOUNT_NOTE_REPLY,
            activity: activities.postAccountNoteReply
              .replace(
                "{{admin_name}}",
                `${userData.firstName} ${userData.lastName}`,
              )
              .replace("{{noteType}}", note.type),
            adminUserId: userData.id,
            iconName: "NoteActivity",
          };

          await createActivity(activityPayload);
        }

        toast.success("Note reply successfully added.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  const unpinNoteHandler = () => {
    unpinNote(data)
      .then(({ data: { note } }) => {
        unpinUserNote(note);
        accountId && updateAccountCounts(parseInt(accountId));

        toast.success("Note successfully unpinned", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => console.log(err));
  };
  const toggleReply = () => {
    setReplying(!replying);
  };

  const linkedButton =
    (!additionalData && data.toDoId !== null && !isListing && !data.adId) ||
    (!additionalData && data.toDoId !== null && !isListing && data.adId) ||
    (isListing && data.adId && data.toDoId !== null) ||
    (data.adId && !isListing && !data.toDoId);

  const linkedListingNotAdditional =
    !additionalData && data.toDoId !== null && !isListing && data.adId;
  const linkedTodoOnListing = isListing && data.adId && data.toDoId !== null;
  const linkedListingOnAccount = data.adId && !isListing && !data.toDoId;
  const linkedTodoNotListing =
    !additionalData && data.toDoId !== null && !isListing && !data.adId;

  const isEdited = moment(data.createdAt) < moment(data.updatedAt);

  // if (!permissionToRemoveNote) permisionToEditNote = false;
  // if (permissionToRemoveNote) permisionToEditNote = true;

  return (
    <div
      className={classnames("fts-note-small", {
        activity: isActivity,
        nested,
        stickyNote: data.isSticky,
        isNotesList,
        long,
      })}
    >
      {reverseOrder && replies?.length > 0 && (
        <Replies
          openNoteInfo={openNoteInfo}
          updateTodo={updateTodo}
          reverseOrder={reverseOrder}
          data={replies}
          handleTodoNoteReplyRemoved={handleTodoNoteReplyRemoved}
        />
      )}
      <div
        className={classnames("", {
          "fts-note-small-header-container":
            isNotesList &&
            (linkedListingOnAccount ||
              linkedTodoOnListing ||
              linkedTodoNotListing ||
              linkedListingNotAdditional),
        })}
      >
        <LinkedLabel
          isLinked={linkedButton}
          todo={data}
          isAdditionalData={!!additionalData}
          unpinNote={unpinNoteHandler}
          isNotesList={isNotesList}
          {...{ isActivity, isListing, note, openSidebar, type }}
        />

        <div
          className={classnames("fts-note-small-created-by", {
            "created-by-long": isNotesList,
            "note-created-by": long,
          })}
        >
          {!linkedButton && !isNotesList ? (
            <div className="d-flex flex-row align-items-center mb-1">
              {updateTodo && permisionToEditNote && (
                <span
                  className="mr-1"
                  onClick={() => {
                    removeNoteFromList(data);
                  }}
                >
                  <span className="d-flex flex-row align-items-center justify-content-center remove-note-btn">
                    <Icon name="Trash" size="18" />
                    <span>Remove</span>
                  </span>
                </span>
              )}
              <FTSBadge
                className="small-note-label"
                style={{
                  backgroundColor: type[0]?.color,
                }}
              >
                {type[0]?.description}
              </FTSBadge>
            </div>
          ) : null}
          <div className="small-note-user-info">
            <Avatar
              content={`${noteAdmin.firstName[0]}${noteAdmin.lastName[0]}`.toUpperCase()}
              color={noteAdmin.color}
            />
            <div>
              <div className="note-info">
                <span className="owner">
                  {`${noteAdmin.firstName} ${noteAdmin.lastName}`}{" "}
                  {isEdited && <small>(Edited)</small>}
                </span>
                <small>{formatRawDate(createdAt)}</small>
              </div>
            </div>
          </div>

          {!linkedButton && isNotesList ? (
            <FTSBadge
              className="small-note-label"
              style={{
                backgroundColor: type[0]?.color,
              }}
            >
              {type[0]?.description}
            </FTSBadge>
          ) : null}
        </div>
      </div>
      <div
        ref={(e) => (noteWrapperRef.current = e)}
        className={classnames({ "cursor-pointer": !nested })}
      >
        <span
          style={{ display: "block" }}
          onClick={() => {
            if (updateTodo) {
              if (!permisionToEditNote) {
                return;
              }

              if (!data.parentId) {
                callToAction("note", true, data);
              }
            } else {
              openNoteInfo(data);
            }
          }}
        >
          <FormikInputValue>{note}</FormikInputValue>
          {/*<div dangerouslySetInnerHTML={note}></div>*/}
          {/*{showLess ? `${note.slice(0, 135)}...` : note}*/}
        </span>
        {showButton && (
          <button
            type="button"
            className="toggle-text"
            onClick={() => setShowLess(!showLess)}
          >
            &nbsp;See {showLess ? "more" : "less"}
          </button>
        )}
      </div>

      <div>
        {inProgress ? (
          <div className="d-flex justify-content-end">
            <Spinner size="sm" />
          </div>
        ) : (
          <div>
            {replying ? (
              <NewReply {...{ onSubmitReply, toggleReply }} />
            ) : (
              <div className="mt-1">
                <Button
                  className="fts-note-reply-btn p-0"
                  color="flat-primary"
                  type="button"
                  onClick={() => {
                    setReplying(!replying);
                  }}
                >
                  <MessageCircle size={20} color="#01B5F5" />
                  <span
                    style={{
                      color: "#01B5F5",
                      fontWeight: "600",
                    }}
                  >
                    Reply
                  </span>
                </Button>

                {replies?.length > 0 && (
                  <span
                    className="fts-replies-btn"
                    onClick={() => setShowReplies(!showReplies)}
                  >
                    <ChevronDown
                      size={20}
                      className={`menu-toggle-icon ${
                        showReplies ? "opened" : "closed"
                      }`}
                    />
                    {replies?.length} replies
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {!reverseOrder && showReplies && replies?.length > 0 && (
        <Replies
          openNoteInfo={openNoteInfo}
          updateTodo={updateTodo}
          data={replies}
          handleTodoNoteReplyRemoved={handleTodoNoteReplyRemoved}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    types: state.essentialLists.types,
    userData: state.auth.login.values.loggedInUser,
    activitiesTypes: state.essentialLists.activitiesTypes,
    adminPermissions: state.auth.login.permissions,
  };
}

export default connect(mapStateToProps, {
  createNewNote,
  unpinNote,
  unpinUserNote,
  addUserNoteReply,
  getUserData,
  deleteNote,
  createActivity,
})(NoteSmall);
