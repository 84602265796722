import FTSAxios from "../../../axios/fts.instance";

export const getListingsReports = (params) => {
  const { id, page, resultsPerPage, sortModel } = params;
  return () => {
    return FTSAxios.post("/admin/reports/listings", {
      id,
      page,
      resultsPerPage,
      sortModel,
    });
  };
};

export const getAccountReports = (params) => {
  const { id, page, resultsPerPage, sortModel } = params;

  return () => {
    return FTSAxios.post("/admin/reports/account", {
      id,
      page,
      resultsPerPage,
      sortModel,
    });
  };
};

export const generateListingReport = (data) => {
  return () => {
    return FTSAxios.post("admin/reports/generateListingReport", data);
  };
};

export const removeListingReport = (params) => {
  const { reportId } = params;
  return () => {
    return FTSAxios.delete(`/admin/listings/report/${reportId}`);
  };
};

export const removeUserReport = (params) => {
  const { reportId } = params;
  return () => {
    return FTSAxios.delete(`/admin/users/report/${reportId}`);
  };
};

export const updateListingReport = (params) => {
  const { reportId, data } = params;
  return () => {
    return FTSAxios.put(`/admin/listings/report/${reportId}`, data);
  };
};

export const updateUserReport = (params) => {
  const { reportId, data } = params;
  return () => {
    return FTSAxios.put(`/admin/users/report/${reportId}`, data);
  };
};

export const sendReport = (data) => {
  return () => {
    return FTSAxios.put("admin/reports/send", data);
  };
};

export const getListingReport = (listingId, startAt, endAt) => {
  return () => {
    return FTSAxios.get("admin/reports/listing", {
      params: { listingId, startAt, endAt },
    });
  };
};

export const getAccountReport = (accountId, startAt, endAt) => {
  return () => {
    return FTSAxios.get("admin/reports/account", {
      params: { accountId, startAt, endAt },
    });
  };
};

export const getGeneratedAccountReport = ({ reportId }) => {
  return () => {
    return FTSAxios.get(`admin/reports/getGeneratedAccountReport/${reportId}`);
  };
};

export const getGeneratedListingReport = ({ reportId }) => {
  return () => {
    return FTSAxios.get(`admin/reports/getGeneratedListingReport/${reportId}`);
  };
};

export const generateMultipleListingsReport = (data) => {
  return () => {
    return FTSAxios.post("admin/reports/multipleListingsReport", data);
  };
};

export const getAccountReportCounters = (accountId) => {
  return () => {
    return FTSAxios.get("admin/reports/account/counts", {
      params: { accountId },
    });
  };
};

export const getListingReportCounters = (listingId) => {
  return () => {
    return FTSAxios.get("admin/reports/listing/counts", {
      params: { listingId },
    });
  };
};
