import FTSAxios from "../../../axios/fts.instance";

export const fetchNotesAccount = (pageNumber, accountID, offset) => {
  const offsetAmount = offset || "";
  return () => {
    return FTSAxios.get(
      `admin/note/get/user/${pageNumber}/${accountID}/${offsetAmount}`
    );
  };
};

export const fetchStickyNotesAccount = (accountID) => {
  return () => {
    return FTSAxios.get(`admin/note/get/user/sticky/${accountID}`);
  };
};

export const fetchNotesListing = (pageNumber, listingID, offset) => {
  const offsetAmount = offset || "";
  return () => {
    return FTSAxios.get(
      `admin/note/get/listing/${pageNumber}/${listingID}/${offsetAmount}`
    );
  };
};

export const createNewNote = (data) => {
  return () => {
    return FTSAxios.post("admin/note/create", data);
  };
};

export const updateNoteById = (data) => {
  return () => {
    return FTSAxios.put(`admin/note/${data.id}`, data);
  };
};

export const unpinNote = (data) => {
  return () => {
    return FTSAxios.put(`admin/note/${data.id}`, { ...data, userId: null });
  };
};

export const getNote = (noteId) => {
  return () => {
    return FTSAxios.get(`admin/note/get/${noteId}`);
  };
};

export const deleteNote = (noteId) => {
  return () => {
    return FTSAxios.delete(`admin/note/${noteId}`);
  };
};
