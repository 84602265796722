import React, { useState } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import AccountBox from "../../../components/fts/account-box-component";

import "../../../assets/scss/components/fts/data-list-sidebar.scss";
import "../../../assets/scss/components/fts/form-fields.scss";
import TitleRow from "../TitleRow";
import CloseButton from "../CloseButton";
import FTSModal from "../../../components/fts/fts-modal";
import Icon from "../Icons";
import { Button, Col, Label, Input } from "reactstrap";
import { duplicatePromotion } from "../../../redux/actions/promotions";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";

import { activities } from "../../../constants";
import { createActivity } from "../../../redux/actions/activities";

const Sidebar = (props) => {
  const [isOpenDuplicatePromotionModal, setIsOpenDuplicatePromotionModal] =
    useState(false);
  const [numberOfCopies, setNumberOfCopies] = useState("");
  const [numberOfCopiesError, setNumberOfCopiesError] = useState("");

  const {
    action,
    headerTitle,
    defaultHeader = true,
    header = null,
    handleSidebar,
    className,
    show,
    style,
    children,
    showAccountBox,
    accountInfo,
    noMarker,
    actionItem,
    onSubmitQuery,
    setPromotionsCount,
    activitiesTypes,
    userData,
    createActivity,
    onSidebarClosed,
  } = props;
  if (show) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  const updateListing = action === "update_listing";

  const handleDuplicate = async () => {
    const promotionData = actionItem;
    if (!numberOfCopies) {
      return setNumberOfCopiesError("This field is required.");
    }
    if (numberOfCopies > 25) {
      return setNumberOfCopiesError("Number of duplicates is limited to 25.");
    }
    const payload = {
      ...promotionData,
      impressions: 0,
      title: "DUPLICATED+" + rfc3986EncodeURIComponent(promotionData.title),
      description: rfc3986EncodeURIComponent(promotionData.description),
      status: 0,
      numberOfCopies: numberOfCopies,
      icon: "",
      imageMobile: "",
      imageMobileForSort: "",
    };

    try {
      const { data } = await props.duplicatePromotion(payload);
      toggleDuplicatePromotionModal();

      await logDuplicatePromotionsActivity({
        ...payload,
        numberOfCopies,
        createdIds: data.createdIds,
      });

      setPromotionsCount && setPromotionsCount(numberOfCopies);
      onSubmitQuery && onSubmitQuery();
      handleSidebar(false, false, true);

      return toast.success(data.message || "Promotions successfully created", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (e) {
      console.log(e, "ERR");
      const message = e?.response?.statusText
        ? e.response.statusText
        : "Error while creating promotions!";
      return toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const toggleDuplicatePromotionModal = () => {
    setIsOpenDuplicatePromotionModal(!isOpenDuplicatePromotionModal);
    setNumberOfCopiesError("");
    setNumberOfCopies("");
  };

  const logDuplicatePromotionsActivity = (data) => {
    let activityPayload = {
      adId: data["listing.id"],
      userId: data["account.id"],
      promotionId: data.id,
      activityType: activitiesTypes.DUPLICATED_PROMOTION,
      activity: activities.duplicatePromotion
        .replace(
          "{{admin_name}}",
          `${userData.loggedInUser.firstName} ${userData.loggedInUser.lastName}`
        )
        .replace("{{promotionId}}", data.id)
        .replace("{{promotionIds}}", data.createdIds.join(", ")),
      adminUserId: userData.loggedInUser.id,
      iconName: "PromotionActivity",
    };

    return createActivity(activityPayload);
  };

  return (
    <div className="fts-data-list">
      <div
        name="fts-data-list-sidebar"
        className={classnames(
          `fts-data-list-sidebar ${className} ${
            updateListing ? "update_listing" : null
          }`,
          {
            show,
          }
        )}
        style={style}
      >
        {defaultHeader && (
          <div
            className="data-list-sidebar-header px-2 justify-content-between align-items-center"
            style={{
              backgroundColor:
                action === "change_listing_type" ||
                action === "preview_listing_details"
                  ? "#F4F6F8"
                  : "",
            }}
          >
            {action === "proof_update_listing" && (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  {/* <div style={{ color: "#00B5F4" }}>STEP 2/4</div> */}
                  <h3>Review Listing details</h3>
                </div>
                <div className="d-flex align-items-center">
                  <CloseButton onClick={() => handleSidebar(false)} />
                </div>
              </div>
            )}
            {action === "change_listing_type" && (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div style={{ color: "#00B5F4" }}>STEP 1/3</div>
                  <h3>Change Listing Type</h3>
                </div>
                <div className="d-flex align-items-center">
                  <CloseButton onClick={() => handleSidebar(false)} />
                </div>
              </div>
            )}
            {action === "preview_listing_details" && (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div style={{ color: "#00B5F4" }}>STEP 3/3</div>
                  <h3>Preview Listing details</h3>
                </div>
                <div className="d-flex align-items-center">
                  <CloseButton onClick={() => handleSidebar(false)} />
                </div>
              </div>
            )}
            {action === "update_listing" && props.reviewListingData && (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div style={{ color: "#00B5F4" }}>STEP 2/3</div>
                  <h3>Review Listing details</h3>
                </div>
                <div className="d-flex align-items-center">
                  <CloseButton onClick={() => handleSidebar(false)} />
                </div>
              </div>
            )}
            {action === "send_multiple_listings_proof" && (
              <div className="d-flex justify-content-between align-items-center pt-1 pb-1">
                <h3 className="mb-0">Send multiple listing proofs</h3>
                <div className="d-flex align-items-center">
                  <CloseButton onClick={() => handleSidebar(false)} />
                </div>
              </div>
            )}
            {action !== "proof_update_listing" &&
              action !== "send_multiple_listings_proof" &&
              action !== "change_listing_type" &&
              action !== "preview_listing_details" &&
              (action !== "update_listing" ||
                (action === "update_listing" && !props.reviewListingData)) && (
                <TitleRow
                  className={`mb-0 ${
                    action === "listing_update_history" ||
                    action === "account_update_history" ||
                    action === "admin_notifications"
                      ? "pt-1 pb-1"
                      : ""
                  }`}
                  title={headerTitle}
                  noMarker={noMarker}
                >
                  <div className="text-right">
                    {showAccountBox &&
                    (action === "todo" || action === "requestedChange") &&
                    (accountInfo?.company_name || accountInfo?.companyName) ? (
                      <div className="display-inline-block ml-auto">
                        <AccountBox accountInfo={accountInfo} />
                      </div>
                    ) : null}
                    {action === "promotion" && actionItem?.id && (
                      <span
                        className="duplicate-promotion-button"
                        onClick={() => {
                          toggleDuplicatePromotionModal();
                        }}
                      >
                        <Icon name="Duplicate" />
                        Duplicate promotion
                      </span>
                    )}

                    <CloseButton
                      onClick={() => {
                        if (onSidebarClosed) {
                          onSidebarClosed();
                        }
                        handleSidebar(false);
                      }}
                    />
                  </div>
                </TitleRow>
              )}
          </div>
        )}
        {!defaultHeader && header}

        {show && children}
      </div>
      <div
        className={classnames("fts-data-list-overlay", {
          show,
        })}
        onClick={() => {
          if (onSidebarClosed) {
            onSidebarClosed();
          }
          handleSidebar(false);
        }}
      />
      <FTSModal
        isOpen={isOpenDuplicatePromotionModal}
        modalClasses={"fts-modal-new"}
        centered={true}
        closeModal={toggleDuplicatePromotionModal}
        footerCTA={() => {
          return (
            <>
              <Button onClick={toggleDuplicatePromotionModal} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleDuplicate} color="primary">
                Duplicate
              </Button>
            </>
          );
        }}
        headerCTA={() => {
          return (
            <div className={"d-flex"}>
              <h4> Duplicate promotion </h4>
              <Col className="text-right">
                <CloseButton onClick={toggleDuplicatePromotionModal} />
              </Col>
            </div>
          );
        }}
        title={"Duplicate promotion"}
      >
        <div>
          <p className={"mb-3"}>
            How many times do you want to duplicate this promotion?
          </p>
          <Label for="cat-label mb-2">Number of duplicates*</Label>
          <div>
            <Input
              type="number"
              valid={false}
              className="w-50"
              onFocus={() => setNumberOfCopiesError("")}
              value={numberOfCopies}
              placeholder="Number of duplicates"
              onChange={({ target }) => setNumberOfCopies(target.value)}
            />
            <div className="formik-validation-error w-100 mb-2 ">
              {numberOfCopiesError}
            </div>
          </div>
          <div
            style={{ height: 1, backgroundColor: "#E3E7EB", width: "100%" }}
          />
        </div>
      </FTSModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, {
  duplicatePromotion,
  createActivity,
})(Sidebar);
