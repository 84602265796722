/* eslint-disable indent */
import React, { PureComponent } from "react";
import capitalize from "../../../utility/capitalize";

import "../../../assets/scss/info-labels.scss";

class InfoLabels extends PureComponent {
  render() {
    const { labels } = this.props;

    return (
      <div className="info-labels">
        {labels?.map((itm, index) => {
          if (itm?.label === "Label:") {
            switch (itm?.value) {
              case 1:
                itm.value = "Free";
                break;
              case 2:
                itm.value = "Lead";
                break;
              case 3:
                itm.value = "Customer";
                break;
              case 4:
                itm.value = "Trial";
                break;
              case 5:
                itm.value = "Suspended";
                break;
              case 6:
                itm.value = "Closed";
                break;
              case 7:
                itm.value = "Spammer";
                break;
              case 8:
                itm.value = "Paid";
                break;
              case 9:
                itm.value = "Unpaid";
                break;
              case 10:
                itm.value = "Project";
                break;

              case 11:
                itm.value = "Other";
                break;

              default:
                itm.value = "Not assigned";
                break;
            }
          }

          if (itm?.label === "Lead status:") {
            switch (itm?.value) {
              case 1:
                itm.value = "Hot";
                break;
              case 2:
                itm.value = "Warm";
                break;

              default:
                itm.value = "None";
                break;
            }
          }

          if (itm?.label === "Assigned to:") {
            itm.value = itm?.value?.length
              ? itm?.value?.map((itm) => itm?.label)?.join(", ")
              : "-";
          }

          if (itm?.type === "radio" && itm?.value === 0) {
            return null;
          }

          if (itm?.label === "Lead status:" && itm?.value === "None") {
            return null;
          }

          if (!itm) return null;

          return (
            <div
              key={index}
              className={`info-labels__box info-labels__box--${itm?.type} ${
                itm?.type === "radio" && itm?.value ? "active" : ""
              }`}
            >
              <div>
                {itm?.label && itm?.type !== "radio" && (
                  <div
                    className={`info-labels__box__label info-labels__box__label--${itm?.type}`}
                  >
                    {itm?.label}
                  </div>
                )}
                {itm?.label && itm?.type === "radio" && (
                  <div
                    className={`info-labels__box__label info-labels__box__label--${itm?.type}`}
                  >
                    {itm?.label}
                  </div>
                )}
                {itm?.type !== "radio" && Array.isArray(itm?.value) && (
                  <div className="info-labels__box__value">
                    {itm?.value.length ? itm?.value : "-"}
                  </div>
                )}
                {itm?.type !== "radio" && typeof itm?.value === "string" && (
                  <div
                    className={`info-labels__box__value  ${
                      itm?.value === "Hot" ? "info-labels__box__value__hot" : ""
                    } ${
                      itm?.value === "Warm"
                        ? "info-labels__box__value__warm"
                        : ""
                    }`}
                  >
                    {itm?.value === "Hot" || itm?.value === "Warm"
                      ? itm?.value.toUpperCase()
                      : capitalize(itm?.value)}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default InfoLabels;
