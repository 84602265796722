import React, { Component } from "react";

import { connect } from "react-redux";
import { Button, Spinner } from "reactstrap";
import FTSModal from "../fts-modal";
import CloseButton from "../CloseButton";
import Icon from "../Icons";
import { updateSelectedListings } from "../../../redux/actions/ads";

import "../../../assets/scss/components/fts/listing-update-confirmation-modal.scss";

class ListingUpdateConfirmation extends Component {
  closeModal = () => {
    this.props.toggleListingUpdateConfirmationModal();
    this.props.updateSelectedListings([]);
  };

  confirmModal = () => {
    this.props.confirmMultipleListingsUpdate();
  };

  render() {
    const {
      isOpen,
      title,
      closeModal,
      onClose,
      toggleListingUpdateConfirmationModal,
      toggleMultipleListingsModal,
    } = this.props;

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-reassign-modal"}
        centered={true}
        style={{ maxWidth: "665px" }}
        footerCTA={() => {
          return (
            <div className="update-listing-confrimation-modal-cta">
              <Button
                className="back-button"
                onClick={() => {
                  toggleListingUpdateConfirmationModal();
                  toggleMultipleListingsModal();
                }}
                color="secondary"
              >
                <Icon name="ArrowLeft" />
                <div>Back</div>
              </Button>
              <div>
                <span className="mr-1">
                  <Button
                    onClick={() => {
                      this.closeModal();
                    }}
                    color="white"
                  >
                    Cancel
                  </Button>
                </span>
                <Button
                  onClick={() => {
                    this.confirmModal();
                  }}
                  color="primary"
                >
                  Confirm
                </Button>
              </div>
            </div>
          );
        }}
      >
        <div className="reassign-modal-body">
          <span className="reassign-modal-body-wrapper">
            <span className="reassign-modal-title">
              <div className="d-flex update-listing-confirmation-title">
                <Icon name="ListingUpdate" size={56} />
                <div>
                  <span className="d-flex align-center">
                    <h3>Listing update confirmation</h3>

                    {this.props.inProgress && (
                      <div className="confirmation-spinner-wrapper">
                        <Spinner size="sm" />
                      </div>
                    )}
                  </span>

                  <span>
                    Are you sure you want to make changes to selected listings?
                  </span>
                </div>
              </div>

              <CloseButton onClick={this.closeModal} />
            </span>
          </span>
        </div>
      </FTSModal>
    );
  }
}

export default connect(null, {
  updateSelectedListings,
})(ListingUpdateConfirmation);
