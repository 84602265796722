import Cookies from "universal-cookie";

export const AUTH_KEY = "x-auth";
export const EXP_KEY = "x-exp";
export const REFRESH_KEY = "x-refresh";
export const TEMPORARY_TOKEN = "temporary-token";

const FTSCookies = new Cookies();

export function getAuthToken() {
  const token = FTSCookies.get(AUTH_KEY);
  return `Bearer ${token}`;
}

export default FTSCookies;
