import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import createSagaMiddleware from "redux-saga";
import { history } from "../../history";
import rootSaga from "../sagas";
import { routerMiddleware } from "connected-react-router";

const cRouterMiddleware = routerMiddleware(history);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();

  let middlewares = [
    sagaMiddleware,
    cRouterMiddleware,
    thunk,
    createDebounce(),
  ];

  // if (process.env.NODE_ENV !== "production") {
  //   middlewares.unshift(require("redux-immutable-state-invariant").default());
  // }

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  sagaMiddleware.run(rootSaga);
  return store;
}
