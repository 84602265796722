import React from "react";
import { FtsCheckboxContainer } from "../../../FtsCheckbox/FtsCheckboxContainer";
import { FtsCheckbox } from "../../../FtsCheckbox/FtsCheckbox";
import { InputLabel } from "../InputLabel";
const Checkbox = (props) => (React.createElement(FtsCheckboxContainer, { ...props },
    React.createElement(FtsCheckbox, { checked: props.checked },
        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", fill: "none" },
            React.createElement("path", { d: "M13.3334 4L6.00008 11.3333L2.66675 8", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }))),
    React.createElement(InputLabel, { label: props.label, ml: "xs", required: props.required })));
export default Checkbox;
