/* eslint-disable indent */

import React, { useState, useEffect } from "react";

import FTSTableHeader from "./FTSTableHeader";
import FTSTableBody from "./FTSTableBody";
import FTSTableFooter from "./FTSTableFooter";

import moment from "moment";

import "./style.css";
import "../../../assets/scss/plugins/tables/_agGridStyleOverride.scss";
// import newAccounts from "../../../views/pages/new-accounts";

const getDevice = (currentWidth) => {
  return currentWidth < 576
    ? "small"
    : currentWidth < 768
    ? "phone"
    : currentWidth < 992
    ? "tablet"
    : "desktop";
};

const FTSDataTable = (props) => {
  let {
    noPagination,
    // FTSTableHeader props
    filterSize,
    searchVal,
    updateSearchQuery,
    searchValType,
    pageSizePropName,
    pageSizes,
    onCreateNewPress,
    filterChips,
    longSearch,
    isListing,
    searchPlaceholder,
    accountId,

    // FTSTableBody props
    onSortChanged,
    getRowHeight,
    rowData,
    columnDefs,
    defaultColDef,
    onGridReadyInit,
    saveState,
    onRowClicked,
    getSelectedAccounts,
    getSelectedActiveLeads,
    overlayNoRowsTemplate = "<span>No results for this specific search.</span>",
    colResizeDefault = "shift",
    suppressPaginationPanel = true,
    animateRows = true,
    getSelectedInvoices,
    rowSelection,
    pagination = true,
    resizable = true,
    pivotPanelShow = "always",
    renewalsTab,
    // upcomingTab,
    updatesTab,
    salesTab,
    promoCodesTab,
    invoicesTab,
    emailsTab,
    getTo,
    reporting,

    // FTSTableFooter props
    pageCount,
    currentPage,
    totalRecords,
    onPageChange,

    // shared props
    currentPageSize,

    // other props
    resetApi,
    isSearch,
    tableName,
    activeAccount,
    totalAmount,
    newListings,
    upgrades,
    globalNewAccounts,
    onScroll = () => {},
  } = props;

  const [deviceType, setDeviceType] = useState(getDevice(window.innerWidth));

  const updateDeviceType = () => {
    setDeviceType(getDevice(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", updateDeviceType);
    return () => {
      window.removeEventListener("resize", updateDeviceType);
      resetApi(tableName);
    };
  }, []);

  const headerProps = {
    totalAmount,
    searchVal,
    updateSearchQuery,
    searchValType,
    onCreateNewPress,
    tableName,
    searchPlaceholder,
    longSearch,
    isListing,
    filterChips,
    accountId,
  };

  // const upcomingRowClassRules = {
  //   "row-renewals-red": (params) => {
  //     return params.data.excludeRenewalEmails || !params.data.email;
  //   },
  // };

  const renewalsRowClassRules = {};

  const updatesRowClassRules = {
    "row-update-red": (params) => {
      const now = moment();
      const days = now.diff(moment(params.data.updatedAt), "days");
      return days > getTo() && tableName === "updates";
    },
  };

  // const promoCodesRowClassRules = {
  //   "row-suspended": (params) => {
  //     const now = moment();
  //     return params.data.status === 0 || now.isAfter(moment(params.data.endAt));
  //   },
  //   "row-active": (params) => {
  //     const now = moment();
  //     return (
  //       params.data.status === 1 && now.isBefore(moment(params.data.endAt))
  //     );
  //   },
  // };

  const rowClassRules = {
    "row-active": (params) => {
      if (params.data.invoiceNumber) return;

      return (
        params.data.statusString === "Active" ||
        params.data.status === 1 ||
        params.data.status === "Active" ||
        params.data.statusString === "Enabled"
      );
    },

    "row-draft": (params) => {
      if (params.data.invoiceNumber) return;

      return params.data.status === 3;
    },

    "row-disabled": (params) => {
      if (params.data.invoiceNumber) return;

      return (
        params.data.statusString === "Disabled" ||
        (params.data.status === 0 && !tableName === "promotion")
      );
    },

    "row-gray-out": (params) => {
      const startAt = moment(params.data.validFrom);
      const now = moment();
      if (params.data.invoiceNumber) return;

      return (
        (params.data.status === "Inactive" || params.data.status === 0) &&
        tableName === "promotion" &&
        startAt > now
      );
    },

    "row-warning": (params) => {
      const startAt = moment(params.data.validFrom);
      const endAt = moment(params.data.validTo);
      const now = moment();
      if (params.data.invoiceNumber) return;

      return (
        (params.data.status === "Inactive" || params.data.status === 0) &&
        startAt < now &&
        endAt > now
      );
    },

    "row-suspended": (params) => {
      if (params.data.invoiceNumber) return;
      return (
        params.data.statusString === "Inactive" ||
        params.data.status === 2 ||
        moment(params.data.validTo) < moment()
      );
    },
  };

  let rowClassRulesTemp = rowClassRules;

  if (renewalsTab) {
    rowClassRulesTemp = renewalsRowClassRules;
  }

  // if (upcomingTab) {
  //   rowClassRulesTemp = upcomingRowClassRules;
  // }

  if (updatesTab) {
    rowClassRulesTemp = updatesRowClassRules;
  }

  if (promoCodesTab) {
    rowClassRulesTemp = null;
  }

  if (salesTab) {
    rowClassRulesTemp = null;
  }

  if (invoicesTab) {
    rowClassRulesTemp = null;
  }

  if (emailsTab) {
    rowClassRulesTemp = null;
  }

  if (reporting) {
    rowClassRulesTemp = null;
  }

  if (activeAccount) {
    rowClassRulesTemp = {
      "row-active": (params) => {
        return !params.data.activities.length;
      },
    };
  }

  if (newListings) {
    rowClassRulesTemp = {
      "row-read": (params) => {
        return params.data.adminLastViewAt > params.data.createdAt;
      },

      "row-unread": (params) => {
        return params.data.adminLastViewAt <= params.data.createdAt;
      },
    };
  }

  if (globalNewAccounts) {
    rowClassRulesTemp = {
      "row-read": (params) => {
        return params.data.adminLastViewAt > params.data.createdAt;
      },

      "row-unread": (params) => {
        return params.data.adminLastViewAt <= params.data.createdAt;
      },
    };
  }

  if (upgrades) {
    rowClassRulesTemp = {
      "row-read": (params) => {
        return params.data.adminLastViewAt > params.data.upgradedAt;
      },

      "row-unread": (params) => {
        return params.data.adminLastViewAt <= params.data.upgradedAt;
      },
    };
  }

  const bodyProps = {
    tableName,
    getRowHeight,
    rowData,
    columnDefs,
    rowSelection,
    getSelectedInvoices,
    defaultColDef,
    onGridReady: onGridReadyInit,
    saveState: saveState,
    onRowClicked,
    getSelectedAccounts,
    getSelectedActiveLeads,
    colResizeDefault,
    suppressPaginationPanel,
    animateRows,
    pagination,
    overlayNoRowsTemplate,
    pivotPanelShow,
    rowHeight: 56,
    paginationPageSize: currentPageSize,
    resizable,
    onSortChanged,
    rowClassRules: rowClassRulesTemp,
  };

  const footerProps = {
    filterSize,
    pageSizePropName,
    currentPageSize,
    pageSizes,
    pageCount,
    currentPage,
    totalRecords,
    onPageChange,
    deviceType,
    tableName,
  };
  return (
    <>
      <div
        className="ag-theme-material ag-grid-table"
        onScroll={() => {
          onScroll();
        }}
      >
        {!isSearch && <FTSTableHeader {...headerProps} />}
        <FTSTableBody {...bodyProps} />
      </div>
      {!noPagination && <FTSTableFooter {...footerProps} />}
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.currentPageSize === nextProps.currentPageSize &&
    prevProps.searchVal === nextProps.searchVal &&
    prevProps.totalRecords === nextProps.totalRecords &&
    prevProps.currentPage === nextProps.currentPage &&
    prevProps.rowData === nextProps.rowData &&
    prevProps.filterChips === nextProps.filterChips &&
    prevProps.pageCount === nextProps.pageCount
  );
};

export default React.memo(FTSDataTable, areEqual);
