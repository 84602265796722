import React, { PureComponent } from "react";
import { Button } from "reactstrap";

import FTSModal from "../fts-modal";

class ConfirmationModal extends PureComponent {
  render() {
    const { isOpen, title, closeModal, confirmModal, message, onClose } =
      this.props;

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        footerCTA={() => {
          return (
            <>
              <Button onClick={closeModal} color="secondary">
                Cancel
              </Button>
              <Button onClick={confirmModal} color="primary">
                Confirm
              </Button>
            </>
          );
        }}
      >
        {message}
      </FTSModal>
    );
  }
}

export default ConfirmationModal;
