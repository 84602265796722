/* eslint-disable indent */
import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import classnames from "classnames";

import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";

import capitalize from "../../../utility/capitalize";

import "../../../assets/scss/plugins/tables/_agGridStyleOverride.scss";
import "../../../assets/scss/pages/users.scss";

const DATA_KEY_SUFFIX = "Data";
const FULL_DATA_KEY_SUFFIX = "FullData";
const TOTAL_RECORDS_PREFIX = "totalRecords";

class FTSTableGroup extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.onRef) {
      this.props.onRef(this);
    }

    this.keys = [
      ...(Array.isArray(props.tableLayouts)
        ? props.tableLayouts
        : Object.keys(props.tableLayouts)),
    ];

    this.tableLayouts = props.tableLayouts;

    this.state = {
      searchQuery: props.initialSearchQuery,
      searchVal: "",
      defaultColDef: {
        sortable: true,
      },
      columnDefs: props.columnDefs,
      activeTab: this.props.activeTab || this.keys[0],
      inProgress: false,
      ...this.keys.reduce(
        (data, key) => ({
          ...data,
          [`${key}${FULL_DATA_KEY_SUFFIX}`]:
            this.tableLayouts[key]?.initialTableData ?? null,
        }),
        {},
      ),
      ...this.keys.reduce(
        (data, key) => ({
          ...data,
          [`${key}${DATA_KEY_SUFFIX}`]:
            this.tableLayouts[key]?.initialTableData ?? null,
        }),
        {},
      ),
      ...this.keys.reduce(
        (data, key) => ({
          ...data,
          [`page${capitalize(key, false)}`]:
            this.tableLayouts[key]?.initialPage ?? 1,
        }),
        {},
      ),
      ...this.keys.reduce(
        (data, key) => ({
          ...data,
          [`sort${capitalize(key, false)}`]:
            this.tableLayouts[key]?.initialSort ?? "",
        }),
        {},
      ),
      ...this.keys.reduce(
        (data, key) => ({
          ...data,
          [`pageSize${capitalize(key, false)}`]:
            this.tableLayouts[key]?.initialPageSize ?? 10,
        }),
        {},
      ),
      ...this.keys.reduce(
        (data, key) => ({
          ...data,
          [`pageCount${capitalize(key, false)}`]:
            this.tableLayouts[key]?.initialPageCount ?? 0,
        }),
        {},
      ),
      ...this.keys.reduce(
        (data, key) => ({
          ...data,
          [`${TOTAL_RECORDS_PREFIX}${capitalize(key, false)}`]:
            this.tableLayouts[key]?.initialTotalRecords ?? 0,
        }),
        {},
      ),
      ...this.keys.reduce(
        (data, key) => ({
          ...data,
          [`searchVal${capitalize(key, false)}`]:
            this.tableLayouts[key]?.initialSearchVal ?? "",
        }),
        {},
      ),
    };

    this.apis = {};
    this.columnsApis = {};
    this.pageSizes = [...(props.pageSizes ?? [])];
  }

  componentDidMount() {
    this.onSubmitQuery();
  }

  getGridApi = (key) => {
    return this.apis[key];
  };

  setGridApi = (key, value) => {
    this.apis[key] = value;
  };

  setGridColumnApi = (key, value) => {
    this.columnsApis[key] = value;
  };

  allApisReady = () => {
    return this.keys.reduce(
      (isReady, key) => isReady && !!this.getGridApi(key),
      true,
    );
  };

  saveState = (key) => {
    let columnState = this.columnsApis[key].getColumnState();
    localStorage.setItem(key, JSON.stringify(columnState));
  };

  onGridReady = (params, key) => {
    this.setGridApi(key, params.api);
    this.setGridColumnApi(key, params.columnApi);

    if (this.props.tableLayouts[key].onGridApiReady) {
      this.props.tableLayouts[key].onGridApiReady(params?.api, key);
    }

    if (localStorage.getItem(key) !== null) {
      this.columnsApis[key].setColumnState(
        JSON.parse(localStorage.getItem(key)),
      );
    }
  };

  resetApi = (key) => {
    this.setGridApi(key, null);
  };

  handleSearchQueryChange = (e) => {
    this.setState({
      searchQuery: {
        ...this.state.searchQuery,
        [e.target.name]: e.target.value,
      },
    });
  };
  setTableState = (state, callback) => {
    this.setState(state, callback);
  };

  onSubmitQuery = (keys = []) => {
    let keysForSubmit = this.keys;
    let priceIds = {
      premium: 0,
      standard: 1,
      basicPlus: 2,
      basic: 3,
      free: 4,
    };

    if (keys && keys.length) keysForSubmit = keys;
    const { inProgress } = this.state;

    if (inProgress) {
      return;
    }

    this.setState({ inProgress: true });

    const { saveData, columnDefs } = this.props;

    const promises = keysForSubmit.map((key) => {
      return this.props.tableLayouts[key].getTableData(
        key,
        priceIds[key],
        this.state,
      );
    });

    const apiAvailable = this.allApisReady();

    if (apiAvailable) {
      keysForSubmit.forEach((key) =>
        this.getGridApi(key)?.showLoadingOverlay(),
      );
    }
    Promise.all(promises)
      .then((results) => {
        if (apiAvailable) {
          keysForSubmit.forEach((key) => this.getGridApi(key)?.hideOverlay());
        }

        const updatedFullData = keysForSubmit.reduce(
          (data, key, index) => ({
            ...data,
            [`${key}${FULL_DATA_KEY_SUFFIX}`]: this.tableLayouts[key]
              ?.formatRows
              ? this.tableLayouts[key]?.formatRows(results[index]?.data?.rows)
              : results[index]?.data?.rows,
          }),
          {},
        );

        const updatedData = keysForSubmit.reduce((data, key, index) => {
          let rowData = results[index]?.data?.rows;

          if (this.tableLayouts[key]?.formatRows) {
            rowData = this.tableLayouts[key]?.formatRows(rowData);
          }

          if (this.tableLayouts[key]?.applyFilters) {
            rowData = this.tableLayouts[key]?.applyFilters(rowData);
          }

          return {
            ...data,
            [`${key}${DATA_KEY_SUFFIX}`]: rowData,
          };
        }, {});

        if (saveData) {
          const totalCount = Object.values(results).reduce(
            (totalResults, response) => {
              return totalResults + (response?.data?.count ?? 0);
            },
            0,
          );

          saveData(updatedData, totalCount);
        }

        const newState = {
          inProgress: false,
          columnDefs,
        };
        this.setState({
          ...newState,
          ...updatedFullData,
          ...updatedData,
          ...keysForSubmit.reduce(
            (data, key, index) => ({
              ...data,
              [`${TOTAL_RECORDS_PREFIX}${capitalize(key, false)}`]:
                results[index]?.data?.count,
              [`pageCount${capitalize(key, false)}`]: Math.ceil(
                results[index]?.data?.count /
                  this.state[`pageSize${capitalize(key, false)}`],
              ),
            }),
            {},
          ),
        });
      })
      .catch((err) => {
        this.setState({ inProgress: false });
        console.log("====================================");
        console.log("err = ", err);
        console.log("====================================");
      });
  };

  debounce = setTimeout(() => {}, 300);

  updateSearchQuery = (type, val, key) => {
    console.log("key: ", key);
    if (this.props.handleSearch) {
      this.setState(
        {
          searchVal: val,
          [`searchVal${capitalize(key, false)}`]: val,
        },
        () => {
          this.props.handleSearch(type, val, key);
        },
      );
      return;
    }

    if (!type && !key) {
      this.keys.forEach((key) => {
        if (this.allApisReady()) {
          this.getGridApi(key).setQuickFilter(val);
        }
      });
      return;
    }

    const searchValType = type ?? `searchVal${capitalize(key, false)}`;
    const api = this.getGridApi(key);

    if (api) {
      // api.setQuickFilter(val);
      this.setState({
        [searchValType]: val,
      });
    }
  };

  filterSize = (type, val) => {
    this.setState(
      {
        [type]: val,
      },
      () => {
        this.onSubmitQuery();
      },
    );
  };

  onPageChanged = ({ selected }, key) => {
    console.log("key: ", key);
    const pageNum = selected + 1;
    this.setState({ [`page${capitalize(key, false)}`]: pageNum }, () => {
      this.onSubmitQuery([key]);
    });
    this.props.onPageChanged &&
      this.props.onPageChanged({ [`page${capitalize(key, false)}`]: pageNum });
  };

  getFullRowData = (key) => {
    return this.state[`${key}${FULL_DATA_KEY_SUFFIX}`];
  };

  getRowData = (key) => {
    return this.state[`${key}${DATA_KEY_SUFFIX}`];
  };

  setRowData = (key, rowData) => {
    this.setState({ [`${key}${DATA_KEY_SUFFIX}`]: [...rowData] });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
      if (this.props.onTabChanged) {
        this.props.onTabChanged(tab);
      }
    }
  };

  generateTabs = () => {
    const tabs = this.keys.map((key) => ({
      label: this.tableLayouts[key]?.tabLabel ?? capitalize(key, false),
      type: key,
      total: this.state[`${TOTAL_RECORDS_PREFIX}${capitalize(key, false)}`],
    }));

    return tabs.map((itm) => (
      <NavItem key={itm.type}>
        <NavLink
          id="price-padding"
          className={classnames({
            active: this.state.activeTab === itm.type,
          })}
          onClick={() => {
            this.toggle(itm.type);
          }}
        >
          {itm.label} ({Number(itm.total.toFixed(1)).toLocaleString()})
        </NavLink>
      </NavItem>
    ));
  };

  renderTables = (multipleTables) => {
    return this.keys.map((key) => {
      const capitalizedKey = capitalize(key, false);
      const props = {
        tableName: key,
        isSearch: !this.tableLayouts[key]?.hasSearch ?? true,
        onCreateNewPress:
          this.tableLayouts[key]?.onCreateNew ?? this.props.onCreateNew,
        pageSizes: this.tableLayouts[key]?.pageSizes ?? this.pageSizes,
        totalRecords:
          this.state[`${TOTAL_RECORDS_PREFIX}${capitalizedKey}`] || 0,
        currentPageSize: this.state[`pageSize${capitalizedKey}`],
        searchValType: `searchVal${capitalizedKey}`,
        pageSizePropName: `pageSize${capitalizedKey}`,
        searchVal: this.state[`searchVal${capitalizedKey}`],
        searchPlaceholder: this.props.searchPlaceholder,
        currentPage: this.state[`page${capitalizedKey}`],
        pageCount: this.state[`pageCount${capitalizedKey}`],
        updatesTab: this.props.updatesTab,
        salesTab: this.props.salesTab,
        promoCodesTab: this.props.promoCodesTab,
        activeAccount: this.props.activeAccount,
        invoicesTab: this.props.invoicesTab,
        getTo: this.props.getTo,
        saveState: this.saveState,
        onGridReadyInit: (params) => {
          this.onGridReady(params, key);
        },
        filterSize: this.filterSize,
        updateSearchQuery: this.updateSearchQuery,
        rowData: this.state[`${key}${DATA_KEY_SUFFIX}`],
        onPageChange: (param) => {
          this.onPageChanged(param, key);
        },
        resetApi: this.resetApi,
        onRowClicked: this.tableLayouts[key]?.onRowClicked ?? this.onRowClicked,
        getSelectedActiveLeads:
          this.tableLayouts[key]?.getSelectedActiveLeads ??
          this.getSelectedActiveLeads,
        columnDefs: this.tableLayouts[key]?.columnDefs ?? this.state.columnDefs,
        defaultColDef:
          this.tableLayouts[key]?.defaultColDef ?? this.state.defaultColDef,
        onSortChanged: this.props.onSortChanged,
        rowSelection: this.props.rowSelection,
        overlayNoRowsTemplate: this.props.overlayNoRowsTemplate,
      };

      if (!multipleTables) {
        return <FTSDataTable key={key} {...props} />;
      }

      return (
        <TabPane key={key} tabId={key}>
          <FTSDataTable {...props} />
        </TabPane>
      );
    });
  };

  onRowClicked = (event) => {
    this.props.onRowClicked(event);
  };

  render() {
    const { activeTab, inProgress } = this.state;
    const {
      childrenPos = "above",
      withinCard,
      children,
      headerCTA,
      tableHeader,
    } = this.props;

    const mainDataIsNull =
      this.state[`${this.keys[0]}${FULL_DATA_KEY_SUFFIX}`] === null;

    const dataFields = Object.keys(this.state)
      .filter((key) => key.endsWith("Data"))
      .map((key) => this.state[key]);

    const above = childrenPos === "above";
    const below = childrenPos === "below";

    return (
      <>
        {above && !withinCard && children}
        <Card innerRef={this.props.wrapperRef}>
          <CardBody className="fts-account-tab-wrapper">
            {above && withinCard && children}
            <div className="nav-horizontal">
              {tableHeader && (
                <div className="fts-table-group-header">
                  <span className="fts-table-group-header-label">
                    {tableHeader}
                  </span>
                </div>
              )}
              <Row className="app-user-list">
                {inProgress && mainDataIsNull && (
                  <Col sm="12">
                    <Spinner size="sm" className="float-right" />
                  </Col>
                )}

                {dataFields.some((field) => field !== null) ? (
                  <Col sm="12">
                    {this.keys?.length && this.keys?.length <= 1 ? (
                      this.renderTables(false)
                    ) : (
                      <>
                        <Nav tabs>
                          {this.generateTabs()}
                          {headerCTA && headerCTA()}
                        </Nav>

                        <TabContent activeTab={activeTab}>
                          {this.renderTables(true)}
                        </TabContent>
                      </>
                    )}
                  </Col>
                ) : null}
              </Row>
            </div>
            {below && withinCard && children}
          </CardBody>
        </Card>
        {below && !withinCard && children}
      </>
    );
  }
}

export default FTSTableGroup;
