/* eslint-disable indent */
/* eslint-disable react/display-name */
import React from "react";
import { formatRawDate, toMomentDate } from "../../../configs/dateConfig";
import FTSBadge from "../../../components/fts/fts-badge";
import Icon from "../../../../src/components/fts/Icons";
import { ContextMenuTrigger } from "react-contextmenu";
import moment from "moment";

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
      <div className="ag-date-table__time">
        {toMomentDate(date).format("[at] h:mm a")}
      </div>
    </div>
  );
};

const iconProps = {
  size: 15,
};

export const defineColumnsNational = (
  collectDataForCopy,
  totalAuthorizedLocations,
  totalAuthorizedListings,
  // togglePopover,
) => {
  return [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      minWidth: 120,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.node.data.id}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.node.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Title",
      field: "titleForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 350,
      flex: 2,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.title}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data.title}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Start at",
      field: "validFrom",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={moment.utc(params.data.validFrom).format("lll")}
              collect={collectDataForCopy}
              id="contextMenuPromotions"
            >
              <span>
                <TableDate date={moment.utc(params.data.validFrom)} />
              </span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "End at",
      field: "validTo",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={moment.utc(params.data.validTo).format("lll")}
              collect={collectDataForCopy}
              id="contextMenuPromotions"
            >
              <span>
                <TableDate date={moment.utc(params.data.validTo)} />
              </span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },

    {
      headerName: "Promotion Image",
      field: "imageForSort",
      unSortIcon: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        if (!params?.data?.image) {
          return <div>No image</div>;
        }
        return (
          <ContextMenuTrigger
            data={params.data.image}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.data.image}`}
            >
              Image
              <span style={{ paddingLeft: "12px" }}>
                <Icon name="Eye" {...iconProps} />
              </span>
            </a>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Impressions",
      field: "impressions",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.impressions}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data.impressions}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Accounts",
      field: "accounts",
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: () => {
        return <div>{totalAuthorizedLocations} accounts</div>;
      },
    },
    {
      headerName: "Listings",
      field: "listingCounts",
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: () => {
        return <div>{totalAuthorizedListings} listings</div>;
      },
    },
    {
      headerName: "Status",
      field: "statusForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let statuses = {
          Active: "success",
          Expired: "danger",
          Inactive: "danger",
        };
        let status = params.data?.status;
        if (typeof params.data?.status === "number") {
          if (params.data?.status === 0) {
            status = "Inactive";
          }
          if (params.data?.status === 1) {
            status = "Active";
          }
        }
        if (moment(params.data.validTo) < moment()) {
          status = "Expired";
        }
        let color = statuses[status];
        let label = status;
        const className = color === "secondary" ? "light-dark" : "";
        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <FTSBadge {...{ color, className }}>{label}</FTSBadge>
          </ContextMenuTrigger>
        );
      },
    },
  ];
};

export const defineColumnsAccount = (collectDataForCopy, togglePopover) => {
  return [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      minWidth: 120,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.node.data.id}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.node.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account ID",
      field: "account.idForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 100,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["account.id"]}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data["account.id"]}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "account.companyNameForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 200,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["account.companyName"]}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data["account.companyName"]}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "account.cityForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["account.city"]}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data["account.city"]}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "account.stateForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 100,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["account.state"]}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data["account.state"]}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "account.addressForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 180,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["account.address"]}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data["account.address"]}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Title",
      field: "titleForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 350,
      flex: 2,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.title}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data.title}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Start at",
      field: "validFrom",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment.utc(params.data.validFrom).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>
              <TableDate date={moment.utc(params.data.validFrom)} />
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "End at",
      field: "validTo",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment.utc(params.data.validTo).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>
              <TableDate date={moment.utc(params.data.validTo)} />
            </span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "Promotion image",
      field: "imageForSort",
      unSortIcon: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        if (!params?.data?.image) {
          return <div>No image</div>;
        }
        return (
          <ContextMenuTrigger
            data={params.data.image}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.data.image}`}
            >
              Image
              <span style={{ paddingLeft: "12px" }}>
                <Icon name="Eye" {...iconProps} />
              </span>
            </a>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Associated with",
      field: "relatedAdsForSort",
      unSortIcon: true,
      sortable: true,
      minWidth: 170,
      cellRendererFramework: (params) => {
        let notIncludedListings = params.data?.allAds;
        notIncludedListings = notIncludedListings.filter(function (item) {
          return params.data?.relatedAds.indexOf(item.id) === -1;
        });

        let message;

        if (params.data?.allAds?.length === 0) {
          message = "No listings included";
        } else if (
          params.data?.relatedAds?.length === params.data?.allAds?.length
        ) {
          message = "All listings included";
        } else {
          message = "See not included";
        }

        return (
          <div style={{ padding: "10px 20px", width: "110%" }}>
            <div
              onMouseEnter={() =>
                togglePopover(`Popover_${params.data.id}`, notIncludedListings)
              }
              onMouseLeave={() => togglePopover()}
              id={`Popover_${params.data.id}`}
            >
              <div
                style={{
                  border: "1px solid #b6b6b6",
                  display: "flex",
                  justifyContent: "center",
                  padding: 6,
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <span style={{ lineHeight: 1.5 }}>
                  {params.data?.relatedAds?.length || 0}/
                  {params.data?.allAds?.length || 0} Listings
                </span>
                <span style={{ lineHeight: 1.2, color: "#b6b6b6" }}>
                  {message}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      headerName: "Listings",
      field: "listingCounts",
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: ({ data }) => {
        return <div>{data.listingCounts} listings</div>;
      },
    },
    {
      headerName: "Impressions",
      field: "impressions",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.impressions}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data.impressions}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Status",
      field: "statusForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let statuses = {
          Active: "success",
          Expired: "warning",
          Inactive: "danger",
        };

        let status = params.data?.status;
        if (typeof params.data?.status === "number") {
          if (params.data?.status === 0) {
            status = "Inactive";
          }
          if (params.data?.status === 1) {
            status = "Active";
          }
        }

        if (moment(params.data.validTo) < moment()) {
          status = "Expired";
        }

        let color = statuses[status];
        let label = status;

        const className = color === "secondary" ? "light-dark" : "";
        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <FTSBadge {...{ color, className }}>{label}</FTSBadge>
          </ContextMenuTrigger>
        );
      },
    },
  ];
};

export const defineColumnsListing = (
  collectDataForCopy,
  // togglePopover,
) => {
  return [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      minWidth: 120,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.node.data.id}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.node.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Listing ID",
      field: "listing.id",
      sortable: true,
      unSortIcon: true,
      minWidth: 100,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["listing.id"]}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data["listing.id"]}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "listing.nameForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 200,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["listing.name"]}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data["listing.name"]}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "listing.cityForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["listing.city"]}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data["listing.city"]}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "listing.stateForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 100,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["listing.state"]}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data["listing.state"]}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "listing.addressForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 180,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["listing.address"]}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data["listing.address"]}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Title",
      field: "titleForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 350,
      flex: 2,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.value}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data.title}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Start at",
      field: "validFrom",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment.utc(params.data.validFrom).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>
              <TableDate date={moment.utc(params.data.validFrom)} />
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "End at",
      field: "validTo",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment.utc(params.data.validTo).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>
              <TableDate date={moment.utc(params.data.validTo)} />
            </span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "Promotion image",
      field: "imageForSort",
      unSortIcon: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        if (!params?.data?.image) {
          return <div>No image</div>;
        }
        return (
          <ContextMenuTrigger
            data={params.data.image}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.data.image}`}
            >
              Image
              <span style={{ paddingLeft: "12px" }}>
                <Icon name="Eye" {...iconProps} />
              </span>
            </a>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Impressions",
      field: "impressions",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Status",
      field: "statusForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let statuses = {
          Active: "success",
          Expired: "warning",
          Inactive: "danger",
        };

        let status = params.data?.status;
        if (typeof params.data?.status === "number") {
          if (params.data?.status === 0) {
            status = "Inactive";
          }
          if (params.data?.status === 1) {
            status = "Active";
          }
        }

        if (moment(params.data.validTo) < moment()) {
          status = "Expired";
        }

        let color = statuses[status];
        let label = status;

        const className = "";
        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <FTSBadge {...{ color, className }}>{label}</FTSBadge>
          </ContextMenuTrigger>
        );
      },
    },
  ];
};

export const defineColumnsGeneral = (
  collectDataForCopy,
  accountId,
  listingId,
  onApply,
) => {
  let generalColumnsArray = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      minWidth: 120,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.node.data.id}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.node.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Title",
      field: "titleForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 350,
      flex: 2,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.title}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data.title}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Start at",
      field: "validFrom",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={moment.utc(params.data.validFrom).format("lll")}
              collect={collectDataForCopy}
              id="contextMenuPromotions"
            >
              <span>
                <TableDate date={moment.utc(params.data.validFrom)} />
              </span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "End at",
      field: "validTo",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={moment(params.data.validTo).format("lll")}
              collect={collectDataForCopy}
              id="contextMenuPromotions"
            >
              <span>
                <TableDate date={params.data.validTo} />
              </span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Promotion Image",
      field: "imageForSort",
      unSortIcon: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        if (!params?.data?.image) {
          return <div>No image</div>;
        }
        return (
          <ContextMenuTrigger
            data={params.data.image}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.data.image}`}
            >
              Image
              <span style={{ paddingLeft: "12px" }}>
                <Icon name="Eye" {...iconProps} />
              </span>
            </a>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Impressions",
      field: "impressions",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.impressions}
            collect={collectDataForCopy}
            id="contextMenuPromotions"
          >
            <span>{params.data.impressions}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Accounts",
      field: "associatedToAccounts",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return <div>{params.data.associatedToAccounts || 0} accounts</div>;
      },
    },
    {
      headerName: "Listings",
      field: "associatedToListings",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return <div>{params.data.associatedToListings || 0} listings</div>;
      },
    },
  ];
  if (accountId || listingId) {
    generalColumnsArray.unshift({
      headerName: "",
      field: "button",
      sortable: false,
      unSortIcon: false,
      minWidth: 100,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <button
            onClick={() => onApply(params.data)}
            type="button"
            className="p-1 btn btn-outline-primary"
          >
            Apply
          </button>
        );
      },
    });
  }
  return generalColumnsArray;
};
