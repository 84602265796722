import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Router as BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import FTSCookies, { AUTH_KEY } from "./cookies";
import FTSAxios from "./axios/fts.instance";
import { handleError } from "./handlers/error";
import collectEssentialData from "./axios/collect-data";
import Router from "./Router";

import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import UpdateListing from "./views/update-listing";
import DownloadRecipient from "./views/recipient";
import Payment from "./views/pages/payment/payment";
import PaymentStatus from "./views/pages/payment/PaymentStatus";
import { history } from "./history";

import "react-perfect-scrollbar/dist/css/styles.css";
import "react-loading-skeleton/dist/skeleton.css";
import "prismjs/themes/prism-tomorrow.css";

import "./components/@vuexy/rippleButton/RippleButton";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname.startsWith("/update-listing/")) return;

    const cookie = FTSCookies.get(AUTH_KEY);
    let loggedInUser = null;

    if (cookie) {
      FTSAxios.get("admin/user")
        .then(async (res) => {
          loggedInUser = res.data.user;

          dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { loggedInUser, loggedInWith: "jwt" },
          });

          await collectEssentialData(dispatch);

          setIsAuthenticated(true);
        })
        .catch((err) => {
          setIsAuthenticated(true);
          handleError(err);
        });
    } else {
      setIsAuthenticated(true);
    }
  }, [dispatch]);

  if (
    window.location.pathname.startsWith("/update-listing/") ||
    window.location.pathname.startsWith("/recipient/") ||
    window.location.pathname.startsWith("/payment/")
  ) {
    return (
      <BrowserRouter history={history}>
        <Switch>
          <Route path={"/update-listing/:token"} component={UpdateListing} />
          <Route path={"/recipient/:token"} component={DownloadRecipient} />
          <Route path={"/payment/:token/:status"} component={PaymentStatus} />
          <Route path={"/payment/:token"} component={Payment} />
          <Redirect to={"/login"} />
        </Switch>
      </BrowserRouter>
    );
  }

  if (!isAuthenticated) {
    return <Spinner />;
  }

  return <Router />;
};

export default App;
