import FTSAxios from "../../../axios/fts.instance";

export const filterAdsTypes = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_ADS_TYPES_DATA", value });
};

export const getAdsData = () => {
  return (dispatch) => {
    return FTSAxios.get("admin/lists/ads-type").then((response) => {
      dispatch({
        type: "GET_ADS_TYPES_DATA",
        data: response.data,
        totalPages: 1,
      });
    });
  };
};

export const updateAdTypeData = (payload) => {
  return () => {
    return FTSAxios.put(`admin/lists/ads-type/${payload.id}`, payload);
  };
};

export const deleteAdTypeData = (payload) => {
  return () => {
    return FTSAxios.delete(`admin/lists/ads-type/${payload.id}`);
  };
};

export const createAdTypeData = (payload) => {
  return () => {
    return FTSAxios.post("admin/lists/ads-type/create", payload);
  };
};

export const updateSelectedListings = (data) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_SELECTED_LISTINGS", data });
  };
};
export const setUpdateListingTypePayload = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_UPDATE_LISTING_TYPE_PAYLOAD", data });
  };
};
