import React, { PureComponent } from "react";

import CountBox from "../CountBox";
import BorderedCountBox from "../BorderedCountBox";
import { Row, Col } from "reactstrap";

class UpgradesVsDowngradesSection extends PureComponent {
  render() {
    const { formattedListings } = this.props;
    return (
      <Row className="mt-2">
        <Col>
          <div className="d-flex justify-content-between">
            <span className="mr-3">
              <CountBox
                label="Listings upgraded to"
                count={formattedListings?.upgrade || "-"}
                size="sm"
                iconName="Upgrades"
              />
            </span>

            <Col>
              <BorderedCountBox
                label="Basic plus"
                count={formattedListings?.upgradeToBasicPlus || "-"}
                color="#F5B053"
              />
            </Col>
            <Col>
              <BorderedCountBox
                label="Standard"
                count={formattedListings?.upgradeToStandard || "-"}
                color="#47A7F2"
              />
            </Col>
            <Col>
              <BorderedCountBox
                label="Premium"
                count={formattedListings?.upgradeToPremium || "-"}
                color="#F36565"
              />
            </Col>
          </div>
        </Col>
        <Col>
          <div className="d-flex justify-content-between">
            <span className="mr-3">
              <CountBox
                label="Listings downgraded to"
                count={formattedListings?.downgrade || "-"}
                size="sm"
                iconName="Downgrades"
              />
            </span>

            <Col>
              <BorderedCountBox
                label="Basic"
                count={formattedListings?.downgradeToBasic || "-"}
                color="#CBD1D3"
              />
            </Col>
            <Col>
              <BorderedCountBox
                label="Basic plus"
                count={formattedListings?.downgradeToBasicPlus || "-"}
                color="#F5B053"
              />
            </Col>
            <Col>
              <BorderedCountBox
                label="Standard"
                count={formattedListings?.downgradeToStandard || "-"}
                color="#47A7F2"
              />
            </Col>
          </div>
        </Col>
      </Row>
    );
  }
}

export default UpgradesVsDowngradesSection;
