export const LOAD_MORE_USERS_TODOS = "LOAD_MORE_USERS_TODOS";
export const SET_USER_TODOS = "SET_USER_TODOS";
export const REMOVE_USER_TODO = "REMOVE_USER_TODO";
export const CLEAR_USER_TODOS = "CLEAR_USER_TODOS";
export const ADD_NEW_TODO = "ADD_NEW_TODO";
export const UPDATE_USER_TODO = "UPDATE_USER_TODO";
export const UNARCHIVE_USER_TODO = "UNARCHIVE_USER_TODO";
export const INCREMENT_TODO_NOTES_COUNT = "INCREMENT_TODO_NOTES_COUNT";
export const INCREMENT_TODO_ASSETS_COUNT = "INCREMENT_TODO_ASSETS_COUNT";
export const UPDATE_TODO_ASSETS_COUNT = "UPDATE_TODO_ASSETS_COUNT";
export const DECREMENT_TODO_NOTES_COUNT = "DECREMENT_TODO_NOTES_COUNT";

export const addUserTodo = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_NEW_TODO,
      data,
    });
  };
};

export const updateTodoAssetsCount = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TODO_ASSETS_COUNT,
      data,
    });
  };
};

export const incrementTodoNotesCount = (data) => {
  return (dispatch) => {
    dispatch({
      type: INCREMENT_TODO_NOTES_COUNT,
      data,
    });
  };
};

export const decrementTodoNotesCount = (data) => {
  return (dispatch) => {
    dispatch({
      type: DECREMENT_TODO_NOTES_COUNT,
      data,
    });
  };
};

export const incrementTodoAssetsCount = (data) => {
  return (dispatch) => {
    dispatch({
      type: INCREMENT_TODO_ASSETS_COUNT,
      data,
    });
  };
};

export const updateUserTodo = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_TODO,
      data,
    });
  };
};

export const unarchiveUserTodo = (data) => {
  return (dispatch) => {
    dispatch({
      type: UNARCHIVE_USER_TODO,
      data,
    });
  };
};

export const setUserTodos = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_TODOS,
      data,
    });
  };
};

export const removeUserTodo = (data) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_USER_TODO,
      data,
    });
  };
};

export const loadMoreUserTodos = (data) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_MORE_USERS_TODOS,
      data,
    });
  };
};

export const clearUserTodosList = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_TODOS,
    });
  };
};
