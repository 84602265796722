import React, { Component } from "react";

import "../../../assets/scss/components/fts/listing-item-action-bar.scss";
import { Row, Col } from "reactstrap";
import Icon from "../Icons";

class ListingItemActionBar extends Component {
  state = {
    collapsed: false,
    collapseButtonText: "Expand Actions",
  };

  iconSize = 25;

  actions = [
    {
      title: "Status",
      icon: <Icon name="Star" size={this.iconSize} />,
    },
    {
      title: "Notes",
      icon: <Icon name="Notes" size={this.iconSize} />,
    },
    {
      title: "Rates",
      icon: <Icon name="DollarCircle" size={this.iconSize} />,
    },
    {
      title: "Services",
      icon: <Icon name="Setting" size={this.iconSize} />,
    },
    {
      title: "Review",
      icon: <Icon name="Review" size={this.iconSize} />,
    },
    {
      title: "Share",
      icon: <Icon name="ShareListing" size={this.iconSize} />,
    },
  ];

  handleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      collapseButtonText: this.state.collapsed
        ? "Expand Actions"
        : "Collapse Actions",
    });
  };

  render() {
    const { isFree, hideActions } = this.props;

    if (this.props.hasPromotions) {
      this.actions = [
        ...this.actions,
        {
          title: "Promotion",
          icon: <Icon name="PromotionTag" size={this.iconSize} />,
          outline: true,
        },
      ];
    }

    if (!isFree) {
      return (
        <div className="fts-bar action-bar-paid">
          <Row className="action-bar">
            {this.actions.map((props, index) => (
              <Col
                key={index}
                className={`action-bar-button no-select ${
                  props.outline ? "action-bar-button-outline" : ""
                }`}
              >
                <div>{props.icon}</div>
                <div>{props.title}</div>
              </Col>
            ))}
          </Row>
        </div>
      );
    }

    return (
      <div className="fts-bar">
        {isFree && (
          <div>
            {!hideActions && (
              <Row className="action-bar-free">
                {this.actions.map((props, index) => (
                  <Col key={index} className="action-bar-button no-select">
                    <div>{props.icon}</div>
                    <div>{props.title}</div>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ListingItemActionBar;
