import React, { PureComponent } from "react";

import "../../../../assets/scss/components/fts/percentage-box.scss";

class PercentageBox extends PureComponent {
  render() {
    const { percentage, isActivity } = this.props;
    if (percentage === "-" || percentage === "-100" || percentage === "-100.0")
      return null;

    return (
      <span
        className={`percentage-box-wrapper ${
          percentage > 0 ? "percentage-green" : "percentage-red"
        }`}
      >
        {percentage > 0 && <span>+ </span>}
        {percentage < 0 && <span>- </span>}
        {percentage !== "No data"
          ? `${isNaN(percentage) ? 0 : Math.abs(percentage)}%`
          : isActivity && "No activities in previous period"}
      </span>
    );
  }
}

export default PercentageBox;
