import styled from "styled-components";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { defaultStyleProps } from "../default";
import { Spacing } from "@find-truck-service/types/ui/spacing";
export const FtsAccordionContainer = styled(FtsWrapper) `
  ${defaultStyleProps};
  border-radius: ${Spacing["2xs"]};
  border: 1px solid ${ColorsValue["greyscale-100"]};
  overflow: hidden;
`;
