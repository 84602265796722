import React, { useEffect, useRef, useState } from "react";

import axios from "axios";

import appConfig from "../../../configs/appConfig";
import { Button, Card, Col, Row, Spinner } from "reactstrap";
import Icon from "../../../components/fts/Icons";

import FullPageLayout from "../../../layouts/FullpageLayout";
import { formatResponse } from "../../recipient";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
// import PaymentMethodModal from "../invoices-page/payment-modals/payment-method";
import FTSModal from "../../../components/fts/fts-modal";
import CloseButton from "../../../components/fts/CloseButton";
import classnames from "classnames";
import CreditCard from "./CreditCard";
import { connect } from "react-redux";
import { setSelectedInvoices } from "../../../redux/actions/invoices";

import { payWithPaypal } from "../../../redux/actions/payments";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import { defineInvoicesColumnsForPaymentLinkCheckBox } from "../invoices-page/invoices-columns";
import { Edit } from "react-feather";
import { toast } from "react-toastify";

const Payment = ({ setSelectedInvoices, match, payWithPaypal }) => {
  const gridApi = useRef(null);
  const gridColumnApi = useRef(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [columnDefCheckBox, setColumnDefCheckbox] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [editing, setEditing] = useState(true);
  const [total, setTotal] = useState(0);
  const [selectedInvoices, setSelectedInvoicesState] = useState([]);

  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [state, setState] = useState({
    isOpen: false,
    sidebar: false,
    updating: false,
  });

  const callToAction = (item) => {
    setState({
      action: "invoice_details",
      actionItem: item,
      sidebar: true,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(appConfig.apiURL + "admin/payment-by-link-hash", {
        params: { hash: match.params.token },
      })
      .then(({ data }) => {
        let newData = formatResponse(
          decodeURIComponent(JSON.stringify(data.results)),
        );
        let newTotal = 0;
        newData = JSON.parse(newData);
        let selectedInvoices = newData.map((e) => {
          newTotal += e.total;
          return {
            ...e,
            isSelected: true,
          };
        });

        if (!newData.length) setError("No invoices found");
        setInvoices(newData);
        setSelectedInvoices({
          selectedInvoices,
        });
        setTotal(newTotal);
        setSelectedInvoicesState(selectedInvoices);

        setColumnDefCheckbox(
          defineInvoicesColumnsForPaymentLinkCheckBox(callToAction),
        );
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("ERROR: ", e);
        setError("Oops! Something went wrong. Please try again.");
      });
  }, [match.params.token, setSelectedInvoices]);

  if (error) {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <h2>{error}</h2>
      </div>
    );
  }

  if (isLoading || !invoices.length) {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <Spinner size={"lg"} color="primary" />
      </div>
    );
  }

  const formatMoney = (number) => {
    return number
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  let invoiceTotal = 0;
  invoices.map(({ total }) => (invoiceTotal += total));

  const handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (bool === false && !closeWithoutPrompt) {
      return setState({ isOpen: true });
    } else {
      return setState({ sidebar: bool, updating: !!update });
    }
  };

  // getSelectedInvoices = () => {
  //   let selectedInvoices = this.gridApi?.getSelectedNodes().map((gridNode) => {
  //     return { ...gridNode.data, isSelected: true };
  //   });
  //
  //   this.props.setSelectedInvoices({ selectedInvoices });
  // };
  const getSelectedInvoices = () => {
    if (!gridApi.current) return;
    gridApi.current.showLoadingOverlay();

    let newTotal = 0;
    let selectedInvoices = gridApi.current
      ?.getSelectedNodes()
      .map((gridNode) => {
        newTotal += gridNode.data.total;
        return { ...gridNode.data, isSelected: true };
      });

    setTotal(newTotal);
    setSelectedInvoices({ selectedInvoices });
    gridApi.current.hideOverlay();
  };

  const pageSizes = [10, 25, 50, 100];

  let a = setInterval(() => {
    let ag = document.getElementsByClassName("ag-root")[0];
    ag.style.marginLeft = editing ? "-50px" : "0px";

    if (ag) clearInterval(a);
  }, 10);

  const onGridReady = (params) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
  };
  return (
    <FullPageLayout bgColor={"#CE1B3B"}>
      <Row>
        <Col md={{ offset: 2, size: 8 }}>
          <Card className={"p-4"}>
            <div className="invoices-date-filter mb-3 d-flex justify-content-between ">
              <h2>Payment overview</h2>
              <Button
                // disabled={!editing}
                onClick={() => {
                  if (editing) {
                    setEditing(!editing);
                  }
                  setEditing(!editing);
                  let ag = document.getElementsByClassName("ag-root")[0];
                  ag.style.marginLeft = editing ? "-100px" : "0px";
                  ag.style.transition = "all 0.5s ease";
                }}
                color="secondary"
              >
                <Edit className="cursor-pointer mr-1" size={20} /> Edit
              </Button>
            </div>

            <FTSDataTable
              noPagination={true}
              isSearch={true}
              getSelectedInvoices={getSelectedInvoices}
              pageSizes={pageSizes}
              rowSelection="multiple"
              rowData={selectedInvoices}
              onGridReadyInit={onGridReady}
              onRowClicked={() => {}}
              columnDefs={columnDefCheckBox}
              resetApi={() => {}}
              tableName="invoices2"
            />

            <Row className={"mt-5"}>
              {editing && !isPaymentCompleted ? (
                <Col md={{ size: 12 }}>
                  <Button
                    disabled={!total}
                    className={"w-100"}
                    color="primary"
                    onClick={() => {
                      setIsOpenPaymentModal(true);
                    }}
                  >
                    Pay ${formatMoney(total)}
                  </Button>
                </Col>
              ) : !isPaymentCompleted ? (
                <>
                  <Col md={{ offset: 9, size: 3 }}>
                    <Button
                      className={"w-100"}
                      color="primary"
                      onClick={() => {
                        setEditing(!editing);
                      }}
                    >
                      Save changes
                    </Button>
                  </Col>
                </>
              ) : null}
            </Row>

            {/*<Row>*/}

            {/*</Row>*/}
          </Card>
        </Col>

        <ActionSidebar
          hideFooter={true}
          handleSidebar={handleSidebar}
          actionItem={state.actionItem}
          getSelectedInvoices={getSelectedInvoices}
          scrollToSelectedInvoice={() => {}}
          // accountData={invoices[0].owner}
          show={state.sidebar}
          action={state.action}
        />
      </Row>

      <ClientPaymentMethodModal
        hash={match.params.token}
        total={total}
        payWithPaypal={payWithPaypal}
        selectedInvoices={selectedInvoices}
        invoiceTotal={formatMoney(invoiceTotal)}
        isOpen={isOpenPaymentModal}
        closeModal={() => setIsOpenPaymentModal(false)}
        setIsPaymentCompleted={setIsPaymentCompleted}
      />
    </FullPageLayout>
  );
};

const mapDispatchToProps = {
  setSelectedInvoices,
  payWithPaypal,
};
export default connect(null, mapDispatchToProps)(Payment);

const ClientPaymentMethodModal = ({
  total,
  isOpen,
  title,
  closeModal,
  onClose,
  selectedInvoices,
  payWithPaypal,
  hash,
  setIsPaymentCompleted,
}) => {
  const [activePaymentMethod, setActivePaymentMethod] = useState("");
  const [loading, setLoading] = useState(false);
  const paymentStepsDefinition = {
    creditCard: {
      label: "CREDIT CARD",
      icon: "Card",
      component: (
        <CreditCard
          setIsPaymentCompleted={setIsPaymentCompleted}
          onSubmit={() => {
            closeModal();
            setActivePaymentMethod("");
          }}
        />
      ),
    },
    // ach: {
    //   label: "ACH",
    //   icon: "ACH",
    //   component: <Ach invoiceTotal={invoiceTotal} />,
    // },
    paypal: {
      label: loading ? <Spinner size={"sm"} /> : "PAYPAL",
      icon: "Paypal",
      component: <CreditCard />,
    },
  };

  return (
    <FTSModal
      {...{ isOpen, title, closeModal, onClose }}
      modalClasses={"fts-payment-modal"}
      centered={true}
      style={{ maxWidth: "800px" }}
    >
      <div className="payment-modal-body">
        <span className="payment-modal-body-wrapper">
          <span className="payment-method-title">
            <h3>Select payment method</h3>
            <CloseButton onClick={closeModal} />
          </span>
          <span className="payment-method-buttons">
            {Object.keys(paymentStepsDefinition).map((payment) => {
              return (
                <Button
                  disabled={loading}
                  key={payment}
                  onClick={() => {
                    setLoading(true);
                    setActivePaymentMethod(payment);
                    if (payment === "paypal") {
                      payWithPaypal({ total, selectedInvoices, hash })
                        .then(({ data }) => {
                          let { href } = data.links.find(
                            (e) => e.method === "REDIRECT",
                          );
                          window.open(href, "_self");
                        })
                        .catch(() => {
                          toast.error(
                            "Oops! Something went wrong. Please try again.",
                          );
                        });
                    } else {
                      setLoading(false);
                    }
                  }}
                  color={
                    activePaymentMethod === payment ? "primary" : "secondary"
                  }
                  className="ach-button"
                >
                  <span className={classnames("button-body")}>
                    <Icon
                      name={paymentStepsDefinition[payment].icon}
                      size="24"
                      stroke={activePaymentMethod === payment ? "#fff" : "#000"}
                      color={activePaymentMethod === payment ? "#fff" : "#000"}
                    />
                    <span className={"ml-1"}>
                      {paymentStepsDefinition[payment].label}
                    </span>
                  </span>
                </Button>
              );
            })}
          </span>
        </span>
      </div>

      {!loading && paymentStepsDefinition[activePaymentMethod]?.component}
    </FTSModal>
  );
};
