import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import "../../../../assets/scss/pages/authentication.scss";
import Icon from "../../../../components/fts/Icons";
import LoginJWT from "./LoginJWT";
import FTSCookies, { AUTH_KEY, TEMPORARY_TOKEN } from "../../../../cookies";

const services = [
  {
    label: "Mobile repair",
    icon: "MobileRepair",
  },
  {
    label: "Parts",
    icon: "Parts",
  },
  {
    label: "Towing",
    icon: "TowingNew",
  },
  {
    label: "Tires",
    icon: "Tires",
  },
  {
    label: "Repair",
    icon: "Repair",
  },
  {
    label: "Dealers & more",
    icon: "Dealers",
  },
];

class Login extends React.Component {
  state = {
    activeTab: "1",
  };
  constructor() {
    FTSCookies.remove(AUTH_KEY);
    FTSCookies.remove(TEMPORARY_TOKEN);
    super();
  }
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  render() {
    return (
      <div className="h-100">
        <Row className="m-0 justify-content-center">
          <Col
            className="d-flex justify-content-end"
            sm="8"
            xl="6"
            lg="6"
            md="8"
          >
            <div className="FTS-services-wrapper">
              <div>
                <div className="FTS-services-label">
                  <div className="FTS-services-label-icon">
                    <Icon name="BgElement" size={70} strokeOpacity={0} />
                  </div>
                  <div className="FTS-services-label-text">
                    <span>Find your</span>
                    <span>nearest service</span>
                  </div>
                </div>
                <div className="FTS-services">
                  {services.map((service, index) => {
                    return (
                      <div className="FTS-single-service d-flex" key={index}>
                        <Icon name={service.icon} size={40} color={"#FFF"} />
                        <span>{service.label}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
          <Col sm="8" xl="6" lg="6" md="8" className="d-flex">
            <Card className="bg-authentication login-card rounded-0 mb-0 ">
              <Row className="m-0">
                <Col lg="12" md="12" className="p-0 login-card">
                  <Card className="rounded-0 mb-0 px-4 mb-4 mt-2 login-tabs-container">
                    <CardHeader className="pb-1">
                      <CardTitle>
                        <h4 className="mb-0">Login</h4>
                      </CardTitle>
                    </CardHeader>
                    <p className="px-2 auth-title">
                      Welcome back, please login to your account.
                    </p>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <LoginJWT />
                      </TabPane>
                    </TabContent>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Login;
