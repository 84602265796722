/* eslint-disable indent */
const initialState = {
  emailsCount: 0,
};

const MarketingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COMMUNICATIONS_COUNTER":
      return {
        ...state,
        emailsCount: action.payload.emailsCount,
      };

    default:
      return state;
  }
};

export default MarketingReducer;
