import { useDispatch, useSelector } from "react-redux";
import {
  selectAmenities,
  selectAmenitiesSearchValue,
} from "../../../../redux/reducers/essentialLists";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { FtsWrapper } from "../../dist/ui/src/FtsWrapper/FtsWrapper";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Span, P } from "../../dist/ui/src/FtsText";

import Checkbox from "../../dist/ui/src/components/react/Checkbox";
import React, { useCallback, useEffect, useState } from "react";
import {
  selectListingAdsAmenities,
  selectListingData,
} from "../../../../redux/reducers/v2/listing";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { Input } from "../../dist/ui/src/components/react/Input";
import {
  InputColors,
  InputSizes,
  InputVariants,
} from "../../dist/types/ui/input";
import { updateAmenitiesSearchValue } from "../../../../redux/actions/essentialLists";
import { FtsButton } from "../../dist/ui/src/FtsButton/FtsButton";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonVariants,
} from "../../dist/types/ui/button";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export const AmenitiesPicker = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);
  const selectedAmenities = useSelector(selectListingAdsAmenities);
  const amenities = useSelector(selectAmenities);
  const amenitiesSearchValue = useSelector(selectAmenitiesSearchValue);

  const [internalAmenitiesList, setInternalAmenitiesList] = useState(amenities);

  const handleSearch = useCallback(
    (event) => {
      let newAmenities = [...amenities];

      newAmenities = newAmenities.filter((e) =>
        e.name.toLowerCase().includes(event.target.value.toLowerCase()),
      );

      dispatch(
        updateAmenitiesSearchValue({
          amenitiesSearchValue: event.target.value,
        }),
      );
      setInternalAmenitiesList(newAmenities);
    },
    [amenities, amenitiesSearchValue, setInternalAmenitiesList],
  );

  useEffect(() => {
    if (amenitiesSearchValue)
      handleSearch({ target: { value: amenitiesSearchValue } });
  }, [handleSearch]);

  const onChangeAmenities = useCallback(
    (amenity) => {
      const isChecked = selectedAmenities.find((i) => i.id === amenity.id);
      if (isChecked) {
        return dispatch(
          getListingDataByIdSuccess({
            data: {
              ...listingData,
              adsAmenities: selectedAmenities.filter(
                (e) => e.id !== amenity.id,
              ),
            },
          }),
        );
      }
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            adsAmenities: [...selectedAmenities, amenity],
          },
        }),
      );
    },
    [listingData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          AMENITIES
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 12 }}>
        <Input
          value={amenitiesSearchValue}
          onChange={handleSearch}
          placeHolder={"Search..."}
          icon={"Search"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
        />
      </FtsColumn>
      {internalAmenitiesList.length ? (
        internalAmenitiesList.map((e) => {
          return (
            <FtsColumn key={e.id} size={{ xs: 4 }}>
              <Checkbox
                onClick={() => onChangeAmenities(e)}
                checked={!!selectedAmenities.find((f) => f.id === e.id)}
                label={e.name}
              />
            </FtsColumn>
          );
        })
      ) : (
        <FtsColumn size={{ xs: 12 }}>
          <FtsWrapper display={"flex"} align={"center"} justify={"center"}>
            <FtsWrapper>
              <P
                variant={VariantsValues["text-2xs"]}
                color={ColorsValue["greyscale-800"]}
                mb={"sm"}
              >
                There is no amenities
              </P>
              <FtsButton
                onClick={() => handleSearch({ target: { value: "" } })}
                variant={ButtonVariants.outlined}
                color={ButtonColorsValue.accent}
                size={ButtonSizes.sm}
              >
                <Span
                  variant={VariantsValues["text-3xs"]}
                  color={ColorsValue["blue-accent-500"]}
                >
                  Clear search
                </Span>
              </FtsButton>
            </FtsWrapper>
          </FtsWrapper>
        </FtsColumn>
      )}
    </FtsRow>
  );
};
