import FTSAxios from "../../../axios/fts.instance";

export const filterAdminUsers = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_ADMIN_USERS_DATA", value });
};

export const getAdminUsers = () => {
  return (dispatch) => {
    return FTSAxios.get("admin/admin-users/all").then((response) => {
      dispatch({
        type: "GET_ADMIN_USERS_DATA",
        data: response.data,
        totalPages: 1,
      });
    });
  };
};

export const updateAdminUser = (payload) => {
  return () => {
    return FTSAxios.put(`admin/admin-users/update/${payload.id}`, payload);
  };
};
export const sendResetPasswordAdminUser = (payload) => {
  return () => {
    return FTSAxios.post("admin/send/update-password", payload);
  };
};
export const updatePassword = (payload) => {
  return () => {
    return FTSAxios.post("admin/update-password", payload);
  };
};

export const getAdminInfo = (adminId) => {
  return () => {
    return FTSAxios.get(`admin/admin-users/${adminId}`);
  };
};

export const deleteAdminUser = (payload) => {
  return () => {
    return FTSAxios.delete(`admin/admin-users/${payload.id}`);
  };
};

export const createAdminUser = (payload) => {
  return () => {
    return FTSAxios.post("admin/admin-users/create", payload);
  };
};
