/* eslint-disable indent */
import React, { Component } from "react";
import capitalizeText from "../../../utility/capitalize";

import "../../../assets/scss/components/fts/fts-square-badge.scss";

class FTSSquareBadge extends Component {
  render() {
    const {
      text,
      textColor,
      backgroundColor,
      borderColor,
      marginLeft,
      capitalize,
      fontSize,
    } = this.props;

    return (
      <div
        className="fts-square-badge"
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          border: `1px solid ${borderColor ? borderColor : "none"}`,
          marginLeft: marginLeft ? marginLeft : "0",
          // fontFamily: category ? "Futura Md BT" : "Futura Book Bt",
          fontSize: fontSize ? fontSize : "10px",
          lineHeight: "22px",
        }}
      >
        {capitalize ? capitalizeText(text) : text?.toUpperCase()}
      </div>
    );
  }
}

export default FTSSquareBadge;
