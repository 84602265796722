import React, { Component, createRef } from "react";
import { FaRegCopy } from "react-icons/fa";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import FTSTableGroup from "../../../components/fts/fts-table-group";
import capitalize from "../../../utility/capitalize";
import { subAccountColumns } from "../accounts/columns";
import {
  getAccountSubUser,
  getUserListings,
} from "../../../redux/actions/users";
import Icons from "../../../components/fts/Icons";

class AccountSubUsers extends Component {
  tableGroupRef = createRef();
  pageSizes = [10, 25, 50, 100];
  tableNames = ["subAccount"];
  state = {
    collectedDataForCopy: "",
  };

  getTableData = (key, index, state) => {
    const { accountId } = this.props;

    return this.props.getAccountSubUser(accountId, {
      ...state.searchQuery,
      searchString: state[`searchVal${capitalize(key, false)}`],
      page: state[`page${capitalize(key, false)}`],
      resultsPerPage: state[`pageSize${capitalize(key, false)}`],
    });
  };

  onRowClicked = () => {
    return;
    // history.push(`/accounts/${data.id}`);
  };

  onCreateNew = () => {
    const { accountData } = this.props;
    if (!accountData || accountData.userTypeNamed === "user") {
      return toast.error("Only vendor accounts can have listings!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    this.props.callToAction("listing", false);
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  getTableLayouts = () => {
    if (!this.tableLayouts) {
      const listingTableLayout = {
        onRowClicked: this.onRowClicked,
        // onCreateNew: this.onCreateNew,
        columnDefs: subAccountColumns(this.collectDataForCopy),
        getTableData: this.getTableData,
        pageSizes: this.pageSizes,
        hasSearch: true,
        initialTableData: null,
        initialPage: 1,
        initialPageSize: 10,
        initialPageCount: 0,
        initialTotalRecords: 0,
        initialSearchVal: "",
      };

      this.tableLayouts = {
        subAccount: { ...listingTableLayout },
      };
    }

    return this.tableLayouts;
  };

  handleSearch = (type, val, key) => {
    let priceId = Object.keys(this.tableLayouts).indexOf(key);

    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.tableGroupRef.onPageChanged({ selected: 0 }, key);
      this.tableLayouts[key].getTableData(
        key,
        priceId,
        this.tableGroupRef.state,
      );
    }, 300);
  };

  render() {
    return (
      <>
        <FTSTableGroup
          onRef={(ref) => (this.tableGroupRef = ref)}
          handleSearch={this.handleSearch}
          tableLayouts={this.getTableLayouts()}
          saveData={this.props.saveData}
          searchPlaceholder="Search"
          initialSearchQuery={{
            id: "",
            name: "",
            phone: "",
            address: "",
            city: "",
            zip: "",
            state: "",
            status: "",
          }}
          tableHeader={"Users"}
        />
        <ContextMenu id="contextMenuSubUser" preventHideOnScroll={false}>
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(this.state.collectedDataForCopy);
            }}
          >
            <FaRegCopy className="copy" />
            <span className="ml-1 text-dark">Copy</span>
          </MenuItem>
          {this.state.call && this.state.collectedDataForCopy && (
            <MenuItem>
              <Icons name="PhoneBlue" />
              <a
                className="ml-1 text-decoration-none text-dark w-100"
                href={`tel:${this.state.collectedDataForCopy}`}
              >
                Call - {this.state.collectedDataForCopy}
              </a>
            </MenuItem>
          )}
        </ContextMenu>
      </>
    );
  }
}

export default connect(null, { getUserListings, getAccountSubUser })(
  AccountSubUsers,
);
