import React from "react";
import ReactQuill from "react-quill";
import { InputLabel } from "../InputLabel";
import "react-quill/dist/quill.snow.css";
import "./Styled/RichTextEditor.css";
export const RichTextEditor = (props) => {
    const { value, placeholder, onChange, label, required, readOnly, className } = props;
    const height = props.height || "256px";
    const width = props.width || "100%";
    return (React.createElement(React.Fragment, null,
        React.createElement(InputLabel, { label: label, mb: "2xs", required: required }),
        React.createElement(ReactQuill, { theme: "snow", readOnly: readOnly, value: value ?? "", onChange: onChange, id: "rich_text_editor", placeholder: placeholder, style: { height, width }, className: `rich-text-editor ${className}`, modules: {
                toolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]],
            } })));
};
