import React, { Component } from "react";
import { Label } from "reactstrap";
import { ErrorMessage } from "formik";
import { connect } from "react-redux";

import { getAdsData } from "../../../redux/actions/ads";
import { pickerStyles, controlStyles } from "../picker-style";
import Select from "../Select";
import FormikInputValue from "../formik/formik-input-value";
import listingTypesOptions from "../../../data/listing-types-options";

const adsLabels = {
  premium: "Premium",
  standard: "Standard",
  basicplus: "Basic Plus",
  basic: "Basic",
  free: "Free",
};

class ListingTypesPicker extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.inProgress !== this.state.inProgress ||
      nextProps.isDisabled !== this.props.isDisabled ||
      (!!this.props.meta &&
        nextProps.meta.touched !== this.props.meta.touched) ||
      (!!this.props.meta && nextProps.meta.error !== this.props.meta.error) ||
      nextProps.value !== this.props.value ||
      nextProps.ads.length !== this.props.ads.length
    );
  }
  _isMounted = false;
  state = {
    inProgress: false,
    options: listingTypesOptions,
  };

  componentDidMount() {
    this._isMounted = true;

    if (!this.props.promocode) {
      this.setState({ inProgress: true });
      this.props.getAdsData().finally(() => {
        const options = this.props.ads.map(({ id, type, price }) => ({
          value: id,
          label: `${adsLabels[type]} ($${price})`,
        }));

        if (this._isMounted) {
          this.setState({ inProgress: false, options });
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      onOptionChange,
      label,
      name,
      isDisabled,
      placeholder,
      meta,
      onBlur,
      value,
      isMulti,
      autoComplete,
    } = this.props;

    const { inProgress, options } = this.state;

    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) => controlStyles(styles, state, {}, { meta }),
    };

    const defaultValue = this.props.promocodes
      ? null
      : meta && meta.value && options?.length
      ? options.find((itm) => itm.value === meta.value)
      : options.find((itm) => itm.value === 5);

    let isLoading = false;

    if (!options?.length) {
      isLoading = true;
    }

    let valueInfo = typeof value !== "object" ? defaultValue : value;

    return (
      <>
        {!!label && (
          <Label className={isDisabled ? "disabled" : ""} for={name}>
            {this.props.required && !isDisabled ? `${label} *` : label}
          </Label>
        )}
        {isDisabled ? (
          <FormikInputValue>{defaultValue?.label || "-"}</FormikInputValue>
        ) : (
          <Select
            isClearable
            isLoading={inProgress}
            onChange={onOptionChange}
            {...{
              isDisabled,
              placeholder,
              defaultValue,
              onBlur,
              isLoading,
              label,
            }}
            value={valueInfo}
            id={name}
            name={name}
            options={options}
            styles={pickerStyle}
            isMulti={isMulti}
            autoComplete={autoComplete}
          />
        )}
        {!!meta && (
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="formik-validation-error">{msg}</div>
            )}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ads: state.adsList.data,
  };
};

export default connect(mapStateToProps, { getAdsData })(ListingTypesPicker);
