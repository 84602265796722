import React, { PureComponent } from "react";
import { Row, Col, Spinner } from "reactstrap";
import classnames from "classnames";

// import appConfig from "../../../../configs/appConfig";

import "../../../../assets/scss/components/fts/form-fields.scss";
import Divider from "../../../../components/fts/Divider";
import FileItem from "../../../../components/fts/files/FileItem";
// import { withTrailingSlash } from "../../../../utility/trailingSlash";
import { listingPromotionFileTypes } from "../../../../data/supported-file-types";

class Images extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      inProgress,
      editMode,
      imageLink,
      setFileInputRef,
      setFileInputRefVal,
      onImageUpdate,
      keys,
      hideRemoveBtn,
      disableImagePreview,
    } = this.props;

    let allowedFileTypes = "";

    const fileTypes = listingPromotionFileTypes.map((type) => `.${type.value}`);

    fileTypes.forEach((type) => {
      allowedFileTypes += type + ",";
    });

    return (
      <React.Fragment>
        <Divider textColor="primary" position="left">
          Current image
        </Divider>
        <Row>
          <Col>
            {inProgress ? (
              <Spinner color="primary" />
            ) : (
              <div
                title="Remove image"
                className={classnames("image-wrapper", {
                  "edit-mode": editMode,
                })}
              >
                {imageLink?.length > 0 ? (
                  <FileItem
                    disableImagePreview={disableImagePreview}
                    // url={`${withTrailingSlash(appConfig.imageUrl)}${imageLink}`}
                    url={imageLink}
                    onRemove={() => {
                      setFileInputRefVal("");
                    }}
                    hideRemoveBtn={!editMode || hideRemoveBtn}
                  />
                ) : (
                  <div className="no-image">No image selected</div>
                )}
              </div>
            )}
            {editMode && (
              <div
                className={classnames(
                  "d-flex flex-wrap justify-content-between",
                  { "display-hidden": !editMode }
                )}
              >
                <span
                  className={`${
                    keys && keys.includes("imageLink") && "field-changed"
                  }`}
                >
                  <label
                    className="btn btn-flat-primary upload-image-btn"
                    htmlFor="update-image"
                    color="primary"
                  >
                    {imageLink?.length > 0 ? "Change image" : "Upload Image"}
                    <input
                      ref={setFileInputRef}
                      type="file"
                      id="update-image"
                      hidden
                      onChange={onImageUpdate}
                      disabled={!editMode}
                      accept={allowedFileTypes}
                    />
                  </label>
                </span>
              </div>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default Images;
