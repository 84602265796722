import React, { PureComponent } from "react";
import { connect } from "react-redux";

import "../../../../assets/scss/components/fts/todo-dashboard-section.scss";
import Icon from "../../../../components/fts/Icons";
import { setActiveTab } from "../../../../redux/actions/sales";
import { Row, Col } from "reactstrap";
import Chart from "react-apexcharts";
import TodoTypesSection from "./TodoTypesSection";
import { history } from "../../../../history";

class TodoSection extends PureComponent {
  state = {
    sectionsData: null,
  };

  componentDidMount = () => {
    let { todoTypes } = this.props;
    let list = {
      firstSection: [],
      secondSection: [],
      thirdSection: [],
    };
    let sections = Object.keys(list);
    let sectionNumber = 0;

    for (let i = 0; i < todoTypes.length; i++) {
      let todo = todoTypes[i];
      if (todo.type === "todo" || todo.type === "both") {
        list[sections[Math.floor(sectionNumber / 5)]].push({
          id: todo.id,
          description: todo.description,
          color: todo.color,
          value: todo.description.toLowerCase(),
        });
        sectionNumber++;
      }
    }

    this.setState({ ...list });
  };

  render() {
    const { firstSection, secondSection, thirdSection } = this.state;
    const { formattedTodos, activeTabDash, options, series } = this.props;

    return (
      <Row className="todo-section-wrapper">
        <Col className="d-flex align-items-center justify-content-center">
          {formattedTodos &&
          !(
            formattedTodos[activeTabDash].total -
            formattedTodos[activeTabDash].completedTotal
          ) ? (
            <div className="congrulations-todo-section">
              <div className="congrulations-header">
                <p>Congrulations {this.props.userData.firstName}!</p>
                <p className="message">You’ve completed all your Todo’s!</p>
              </div>
              <Icon name="Congratulations" size="104" />
              <span
                className="task-management-link"
                onClick={() => {
                  this.props.setActiveTab(2);
                  history.push("/sales");
                  // history
                }}
              >
                <span>Jump into the leads pool</span>
                <Icon name="ArrowLink" size={24} />
              </span>
            </div>
          ) : (
            <Chart
              options={options}
              series={series}
              type="radialBar"
              height="300px"
              width="300px"
            />
          )}
        </Col>
        <Col>
          <TodoTypesSection
            activeTab={activeTabDash}
            formattedTodos={formattedTodos}
            todoTypes={firstSection}
          />
        </Col>
        <Col>
          <TodoTypesSection
            activeTab={activeTabDash}
            formattedTodos={formattedTodos}
            todoTypes={secondSection}
          />
        </Col>
        <Col>
          <TodoTypesSection
            activeTab={activeTabDash}
            formattedTodos={formattedTodos}
            todoTypes={thirdSection}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.sales.activeTab,
    todoTypes: state.essentialLists.types,
    userData: state.auth.login.values.loggedInUser,
  };
};

const mapDispatchToProps = {
  setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(TodoSection);
