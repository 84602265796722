// Common
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Redux
import { getListingDataByIdRequest } from "../../../redux/actions/v2/listings";
import { EditListingForm } from "../EditListingForm";
import {
  selectIsLoadingListingData,
  selectListingData,
  selectListingDataError,
} from "../../../redux/reducers/v2/listing";
// Components
import { SideOutEditListingHeader } from "./SideOutEditListingHeader";
import { SideOutEditListingFooter } from "./SideOutEditListingFooter";
import { SideOutErrorState } from "../dist/ui/src/components/react/SideOut/Components/SideOutErrorState";
import { SideOutLoadingState } from "../dist/ui/src/components/react/SideOut/Components/SideOutLoadingState";
import { selectSideOutData } from "../../../redux/reducers/v2/sideOut";

export const SideOutEditListing = () => {
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);
  const error = useSelector(selectListingDataError);
  const isLoading = useSelector(selectIsLoadingListingData);
  const sideOutData = useSelector(selectSideOutData);

  const getListingData = useCallback(() => {
    dispatch(getListingDataByIdRequest(sideOutData?.id));
  }, [sideOutData?.id]);

  useEffect(() => {
    if (sideOutData?.id && !listingData) getListingData();
  }, [sideOutData?.id, listingData]);

  if (isLoading) {
    return <SideOutLoadingState />;
  }

  if (error) {
    return <SideOutErrorState error={error} onClick={getListingData} />;
  }

  if (!listingData) {
    return <SideOutErrorState error={error} onClick={getListingData} />;
  }

  return (
    <>
      <SideOutEditListingHeader />
      <EditListingForm />
      <SideOutEditListingFooter />
    </>
  );
};
