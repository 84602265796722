import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";

import "../../../assets/scss/components/fts/todos.scss";
import Note from "../notes/NoteSmall";
import TodoSmallComponent from "../todos/TodoSmallComponent";
import { connect } from "react-redux";
import Activity from "../activity";
import { getTodo } from "../../../redux/actions/todo";

class MergedList extends PureComponent {
  render() {
    const {
      list = [],
      adminID,
      listingID,
      onReplyAdded,
      callToAction,
      showTodos,
      showNotes,
      showActivities,
      isListing,
      openSidebar,
    } = this.props;
    /* eslint-disable  */

    let todosList = list
      .filter((e) => e.type === "todo")
      .sort((left, right) => {
        let { reminderDate: reminderDateLeft, reminderTime: reminderTimeLeft } =
          left;
        let {
          reminderDate: reminderDateRight,
          reminderTime: reminderTimeRight,
        } = right;

        reminderDateLeft =
          reminderDateLeft.split(" ")[0] + " " + reminderTimeLeft;
        reminderDateRight =
          reminderDateRight.split(" ")[0] + " " + reminderTimeRight;
        return new Date(reminderDateLeft) - new Date(reminderDateRight);
      });

    let data = list
      .filter((e) => e.type === "note" || e.type === "activity")
      .sort((left, right) => {
        let { createdAt: createdAtLeft } = left;
        let { createdAt: createdAtRight } = right;
        return new Date(createdAtRight) - new Date(createdAtLeft);
      });

    data = [...todosList, ...data];

    const onActivityClicked = (todoId, activityType) => {
      // if (activityType === "requested_participation") return;
      if (activityType === this.props.activitiesTypes?.REQUESTED_PARTICIPATION)
        return;

      let todo = data.find(
        (item) => item.type === "todo" && item.id === todoId,
      );

      if (!todo) {
        return this.props.getTodo(todoId).then(({ data }) => {
          return callToAction("todo", true, data);
        });
      }

      return callToAction("todo", true, todo);
    };

    return (
      <React.Fragment>
        {data.map((item) => {
          if (item.toDoId && isListing) return null;
          return (
            <Row key={`#${item.id}-${item.type}`}>
              <Col>
                {item.type === "todo" && showTodos ? (
                  <TodoSmallComponent
                    data={item}
                    callToAction={callToAction}
                    onReplyAdded={onReplyAdded}
                    types={this.props.types}
                    isSidebarList
                    isListing={isListing}
                    listingID={listingID}
                  />
                ) : null}

                {item.type === "note" && showNotes ? (
                  <Note
                    data={item}
                    isListing={isListing}
                    openSidebar={openSidebar}
                    {...{ adminID, onReplyAdded, callToAction }}
                  />
                ) : null}

                {item.type === "activity" && showActivities ? (
                  <Activity
                    listingId={listingID}
                    isListing={isListing}
                    showLink={true}
                    data={item}
                    mergedList={data}
                    callToAction={callToAction}
                    onActivityClicked={onActivityClicked}
                    isSmallActivity
                  />
                ) : null}
              </Col>
            </Row>
          );
        })}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.essentialLists.types,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, { getTodo })(MergedList);
