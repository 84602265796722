import * as Yup from "yup";
import {
  phoneNumberValidate,
  requiredPhoneNumberValidate,
} from "../../dist/types/validators/global";

export const accountContactRowValidator = Yup.object().shape({
  additionalEmail: Yup.string().email("Invalid email"),
  email: Yup.string().required().email("Invalid email"),
  mobilePhone: phoneNumberValidate,
  phoneNumberSecondary: phoneNumberValidate,
  phoneNumber: requiredPhoneNumberValidate,
});
