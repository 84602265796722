import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import LoadingSkeleton from "react-loading-skeleton";
import React from "react";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
export const SkeletonRow = () => {
    return (React.createElement(FtsColumn, { size: { xs: 12 } },
        React.createElement(FtsWrapper, { py: "lg", px: "2xl", gap: "lg", align: "center", display: "flex", borderRadius: "2xs", justify: "space-between", border: `${Spacing["4xs"]} solid ${ColorsValue["greyscale-50"]}` },
            React.createElement(FtsWrapper, { gap: "lg", display: "flex" },
                React.createElement(LoadingSkeleton, { duration: 1, width: 32, height: 32 }),
                React.createElement(FtsWrapper, { display: "flex", align: "flex-start", justify: "center", direction: "column" },
                    React.createElement(LoadingSkeleton, { duration: 1, width: 240, height: 12 }),
                    React.createElement(LoadingSkeleton, { duration: 1, width: 120, height: 8 }))),
            React.createElement(LoadingSkeleton, { duration: 1, width: 85, height: 36 }))));
};
