/* eslint-disable indent */
import {
  SET_USER_ACTIVITIES,
  ADD_USER_ACTIVITY,
  CLEAR_USER_ACTIVITY_LIST,
} from "../../actions/user-activities";

const initialState = {
  activities: [],
};

const UserActivitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ACTIVITIES:
      return { ...state, activities: action.data };

    case ADD_USER_ACTIVITY: {
      return {
        ...state,
        activities: [...state.activities, action.data],
      };
    }

    case CLEAR_USER_ACTIVITY_LIST: {
      return {
        ...state,
        activities: [],
      };
    }

    default:
      return state;
  }
};

export default UserActivitiesReducer;
