import {
  RESET_ACCOUNT_STATE,
  SET_ACCOUNT_STATE,
} from "../../reducers/accounts";

export const setAccountSettingsState = (payload) => {
  return (dispatch) => {
    return dispatch({ type: SET_ACCOUNT_STATE, payload });
  };
};

export const resetAccountSettingsState = () => {
  return (dispatch) => {
    return dispatch({ type: RESET_ACCOUNT_STATE });
  };
};
