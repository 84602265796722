import React, { PureComponent } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextareaAutosize from "react-textarea-autosize";
import { Button, FormGroup, Spinner } from "reactstrap";
import classnames from "classnames";
import { nanoid } from "nanoid";

const formSchema = Yup.object().shape({
  reply: Yup.string().trim().required("Field is required"),
});

class NewReply extends PureComponent {
  render() {
    const {
      inProgress,
      alwaysActive,
      hideCancelBtn,
      onSubmitReply,
      autoFocus = false,
      toggleReply,
    } = this.props;

    return (
      <div className="fts-new-reply-wrapper">
        <div className="mt-1 mb-1">
          <Formik
            enableReinitialize
            initialValues={{ reply: "" }}
            validationSchema={formSchema}
            onSubmit={onSubmitReply}
          >
            {() => (
              <Form>
                <FormGroup>
                  <Field name="reply">
                    {({ field, form: { errors, touched } }) => (
                      <TextareaAutosize
                        autoFocus={autoFocus}
                        minRows={3}
                        className={classnames("form-control", {
                          "invalid-formik-input":
                            errors[field.name] && touched[field.name],
                        })}
                        type="textarea"
                        id={nanoid(3)}
                        rows="2"
                        placeholder="Leave note reply"
                        disabled={false}
                        {...field}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="reply"
                    render={(msg) => (
                      <div className="formik-validation-error">{msg}</div>
                    )}
                  />
                </FormGroup>

                <div className="mb-0">
                  <Button type="submit" color="primary" className="float-right">
                    {alwaysActive && inProgress ? (
                      <Spinner size="sm" color="white" />
                    ) : (
                      "Reply"
                    )}
                  </Button>
                  {!hideCancelBtn && (
                    <Button
                      type="button"
                      onClick={alwaysActive ? null : toggleReply}
                      color="secondary"
                      className="mr-1 float-right"
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default NewReply;
