import moment from "moment";

const generateMonths = () => {
  let formatted = {
    yearly: {},
    monthly: {},
    daily: {},
    weekly: {},
    counters: {
      yearly: 0,
      monthly: 0,
      weekly: 0,
      daily: 0,
    },
  };
  let months = [];
  let weekdays = [];
  let hours = [];

  for (let i = 0; i < 7; i++) {
    let now = moment().add(i, "days").format("ddd");
    weekdays.push(now);
    formatted.weekly[now] = 0;
  }

  for (let i = 0; i < 24; i++) {
    let now = moment().add(i, "hours").set("minutes", 0).format("HH:mm");
    hours.push(now);
    formatted.daily[now] = 0;
  }

  for (let i = 0; i < moment().daysInMonth(); i++) {
    formatted.monthly[
      moment().add(i, "days").format("W") - moment().format("W") + 1
    ] = 0;
  }

  for (let i = 0; i < 12; i++) {
    let now = moment().add(i, "months").format("MMM");
    formatted.yearly[now] = 0;
    months.push(now);
  }

  return { months, weekdays, hours, formatted };
};

export const formatResponseForChart = (res, dateKey = "createdAt") => {
  let { formatted } = generateMonths();

  for (let data of res) {
    let now = moment();
    const date = moment(data[dateKey]);
    const dataMonth = date.format("MMM");
    const dataDay = date.format("ddd");
    const dataHour = date.set("minute", 0).format("HH:mm");
    const dataWeek = date.format("W") - now.format("W") + 1;

    formatted.yearly[dataMonth] = formatted.yearly[dataMonth] + 1;
    formatted.counters["yearly"] = (formatted.counters["yearly"] || 0) + 1;

    if (
      moment().diff(moment(data[dateKey]), "days") <= moment().daysInMonth()
    ) {
      formatted.monthly[dataWeek] = (formatted.monthly[dataWeek] || 0) + 1;
      formatted.counters["monthly"] = (formatted.counters["monthly"] || 0) + 1;
    }

    if (moment().diff(moment(data[dateKey]), "days") <= 7) {
      formatted.weekly[dataDay] = (formatted.weekly[dataDay] || 0) + 1;
      formatted.counters["weekly"] = (formatted.counters["weekly"] || 0) + 1;
    }

    if (moment().diff(moment(data[dateKey]), "hours") <= 24) {
      formatted.daily[dataHour] = (formatted.daily[dataHour] || 0) + 1;
      formatted.counters["daily"] = (formatted.counters["daily"] || 0) + 1;
    }
  }
  return formatted;
};

export const formatListings = (res, dateKey = "createdAt") => {
  let formatted = {
    yearly: {},
    monthly: {},
    weekly: {},
    daily: {},
  };

  const updateObjectByTime = (data, timeFilter) => {
    if (data.activityType.includes("downgrade")) {
      formatted[timeFilter]["downgrade"] =
        (formatted[timeFilter]["downgrade"] || 0) + 1;
    }

    if (data.activityType.includes("upgrade")) {
      formatted[timeFilter]["upgrade"] =
        (formatted[timeFilter]["upgrade"] || 0) + 1;
    }

    formatted[timeFilter][data.activityType] =
      (formatted[timeFilter][data.activityType] || 0) + 1;
  };

  for (let data of res) {
    updateObjectByTime(data, "yearly");

    if (
      moment().diff(moment(data[dateKey]), "days") <= moment().daysInMonth()
    ) {
      updateObjectByTime(data, "monthly");
    }

    if (moment().diff(moment(data[dateKey]), "days") <= 7) {
      updateObjectByTime(data, "weekly");
    }

    if (moment().diff(moment(data[dateKey]), "hours") <= 24) {
      updateObjectByTime(data, "daily");
    }
  }
  return formatted;
};

export const defaultChartOptions = {
  chart: {
    background: "transparent",
    foreColor: "#7D8C91",
    toolbar: {
      show: false,
    },
    type: "line",

    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    curve: "straight",
    lineCap: "butt",
    colors: ["#F36565"],
    width: 3,
    dashArray: 0,
  },
  title: {
    text: "",
  },
  grid: {
    show: true,
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  xaxis: {
    categories: [
      "13:00",
      "15:00",
      "17:00",
      "19:00",
      "21:00",
      "23:00",
      "01:00",
      "03:00",
      "05:00",
      "07:00",
      "09:00",
      "11:00",
    ],
  },
};

export const formatResponseForRenewals = (
  res,
  dateKey = "createdAt",
  adminUserId,
  adminsCount,
) => {
  let defs = {
    mine: 0,
    others: 0,
    mineWon: 0,
    mineLost: 0,
    othersWon: 0,
    othersLost: 0,
  };
  let formatted = {
    yearly: { ...defs },
    monthly: { ...defs },
    weekly: { ...defs },
    daily: { ...defs },
  };

  for (let data of res) {
    let isLost = data.isPaid
      ? 1
      : data.isPaid && moment(data[dateKey]) < moment()
      ? 1
      : 0;

    let isMineRenewals = data.adminUserId === adminUserId;

    if (isMineRenewals) {
      formatted.yearly.mine += 1;
      formatted.yearly.mineWon += data.isPaid;
      formatted.yearly.mineLost += Math.abs(isLost - 1);

      if (
        moment().diff(moment(data[dateKey]), "days") <= moment().daysInMonth()
      ) {
        formatted.monthly.mine += 1;
        formatted.monthly.mineWon += data.isPaid;
        formatted.monthly.mineLost += Math.abs(isLost - 1);
      }

      if (moment().diff(moment(data[dateKey]), "days") <= 7) {
        formatted.weekly.mine += 1;
        formatted.weekly.mineWon += data.isPaid;
        formatted.weekly.mineLost += Math.abs(isLost - 1);
      }

      if (moment().diff(moment(data[dateKey]), "hours") <= 24) {
        formatted.daily.mine += 1;
        formatted.daily.mineWon += data.isPaid;
        formatted.daily.mineLost += Math.abs(isLost - 1);
      }
    } else {
      formatted.yearly.others += 1;
      formatted.yearly.othersWon += data.isPaid;
      formatted.yearly.othersLost += Math.abs(isLost - 1);

      if (
        moment().diff(moment(data[dateKey]), "days") <= moment().daysInMonth()
      ) {
        formatted.monthly.others += 1;
        formatted.monthly.othersWon += data.isPaid;
        formatted.monthly.othersLost += Math.abs(isLost - 1);
      }

      if (moment().diff(moment(data[dateKey]), "days") <= 7) {
        formatted.weekly.others += 1;
        formatted.weekly.othersWon += data.isPaid;
        formatted.weekly.othersLost += Math.abs(isLost - 1);
      }

      if (moment().diff(moment(data[dateKey]), "hours") <= 24) {
        formatted.daily.others += 1;
        formatted.daily.othersWon += data.isPaid;
        formatted.daily.othersLost += Math.abs(isLost - 1);
      }
    }
  }

  for (let [key] of Object.entries(formatted)) {
    let compWonAvg =
      (formatted[key].mineWon + formatted[key].othersWon) / adminsCount;

    let compLostAvg =
      (formatted[key].mineWon + formatted[key].othersWon) / adminsCount;
    formatted[key].won = (
      ((formatted[key].mineWon - compWonAvg) / compWonAvg) *
      100
    ).toFixed(1);

    formatted[key].lost = (
      ((formatted[key].mineWon - compLostAvg) / compLostAvg) *
      100
    ).toFixed(1);
  }

  return formatted;
};

export const formatResponseForNewBusinesses = (res, dateKey = "createdAt") => {
  // let { months } = generateMonths();
  let defs = {
    premium: 0,
    standard: 0,
    basicPlus: 0,
    basic: 0,
    free: 0,
    total: 0,
  };
  let formatted = {
    yearly: { ...defs },
    monthly: { ...defs },
    weekly: { ...defs },
    daily: { ...defs },
  };

  for (let data of res) {
    let plans = ["", "premium", "standard", "basicPlus", "basic", "free"];
    let plan = plans[data.priceId];

    formatted.yearly[plan] += 1;
    formatted.yearly.total += 1;

    if (
      moment().diff(moment(data[dateKey]), "days") <= moment().daysInMonth()
    ) {
      formatted.monthly[plan] += 1;
      formatted.monthly.total += 1;
    }

    if (moment().diff(moment(data[dateKey]), "days") <= 7) {
      formatted.weekly[plan] += 1;
      formatted.weekly.total += 1;
    }

    if (moment().diff(moment(data[dateKey]), "hours") <= 24) {
      formatted.daily[plan] += 1;
      formatted.daily.total += 1;
    }
  }

  return formatted;
};

export const generateAdminActivitiesTest = (
  adminActivities,
  dateKey = "createdAt",
) => {
  let formatted = {
    daily: {
      newActivities: [],
      pastActivities: [],
    },
    weekly: {
      newActivities: [],
      pastActivities: [],
    },
    monthly: {
      newActivities: [],
      pastActivities: [],
    },
    yearly: {
      newActivities: [],
      pastActivities: [],
    },
  };

  const updateNewActivities = (data, timeFilter) => {
    formatted[timeFilter].newActivities.push(data);
  };

  const updatePastActivities = (data, timeFilter) => {
    formatted[timeFilter].pastActivities.push(data);
  };

  const getPastRange = (quantity, interval) => {
    return {
      start: moment().subtract(quantity, interval),
      end: moment().subtract(1, interval),
    };
  };

  for (let data of adminActivities) {
    const date = moment(data[dateKey]);
    const currentYear = getPastRange(1, "years");
    const currentMonth = getPastRange(1, "months");
    const currentWeek = getPastRange(1, "week");
    const currentDay = getPastRange(1, "day");

    if (date >= currentYear.start) {
      updateNewActivities(data, "yearly");
    }

    if (date >= currentMonth.start) {
      updateNewActivities(data, "monthly");
    }

    if (date >= currentWeek.start) {
      updateNewActivities(data, "weekly");
    }

    if (date >= currentDay.start) {
      updateNewActivities(data, "daily");
    }
  }

  for (let data of adminActivities) {
    const date = moment(data[dateKey]);
    const pastYear = getPastRange(2, "years");
    const pastMonth = getPastRange(2, "months");
    const pastWeek = getPastRange(2, "week");
    const pastDay = getPastRange(2, "day");

    if (date >= pastYear.start && date <= pastYear.end) {
      updatePastActivities(data, "yearly");
    }

    if (date >= pastMonth.start && date <= pastMonth.end) {
      updatePastActivities(data, "monthly");
    }

    if (date >= pastWeek.start && date <= pastWeek.end) {
      updatePastActivities(data, "weekly");
    }

    if (date >= pastDay.start && date <= pastDay.end) {
      updatePastActivities(data, "daily");
    }
  }

  return formatted;
};

export const formatResponseForTodos = (res, dateKey = "createdAt") => {
  let formatted = {};
  let counters = { completed: 0, unCompleted: 0, total: 0 };
  let list = [
    "personal",
    "verify",
    "renewal",
    "payment",
    "trial",
    "project",
    "follow up",
    "image",
    "report",
    "alert",
    "reminder",
    "need to finish",
    "authorized",
    "lead",
  ];

  list.map((e) => {
    formatted.yearly = {
      ...formatted.yearly,
      [e]: { ...counters },
      total: 0,
      completedTotal: 0,
    };
    formatted.monthly = {
      ...formatted.monthly,
      [e]: { ...counters },
      total: 0,
      completedTotal: 0,
    };
    formatted.weekly = {
      ...formatted.weekly,
      [e]: { ...counters },
      total: 0,
      completedTotal: 0,
    };
    formatted.daily = {
      ...formatted.daily,
      [e]: { ...counters },
      total: 0,
      completedTotal: 0,
    };
    return null;
  });

  for (let data of res) {
    let isCompleted =
      data.todoStatus === "archived" ? "completed" : "unCompleted";

    formatted.yearly[data.todoType][isCompleted] += 1;
    formatted.yearly[data.todoType].total += 1;

    if (isCompleted === "completed") formatted.yearly.completedTotal += 1;
    formatted.yearly.total += 1;

    if (
      moment().diff(moment(data[dateKey]), "days") <= moment().daysInMonth()
    ) {
      formatted.monthly[data.todoType][isCompleted] += 1;
      formatted.monthly[data.todoType].total += 1;
      if (isCompleted === "completed") formatted.monthly.completedTotal += 1;
      formatted.monthly.total += 1;
    }

    if (moment().diff(moment(data[dateKey]), "days") <= 7) {
      formatted.weekly[data.todoType][isCompleted] += 1;
      formatted.weekly[data.todoType].total += 1;

      if (isCompleted === "completed") formatted.weekly.completedTotal += 1;
      formatted.weekly.total += 1;
    }

    if (moment().diff(moment(data[dateKey]), "hours") <= 24) {
      formatted.daily[data.todoType][isCompleted] += 1;
      formatted.daily[data.todoType].total += 1;
      if (isCompleted === "completed") formatted.daily.completedTotal += 1;
      formatted.daily.total += 1;
    }

    if (data.todoStatus !== "archived" && data.activities.length) {
      for (let activity of data.activities) {
        formatted.yearly[data.todoType].completed += 1;
        formatted.yearly[data.todoType].total += 1;
        formatted.yearly.completedTotal += 1;
        formatted.yearly.total += 1;

        if (
          moment().diff(moment(activity.createdAt), "days") <=
          moment().daysInMonth()
        ) {
          formatted.monthly[data.todoType].completed += 1;
          formatted.monthly[data.todoType].total += 1;
          formatted.monthly.completedTotal += 1;
          formatted.monthly.total += 1;
        }

        if (moment().diff(moment(activity.createdAt), "days") <= 7) {
          formatted.weekly[data.todoType].completed += 1;
          formatted.weekly[data.todoType].total += 1;
          formatted.weekly.completedTotal += 1;
          formatted.weekly.total += 1;
        }

        if (moment().diff(moment(activity.createdAt), "hours") <= 24) {
          formatted.daily[data.todoType].completed += 1;
          formatted.daily[data.todoType].total += 1;

          formatted.daily.completedTotal += 1;
          formatted.daily.total += 1;
        }
      }
    }
  }

  return formatted;
};
