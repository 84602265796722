import React from "react";
import "../../../assets/scss/chart-heading.scss";
const ChartHeading = ({ heading, subHeading, backCta }) => {
  return (
    <div className="FTS-chart-subheading">
      {backCta && backCta()}
      <div className="FTS-chart-subheading-flex">
        <h2>{heading}</h2>
      </div>
      <p>{subHeading}</p>
    </div>
  );
};

export default ChartHeading;
