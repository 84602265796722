import React, { PureComponent } from "react";
import { Card, CardBody } from "reactstrap";
import TaskManagementNew from "../task-management-new";
import "../../../assets/scss/components/fts/account-todos.scss";

class AccountTodos extends PureComponent {
  render() {
    let { accountId, accountInfo } = this.props;
    return (
      <Card className="account-todos-card">
        <CardBody className="fts-listing-tab-wrapper">
          <TaskManagementNew accountInfo={accountInfo} accountId={accountId} />
        </CardBody>
      </Card>
    );
  }
}

export default AccountTodos;
