export const formatInvoicesCounters = ({ subTabs, counts }) => {
  let allCount = 0;
  const countMapping = {
    2: "paidCount",
    3: "overdueCount",
    4: "pendingCount",
    5: "unsuccessfulCount",
    6: "voidedCount",
  };

  let newSubTabs = subTabs.map((tab) => {
    const countKey = countMapping[tab.id];
    if (!countKey) return tab;

    allCount += counts[countKey];
    return { ...tab, count: counts[countKey] };
  });

  newSubTabs[0].count = allCount;

  return newSubTabs;
};
