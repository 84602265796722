import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import CategoryCustomSelect from "../../../components/fts/category-custom-select";

import "../../../assets/scss/components/fts/form-fields.scss";
import TitleRow from "../TitleRow";
// import { limits } from "../category-custom-select/data";

class Services extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.categories !== nextProps.categories ||
      this.props.subCategories !== nextProps.subCategories ||
      this.props.editMode !== nextProps.editMode ||
      this.props.priceId !== nextProps.priceId
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      editMode,
      title,
      notWrapped,
      categories,
      subCategories,
      refer,
      isListing,
      right,
      changedServices,
    } = this.props;

    return (
      <div id="listing-type-services">
        {title &&
          (notWrapped ? (
            <Row>
              <Col>
                <h3 className={notWrapped ? "mb-0" : ""}>{title}</h3>
              </Col>
            </Row>
          ) : (
            <TitleRow title={title} />
          ))}
        <Row>
          <Col>
            <CategoryCustomSelect
              cur={refer}
              categories={categories}
              subCategories={subCategories}
              editMode={editMode}
              isListing={isListing}
              right={right}
              changedServices={changedServices}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.essentialLists.categories,
    subCategories: state.essentialLists.subCategories,
  };
};

export default connect(mapStateToProps)(React.memo(Services));
