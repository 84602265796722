import React, { memo, PureComponent } from "react";
import { FastField } from "formik";
import { FormInput } from "./formik-input";

class FormikFastInput extends PureComponent {
  render() {
    const { disabled, ...rest } = this.props;
    if (disabled)
      return (
        <FastField {...rest} {...{ disabled }} component={memo(FormInput)} />
      );
    else return <FastField {...rest} component={memo(FormInput)} />;
  }
}

export default FormikFastInput;
