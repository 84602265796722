import { Component } from "react";
import isEmpty from "lodash/isEmpty";

class FormValidationEffect extends Component {
  submitNumber = 0;

  componentDidUpdate() {
    if (
      !this.props.formik.isValid &&
      this.props.formik.submitCount > this.submitNumber &&
      !isEmpty(this.props.formik.errors)
    ) {
      this.submitNumber = this.submitNumber + 1;
      this.props.onSubmissionError(this.props.formik.errors);
    }
  }

  render() {
    return null;
  }
}

export default FormValidationEffect;
