import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { history } from "../../../history";

import { Badge, Popover, PopoverBody } from "reactstrap";

import FTSTableGroup from "../fts-table-group";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import { ContextMenu, hideMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";

import capitalize from "../../../utility/capitalize";
import "../../../assets/scss/plugins/tables/_agGridStyleOverride.scss";
import "../../../assets/scss/components/fts/updates-tab.scss";

import { FtsTags } from "@find-truck-service/ui/src/FtsTags/FtsTags";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Dropdown } from "@find-truck-service/ui/src/components/react/Dropdown";
import { CategoryWithSubCategoryDropdown } from "@find-truck-service/ui/src/components/react/CategoryWithSubCategoryDropdown";

import {
  onHoldColumns,
  // rejectedRequestsColumns,
  // sentRequestsColumns,
  // updateRequestsColumns,
  updatesColumns,
} from "./updates-columns";

import {
  getAllUpdates,
  getCountAll,
  getOnHoldUpdates,
  // getRejectedRequests,
  // getSentRequests,
  // getUpdateRequests,
} from "../../../redux/actions/updates";
import moment from "moment";
import Icons from "../Icons";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";

import {
  closeSideOutAction,
  openEditAccountSideOutAction,
  openEditListingSideOutAction,
} from "../../../redux/actions/v2/sideOut";

import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonVariants,
} from "@find-truck-service/types/ui/button";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsButton } from "@find-truck-service/ui/src/FtsButton/FtsButton";
import {
  InputColors,
  InputSizes,
  InputVariants,
} from "@find-truck-service/types/ui/input";
import { Input } from "@find-truck-service/ui/src/components/react/Input";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { getListingDataByIdSuccess } from "../../../redux/actions/v2/listings";
import FtsIcons from "@find-truck-service/ui/src/FtsIcons/FtsIcons";
import { EmptyComponent } from "@find-truck-service/ui/src/components/react/EmptyComponent";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

const defaultListingStatusFilter = { label: "Active", value: 1 };
class UpdatesTab extends Component {
  state = {
    subCategory: "",
    searchString: "",
    popoverTarget: "",
    popoverData: null,
    dateRange: null,
    apisReady: false,
    popoverOpen: false,
    activeTab: "180-365",
    from: 180,
    to: 365,
    firstTabCount: 0,
    secondTabCount: 0,
    thirdTabCount: 0,
    fourthTabCount: 0,
    fifthTabCount: 0,
    excludeInactive: false,
    currentRow: null,
    isOpen: false,
    sidebar: false,
    action: "",
    actionItem: null,
    listingSearchValue: "",
    filterCollapsed: true,
    statesValue: "",
    listingStatus: defaultListingStatusFilter,
    appliedFiltersCount: 1,
  };

  pageSizes = [10, 25, 50, 100];
  tableGroupRef = createRef();

  componentDidMount = () => {
    this.getTabsCounts();
  };

  openSidebar = (data, listing) => {
    if (listing) {
      this.callToAction("requestedChange", false, data);
    }
  };

  handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (bool === false && this.formikSidebarRef?.dirty && !closeWithoutPrompt) {
      return this.setState({ isOpen: true });
    } else {
      this.setState({ sidebar: bool, updating: !!update });
    }
  };

  closeSideOutAction = () => {
    this.setState({ sidebar: false, update: false, isOpen: false });
  };

  callToAction = (action, update, item) => {
    if (!action) return;
    this.setState({
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
    });
  };

  getTabsCounts = () => {
    const params = {
      searchString: rfc3986EncodeURIComponent(
        this.state.listingSearchValue,
      ).replace(/%40/g, "@"),
      subCategory: this.state.subCategory.length
        ? this.state.subCategory.map((e) => e.data.id).join(",")
        : undefined,
      state: this.state.statesValue?.value || "",
      adminUserId: this.state.adminUserId?.value || "",
      status: this.state.listingStatus?.value,
    };
    this.props.getCountAll({ from: 365, to: 0, ...params }).then(({ data }) => {
      this.setState({ firstTabCount: data.count });
    });
    this.props
      .getCountAll({ from: 180, to: 365, ...params })
      .then(({ data }) => {
        this.setState({ secondTabCount: data.count });
      });
    this.props
      .getCountAll({ from: 90, to: 180, ...params })
      .then(({ data }) => {
        this.setState({ thirdTabCount: data.count });
      });
    this.props.getCountAll({ from: 30, to: 90, ...params }).then(({ data }) => {
      this.setState({ fourthTabCount: data.count });
    });
    this.props.getCountAll({ to: 30, ...params }).then(({ data }) => {
      this.setState({ fifthTabCount: data.count });
    });
  };

  getTo = () => {
    return this.state.to;
  };

  collectDataForCopy = (event, call) => {
    this.setState({ collectedDataForCopy: event.data, call });
  };

  getTableData = (key, index, state) => {
    let params = {
      from: this.state.from,
      page: state[`page${capitalize(key, false)}`],
      resultsPerPage: state[`pageSize${capitalize(key, false)}`],
      sortColumn:
        state[
          `sort${capitalize(
            this.tableGroupRef?.state?.activeTab,
            false,
          )}Listing`
        ]?.colId,
      sortOrder:
        state[
          `sort${capitalize(
            this.tableGroupRef?.state?.activeTab,
            false,
          )}Listing`
        ]?.sort,
      searchString: rfc3986EncodeURIComponent(
        this.state.listingSearchValue,
      ).replace(/%40/g, "@"),
      subCategory: this.state.subCategory.length
        ? this.state.subCategory.map((e) => e.data.id).join(",")
        : undefined,
      state: this.state.statesValue?.value || "",
      adminUserId: this.state.adminUserId?.value || "",
      status: this.state.listingStatus?.value,
    };
    console.log("PARAMS: ", params);
    if (key === "updates") {
      return this.props.getAllUpdates(params);
      // } else if (key === "updateRequests") {
      //   return this.props.getUpdateRequests({ ...params, from: 0 });
      // } else if (key === "rejectedRequests") {
      //   return this.props.getRejectedRequests({ ...params, from: 0 });
      // } else if (key === "sentRequests") {
      //   return this.props.getSentRequests({ ...params, from: 0 });
    } else {
      return this.props.getOnHoldUpdates({ ...params, from: 0 });
    }
  };

  onRowClicked = (row) => {
    this.setState({
      currentRow: row,
    });
    const activeTab = this.tableGroupRef.state.activeTab;

    const adId = row.data.adId || row.data.id;
    if (activeTab === "updates" || activeTab === "onHold") {
      this.props.getListingDataByIdSuccess({ data: null });
      this.props.openEditListingSideOutAction({
        data: { id: adId },
        onSuccess: () => {
          this.tableGroupRef.onSubmitQuery(["updates", "onHold"]);
          this.props.closeSideOutAction();
        },
      });
      //
      // this.props.openEditAccountSideOutAction({ id: row.data.userId });

      // history.push({
      //   pathname: `/accounts/${userId}`,
      //   search: `?adId=${adId}`,
      // });
      // } else if (activeTab === "updateRequests") {
      //   this.openSidebar(row.data, true);
      // } else if (activeTab === "rejectedRequests") {
      //   let newData = { ...row.data, update: { ...row.data } };
      //   console.log(newData);
      //   this.openSidebar(newData, true);
    } else {
      history.push({
        pathname: `/listings/${adId}`,
      });
    }
  };

  getTableLayouts = () => {
    if (!this.tableLayouts) {
      const defaultTableLayout = {
        onRowClicked: this.onRowClicked,
        onCreateNew: this.onCreateNew,
        onGridApiReady: this.onGridApiReady,
        columnDefs: updatesColumns(
          this.collectDataForCopy,
          this.props.employees,
        ),
        getTableData: this.getTableData,
        pageSizes: this.pageSizes,
        hasSearch: false,
        initialTableData: null,
        initialPage: 1,
        initialPageSize: 10,
        initialPageCount: 0,
        initialTotalRecords: 0,
        initialSearchVal: this.state.searchString,
      };

      const togglePopOver = (popOverName, popoverData) => {
        if (popOverName) {
          return this.setState({
            popoverTarget: popOverName,
            popoverOpen: true,
            popoverData,
          });
        }
        return this.setState({
          popoverTarget: "",
          popoverOpen: false,
          popoverData: null,
        });
      };

      this.tableLayouts = {
        updates: {
          ...defaultTableLayout,
          columnDefs: updatesColumns(
            this.collectDataForCopy,
            this.props.employees,
          ),
          tabLabel: "Updates",
        },
        // updateRequests: {
        //   ...defaultTableLayout,
        //   columnDefs: updateRequestsColumns(
        //     this.collectDataForCopy,
        //     this.props.employees,
        //   ),
        //   tabLabel: "Update requests",
        // },
        // rejectedRequests: {
        //   ...defaultTableLayout,
        //   columnDefs: rejectedRequestsColumns(
        //     this.collectDataForCopy,
        //     this.collectDataForCopy,
        //     this.props.employees,
        //   ),
        //   tabLabel: "Rejected requests",
        // },
        // sentRequests: {
        //   ...defaultTableLayout,
        //   columnDefs: sentRequestsColumns(
        //     togglePopOver,
        //     this.collectDataForCopy,
        //     this.props.employees,
        //   ),
        //   tabLabel: "Sent requests",
        // },
        onHold: {
          ...defaultTableLayout,
          columnDefs: onHoldColumns(
            togglePopOver,
            this.collectDataForCopy,
            this.props.employees,
          ),
          tabLabel: "On hold",
        },
      };
    }

    return this.tableLayouts;
  };

  formatNumber = (number) => {
    return Intl.NumberFormat("en", { notation: "compact" })
      .format(number)
      .replace(/$/gi, "");
  };

  debounce = setTimeout(() => {}, 300);

  updateSearchQuery = (e) => {
    // if (isRefreshing) return;
    // rfc3986EncodeURIComponent();
    this.setState({ listingSearchValue: e.target.value });
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.tableGroupRef.onSubmitQuery([
        "updates",
        // "updateRequests",
        // "rejectedRequests",
        // "sentRequests",
        "onHold",
      ]);
      this.getTabsCounts();
    }, 300);
  };

  updateActiveTab = (type) => {
    const fromToArray = type.split("-");
    if (fromToArray.length === 1) {
      this.setState({
        from: type,
        to: 0,
      });
    } else {
      this.setState({
        from: fromToArray[0],
        to: fromToArray[1],
      });
    }
    this.setState(
      {
        activeTab: type,
        dateValue: null,
      },
      () => {
        this.applyFilters();
      },
    );
  };

  onSortChanged = (params) => {
    let sort = params.api.getSortModel();
    let { state } = this.tableGroupRef;
    let key = state.activeTab;
    this.tableGroupRef.setState({
      [`sort${capitalize(key, false)}Listing`]: sort[0],
    });
    this.tableGroupRef.onSubmitQuery([key]);
  };

  handleSubcategoryChange = (subCategories) => {
    return this.setState({ subCategory: subCategories });
  };
  handleStateChange = (option) => {
    this.setState({ statesValue: option || "" });
  };

  handleAdminOwnerChange = (option) => {
    this.setState({ adminUserId: option || "" });
  };
  handleListingStatusChange = (option) => {
    this.setState({ listingStatus: option || "" });
  };

  applyFilters = () => {
    let filtersCount = 0;
    if (this.state.subCategory) filtersCount++;
    if (this.state.statesValue) filtersCount++;
    if (this.state.adminUserId) filtersCount++;
    if (this.state.listingStatus) filtersCount++;

    this.tableGroupRef.onSubmitQuery([
      "updates",
      // "updateRequests",
      // "rejectedRequests",
      // "sentRequests",
      "onHold",
    ]);
    this.getTabsCounts();
    this.setState({ appliedFiltersCount: filtersCount });
  };

  resetFilters = () => {
    this.setState(
      {
        subCategory: "",
        listingSearchValue: "",
        statesValue: "",
        adminUserId: "",
        listingStatus: defaultListingStatusFilter,
      },
      this.applyFilters,
    );
  };

  render() {
    const {
      activeTab,
      firstTabCount,
      secondTabCount,
      thirdTabCount,
      fourthTabCount,
      fifthTabCount,
      currentRow,
      sidebar,
      action,
      updating,
      filterCollapsed,
    } = this.state;

    let tabs = [
      {
        label: "365+",
        type: "365",
        count: this.formatNumber(firstTabCount),
      },
      {
        label: "180-365",
        type: "180-365",
        count: this.formatNumber(secondTabCount),
      },
      {
        label: "90-180",
        type: "90-180",
        count: this.formatNumber(thirdTabCount),
      },
      {
        label: "30-90",
        type: "30-90",
        count: this.formatNumber(fourthTabCount),
      },
      {
        label: "0-30",
        type: "0-30",
        count: this.formatNumber(fifthTabCount),
      },
    ];

    // const title = "Updates";
    return (
      <div className="fts-updates-list">
        <FTSTableGroup
          onScroll={hideMenu}
          tableLayouts={this.getTableLayouts()}
          pageSizes={this.pageSizes}
          saveData={this.saveData}
          getTableData={this.getTableData}
          onRowClicked={this.onRowClicked}
          initialSearchQuery={{}}
          childrenPos="above"
          withinCard
          updatesTab
          getTo={this.getTo}
          onRef={(ref) => (this.tableGroupRef = ref)}
          onSortChanged={this.onSortChanged}
        >
          <FtsWrapper bg={ColorsValue["greyscale-0"]} py={"lg"} px={"2xl"}>
            <FtsWrapper
              display={"flex"}
              justify={"space-between"}
              align={"center"}
            >
              <Span
                variant={VariantsValues["text-xs"]}
                color={ColorsValue["greyscale-800"]}
                weight={WeightSize.semibold}
              >
                Updates
              </Span>

              <FtsWrapper display={"flex"}>
                <FtsWrapper
                  mr={"lg"}
                  display={"flex"}
                  bg={ColorsValue["greyscale-50"]}
                  borderRadius={"2xs"}
                >
                  {tabs.map((itm) => {
                    return (
                      <FtsWrapper
                        key={itm.type}
                        display={"flex"}
                        px={"sm"}
                        py={"xs"}
                        bg={activeTab === itm.type ? "greyscale-0" : ""}
                        borderRadius={"2xs"}
                        border={
                          activeTab === itm.type
                            ? `${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]}`
                            : ""
                        }
                        onClick={() => this.updateActiveTab(itm.type)}
                        cursor={"pointer"}
                        align={"center"}
                      >
                        <Span
                          variant={VariantsValues["text-3xs"]}
                          color={ColorsValue["greyscale-500"]}
                        >
                          {itm.label}
                        </Span>
                        <FtsTags
                          ml={"2xs"}
                          variant={"contained"}
                          color={
                            itm.count.indexOf("K") !== -1
                              ? ColorsValue["red-error-100"]
                              : ColorsValue["greyscale-100"]
                          }
                          size={"sm"}
                          textColor={ColorsValue["grayscale-0"]}
                        >
                          <Span
                            variant={VariantsValues["text-4xs"]}
                            color={ColorsValue["greyscale-800"]}
                          >
                            {itm.count}
                          </Span>
                        </FtsTags>
                      </FtsWrapper>
                    );
                  })}
                </FtsWrapper>
                <Input
                  name={"search"}
                  appendIcon={{ iconName: IconNames.Search }}
                  bg={ColorsValue["greyscale-50"]}
                  onChange={this.updateSearchQuery}
                  value={this.state.listingSearchValue}
                  placeholder={"Search..."}
                  variant={InputVariants.outlined}
                  size={InputSizes.sm}
                  color={InputColors.accent}
                />
                <FtsWrapper
                  cursor={"pointer"}
                  ml={"lg"}
                  onClick={() =>
                    this.setState({
                      filterCollapsed: !this.state.filterCollapsed,
                    })
                  }
                  display={"flex"}
                  gap={"xs"}
                  py={"sm"}
                  px={"md"}
                  bg={ColorsValue["blue-accent-50"]}
                  borderRadius={"2xs"}
                  border={
                    this.state.filterCollapsed
                      ? `${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]}`
                      : `${Spacing["3xs"]} solid ${ColorsValue["blue-accent-300"]}`
                  }
                >
                  <FtsIcons
                    iconName={IconNames.Filter}
                    svgProps={{
                      width: 20,
                      height: 20,
                    }}
                    pathProps={{
                      stroke: "#2a343e",
                    }}
                  />
                  <Span
                    variant={VariantsValues["text-3xs"]}
                    color={ColorsValue["greyscale-800"]}
                    lineHeightVariant={"relaxed"}
                  >
                    Filter
                  </Span>
                  {this.state.appliedFiltersCount ? (
                    <FtsTags
                      variant={"contained"}
                      size={"sm"}
                      textColor={ColorsValue["greyscale-0"]}
                      color={ColorsValue["blue-accent-500"]}
                    >
                      {this.state.appliedFiltersCount}
                    </FtsTags>
                  ) : (
                    <EmptyComponent />
                  )}
                </FtsWrapper>
              </FtsWrapper>
            </FtsWrapper>

            <FtsRow
              columnGap={"2xl"}
              bg={ColorsValue["greyscale-50"]}
              mt={"lg"}
              borderRadius={"2xs"}
              style={
                filterCollapsed
                  ? {
                      opacity: 0,
                      maxHeight: 0,
                      transition: "all 0.3s ease 0s",
                    }
                  : {
                      opacity: 1,
                      maxHeight: 150,
                      transition: "all 0.3s ease 0s",
                      padding: `${Spacing["2xl"]} ${Spacing["lg"]}`,
                    }
              }
            >
              <FtsColumn size={{ xs: 10 }}>
                <FtsRow columnGap={"lg"}>
                  <FtsColumn size={{ xs: 5 }}>
                    <CategoryWithSubCategoryDropdown
                      isMulti
                      label={"Services"}
                      placeholder="Select"
                      size={"md"}
                      value={this.state.subCategory}
                      onChange={this.handleSubcategoryChange}
                      options={this.props.categories}
                    />
                  </FtsColumn>
                  <FtsColumn size={{ xs: 2 }}>
                    <Dropdown
                      label={"State"}
                      size={"md"}
                      placeholder="Select"
                      onChange={this.handleStateChange}
                      value={this.state.statesValue}
                      options={this.props.states}
                    />
                  </FtsColumn>
                  <FtsColumn size={{ xs: 2 }}>
                    <Dropdown
                      label={"Status"}
                      size={"md"}
                      placeholder="Select"
                      onChange={this.handleListingStatusChange}
                      value={this.state.listingStatus}
                      options={this.props.listingStatuses}
                    />
                  </FtsColumn>
                  <FtsColumn size={{ xs: 3 }}>
                    <Dropdown
                      onChange={this.handleAdminOwnerChange}
                      options={this.props.employees?.filter(
                        (option) => option.status,
                      )}
                      label={"Assigned to"}
                      size={"md"}
                      placeholder="Select"
                      value={this.state.adminUserId}
                    />
                  </FtsColumn>
                </FtsRow>
              </FtsColumn>
              <FtsColumn
                size={{ xs: 2 }}
                display={"flex"}
                align={"flex-end"}
                justify={"end"}
              >
                <FtsWrapper>
                  <FtsButton
                    mr={"lg"}
                    onClick={() => {
                      this.resetFilters();
                      this.setState({
                        filterCollapsed: !this.state.filterCollapsed,
                      });
                    }}
                    variant={ButtonVariants.outlined}
                    color={ButtonColorsValue.error}
                    size={ButtonSizes.sm}
                    disabled={
                      !this.state.subCategory &&
                      !this.state.statesValue &&
                      !this.state.listingStatus &&
                      !this.state.adminUserId
                    }
                  >
                    {/*<Span variant={VariantsValues["text-3xs"]} color={"red-error-500"}>*/}
                    Reset
                    {/*</Span>*/}
                  </FtsButton>

                  <FtsButton
                    onClick={() => {
                      this.applyFilters();
                      this.setState({
                        filterCollapsed: !this.state.filterCollapsed,
                      });
                    }}
                    variant={ButtonVariants.contained}
                    color={ButtonColorsValue.accent}
                    size={ButtonSizes.sm}
                  >
                    Apply filter
                  </FtsButton>
                </FtsWrapper>
              </FtsColumn>
            </FtsRow>
          </FtsWrapper>

          <ActionSidebar
            setFormikSidebarRef={this.setFormikSidebarRef}
            resetFormikForm={this.resetFormikForm}
            show={sidebar}
            action={action}
            updating={updating}
            actionItem={this.state.actionItem}
            handleSidebar={this.handleSidebar}
            accountInfo={currentRow?.data?.listing?.user}
            showAccountBox={true}
            noMarker={true}
            tableGroupRef={this.tableGroupRef}
          />
          {this.state.popoverOpen && (
            <Popover
              popperClassName={"bg-popover"}
              style={{ minWidth: 400 }}
              placement="auto"
              isOpen={this.state.popoverOpen}
              target={this.state.popoverTarget}
              toggle={() => {}}
            >
              <p style={{ color: "#fff" }}>Automatic Email sent on:</p>

              <PopoverBody>
                {this.state.popoverData.length ? (
                  this.state.popoverData.map((e, i) => {
                    return (
                      <div
                        key={i}
                        style={{ color: "#fff" }}
                        className={"d-flex"}
                      >
                        <p
                          className={"mr-1"}
                          style={{ fontSize: 12, color: "#B1BABD" }}
                        >
                          #{e.emailId}
                        </p>
                        <p className={"mr-1"}>
                          {moment(e.updatedAt).format("lll")}
                        </p>
                        <p>{getBadge(e.activity)}</p>
                      </div>
                    );
                  })
                ) : (
                  <h4 style={{ color: "#fff" }}>No activity</h4>
                )}
              </PopoverBody>
            </Popover>
          )}
          <ContextMenu id="contextMenuUpdatesTab" preventHideOnScroll={false}>
            <MenuItem
              onClick={() =>
                navigator.clipboard.writeText(this.state.collectedDataForCopy)
              }
            >
              <FaRegCopy className="copy" />
              <span className="text-dark">Copy</span>
            </MenuItem>
            {this.state.call && this.state.collectedDataForCopy && (
              <MenuItem>
                <a
                  className="text-decoration-none text-dark d-flex align-items-center w-100"
                  href={`tel:${this.state.collectedDataForCopy}`}
                >
                  <Icons name="PhoneBlue" />
                  <div className="ml-1">
                    Call - {this.state.collectedDataForCopy}
                  </div>
                </a>
              </MenuItem>
            )}
          </ContextMenu>
        </FTSTableGroup>
      </div>
    );
  }
}

const getBadge = (activity) => {
  // const emailActivity = {
  //   // email related
  //   CREATED: "created",
  //   SENT: "sent",
  //   EMAIL_VIEWED: "email_viewed",
  //   LINK_OPENED: "link_opened",
  //   RESENT: "resent",
  // };

  switch (activity) {
    case "link_opened": {
      return (
        <Badge
          className={"rounded-pill "}
          style={{ padding: 5, backgroundColor: "#01B5F5", fontSize: 10 }}
        >
          OPENED
        </Badge>
      );
    }
    case "email_viewed": {
      return (
        <Badge
          className={"rounded-pill "}
          style={{ padding: 5, backgroundColor: "#F5B053", fontSize: 10 }}
        >
          VIEWED
        </Badge>
      );
    }
    case "sent": {
      return (
        <Badge
          className={"rounded-pill "}
          style={{ padding: 5, backgroundColor: "#8C919F", fontSize: 10 }}
        >
          SENT
        </Badge>
      );
    }
    default: {
      return null;
    }
  }
};

const mapStateToProps = (state) => {
  return {
    subCategories: state.essentialLists.subCategories,
    categories: state.essentialLists.categories.map((category) => ({
      ...category,
      options: category.subCategories,
    })),
    employees: state.essentialLists.employees,
    states: state.essentialLists.states,
    listingStatuses: state.essentialLists.listingStatuses,
  };
};

export default connect(mapStateToProps, {
  getCountAll,
  getAllUpdates,
  // getUpdateRequests,
  // getRejectedRequests,
  // getSentRequests,
  getOnHoldUpdates,
  openEditListingSideOutAction,
  openEditAccountSideOutAction,
  getListingDataByIdSuccess,
  closeSideOutAction,
})(UpdatesTab);
