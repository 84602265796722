/* eslint-disable indent */
/* eslint-disable no-extra-boolean-cast */
import React, { Component } from "react";
import { connect } from "react-redux";
// import { toast } from "react-toastify";
import { components } from "react-select";
// import moment from "moment";

// import { createTodo, assignTodoToAccount } from "../../../redux/actions/todo";
// import { getUserData, reassignUser } from "../../../redux/actions/users";

import { pickerStyles } from "../picker-style";
import Select from "../Select";
// import Avatar from "../../@vuexy/avatar/AvatarComponent";
// import { formatRawDate } from "../../../configs/dateConfig";
// import { history } from "../../../history";
import { updatePayment } from "../../../redux/actions/payments";
import ConfirmationModal from "../confirmation-modal";

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {children}
      </div>
    </components.ValueContainer>
  );
};

class PaidMethodPicker extends Component {
  state = {
    menuPlacement: "bottom",
    confirmationModalOpen: false,
    value: {},
  };
  wrapperRef = null;

  toggleConfirmationModal = () => {
    this.setState({
      confirmationModalOpen: !this.state.confirmationModalOpen,
    });
  };

  onChange = ({ value }) => {
    let { paymentId, updatePayment, refreshTable } = this.props;
    updatePayment({ paidMethod: value, paymentId })
      .then(() => {
        refreshTable();
      })
      .catch((e) => {
        console.log("ERROR: ", e);
      });
  };
  render() {
    const { placeholder } = this.props;

    let pickerOptions = [
      { label: "Card", value: "card" },
      { label: "ACH", value: "ach" },
      { label: "Check", value: "check" },
    ];

    const pickerStyle = {
      ...pickerStyles,
    };

    return (
      <div ref={(ref) => (this.wrapperRef = ref)}>
        <ConfirmationModal
          title="Confirmation"
          message={`Are you sure you want to change payment source to ${this.state.value.label}?`}
          closeModal={this.toggleConfirmationModal}
          confirmModal={() => {
            this.setState({ confirmationModalOpen: false }, () => {
              this.onChange(this.state.value);
            });
          }}
          isOpen={this.state.confirmationModalOpen}
        />
        <Select
          id="paid-method-picker"
          placeholder={placeholder}
          isClearable={false}
          isSearchable
          components={{
            ValueContainer: (props) => <ValueContainer {...props} />,
          }}
          onChange={(value) => {
            this.setState({ value, confirmationModalOpen: true });
          }}
          onMenuOpen={() => {
            let b = this.wrapperRef.getBoundingClientRect();
            if (b.top > 470) {
              this.setState({ menuPlacement: "top" });
            }
          }}
          options={pickerOptions}
          styles={pickerStyle}
          maxMenuHeight={200}
          menuPlacement={this.state.menuPlacement}
        />
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     userData: state.auth.login.values.loggedInUser,
//     types: state.essentialLists.types,
//   };
// };

const mapDispatchToProps = {
  updatePayment,
};

export default connect(null, mapDispatchToProps)(PaidMethodPicker);
