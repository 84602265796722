import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsChipsSizes, FtsChipsVariants } from "@find-truck-service/types/ui/chips";
const defaultStyle = `
display: flex;
align-items: center;
width: fit-content;
height: fit-content;
border-radius: ${Spacing["2xs"]};
`;
export const FtsChipsStyles = (props) => {
    let style = {
        [FtsChipsVariants.outlined]: {
            [FtsChipsSizes.sm]: `${defaultStyle}; padding: ${Spacing["2xs"]}; outline: ${Spacing["4xs"]} ${props.color} solid;`,
            [FtsChipsSizes.md]: `${defaultStyle}; padding: ${Spacing["xs"]}; outline: ${Spacing["4xs"]} ${props.color} solid;`,
            [FtsChipsSizes.lg]: `${defaultStyle}; padding: ${Spacing["sm"]}; outline: ${Spacing["4xs"]} ${props.color} solid;`,
        },
        [FtsChipsVariants.contained]: {
            [FtsChipsSizes.sm]: `${defaultStyle}; padding: ${Spacing["2xs"]};background:${props.color}`,
            [FtsChipsSizes.md]: `${defaultStyle}; padding: ${Spacing["xs"]};background:${props.color}`,
            [FtsChipsSizes.lg]: `${defaultStyle}; padding: ${Spacing["sm"]};background:${props.color}`,
        },
    };
    return style[props.variant][props.size];
};
