//Available opt in options
export const checkboxOptLabels = ["Marketing", "Renewal", "Update", "Repair"];

export const mobileOptIn = [
  "mobileMarketingOptIn",
  "mobileRenewalsOptIn",
  "mobileUpdatesOptIn",
];
export const primaryOptIn = [
  "primaryMarketingOptIn",
  "primaryRenewalsOptIn",
  "primaryUpdatesOptIn",
];
export const additionalOptIn = [
  "additionalMarketingOptIn",
  "additionalRenewalsOptIn",
  "additionalUpdatesOptIn",
];

export const defaultCardData = {
  cardHolder: "",
  cardNumber: "",
  expirationDate: "",
  cvv: "",
  zipCode: "",
  isDefault: false,
};

export const accountToggles = [
  {
    name: "excludeRenewalEmails",
    label: "Renewal",
    isReverseLogic: true,
  },
  {
    name: "excludeDowngrades",
    label: "Downgrade",
    isReverseLogic: true,
  },
  {
    name: "adminCorporateAccount",
    label: "Corporate",
  },
  {
    name: "adminIsMerchant",
    label: "AVP Banner",
  },
];

export const socialLinkOptions = [
  { label: "Google maps", name: "google" },
  { label: "Facebook", name: "facebook" },
  { label: "Instagram", name: "instagram" },
  { label: "X", name: "twitter" },
  { label: "LinkedIn", name: "linkedIn" },
  { label: "Other", name: "other" },
];

export const renewalOptIn = [
  "mobileRenewalsOptIn",
  "primaryRenewalsOptIn",
  "additionalRenewalsOptIn",
];
