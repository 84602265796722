import React from "react";
import { connect } from "react-redux";
import { Label } from "reactstrap";

import { pickerStyles, controlStyles } from "../picker-style";
import { components } from "react-select";
import Select from "../Select";

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};

const AdminPicker = ({ label, name, list, onChange, haveSystem, ...rest }) => {
  const pickerStyle = {
    ...pickerStyles,
    borderWidth: 0,
    control: (styles, state) => controlStyles(styles, state),
  };

  let options = list;

  options = options.sort(function (a, b) {
    return b.status - a.status;
  });

  if (haveSystem) {
    options = [...options, { label: "System", value: "system" }];
  }

  return (
    <div style={{ width: "240px" }}>
      <div>
        {!!label && (
          <Label className={rest.isDisabled ? "disabled" : ""} for={name}>
            {label}
          </Label>
        )}
      </div>
      <div>
        <Select
          {...{ label }}
          isSearchable={true}
          isClearable={true}
          options={options}
          styles={pickerStyle}
          name={name}
          components={{
            ValueContainer: (props) => <ValueContainer {...props} />,
            Option,
          }}
          onChange={onChange}
          {...rest}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.login.values.loggedInUser,
  list: state.essentialLists.employees,
});

export default connect(mapStateToProps, null)(AdminPicker);

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div style={{ color: props.data?.status === 0 && "#BEBEBE" }}>
          {props.children}
        </div>
      </components.Option>
    </>
  );
};
