import React from "react";
import { Component } from "react";
import classnames from "classnames";
import "./index.scss";
import MaskedInput from "react-text-mask";

export default class SearchInput extends Component {
  state = {
    isFocused: false,
    hide: true,
  };

  handleAutoFill = (e) => {
    this.setState({
      hide: e.animationName === "onAutoFillStart",
    });
  };

  render() {
    const { isFocused } = this.state;

    return (
      <>
        <div
          className={`search-input search-input-group ${
            isFocused ? "search-input-group-focused" : ""
          } ${
            typeof this.props.sidebarCollapsed !== "undefined" &&
            !this.props.sidebarCollapsed
              ? "search-input-sidebar-collapsed"
              : ""
          }`}
        >
          {!!this.props.icon && (
            <div className={isFocused ? "icon-focused" : "icon"}>
              {this.props.icon}
            </div>
          )}

          <div
            className={classnames("search-input__input", {
              "search-input__input--placeholder": !!this.props.placeholder,
            })}
          >
            {this.props.masked ? (
              <MaskedInput
                className="input"
                name={this.props.name}
                value={this.props.value}
                placeholder=""
                mask={this.props.mask}
                onChange={this.props.onInputUpdate}
                autoComplete="maskedInput"
                onBlur={() => {
                  this.setState({
                    isFocused: false,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    isFocused: true,
                  });
                }}
              />
            ) : (
              <input
                className="input"
                name={this.props.name}
                value={this.props.value}
                type={this.props.type}
                onChange={(e) => {
                  this.props.onInputUpdate(e);
                }}
                onBlur={() => {
                  this.setState({
                    isFocused: false,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    isFocused: true,
                  });
                }}
                autoComplete="text"
              />
            )}

            {this.props.placeholder && (
              <div
                className={classnames("placeholder", {
                  isFocused: isFocused || this.props.value,
                })}
              >
                {this.props.placeholder}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
