import React from "react";
import Icon from "../../../../../components/fts/Icons";

class TodoTypesSection extends React.Component {
  render() {
    const { todoTypes, formattedTodos, activeTab } = this.props;
    if (!formattedTodos) return <></>;

    return (
      <div className="todo-types-section">
        {todoTypes &&
          todoTypes.map((type) => {
            return (
              <div key={type.description} className="todo-type-row">
                <div>
                  <span
                    className="todo-type-color"
                    style={{ background: type.color }}
                  >
                    {" "}
                  </span>
                  <span
                    style={
                      formattedTodos[activeTab][type.value].completed ===
                        formattedTodos[activeTab][type.value].total &&
                      formattedTodos[activeTab][type.value].total
                        ? {
                            color: "#7D8C91",
                            textDecoration: "line-through",
                          }
                        : {}
                    }
                  >
                    {type.description}
                  </span>
                </div>
                <span>
                  {formattedTodos[activeTab][type.value].total ? (
                    <span
                      style={
                        formattedTodos[activeTab][type.value].completed ===
                        formattedTodos[activeTab][type.value].total
                          ? {
                              color: "#98CA86",
                            }
                          : {}
                      }
                    >
                      {formattedTodos[activeTab][type.value].completed ===
                        formattedTodos[activeTab][type.value].total && (
                        <span style={{ marginRight: 8 }}>
                          <Icon name="CheckGreen" size={15} />
                        </span>
                      )}
                      {formattedTodos[activeTab][type.value].completed} /{" "}
                      {formattedTodos[activeTab][type.value].total}
                    </span>
                  ) : (
                    <strong> No tasks</strong>
                  )}
                </span>
              </div>
            );
          })}
      </div>
    );
  }
}

export default TodoTypesSection;
