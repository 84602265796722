import React, { Component } from "react";

import "../../../assets/scss/components/fts/listing-item.scss";
import { Button } from "reactstrap";
import Icon from "../Icons";
import { LISTING_TYPE, LISTING_IMAGE_SIZES } from "../../../constants";
import WorkingHoursPreview from "../working-hours-preview";
import ListingItemActionBar from "../listing-item-action-bar";
import "react-quill/dist/quill.snow.css";
import "../../../assets/scss/components/fts/listing-description.scss";
import { formatImageLink } from "../../../utility";

import PremiumImage from "../../../assets/img/svg/Icon/PremiumImage.svg";
import StandardImage from "../../../assets/img/svg/Icon/StandardImage.svg";
import BasicPlusImage from "../../../assets/img/svg/Icon/BasicPlusImage.svg";

class ListingItem extends Component {
  state = {
    collapsed: false,
    collapseButtonText: "Expand Actions",
  };

  getDefaultImage = (priceId) => {
    switch (priceId) {
      case 1:
        return PremiumImage;
      case 2:
        return StandardImage;
      case 3:
        return BasicPlusImage;
      default:
        return null;
    }
  };

  handleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      collapseButtonText: this.state.collapsed
        ? "Expand Actions"
        : "Collapse Actions",
    });
  };

  getMaxHeight = (priceId) => {
    switch (priceId) {
      case 1:
        return 244;
      case 2:
        return 120;
      case 3:
        return 60;

      default:
        return 0;
    }
  };

  render() {
    const {
      name,
      // id,
      distance,
      address,
      city,
      state,
      alwaysOpen,
      image,
      priceId,
      description,
      includeInAuthorizedPromotions,
      fuelPrice,
      listingCurrentTime,
      // promotionsCount,
      // seoUrlIdentifier,
    } = this.props.data;
    const { collapsed, collapseButtonText } = this.state;
    let workingHours = this.props.workingHours || this.props.data.workingHours;

    const inCity = true;
    const miles = distance?.toFixed(2);
    const imageSize = LISTING_IMAGE_SIZES[priceId];
    const fuel = fuelPrice > 0 && fuelPrice?.toFixed(2);
    const hasImageAndDescription =
      LISTING_TYPE.FREE !== priceId && LISTING_TYPE.BASIC !== priceId;
    const isFree = LISTING_TYPE.FREE === priceId;
    const freeOrBasic =
      priceId === LISTING_TYPE.FREE || priceId === LISTING_TYPE.BASIC;

    const removeStyleAttribute = (html) => {
      const regex = /style="[^"]+"/;
      return html.replaceAll(new RegExp(regex, "g"), "");
    };

    return (
      <div
        className={`fts-listing-item ${
          priceId === 5 && "fts-listing-item-free"
        }`}
      >
        <div
          className={`fts-listing-item-content ${
            freeOrBasic ? "align-items-center" : "align-items-start"
          }`}
        >
          <div className="fts-listing-item-info">
            <div className="title-row">
              <div className="title">
                <span>{name}</span>
              </div>
              {includeInAuthorizedPromotions && (
                <div className="authorized">
                  <span className="card-icon">
                    <Icon name="CreditCardRed" size="32" />
                  </span>
                  <span>Authorizied Vendor</span>
                  <span className="info-icon">
                    <Icon name="IconInfo" size="13" />
                  </span>
                </div>
              )}
            </div>
            <div className="info-row">
              {!inCity && (
                <div className="info-row-item">
                  <Icon name="Miles" size="16" />
                  <span className="text">{miles} Miles</span>
                </div>
              )}
              <div className="info-row-item">
                <span>
                  <Icon name="MapPin" size="16" />
                </span>
                <span className="text">{`${address}, ${city}, ${state}`} </span>
              </div>
              <div className="info-row-item">
                <Icon name="Clock" size="16" />
                <span className="text">
                  {workingHours && (
                    <WorkingHoursPreview
                      listingCurrentTime={listingCurrentTime}
                      alwaysOpen={alwaysOpen}
                      workingHours={workingHours}
                    />
                  )}
                </span>
              </div>
              {fuel && (
                <div className="info-row-item">
                  <Icon name="Fuel" size="16" />
                  <span className="text">Fuel: ${fuel}</span>
                </div>
              )}
            </div>

            {hasImageAndDescription && (
              <div className="mt-6 d-flex image-and-description">
                <div
                  className="flex justify-center cursor-pointer"
                  style={{ marginRight: "32px", marginBottom: "16px" }}
                >
                  {imageSize && (
                    <img
                      width={imageSize.width}
                      height={imageSize.height}
                      src={
                        image
                          ? formatImageLink(image)
                          : this.getDefaultImage(priceId)
                      }
                      alt={name}
                    />
                  )}
                </div>

                <div
                  id={"listing_item_descriptions"}
                  className="description"
                  style={{
                    maxHeight: this.getMaxHeight(priceId),
                    overflow: "hidden",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: removeStyleAttribute(description),
                  }}
                />
              </div>
            )}
          </div>
          {!isFree && (
            <div
              className="fts-listing-item-buttons"
              style={{
                width: "145px",
              }}
            >
              <Button className="contact-button">Contact</Button>
              {/* <Button
                className="start-repair-button"
                style={{ marginTop: "12px" }}
              >
                Start Repair
              </Button> */}
            </div>
          )}

          {isFree && (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!collapsed && (
                <div
                  className={"collapse-button no-select not-collapsed"}
                  onClick={() => {
                    this.handleCollapse();
                  }}
                >
                  <Icon name="CollapseArrow" size="12" />
                  <span>{collapseButtonText}</span>
                </div>
              )}
              {collapsed && (
                <div
                  className={`fts-listing-item-buttons flex ${
                    isFree && "flex-col align-items-center"
                  }`}
                >
                  <Button
                    className="contact-button"
                    style={{ marginRight: "12px" }}
                  >
                    Contact
                  </Button>
                  {/* <Button className="start-repair-button">Start Repair</Button> */}
                </div>
              )}
            </div>
          )}
        </div>

        <ListingItemActionBar isFree={isFree} hideActions={!collapsed} />
      </div>
    );
  }
}

export default ListingItem;
