import { DEFAULT_GET_ERROR_MSG, DEFAULT_UPDATE_ERROR_MSG } from "./constants";
import { USER_UPDATED_SUCCESSFULLY } from "../altContacts/constants";
import { call, put, takeEvery, take } from "redux-saga/effects";
import { handleResponseError } from "../../../handlers/error";
import { saveAltContactsAction } from "../../actions/v2/altContacts";
import { closeSubSideOutAction } from "../../actions/v2/sideOut";
import { formatUserDataForUpdate } from "./helpers";
import FTSAxios from "../../../axios/fts.instance";
import { toast } from "react-toastify";
import {
  getAccountDataByIdFiledAction,
  getAccountDataByIdSuccessAction,
  updateAccountDataByIdFailedAction,
  updateAccountDataByIdSuccessAction,
} from "../../actions/v2/account";
import {
  GET_ACCOUNT_DATA_BY_ID_REQUEST,
  UPDATE_ACCOUNT_DATA_BY_ID_REQUEST,
} from "../../reducers/v2/account";
import {
  SAVE_ALT_CONTACTS_FAILED,
  SAVE_ALT_CONTACTS_SUCCESS,
} from "../../reducers/v2/altContacts";

function* getAccountDataByIdSaga(payload) {
  try {
    const result = yield call(FTSAxios.get, `admin/users/${payload.payload}`);
    yield put(getAccountDataByIdSuccessAction(result.data));
  } catch (e) {
    const msg = handleResponseError(e, true, DEFAULT_GET_ERROR_MSG);
    yield put(getAccountDataByIdFiledAction(msg));
  }
}

function* updateUserDataByIdSaga({ payload: data }) {
  try {
    yield put(saveAltContactsAction(data.id));
    yield take(SAVE_ALT_CONTACTS_SUCCESS);
    const payload = formatUserDataForUpdate(data);
    const result = yield call(FTSAxios.put, `admin/users/${data.id}`, payload);
    yield put(updateAccountDataByIdSuccessAction(result.data.user));
    yield put(closeSubSideOutAction());
    toast.success(USER_UPDATED_SUCCESSFULLY);
  } catch (e) {
    handleResponseError(e, true, DEFAULT_UPDATE_ERROR_MSG);
    yield put(updateAccountDataByIdFailedAction());
  }
}

function* handleSaveAltContactsFailedSaga() {
  yield put(updateAccountDataByIdFailedAction());
}

export default function* accountWatcher() {
  yield takeEvery(GET_ACCOUNT_DATA_BY_ID_REQUEST, getAccountDataByIdSaga);
  yield takeEvery(UPDATE_ACCOUNT_DATA_BY_ID_REQUEST, updateUserDataByIdSaga);
  yield takeEvery(SAVE_ALT_CONTACTS_FAILED, handleSaveAltContactsFailedSaga);
}
