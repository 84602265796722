import {
  DELETE_ALT_CONTACT_FAILED,
  DELETE_ALT_CONTACT_REQUEST,
  DELETE_ALT_CONTACT_SUCCESS,
  GET_ALT_CONTACTS_BY_ID_FAILED,
  GET_ALT_CONTACTS_BY_ID_REQUEST,
  GET_ALT_CONTACTS_BY_ID_SUCCESS,
  SAVE_ALT_CONTACTS_FAILED,
  SAVE_ALT_CONTACTS_REQUEST,
  SAVE_ALT_CONTACTS_SUCCESS,
  SET_ALT_CONTACT,
  SET_ALT_CONTACT_DATA_INVALID_FORM_FIELDS,
  SET_ALT_CONTACTS,
  SET_CANCEL_EDITING_ALT_CONTACT,
  UPDATE_ALT_CONTACT_FAILED,
  UPDATE_ALT_CONTACT_REQUEST,
  UPDATE_ALT_CONTACT_SUCCESS,
} from "../../../reducers/v2/altContacts";

//API actions

export const getAltContactsByIdAction = (payload) => ({
  type: GET_ALT_CONTACTS_BY_ID_REQUEST,
  payload,
});

export const getAltContactsByIdSuccessAction = (payload) => ({
  type: GET_ALT_CONTACTS_BY_ID_SUCCESS,
  payload,
});

export const getAltContactsByIdFailedAction = (payload) => ({
  type: GET_ALT_CONTACTS_BY_ID_FAILED,
  payload,
});

export const saveAltContactsAction = (payload) => ({
  type: SAVE_ALT_CONTACTS_REQUEST,
  payload,
});

export const saveAltContactsSuccessAction = (payload) => ({
  type: SAVE_ALT_CONTACTS_SUCCESS,
  payload,
});

export const saveAltContactsFailedAction = () => ({
  type: SAVE_ALT_CONTACTS_FAILED,
});

export const updateAltContactAction = (payload) => ({
  type: UPDATE_ALT_CONTACT_REQUEST,
  payload,
});

export const updateAltContactSuccessAction = () => ({
  type: UPDATE_ALT_CONTACT_SUCCESS,
});

export const updateAltContactFailedAction = () => ({
  type: UPDATE_ALT_CONTACT_FAILED,
});

export const deleteAltContactAction = (payload) => ({
  type: DELETE_ALT_CONTACT_REQUEST,
  payload,
});

export const deleteAltContactSuccessAction = () => ({
  type: DELETE_ALT_CONTACT_SUCCESS,
});

export const deleteAltContactFailedAction = () => ({
  type: DELETE_ALT_CONTACT_FAILED,
});

//Setter actions

export const setAltContactsAction = (data) => ({
  type: SET_ALT_CONTACTS,
  data,
});

export const setAltContactAction = (data) => ({
  type: SET_ALT_CONTACT,
  data,
});

export const cancelEditingAltContactAction = () => ({
  type: SET_CANCEL_EDITING_ALT_CONTACT,
});

export const setAltContactInvalidFormFieldsAction = (data) => ({
  type: SET_ALT_CONTACT_DATA_INVALID_FORM_FIELDS,
  data,
});
