import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";

import FTSNote from "../../../../components/fts/fts-note";

import "../../../../assets/scss/components/fts/form-fields.scss";

class Note extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      editMode,
      noteLabelValue,
      setFieldError,
      setFieldTouched,
      isAddNoteBtn = true,
      noSticky = true,
      isNewNote = true,
      typeName,
      name,
      keys,
    } = this.props;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <span
              className={`${
                keys && keys.includes("adminNote") && "field-changed"
              }`}
            >
              <FTSNote
                noteRequired={this.props.noteRequired}
                disabled={!editMode}
                label="Note"
                labelId="todoNote"
                typePlaceholder="Note type..."
                placeholder="Some note goes here..."
                stickyName="noteIsSticky"
                {...{
                  setFieldError,
                  setFieldTouched,
                  noteLabelValue,
                  isAddNoteBtn,
                  noSticky,
                  isNewNote,
                  typeName,
                  name,
                }}
              />
            </span>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default Note;
