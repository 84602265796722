import React, { Component } from "react";
import { connect } from "react-redux";
import { Phone, User, MapPin, Briefcase, Hash, Mail } from "react-feather";
import { Row, Col, FormGroup, Button, CustomInput } from "reactstrap";

import StatesPicker from "../../../components/fts/states-picker";
import StatusFilter from "../../../components/fts/status-filter";
import { phoneNumberMask } from "../../../utility/masks";
import ActivationPicker from "../../../components/fts/activation-picker";
import ServicesPicker from "../../../components/fts/services-picker/index";
import AmenitiesPicker from "../../../components/fts/amenities-picker/index";
import "../../../assets/scss/components/fts/search-filter.scss";
import * as Icon from "react-feather";
import SearchInput from "../../../components/fts/search-input/index";

class SearchFilter extends Component {
  state = {
    collapsed: this.props.collapse,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.collapse !== this.props.collapse) {
      this.setState({ collapsed: this.props.collapse });
    }
  }

  render() {
    const {
      query,
      onInputUpdate,
      updateState,
      updateStatus,
      updateActivationStatus,
      inProgress,
      clearAllFilters,
      startSearching,
      toggleCollapse,
    } = this.props;

    return (
      <>
        <div className="search-header">
          <p className="search-title">Search for accounts and listings</p>
          <button
            className="colapse-button"
            onClick={() => {
              toggleCollapse();
            }}
          >
            {this.state.collapsed ? (
              <Icon.ChevronUp size={17} className="chevron" />
            ) : (
              <Icon.ChevronDown size={17} className="chevron" />
            )}
            {this.state.collapsed ? "Collapse Filters" : "Expand Filters"}
          </button>
        </div>

        {this.state.collapsed ? (
          <div className="collapse-row">
            <Row>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<Hash size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="id"
                    value={query.id}
                    placeholder="ID"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<Briefcase size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="name"
                    value={query.name}
                    placeholder="Company Name"
                    sidebarCollapsed={
                      this.props.app.customizer.sidebarCollapsed
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<Phone size={15} />}
                    masked
                    mask={phoneNumberMask}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="phoneNumber"
                    value={query.phoneNumber}
                    placeholder="Phone"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<MapPin size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="address"
                    value={query.address}
                    placeholder="Address"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<MapPin size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="city"
                    value={query.city}
                    placeholder="City"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <StatesPicker
                    name="state"
                    isSearch
                    value={query.state}
                    placeholder="State"
                    onStateChange={(option) => {
                      updateState(option);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<User size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="firstName"
                    value={query.firstName}
                    placeholder="First Name"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<User size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="text"
                    name="lastName"
                    value={query.lastName}
                    placeholder="Last Name"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <SearchInput
                    icon={<Mail size={15} />}
                    onInputUpdate={onInputUpdate}
                    type="email"
                    name="email"
                    value={query.email}
                    placeholder="Email"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup className="position-relative has-icon-left">
                  <StatusFilter
                    isSearch
                    placeholder="Label"
                    value={query.adminStatus}
                    onStateChange={(option) => {
                      updateStatus(option);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <ActivationPicker
                  isDisabled={false}
                  isSearch
                  placeholder="Status"
                  isClearable
                  name="status"
                  value={query.status}
                  onStateChange={(option) => {
                    updateActivationStatus(option);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ServicesPicker
                  query={query}
                  onSelect={(param) => {
                    onInputUpdate({
                      target: { value: param, name: "subCategory" },
                    });
                  }}
                />
              </Col>
              <Col md={6}>
                <AmenitiesPicker
                  query={query}
                  onSelect={(param) => {
                    onInputUpdate({
                      target: { value: param, name: "serviceAmenities" },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className={"mt-2"}>
              <Col md={4} className="d-flex align-items-center">
                <CustomInput
                  checked={!!query.isPrivate}
                  type="switch"
                  id="isPrivate"
                  name="isPrivate"
                  inline
                  onChange={() =>
                    onInputUpdate({
                      target: {
                        value: !query.isPrivate,
                        name: "isPrivate",
                      },
                    })
                  }
                />
                <span className="switch-text">Show private locations only</span>
              </Col>
              <Col md={4} className="d-flex align-items-center">
                <CustomInput
                  checked={query.includeInAuthorizedPromotions ? 1 : 0}
                  type="switch"
                  id="includeInAuthorizedPromotions"
                  name="includeInAuthorizedPromotions"
                  inline
                  onChange={() =>
                    onInputUpdate({
                      target: {
                        value: query.includeInAuthorizedPromotions ? 0 : 1,
                        name: "includeInAuthorizedPromotions",
                      },
                    })
                  }
                />
                <span className="switch-text">
                  Show authorized vendors only
                </span>
              </Col>
              <Col
                md={4}
                className="d-flex align-items-center justify-content-end"
              >
                <div className="d-flex">
                  <Button
                    type="button"
                    className="clear-button"
                    color="white"
                    onClick={clearAllFilters}
                    disabled={inProgress}
                  >
                    Clear
                  </Button>
                  <Button
                    type="button"
                    className="search-button"
                    color="primary"
                    onClick={startSearching}
                  >
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.customizer,
  };
};
export default connect(mapStateToProps, {})(SearchFilter);
