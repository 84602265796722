/* eslint-disable indent */
/* eslint-disable no-extra-boolean-cast */
import React, { Component } from "react";
import "../../../assets/scss/components/fts/sales-reassign-dropdown.scss";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { Scrollbars } from "react-custom-scrollbars";
import { reassignTo } from "../../../redux/actions/users";
import { checkPermissions } from "../../../utility/permissionsCheck";
import { Spinner } from "reactstrap";

class SalesReassignDropdown extends Component {
  state = {
    inProgress: false,
  };

  render() {
    const {
      list,
      dropdownRef,
      isOpenReassignDropdown,
      handleDropdown,
      selectedAccounts,
      customers,
      reassignTo,
      activeLeadsPage,
    } = this.props;

    let employeeList = [...list];

    if (customers) {
      employeeList = employeeList.filter((emp) => emp.value !== 0);
    }

    employeeList = employeeList.filter((option) => option.status);

    if (reassignTo && activeLeadsPage) {
      const unassignedIndex = employeeList.findIndex((emp) => {
        return emp.value === 0;
      });

      employeeList[unassignedIndex] = { value: 0, label: "Unassign" };
    }

    return (
      <div ref={dropdownRef} className="sales-reassign-wrapper">
        {checkPermissions(
          [{ id: 4, subCat: [54] }],
          this.props.adminPermissions
        ) && (
          <div
            className={`sales-reassign-dropdown no-select ${
              !selectedAccounts?.length && "disabled"
            }`}
            onClick={() => {
              selectedAccounts?.length && handleDropdown();
            }}
          >
            {this.state.inProgress ? (
              <Spinner size="sm" color="light" />
            ) : (
              "Reassign to"
            )}
          </div>
        )}
        {isOpenReassignDropdown && (
          <div className="reassign-dropdown">
            <Scrollbars style={{ height: "300px", width: "100%" }}>
              {employeeList.map((employee) => {
                return (
                  <div
                    key={employee.value}
                    className="employee"
                    onClick={() => {
                      const payload = {
                        userIds: selectedAccounts,
                        adminId: employee.value,
                      };

                      this.setState({
                        inProgress: true,
                      });
                      handleDropdown();

                      this.props
                        .reassignTo(payload)
                        .then(() => {
                          toast.success(
                            `${
                              selectedAccounts.length === 1 ? "User" : "Users"
                            } successfully ${
                              employee.value === 0 ? "unassigned" : "assigned"
                            }!`,
                            {
                              position: toast.POSITION.TOP_RIGHT,
                            }
                          );

                          this.setState({
                            inProgress: false,
                          });
                          this.props.onReassign();
                        })
                        .catch((err) => {
                          console.log("err: ", err);
                          toast.error("Something went wrong. Try again.", {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                          this.setState({
                            inProgress: false,
                          });
                        });
                    }}
                  >
                    {employee.label}
                  </div>
                );
              })}
            </Scrollbars>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.essentialLists.employees,
    adminPermissions: state.auth.login.permissions,
  };
};

export default connect(mapStateToProps, { reassignTo })(SalesReassignDropdown);
