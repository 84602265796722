import FTSAxios from "../../../axios/fts.instance";

export const getAllUpdates = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    return FTSAxios.get("admin/updates/all", { params: queryParams });
  };
};

export const getUpdateRequests = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    return FTSAxios.get("admin/updates/requested", { params: queryParams });
  };
};

export const getRejectedRequests = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    return FTSAxios.get("admin/updates/rejected", { params: queryParams });
  };
};

export const getSentRequests = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    return FTSAxios.get("admin/updates/sent", { params: queryParams });
  };
};

export const getCountAll = (params) => {
  return () => {
    const queryParams = { ...params };
    return FTSAxios.get("admin/updates/count-all", { params: queryParams });
  };
};

export const getOnHoldUpdates = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page, onHold: true };
    return FTSAxios.get("admin/updates/all", { params: queryParams });
  };
};

export const putUpdateOnHold = (params) => {
  return () => {
    return FTSAxios.post("/admin/updates/on-hold", { ...params });
  };
};

export const updateRequestStatus = (params) => {
  return () => {
    return FTSAxios.put("/admin/updates/update-status", { ...params });
  };
};

export const updateRequestUpdate = (payload) => {
  return () => {
    return FTSAxios.put("/admin/updates/request-update", payload);
  };
};

export const cancelRequestUpdate = (payload) => {
  return () => {
    return FTSAxios.put("/admin/request-update/cancel", payload);
  };
};

export const getUnassignedUpdatesCount = () => {
  return () => {
    return FTSAxios.get("admin/updates/unassinged/count");
  };
};
