import FTSAxios from "../../../axios/fts.instance";

export const getListings = () => {
  return () => FTSAxios.get("admin/dashboard/listings");
};

export const getLeads = () => {
  return () => FTSAxios.get("admin/dashboard/leads");
};

export const getCustomerRenewals = () => {
  return () => FTSAxios.get("admin/dashboard/renewals");
};

export const getRenewals = () => {
  return () => FTSAxios.get("admin/dashboard/getAdminRenewals");
};

export const getNewBusinesses = () => {
  return () => FTSAxios.get("admin/dashboard/newBusinesses");
};

export const getAdminActivities = () => {
  return () => FTSAxios.get("admin/dashboard/getUserActivities");
};

export const getTodos = () => {
  return () => FTSAxios.get("admin/dashboard/todos");
};
