import React from "react";
import { Link } from "react-router-dom";
import {
  CardBody,
  FormGroup,
  Form,
  Input,
  Button,
  Label,
  Alert,
} from "reactstrap";
import Checkbox from "../../../../components/@vuexy/checkbox/CheckboxesVuexy";
import { Mail, Check } from "react-feather";

import { connect } from "react-redux";
import { loginWithJWT } from "../../../../redux/actions/auth/loginActions";

import Icon from "../../../../components/fts/Icons";
import collectEssentialData from "../../../../axios/collect-data";
import { handleError } from "../../../../handlers/error";
import { history } from "../../../../history";
import FTSAxios from "../../../../axios/fts.instance";
import FTSCookies, { TEMPORARY_TOKEN } from "../../../../cookies";

class LoginJWT extends React.Component {
  state = {
    email: "",
    password: "",
    remember: false,
    inProgress: false,
    error: null,
  };

  handleLogin = async (e) => {
    try {
      if (this.state.inProgress) {
        return;
      }

      this.setState({ inProgress: true, error: null });
      e.preventDefault();
      let { data } = await this.props.loginWithJWT(this.state);
      if (data.mfaRequired) {
        await FTSCookies.set(TEMPORARY_TOKEN, data.mfaHash, { path: "/" });
        return history.push("/login-mfa");
      }
      var loggedInUser;
      let res = await FTSAxios.get("admin/user");
      loggedInUser = res?.data?.user;
      this.props.mapDispatchToProps({
        type: "LOGIN_WITH_JWT",
        payload: { loggedInUser, loggedInWith: "jwt" },
      });
      await collectEssentialData(this.props.mapDispatchToProps);
      history.push("/");
    } catch (e) {
      handleError(e);
      const { response } = e;
      this.setState({
        error: {
          status: response?.status,
          message:
            response?.data?.errors?.message ||
            response?.data?.error?.message ||
            `Failed to login: ${
              response?.status === 500 ? "Connection Error" : "Server Error"
            }.`,
        },
      });
    } finally {
      this.setState({ inProgress: false });
    }
  };
  render() {
    const { error } = this.state;

    return (
      <React.Fragment>
        {!!error && (
          <CardBody className="p-1">
            <Alert color="danger">{error.message}</Alert>
          </CardBody>
        )}
        <CardBody className="pt-1">
          <Form action="/" onSubmit={this.handleLogin}>
            <FormGroup className="form-label-group position-relative has-icon-left">
              <Input
                type="email"
                placeholder="Email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                required
              />
              <div className="form-control-position">
                <Mail size={19} />
              </div>
              <Label>Email</Label>
            </FormGroup>
            <FormGroup className="form-label-group position-relative has-icon-left">
              <Input
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
                required
              />
              <div className="form-control-position">
                <Icon name="Password" size={19} />
              </div>
              <Label>Password</Label>
            </FormGroup>
            <FormGroup className="d-flex justify-content-between align-items-center">
              <Checkbox
                color="primary"
                icon={<Check className="vx-icon" size={16} />}
                label="Remember me"
                defaultChecked={false}
                onChange={this.handleRemember}
              />
              <div className="float-right">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </FormGroup>
            <div className="d-flex justify-content-between">
              <Button color="primary" type="submit" className="w-100">
                {this.state.inProgress ? "Please wait..." : "Login"}
              </Button>
            </div>
          </Form>
        </CardBody>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    values: state.auth.login,
  };
};

const mapDispatchToProps = (dispatch) => dispatch;

export default connect(mapStateToProps, { loginWithJWT, mapDispatchToProps })(
  LoginJWT,
);
