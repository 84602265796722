import React from "react";
import { ContextMenuTrigger } from "react-contextmenu";

export const defineNewListingsColumns = (collectDataForCopy, employees) => {
  let columns = [
    {
      headerName: "CID",
      field: "userId",
      sortable: true,
      unSortIcon: true,
      width: 193,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.userId}
          collect={collectDataForCopy}
          id="contextMenuUpgrades"
        >
          <span>{params.data.userId}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Listing ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.id}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "Assigned to",
      field: "adminAccountOwner",
      sortable: true,
      unSortIcon: true,
      width: 193,
      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data.adminAccountOwner,
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Unassigned"}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{admin?.label || "Unassigned"}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "nameForSort",
      sortable: true,
      unSortIcon: true,
      width: 386,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpgrades"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.city}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "addressForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.address}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.address}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email",
      field: "emailForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuUpgrades"
          >
            <span>{params.data.phoneNumber}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "After hours",
      field: "afterHoursForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.afterHours}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.afterHours || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
  ];

  return columns;
};
