import React, { Component } from "react";
import { Label, FormGroup, Button } from "reactstrap";
import { X } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import ActivationPicker from "../../../components/fts/activation-picker";

const formSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

class AmenitiesSidebar extends Component {
  addNew = false;

  initialData = {
    name: "",
    status: { label: "Disabled", value: 0 },
  };

  componentDidUpdate() {
    this.addNew = false;
  }

  handleSubmit = this.props.onServiceAmenityUpdate;

  render() {
    const statusOptions = [
      { label: "Disabled", value: 0 },
      { label: "Enabled", value: 1 },
      { label: "Inactive", value: 2 },
    ];

    const { show, handleSidebar, data } = this.props;
    const initValues = data
      ? { ...data, enabled: !!data.enabled }
      : this.initialData;

    return (
      <div
        className={classnames("data-list-sidebar", {
          show: show,
        })}
      >
        <div className="data-list-sidebar-header px-2 d-flex justify-content-between">
          <h4>{data !== null ? "Update amenity" : "Submit new amenity"}</h4>
          <X size={20} onClick={() => handleSidebar(false, true)} />
        </div>
        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          validationSchema={formSchema}
          initialValues={initValues}
        >
          {({ errors, touched }) => (
            <Form style={{ height: "100%" }}>
              <PerfectScrollbar
                className="data-list-fields px-2 mt-3"
                options={{ wheelPropagation: false }}
              >
                <FormGroup>
                  <Label for="cat-id">ID</Label>
                  <Field
                    disabled
                    placeholder="eg. 12"
                    name="id"
                    className="form-control"
                    id="cat-id"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-label">Name</Label>
                  <Field
                    name="name"
                    placeholder="eg. Towing"
                    id="cat-label"
                    className={`form-control ${
                      errors.name && touched.name && "is-invalid"
                    }`}
                  />
                </FormGroup>
                <FormGroup>
                  <Field name="status" id="cat-status">
                    {({ form, field: { value, name } }) => {
                      return (
                        <ActivationPicker
                          label="Status"
                          {...{ value, name }}
                          statusOptions={statusOptions}
                          onChange={(option) =>
                            form.setFieldValue("status", option)
                          }
                        />
                      );
                    }}
                  </Field>
                </FormGroup>
              </PerfectScrollbar>

              <div className="data-list-sidebar-footer px-2 d-flex justify-content-end align-items-center mt-1">
                <Button color="primary" type="submit">
                  {data !== null ? "Update" : "Submit"}
                </Button>
                <Button
                  type="button"
                  className="ml-1"
                  color="danger"
                  outline
                  onClick={() => handleSidebar(false, true)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default AmenitiesSidebar;
