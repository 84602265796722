import { createSelector } from "reselect";

//API
export const GET_ALT_CONTACTS_BY_ID_REQUEST =
  "ALT_CONTACTS/GET_ALT_CONTACTS_BY_ID_REQUEST";
export const GET_ALT_CONTACTS_BY_ID_SUCCESS =
  "ALT_CONTACTS/GET_ALT_CONTACTS_BY_ID_SUCCESS";
export const GET_ALT_CONTACTS_BY_ID_FAILED =
  "ALT_CONTACTS/GET_ALT_CONTACTS_BY_ID_FAILED";

export const SAVE_ALT_CONTACTS_REQUEST =
  "ALT_CONTACTS/SAVE_ALT_CONTACTS_REQUEST";
export const SAVE_ALT_CONTACTS_SUCCESS =
  "ALT_CONTACTS/SAVE_ALT_CONTACTS_SUCCESS";
export const SAVE_ALT_CONTACTS_FAILED = "ALT_CONTACTS/SAVE_ALT_CONTACTS_FAILED";

export const DELETE_ALT_CONTACT_REQUEST =
  "ALT_CONTACTS/DELETE_ALT_CONTACT_REQUEST";
export const DELETE_ALT_CONTACT_SUCCESS =
  "ALT_CONTACTS/DELETE_ALT_CONTACT_SUCCESS";
export const DELETE_ALT_CONTACT_FAILED =
  "ALT_CONTACTS/DELETE_ALT_CONTACT_FAILED";

export const UPDATE_ALT_CONTACT_REQUEST =
  "ALT_CONTACTS/UPDATE_ALT_CONTACT_REQUEST";
export const UPDATE_ALT_CONTACT_SUCCESS =
  "ALT_CONTACTS/UPDATE_ALT_CONTACT_SUCCESS";
export const UPDATE_ALT_CONTACT_FAILED =
  "ALT_CONTACTS/UPDATE_ALT_CONTACT_FAILED";

//store
export const SET_ALT_CONTACT_DATA_INVALID_FORM_FIELDS =
  "ALT_CONTACTS/SET_ALT_CONTACT_DATA_INVALID_FORM_FIELDS";
export const SET_CANCEL_EDITING_ALT_CONTACT =
  "ALT_CONTACTS/SET_CANCEL_EDITING_ALT_CONTACT";
export const SET_ALT_CONTACTS = "ALT_CONTACTS/SET_ALT_CONTACTS";
export const SET_ALT_CONTACT = "ALT_CONTACTS/SET_ALT_CONTACT";

const initialState = {
  isLoading: false,
  error: null,
  altContact: null,
  altContacts: null,
  invalidAltContactFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    //API
    case GET_ALT_CONTACTS_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        invalidAltContactFormFields: null,
      };

    case GET_ALT_CONTACTS_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        altContacts: action.payload,
        error: null,
        invalidAltContactFormFields: null,
      };

    case GET_ALT_CONTACTS_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        invalidAltContactFormFields: null,
      };

    case DELETE_ALT_CONTACT_SUCCESS:
    case DELETE_ALT_CONTACT_FAILED:
    case UPDATE_ALT_CONTACT_SUCCESS:
    case UPDATE_ALT_CONTACT_FAILED:
    case SAVE_ALT_CONTACTS_FAILED:
      return { ...state, invalidAltContactFormFields: null, isLoading: false };

    case SAVE_ALT_CONTACTS_SUCCESS:
      return {
        ...state,
        altContacts: action.payload,
        invalidAltContactFormFields: null,
        isLoading: false,
      };

    //STORE
    case SET_ALT_CONTACT_DATA_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidAltContactFormFields: action.data,
      };

    case SET_CANCEL_EDITING_ALT_CONTACT:
      return {
        ...state,
        altContact: null,
        invalidAltContactFormFields: null,
      };

    case SET_ALT_CONTACTS:
      return {
        ...state,
        altContacts: action.data,
        invalidAltContactFormFields: null,
        altContact: null,
      };

    case SET_ALT_CONTACT:
      return {
        ...state,
        altContact: action.data,
        invalidAltContactFormFields: null,
      };

    default:
      return state;
  }
}

const selectAltContactsState = (state) => state.altContactsState;

export const selectAltContactsLoading = createSelector(
  selectAltContactsState,
  (contacts) => {
    return contacts.isLoading;
  },
);

export const selectAltContactsError = createSelector(
  selectAltContactsState,
  (contacts) => {
    return contacts.error;
  },
);

export const selectAltContacts = createSelector(
  selectAltContactsState,
  (contacts) => {
    return contacts.altContacts;
  },
);

export const selectAltContactsLength = createSelector(
  selectAltContactsState,
  (contacts) => {
    return contacts.altContacts?.length ?? 0;
  },
);

export const selectAltContact = createSelector(
  selectAltContactsState,
  (contacts) => {
    return contacts.altContact;
  },
);

export const selectAltContactId = createSelector(
  selectAltContactsState,
  (contacts) => {
    return contacts.altContact?.id;
  },
);

export const selectAltContactInvalidFormFields = createSelector(
  selectAltContactsState,
  (contacts) => {
    return contacts.invalidAltContactFormFields;
  },
);
