import React, { PureComponent } from "react";

import CountBox from "../CountBox";

import "../../../../assets/scss/plugins/charts/apex-charts.scss";
import Chart from "react-apexcharts";
class FTSChart extends PureComponent {
  render() {
    const { label, count } = this.props;
    return (
      <div>
        <CountBox label={label} count={count} size="sm" />
        <div>
          <Chart
            options={this.props.options}
            series={this.props.series}
            type="line"
            height="100%"
            width="100%"
          />
        </div>
      </div>
    );
  }
}

export default FTSChart;
