import React, { Component } from "react";
import AccountNotFound from "../../../components/fts/account-not-found";

class AccountEmptyState extends Component {
  render() {
    return <AccountNotFound />;
  }
}

export default AccountEmptyState;
