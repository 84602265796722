/* eslint-disable indent */
/* eslint-disable no-extra-boolean-cast */
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { components } from "react-select";
import moment from "moment";

import { createTodo, assignTodoToAccount } from "../../../redux/actions/todo";
import { getUserData, reassignUser } from "../../../redux/actions/users";
import { createActivity } from "../../../redux/actions/activities";

import { pickerStyles, controlStyles } from "../picker-style";
import Select from "../Select";
import Avatar from "../../@vuexy/avatar/AvatarComponent";
import { formatRawDate } from "../../../configs/dateConfig";
import { history } from "../../../history";

import { activities } from "../../../constants";

const ValueContainer = ({ children, hideInnerLabel, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      {!hideInnerLabel && (
        <div style={{ paddingRight: "0.5rem" }}>Assigned to:</div>
      )}
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {children}
      </div>
    </components.ValueContainer>
  );
};

class SalesEmployeePicker extends Component {
  assignToAccount = (toDoId, accountIds) => {
    return this.props.assignTodoToAccount(toDoId, accountIds);
  };

  state = {
    menuPlacement: "bottom",
    value: "",
  };
  wrapperRef = null;

  componentDidMount() {
    const { list, assignedTo, hideDisabledUsers } = this.props;

    let pickerOptions = list;

    let defaultValue =
      assignedTo && pickerOptions?.length
        ? typeof assignedTo === "number"
          ? pickerOptions?.find((itm) => itm.value === assignedTo)
          : null
        : null;

    if (assignedTo === 0) {
      defaultValue = list[0];
    }

    if (hideDisabledUsers) {
      pickerOptions = pickerOptions.filter((option) => option.status);
    } else {
      pickerOptions = pickerOptions.sort(function (a, b) {
        return b.status - a.status;
      });
    }

    this.setState({ value: defaultValue });
  }

  render() {
    const {
      label,
      isDisabled,
      placeholder,
      meta,
      hideInnerLabel,
      onBlur,
      list,
      isMulti,
      closeMenuOnSelect,
      showAssigned,
      userId,
      companyName,
      refreshTable,
      customersPage,
      activeLeadsPage,
      isClearable,
      hideCurrentAdmin,
      hideDisabledUsers,
    } = this.props;

    let salesTodoId = this.props.types.filter((type) => {
      return type.type !== "note" && type.description === "Lead";
    })[0]?.id;

    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) => controlStyles(styles, state, {}, { meta }),
    };

    let pickerOptions = !hideCurrentAdmin
      ? list
      : list.filter((admin) => admin.value !== this.state.value.value);

    if (hideDisabledUsers) {
      pickerOptions = pickerOptions.filter((option) => option.status);
    } else {
      pickerOptions = pickerOptions.sort(function (a, b) {
        return b.status - a.status;
      });
    }

    if (activeLeadsPage) {
      pickerOptions = pickerOptions.filter((user) => user.value !== 0);
    }

    let isLoading = false;

    if (!pickerOptions?.length) {
      isLoading = true;
    }
    let { value } = this.state;
    return (
      <div ref={(ref) => (this.wrapperRef = ref)}>
        <Select
          {...{
            onBlur,
            isLoading,
            isDisabled,
            isMulti,
            label,
          }}
          defaultValue={value}
          value={value}
          id="employee-picker"
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable
          components={
            showAssigned
              ? {
                  ValueContainer: (props) => (
                    <ValueContainer {...props} {...{ hideInnerLabel }} />
                  ),
                  Option,
                }
              : Option
          }
          onChange={(value) => {
            if (customersPage) {
              this.setState({ value });
              this.props.updateAdminAccountOwner(value);
            } else if (activeLeadsPage) {
              this.setState({ value });
              this.props.updateAdminAccountOwner(value);
            } else {
              const reassignPayload = {
                adminAccountOwner: value?.value ? value.value : 0,
                userId: userId,
              };
              this.props
                .reassignUser(reassignPayload)
                .then(() => {
                  let { userData, createActivity } = this.props;
                  if (!value?.value) {
                    toast.success("Account successfully unassigned", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }
                  if (
                    this.state.value.value &&
                    value.value !== this.state.value.value
                  ) {
                    let activity = activities.unassignedAccountUser
                      .replace(
                        "{{admin_name}}",
                        `${userData.firstName} ${userData.lastName}`,
                      )
                      .replace(
                        "{{assigned_admin}}",
                        `${this.state.value.label}`,
                      );
                    const createActivityPayload = {
                      userId,
                      status: 2,
                      activityType:
                        this.props.activitiesTypes.UNASSIGNED_ACCOUNT_USER,
                      activity,
                      adminUserId: userData.id,
                      createdAt: moment().subtract(1, "second").toISOString(),
                      iconName: "AccountsActivity",
                    };
                    createActivity(createActivityPayload)
                      .then(() => {
                        refreshTable && refreshTable();
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }
                  if (value.value && value.value !== this.state.value.value) {
                    let activity = activities.assignedAccountUser
                      .replace(
                        "{{admin_name}}",
                        `${userData.firstName} ${userData.lastName}`,
                      )
                      .replace("{{assigned_admin}}", `${value.label}`);
                    const createActivityPayload = {
                      userId,
                      status: 2,
                      activityType:
                        this.props.activitiesTypes.ASSIGNED_ACCOUNT_USER,
                      activity,
                      adminUserId: value.value,
                      iconName: "AccountsActivity",
                    };
                    createActivity(createActivityPayload)
                      .then(() => {})
                      .catch((e) => {
                        console.log(e);
                      });
                  }
                  this.setState({ value });
                  if (value?.value) {
                    let adminInfo = {
                      firstName: value.label.split(" ")[0],
                      lastName: value.label.split(" ")[1],
                    };
                    const followUpTodoPayload = {
                      adminIds: [],
                      listingIds: [],
                      ownerId: userId,
                      userId,
                      admin_owner_id: value.value,
                      adminOwnerId: value.value,
                      reminderDate: moment().format("YYYY-MM-DD"),
                      reminderTime: moment().format("kk:mm"),
                      title: `Lead todo - Lead on ${companyName}`,
                      todo: "test",
                      todoPriority: "",
                      todoStatus: "active",
                      todoType: "lead",
                      todo_type_id: salesTodoId,
                      todoForSales: true,
                    };
                    this.props
                      .createTodo(followUpTodoPayload)
                      .then(async ({ data: { todo } }) => {
                        this.assignToAccount(todo.id, [userId]);
                        refreshTable && refreshTable(userId);
                        toast.info(
                          <TodoNotification
                            adminInfo={adminInfo}
                            companyName={companyName}
                            onClick={() =>
                              history.push(
                                `/accounts/${userId}?toDoId=${todo.id}`,
                              )
                            }
                          />,
                        );
                      });
                  }
                  this.setState({ inProgress: false });
                })
                .catch((err) => {
                  console.log(err);
                  toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                });
            }
          }}
          onMenuOpen={() => {
            let b = this.wrapperRef.getBoundingClientRect();
            if (b.top > 420) {
              this.setState({ menuPlacement: "top" });
            }
          }}
          options={pickerOptions}
          styles={pickerStyle}
          maxMenuHeight={200}
          menuPlacement={this.state.menuPlacement}
          // menuPortalTarget={target}
          {...{ closeMenuOnSelect }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values.loggedInUser,
    types: state.essentialLists.types,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, {
  getUserData,
  reassignUser,
  createTodo,
  assignTodoToAccount,
  createActivity,
})(SalesEmployeePicker);

const TodoNotification = ({ adminInfo, companyName, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-white"
      style={{ margin: "-8px -20px -8px -8px" }}
    >
      <div
        style={{
          backgroundColor: "#01B5F5",
          minWidth: 450,
          padding: "5px 0px",
        }}
        className="d-flex justify-content-center "
      >
        <h4 className={"text-white m-0"}>NOTIFICATION</h4>
      </div>
      <div style={{ padding: "10px 20px" }}>
        <div className="fts-todo__owner d-flex align-items-center">
          <Avatar
            fullName={`${adminInfo.firstName} ${adminInfo.lastName}`}
            content={`${adminInfo.firstName[0]}${adminInfo.lastName[0]}`.toUpperCase()}
            className="m-0"
            size={"lg"}
          />
          <div className="ml-1">
            <h4
              className="d-inline-block m-0"
              style={{ fontSize: 20 }}
            >{`${adminInfo.firstName} ${adminInfo.lastName}`}</h4>
            <div style={{ color: "#444", fontSize: 12 }}>
              {formatRawDate(new Date())}
            </div>
          </div>
        </div>
        <h5 className="mt-1">
          TODO created for{" "}
          <span style={{ textDecoration: "underline" }}>{companyName}</span>
        </h5>
      </div>
    </div>
  );
};

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div style={{ color: props.data?.status === 0 && "#BEBEBE" }}>
          {props.children}
        </div>
      </components.Option>
    </>
  );
};
