import React from "react";

const Action = ({ icon, title, ...rest }) => {
  return (
    <div className="fts-action-bar-item" {...rest}>
      <div className="fts-action-icon">{icon}</div>
      <span className="fts-action-title">{title}</span>
    </div>
  );
};

export default Action;
