import React from "react";
import { X } from "react-feather";

import "../../assets/scss/components/fts/close-button.scss";

const CloseButton = ({ className, ...rest }) => {
  return (
    <div
      className={`fts-close-btn ${
        className ?? ""
      }`}
      {...rest}
    >
      <X size={20} />
    </div>
  );
};

export default CloseButton;
