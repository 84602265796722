import styled from "styled-components";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
import { FtsChipsStyles } from "./Styled";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
export const FtsChips = styled(FtsWrapper) `
  ${FtsChipsStyles};
  svg {
    width: ${(props) => SvgIconSizeValues[props.size]};
    height: ${(props) => SvgIconSizeValues[props.size]};
  }
`;
