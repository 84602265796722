const accountAccessRequestsStatuses = {
  pending: 1,
  granted: 2,
  rejected: 3,
};

const DEFAULT_TABLE_DATA = {
  RESULTS_PER_PAGE: 20,
  CURRENT_PAGE: 1,
  SEARCH_STRING: "",
  PAGE_COUNT: 0,
  SORT_MODEL: [],
};

const INVOICES_STATUSES = {
  PAID: "Paid",
  OVERDUE: "Overdue",
  PENDING: "Pending",
  UNSUCCESSFUL: "Unsuccessful",
  VOIDED: "Voided",
};

const INVOICES_SEARCH_FIELDS = [
  "statusForString",
  "owner.assignedAdmins.firstName",
  "owner.assignedAdmins.lastName",
  "invoiceNumber",
  "totalForSort",
  "dueAtString",
  "paidAtString",
  "payments.paidMethod",
  "payments.paidSource",
  "ownerIdForSort",
  "owner.city",
  "owner.state",
  "listingIdForSort",
  "activities.activityNote",
];
const PAGE_SIZES = [10, 20, 25, 50, 100];

export {
  accountAccessRequestsStatuses,
  DEFAULT_TABLE_DATA,
  PAGE_SIZES,
  INVOICES_STATUSES,
  INVOICES_SEARCH_FIELDS,
};
