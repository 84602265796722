import moment from "moment";
import FTSAxios from "../axios/fts.instance";
import { capitalizeFirstLetter } from "./googleCalendar";

const folderPathImage = `images/${moment().format("YYYY")}/${moment().format(
  "MM",
)}/${moment().format("DD")}`;

export const getUploadUrl = async ({ filePath, mimeType }) => {
  try {
    let {
      data: { uploadUrl },
    } = await FTSAxios.get("admin/get/upload-url", {
      params: { filePath, mimeType },
    });

    return uploadUrl;
  } catch (e) {
    console.log(e);
  }
};

const stripSpecialChars = (str) => str.replace(/[^a-zA-Z ]/g, "");

export const generateUserFileName = (userData, fileExtension) => {
  const ID = userData.id;
  const encodedAddress = stripSpecialChars(
    `${userData.address} ${userData.city} ${userData.state} ${userData.zip}`,
  );
  const encodedCompanyName = stripSpecialChars(userData.companyName);
  const encodedFullName = stripSpecialChars(
    `${userData.firstName}-${userData.lastName}`,
  );
  const address = encodedAddress.split(" ").join("-").toLowerCase();

  let name = encodedCompanyName
    ? encodedCompanyName.split(" ").join("-").toLowerCase()
    : encodedFullName.toLowerCase();

  // name = `${name}-${address}-${ID}-${Date.now()}`;
  name = `${folderPathImage}/${name}${address}${ID}-${Date.now()}.${fileExtension}`;

  return name;
};

export const generateTodoFileName = (todoData) => {
  const ID = todoData.id;
  const encodedTypeName = todoData.todoType
    ? stripSpecialChars(todoData.todoType)
    : "";
  const type = encodedTypeName || "";
  const encodedTitle = stripSpecialChars(todoData.title);
  let name = encodedTitle.split(" ").join("-").toLowerCase();

  name = `${ID}_${name}_${type}_${Date.now()}`;

  return name;
};

export const generateListingFileName = (
  { id, name = "", address = "", city = "", state = "" },
  fileExtension = "gif",
) => {
  if (!id || !name || !city || !state || !address) return;
  let date = moment();
  const folderPathImage = `images/${date.format("YYYY")}/${date.format(
    "MM",
  )}/${date.format("DD")}`;

  state = state.toUpperCase();
  let listingName = name.replace(/[^\w\s]/gi, "");
  const listingAddress = `${city} ${state}`.replace(/[^\w\s]/gi, "");

  let imageHref = `${listingName} ${listingAddress} ${id} ${Date.now()}`;
  imageHref = imageHref
    .split(" ")
    .map((e) => capitalizeFirstLetter(e))
    .join(" ");
  imageHref = imageHref.trim().split(" ").join("-").replace(/--/gi, "-");
  return `${folderPathImage}/${imageHref}.${fileExtension}`;
};

export const generateFileName = (fileName, fileExtension = "png") => {
  let name = `${folderPathImage}/${fileName}-${Date.now()}.${fileExtension}`;

  return name;
};
