import React, { useEffect } from "react";
import { FtsLoaderIndicator } from "../../../FtsLoader/FtsLoaderIndicator";
import { FtsFullPageLoaderIndicatorWrapper, FtsLoaderIndicatorWrapper, } from "../../../FtsLoader/FtsLoaderIndicatorWrapper";
export const Loader = ({ onFullPage }) => {
    useEffect(() => {
        if (!onFullPage)
            return;
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, [onFullPage]);
    const Wrapper = onFullPage ? FtsFullPageLoaderIndicatorWrapper : FtsLoaderIndicatorWrapper;
    return (React.createElement(Wrapper, null,
        React.createElement(FtsLoaderIndicator, null)));
};
