export const rfc3986EncodeURIComponent = (str) => {
  let string = str.trim();
  return encodeURIComponent(string)
    .replace(/[!'()*]/g, escape)
    .replace(/%20/g, "+");
};
export const rfc3986DecodeURIComponent = (str = "") => {
  let string = str.trim();
  try {
    string = decodeURIComponent(string.replace(/\+/g, " "));
  } catch (e) {
    string = unescape(string.replace(/\+/g, " "));
  }
  return string;
};
