/* eslint-disable indent */
import React, { Component } from "react";
import { Label } from "reactstrap";

import Select from "../../Select";
import { components } from "react-select";

import { pickerStyles, controlStyles } from "../../picker-style";
import supportedFileTypes from "../../../../data/supported-file-types";

const ValueContainer = ({ children, showInnerLabel, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {showInnerLabel && (
          <div
            style={{
              paddingRight: "0.5rem",

              color: "#9CA6A9",
            }}
          >
            Type:
          </div>
        )}
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};

class FileTypesPicker extends Component {
  render() {
    const {
      label,
      name,
      defaultValue: defaultValueProp,
      hasAll,
      ...rest
    } = this.props;

    const pickerStyle = {
      ...pickerStyles,
      borderWidth: 0,
      control: (styles, state) => controlStyles(styles, state),
    };

    const options = supportedFileTypes.map((type) => ({
      value: type.value,
      label: type.label,
    }));

    let defaultValue =
      typeof defaultValueProp === "object"
        ? defaultValueProp
        : options.find(({ value }) => value === defaultValueProp);

    if (hasAll) {
      defaultValue = options.find((itm) => itm.value === "");
    }

    return (
      <div style={{ width: "100%" }}>
        <div>
          {!!label && (
            <Label className={rest.isDisabled ? "disabled" : ""} for={name}>
              {label}
            </Label>
          )}
        </div>
        <div>
          <Select
            {...{ defaultValue, label }}
            isSearchable={true}
            options={options}
            styles={pickerStyle}
            name={name}
            placeholder={rest.placeholder ?? "Select..."}
            components={{
              ValueContainer: (props) => (
                <ValueContainer showInnerLabel={hasAll} {...props} />
              ),
            }}
            {...rest}
          />
        </div>
      </div>
    );
  }
}

export default FileTypesPicker;
