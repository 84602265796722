/* eslint-disable indent */
/* eslint-disable no-extra-boolean-cast */
import React, { Component } from "react";
import { connect } from "react-redux";
import { components } from "react-select";
import { Label } from "reactstrap";
import { ErrorMessage } from "formik";
import { getUserData } from "../../../redux/actions/users";

import { pickerStyles, controlStyles } from "../picker-style";
import Select from "../Select";

const ValueContainer = ({ children, hideInnerLabel, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      {!hideInnerLabel && (
        <div style={{ paddingRight: "0.5rem" }}>Assigned to:</div>
      )}
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {children}
      </div>
    </components.ValueContainer>
  );
};

class EmployeePicker extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isDisabled !== this.props.isDisabled ||
      nextProps.meta.touched !== this.props.meta.touched ||
      nextProps.meta.value !== this.props.meta.value ||
      nextProps.meta.error !== this.props.meta.error ||
      nextProps.extractValue !== this.props.extractValue
    );
  }

  render() {
    const {
      name,
      label,
      isDisabled,
      isPersonal,
      updating,
      adminOwner,
      placeholder,
      meta,
      hideInnerLabel,
      onBlur,
      setFieldTouched,
      setFieldValue,
      setFieldError,
      list,
      extractValue,
      isMulti,
      closeMenuOnSelect,
      showAssigned,
      hideDisabledUsers,
    } = this.props;

    let pickerOptions = list;

    if (hideDisabledUsers) {
      pickerOptions = pickerOptions.filter((option) => option.status);
    } else {
      pickerOptions = pickerOptions.sort(function (a, b) {
        return b.status - a.status;
      });
    }

    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) => controlStyles(styles, state, {}, { meta }),
    };

    const defaultValue =
      meta && meta.initialValue && pickerOptions.length
        ? typeof meta.initialValue === "number"
          ? pickerOptions.find((itm) => itm.value === meta.initialValue)
          : typeof meta.initialValue[0] === "number"
          ? meta.initialValue.map((id) =>
              pickerOptions.find(({ value }) => value === id),
            )
          : meta.initialValue
        : null;

    let isLoading = false;

    if (!pickerOptions?.length) {
      isLoading = true;
    }

    if (!!extractValue) {
      const valuesToExtract = Array.isArray(extractValue)
        ? extractValue.map((itm) => (typeof itm === "object" ? itm.value : itm))
        : [extractValue?.value || extractValue];
      pickerOptions = pickerOptions.filter(
        (itm) => valuesToExtract.indexOf(itm.value) === -1,
      );
    }

    return (
      <>
        {!!label && (
          <Label className={isDisabled ? "disabled" : ""} for="employee-picker">
            {this.props.required && !isDisabled ? `${label} *` : label}
          </Label>
        )}
        {isPersonal ? (
          !updating ? (
            <div className="employee-picker-gray-box">
              {`${this.props.userData?.firstName} ${this.props.userData?.lastName}`}
            </div>
          ) : (
            <div className="employee-picker-gray-box">
              {`${adminOwner?.firstName} ${adminOwner.lastName}`}
            </div>
          )
        ) : (
          <>
            <Select
              {...{
                defaultValue,
                onBlur,
                isLoading,
                isDisabled,
                isMulti,
                label,
              }}
              id="employee-picker"
              placeholder={placeholder}
              isClearable
              isSearchable
              components={
                showAssigned
                  ? {
                      ValueContainer: (props) => (
                        <ValueContainer {...props} {...{ hideInnerLabel }} />
                      ),
                      Option,
                    }
                  : Option
              }
              onChange={(value) => {
                if (value && value.length) {
                  setFieldError(name, undefined);
                }
                setFieldValue(name, value);
                setTimeout(() => {
                  setFieldTouched(name, true, true);
                }, 0);
              }}
              options={pickerOptions}
              styles={pickerStyle}
              {...{ closeMenuOnSelect }}
            />
            <ErrorMessage
              name={name}
              render={(msg) => (
                <div className="formik-validation-error">{msg}</div>
              )}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.essentialLists.employees,
    userData: state.auth.login.values.loggedInUser,
  };
};

export default connect(mapStateToProps, {
  getUserData,
})(EmployeePicker);

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div style={{ color: props.data?.status === 0 && "#BEBEBE" }}>
          {props.children}
        </div>
      </components.Option>
    </>
  );
};
