import React from "react";
const Clock = (props) => {
    return (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props.svgProps },
        React.createElement("g", { "clip-path": "url(#clip0_6848_153356)" },
            React.createElement("path", { d: "M8.00016 3.9987V7.9987L10.6668 9.33203M14.6668 7.9987C14.6668 11.6806 11.6821 14.6654 8.00016 14.6654C4.31826 14.6654 1.3335 11.6806 1.3335 7.9987C1.3335 4.3168 4.31826 1.33203 8.00016 1.33203C11.6821 1.33203 14.6668 4.3168 14.6668 7.9987Z", stroke: "#2A343E", strokeLinecap: "round", strokeLinejoin: "round", ...props.pathProps })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_6848_153356" },
                React.createElement("rect", { width: "16", height: "16", fill: "white", ...props.pathProps })))));
};
export default Clock;
