/* eslint-disable indent */
import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Col, Row } from "reactstrap";

import { connect } from "react-redux";

import FTSNote from "../fts-note";

const formSchema = Yup.object().shape({
  noteType: Yup.mixed()
    .when(
      ["note"],
      {
        is: (val) => val && val.length > 0,
        then: Yup.mixed().required("Note type is required"),
      },
      ["note", "noteType"]
    )
    .nullable(),
  note: Yup.string().required("Note is required"),
  stickyName: Yup.bool().default(false),
});

class NoteForm extends Component {
  render() {
    const {
      showCancelBtn,
      handleSubmit,
      handleSidebar,
      isPinToAccount,
      inProgress,
      innerRef,
      noSticky,
      labelId,
      pinToAccountName,
      types,
      menuPlacement,
      todo,
    } = this.props;

    const initialData = {
      stickyName: false,
      note: "",
      noteType: types.filter((element) => element.type === "note")[0],
    };

    return (
      <Formik
        innerRef={innerRef}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={formSchema}
        initialValues={initialData}
      >
        {({ setFieldError, setFieldTouched }) => (
          <>
            <Form>
              <Row>
                <Col>
                  <FTSNote
                    name="note"
                    label="New note"
                    labelId={labelId}
                    noteRequired
                    typeName="noteType"
                    typePlaceholder="Note type..."
                    placeholder="Some note goes here..."
                    stickyName="stickyName"
                    isNewNote
                    isAddNoteBtn
                    isPinToAccount={isPinToAccount}
                    pinToAccountName={pinToAccountName}
                    noSticky={noSticky}
                    noteButtonType="submit"
                    menuPlacement={menuPlacement}
                    todo={todo}
                    {...{ setFieldError, setFieldTouched }}
                  />
                </Col>
              </Row>

              <Row className="pb-1">
                <Col className="d-flex justify-content-end">
                  <div>
                    {showCancelBtn && (
                      <Button
                        disabled={inProgress}
                        type="button"
                        color="danger"
                        outline
                        onClick={() => handleSidebar(false)}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.essentialLists.types,
  };
};

export default connect(mapStateToProps)(NoteForm);
