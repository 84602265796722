import { createSelector } from "reselect";
import uuid from "react-uuid";

export const ADD_CARD_TO_STORE = "CREDIT_CARDS/ADD_CARD_TO_STORE";
export const UPDATE_CARD_IN_STORE = "CREDIT_CARDS/UPDATE_CARD_IN_STORE";
export const DELETE_CARD_IN_STORE = "CREDIT_CARDS/DELETE_CARD_IN_STORE";
export const UPDATE_SELECTED_CARD_STORE =
  "CREDIT_CARDS/UPDATE_SELECTED_CARD_STORE";
export const SET_CARD_DATA_INVALID_FORM_FIELDS =
  "CREDIT_CARDS/SET_CARD_DATA_INVALID_FORM_FIELDS";
export const SAVE_CARD_DATA_STORE = "CREDIT_CARDS/SAVE_CARD_DATA_STORE";
export const CANCEL_EDITING_CARD = "CREDIT_CARDS/CANCEL_EDITING_CARD";

const initialState = {
  isLoading: false,
  error: null,
  card: null,
  cards: null,
  invalidCardFormFields: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SELECTED_CARD_STORE:
      return {
        ...state,
        card: action.data,
        invalidCardFormFields: null,
      };

    case UPDATE_CARD_IN_STORE:
      const selectedCard = state.card || {};
      return {
        ...state,
        card: { ...selectedCard, ...action.data },
      };

    case ADD_CARD_TO_STORE:
      const cards = state.cards ? [...state.cards] : [];
      const newCard = {
        ...state.card,
        id: uuid(),
        lastFourDigital: state.card.cardNumber.slice(-4),
      };

      if (newCard.isDefault)
        cards.map((card) => {
          if (!card.isDefault) return card;
          card.isDefault = false;
          return card;
        });

      return {
        ...state,
        cards: [...cards, newCard],
        card: null,
        invalidCardFormFields: null,
      };

    case DELETE_CARD_IN_STORE:
      return {
        ...state,
        cards: state.cards.filter((card) => card.id !== state.card.id),
        card: null,
        invalidCardFormFields: null,
      };

    case SAVE_CARD_DATA_STORE:
      const mappedCards = state.cards.map((card) => {
        let toggleDefault = false;
        if (state.card.isDefault) toggleDefault = true;
        if (card.id !== state.card.id) {
          if (card.isDefault && toggleDefault)
            return { ...card, isDefault: false };
          return card;
        }
        return {
          ...state.card,
          lastFourDigital: state.card.cardNumber.slice(-4),
        };
      });

      return {
        ...state,
        cards: mappedCards,
        card: null,
        invalidCardFormFields: null,
      };

    case SET_CARD_DATA_INVALID_FORM_FIELDS:
      return {
        ...state,
        invalidCardFormFields: action.data,
      };

    case CANCEL_EDITING_CARD:
      return {
        ...state,
        card: null,
        invalidCardFormFields: null,
      };

    default:
      return state;
  }
}

const selectCreditCardsState = (state) => state.creditCardsState;

export const selectCards = createSelector(selectCreditCardsState, (account) => {
  return account.cards;
});

export const selectCardsLength = createSelector(
  selectCreditCardsState,
  (account) => {
    return account.cards?.length ?? 0;
  },
);

export const selectCard = createSelector(selectCreditCardsState, (account) => {
  return account.card;
});

export const selectCardId = createSelector(
  selectCreditCardsState,
  (account) => {
    return account.card?.id;
  },
);

export const selectCardInvalidFormFields = createSelector(
  selectCreditCardsState,
  (account) => {
    return account.invalidCardFormFields;
  },
);
