import React, { PureComponent } from "react";

import "../../../../assets/scss/components/fts/count-box.scss";
import Icon from "../../../../components/fts/Icons";
import PercentageBox from "../PercentageBox";

class CountBox extends PureComponent {
  render() {
    const { label, count, size, percentage, iconName } = this.props;

    const backgroundColors = {
      leads: "rgba(243, 101, 101, 0.16)",
      trials: "rgba(245, 176, 83, 0.16)",
      renewals: "rgba(1, 181, 245, 0.16)",
    };

    return (
      <div className="count-box-wrapper">
        {size === "sm" ? (
          <>
            <div className="icon-wrapper">
              <div
                className="icon"
                style={{
                  backgroundColor: backgroundColors[label.toLowerCase()],
                  border: iconName ? "1px solid #E5E8E9" : "",
                }}
              >
                <Icon name={iconName || label + "Dashboard"} size="24" />
              </div>
            </div>

            <div className="info-small">
              <span className="count">
                {count === "-" || !count ? 0 : count}
              </span>
              <span className="label">{label}</span>
            </div>
          </>
        ) : (
          <div className="d-flex">
            <div className="d-flex">
              <div className="icon-wrapper">
                <div
                  className="icon"
                  style={{
                    border: "1px solid #E5E8E9",
                  }}
                >
                  <Icon name={label.replace(" ", "") + "Dashboard"} size="24" />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="info-medium">
                <span className="count">{count}</span>
                <span className="label">{label}</span>
              </div>
              <span className="percentage">
                <PercentageBox percentage={percentage} isActivity />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CountBox;
