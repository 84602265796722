import React, { Component } from "react";
import { Prompt } from "react-router-dom";

class PromptWrapper extends Component {
  state = {
    when: false,
  };

  componentDidMount() {
    this.setState({ when: true });
  }

  render() {
    const { dirty } = this.props;
    const { when } = this.state;

    return (
      <Prompt
        when={dirty && when}
        message="Are you sure you want to leave? All of your unsaved changes will be lost."
      />
    );
  }
}

export default PromptWrapper;
