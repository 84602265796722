import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import FTSModal from "../fts-modal";

import Action from "./Action";
import Icon from "../Icons";

import "../../../assets/scss/components/fts/action-bar.scss";
import { checkPermissions } from "../../../utility/permissionsCheck";
import { toast } from "react-toastify";
import moment from "moment";

class ActionBar extends Component {
  state = {
    isOpen: false,
    isChangingListingType: false,
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const {
      account,
      listing,
      callToAction,
      isListingDuplicated = false,
      accountData,
      isDeleted = false,
      changedListingType = false,
      toggleCancelListingTypeChange,
      changedListingTypeMessage = "",
      listingLocked,
      changeType,
      setActiveTab,
    } = this.props;

    const { isOpen, isChangingListingType } = this.state;
    const classList = account ? "account" : "listing";
    const accListClasses =
      account || listing ? "acc-list-action-bar-wrapper" : "";

    const iconSize = 27;
    const colProps = listing
      ? {
          className: "text-center col-listing",
        }
      : {
          className: "text-center",
          xs: "6",
          sm: "4",
          md: "2",
        };

    const accountActions = [
      {
        title: "Create todo",
        icon: <Icon name="CheckCircle" size={iconSize} />,
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 3, subCat: [32] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            return callToAction("todo");
          }
          toast.error("You don't have permission for this action");
        },
      },
      {
        title: "Create note",
        icon: <Icon name="EditAction" size={iconSize} />,
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 3, subCat: [33] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            return callToAction("note");
          }
          toast.error("You don't have permission for this action");
        },
      },
      {
        title: "Create listing",
        icon: <Icon name="FileText" size={iconSize} />,
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 3, subCat: [26] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            if (accountData?.userTypeNamed === "vendor") {
              return callToAction("listing");
            } else {
              return this.toggleModal();
            }
          }
          toast.error("You don't have permission for this action");
        },
      },
      {
        title: "Create promotion",
        icon: <Icon name="Tag" size={iconSize} />,
        //todo uncomment once feature is back
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 3, subCat: [29] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            return callToAction("promotion");
          }
          toast.error("You don't have permission for this action");
        },
      },
      {
        title: "Create report",
        icon: <Icon name="PieChart" size={iconSize} />,
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 3, subCat: [30] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            return callToAction("generate_report", false, {
              reportFrom: moment().subtract(1, "years"),
              reportTo: moment(),
            });
          }
          toast.error("You don't have permission for this action");
        },
      },
      {
        title: "Marketing",
        icon: <Icon name="BarChart" size={iconSize} />,
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 3, subCat: [31] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            return this.props.toggle(6);
          }
          toast.error("You don't have permission for this action");
        },
      },
    ];

    const listingActions = [
      {
        title: "Create todo",
        icon: <Icon name="CheckCircle" size={iconSize} />,
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 2, subCat: [21] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            return callToAction("todo");
          }
          toast.error("You don't have permission for this action");
        },
      },
      {
        title: "Create note",
        icon: <Icon name="EditAction" size={iconSize} />,
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 2, subCat: [22] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            return callToAction("note");
          }
          toast.error("You don't have permission for this action");
        },
      },
      {
        title: "Create promotion",
        icon: <Icon name="Tag" size={iconSize} />,
        //todo uncomment once feature is back
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 2, subCat: [16] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            return callToAction("promotion");
          }
          toast.error("You don't have permission for this action");
        },
      },
      {
        title: "Create report",
        icon: <Icon name="PieChart" size={iconSize} />,

        onClick: () => {
          setActiveTab(5);
        },
      },
      {
        title: "Send proof",
        icon: <Icon name="FileText" size={iconSize} />,
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 2, subCat: [51] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            this.props.setListingPreviewModalTitle("sendListingProof");
            this.props.toggleListingPreviewModal();
            return;
          }
          toast.error("You don't have permission for this action");
        },
      },
      {
        title: "Make a payment",
        icon: <Icon name="CreditCard" size={iconSize} />,
        onClick: () => {
          const havePermission = checkPermissions(
            [{ id: 2, subCat: [17] }],
            this.props.adminPermissions,
          );
          if (havePermission) {
            return this.props.setActiveTab(2);
          }
          toast.error("You don't have permission for this action");
        },
      },
    ];

    const actions =
      account && !listing
        ? accountActions
        : !account && listing
        ? listingActions
        : [];

    return (
      <Card className={`fts-action-bar ${accListClasses} ${classList}`}>
        <CardBody>
          <Row>
            {actions.map((props, index) => (
              <Col key={index} {...colProps}>
                <Action {...props} />
              </Col>
            ))}
          </Row>
        </CardBody>

        <FTSModal
          isOpen={isOpen}
          closeModal={this.toggleModal}
          title="Information"
          footerCTA={() => {
            return (
              <>
                <Button
                  onClick={this.toggleModal}
                  color="primary"
                  disabled={false}
                >
                  Cancel
                </Button>
              </>
            );
          }}
        >
          Only vendor account type is able to have listing
        </FTSModal>
        {isListingDuplicated && (
          <div className={"duplicated-listing-info"}>
            <Icon name="Info" />
            <p className={"mb-0 ml-1"}>
              This listing has been duplicated, the information needs to be
              adjusted. Please check all information before you set enabled
              status.
            </p>
          </div>
        )}
        {listingLocked && (
          <div className={"locked-listing-info"}>
            <Icon name="Eye" />
            <p className={"mb-0 ml-1"}>
              {listingLocked.firstName} {listingLocked.lastName} is currently
              editing this listing. You are in read-only mode.
            </p>
          </div>
        )}
        {isDeleted && (
          <div className={"deleted-listing-info"}>
            <Icon name="Info" />
            <p className={"mb-0 ml-1"}>
              This is a deleted {listing ? "listing" : "account"}. If you want
              to enable it again, please check that all information is correct
              first.
            </p>
          </div>
        )}

        {changedListingType && (
          <div
            className={"changed-listing-type d-flex justify-content-between"}
          >
            <div className="d-flex">
              <Icon name="Info" stroke="#000" />
              <p className={"mb-0 ml-1"}>{changedListingTypeMessage}</p>
            </div>

            <Button
              color="danger"
              className="cancel-upgrade-button"
              onClick={() => {
                toggleCancelListingTypeChange();
              }}
            >
              {isChangingListingType ? (
                <Row>
                  <Col>
                    <Spinner size="sm" color="white" />
                  </Col>
                </Row>
              ) : (
                `Cancel ${changeType}`
              )}
            </Button>
          </div>
        )}
      </Card>
    );
  }
}

export default ActionBar;
