import React, { PureComponent } from "react";
import { FormGroup, Label, CustomInput } from "reactstrap";
import { ErrorMessage, Field } from "formik";

import { Button } from "reactstrap";
import { connect } from "react-redux";

import NoteTypesPicker from "../note-types";
import FTSBadge from "../fts-badge";
import ReactQuill from "react-quill";
import FormikInputValue from "../formik/formik-input-value";
import "../../../assets/scss/components/fts/note-quill.scss";

import "react-quill/dist/quill.snow.css";
class FTSNote extends PureComponent {
  state = {
    isPinnedToAccount: this.props.isPinToAccount,
  };

  validateNote(value) {
    let error;

    if (!value || !value.trim()) {
      error = "Invalid note";
    }
    if (
      value &&
      /^<[^/>]+>[ \n\r\t]*<\/[^>]+>$/.test(value.replace(/<br>/g, ""))
    ) {
      error = "Invalid no empty html tags";
    }
    return error;
  }

  render() {
    const {
      name,
      label,
      labelId,
      pinToAccountName,
      required,
      disabled,
      placeholder,

      setFieldError,
      setFieldTouched,
      isMulti,
      typeName,
      typeLabel,
      typePlaceholder,
      stickyName,

      noteLabelValue,
      noSticky,
      // isPinToAccount,
      isAddNoteBtn,
      isAbsolutePosition,
      isNewNote,
      noteRequired = true,
      noteButtonType,
      menuPlacement,
      todo,
    } = this.props;

    let types = this.props.types;

    types = types.filter(function (obj) {
      return obj.type === "both" || obj.type === "note";
    });

    types.forEach((obj) => {
      obj.value = obj.description.toLowerCase();
      obj.label = obj.description;
    });

    const noteTypeLabel = "Note type";

    const noteLabel = noteLabelValue
      ? Array.isArray(noteLabelValue)
        ? noteLabelValue[0]
        : noteLabelValue
      : null;
    const noteData = noteLabel
      ? types.find((itm) => itm.value === noteLabel)
      : null;

    return (
      <React.Fragment>
        {isNewNote && !disabled ? (
          <FormGroup
            className={`${isAbsolutePosition ? "fts-note-absolute" : ""}`}
          >
            <div
              className={`${
                isNewNote ? "fts-note-header-new" : "fts-note-header"
              }`}
            >
              <div className="fts-note-header-new__label">
                {!!label && !this.props.actionItem?.parentId && (
                  <>
                    <Label className={disabled ? "disabled" : ""} for={labelId}>
                      {required && !disabled
                        ? `${noteTypeLabel}:*`
                        : `${noteTypeLabel}:`}
                    </Label>
                    {noteLabelValue && disabled && (
                      <FTSBadge
                        className="ml-1"
                        style={{ backgroundColor: noteData?.color }}
                      >
                        {noteData?.label}
                      </FTSBadge>
                    )}
                    <Field name={typeName}>
                      {({ meta, form, field }) => (
                        <NoteTypesPicker
                          name={typeName}
                          label={typeLabel}
                          isClearable
                          placeholder={typePlaceholder}
                          onChange={(option) => {
                            if (option) {
                              setFieldError(typeName, undefined);
                            }
                            form.setFieldValue(typeName, option);
                            setTimeout(() => {
                              setFieldTouched(typeName, true, true);
                            }, 0);
                          }}
                          setFieldValue={form.setFieldValue}
                          value={field.value}
                          onBlur={field.onBlur}
                          isDisabled={disabled}
                          menuPlacement={menuPlacement}
                          {...{ meta, isMulti }}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name={typeName}
                      render={(msg) => (
                        <div className="formik-validation-error">{msg}</div>
                      )}
                    />
                  </>
                )}
              </div>
              {!disabled && (
                <div className="fts-optin-new fts-note-header__info">
                  {pinToAccountName && todo?.todoType !== "personal" && (
                    <Field name={"pinToAccountName"}>
                      {({ form }) => {
                        return (
                          <>
                            <CustomInput
                              checked={this.state.isPinnedToAccount}
                              type="switch"
                              id={pinToAccountName}
                              {...{ disabled }}
                              onChange={(e) => {
                                this.setState({
                                  isPinnedToAccount: e.target.checked,
                                });
                                form.setFieldValue(
                                  "pinToAccountName",
                                  e.target.checked
                                );
                              }}
                            >
                              <span className="switch-label">
                                Pin to the account
                              </span>
                            </CustomInput>
                          </>
                        );
                      }}
                    </Field>
                  )}

                  {!noSticky && !this.props.actionItem?.parentId && (
                    <Field name={stickyName}>
                      {({ field: { value }, form }) => {
                        return (
                          <>
                            <CustomInput
                              checked={value}
                              type="switch"
                              id={stickyName}
                              {...{ disabled }}
                              onChange={(e) => {
                                form.setFieldValue(
                                  stickyName,
                                  e.target.checked
                                );
                              }}
                            >
                              <span className="switch-label">Sticky note</span>
                            </CustomInput>
                          </>
                        );
                      }}
                    </Field>
                  )}

                  {isAddNoteBtn && (
                    <Button
                      //   disabled={inProgress}
                      color="primary"
                      type={noteButtonType ?? "button"}
                      className=""
                    >
                      Add note
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="fts-note-content">
              <Field
                name={name}
                validate={noteRequired ? this.validateNote : null}
              >
                {({ field, form }) => (
                  <div className="note-quill">
                    <ReactQuill
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline"],

                          [{ list: "ordered" }, { list: "bullet" }],

                          ["clean"],
                        ],
                      }}
                      theme="snow"
                      placeholder={placeholder}
                      initialValue={field.value || ""}
                      value={field.value || ""}
                      onChange={(e) => {
                        form.setFieldValue(name, e);
                      }}
                    />
                  </div>
                )}
              </Field>
              <ErrorMessage
                name={name}
                render={(msg) => (
                  <div className="formik-validation-error">{msg}</div>
                )}
              />
            </div>
          </FormGroup>
        ) : (
          <FormGroup>
            <div
              className={`${isNewNote ? "fts-note-header" : "fts-note-header"}`}
            >
              <div className="fts-note-header__label">
                {!!label && (
                  <>
                    {!disabled && (
                      <Label
                        className={disabled ? "disabled" : ""}
                        for={labelId}
                      >
                        {required && !disabled ? `${label} *` : label}
                      </Label>
                    )}
                    {noteLabelValue && disabled && (
                      <div style={{ display: "flex" }}>
                        {/* <FTSBadge style={{ backgroundColor: noteData?.color }}>
                          {noteData?.label}
                        </FTSBadge> */}
                      </div>
                    )}
                  </>
                )}
              </div>
              {!disabled && (
                <div className="d-flex align-items-center fts-optin fts-note-header__info">
                  {!noSticky && (
                    <Field name={stickyName}>
                      {({ field: { value }, form }) => {
                        return (
                          <>
                            <CustomInput
                              checked={value}
                              type="switch"
                              id={stickyName}
                              onChange={(e) => {
                                form.setFieldValue(
                                  stickyName,
                                  e.target.checked
                                );
                              }}
                              {...{ disabled }}
                            >
                              <span className="switch-label">Is sticky?</span>
                            </CustomInput>
                          </>
                        );
                      }}
                    </Field>
                  )}

                  <Field name={typeName}>
                    {({ meta, form, field }) => (
                      <NoteTypesPicker
                        name={typeName}
                        label={typeLabel}
                        isClearable
                        placeholder={typePlaceholder}
                        onChange={(option) => {
                          if (option) {
                            setFieldError(typeName, undefined);
                          }
                          form.setFieldValue(typeName, option);
                          setTimeout(() => {
                            setFieldTouched(typeName, true, true);
                          }, 0);
                        }}
                        setFieldValue={form.setFieldValue}
                        value={field.value}
                        onBlur={field.onBlur}
                        isDisabled={disabled}
                        {...{ meta, isMulti }}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name={typeName}
                    render={(msg) => (
                      <div className="formik-validation-error">{msg}</div>
                    )}
                  />
                </div>
              )}
            </div>
            <div className="fts-note-content">
              <Field
                name={name}
                validate={noteRequired ? this.validateNote : null}
              >
                {({ field, form }) => (
                  <>
                    {!disabled ? (
                      <div className="note-quill">
                        <ReactQuill
                          modules={{
                            toolbar: [
                              ["bold", "italic", "underline"],

                              [{ list: "ordered" }, { list: "bullet" }],

                              ["clean"],
                            ],
                          }}
                          theme="snow"
                          initialValue={field.value || ""}
                          value={field.value || ""}
                          onChange={(e) => {
                            form.setFieldValue(name, e);
                          }}
                        />
                      </div>
                    ) : (
                      <FormikInputValue>{field.value}</FormikInputValue>
                    )}
                  </>
                )}
              </Field>
              <ErrorMessage
                name={name}
                render={(msg) => (
                  <div className="formik-validation-error">{msg}</div>
                )}
              />
            </div>
          </FormGroup>
        )}
      </React.Fragment>
    );
  }
}

FTSNote.defaultProps = {
  className: "form-control",
};

const mapStateToProps = (state) => {
  return {
    types: state.essentialLists.types,
  };
};

export default connect(mapStateToProps)(FTSNote);
