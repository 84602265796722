import axios from "axios";

import appConfig from "../configs/appConfig";
import FTSCookies, { AUTH_KEY, REFRESH_KEY, TEMPORARY_TOKEN } from "../cookies";
import { withTrailingSlash } from "../utility/trailingSlash";

const instance = axios.create({ baseURL: appConfig.apiURL });

export let isRefreshing = false;
export let failedQueue = [];
// const TIMEOUT_SEC = 60;
instance.interceptors.request.use(
  async (req) => {
    const xAuthCookie = FTSCookies.get(AUTH_KEY);
    const tokenFromEmail = FTSCookies.get(TEMPORARY_TOKEN);
    if (tokenFromEmail) {
      req.headers = {
        [TEMPORARY_TOKEN]: tokenFromEmail,
      };
      return req;
    }
    if (xAuthCookie) {
      req.headers = {
        Authorization: `Bearer ${xAuthCookie}`,
      };
    }

    // req.timeout = TIMEOUT_SEC * 1000;

    return req;
  },
  async (error) => {
    return Promise.reject(error);
  },
);

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  async (response) => {
    if (response.data) {
      let data = JSON.stringify(response.data);

      data = data
        .replace(/\+/g, " ")
        .replace(/%5C/g, "%5C%5C")
        .replace(/%22/g, "'")
        .replace(/%27/g, "'");
      try {
        data = decodeURIComponent(data);
      } catch (e) {
        data = unescape(data);
      }
      data = data.replace(/\r\n/g, "").replace(/\n/g, "").replace(/\t/g, "");

      response.data = JSON.parse(data);
    }
    if (response.status === 200 && response.data.token) {
      if (!response.data.lastFourDigital) storeCookies(response.data);
    }

    return response;
  },
  async (error) => {
    const originalRequest = error?.config;
    if (originalRequest.data && typeof originalRequest.data === "string") {
      originalRequest.data = JSON.parse(originalRequest.data);
    }
    if (error?.response?.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return instance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      const refreshToken = FTSCookies.get(REFRESH_KEY);
      const token = FTSCookies.get(AUTH_KEY);

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        const baseURL = withTrailingSlash(appConfig.apiURL);

        axios
          .post(
            baseURL + "refresh_token",
            { refreshToken },
            { headers: { Authorization: `Bearer ${token}` } },
          )
          .then(({ data, status }) => {
            if (status === 201) {
              storeCookies(data);
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${data.token}`;

              processQueue(null, data.token);
              FTSCookies.remove(TEMPORARY_TOKEN);
              resolve(instance(originalRequest));
            }
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  },
);

export function storeCookies(data) {
  const path = "/";
  FTSCookies.set(AUTH_KEY, data.token, { path });
  FTSCookies.set(REFRESH_KEY, data.refreshToken, { path });
}

export default instance;
