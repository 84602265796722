/* eslint-disable indent */
export const GET_ACCOUNT_COUNTS = "accounts/GET_ACCOUNT_COUNTS";
export const SET_ACCOUNT_STATE = "accounts/SET_ACCOUNT_STATE";
export const RESET_ACCOUNT_STATE = "accounts/RESET_ACCOUNT_STATE";
const initialState = {
  listingsCount: 0,
  promotionsCount: 0,
  notesCount: 0,
  filesCount: 0,
  todosCount: 0,
  subusersCount: 0,
  accountSettingsState: {
    accountId: 0,
    activeTab: 0,
    listingsTableActiveTab: "premium",
    pageBasic: 1,
    pageBasicPlus: 1,
    pageFree: 1,
    pagePremium: 1,
    pageSizeBasic: 10,
    pageSizeBasicPlus: 10,
    pageSizeFree: 10,
    pageSizePremium: 10,
    pageSizeStandard: 10,
    pageStandard: 1,
    searchValBasic: "",
    searchValBasicPlus: "",
    searchValFree: "",
    searchValPremium: "",
    searchValStandard: "",
    sortPremiumListing: "",
    sortBasicListing: "",
    sortBasicPlusListing: "",
    sortStandardListing: "",
    sortFreeListing: "",
  },
  userListings: [],
  newAccountUnReadCount: 0,
};

const AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NEW_ACCOUNT_UNREAD_COUNTER":
      return {
        ...state,
        newAccountUnReadCount: action.payload,
      };
    case "SET_USERS_LISTINGS":
      return {
        ...state,
        userListings: action.payload || [],
      };
    case RESET_ACCOUNT_STATE: {
      return initialState;
    }
    case SET_ACCOUNT_STATE: {
      return {
        ...state,
        accountSettingsState: {
          ...state.accountSettingsState,
          ...action.payload,
          listingsTableActiveTab:
            action.payload.listingsTableActiveTab ||
            state.accountSettingsState.listingsTableActiveTab,
        },
      };
    }
    case GET_ACCOUNT_COUNTS:
      return {
        ...state,
        listingsCount: action.data.userListingsCount,
        // promotionsCount: action.data.userPromotionsCount,
        notesCount: action.data.userNotesCount,
        filesCount: action.data.filesCount,
        subusersCount: action.data.subusersCount,
        todosCount: action.data.userTodos?.length,
      };

    default:
      return state;
  }
};

export default AccountReducer;
