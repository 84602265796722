/* eslint-disable indent */
import React, { Component } from "react";
import { Label } from "reactstrap";

import Select from "../Select";
import { components } from "react-select";

import { pickerStyles, controlStyles } from "../picker-style";
import { connect } from "react-redux";
import chroma from "chroma-js";

const ValueContainer = ({ children, showInnerLabel, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {showInnerLabel && (
          <div
            style={{
              paddingRight: "0.5rem",

              color: "#9CA6A9",
            }}
          >
            Type:
          </div>
        )}
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};
class NoteTypes extends Component {
  render() {
    const {
      label,
      name,
      defaultValue: defaultValueProp,
      hasAll,
      ...rest
    } = this.props;

    let types = this.props.types;

    types = types.filter(function (obj) {
      return obj.type === "both" || obj.type === "note";
    });

    types.forEach((obj) => {
      obj.value = obj.description.toLowerCase();
      obj.label = obj.description;
    });

    if (hasAll) {
      types = [
        { color: "#7d8c91", type: "both", label: "All", value: "" },
        ...types,
      ];
    }

    const pickerStyle = {
      ...pickerStyles,
      borderWidth: 0,
      control: (styles, state) => controlStyles(styles, state),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.1).css()
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? chroma.contrast(color, "white") > 2
              ? "white"
              : "black"
            : data.color,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
          },
        };
      },

      multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: color.alpha(0.1).css(),
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ":hover": {
          backgroundColor: data.color,
          color: "white",
        },
      }),
    };

    const options = types;

    let defaultValue =
      typeof defaultValueProp === "object"
        ? defaultValueProp
        : options.find(({ value }) => value === defaultValueProp);

    if (hasAll) {
      defaultValue = types.find((itm) => itm.label === "All");
    }

    return (
      <div style={{ width: "100%" }}>
        <div>
          {!!label && (
            <Label className={rest.isDisabled ? "disabled" : ""} for={name}>
              {label}
            </Label>
          )}
        </div>
        <div>
          <Select
            {...{ defaultValue, label }}
            {...{ defaultValue, label }}
            isSearchable={true}
            isClearable={true}
            options={options}
            styles={pickerStyle}
            name={name}
            components={{
              ValueContainer: (props) => (
                <ValueContainer showInnerLabel={hasAll} {...props} />
              ),
            }}
            placeholder={rest.placeholder ?? "Select..."}
            onChange={this.props.onChange}
            {...rest}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.essentialLists.types,
  };
};

export default connect(mapStateToProps)(NoteTypes);
