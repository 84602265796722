/* eslint-disable indent */
const initialState = {
  id: "",
  name: "",
  phoneNumber: "",
  address: "",
  city: "",
  zip: "",
  state: "",
  status: "",
  firstName: "",
  lastName: "",
  email: "",
  adminStatus: "",
  subCategory: "",
  serviceAmenities: [],
  includeInAuthorizedPromotions: 0,
  includeInFeaturedVendors: 0,
  // excludeRenewalEmails: 0,
  // excludeDowngrades: 0,
  listState: null,
};

const SearchFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SEARCH_FILTER":
      return {
        ...state,
        ...action.data,
        listState: action.data.listState || null,
      };

    case "CLEAR_FILTER":
      return initialState;

    default:
      return state;
  }
};

export default SearchFilterReducer;
