import React, { Fragment } from "react";
import { differenceInMinutes, format, isBefore } from "date-fns";

const formatHours = (hours) => {
  if (!hours) return "";
  let [time, suffix] = hours.trim().split(" ");
  time = time.split(":");

  if (time.length > 1) {
    time = time.join(":");
  } else {
    time = `${time[0]}:00`;
  }

  return `${time} ${suffix}`;
};

export const getStoreStatus = ({ currentHours, listingCurrentTime }) => {
  if (!currentHours) return null;
  if (currentHours.trim() === "-") {
    return (
      <span style={{ color: "#CE1B3B", textTransform: "uppercase" }}>
        Closed
      </span>
    );
  }
  if (currentHours.toLowerCase().trim() === "closed -") {
    return (
      <span style={{ color: "#CE1B3B", textTransform: "uppercase" }}>
        Closed
      </span>
    );
  }

  if (currentHours.toLowerCase().trim() === "24 hrs -")
    return (
      <span>
        {"24 HR ("}
        <span style={{ color: "#98CA86" }}>Open</span>
        {")"}
      </span>
    );

  if (currentHours.toLowerCase().trim() === "by appointment -")
    return (
      <Fragment>
        <span>
          {"Hours ("}
          <span style={{ color: "#128548" }}>Special hours</span>
          {")"}
        </span>
      </Fragment>
    );

  let currentDate = format(new Date(), "P");
  let currentDateEnd = format(new Date(), "P");

  const [openHours, closeHours] = currentHours.split("-");
  const formatOpenHours = formatHours(openHours);
  const formatCloseHours = formatHours(closeHours);
  const isAM = (str) => str.indexOf("AM") !== -1;

  const isCloseBeforeStart = isBefore(
    new Date(`${currentDate} ${formatCloseHours}`),
    new Date(`${currentDate} ${formatOpenHours}`),
  );

  if (isAM(formatCloseHours) && isCloseBeforeStart) {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    currentDateEnd = format(date, "P");
  }

  const startTime = isBefore(
    new Date(`${currentDate} ${formatOpenHours}`),
    new Date(listingCurrentTime),
  );
  const endTime = isBefore(
    new Date(listingCurrentTime),
    new Date(`${currentDateEnd} ${formatCloseHours}`),
  );

  if (currentHours.toLowerCase() === "closed") {
    return (
      <Fragment>
        <span style={{ color: "#CE1B3B", textTransform: "uppercase" }}>
          Closed
        </span>
      </Fragment>
    );
  }

  if (startTime && endTime) {
    const hoursDiffrence = differenceInMinutes(
      new Date(`${currentDateEnd} ${formatCloseHours}`),
      new Date(listingCurrentTime),
    );

    const isClosingSoon = hoursDiffrence <= 60;
    return !isClosingSoon ? (
      <Fragment>
        <span>
          {`${currentHours} (`}
          <span style={{ color: "#98CA86" }}>Open</span>
          {")"}
        </span>
      </Fragment>
    ) : (
      <Fragment>
        {`${currentHours} (`}
        <span style={{ color: "#F5B053" }}>Closing Soon</span>
        {")"}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {`${currentHours} (`}
      <span style={{ color: "#CE1B3B", textTransform: "uppercase" }}>
        Closed
      </span>
      {")"}
    </Fragment>
  );
};
