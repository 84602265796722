import FTSAxios from "../../../axios/fts.instance";

export const getCategoriesData = (queryParams = "?status=1&status=2") => {
  return (dispatch) => {
    return FTSAxios.get(`admin/lists/categories${queryParams}`).then(
      (response) => {
        dispatch({
          type: "GET_CATEGORIES_DATA",
          data: response.data,
          totalPages: 1,
        });
      },
    );
  };
};

export const getSubcategoriesData = (queryParams = "?status=1&status=2") => {
  return (dispatch) => {
    return FTSAxios.get(`admin/lists/subcategories${queryParams}`).then(
      (response) => {
        dispatch({
          type: "GET_SUBCATEGORIES_DATA",
          data: response.data,
          totalPages: 1,
        });
      },
    );
  };
};

export const updateCategoryData = (payload) => {
  return () => {
    return FTSAxios.put(`admin/lists/category/${payload.id}`, payload);
  };
};

export const updateSubCategoryData = (payload) => {
  return () => {
    return FTSAxios.put(`admin/lists/subcategory/${payload.id}`, payload);
  };
};

export const addCategoryData = (payload) => {
  return () => {
    return FTSAxios.post("admin/lists/category/create", payload);
  };
};

export const addSubCategoryData = (payload) => {
  return () => {
    return FTSAxios.post("admin/lists/subcategory/create", payload);
  };
};

export const removeCategoryData = (payload) => {
  return () => {
    return FTSAxios.delete(`admin/lists/category/${payload.id}`);
  };
};

export const removeSubCategoryData = (payload) => {
  return () => {
    return FTSAxios.delete(`admin/lists/subcategory/${payload.id}`);
  };
};

export const filterCategories = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_CATEGORIES_DATA", value });
};

export const filterSubcategories = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_SUBCATEGORIES_DATA", value });
};
