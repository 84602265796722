import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { history } from "../../../history";
import { isRefreshing } from "../../../axios/fts.instance";

import {
  getCountAll,
  getAllUpdates,
  getUpdateRequests,
  getRejectedRequests,
  getSentRequests,
  getOnHoldUpdates,
} from "../../../redux/actions/updates";
import { searchVendorAccounts } from "../../../redux/actions/users";
import { defineCustomerColumns as defineAccountColumns } from "./sales-columns";
import { ContextMenu, MenuItem, hideMenu } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";
import Icons from "../../../components/fts/Icons";

import { Card, Row, Col, TabPane, Input, Spinner, Popover } from "reactstrap";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";

import "../../../assets/scss/plugins/extensions/react-paginate.scss";
import "../../../assets/scss/pages/sales-tab.scss";
import SalesEmployeePicker from "../../../components/fts/sales-employee-picker";
import SalesReassignDropdown from "../../../components/fts/sales-reassign-dropdown";

class Customers extends Component {
  constructor(props) {
    super(props);

    const tempList = this.props.list;

    if (tempList[0].value !== 0) {
      tempList.unshift({
        value: 0,
        label: "Unassigned",
      });
    }

    this.accountColumnsVendor = defineAccountColumns(
      this.props.types,
      this.props.adminPermissions,
      this.collectDataForCopy,
      this.togglePopover,
      tempList,
    );
  }

  togglePopover = (popOverName, popoverData) => {
    if (popOverName) {
      return this.setState({
        popoverTarget: popOverName,
        popoverOpen: true,
        popoverData,
      });
    }
    return this.setState({
      popoverTarget: "",
      popoverOpen: false,
      popoverData: null,
    });
  };

  state = {
    collectedDataForCopy: "",
    rowData: null,
    inProgress: false,
    isVisible: true,
    collapse: true,
    initialSearch: true,
    page: 1,
    totalRecords: 0,
    pageCount: 0,
    pageSize: 25,
    accountSearchValue: "",
    pageSizeFree: 50,
    query: this.props.storedFilter,
    defaultColDef: {
      sortable: true,
    },
    columnDefs: this.accountColumns,
    isOpenReassignDropdown: null,
    adminAccountOwner: null,
    selectedAccounts: null,
  };

  onRowClicked = (e) => {
    if (
      e.api.focusedCellController.focusedCellPosition.column.colId !==
      "adminAccountOwner"
    ) {
      history.push(`/accounts/${e.data.id}`);
    }
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  resetApiAccount = () => {
    this.gridApiAccount = null;
  };

  onGridReadyAccount = (params) => {
    this.gridApiAccount = params.api;
    this.gridColumnApiAccount = params.columnApi;

    if (localStorage.getItem("accountColumnState") !== null) {
      this.gridColumnApiAccount.setColumnState(
        JSON.parse(localStorage.getItem("accountColumnState")),
      );
    }
  };

  saveStateAccount = () => {
    let accountColumnState = this.gridColumnApiAccount.getColumnState();
    localStorage.setItem(
      "accountColumnState",
      JSON.stringify(accountColumnState),
    );
  };

  debounce = setTimeout(() => {}, 300);

  updateSearchQuery = (e) => {
    if (isRefreshing) return;

    this.setState(
      {
        accountSearchValue: e.target.value,
        page: 1,
        pageSize: this.state.pageSize,
      },
      () => {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.updateAccountSearchResults();
        }, 300);
      },
    );
  };

  componentDidMount = async () => {
    const { listState } = this.props.storedFilter;
    this.setState({ inProgress: true });

    this.setState(listState, async () => {
      await this.updateAccountSearchResults();
      this.setState({ inProgress: false });
    });

    window.addEventListener("mousedown", this.handleDropdownClickOutside);
  };

  refreshTable = () => {
    this.updateAccountSearchResults();
  };

  updateAdminAccountOwner = (value) => {
    this.setState(
      {
        adminAccountOwner: value ? value.value : null,
        page: 1,
      },
      () => this.refreshTable(),
    );
  };

  handleReassignDropdown = () => {
    this.setState({
      isOpenReassignDropdown: !this.state.isOpenReassignDropdown,
    });
  };

  handleDropdownClickOutside = (e) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(e.target) &&
      this.state.isOpenReassignDropdown
    ) {
      this.handleReassignDropdown();
    }
  };

  getSelectedAccounts = () => {
    let selectedAccounts =
      this.gridApiAccount.selectionController.selectedNodes;

    selectedAccounts = Object.values(selectedAccounts);

    selectedAccounts = selectedAccounts
      .filter((customer) => {
        if (customer?.data.id) {
          return customer.data.id;
        }
        return null;
      })
      .map((customer) => customer.data.id);

    this.setState({ selectedAccounts: selectedAccounts });
  };

  pageSizes = [10, 25, 50, 100];

  dropdownRef = createRef();

  updateAccountSearchResults = () => {
    const { page, pageSize, sortAccountVendor, accountSearchValue } =
      this.state;
    const apiAvailableAccount = !!this.gridApiAccount;
    if (apiAvailableAccount) {
      this.gridApiAccount.showLoadingOverlay();
    }

    return this.props
      .searchVendorAccounts({
        page,
        resultsPerPage: pageSize,
        sort: sortAccountVendor?.length
          ? sortAccountVendor
          : [{ colId: "createdAt", sort: "desc" }],
        searchString: accountSearchValue,
        adminAccountOwner: this.state.adminAccountOwner,
        adminStatus: 3,
      })
      .then(
        ({
          data: {
            result: { hits, nbHits },
          },
        }) => {
          if (apiAvailableAccount) {
            this.gridApiAccount.hideOverlay();
          }
          this.setState({
            columnDefs: this.accountColumns,
            rowData: hits,
            totalRecords: nbHits,
            pageCount: Math.ceil(nbHits / pageSize),
            selectedAccounts: [],
          });
        },
      );
  };

  onSortChanged = (params) => {
    this.setState({ sortAccountVendor: params.api.getSortModel() });
    return this.updateAccountSearchResults();
  };

  unselectAllAccounts = () => {
    this.gridApiAccount.deselectAll();
    this.setState({ selectedAccounts: [] }, () => {
      setTimeout(() => {
        this.refreshTable();
      }, 1000);
    });
  };

  getTableLayouts = () => {
    if (!this.tableLayouts) {
      const defaultTableLayout = {
        onRowClicked: this.onRowClicked,
        onCreateNew: this.onCreateNew,
        onGridApiReady: this.onGridApiReady,
        getTableData: this.getTableData,
        pageSizes: this.pageSizes,
        hasSearch: false,
        initialTableData: null,
        initialPage: 1,
        initialPageSize: 10,
        initialPageCount: 0,
        initialTotalRecords: 0,
        initialSearchVal: this.state.searchString,
      };

      this.tableLayouts = {
        customers: {
          ...defaultTableLayout,
          columnDefs: defineAccountColumns,
          tabLabel: "Customers",
        },
      };
    }

    return this.tableLayouts;
  };

  filterSize = (type, val) => {
    this.setState(
      {
        [type]: val,
      },
      () => {
        this.updateAccountSearchResults();
      },
    );
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      this.updateAccountSearchResults();
    });
  };

  render() {
    const {
      pageSize,
      page,
      pageCount,
      rowData,
      defaultColDef,
      totalRecords,
      accountSearchValue,
      inProgress,
      isOpenReassignDropdown,
      selectedAccounts,
    } = this.state;

    return (
      <Row className="sales-lists">
        <Col sm="12">
          <Card>
            <div>
              <TabPane tabId="vendor">
                <div className="result-header d-flex align-items-center">
                  <div>
                    <p className="result-title">
                      Customers (
                      {Number(totalRecords.toFixed(1)).toLocaleString()})
                    </p>
                  </div>
                  <div className="ml-auto d-flex align-items-center">
                    <div className="assigned-to-dropdown">
                      <SalesEmployeePicker
                        isClearable
                        showAssigned
                        customersPage
                        placeholder="Assigned to"
                        list={this.props.list}
                        updateAdminAccountOwner={this.updateAdminAccountOwner}
                        refreshTable={this.refreshTable}
                        search
                      />
                    </div>
                    <div className="filter-section mr-1">
                      <Input
                        type="text"
                        placeholder="Search..."
                        style={{ width: "230px" }}
                        onChange={this.updateSearchQuery}
                        value={accountSearchValue}
                      />
                    </div>
                    <div>
                      <SalesReassignDropdown
                        dropdownRef={this.dropdownRef}
                        handleDropdown={this.handleReassignDropdown}
                        isOpenReassignDropdown={isOpenReassignDropdown}
                        selectedAccounts={selectedAccounts}
                        onReassign={() => {
                          this.unselectAllAccounts();
                        }}
                        reassignTo
                        customers={true}
                      />
                    </div>
                  </div>
                </div>
              </TabPane>

              {rowData === null && !inProgress && (
                <Col sm="12" className="text-center mt-5">
                  <Spinner />
                </Col>
              )}
              <FTSDataTable
                onSortChanged={(params) =>
                  this.onSortChanged(params, "vendors")
                }
                rowSelection="multiple"
                getSelectedAccounts={this.getSelectedAccounts}
                onScroll={hideMenu}
                isSearch
                salesTab
                pageSizes={this.pageSizes}
                currentPageSize={pageSize}
                pageSizePropName={"pageSize"}
                currentPage={page}
                pageCount={pageCount}
                onGridReadyInit={this.onGridReadyAccount}
                saveState={this.saveStateAccount}
                filterSize={this.filterSize}
                rowData={rowData}
                onPageChange={this.onPageChanged}
                resetApi={this.resetApiAccount}
                onRowClicked={this.onRowClicked}
                columnDefs={this.accountColumnsVendor}
                {...{
                  defaultColDef,
                  totalRecords,
                }}
              />
              {this.state.popoverOpen && (
                <Popover
                  className="bg-sales-popover"
                  popperClassName={"bg-popover"}
                  style={{ minWidth: 310 }}
                  placement="bottom"
                  isOpen={this.state.popoverOpen}
                  target={this.state.popoverTarget}
                >
                  {this.state.popoverData.length ? (
                    <>
                      <p className="bg-popover-title">MAIN CATEGORIES:</p>

                      <div className="bg-popover-wrapper">
                        {this.state.popoverData.map((category, index) => (
                          <div key={index} className="bg-popover-cat-name">
                            {category}
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}
                </Popover>
              )}
              <ContextMenu
                style={{ minWidth: 250, zIndex: 1000 }}
                id="contextMenuCustomerLeads"
                preventHideOnScroll={false}
              >
                <MenuItem
                  onClick={() =>
                    navigator.clipboard.writeText(
                      this.state.collectedDataForCopy,
                    )
                  }
                >
                  <FaRegCopy size={18} className="copy" />
                  <span className="ml-1 text-dark">Copy</span>
                </MenuItem>
                {this.state.call && this.state.collectedDataForCopy && (
                  <MenuItem>
                    <a
                      className="text-decoration-none text-dark d-flex align-items-center w-100"
                      href={`tel:${this.state.collectedDataForCopy}`}
                    >
                      <Icons name="PhoneBlue" />
                      <div className="ml-1">
                        Call - {this.state.collectedDataForCopy}
                      </div>
                    </a>
                  </MenuItem>
                )}
              </ContextMenu>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.essentialLists.employees,
    storedFilter: state.searchFilter,
    types: state.essentialLists.types,
    userData: state.auth.login.values.loggedInUser,
    adminPermissions: state.auth.login.permissions,
  };
};

export default connect(mapStateToProps, {
  searchVendorAccounts,
  getCountAll,
  getAllUpdates,
  getUpdateRequests,
  getRejectedRequests,
  getSentRequests,
  getOnHoldUpdates,
})(Customers);
