import FTSAxios from "../../../axios/fts.instance";

export const fetchActivitiesAccount = (accountID) => {
  return () => {
    return FTSAxios.get(`admin/activity/get/user/0/${accountID}`);
  };
};

export const fetchActivitiesListing = (listingId) => {
  return () => {
    return FTSAxios.get(`admin/activity/get/listing/0/${listingId}`);
  };
};

export const fetchActivities = (payload) => {
  return () => {
    return FTSAxios.get("admin/activity", { params: payload });
  };
};

export const createActivity = (data) => {
  return () => {
    return FTSAxios.post("admin/activity", data);
  };
};

export const updateActivity = (params) => {
  return () => {
    return FTSAxios.put("admin/activity", { params });
  };
};

export const createActivities = (data) => {
  return () => {
    return FTSAxios.post("admin/activity/bulk", data);
  };
};
