const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
};

const approved = (state = initialState, action) => {
  switch (action.type) {
    case "GET_APPROVED_IPS":
      return { ...state, data: action.data };
    case "FILTER_APPROVED_IP_DATA":
      let value = action.value;
      let filteredData = [];
      if (value.length) {
        filteredData = state.data.filter((item) => {
          let startsWithCondition =
            item.address.toLowerCase().startsWith(value.toLowerCase()) ||
            item.note.toLowerCase().startsWith(value.toLowerCase()) ||
            item.createdBy.toLowerCase().startsWith(value.toLowerCase());

          let includesCondition =
            item.address.toLowerCase().startsWith(value.toLowerCase()) ||
            item.note.toLowerCase().startsWith(value.toLowerCase()) ||
            item.createdBy.toLowerCase().startsWith(value.toLowerCase());

          if (startsWithCondition) {
            return startsWithCondition;
          } else if (!startsWithCondition && includesCondition) {
            return includesCondition;
          } else return null;
        });
        return { ...state, filteredData };
      } else {
        filteredData = state.data;
        return { ...state, filteredData };
      }
    default:
      return state;
  }
};

export default approved;
