import React, { PureComponent } from "react";
import Icon from "../Icons";
import { history } from "../../../history";

import "../../../assets/scss/account-box-component.scss";

class AccountBoxComponent extends PureComponent {
  render() {
    const { accountInfo, listing, updateListingFromEmail, listingNav } =
      this.props;

    if (!accountInfo) {
      return null;
    }

    const userTypeName = accountInfo.userTypeNamed;

    return (
      <>
        {listingNav ? (
          <div
            className="listing-nav-account-box"
            onClick={() => {
              history.push({
                pathname: `/accounts/${accountInfo.id}`,
                search: "?activeTab=0",
              });
            }}
          >
            {userTypeName === "vendor" ? (
              <p className="account-box-badge vendor">Vendor</p>
            ) : (
              <p className="account-box-badge user">User</p>
            )}
            <span className="nav-company-name">
              {accountInfo.companyName ||
                accountInfo.company_name ||
                "NEW ACCOUNT"}
            </span>
          </div>
        ) : (
          <div
            className={`${
              !updateListingFromEmail ? "account-box-hover" : null
            }`}
          >
            <div
              className="account-box-wrapper"
              onClick={() => {
                !updateListingFromEmail &&
                  history.push(`/accounts/${accountInfo.id}`);
              }}
            >
              {!listing && (
                <div className="icon-left">
                  <Icon name="GoTo" />
                </div>
              )}
              <div
                className={`company-name ${listing ? "gray" : null}`}
                // style={{ wordBreak: "break-all" }}
              >
                {accountInfo.companyName || accountInfo.company_name}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default AccountBoxComponent;
