import React, { Component } from "react";
import {
  Button,
  Col,
  Collapse,
  CustomInput,
  FormGroup,
  Row,
  Spinner,
} from "reactstrap";
import { ChevronDown, ChevronUp } from "react-feather";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { phoneNumberMask } from "../../../utility/masks";

import FormikInput from "../formik/formik-input";
import FormikMaskedInput from "../formik/masked-input";
import FTSNote from "../fts-note";
import * as Yup from "yup";
import { createActivity } from "../../../redux/actions/activities";
import { Field } from "formik";

const formSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("First name is required"),
  lastName: Yup.string().trim().required("Last name is required"),
});
class AltItem extends Component {
  state = {
    isOpen: this.props.item.isOpen || false,
    inProgress: false,
    isValid: false,
    altContact: {
      firstName: "",
      lastName: "",
      email: "",
    },
    activeField: "",
  };

  shouldComponentUpdate(prevProps, prevState) {
    formSchema.isValid(this.state.altContact).then((e) => {
      if (this.state.isValid !== e) {
        this.setState({ isValid: e });
      }
    });
    return (
      prevState.isOpen !== this.state.isOpen ||
      prevState.inProgress !== this.state.inProgress ||
      prevProps.editMode !== this.props.editMode ||
      prevState.isValid !== this.state.isValid
    );
  }
  toggleCollapse = () => {
    if (this.state.inProgress) {
      return;
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  removeContactItem = () => {
    this.props.arrayHelpers.remove(this.props.index);
  };

  deleteContactItem = () => {
    this.setState({ inProgress: true });
    this.props
      .onRemove(this.props.item.id)
      .then(() => {
        toast.success("Alt contact successfully removed", {
          position: toast.POSITION.TOP_RIGHT,
        });

        this.setState({ isOpen: !this.state.isOpen, inProgress: false });
        this.props.getAltContactInfo(this.props.item.userId);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ inProgress: false });
      });
  };

  confirmCreation = () => {
    this.setState({ inProgress: true });
    this.props
      .onCreate(this.props.item.id, this.props.index)
      .then(({ data }) => {
        toast.success("Alt contact successfully added", {
          position: toast.POSITION.TOP_RIGHT,
        });

        this.props.setNewAltContacts(data.contact);

        this.setState({ isOpen: !this.state.isOpen, inProgress: false });
        this.props.getAltContactInfo(data?.contact?.userId);
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  confirmUpdate = () => {
    this.setState({ inProgress: true });
    this.props
      .onUpdate(this.props.item.id)
      .then(({ data }) => {
        toast.success("Alt contact successfully updated", {
          position: toast.POSITION.TOP_RIGHT,
        });

        this.setState({ isOpen: !this.state.isOpen, inProgress: false });
        this.props.getAltContactInfo(data?.contact?.userId);
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  render() {
    const { isOpen, inProgress, isValid, activeField } = this.state;
    const {
      item,
      editMode,
      setFieldError,
      setFieldTouched,
      index,
      setFieldValue,
    } = this.props;

    const isCreated = !!item.userId;
    const itemName = item.name ?? `${item.firstName} ${item.lastName}`;

    if (document.querySelectorAll(`[name="${activeField}"]`)[0]) {
      setTimeout(() => {
        if (document.querySelectorAll(`[name="${activeField}"]`)[0]) {
          document.querySelectorAll(`[name="${activeField}"]`)[0].focus();
        }
      }, 100);
    }
    return (
      <div key={item.id} className="alt-contacts__item">
        <div
          className="alt-contacts__item__header"
          onClick={this.toggleCollapse}
        >
          <div className="alt-contacts__item__title">{itemName}</div>
          <div className="alt-contacts__item__icon">
            {isOpen ? (
              <ChevronUp color="#7D8C91" width={13} height={13} />
            ) : (
              <ChevronDown color="#7D8C91" width={13} height={13} />
            )}
          </div>
        </div>
        <Collapse {...{ isOpen }}>
          <div className="alt-contacts__item__content">
            {inProgress ? (
              <div className="d-flex justify-content-center pl-2 pr-2 pb-2">
                <Spinner size="sm" />
              </div>
            ) : (
              <div>
                <Row>
                  <Col md="6" xl="4">
                    <FormikInput
                      name={`altContacts.${index}.firstName`}
                      label="First name"
                      labelId={`firstName_alt_${item.id}`}
                      disabled={!editMode}
                      placeholder="First name"
                      required
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                        this.setState({
                          altContact: {
                            ...this.state.altContact,
                            [e.target.name.split(".").pop()]: e.target.value,
                          },
                          activeField: e.target.name,
                        });
                      }}
                    />
                  </Col>
                  <Col md="6" xl="4">
                    <FormikInput
                      name={`altContacts.${index}.lastName`}
                      label="Last name"
                      labelId={`lastName_alt_${item.id}`}
                      disabled={!editMode}
                      placeholder="Last name"
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);

                        this.setState({
                          altContact: {
                            ...this.state.altContact,
                            [e.target.name.split(".").pop()]: e.target.value,
                          },
                          activeField: e.target.name,
                        });
                      }}
                      required
                    />
                  </Col>
                  <Col md="6" xl="4">
                    <FormikInput
                      name={`altContacts.${index}.jobTitle`}
                      label="Job title"
                      labelId={`jobTitle_alt_${item.id}`}
                      disabled={!editMode}
                      placeholder="Job title"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6" xl="4">
                    <FormikMaskedInput
                      disabled={!editMode}
                      name={`altContacts.${index}.mobileNumber`}
                      label="Mobile phone"
                      labelId={`mobileNumber_${item.id}`}
                      placeholder="Mobile phone number"
                      mask={phoneNumberMask}
                    />
                  </Col>
                  <Col md="6" xl="4">
                    <FormikMaskedInput
                      disabled={!editMode}
                      name={`altContacts.${index}.phoneNumber`}
                      label="Primary phone number"
                      labelId={`phoneNumber_${item.id}`}
                      placeholder="Primary phone number"
                      mask={phoneNumberMask}
                    />
                  </Col>
                  <Col md="6" xl="4">
                    <FormikMaskedInput
                      disabled={!editMode}
                      name={`altContacts.${index}.phoneNumberSecondary`}
                      label="Secondary phone number"
                      labelId={`phoneNumberSecondary_${item.id}`}
                      placeholder="Secondary phone number"
                      mask={phoneNumberMask}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6" xl="4">
                    <FormikInput
                      name={`altContacts.${index}.email`}
                      label="E-mail"
                      labelId={`email_alt_${item.id}`}
                      disabled={!editMode}
                      placeholder="E-mail"
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                        this.setState({
                          altContact: {
                            ...this.state.altContact,
                            [e.target.name.split(".").pop()]: e.target.value,
                          },
                          activeField: e.target.name,
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <FormGroup
                      className="mb-1"
                      style={{
                        marginTop: editMode ? "2.7rem" : "1.2rem",
                      }}
                    >
                      <Field
                        name={`altContacts.${index}.primaryMarketingOptIn`}
                      >
                        {({ field: { value, name }, form }) => {
                          return (
                            <CustomInput
                              defaultChecked={
                                this.props.item.primaryMarketingOptIn || value
                              }
                              type="switch"
                              id={`altContacts.${index}.primaryMarketingOptIn`}
                              inline
                              onChange={(e) => {
                                form.setFieldValue(name, e.target.checked);
                              }}
                              disabled={
                                // !havePermissionForMarketingOptIn || havePermissionForMarketingOptIn === 1 ||
                                !editMode
                              }
                            >
                              <span className="ml-1">Marketing Opt-in</span>
                            </CustomInput>
                          );
                        }}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup
                      className="mb-1"
                      style={{
                        marginTop: editMode ? "2.7rem" : "1.2rem",
                      }}
                    >
                      <Field name={`altContacts.${index}.primaryUpdatesOptIn`}>
                        {({ field: { value, name }, form }) => {
                          // const accountAdditioanlMarketingOptIn =
                          //   this.props.accountData?.additionalMarketingOptIn;

                          return (
                            <CustomInput
                              defaultChecked={
                                this.props.primaryUpdatesOptIn || value
                              }
                              type="switch"
                              id={`altContacts.${index}.primaryUpdatesOptIn`}
                              inline
                              onChange={(e) => {
                                form.setFieldValue(name, e.target.checked);
                              }}
                              disabled={
                                // !havePermissionForUpdatesOptIn || havePermissionForUpdatesOptIn === 1 ||
                                !editMode
                              }
                            >
                              <span className="ml-1">Updates Opt-in</span>
                            </CustomInput>
                          );
                        }}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup
                      className="mb-1"
                      style={{
                        marginTop: editMode ? "2.7rem" : "1.2rem",
                      }}
                    >
                      <Field name={`altContacts.${index}.primaryRenewalsOptIn`}>
                        {({ field: { value, name }, form }) => {
                          return (
                            <CustomInput
                              defaultChecked={
                                this.props.item.primaryRenewalsOptIn || value
                              }
                              type="switch"
                              id={`altContacts.${index}.primaryRenewalsOptIn`}
                              inline
                              onChange={(e) => {
                                form.setFieldValue(name, e.target.checked);
                              }}
                              disabled={
                                // !havePermissionForUpdatesOptIn || havePermissionForUpdatesOptIn === 1 ||
                                !editMode
                              }
                            >
                              <span className="ml-1">Renewal Opt-in</span>
                            </CustomInput>
                          );
                        }}
                      </Field>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FTSNote
                      noteRequired={false}
                      noSticky
                      name={`altContacts.${index}.adminNotes`}
                      label="Note"
                      labelId={`todoNote_${item.id}`}
                      typeName={`altContacts.${index}.noteType`}
                      typePlaceholder="Note type..."
                      placeholder="Some note goes here..."
                      disabled={!editMode}
                      noteLabelValue={item.noteType}
                      {...{ setFieldError, setFieldTouched }}
                    />
                  </Col>
                </Row>
                {!isCreated && (
                  <Row>
                    <Col className="justify-content-end d-flex pb-1">
                      <div>
                        <Button
                          onClick={this.removeContactItem}
                          color="secondary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.confirmCreation}
                          color="primary"
                          className="ml-1"
                          disabled={!isValid}
                        >
                          Add
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
                {isCreated && editMode && (
                  <Row>
                    <Col className="justify-content-end d-flex pb-1">
                      <div>
                        <Button
                          onClick={this.deleteContactItem}
                          color="secondary"
                        >
                          Remove
                        </Button>
                        <Button
                          onClick={this.confirmUpdate}
                          color="primary"
                          className="ml-1"
                          type="button"
                        >
                          Save changes
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitiesTypes: state.essentialLists.activitiesTypes,
    userData: state.auth.login.values,
  };
};

export default connect(mapStateToProps, {
  createActivity,
})(AltItem);
