import React, { PureComponent } from "react";

import "../../../assets/scss/account-cards.scss";
import Icons from "../Icons";
import { nanoid } from "nanoid";
import { FieldArray } from "formik";
import FormikMaskedInput from "../formik/masked-input";
import FormikInput from "../formik/formik-input";
import { zipCodeMask } from "../../../utility/masks";

import { Button } from "reactstrap";
import { deletePayment, setDefaultPayment } from "../../../redux/actions/users";
import { connect } from "react-redux";
import ConfirmationModal from "../../../components/fts/confirmation-modal";
import { checkPermissions } from "../../../utility";
import { PERMISSIONS, SUB_PERMISSIONS } from "../../../constants";

class PaymentMethods extends PureComponent {
  state = {
    confirmModal: false,
    deleteCardId: null,
  };

  addNewPaymentMethod = () => {
    const nanoID = nanoid(3);
    this.props.setFieldValue("userCards", [
      ...this.props.cards,
      {
        isOpen: true,
        new: true,
        name: "New payment method",
        id: nanoID,
        status: true,
        isDefault: false,
        cardNumber: "",
        cardHolder: "",
        expirationDate: "",
        cvv: "",
        zipCode: "",
      },
    ]);
  };

  setDefaultPayment = (id) => {
    let newCards = this.props.cards.map((card) => {
      if (card.id === id) {
        return { ...card, isDefault: true };
      }
      return { ...card, isDefault: false };
    });

    this.props.setDefaultPayment(id);
    this.props.setFieldValue("userCards", newCards);
  };

  deleteCard = () => {
    const { deleteCardId: id } = this.state;
    let newCards = this.props.cards.filter((card) => card.id !== id);

    this.props.deletePayment(id);
    this.props.setFieldValue("userCards", newCards);
    this.setState({ deleteCardId: null, confirmModal: false });
  };

  render() {
    const {
      cards,
      editMode,
      payWithCard = () => {},
      wrapperClassName = "",
      hideRemoveBtn,
    } = this.props;

    const havePermissionsForRemoveExistingCard = checkPermissions(
      [PERMISSIONS.ADDITIONAL_FEATURES],
      this.props.adminUserPermissions,
      [SUB_PERMISSIONS[PERMISSIONS.ADDITIONAL_FEATURES].REMOVE_EXISTING_CARD],
    );

    if (!cards?.length)
      return (
        <>
          <span>Account does not have any Payment methods yet.</span>
          {editMode && (
            <Button
              outline
              block
              type="button"
              className="p-1 mt-2"
              color="primary"
              onClick={this.addNewPaymentMethod}
            >
              + Add payment method
            </Button>
          )}
        </>
      );

    const brand = {
      visa: "Visa",
      amex: "Amex",
      mastercard: "Mastercard",
      discover: "Visa",
    };

    return (
      <>
        {cards.map((card, index) => {
          if (card?.new)
            return (
              <CreateNewPaymentMethod key={card.id} index={index} card={card} />
            );
          return (
            <div
              onClick={() => payWithCard(card)}
              key={card.id}
              className={"mt-2 credit-card-wrapper" + wrapperClassName}
              style={{
                padding: 24,
                border: "1px solid #E5E8E9",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="d-flex align-items-center">
                <Icons
                  className={"some-class"}
                  name={brand[card.brand]}
                  size={50}
                />
                <p className={"ml-1 mt-0 mb-0"}>
                  {card.brand} ({card.lastFourDigital})
                </p>
              </div>
              {!hideRemoveBtn && havePermissionsForRemoveExistingCard && (
                <div className="d-flex align-items-center">
                  <Icons
                    onClick={() => {
                      this.setState({
                        deleteCardId: card.id,
                        confirmModal: true,
                      });
                    }}
                    className="cursor-pointer"
                    name={"Delete"}
                    size={30}
                  />
                </div>
              )}
            </div>
          );
        })}
        {editMode && (
          <Button
            outline
            block
            type="button"
            className="p-1 mt-2"
            color="primary"
            onClick={this.addNewPaymentMethod}
          >
            + Add payment method
          </Button>
        )}
        <ConfirmationModal
          title="Confirmation"
          message={"Are you sure you want to remove this credit card?"}
          closeModal={() => this.setState({ confirmModal: false })}
          confirmModal={this.deleteCard}
          isOpen={this.state.confirmModal}
        />
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      adminUserPermissions: state.auth.login?.values.loggedInUser.permissions,
    };
  },
  { setDefaultPayment, deletePayment },
)(PaymentMethods);

const CreateNewPaymentMethod = ({ index }) => {
  return (
    <FieldArray
      name="userCards"
      render={() => {
        return (
          <div className="credit-card-form">
            <span className="d-flex">
              <div className="credit-card-fields">
                <span className="labels">Card number</span>

                <FormikMaskedInput
                  name={`userCards.${index}.cardNumber`}
                  placeholder="0000-0000-0000-0000"
                  required
                  mask={[
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    "-",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    "-",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    "-",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                />
              </div>

              <div className="credit-card-fields">
                <span className="labels">Cardholder name</span>

                <FormikInput
                  name={`userCards.${index}.cardHolder`}
                  placeholder="Cardholder name"
                />
              </div>
            </span>

            <span className="d-flex">
              <div className="credit-card-fields">
                <span className="labels">Expiration date</span>
                <FormikMaskedInput
                  name={`userCards.${index}.expirationDate`}
                  placeholder="00/00"
                  required
                  mask={[/[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/]}
                />
              </div>
              <div className="credit-card-fields">
                <span className="labels">CVV</span>
                <FormikMaskedInput
                  mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                  name={`userCards.${index}.cvv`}
                  placeholder="CVV"
                  required
                />
              </div>
              <div className="credit-card-fields">
                <span className="labels">Billing zip code</span>
                <FormikMaskedInput
                  name={`userCards.${index}.zipCode`}
                  placeholder="000000"
                  mask={zipCodeMask}
                />
              </div>
            </span>
          </div>
        );
      }}
    />
  );
};
