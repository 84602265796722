import React from "react";
import { Badge } from "reactstrap";

import "../../../assets/scss/components/fts/fts-badge.scss";

const FTSBadge = (props) => {
  return (
    <Badge {...props} className={`fts-badge ${props.className}`}>
      {props.children}
    </Badge>
  );
};

export default FTSBadge;
