import React, { Component } from "react";

import { Button, Spinner } from "reactstrap";
import FTSModal from "../fts-modal";
import CloseButton from "../CloseButton";
import Icon from "../Icons";

import "../../../assets/scss/components/fts/listing-update-confirmation-modal.scss";

class ListingProofSentSuccessfully extends Component {
  closeModal = () => {
    this.props.closeModal();
    this.props.closePreviewModal && this.props.closePreviewModal();
  };

  confirmModal = () => {
    this.props.confirmMultipleListingsUpdate();
  };

  render() {
    const { isOpen, title, closeModal, onClose } = this.props;

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-reassign-modal"}
        centered={true}
        style={{ maxWidth: "665px" }}
        footerCTA={() => {
          return (
            <div className="update-listing-confrimation-modal-cta">
              <Button
                onClick={() => {
                  this.closeModal();
                }}
                color="primary"
              >
                OK
              </Button>
            </div>
          );
        }}
      >
        <div className="reassign-modal-body">
          <span className="reassign-modal-body-wrapper">
            <span className="reassign-modal-title">
              <div className="d-flex update-listing-confirmation-title">
                <Icon name="ProofSuccess" size={56} />
                <div>
                  <span className="d-flex align-center">
                    <h3>Listing proof successfully sent</h3>

                    {this.props.inProgress && (
                      <div className="confirmation-spinner-wrapper">
                        <Spinner size="sm" />
                      </div>
                    )}
                  </span>

                  <span>After vendor responds you will be notified.</span>
                </div>
              </div>

              <CloseButton onClick={this.closeModal} />
            </span>
          </span>
        </div>
      </FTSModal>
    );
  }
}

export default ListingProofSentSuccessfully;
