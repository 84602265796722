import React from "react";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "../../../components/@vuexy/breadCrumbs/BreadCrumb";
import ServiceAmenitiesViewConfig from "./ServiceAmenitiesListingConfig";
import queryString from "query-string";

class ServiceAmenitiesView extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Breadcrumbs
          breadCrumbTitle="Service Amenities"
          breadCrumbActive="All Service Amenities"
        />
        <Row>
          <Col sm="12">
            <ServiceAmenitiesViewConfig
              parsedFilter={queryString.parse(this.props.location.search)}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ServiceAmenitiesView;
