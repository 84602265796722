import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  PERMISSION_GATE_DEFAULT_FALLBACK,
  PERMISSION_GATE_PRINCIPLE,
} from "../../../constants";
import { checkPermissions } from "../../../utility";

const PermissionGate = (props) => {
  const [isPassed, setIsPassed] = useState(false);

  const {
    fallbackComponent,
    userPermissions = [],
    principle = PERMISSION_GATE_PRINCIPLE.ALL,
    children,
    forcePassed,
    ///
    permissions = [],
    subPermissions = [],
  } = props;

  useEffect(() => {
    if (forcePassed) return;

    if (principle === PERMISSION_GATE_PRINCIPLE.ALL) {
      let passed = checkPermissions(
        permissions,
        userPermissions,
        subPermissions
      );

      setIsPassed(passed);
    }
  }, [
    userPermissions,
    principle,
    fallbackComponent,
    setIsPassed,
    forcePassed,
    permissions,
    subPermissions,
  ]);

  const FallbackComponent =
    fallbackComponent || PERMISSION_GATE_DEFAULT_FALLBACK;

  if (isPassed || forcePassed) {
    return <>{children}</>;
  }

  return <FallbackComponent />;
};

const mapStateToProps = (state) => {
  return {
    userPermissions: state.auth.login?.values.loggedInUser.permissions,
  };
};

export default connect(mapStateToProps, null)(PermissionGate);
