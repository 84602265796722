import React from "react";

import { history } from "../../../history";
import { Icon } from "../../../components/fts/Icons";

import "../../../assets/scss/components/fts/backButton-create.scss";

const BackButton = () => {
  return (
    <div
      className="backButton-container mr-1"
      onClick={() => {
        history.goBack();
      }}
    >
      <Icon size={32} name="BackToList" />
    </div>
  );
};

export default BackButton;
