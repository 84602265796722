import React, { Component } from "react";

import { Button } from "reactstrap";
import FTSModal from "../../../../../components/fts/fts-modal";
import CloseButton from "../../../../../components/fts/CloseButton";
import Icon from "../../../../../components/fts/Icons";
import "../../../../../assets/scss/components/fts/payment-modals.scss";

class PaymentErrorModal extends Component {
  render() {
    const { isOpen, type, title, closeModal, onClose, setPaymentStep } =
      this.props;

    const goToQuicbooks = () => {
      const url =
        "https://merchantcenter.intuit.com/msc/portal/login?service=https%3A%2F%2Fmerchantcenter.intuit.com%2Fmsc%2Fportal%2Fhome";
      const win = window.open(url, "_blank");
      win.focus();
    };

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-payment-modal"}
        centered={true}
        style={{ maxWidth: "665px" }}
        footerCTA={() => {
          return (
            <div className="confirm-payment-modal-footer">
              {type === "admin" ? (
                <Button
                  onClick={() => {
                    goToQuicbooks();
                    setPaymentStep("creditCard");
                  }}
                  color="primary"
                >
                  Go to Quickbooks
                </Button>
              ) : (
                <>
                  <span className="mr-1">
                    <Button onClick={closeModal} color="secondary">
                      Cancel
                    </Button>
                  </span>
                  <Button
                    onClick={() => {
                      setPaymentStep("paymentMethod");
                    }}
                    color="primary"
                  >
                    Choose another payment method
                  </Button>
                </>
              )}
            </div>
          );
        }}
      >
        <div className="payment-modal-body">
          <span className="payment-modal-body-wrapper">
            <span className="payment-modal-title payment-error-title">
              <span className="d-flex">
                <span className="card-remove-icon">
                  <Icon name="CardRemove" size="56" />
                </span>
                <span>
                  <h3>
                    {type === "admin"
                      ? "Payment failed"
                      : "Payment unsuccessful"}
                  </h3>
                  <span className="payment-error-text">
                    {type === "admin" ? (
                      "Stripe payment processing is temporarily down. Please use a payment alternative like Quickbooks and then log payment manually."
                    ) : (
                      <>
                        Payment processing is temporarily down. Please use a
                        payment alternative like PayPal or contact Find Truck
                        Service on{" "}
                        <span className="ftsPhoneNumber">(847) 586-9110</span>{" "}
                        to process your payment.
                      </>
                    )}
                  </span>
                </span>
              </span>

              <CloseButton onClick={closeModal} />
            </span>
          </span>
        </div>
      </FTSModal>
    );
  }
}

export default PaymentErrorModal;
