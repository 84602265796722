import React from "react";
import { FormGroup } from "reactstrap";

const Input = ({
  disabled,
  placeholder,
  className,
  inputClassName,
  ...rest
}) => {
  return (
    <FormGroup className={className}>
      <input
        className={`form-control ${inputClassName}`}
        disabled={disabled}
        placeholder={disabled ? "" : placeholder}
        {...rest}
      />
    </FormGroup>
  );
};

export default Input;
