import FTSAxios from "../../../axios/fts.instance";

export const loginWithRemoteAccess = ({ refreshToken }) => {
  return () => {
    return FTSAxios.post("admin/account-access-request/get-access-token", {
      refreshToken,
    });
  };
};

export const updateAccountAccessRequest = ({ id, status, expiresAt }) => {
  return () => {
    return FTSAxios.put(`admin/account-access-request/${id}`, {
      status,
      expiresAt,
    });
  };
};
