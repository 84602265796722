import React from "react";

import "../../../assets/scss/components/fts/fts-description-length.scss";

const descritpionLengths = [
  { id: 1, type: "Premium", length: "12 lines" },
  { id: 2, type: "Standard", length: "6 lines" },
  { id: 3, type: "Basic Plus", length: "3 lines" },
  { id: 4, type: "Basic", length: "No description" },
  { id: 5, type: "Free", length: "No description" },
];

const DescritpionLength = () => {
  return (
    <div className="fts-description-length">
      <div className="fts-description-length-title">Description Length:</div>
      {descritpionLengths.map((limit) => {
        return (
          <div key={limit.id} className="fts-description-length-item">
            <span className="type">{limit.type}</span>
            <span className="length">{limit.length}</span>
          </div>
        );
      })}
    </div>
  );
};

export default DescritpionLength;
