const initialState = {
  inProgress: false,
  promoCodesData: null,
  promoCodesPage: 1,
  totalRecordsPromoCodes: 0,
  totalAmount: 0,
  searchVal: "",
  pageSize: 10,
  pageCount: 0,
  sort: null,
  promoCode: null,
};

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROMOCODES_LIST":
      return {
        ...state,
        promoCodesData: action.payload?.rows || null,
        totalRecordsPromoCodes: action.payload?.count || 0,
      };

    case "SET_PROMO_CODE":
      return {
        ...state,
        promoCode: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default invoicesReducer;
