import FTSAxios from "../../../axios/fts.instance";
// import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";

//
// export const payWithStripes = (payload) => {
//   return (dispatch) => dispatch({ type: "SET_IN_PROGRESS", payload });
// };

export const payWithStripe = (data) => {
  return () => {
    return FTSAxios.post("admin/payment/stripe", data);
  };
};

export const payWithStripeAdmin = (data) => {
  return () => {
    return FTSAxios.post("admin/payment/stripe/generate", data);
  };
};

export const payWithStripeSuccess = (data) => {
  return () => {
    return FTSAxios.post("admin/payment/stripe/success", data);
  };
};

export const getPlaidPublicToken = (data) => {
  return () => {
    return FTSAxios.post("admin/payment/create_link_token", data);
  };
};

export const payWithPlaid = (data) => {
  return () => {
    return FTSAxios.post("admin/payment/plaid", data);
  };
};
export const payWithPaypal = (data) => {
  return () => {
    return FTSAxios.post("admin/payment/paypal", data);
  };
};

export const forceCreatePayment = (data) => {
  return () => {
    return FTSAxios.post("admin/payment/create", data);
  };
};
export const updatePayment = (data) => {
  return () => {
    return FTSAxios.put("admin/payment/update", data);
  };
};

export const payWithStripeTokenClient = (data) => {
  return () => {
    return FTSAxios.post("admin/payment/stripe/token", data);
  };
};

export const cancelListingRecurring = (data) => {
  return () => {
    return FTSAxios.put("admin/payment/update/recurring", data);
  };
};
