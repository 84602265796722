export const IconNames = {
    Achievement: "Achievement",
    AlertOctagon: "AlertOctagon",
    AlertTriangle: "AlertTriangle",
    Amex: "Amex",
    Check: "Check",
    ChevronDown: "ChevronDown",
    ChevronLeft: "ChevronLeft",
    Clock: "Clock",
    Close: "Close",
    CreditCard: "CreditCard",
    Danger: "Danger",
    Discover: "Discover",
    Edit: "Edit",
    ExternalLink: "ExternalLink",
    EyeIcon: "EyeIcon",
    Filter: "Filter",
    Info: "Info",
    Interstate: "Interstate",
    MapPin: "MapPin",
    Mastercard: "Mastercard",
    MoreVertical: "MoreVertical",
    PasswordCheckError: "PasswordCheckError",
    PasswordCheckPassed: "PasswordCheckPassed",
    PayPal: "PayPal",
    Plus: "Plus",
    Search: "Search",
    Star: "Star",
    StepActive: "StepActive",
    StepChecked: "StepChecked",
    Trash: "Trash",
    Visa: "Visa",
    facebookAuth: "facebookAuth",
    googleAuth: "googleAuth",
    PhotoIcon: "PhotoIcon",
    Refresh: "Refresh",
    Upload: "Upload",
};
