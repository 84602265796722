import React from "react";
import { Button } from "reactstrap";

const FTSAgGridDataTableActionSection = ({
  tableActionSectionText,
  handleCancelAction,
  handleSubmitAction,
  submitButtonLabel,
  cancelButtonLabel = "Cancel",
}) => {
  return (
    <div className="FTS-ag-grid-data-table-action-section">
      <span>{tableActionSectionText}</span>
      <div className="FTS-ag-grid-data-table-action-section-buttons">
        <Button
          type="button"
          className="ml-1"
          color="tertiary"
          outline
          onClick={handleCancelAction}
        >
          {cancelButtonLabel}
        </Button>

        <Button type="button" color="primary" onClick={handleSubmitAction}>
          {submitButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default FTSAgGridDataTableActionSection;
