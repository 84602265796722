import React from "react";
import { Row, Col } from "reactstrap";
import { ChevronRight } from "react-feather";
import classnames from "classnames";

import { connect } from "react-redux";
import { checkPermissions } from "../../../utility/permissionsCheck";

import Checkbox from "../Checkbox";

const SidebarHeader = ({
  toggleTodos,
  toggleNotes,
  toggleActivities,
  isListing,
  showTodos,
  showNotes,
  showActivities,
  switchSideTab,
  adminPermissions,
}) => {
  const onTodoClick = () => {
    if (isListing) {
      switchSideTab(7);
    } else {
      switchSideTab(8);
    }

    return;
  };

  const onNoteClick = () => {
    if (isListing) {
      switchSideTab(8);
    } else {
      switchSideTab(9);
    }
  };

  const onActivityClick = () => {
    if (
      isListing &&
      checkPermissions([{ id: 2, subCat: [24] }], adminPermissions)
    ) {
      switchSideTab(10);
    }

    if (
      !isListing &&
      checkPermissions([{ id: 3, subCat: [35] }], adminPermissions)
    ) {
      switchSideTab(11);
    }
    return;
  };

  const onTodoCheck = (e) => {
    toggleTodos(e.target.checked);
    stopClickPropagation(e);
  };

  const onNoteCheck = (e) => {
    toggleNotes(e.target.checked);
    stopClickPropagation(e);
  };

  const onActivityCheck = (e) => {
    toggleActivities(e.target.checked);
    stopClickPropagation(e);
  };

  const stopClickPropagation = (e) => e.stopPropagation();

  return (
    <div className="acc-list-sidebar-header">
      <Row>
        <Col
          md="4"
          className="pl-1 pr-1 text-center acc-list-sidebar-header-col"
        >
          <div
            className={classnames("acc-list-sidebar-header-btn", {
              checked: showTodos,
            })}
          >
            <Checkbox
              className="header-btn-check"
              defaultChecked={showTodos}
              onClick={onTodoCheck}
            />
            <span
              onClick={onTodoClick}
              style={{ cursor: "default" }}
              className="header-btn-label"
            >
              <span>To-Do's</span>
              <ChevronRight size={14} />
            </span>
          </div>
        </Col>
        <Col
          md="4"
          className="pl-0 pr-1 text-center acc-list-sidebar-header-col"
        >
          <div
            className={classnames("acc-list-sidebar-header-btn", {
              checked: showNotes,
            })}
          >
            <Checkbox
              className="header-btn-check"
              defaultChecked={showNotes}
              onClick={onNoteCheck}
            />
            <span className="header-btn-label" onClick={onNoteClick}>
              <span>Notes</span>
              <ChevronRight size={14} />
            </span>
          </div>
        </Col>
        {/* /// todo revert this*/}
        <Col
          md="4"
          className="pl-0 pr-1 text-center acc-list-sidebar-header-col"
        >
          <div
            className={classnames("acc-list-sidebar-header-btn", {
              checked: showActivities,
            })}
          >
            <Checkbox
              className="header-btn-check"
              defaultChecked={showActivities}
              onClick={onActivityCheck}
            />
            <span className="header-btn-label" onClick={onActivityClick}>
              <span>Activities</span>
              <ChevronRight size={14} />
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    adminPermissions: state.auth.login.permissions,
  };
};

export default connect(mapStateToProps, null)(SidebarHeader);
