import React, { Component } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

import "../../../assets/scss/components/fts/form-fields.scss";
import TitleRow from "../TitleRow";

import { generateAdsRatesKey } from "../../../utility/serialize";
import { connect } from "react-redux";
import FormikInput from "../formik/formik-input";
import Select from "../Select";
import { pickerStyles } from "../picker-style";

class AdsRates extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.editMode !== nextProps.editMode ||
      this.props.adsRates?.length !== nextProps.adsRates?.length
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      adsRates = [],
      title,
      notWrapped,
      editMode,
      keys,
      rates = [],
      form,
    } = this.props;
    let listingRates = adsRates;
    // console.log("PROPS: ", this.props);
    // debugger;
    if (!listingRates.length) {
      listingRates = rates.map((e) => ({ rate: e }));
    }

    if (adsRates.length !== rates.length) {
      listingRates = rates.map((e) => {
        const index = adsRates.findIndex((f) => f.rate.id === e.id);
        if (index !== -1) {
          return adsRates[index];
        }
        return { rate: e };
      });
    }

    const pickerStyle = {
      ...pickerStyles,
      container: (
        styles,
        { isDisabled, selectProps: { menuIsOpen, isMulti } },
      ) => ({
        ...styles,
        zIndex: menuIsOpen ? 3 : 0,
        height: isDisabled && !isMulti ? "25px" : styles.height,
        // width: 200,
      }),
    };
    // console.log("listingRates: ", listingRates);
    return (
      <div id="listing-type-services">
        {title &&
          (notWrapped ? (
            <Row>
              <Col>
                <h3 className={notWrapped ? "mb-0" : ""}>{title}</h3>
              </Col>
            </Row>
          ) : (
            <TitleRow title={title} />
          ))}
        <Row>
          {listingRates.map((e) => {
            const key = generateAdsRatesKey(e.rate?.name, e.rate?.id);
            if (e.rate?.id === 5 && editMode) {
              let defaultValue = e.value && { label: e.value, value: e.value };

              return (
                <Col xs={3} key={key}>
                  <FormGroup
                    className={"w-100"}
                    key={generateAdsRatesKey(e.rate?.name, e.rate?.id)}
                  >
                    <Label>{e.rate?.name}</Label>

                    <Select
                      id={key}
                      isClearable
                      isDisabled={!editMode}
                      placeholder={`Select ${e.rate?.name}`}
                      options={minimumChargeOptions}
                      isSearchable={false}
                      name={key}
                      defaultValue={defaultValue}
                      styles={pickerStyle}
                      onChange={(option) => {
                        const { setFieldValue, setFieldTouched } = form;

                        setFieldValue(key, option?.value);
                        setTimeout(() => {
                          setFieldTouched(key, true, true);
                        }, 0);
                      }}
                    />
                  </FormGroup>
                </Col>
              );
            }
            return (
              <Col xs={3} key={key}>
                <div
                  className={"position-relative w-100"}
                  key={`adsRates_${e.rate?.id}`}
                >
                  <span
                    className={`${
                      keys && keys.includes(key) && "field-changed"
                    } `}
                  >
                    <FormikInput
                      prepend={e.rate?.id !== 5 ? "$" : null}
                      form={form}
                      disabled={!editMode}
                      editMode={editMode}
                      type="number"
                      name={key}
                      label={e.rate?.name}
                      labelId={key}
                      placeholder="Enter rate"
                    />
                  </span>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rates: state.essentialLists.rates,
  };
};
export default connect(mapStateToProps)(AdsRates);

const minimumChargeOptions = [
  { label: "30 min", value: "30 min" },
  { label: "1h", value: "1h" },
  { label: "1h 30 min", value: "1h 30 min" },
  { label: "2h", value: "2h" },
  { label: "2h 30 min", value: "2h 30 min" },
];
