import React, { Component } from "react";
import { connect } from "react-redux";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormikInput from "../../../components/fts/formik/formik-input";

import { Button, Spinner, Row, Col } from "reactstrap";

import Icon from "../Icons";
import FTSModal from "../../../components/fts/fts-modal";
import CloseButton from "../../../components/fts/CloseButton";
import "../../../assets/scss/components/fts/payment-modals.scss";

import { createActivity } from "../../../redux/actions/activities";
import { activities } from "../../../constants";
import { toast } from "react-toastify";
import { cancelRequestUpdate } from "../../../redux/actions/updates";
import { updateInvoice } from "../../../redux/actions/invoices";

class CancelListingUpdateModal extends Component {
  state = {
    cancelUpgradeDowngradeInProgress: false,
    initialFormData: {
      voidReason: "",
    },
  };

  formSchema = Yup.object().shape({
    voidReason: Yup.string().trim().required("Reason is required"),
  });

  handleSubmit = async (values) => {
    this.confirmModal(values.voidReason);
  };

  confirmModal = (reason) => {
    this.setState({
      cancelUpgradeDowngradeInProgress: true,
    });
    const listingData = this.props.listingData;
    let payload = {
      adId: listingData.id,
      reason,
      userId: listingData.userId,
      priceId: listingData.priceId,
      renewalTimestamp: listingData.renewalTimestamp,
    };

    this.props.cancelRequestUpdate(payload).then((res) => {
      let activityPayload = {
        adId: listingData?.id,
        status: 1,
        userId: listingData?.userId,
        invoiceId: res.data?.invoice?.id,
        activityType: this.props.activitiesTypes.CANCELED_LISTING_TYPE_CHANGE,
        activityNote: reason,
        activity: activities.canceledListingTypeChange
          .replace(
            "{{admin_name}}",
            `${this.props.user.loggedInUser.firstName} ${this.props.user.loggedInUser.lastName}`,
          )
          .replace("{{changeType}}", "upgrade/downgrade")
          .replace("{{invoiceId}}", res.data?.invoice?.id)
          .replace("{{voidReason}}", reason),
        adminUserId: this.props.user.loggedInUser.id,
        iconName: "Listings",
      };

      this.props
        .createActivity(activityPayload)
        .then(async () => {
          this.props
            .updateInvoice({
              status: 0,
              invoiceId: res.data?.invoice?.id,
            })
            .then(() => {
              this.props.toggleCancelListingTypeChange();
              toast.success("Listing type change successfully canceled.", {
                position: toast.POSITION.TOP_RIGHT,
              });

              this.props.secondaryRef.refreshTable();
              this.props.getListingInfo(listingData?.id);
            });
        })
        .catch((err) => {
          console.log("ERROR:", err);
          toast.error("Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    });
  };

  render() {
    const { isOpen, title, closeModal, onClose } = this.props;
    const inProgress = this.state.cancelUpgradeDowngradeInProgress;

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-payment-modal"}
        centered={true}
        style={{ maxWidth: "716px" }}
        footerCTA={() => {
          return (
            <div className="confirm-payment-modal-footer">
              <span className="mr-1">
                <Button
                  onClick={() => {
                    closeModal();
                  }}
                  disabled={inProgress}
                  color="secondary"
                >
                  Cancel
                </Button>
              </span>
              <Button
                color="primary"
                form="void-reason-form"
                type="submit"
                disabled={inProgress}
              >
                {inProgress ? (
                  <Row>
                    <Col>
                      <Spinner size="sm" color="white" />
                    </Col>
                  </Row>
                ) : (
                  "Confirm"
                )}
              </Button>
            </div>
          );
        }}
      >
        <Formik
          initialValues={this.state.initialFormData}
          validationSchema={this.formSchema}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={this.handleSubmit}
        >
          {() => {
            return (
              <Form id="void-reason-form">
                <div className="payment-modal-body void-reason-body">
                  <span className="payment-modal-body-wrapper">
                    <span className="payment-modal-title border-bottom-none">
                      <div className="d-flex">
                        <Icon size={44} color={"#fff"} name={"CardRemove"} />
                        <div className="d-flex flex-column ml-1">
                          <div>
                            <h3 className="mb-1">
                              Cancel Listing Upgrade/Downgrade
                            </h3>
                            {this.state.inProgress && (
                              <Spinner size="sm" className="ml-1" />
                            )}
                          </div>
                          <div
                            className="d-flex flex-column mb-1"
                            style={{
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#424C54",
                            }}
                          >
                            <span>
                              Are you sure you want to cancel this process?
                            </span>
                            <span>
                              Changes you made won’t be saved and invoice will
                              be voided.
                            </span>
                          </div>
                        </div>
                      </div>

                      <CloseButton
                        disabled={inProgress}
                        onClick={() => {
                          if (inProgress) return;
                          closeModal();
                        }}
                      />
                    </span>

                    <Row className="d-flex flex-column align-items-end">
                      <Col lg="11">
                        <p className="void-reason-title">
                          Reason for canceling the upgrade and voiding the
                          invoice:
                        </p>
                        <FormikInput
                          type="textarea"
                          required
                          name="voidReason"
                          placeholder="Provide your reason here"
                          rows={3}
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </span>
                </div>
              </Form>
            );
          }}
        </Formik>
      </FTSModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values.loggedInUser,
    activitiesTypes: state.essentialLists.activitiesTypes,
    user: state.auth.login.values,
  };
};

const mapDispatchToProps = {
  createActivity,
  cancelRequestUpdate,
  updateInvoice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelListingUpdateModal);
