import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Spinner } from "reactstrap";

import Popover from "../../Popover";
import TitleRow from "../../TitleRow";
import FileVersions from "./file-versions";

import axios from "axios";
import { readPsd } from "ag-psd";
import FTSAxios from "../../../../axios/fts.instance";

const FilePopover = ({
  data,
  onClosePopover,
  className,
  accountCompany,
  onTitleClick,
  isListing,
  ...rest
}) => {
  const [previewURL, setPreviewURL] = useState(data?.path ?? "");
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [imageResolution, setImageResolution] = useState(null);
  const previewFileRef = useRef(null);
  const previewImageRef = useRef(null);

  function onChangePreview(version) {
    setPreviewURL(version?.path);
  }

  useEffect(() => {
    // previewFileRef.current.src = "";
    if (previewFileRef.current) {
      previewFileRef.current.style.display = "none";
    }

    if (data.type === "psd") {
      setLoading(true);
      axios
        .get(previewURL, {
          responseType: "arraybuffer",
        })
        .then(({ data }) => {
          let psd = readPsd(data);
          let img = psd.canvas.toDataURL("image/png");
          previewFileRef.current.src = img;
          previewFileRef.current.style.display = "block";

          setLoading(false);
        })
        .catch((e) => {
          console.log("ERROR: ", e);
          setLoading(false);
        });
    }
  }, [data, previewURL]);

  const previewFileName = decodeURIComponent(
    previewURL.toString().split("/").pop(),
  );

  const previewFileFormat = previewFileName.split(".").pop();

  const downloadFile = (data) => {
    setIsDownloading(true);
    FTSAxios.get("admin/get/download-url", {
      params: { downloadPath: data.path },
    }).then(({ data: resData }) => {
      window.open(resData.downloadUrl, "_target");
      setIsDownloading(false);
    });
  };

  return (
    <Popover
      {...rest}
      close={onClosePopover}
      className={`file-popover ${className ?? ""}`}
      header={
        <TitleRow
          onTitleClick={isListing ? onTitleClick : null}
          title={accountCompany ?? "About file"}
        />
      }
    >
      <Row>
        <Col
          xs="6"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div className="file-versions-wrapper">
            <FileVersions
              versions={data?.versions}
              previewURL={previewURL}
              onChangePreview={onChangePreview}
            />
          </div>
          <Row>
            <Col xs="6">
              <Button
                onClick={() => downloadFile(data)}
                color="secondary"
                type="button"
                style={{ width: "100%" }}
                className="preview-listing-btn account-listing-header-btn"
              >
                {isDownloading ? (
                  <Spinner size="sm" className="float-right" />
                ) : (
                  <>
                    <span className="preview-listing-btn-label mr-1">
                      Download
                    </span>
                  </>
                )}
              </Button>
            </Col>
            <Col xs="6">
              <Button
                color="secondary"
                type="button"
                style={{ width: "100%" }}
                className="preview-listing-btn account-listing-header-btn p-0"
                onClick={() => window.open(previewURL, "_blank")}
              >
                <span style={{ color: "#00b5f4" }}>Preview</span>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs="6">
          <Row>
            <Col className="file-preview-column">
              {previewFileFormat === "pdf" ? (
                <embed
                  src={previewURL}
                  style={{ width: "100%", height: "45vh" }}
                />
              ) : previewFileFormat === "psd" ? (
                <span className="file-resolution-box">
                  <a
                    href={previewURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {loading && (
                      <div className={"spinner-wrapper"}>
                        <Spinner color="primary" size={"lg"} />
                      </div>
                    )}
                    <span>
                      <img
                        // style={{
                        //   maxWidth: 295,
                        //   maxHeight: 245,
                        // }}
                        alt={previewFileName ?? "File preview"}
                        className="file-preview"
                        ref={(e) => (previewFileRef.current = e)}
                        onLoad={() => {
                          setImageHeight(previewFileRef.current?.naturalHeight);
                          setImageWidth(previewFileRef.current?.naturalWidth);
                          setImageResolution(
                            `${previewFileRef.current?.naturalWidth}x${previewFileRef.current?.naturalHeight}`,
                          );
                        }}
                      />
                    </span>
                  </a>
                  <span className="image-resolution-text">
                    {imageResolution}
                    {imageHeight > 400 || imageWidth > 400 ? (
                      <span className="large-image"> (large image)</span>
                    ) : null}
                  </span>
                </span>
              ) : (
                <span className="file-resolution-box">
                  <a
                    href={previewURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // style={{
                      //   maxWidth: 295,
                      //   maxHeight: 245,
                      // }}
                      className="file-preview"
                      alt={previewFileName ?? "File preview"}
                      src={previewURL}
                      ref={(e) => (previewImageRef.current = e)}
                      onLoad={() => {
                        setImageHeight(previewImageRef.current?.naturalHeight);
                        setImageWidth(previewImageRef.current?.naturalWidth);
                        setImageResolution(
                          `${previewImageRef.current?.naturalWidth}x${previewImageRef.current?.naturalHeight}`,
                        );
                      }}
                    />
                  </a>
                  <span className="image-resolution-text">
                    {imageResolution}
                    {imageHeight > 400 || imageWidth > 400 ? (
                      <span className="large-image"> (large image)</span>
                    ) : null}
                  </span>
                </span>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Popover>
  );
};

export default FilePopover;
