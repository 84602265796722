import styled from "styled-components";
import { defaultStyleProps } from "../default";
import { device } from "@find-truck-service/types/ui/breakpoints";
export const FtsColumn = styled.div `
  grid-column: span ${(props) => props.size.lg || props.size.md || props.size.sm || props.size.xs || 1};

  ${(props) => {
    let res = ``;
    for (let [key, val] of Object.entries(device)) {
        const sizeValue = props.size[key];
        if (sizeValue === 0) {
            res += `@media ${val} { display: none; };`;
        }
        if (sizeValue !== undefined) {
            let offset = "span";
            let size = sizeValue;
            let gridColumn = `${offset} ${size}`;
            const offsetValue = props.offset ? props.offset[key] : undefined;
            if (offsetValue !== undefined) {
                offset = offsetValue + 1;
                size = offset + sizeValue;
                gridColumn = `${offset} / ${size}`;
            }
            res += `@media ${val} { grid-column: ${gridColumn};};`;
        }
    }
    return res;
}}
  ${defaultStyleProps};
`;
