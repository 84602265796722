import React from "react";

import { connect } from "react-redux";
import "../../../assets/scss/components/fts/extend-validity-menu.scss";
import { Button, Spinner } from "reactstrap";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import FTSModal from "../fts-modal";
import {
  updateListingReport,
  updateUserReport,
} from "../../../redux/actions/reports";
import { toast } from "react-toastify";
import { activities } from "../../../constants";
import { createActivity } from "../../../redux/actions/activities";

class ExtendValidityMenu extends React.Component {
  state = {
    newDate: null,
    collected: {
      expirationDate: null,
    },
    inProgress: false,
  };

  componentDidMount = () => {
    this.setState({
      collected: this.props.getCollectedDataForReportModal(),
    });
  };

  handleDate = (value) => {
    if (value.length)
      this.setState({
        expirationDate: value[0],
      });
  };

  handleCloseModal = () => {
    this.setState({ newDate: null });
    this.props.closeModal();
  };

  handleConfirm = () => {
    this.setState({
      inProgress: true,
    });
    const collected = this.props.getCollectedDataForReportModal();
    const payload = {
      data: {
        // THIS IS A HACK AND NEED TO BE CHANGED
        expirationDate: moment
          .utc(this.state.expirationDate)
          .add(Math.abs(new Date().getTimezoneOffset() / 60), "hours")
          .toISOString(),
      },
      reportId: collected.id,
    };
    const reportId = collected.id;
    const reportName = collected.title;

    let activityPayload = {
      adId: this.props.listingData?.id,
      userId: this.props.accountData?.id,
      status: 2,
      activityType: this.props.activitiesTypes.EXTENDED_VALIDITY,
      activity: activities.extendedValidity
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`
        )
        .replace("{{reportName}}", reportName)
        .replace("{{newDate}}", moment(this.state.expirationDate).format("ll")),
      adminUserId: this.props.userData.loggedInUser.id,
      iconName: "Report",
    };

    // .utc(this.props.actionItem.validFrom)
    //         .add(Math.abs(new Date().getTimezoneOffset() / 60), "hours")
    //         .toISOString(),

    if (this.props.listingData) {
      activityPayload["adReportId"] = reportId;
    } else {
      activityPayload["accountReportId"] = reportId;
    }

    if (this.props.listingData) {
      this.props
        .updateListingReport(payload)
        .then(() => {
          toast.success("Report successfully updated!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.onUpdate();
          this.props.createActivity(activityPayload);
          this.setState({
            inProgress: false,
          });
        })
        .catch((e) => {
          console.log("ERROR: ", e);
          toast.error("Something went wrong. ", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({
            inProgress: false,
          });
        });
    } else {
      this.props
        .updateUserReport(payload)
        .then(() => {
          toast.success("Report successfully updated!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.onUpdate();
          this.props.createActivity(activityPayload);
          this.setState({
            inProgress: false,
          });
        })
        .catch((e) => {
          console.log("ERROR: ", e);
          toast.error("Something went wrong. ", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({
            inProgress: false,
          });
        });
    }
  };

  onUpdate = () => {
    if (this.props.listingData) {
      this.props.getListingReportsTableData();
    } else {
      this.props.getAccountReportsTableData();
    }

    this.handleCloseModal();
  };

  render() {
    const { modalOpen } = this.props;
    const { inProgress } = this.state;
    const expiration = this.props.getCollectedDataForReportModal();
    const minDate = new Date(expiration?.expirationDate);

    return (
      <FTSModal
        isOpen={modalOpen}
        closeModal={this.handleCloseModal}
        title="Extend validity"
        centered
        footerCTA={() => {
          return (
            <>
              <Button onClick={this.handleCloseModal} color="tertiary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.handleConfirm();
                }}
                className="extend-validity-button"
                color="primary"
                disabled={this.state.inProgress || !this.state.expirationDate}
              >
                {!inProgress ? "Confirm" : <Spinner size="sm" />}
              </Button>
            </>
          );
        }}
      >
        <div className="extend-validity-menu">
          <h2>EXPIRES ON</h2>
          <div className="extend-validity-date">
            {expiration?.expirationDate &&
              moment(expiration.expirationDate).format("ll")}
          </div>

          <div className="range-picker">
            <div className="range">
              <div className="position-relative has-icon-right">
                <div className="fts-date-and-time">
                  <div
                    id="datepickerWrapperFrom"
                    className="u-datepicker input-group"
                  >
                    <Flatpickr
                      placeholder="Choose new validity date"
                      className="fts-date-picker form-control date-picker"
                      options={{
                        minDate: minDate,
                        dateFormat: "M d, Y",
                      }}
                      onChange={(values) => {
                        this.handleDate(values);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FTSModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

const mapDispatchToProps = {
  updateListingReport,
  updateUserReport,
  createActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtendValidityMenu);
