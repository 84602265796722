/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Col, Row, Spinner } from "reactstrap";
import NewReply from "./NewReply";

import Reply from "./Reply";

const Replies = ({
  data,
  reverseOrder,
  withReplyForm,
  onSubmitReply,
  noteInProgress,
  long,
  hideHR,
  updateTodo,
  setReplies,
  handleTodoNoteReplyRemoved,
  openNoteInfo,
}) => {
  if (noteInProgress) {
    return (
      <Row>
        <Col className="text-center mt-1">
          <Spinner size="lg" color="primary" />
        </Col>
      </Row>
    );
  }

  if (data?.length === 0) {
    if (withReplyForm) {
      return (
        <>
          This note doesn't have any replies yet.
          <NewReply
            onSubmitReply={onSubmitReply}
            inProgress={noteInProgress}
            alwaysActive
            hideCancelBtn
          />
        </>
      );
    }
    return null;
  }

  const replies = data
    ?.filter(({ adminOwner, owner }) => !!(adminOwner ?? owner))
    .sort(function (a, b) {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });

  if (replies?.length === 0) {
    if (withReplyForm) {
      return (
        <>
          This note doesn't have any replies yet.
          <NewReply
            onSubmitReply={onSubmitReply}
            inProgress={noteInProgress}
            alwaysActive
            hideCancelBtn
          />
        </>
      );
    }
    return null;
  }

  return (
    <React.Fragment>
      {!reverseOrder && !hideHR && <hr />}
      <div className="fts-note-replies-wrapper">
        {replies?.map((reply, index) => (
          <React.Fragment key={reply.id}>
            <Reply
              openNoteInfo={openNoteInfo}
              reply={reply}
              long={long}
              hasHR={!hideHR && index < replies.length - 1}
              updateTodo={updateTodo}
              setReplies={setReplies}
              handleTodoNoteReplyRemoved={handleTodoNoteReplyRemoved}
            />
          </React.Fragment>
        ))}
      </div>
      {withReplyForm && (
        <>
          {replies?.length && <hr className="extends-into-padding" />}
          <NewReply
            onSubmitReply={onSubmitReply}
            inProgress={noteInProgress}
            alwaysActive
            hideCancelBtn
          />
        </>
      )}
      {reverseOrder && !withReplyForm && !hideHR && <hr />}
    </React.Fragment>
  );
};

export default Replies;
