/* eslint-disable indent */
const initialState = {
  upgradesUnReadCount: null,
};

const UpgradesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_UPGRADES_UNREAD_COUNTER":
      return {
        ...state,
        upgradesUnReadCount: action.payload || 0,
      };

    default:
      return state;
  }
};

export default UpgradesReducer;
