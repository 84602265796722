import FTSAxios from "../../../axios/fts.instance";

export const createNotification = (payload) => {
  return () => {
    return FTSAxios.post("admin/notifications", payload);
  };
};

export const getNotifications = (params) => {
  return () => {
    return FTSAxios.get("admin/notifications", { params });
  };
};

export const getNotificationsCount = () => {
  return () => {
    return FTSAxios.get("admin/notifications/count");
  };
};

export const markAsRead = (id) => {
  return () => {
    return FTSAxios.get(`admin/notifications/read/${id}`);
  };
};

export const setNotificationUnreadCount = (payload) => {
  return (dispatch) => {
    return dispatch({ type: "SET_NOTIFICATIONS_UNREAD_COUNTER", payload });
  };
};

export const setNotificationsData = (payload) => {
  return (dispatch) => {
    return dispatch({ type: "SET_NOTIFICATIONS", payload });
  };
};
