/* eslint-disable indent */
import React, { Component } from "react";
import { Label, FormGroup, Button } from "reactstrap";
import { X } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

const formSchema = Yup.object().shape({
  label: Yup.string().required("Required"),
});

class PermissionsSidebar extends Component {
  addNew = false;

  initialData = {
    id: "",
    label: "",
  };

  componentDidUpdate() {
    this.addNew = false;
  }

  handleSubmit = this.props.onUserTypeUpdate;

  render() {
    const { show, handleSidebar, data } = this.props;
    const initValues = data ? { ...data } : this.initialData;

    return (
      <div
        className={classnames("data-list-sidebar", {
          show: show,
        })}
      >
        <div className="data-list-sidebar-header px-2 d-flex justify-content-between">
          <h4>
            {data !== null ? "Update permission" : "Submit new permission"}
          </h4>
          <X size={20} onClick={() => handleSidebar(false, true)} />
        </div>
        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          validationSchema={formSchema}
          initialValues={initValues}
        >
          {({ errors, touched }) => (
            <Form style={{ height: "100%" }}>
              <PerfectScrollbar
                className="data-list-fields px-2 mt-3"
                options={{ wheelPropagation: false }}
              >
                <FormGroup>
                  <Label for="cat-id">ID</Label>
                  <Field
                    disabled
                    placeholder="eg. New Customer"
                    name="id"
                    className="form-control"
                    id="cat-id"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-label">Label</Label>
                  <Field
                    name="label"
                    placeholder="eg. Fleet"
                    id="cat-label"
                    className={`form-control ${
                      errors.label && touched.label && "is-invalid"
                    }`}
                  />
                </FormGroup>

                {initValues &&
                  initValues.subCat &&
                  initValues.subCat.length > 0 && (
                    <div>
                      <h5>Sub-permissions</h5>
                      <FormGroup>
                        {initValues.subCat.map((itm) => (
                          <div key={itm.id}>
                            <Label>
                              ID:{itm.id}; {itm.label}
                            </Label>
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                  )}
              </PerfectScrollbar>

              <div className="data-list-sidebar-footer px-2 d-flex justify-content-end align-items-center mt-1">
                {/* <Button color="primary" type="submit">
                  {data !== null ? "Update" : "Submit"}
                </Button> */}
                <Button
                  type="button"
                  color="danger"
                  outline
                  onClick={() => handleSidebar(false, true)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default PermissionsSidebar;
