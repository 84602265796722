import { createSelector } from "reselect";

//API
export const GET_ACCOUNT_DATA_BY_ID_REQUEST =
  "ACCOUNT/GET_ACCOUNT_DATA_BY_ID_REQUEST";
export const GET_ACCOUNT_DATA_BY_ID_SUCCESS =
  "ACCOUNT/GET_ACCOUNT_DATA_BY_ID_SUCCESS";
export const GET_ACCOUNT_DATA_BY_ID_FAILED =
  "ACCOUNT/GET_ACCOUNT_DATA_BY_ID_FAILED";

export const UPDATE_ACCOUNT_DATA_BY_ID_REQUEST =
  "ACCOUNT/UPDATE_ACCOUNT_DATA_BY_ID_REQUEST";
export const UPDATE_ACCOUNT_DATA_BY_ID_SUCCESS =
  "ACCOUNT/UPDATE_ACCOUNT_DATA_BY_ID_SUCCESS";
export const UPDATE_ACCOUNT_DATA_BY_ID_FAILED =
  "ACCOUNT/UPDATE_ACCOUNT_DATA_BY_ID_FAILED";

//STORE

export const SET_ACCOUNT_DATA_INVALID_FORM_FIELDS =
  "ACCOUNT/SET_ACCOUNT_DATA_INVALID_FORM_FIELDS";
export const SET_ACCOUNT_DATA = "ACCOUNT/SET_ACCOUNT_DATA";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  invalidFormFields: null,
};

/// Reducer
export default function (state = initialState, action) {
  switch (action.type) {
    //API
    case GET_ACCOUNT_DATA_BY_ID_REQUEST:
    case UPDATE_ACCOUNT_DATA_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        invalidFormFields: null,
      };

    case GET_ACCOUNT_DATA_BY_ID_SUCCESS:
    case UPDATE_ACCOUNT_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
        invalidFormFields: null,
      };

    case GET_ACCOUNT_DATA_BY_ID_FAILED:
    case UPDATE_ACCOUNT_DATA_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        invalidFormFields: null,
      };

    //STORE

    case SET_ACCOUNT_DATA_INVALID_FORM_FIELDS:
      return { ...state, invalidFormFields: action.data };

    case SET_ACCOUNT_DATA:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
        invalidFormFields: null,
      };

    default:
      return state;
  }
}

/// Selectors
const selectAccountState = (state) => state.accountState;

export const selectAccountData = createSelector(
  selectAccountState,
  (account) => {
    return account.data;
  },
);

export const selectAccountDataError = createSelector(
  selectAccountState,
  (account) => {
    return account.error;
  },
);

export const selectIsLoadingAccountData = createSelector(
  selectAccountState,
  (account) => {
    return account.isLoading;
  },
);

export const selectAccountInvalidFormFields = createSelector(
  selectAccountState,
  (account) => {
    return account.invalidFormFields;
  },
);

export const selectAccountCategories = createSelector(
  selectAccountState,
  (account) => {
    return account.data.userCategories;
  },
);

export const selectAccountSubCategories = createSelector(
  selectAccountState,
  (account) => {
    return account.data.userSubCategories;
  },
);
