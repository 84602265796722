import React from "react";
const facebookAuth = (props) => {
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props.svgProps },
        React.createElement("g", { "clip-path": "url(#clip0_597_26423)" },
            React.createElement("path", { d: "M12 0C8.8174 0 5.76515 1.26428 3.51472 3.51472C1.26428 5.76515 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76515 22.7357 8.8174 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0Z", fill: "#039BE5", ...props.pathProps }),
            React.createElement("path", { d: "M13.6243 15.18H16.7297L17.2173 12.0252H13.6236V10.301C13.6236 8.99048 14.0518 7.82837 15.2777 7.82837H17.2476V5.07532C16.9015 5.02859 16.1695 4.92627 14.7864 4.92627C11.8982 4.92627 10.2049 6.45153 10.2049 9.92648V12.0252H7.23584V15.18H10.2049V23.8509C10.7929 23.9393 11.3885 23.9993 11.9998 23.9993C12.5525 23.9993 13.0918 23.9488 13.6243 23.8768V15.18Z", fill: "white", ...props.pathProps })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_597_26423" },
                React.createElement("rect", { width: "24", height: "24", fill: "white", ...props.pathProps })))));
};
export default facebookAuth;
