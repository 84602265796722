import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { fetchStickyNotesAccount } from "../../../redux/actions/notes";
import StickyNote from "../../../components/fts/sticky-note";
import { setUserNotesStickyList } from "../../../redux/actions/user-notes";

class AccountStickyNotes extends PureComponent {
  componentDidMount() {
    const { accountId } = this.props;
    this.getStickyNotes(accountId);
  }

  getStickyNotes = (id) => {
    if (!id) return;
    this.props
      .fetchStickyNotesAccount(id)
      .then(({ data }) => {
        this.props.setUserNotesStickyList(data);
      })
      .catch((err) => {
        console.log("err = ", err);
      });
  };

  render() {
    const { callToAction, list } = this.props;
    const stickyNotesList = list.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return stickyNotesList.map((itm) => (
      <StickyNote key={itm?.id} item={itm} callToAction={callToAction} />
    ));
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.accountNotes.stickyList,
  };
};

export default connect(mapStateToProps, {
  fetchStickyNotesAccount,
  setUserNotesStickyList,
})(AccountStickyNotes);
