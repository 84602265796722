import React from "react";
import "../../../assets/scss/components/fts/todos.scss";
import Icon from "../Icons";
import { Col, Spinner } from "reactstrap";

const TodoEmpty = ({ loading }) => {
  return (
    <div className="fts-todo-empty-search">
      {loading ? (
        <Col sm="12" className="text-center">
          <Spinner />
        </Col>
      ) : (
        <div>
          <Icon
            name="EmptySearch"
            style={{ marginBottom: "1rem" }}
            size={182}
          />
          <p className="empty-text">No results found.</p>
          <p className="empty-small-text">
            We can’t find any item matching your search.
          </p>
        </div>
      )}
    </div>
  );
};

export default TodoEmpty;
