import React from "react";
import { DropdownMenu, DropdownItem } from "reactstrap";
import * as Icon from "react-feather";

import {
  logoutWithJWT,
  logoutWithFirebase,
} from "../../../redux/actions/auth/loginActions";

import { history } from "../../../history";
import { connect } from "react-redux";

const UserDropdown = (props) => {
  const onLogout = (e) => {
    e.preventDefault();

    const provider = props.userFullname.login.values.loggedInWith;
    if (provider !== null) {
      if (provider === "jwt") {
        return props.logoutWithJWT();
      }
      if (provider === "firebase") {
        return props.logoutWithFirebase();
      }
    } else {
      history.push("/login");
    }
  };

  const onClearStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <DropdownMenu className="user-info-dropdown">
      <DropdownItem tag="a" href="/login" onClick={onLogout}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
      <DropdownItem tag="a" onClick={onClearStorage}>
        <span className="align-middle">Reset columns</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

export default connect(null, { logoutWithFirebase, logoutWithJWT })(
  UserDropdown,
);
