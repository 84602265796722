const hoursMap = {
  "1 am": "1 AM",
  "2 am": "2 AM",
  "3 am": "3 AM",
  "4 am": "4 AM",
  "5 am": "5 AM",
  "6 am": "6 AM",
  "7 am": "7 AM",
  "8 am": "8 AM",
  "9 am": "9 AM",
  "10 am": "10 AM",
  "11 am": "11 AM",
  "12 am": "12 AM",
  "12 am (midnight)": "12 AM (Midnight)",
  "1 pm": "1 PM",
  "2 pm": "2 PM",
  "3 pm": "3 PM",
  "4 pm": "4 PM",
  "5 pm": "5 PM",
  "6 pm": "6 PM",
  "7 pm": "7 PM",
  "8 pm": "8 PM",
  "9 pm": "9 PM",
  "10 pm": "10 PM",
  "11 pm": "11 PM",
  "12 pm": "12 PM",
  "12 pm (noon)": "12 PM (Noon)",

  "1:30 am": "1:30 AM",
  "2:30 am": "2:30 AM",
  "3:30 am": "3:30 AM",
  "4:30 am": "4:30 AM",
  "5:30 am": "5:30 AM",
  "6:30 am": "6:30 AM",
  "7:30 am": "7:30 AM",
  "8:30 am": "8:30 AM",
  "9:30 am": "9:30 AM",
  "10:30 am": "10:30 AM",
  "11:30 am": "11:30 AM",
  "12:30 am": "12:30 AM",
  "1:30 pm": "1:30 PM",
  "2:30 pm": "2:30 PM",
  "3:30 pm": "3:30 PM",
  "4:30 pm": "4:30 PM",
  "5:30 pm": "5:30 PM",
  "6:30 pm": "6:30 PM",
  "7:30 pm": "7:30 PM",
  "8:30 pm": "8:30 PM",
  "9:30 pm": "9:30 PM",
  "10:30 pm": "10:30 PM",
  "11:30 pm": "11:30 PM",
  "12:30 pm": "12:30 PM",

  "01 am": "1 AM",
  "02 am": "2 AM",
  "03 am": "3 AM",
  "04 am": "4 AM",
  "05 am": "5 AM",
  "06 am": "6 AM",
  "07 am": "7 AM",
  "08 am": "8 AM",
  "09 am": "9 AM",
  "01 pm": "1 PM",
  "02 pm": "2 PM",
  "03 pm": "3 PM",
  "04 pm": "4 PM",
  "05 pm": "5 PM",
  "06 pm": "6 PM",
  "07 pm": "7 PM",
  "08 pm": "8 PM",
  "09 pm": "9 PM",

  "01:30 am": "1:30 AM",
  "02:30 am": "2:30 AM",
  "03:30 am": "3:30 AM",
  "04:30 am": "4:30 AM",
  "05:30 am": "5:30 AM",
  "06:30 am": "6:30 AM",
  "07:30 am": "7:30 AM",
  "08:30 am": "8:30 AM",
  "09:30 am": "9:30 AM",
  "01:30 pm": "1:30 PM",
  "02:30 pm": "2:30 PM",
  "03:30 pm": "3:30 PM",
  "04:30 pm": "4:30 PM",
  "05:30 pm": "5:30 PM",
  "06:30 pm": "6:30 PM",
  "07:30 pm": "7:30 PM",
  "08:30 pm": "8:30 PM",
  "09:30 pm": "9:30 PM",

  "24 hours": "24 hrs",
  "24 hrs": "24 hrs",
  "by appointment": "By Appointment",
  closed: "Closed",
};

function formatHour(value) {
  value = value.toLowerCase().trim().replace(/:00/g, "");
  return hoursMap[value] ?? value;
}

export default formatHour;
