import React, { useEffect, useState } from "react";

import axios from "axios";

import html2pdf from "html2pdf.js";
import appConfig from "../../configs/appConfig";
import { Spinner } from "reactstrap";

const DownloadRecipient = (props) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(appConfig.apiURL + "admin/payment/recipient", {
        hash: props.match.params.token,
      })
      .then(({ data: res }) => {
        let data = JSON.stringify(res);
        data = formatResponse(data);
        data = JSON.parse(data);

        axios
          .get(data.downloadUrl, {
            responseType: "arraybuffer",
          })
          .then((res) => {
            setIsLoading(false);

            let wrapper = document.getElementById("new-recipient");
            if (!wrapper) return;
            let img = "";
            img = btoa(String.fromCharCode(...new Uint8Array(res.data)));

            let src = "data:image/svg+xml;base64," + img;
            wrapper.innerHTML = data.html.replace(
              `${process.env.REACT_APP_IMAGE_URL}/assets/img/FTS-logo.svg`,
              src,
            );
            html2pdf(wrapper, {
              html2canvas: { scale: 5 },
              filename: "FTS_Receipt.pdf",
              jsPDF: { unit: "px", format: [612, 791], orientation: "p" },
            });
          })
          .catch((e) => {
            console.log("ERROR: ", e);
            setError("Oops! Something went wrong. Please try again.");
          });
      })
      .catch((e) => {
        console.log("ERROR: ", e);
        setError("Oops! Something went wrong. Please try again.");
      });
  }, [props.match.params.token]);

  if (error) {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <h2>{error}</h2>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <Spinner size={"lg"} color="primary" />
      </div>
    );
  }
  return (
    <div className={"container"}>
      <div id={"new-recipient"} className={"d-flex justify-content-center"} />
    </div>
  );
};

export const formatResponse = (data) => {
  data = data.replace(/\+/g, " ").replace(/%22/g, "'").replace(/%27/g, "'");

  data = decodeURIComponent(data);
  data = data.replace(/\r\n/g, "").replace(/\n/g, "").replace(/\t/g, "");
  return data;
};
export default DownloadRecipient;
