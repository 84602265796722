import React, { Component, Suspense, lazy } from "react";
import { Spinner } from "reactstrap";

import "flatpickr/dist/themes/light.css";
import "../../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss";

import Sidebar from "../../sidebar";

const CreateTodo = lazy(() => import("./CreateTodo"));
const UpdateTodo = lazy(() => import("./UpdateTodo"));
const CreateNote = lazy(() => import("./CreateNote"));
const UpdateNote = lazy(() => import("./UpdateNote"));
const CreateListing = lazy(() => import("./CreateListing"));
const UpdateListing = lazy(() => import("./UpdateListing"));
const CreatePromotion = lazy(() => import("./CreatePromotion"));
const UpdatePromotion = lazy(() => import("./UpdatePromotion"));
const CreateReport = lazy(() => import("./CreateReport"));
const Marketing = lazy(() => import("./Marketing"));
const RequestedChange = lazy(() => import("./RequestedChange"));
const PaymentPreview = lazy(() => import("./PaymentOverview"));
const ReceiptDetails = lazy(() => import("./ReceiptDetails"));
const InvoiceDetails = lazy(() => import("./InvoiceDetails"));
const TemplatePreview = lazy(() => import("./TemplatePreview"));
const UpdateHistory = lazy(() => import("./UpdateHistory"));

const CreatePromocode = lazy(() => import("./CreatePromocode"));
const UpdatePromocode = lazy(() => import("./UpdatePromocode"));
const GenerateReport = lazy(() => import("./GenerateReport"));
const ReportPreview = lazy(() => import("./ReportPreview"));
const AdminNotifications = lazy(() => import("./AdminNotifications"));
const CreateCustomer = lazy(() =>
  import("../../../../views/pages/createCustomer/CreateCustomerSidebarView"),
);
const SendMulitpleListingsProof = lazy(() =>
  import("./SendMulitpleListingsProof"),
);
const ChangeListingType = lazy(() => import("./ChangeListingType"));
const PreviewListingDetails = lazy(() => import("./PreviewListingDetails"));
const ChangePaymentMethod = lazy(() => import("./ChangePaymentMethod"));

const PlaceholderComponent = () => <div></div>;

class ActionSidebar extends Component {
  sidebarScreens = {
    todo: {
      title: "Create new todo",
      component: CreateTodo,
      style: { overflowY: "scroll" },
    },
    admin_notifications: {
      title: "Notifications",
      component: AdminNotifications,
      style: { overflowY: "auto" },
    },
    note: {
      title: "Create new note",
      component: CreateNote,
      style: { overflowY: "hidden" },
    },
    listing: {
      title: "Create new listing",
      component: CreateListing,
      style: { width: "80rem", overflowY: "scroll" },
    },
    update_listing: {
      title: "Listing preview",
      component: UpdateListing,
      style: { width: "90%", overflowY: "scroll" },
    },
    listing_update_history: {
      title: "Listing update history",
      component: UpdateHistory,
      style: { width: "80rem", overflowY: "scroll" },
    },
    account_update_history: {
      title: "Account update history",
      component: UpdateHistory,
      style: { width: "80rem", overflowY: "scroll" },
    },
    requestedChange: {
      title: "Requested change",
      component: RequestedChange,
      style: { width: "100%", overflowY: "scroll" },
    },
    promotion: {
      title: "Create new promotion",
      component: CreatePromotion,
      style: { overflowY: "scroll" },
    },
    promotion_update: {
      title: "Promotion preview",
      component: UpdatePromotion,
      style: { width: "80rem", overflowY: "scroll" },
    },
    report: {
      title: "Create new report",
      component: CreateReport,
      style: { overflowY: "scroll" },
    },
    marketing: {
      title: "Marketing",
      component: Marketing,
      style: { overflowY: "scroll" },
    },
    todo_update: {
      title: "Update todo",
      component: UpdateTodo,
      style: { overflowY: "scroll" },
    },
    note_update: {
      title: "Update note",
      component: UpdateNote,
      style: { overflowY: "scroll" },
    },
    create_customer: {
      title: "Create new lead",
      component: CreateCustomer,
      style: { width: "85%", overflowY: "scroll" },
    },
    payment_overview: {
      title: "Payment overview",
      component: PaymentPreview,
      style: { width: "1000px", overflowY: "scroll" },
    },
    invoice_details: {
      title: "Invoice details",
      component: InvoiceDetails,
      style: { width: "850px", overflowY: "scroll" },
    },
    receipt_details: {
      title: "Receipt details",
      component: ReceiptDetails,
      style: { width: "850px", overflowY: "scroll" },
    },
    template_preview: {
      title: "Template Preview",
      component: TemplatePreview,
      style: { width: "850px", overflowY: "scroll" },
    },
    system_template_preview: {
      title: "Template Preview",
      component: TemplatePreview,
      style: { width: "850px", overflowY: "scroll" },
    },
    promocode: {
      title: "Create promo code",
      component: CreatePromocode,
      style: { width: "60%", overflowY: "scroll" },
    },
    promocode_update: {
      title: "Update promo code",
      component: UpdatePromocode,
      style: { width: "60%", overflowY: "scroll" },
    },
    generate_report: {
      title: "Generate Custom Account Report",
      component: GenerateReport,
      style: { width: "60%", overflowY: "scroll" },
    },
    report_preview: {
      title: this.props.accountReportTitle || this.props.listingReportTitle,
      component: ReportPreview,
      style: { width: "70%", overflowY: "scroll" },
    },
    send_multiple_listings_proof: {
      title: "Send multiple listing proof ",
      component: SendMulitpleListingsProof,
      style: { width: "70%", overflowY: "scroll" },
    },
    proof_update_listing: {
      title: "Review Listing details",
      component: UpdateListing,
      style: { width: "80rem", overflowY: "scroll" },
    },
    change_listing_type: {
      title: "Change Listing Type",
      component: ChangeListingType,
      style: { width: "80rem", overflowY: "scroll" },
    },
    preview_listing_details: {
      title: "Preview Listing details",
      component: PreviewListingDetails,
      style: { width: "85%", overflowY: "scroll" },
    },
    change_payment_method: {
      title: "Change recurring payment method",
      component: ChangePaymentMethod,
      style: { width: "850px", overflowY: "scroll" },
    },
  };

  getScreenData = (screen) => {
    return this.sidebarScreens[screen] ?? {};
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isPersonal !== this.props.isPersonal ||
      nextProps.action !== this.props.action ||
      nextProps.show !== this.props.show ||
      nextProps.actionItem?.todoNotes?.length !==
        this.props.actionItem?.todoNotes?.length ||
      nextProps.actionItem?.replies?.length !==
        this.props.actionItem?.replies?.length ||
      nextProps.listings?.length !== this.props.listings?.length ||
      JSON.stringify(nextProps.accountInfo) !==
        JSON.stringify(this.props.accountInfo) ||
      JSON.stringify(nextProps.listingData) !==
        JSON.stringify(this.props.listingData) ||
      nextProps.accountReportTitle !== this.props.accountReportTitle ||
      nextProps.listingReportTitle !== this.props.listingReportTitle ||
      nextProps.forceShowAssociate !== this.props.forceShowAssociate
    );
  }

  getPersonalMessage() {
    if (!this.props.updating) {
      return "Create new personal todo";
    }
  }

  render() {
    const {
      action,
      actionItem,
      adminData,
      accountInfo,
      listingData,
      onPrefillListingData,
      handleSidebar,
      show,
      updating,
      setEditMode,
      isPersonal,
      forceShowAssociate,
      taskManagement,
      getAccountInfo,
      getListingInfo,
      onNotesListUpdate,
      onTodoListUpdate,
      filesTableGroupRef,
      getSystemTemplates,
      setFormikSidebarRef,
      resetFormikForm,
      listings,
      showAccountBox,
      callToAction,
      promotionType,
      listingName,
      listingId,
      noMarker,
      tableGroupRef,
      onPromotionCreate,
      fetchTodos,
      setPromotionsCount,
      onSubmitQuery,
      setPaymentStep,
      setDetailsRef,
      setActivityPreloadDate,
      activityPreloadDate,
      getSelectedInvoices,
      scrollToSelectedInvoice,
      reportAccountId,
      reportListingId,
      refetchReports,
      accountReportTitle,
      listingReportTitle,
      handleReviewListingData,
      onSidebarClosed,
      ...rest
    } = this.props;

    let isPersonalTodo;

    if (action === "todo") {
      if (isPersonal && !updating) {
        isPersonalTodo = true;
      }
    }

    const screen = `${action}${updating ? "_update" : ""}`;

    const Component =
      this.getScreenData(screen).component || PlaceholderComponent;

    const accountID = accountInfo?.id ?? listingData?.userId;

    const componentProps = {
      action,
      actionItem,
      handleSidebar,
      accountID,
      accountInfo,
      listingData,
      adminData,
      updating,
      setEditMode,
      isPersonal,
      forceShowAssociate,
      taskManagement,
      getAccountInfo,
      getListingInfo,
      promotionType,
      listingName,
      listingId,
      onNotesListUpdate,
      fetchTodos,
      callToAction,
      onTodoListUpdate,
      listings,
      onPrefillListingData,
      filesTableGroupRef,
      setFormikSidebarRef,
      getSystemTemplates,
      resetFormikForm,
      onSubmitQuery,
      showAccountBox,
      noMarker,
      tableGroupRef,
      onPromotionCreate,
      setPromotionsCount,
      setPaymentStep,
      setDetailsRef,
      setActivityPreloadDate,
      activityPreloadDate,
      getSelectedInvoices,
      scrollToSelectedInvoice,
      reportAccountId,
      reportListingId,
      refetchReports,
      accountReportTitle,
      listingReportTitle,
      handleReviewListingData,
      onSidebarClosed,
    };

    return (
      <Sidebar
        action={action}
        show={show && action !== ""}
        style={this.getScreenData(screen).style}
        handleSidebar={handleSidebar}
        sidebarData={accountInfo}
        headerTitle={
          isPersonalTodo
            ? this.getPersonalMessage()
            : this.getScreenData(screen).title
        }
        showAccountBox={showAccountBox}
        noMarker={noMarker}
        accountInfo={accountInfo || actionItem?.owner}
        actionItem={actionItem}
        onPromotionCreate={this.onPromotionCreate}
        setPromotionsCount={setPromotionsCount}
        onSubmitQuery={onSubmitQuery}
        onSidebarClosed={onSidebarClosed}
        {...rest}
      >
        <Suspense
          fallback={
            <div className="text-center mt-1">
              <Spinner size="sm" />
            </div>
          }
        >
          <Component {...componentProps} {...rest} />
        </Suspense>
      </Sidebar>
    );
  }
}

export default ActionSidebar;
