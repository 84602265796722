import React, { Component } from "react";
import { Label, FormGroup, Button, CustomInput } from "reactstrap";
import { X } from "react-feather";
import classnames from "classnames";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Toggle from "react-toggle";
import {
  getAdminInfo,
  sendResetPasswordAdminUser,
} from "../../../redux/actions/adminUsers";
import "react-toggle/style.css";
import Spinner from "../../../components/@vuexy/spinner/Loading-spinner";
import "../../../assets/scss/plugins/forms/switch/react-toggle.scss";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const editSchema = {
  firstName: Yup.string().trim().required("Required"),
  lastName: Yup.string().trim().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
};
const initSchema = {
  ...editSchema,
  password: Yup.string().required("Required"),
};
const defaultData = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  image: "",
  createdAt: "",
  updatedAt: "",
  isTestMode: false,
  status: false,
};
const defaultAdminInfo = {
  permissions: [
    { id: 1, active: true, subCat: [] },
    { id: 2, active: true, subCat: [] },
    { id: 3, active: true, subCat: [1, 2, 3, 4] },
    { id: 4, active: true, subCat: [5, 6] },
    { id: 5, active: true, subCat: [] },
    { id: 6, active: true, subCat: [] },
    { id: 7, active: true, subCat: [] },
    { id: 8, active: true, subCat: [] },
    { id: 9, active: true, subCat: [7, 8, 9, 10, 11] },
    { id: 10, active: true, subCat: [12, 13, 14, 15] },
    { id: 11, active: true, subCat: [16, 17, 18] },
    {
      id: 12,
      active: true,
      subCat: [
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
        37, 38,
      ],
    },
  ],
};

class AdminUsersSidebar extends Component {
  state = {
    inProgress: false,
    currentAdminInfo: defaultAdminInfo,
  };

  initialData = defaultData;
  formSchema = Yup.object().shape(initSchema);

  handleSubmit = (values) => {
    const payload = {
      ...values,
      isTestMode: values.isTestMode ? 1 : 0,
      status: values.status ? 1 : 0,
      permissions: this.state.currentAdminInfo.permissions,
    };
    this.props.updateAdmin(payload);
  };

  fetchInfoData = (userId) => {
    if (this.state.inProgress) {
      return;
    }

    this.setState({ inProgress: true });

    this.props
      .getAdminInfo(userId)
      .then(({ data }) => {
        this.setState({ currentAdminInfo: data, inProgress: false });
      })
      .catch((error) => {
        this.setState({ inProgress: false });
        console.log("error = ", error);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.addNew === "" || prevProps.addNew === true) &&
      !prevProps.data &&
      !!this.props.data &&
      !prevState.inProgress &&
      !prevState.currentAdminInfo.id
    ) {
      this.initialData = {
        id: this.props.data.id,
        firstName: this.props.data.firstName,
        lastName: this.props.data.lastName,
        email: this.props.data.email,
        phoneNumber: this.props.data.phoneNumber || "",
        password: "",
        image: this.props.data.image,
        createdAt: this.props.data.createdAt,
        updatedAt: this.props.data.updatedAt,
        status: this.props.data.status ? true : false,
        isTestMode: this.props.data.isTestMode || false,
        calendarEmails: this.props.data.calendarEmails || false,
      };

      this.formSchema = Yup.object().shape(editSchema);
      this.fetchInfoData(this.props.data.id);
    }
    if (
      !prevProps.addNew &&
      prevProps.data !== null &&
      this.props.data === null
    ) {
      this.setState({ currentAdminInfo: defaultAdminInfo });
      this.formSchema = Yup.object().shape(initSchema);
      this.initialData = defaultData;
    }
  }

  handleOnTopPermissionChange = (e, index) => {
    const {
      target: { checked },
    } = e;
    const currentAdminUpdateInfo = this.state.currentAdminInfo;
    currentAdminUpdateInfo.permissions[index].active = checked;
    this.setState({ currentAdminInfo: currentAdminUpdateInfo });
  };

  handleOnSubPermissionChange = (e, index, id) => {
    const {
      target: { checked },
    } = e;
    const currentAdminUpdateInfo = this.state.currentAdminInfo;
    const permissions = currentAdminUpdateInfo.permissions[index].subCat;
    let updatedPermissions;

    if (checked) {
      updatedPermissions = permissions;
      updatedPermissions.push(id);
    } else {
      updatedPermissions = permissions.filter((itm) => itm !== id);
    }

    currentAdminUpdateInfo.permissions[index].subCat = updatedPermissions;
    this.setState({ currentAdminInfo: currentAdminUpdateInfo });
  };
  sendResetPassword = async () => {
    try {
      const { data, userData } = this.props;
      const { email, firstName, lastName, id } = data;
      const sendBy = `${userData.firstName} ${userData.lastName}`;

      await this.props.sendResetPasswordAdminUser({
        email,
        firstName,
        lastName,
        id,
        sendBy,
      });
      toast.success(`Reset password email sent to ${email}`);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  };
  render() {
    const { show, handleSidebar, addNew, permissionsList } = this.props;
    const { currentAdminInfo, inProgress } = this.state;

    return (
      <div
        className={classnames("data-list-sidebar", {
          show: show,
        })}
      >
        <div className="data-list-sidebar-header px-2 py-1 d-flex justify-content-between align-items-center">
          <h4>User Details</h4>
          <div className="d-flex justify-content-center align-items-center">
            <Button onClick={this.sendResetPassword} className={"mr-1"}>
              Send Reset Password Email
            </Button>
            <X size={20} onClick={() => handleSidebar(false, true)} />
          </div>
        </div>

        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          validationSchema={this.formSchema}
          initialValues={this.initialData}
        >
          {({ errors, touched }) => (
            <Form style={{ height: "100%" }}>
              <div
                className="data-list-fields px-2 mt-3"
                style={{ overflow: "auto" }}
              >
                <FormGroup>
                  <Field>
                    {({ field: { onChange, value } }) => (
                      <>
                        <label className="react-toggle-wrapper d-inline-block align-middle">
                          <Toggle
                            {...{ onChange }}
                            name="status"
                            checked={value.status}
                          />
                        </label>
                        <span className="label-text align-middle">
                          Status: {value.status ? "Enabled" : "Disabled"}
                        </span>
                      </>
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field>
                    {({ field: { onChange, value } }) => (
                      <>
                        <label className="react-toggle-wrapper d-inline-block align-middle">
                          <Toggle
                            {...{ onChange }}
                            name="isTestMode"
                            checked={value.isTestMode}
                          />
                        </label>
                        <span className="label-text align-middle">
                          Test Mode: {value.isTestMode ? "Enabled" : "Disabled"}
                        </span>
                      </>
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Label for="id">ID</Label>
                  <Field
                    disabled
                    placeholder=""
                    name="id"
                    className="form-control"
                    id="id"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Field
                    placeholder="eg. John"
                    name="firstName"
                    className={`form-control ${
                      errors.firstName && touched.firstName && "is-invalid"
                    }`}
                    id="firstName"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Field
                    placeholder="eg. Doe"
                    name="lastName"
                    className={`form-control ${
                      errors.lastName && touched.lastName && "is-invalid"
                    }`}
                    id="lastName"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Field
                    type="password"
                    placeholder="User's password"
                    name="password"
                    className={`form-control ${
                      errors.password && touched.password && "is-invalid"
                    }`}
                    id="password"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Field
                    type="email"
                    placeholder="eg. john@doe.com"
                    name="email"
                    className={`form-control ${
                      errors.email && touched.email && "is-invalid"
                    }`}
                    id="email"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="calendarEmails">Calendar Email</Label>
                  <Field
                    type="email"
                    placeholder="eg. john@doe.com"
                    name="calendarEmails"
                    className={`form-control ${
                      errors.calendarEmails &&
                      touched.calendarEmails &&
                      "is-invalid"
                    }`}
                    id="calendarEmails"
                  />
                </FormGroup>
                <FormGroup>
                  {/* <Label for="phoneNumber">Phone Number</Label>
                  <Field
                    placeholder="eg. +15854380257"
                    name="phoneNumber"
                    className="form-control"
                    id="phoneNumber"
                  /> */}
                </FormGroup>

                {inProgress ? (
                  <div
                    className="spinnerHolder"
                    style={{ position: "relative", height: "200px" }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="data-list-sidebar-header mb-2 d-flex">
                      <h4>User Permissions</h4>
                    </div>
                    {permissionsList.length > 0 &&
                      !!currentAdminInfo &&
                      permissionsList.map((permission, index) => (
                        <FormGroup key={permission.id}>
                          <div
                            className="permission-holder"
                            style={{ border: "1px solid #ededed", padding: 10 }}
                          >
                            <strong>{permission.label}</strong>
                            {permission.subCat.length > 0 && (
                              <div className="subPermissions mt-1">
                                {permission.subCat.map((subPermission) => (
                                  <div
                                    className="subPermission"
                                    key={subPermission.id}
                                  >
                                    <CustomInput
                                      inline
                                      type="checkbox"
                                      onChange={(e) => {
                                        if (
                                          currentAdminInfo.email ===
                                          "admin@fts.com"
                                        )
                                          return;
                                        this.handleOnSubPermissionChange(
                                          e,
                                          index,
                                          subPermission.id,
                                        );
                                      }}
                                      checked={
                                        currentAdminInfo.permissions[
                                          index
                                        ].subCat.indexOf(subPermission.id) !==
                                        -1
                                      }
                                      id={`permission_${permission.id}_subPermission_${subPermission.id}`}
                                      label={subPermission.label}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </FormGroup>
                      ))}
                  </>
                )}
              </div>

              <div className="px-2 py-1">
                <Button type="submit" color="primary" className="float-right">
                  {addNew ? "Create" : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    userData: state.auth.login.values.loggedInUser,
  }),
  { getAdminInfo, sendResetPasswordAdminUser },
)(AdminUsersSidebar);
