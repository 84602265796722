import { DEFAULT_TABLE_DATA } from "../../../utility/constants";
import { formatPaymentsCounters } from "../../../utility/formatPaymentsCounters";
import {
  SET_ACCOUNT_PAYMENTS,
  SET_ACCOUNT_PAYMENTS_ACTIVE_SUBTAB,
  SET_ACCOUNT_PAYMENTS_CURRENT_PAGE,
  SET_ACCOUNT_PAYMENTS_IN_PROGRESS,
  SET_ACCOUNT_PAYMENTS_LAST_VIEWED_ID,
  SET_ACCOUNT_PAYMENTS_RESULTS_PER_PAGE,
  SET_ACCOUNT_PAYMENTS_SEARCH_STRING,
  SET_ACCOUNT_PAYMENTS_SORT_MODEL,
  SET_ACCOUNT_PAYMENTS_PRESELECTED_INVOICE,
} from "../../actions/accountPayments";

const initialState = {
  lastViewedAccount: null,
  paymentsData: [],
  activeSubTab: { id: 1, label: "All", elasticKey: "" },
  inProgress: false,
  searchString: DEFAULT_TABLE_DATA.SEARCH_STRING,
  currentPage: DEFAULT_TABLE_DATA.CURRENT_PAGE,
  resultsPerPage: DEFAULT_TABLE_DATA.RESULTS_PER_PAGE,
  pageCount: DEFAULT_TABLE_DATA.PAGE_COUNT,
  sortModel: DEFAULT_TABLE_DATA.SORT_MODEL,
  subTabs: [
    {
      id: 1,
      label: "All",
      elasticKey: "",
      count: 0,
    },
    {
      id: 2,
      label: "Paid",
      elasticKey: "Paid",
      count: 0,
    },
    {
      id: 3,
      label: "Unsuccessful",
      elasticKey: "Unsuccessful",
      count: 0,
    },
  ],
};

const AccountPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_PAYMENTS:
      const pageCount = Math.ceil(
        action?.payload?.nbHits / state.resultsPerPage,
      );
      const { paidCount, unsuccessfulCount } = action.payload;

      return {
        ...state,
        paymentsData: action?.payload?.hits || [],
        inProgress: false,
        subTabs: formatPaymentsCounters({
          subTabs: state?.subTabs,
          counts: {
            paidCount,
            unsuccessfulCount,
          },
        }),
        pageCount,
      };

    case SET_ACCOUNT_PAYMENTS_ACTIVE_SUBTAB:
      return {
        ...state,
        activeSubTab: action.payload,
        currentPage: 1,
      };

    case SET_ACCOUNT_PAYMENTS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_ACCOUNT_PAYMENTS_IN_PROGRESS:
      return {
        ...state,
        inProgress: action.payload,
      };

    case SET_ACCOUNT_PAYMENTS_SORT_MODEL:
      return {
        ...state,
        sortModel: action.payload,
      };

    case SET_ACCOUNT_PAYMENTS_RESULTS_PER_PAGE:
      return {
        ...state,
        resultsPerPage: action.payload,
      };

    case SET_ACCOUNT_PAYMENTS_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      };

    case SET_ACCOUNT_PAYMENTS_LAST_VIEWED_ID:
      return {
        ...initialState,
        searchString: action.payload?.searchString,
        lastViewedAccount: action.payload?.id,
      };

    case SET_ACCOUNT_PAYMENTS_PRESELECTED_INVOICE:
      return {
        ...initialState,
        searchString: action.payload,
      };

    default:
      return state;
  }
};

export default AccountPaymentsReducer;
