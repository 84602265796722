export const mergeArrayWithoutDuplicates = ({
  firstArray = [],
  secondArray = [],
}) => {
  const map = new Map();

  [...firstArray, ...secondArray].forEach((item) => {
    map.set(item.id, item);
  });

  return Array.from(map.values());
};
