import React, { Component } from "react";
import { Label, FormGroup, Button } from "reactstrap";
import { X } from "react-feather";
import classnames from "classnames";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { getApprovedIPInfo } from "../../../redux/actions/approvedIPs";
import "react-toggle/style.css";
import Spinner from "../../../components/@vuexy/spinner/Loading-spinner";
import "../../../assets/scss/plugins/forms/switch/react-toggle.scss";
import { connect } from "react-redux";

const editSchema = {
  address: Yup.string().required("Required"),
  note: Yup.string().required("Required"),
};
const initSchema = {
  ...editSchema,
};
const defaultData = {
  address: "",
  note: "",
  createdBy: "",
  createdAt: "",
};
const defaultAddressInfo = {};

class AdminUsersSidebar extends Component {
  state = {
    inProgress: false,
    currentAddressInfo: defaultAddressInfo,
  };

  initialData = defaultData;
  formSchema = Yup.object().shape(initSchema);

  handleSubmit = (values) => {
    const payload = {
      ...values,
    };
    this.props.updateIPAddress(payload);
  };

  fetchInfoData = (addressId) => {
    if (this.state.inProgress) {
      return;
    }

    this.setState({ inProgress: true });

    this.props
      .getApprovedIPInfo(addressId)
      .then(({ data }) => {
        this.setState({ currentAddressInfo: data, inProgress: false });
      })
      .catch((error) => {
        this.setState({ inProgress: false });
        console.log("error = ", error);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.addNew === "" || prevProps.addNew === true) &&
      !prevProps.data &&
      !!this.props.data &&
      !prevState.inProgress &&
      !prevState.currentAddressInfo.id
    ) {
      this.initialData = {
        id: this.props.data.id,
        address: this.props.data.address,
        note: this.props.data.note,
        createdBy: this.props.data.createdBy,
        createdAt: this.props.data.createdAt,
      };
      this.formSchema = Yup.object().shape(editSchema);
      this.fetchInfoData(this.props.data.id);
    }
    if (
      !prevProps.addNew &&
      prevProps.data !== null &&
      this.props.data === null
    ) {
      this.setState({ currentAddressInfo: defaultAddressInfo });
      this.formSchema = Yup.object().shape(initSchema);
      this.initialData = defaultData;
    }
  }

  handleOnTopPermissionChange = (e, index) => {
    const {
      target: { checked },
    } = e;
    const currentAdminUpdateInfo = this.state.currentAddressInfo;
    currentAdminUpdateInfo.permissions[index].active = checked;
    this.setState({ currentAddressInfo: currentAdminUpdateInfo });
  };

  handleOnSubPermissionChange = (e, index, id) => {
    const {
      target: { checked },
    } = e;
    const currentAdminUpdateInfo = this.state.currentAddressInfo;
    const permissions = currentAdminUpdateInfo.permissions[index].subCat;
    let updatedPermissions;

    if (checked) {
      updatedPermissions = permissions;
      updatedPermissions.push(id);
    } else {
      updatedPermissions = permissions.filter((itm) => itm !== id);
    }

    currentAdminUpdateInfo.permissions[index].subCat = updatedPermissions;
    this.setState({ currentAddressInfo: currentAdminUpdateInfo });
  };

  render() {
    const { show, handleSidebar, addNew } = this.props;
    const { inProgress } = this.state;

    return (
      <div
        className={classnames("data-list-sidebar", {
          show: show,
        })}
      >
        <div className="data-list-sidebar-header px-2 d-flex justify-content-between">
          <h4>IP address details</h4>
          <X size={20} onClick={() => handleSidebar(false, true)} />
        </div>

        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          validationSchema={this.formSchema}
          initialValues={this.initialData}
        >
          {({ errors, touched }) => (
            <Form style={{ height: "100%" }}>
              <div
                className="data-list-fields px-2 mt-3"
                style={{ overflow: "auto" }}
              >
                {inProgress ? (
                  <div
                    className="spinnerHolder"
                    style={{ position: "relative", height: "200px" }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {/* <FormGroup>
                      <Label for="id">ID</Label>
                      <Field
                        disabled
                        placeholder=""
                        name="id"
                        className="form-control"
                        id="id"
                      />
                    </FormGroup> */}
                    <FormGroup>
                      <Label for="address">IP Address</Label>
                      <Field
                        placeholder="eg. 127.0.0.1"
                        name="address"
                        className={`form-control ${
                          errors.address && touched.address && "is-invalid"
                        }`}
                        id="address"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="note">Note</Label>
                      <Field
                        placeholder="eg. Doe's Office"
                        name="note"
                        className={`form-control ${
                          errors.note && touched.note && "is-invalid"
                        }`}
                        id="note"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="createdBy">Created by</Label>
                      <Field
                        type="createdBy"
                        placeholder="eg. Doe"
                        name="createdBy"
                        className={`form-control ${
                          errors.createdBy && touched.createdBy && "is-invalid"
                        }`}
                        id="createdBy"
                      />
                    </FormGroup>
                    {/* <FormGroup>
                      <Label for="email">Email</Label>
                      <Field
                        type="email"
                        placeholder="eg. john@doe.com"
                        name="email"
                        className={`form-control ${
                          errors.email && touched.email && "is-invalid"
                        }`}
                        id="email"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="phone_number">Phone Number</Label>
                      <Field
                        placeholder="eg. +15854380257"
                        name="phone_number"
                        className="form-control"
                        id="phone_number"
                      />
                    </FormGroup> */}
                  </>
                )}
              </div>

              <div className="px-2 py-1">
                <Button type="submit" color="primary" className="float-right">
                  {addNew ? "Create" : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(null, { getApprovedIPInfo })(AdminUsersSidebar);
