import React, { PureComponent } from "react";

import CountBox from "../CountBox";
import BorderedCountBox from "../BorderedCountBox";
import { Row, Col } from "reactstrap";

class RenewalsSection extends PureComponent {
  render() {
    const {
      iconName,
      renewalsWonCount,
      renewalsWonPercentage,
      renewalsLostCount,
      renewalsLostPercentage,
      renewals,
    } = this.props;
    return (
      <div className="mt-2">
        <CountBox
          label="Renewals due"
          iconName={iconName}
          count={renewals}
          size="sm"
        />

        <hr />

        <Row>
          <Col>
            {renewalsWonCount && (
              <BorderedCountBox
                label="Won:"
                count={renewalsWonCount}
                percentage={renewalsWonPercentage}
                color="#98CA86"
                tooltip="Compared to company avg."
              />
            )}
          </Col>
          <Col>
            {renewalsLostCount && (
              <BorderedCountBox
                label="Lost:"
                count={renewalsLostCount}
                percentage={renewalsLostPercentage}
                color="#F36565"
                tooltip="Compared to company avg."
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default RenewalsSection;
