import React, { Component } from "react";

import Icon from "../Icons";
import { Button } from "reactstrap";
import DropdownItem from "../../../components/fts/todo-dropdown/DropdownItem";

import "../../../assets/scss/components/fts/fts-menu.scss";

class FTSMenu extends Component {
  componentDidMount = () => {
    window.addEventListener("mousedown", this.handleDropdownClickOutside);
  };

  handleDropdownClickOutside = (e) => {
    if (
      this.menuRef.current &&
      !this.menuRef.current.contains(e.target) &&
      this.props.isOpen
    ) {
      this.props.handleMenu();
    }
  };

  menuRef = React.createRef();
  render() {
    const { options } = this.props;

    return (
      <div className="dropdown-wrapper" ref={this.menuRef}>
        <Button
          type="button"
          color="transparent"
          className="gray-button"
          onClick={() => {
            this.props.handleMenu();
          }}
        >
          <Icon name="MoreVertical" color="#7D8C91" />
        </Button>
        {this.props.isOpen && (
          <div className="dropdown dropdown-custom dropdown-custom-left">
            {options?.map((option) => {
              if (option.hide) {
                return null;
              }

              return (
                <DropdownItem
                  key={option.id}
                  text={option.text}
                  componentLeft={option.componentLeft}
                  action={option.action}
                  red={option.red}
                  disabled={option.disabled}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default FTSMenu;
