const listingTypes = [
  { label: "Premium listing type", value: 1 },
  { label: "Standard listing type", value: 2 },
  { label: "Basic plus listing type", value: 3 },
  { label: "Basic listing type", value: 4 },
];

export default listingTypes;
export const listingTypesArray = [
  {
    label: "Premium",
    value: 1,
  },
  {
    label: "Standard",
    value: 2,
  },
  {
    label: "Basic Plus",
    value: 3,
  },
  {
    label: "Basic",
    value: 4,
  },
  {
    label: "Free",
    value: 5,
  },
];
