import React, { Component } from "react";
import { ErrorMessage } from "formik";
import Flatpickr from "react-flatpickr";
import { Label } from "reactstrap";
import classNames from "classnames";

class FTSDateAndTime extends Component {
  render() {
    const {
      required,
      disabled,
      labelId,
      label,
      field,
      form,
      meta,
      options,
      placeholder,
      autoFocus,
      setFieldError,
      setFieldTouched,

      standalone,

      // standalone component props
      defaultValue,
      value,
      name,
      onChange,
      ...rest
    } = this.props;

    if (standalone) {
      const standaloneProps = {
        defaultValue,
        onChange,
        value,
        name,
      };

      return (
        <div className="fts-date-and-time">
          {!!label && (
            <Label className={disabled ? "disabled" : ""} for={labelId}>
              {label}
            </Label>
          )}
          <Flatpickr
            className={"fts-date-picker form-control"}
            id={labelId}
            {...standaloneProps}
            {...rest}
            {...{ placeholder, disabled, options, autoFocus }}
          />
        </div>
      );
    }

    const { errors, touched } = this.props?.form ?? {};

    return (
      <div className="fts-date-and-time">
        {!!label && (
          <Label className={disabled ? "disabled" : ""} for={labelId}>
            {required && !disabled ? `${label} *` : label}
          </Label>
        )}
        <Flatpickr
          key={meta.initialValue}
          defaultValue={meta.initialValue}
          className={classNames("fts-date-picker form-control", {
            "invalid-formik-input":
              !!errors[field.name] && !!touched[field.name],
            disabled,
          })}
          name={field.name}
          onChange={(date) => {
            if (!date) {
              setFieldError(field.name, undefined);
            }
            form.setFieldValue(field.name, date);
            setTimeout(() => {
              setFieldTouched(field.name, true, true);
            }, 0);
          }}
          // onBlur={field.onBlur}
          id={labelId}
          {...{ placeholder, disabled, options, autoFocus }}
        />
        <ErrorMessage
          name={field.name}
          render={(msg) => <div className="formik-validation-error">{msg}</div>}
        />
      </div>
    );
  }
}

export default FTSDateAndTime;
