import * as Yup from "yup";
import { REQUIRED_ERROR_MESSAGE } from "../../../../constants";

export const listingContactRowValidator = Yup.object().shape({
  phoneNumber: Yup.string().ensure().required(REQUIRED_ERROR_MESSAGE),
  phoneNumberSecondary: Yup.string().ensure(),
  mobile: Yup.string().ensure(),
  afterHours: Yup.string().ensure(),
  website: Yup.string()
    .ensure()
    .test("Check url", "Invalid link", (value) => {
      if (value) {
        const regex = new RegExp(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        );
        return regex.test(value);
      }
      return true;
    }),

  website2: Yup.string()
    .ensure()
    .test("Check url", "Invalid link", (value) => {
      if (value) {
        const regex = new RegExp(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        );
        return regex.test(value);
      }
      return true;
    }),
  email: Yup.string().ensure().email("Invalid email"),
});
