import styled from "styled-components";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const FtsPortal = styled(FtsWrapper) `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100dvw;
  min-height: 100dvh;
  z-index: 1;
  //background: ${ColorsValue["greyscale-1000"]};
  background: rgba(0, 0, 0, 0.5);
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
`;
