import { Dropdown } from "../Dropdown";
import { Chips } from "../Chips";
import React from "react";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { Span } from "../../../FtsText";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { components } from "react-select";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const CategoryWithSubCategoryDropdown = (props) => {
    return (React.createElement(Dropdown, { hideSelectedOptions: false, closeMenuOnSelect: false, components: {
            Option: (item) => {
                return (React.createElement(FtsWrapper, { cursor: "pointer", onClick: () => item.selectOption(item), ...item, display: "flex", justify: "space-between", px: "xl", py: "xs", bg: ColorsValue[item.isSelected ? "greyscale-50" : "greyscale-0"] },
                    React.createElement(Span, { variant: VariantsValues["text-2xs"] }, item.children),
                    item.isSelected && (React.createElement(FtsIcons, { width: "20px", height: "20px", iconName: IconNames.Check, pathProps: { stroke: ColorsValue["green-success-300"] } }))));
            },
            MultiValueRemove: (item) => {
                return (React.createElement(components.MultiValueRemove, { ...item },
                    React.createElement(FtsIcons, { ...item, iconName: IconNames.Close })));
            },
            MultiValueContainer: (item) => {
                return (React.createElement(Chips, { display: "flex", label: item.children, size: "sm", variant: "contained", textColor: "greyscale-0", color: ColorsValue["blue-accent-50"], ...item }));
            },
            MultiValue: (item) => {
                return (React.createElement(components.MultiValue, { ...item },
                    React.createElement(Span, { variant: VariantsValues["text-4xs"] }, item.children)));
            },
        }, ...props, options: props.options }));
};
