import FTSAxios from "../../../axios/fts.instance";

export const filterServiceAmenities = (value) => {
  return (dispatch) =>
    dispatch({ type: "FILTER_SERVICE_AMENITIES_DATA", value });
};

export const getServiceAmenitiesData = (queryParams = "?status=1&status=2") => {
  return (dispatch) => {
    return FTSAxios.get(`admin/lists/service-amenities${queryParams}`).then(
      (response) => {
        dispatch({
          type: "GET_SERVICE_AMENITIES_DATA",
          data: response.data,
          totalPages: 1,
        });
      },
    );
  };
};

export const updateServiceAmenity = (payload) => {
  return () => {
    return FTSAxios.put(`admin/lists/service-amenities/${payload.id}`, payload);
  };
};

export const deleteServiceAmenity = (payload) => {
  return () => {
    return FTSAxios.delete(`admin/lists/service-amenities/${payload.id}`);
  };
};

export const createServiceAmenity = (payload) => {
  return () => {
    return FTSAxios.post("admin/lists/service-amenities/create", payload);
  };
};
