import React from "react";
import { FtsAccordionContainer } from "../../../FtsAccordion/FtsAccordionContainer";
import { FtsAccordionHeader } from "../../../FtsAccordion/FtsAccordionHeader";
import { FtsAccordionDescription } from "../../../FtsAccordion/FtsAccordionDescription";
import { Span } from "../../../FtsText";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";
export const Accordion = (props) => {
    const { headerLabel, children, show, toggleShowAccordionDescription } = props;
    return (React.createElement(FtsAccordionContainer, null,
        React.createElement(FtsAccordionHeader, { onClick: toggleShowAccordionDescription, show: show },
            React.createElement(Span, { variant: VariantsValues["text-2xs"], color: ColorsValue["greyscale-800"] }, headerLabel),
            React.createElement(FtsIcons, { iconName: IconNames.ChevronDown })),
        React.createElement(FtsAccordionDescription, { show: show }, children)));
};
