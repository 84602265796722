import React, { useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import TableTabs from "../table-tabs";
import FTSAgGridDataTableHeader from "./FTSAgGridDataTableHeader";
import FTSAgGridDataTablePagination from "./FTSAgGridDataTablePagination";
import "../../../assets/scss/plugins/tables/_agGridStyleOverride.scss";
import FTSAgGridDataTableActionSection from "./FTSAgGridDataTableActionSection";

const FTSAgGridDataTable = ({
  rowData,
  columnDefs,
  onSelectionChanged,
  isMultiTab,
  subTabs = [],
  activeSubTabId,
  onRowClicked,
  rowSelection,
  tableHeader,
  tableSubHeader,
  buttonActionHandler,
  buttonActionLabel,
  buttonActionIcon,
  buttonActionInProgress,
  buttonActionDisabled,
  buttonActionColor,
  hasSearch = true,
  searchValue,
  handleSearchInput,
  searchInputName,
  searchPlaceholder = "Search",
  currentPageSize = 20,
  onPageChange,
  onSubTabChange,
  onSortChanged,
  currentPage = 1,
  handleFilterSize,
  pageCount = 0,
  defaultSortModel,
  getRowClass,
  onGridReadyInit,
  showActionSection = false,
  tableActionSectionText,
  handleCancelAction,
  handleSubmitAction,
  submitButtonLabel,
  inProgress,
  selectedRows,
  noRowsMessage = "No rows to show",
}) => {
  const gridRef = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      if (inProgress) {
        gridRef.current.api.showLoadingOverlay();
      } else if (rowData.length === 0) {
        gridRef.current.api.showNoRowsOverlay();
      } else {
        gridRef.current.api.hideOverlay();
      }
    }
  }, [inProgress, rowData]);

  useEffect(() => {
    if (gridRef.current && selectedRows?.length) {
      const selectedRowIdsMap = new Map(
        selectedRows.map((row) => [row.id, true]),
      );

      gridRef.current.api.forEachNode((node) => {
        if (selectedRowIdsMap.has(node.data?.id)) {
          node.setSelected(true);
        }
      });
    }
  }, [selectedRows, rowData]);

  const onColumnMoved = () => {
    // if (props?.tableName) {
    //   props.saveState(props?.tableName);
    // } else {
    //   props.saveState();
    // }

    console.log("ON COLUMN MOVED");
  };

  const handlePageChange = ({ selected }) => {
    onPageChange(selected + 1);
  };

  const onSelectionChangedHandler = (event) => {
    onSelectionChanged && onSelectionChanged(event);
  };

  const isRowSelectable = (rowNode) =>
    rowNode.data.statusForSort !== "Paid" &&
    rowNode.data.statusForSort !== "Voided"
      ? true
      : false;

  return (
    <div className="FTS-ag-grid-data-table">
      <FTSAgGridDataTableHeader
        tableHeader={tableHeader}
        tableSubHeader={tableSubHeader}
        searchValue={searchValue}
        hasSearch={hasSearch}
        handleSearchInput={handleSearchInput}
        searchInputName={searchInputName}
        searchPlaceholder={searchPlaceholder}
        buttonActionHandler={buttonActionHandler}
        buttonActionColor={buttonActionColor}
        buttonActionDisabled={buttonActionDisabled}
        buttonActionInProgress={buttonActionInProgress}
        buttonActionIcon={buttonActionIcon}
        buttonActionLabel={buttonActionLabel}
      />

      {isMultiTab && (
        <TableTabs
          tabs={subTabs}
          handleActiveTab={onSubTabChange}
          activeTabId={activeSubTabId}
        />
      )}
      <div className="ag-theme-material ag-grid-table">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          onRowClicked={onRowClicked}
          onColumnMoved={onColumnMoved}
          suppressDragLeaveHidesColumns={true}
          suppressRowClickSelection={true}
          components={{
            customLoadingOverlay: CustomLoadingOverlay,
          }}
          loadingOverlayComponent={"customLoadingOverlay"}
          loadingOverlayComponentParams={{
            loadingMessage: "One moment please...",
          }}
          onSortChanged={onSortChanged}
          onGridReady={(params) => {
            if (onGridReadyInit) onGridReadyInit(params);
            if (defaultSortModel) params.api.setSortModel(defaultSortModel);
          }}
          getRowClass={getRowClass}
          onSelectionChanged={onSelectionChangedHandler}
          isRowSelectable={onSelectionChangedHandler && isRowSelectable}
          rowSelection={rowSelection}
          overlayNoRowsTemplate={`<span class="ag-overlay-no-rows-center">${noRowsMessage}</span>`}
        />

        {rowData?.length ? (
          <FTSAgGridDataTablePagination
            currentPageSize={currentPageSize}
            handleFilterSize={handleFilterSize}
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            showArrows={rowData.length}
          />
        ) : null}

        {showActionSection && (
          <FTSAgGridDataTableActionSection
            tableActionSectionText={tableActionSectionText}
            handleCancelAction={handleCancelAction}
            handleSubmitAction={handleSubmitAction}
            submitButtonLabel={submitButtonLabel}
          />
        )}
      </div>
    </div>
  );
};

export default FTSAgGridDataTable;

function CustomLoadingOverlay() {}

CustomLoadingOverlay.prototype.init = function () {
  this.eGui = document.createElement("div");
  this.eGui.innerHTML = `
    <div role="status" class="m-1 spinner-border-lg spinner-border text-primary"/>`;
};

CustomLoadingOverlay.prototype.getGui = function () {
  return this.eGui;
};
