import React, { Component } from "react";
import { ErrorMessage } from "formik";

import { pickerStyles, controlStyles } from "../../picker-style";
import Select from "../../Select";

class HourSelect extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isDisabled !== this.props.isDisabled ||
      nextProps.options !== this.props.options ||
      nextProps.closedOr24hr !== this.props.closedOr24hr ||
      (!!this.props.meta &&
        nextProps.meta.touched !== this.props.meta.touched) ||
      (!!this.props.meta && nextProps.meta.errors !== this.props.meta.errors) ||
      nextProps.value?.value !== this.props.value?.value
    );
  }

  componentDidUpdate() {
    const { value, options, type, closedOr24hr, onHoursChange } = this.props;

    if (type && type === "To" && !closedOr24hr) {
      const indexAt = options.findIndex(({ value: optionValue }) => {
        return value?.value === optionValue;
      });

      if (indexAt < 0) {
        onHoursChange(options[0]);
      }
    }
  }

  render() {
    const {
      isDisabled,
      value,
      onHoursChange,
      options,
      type,
      meta,
      name,
      closedOr24hr,
    } = this.props;
    const additionalProps =
      isDisabled || closedOr24hr ? { isDisabled: true } : {};

    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) => controlStyles(styles, state, {
        minHeight: "32px",
        height: "32px",
      }, { meta }),
      valueContainer: (styles, { isDisabled: disabled }) => ({
        ...styles,
        paddingLeft: disabled ? 0 : styles.paddingLeft,
        paddingTop: 0,
        paddingBottom: 0,
        top: "1px",
        fontSize: disabled ? styles.fontSize : "1.1rem",
      }),
      dropdownIndicator: (styles, { isDisabled }) => ({
        ...styles,
        padding: isDisabled ? styles.padding : "5px",
        paddingTop: isDisabled ? styles.paddingTop : "6px",
      }),
    };

    return (
      <React.Fragment>
        <>
          <Select
            defaultValue={meta ? meta.initialValue : null}
            onChange={onHoursChange}
            value={closedOr24hr ? "" : value}
            options={options}
            styles={pickerStyle}
            name={name}
            placeholder={type}
            noPlaceholder={closedOr24hr}
            {...additionalProps}
          />
          {!!meta && (
            <ErrorMessage
              name={name}
              render={(msg) => {
                if (typeof msg === "object") return null;
                return <div className="formik-validation-error">{msg}</div>;
              }}
            />
          )}
        </>
      </React.Fragment>
    );
  }
}

export default React.memo(HourSelect);
