import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";

import Icon from "../Icons";

import "../../../assets/scss/components/fts/activity.scss";
import FormikInputValue from "../formik/formik-input-value";
import { requestParticipation } from "../../../redux/actions/todo";
import {
  createActivity,
  fetchActivitiesAccount,
  fetchActivitiesListing,
} from "../../../redux/actions/activities";
import { activities } from "../../../constants";
import { toast } from "react-toastify";
import { setUserActivities } from "../../../redux/actions/user-activities";

class Activity extends Component {
  onHandleParticipation = async (status) => {
    const params = {
      todoId: this.props.data.todo.id,
      sendBy: this.props.data.adminUserId,
      status,
      notificationId: this.props.data.notificationId,
      todoTitle: this.props.data.todo.title,
      ownerId: this.props.data.todo.ownerId,
    };

    await this.props.requestParticipation(params);

    let activityPayload = {
      status: 1,
      toDoId: this.props.data.todo.id,
      userId: this.props.data.todo.ownerId,
      activityId: this.props.data.id,
      activityType: this.props.activitiesTypes.HANDLED_PARTICIPATION_REQUEST,
      activity: activities.handleParticipationRequest
        .replace(
          "{{admin_name}}",
          `${this.props.userData.firstName} ${this.props.userData.lastName}`,
        )
        .replace("{{status}}", status)
        .replace("{{todoId}}", `${this.props.data.todo.id}`),
      adminUserId: this.props.userData.id,
      iconName: "Todos",
      notificationId: this.props.data.notificationId,
    };

    await this.props.createActivity(activityPayload);
    if (this.props.isListing) {
      this.props
        .fetchActivitiesListing(this.props.listingId)
        .then(({ data }) => this.props.setUserActivities(data));
    } else {
      this.props
        .fetchActivitiesAccount(this.props.data.todo.ownerId)
        .then(({ data }) => this.props.setUserActivities(data));
    }

    toast.success(`Todo participation ${status}.`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  isCurrentUserTodoOwner = (adminOwnerId) => {
    return this.props.userData.id === adminOwnerId;
  };

  render() {
    const {
      data,
      onActivityClicked,
      showLink,
      activitiesTypes,
      isSmallActivity,
    } = this.props;

    return (
      <div className="activity-wrapper">
        <div
          className="d-flex flex-column"
          onClick={() => {
            if (data.toDoId && showLink) {
              return onActivityClicked(data.toDoId, data.activityType);
            }
          }}
        >
          {data.toDoId && showLink && (
            <div className="fts-note-linked mb-1">
              <span
                style={{
                  color: "#00b5f4",
                  fontSize: 14,
                  cursor: "pointer",
                }}
              >
                Linked from Todo {">"}{" "}
              </span>
            </div>
          )}
          <>
            <span className="d-flex flex-row">
              <span className="activity-icon">
                <Icon
                  name={data.iconName || "Update"}
                  color="#9CA6A9"
                  size={25}
                />
              </span>
              <div
                className="d-flex flex-column"
                style={{
                  wordBreak: "break-word",
                }}
              >
                <FormikInputValue>{data.activity}</FormikInputValue>

                {isSmallActivity &&
                  data.activityType ===
                    activitiesTypes?.REQUESTED_PARTICIPATION &&
                  !data.requestHandled &&
                  this.isCurrentUserTodoOwner(data?.todo?.adminOwnerId) && (
                    <div className="buttonsm mt-1 mb-1">
                      <span
                        onClick={() =>
                          this.onHandleParticipation(
                            "Approved",
                            data.notificationId,
                          )
                        }
                        className="mr-1"
                        style={{ color: "#00b5f4", cursor: "pointer" }}
                      >
                        <span>
                          <Icon size={20} color={"#fff"} name={"ApproveTodo"} />
                        </span>
                        Accept
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.onHandleParticipation(
                            "Rejected",
                            data.notificationId,
                          )
                        }
                      >
                        <span className="reject-icon">
                          <Icon size={22} color={"#fff"} name={"Close"} />
                        </span>
                        Reject
                      </span>
                    </div>
                  )}
                <span className="activity-date-time">
                  {moment(data.createdAt).format("lll")}
                </span>
              </div>
            </span>
          </>
        </div>
        {data.activityNote && (
          <>
            <hr />
            <div className="activity-data-title">Activity note</div>
            <div>{data.activityNote}</div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoicesData: state.invoices.invoicesData,
    activitiesTypes: state.essentialLists.activitiesTypes,
    userData: state.auth.login.values?.loggedInUser,
    activities: state.accountActivities.activities,
  };
};

export default connect(mapStateToProps, {
  requestParticipation,
  createActivity,
  setUserActivities,
  fetchActivitiesAccount,
  fetchActivitiesListing,
})(Activity);
