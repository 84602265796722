import React from "react";
import Icon from "../Icons";
import "../../../assets/scss/components/fts/search-input-new.scss";

const SearchInputNew = ({ onInputUpdate, placeholder, name, value, type }) => {
  return (
    <div className="FTS-search-input-new">
      <div className="FTS-search-input-new-icon">
        <Icon name="Search" />
      </div>
      <input
        className="input"
        name={name}
        value={value}
        type={type}
        onChange={(e) => {
          onInputUpdate(e.target?.value);
        }}
        autoComplete="text"
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchInputNew;
