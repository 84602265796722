import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";

import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Span } from "../../dist/ui/src/FtsText";

import Checkbox from "../../dist/ui/src/components/react/Checkbox";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectListingCategories,
  selectListingData,
  selectListingSubCategories,
} from "../../../../redux/reducers/v2/listing";
import { Accordion } from "../../dist/ui/src/components/react/Accordion";
import {
  selectCategories,
  selectSubCategories,
} from "../../../../redux/reducers/essentialLists";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const CategoriesPicker = () => {
  const dispatch = useDispatch();
  const selectedCategories = useSelector(selectListingCategories);
  const selectedSubCategories = useSelector(selectListingSubCategories);
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const listingData = useSelector(selectListingData);

  const onChangeSubCategory = useCallback(
    (subCategory) => {
      const isChecked = selectedSubCategories.find(
        (i) => i.id === subCategory.id,
      );
      if (isChecked) {
        return dispatch(
          getListingDataByIdSuccess({
            data: {
              ...listingData,
              adsSubCategories: selectedSubCategories.filter(
                (e) => e.id !== subCategory.id,
              ),
            },
          }),
        );
      }
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            adsSubCategories: [...selectedSubCategories, subCategory],
          },
        }),
      );
    },
    [listingData],
  );
  const toggleShowAccordionDescription = useCallback(
    (category) => {
      const isChecked = selectedCategories.find((i) => i.id === category.id);
      if (isChecked) {
        return dispatch(
          getListingDataByIdSuccess({
            data: {
              ...listingData,
              adsCategories: selectedCategories.filter(
                (e) => e.id !== category.id,
              ),
            },
          }),
        );
      }
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            adsCategories: [...selectedCategories, category],
          },
        }),
      );
    },
    [listingData],
  );
  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          SEARCH CATEGORIES
        </Span>
      </FtsColumn>
      {categories.map((e) => (
        <FtsColumn key={e.id} size={{ xs: 4 }}>
          <Accordion
            show={!!selectedCategories.find((i) => i.id === e.id)}
            headerLabel={e.value}
            toggleShowAccordionDescription={() =>
              toggleShowAccordionDescription(e)
            }
          >
            {subCategories
              .filter((f) => f.categoryId === e.id)
              .map((g) => (
                <Checkbox
                  ml={"md"}
                  onClick={() => onChangeSubCategory(g)}
                  key={g.id}
                  checked={selectedSubCategories.find((h) => h.id === g.id)}
                  label={g.value}
                  my={"md"}
                />
              ))}
          </Accordion>
        </FtsColumn>
      ))}
    </FtsRow>
  );
};
