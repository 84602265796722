import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";

import FormikFastInput from "../../../../components/fts/formik/formik-fast-input";
import Divider from "../../../../components/fts/Divider";
import FormikMaskedInput from "../../../../components/fts/formik/masked-input";
import { phoneNumberMask } from "../../../../utility/masks";
import { ContextMenuTrigger } from "react-contextmenu";

import "../../../../assets/scss/components/fts/form-fields.scss";

class BusinessDetails extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      createMode,
      editMode,
      keys,
      updateListingFromEmail,
      formRef,
      collectDataForCopy,
    } = this.props;

    return (
      <React.Fragment>
        <Divider textColor="primary" position="left">
          Contact info
        </Divider>
        <Row>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${
                keys && keys.includes("phoneNumber") && "field-changed"
              }`}
            >
              <ContextMenuTrigger
                disable={editMode}
                data={formRef?.current?.values?.phoneNumber}
                collect={() => {
                  collectDataForCopy(
                    formRef?.current?.values?.phoneNumber,
                    "Phone"
                  );
                }}
                id="contextMenuListingSettings"
              >
                <div className="cursor-pointer">
                  <FormikMaskedInput
                    disabled={!editMode}
                    name="phoneNumber"
                    label="Primary phone number"
                    labelId="phoneNumber"
                    required
                    placeholder="Enter phone number"
                    mask={phoneNumberMask}
                  />
                </div>
              </ContextMenuTrigger>
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${
                keys && keys.includes("phoneNumberSecondary") && "field-changed"
              }`}
            >
              <ContextMenuTrigger
                disable={editMode}
                data={formRef?.current?.values?.phoneNumberSecondary}
                collect={() => {
                  collectDataForCopy(
                    formRef?.current?.values?.phoneNumberSecondary,
                    "Phone"
                  );
                }}
                id="contextMenuListingSettings"
              >
                <div className="cursor-pointer">
                  <FormikMaskedInput
                    disabled={!editMode}
                    name="phoneNumberSecondary"
                    label="Secondary phone number"
                    labelId="phoneNumberSecondary"
                    placeholder="Secondary phone number"
                    mask={phoneNumberMask}
                  />
                </div>
              </ContextMenuTrigger>
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${
                keys && keys.includes("afterHours") && "field-changed"
              }`}
            >
              <ContextMenuTrigger
                disable={editMode}
                data={formRef?.current?.values?.afterHours}
                collect={() => {
                  collectDataForCopy(
                    formRef?.current?.values?.afterHours,
                    "Phone"
                  );
                }}
                id="contextMenuListingSettings"
              >
                <div className="cursor-pointer">
                  <FormikMaskedInput
                    disabled={!editMode}
                    name="afterHours"
                    label="Afterhours phone"
                    labelId="afterHours"
                    placeholder="Enter phone number"
                    mask={phoneNumberMask}
                  />
                </div>
              </ContextMenuTrigger>
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${keys && keys.includes("fax") && "field-changed"}`}
            >
              <ContextMenuTrigger
                disable={editMode}
                data={formRef?.current?.values?.fax}
                collect={() => {
                  collectDataForCopy(formRef?.current?.values?.fax, "Phone");
                }}
                id="contextMenuListingSettings"
              >
                <div className="cursor-pointer">
                  <FormikMaskedInput
                    disabled={!editMode}
                    name="fax"
                    label="Fax"
                    labelId="fax"
                    placeholder="Enter fax number"
                    mask={phoneNumberMask}
                  />
                </div>
              </ContextMenuTrigger>
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="6">
            <span
              className={`${keys && keys.includes("email") && "field-changed"}`}
            >
              <FormikFastInput
                disabled={!editMode}
                type="email"
                name="email"
                label="E-mail"
                labelId="email"
                placeholder="Enter E-mail address"
              />
            </span>
          </Col>
        </Row>
        <Divider textColor="primary" position="left">
          Website links
        </Divider>
        <Row>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${
                keys && keys.includes("website") && "field-changed"
              }`}
            >
              {editMode ? (
                <FormikFastInput
                  disabled={!editMode}
                  name="website"
                  label="Website link"
                  labelId="website-listing"
                  placeholder="Enter website link"
                />
              ) : (
                <span className="undefined">
                  <div className="form-group">
                    <label htmlFor="website-listing" className="disabled">
                      Website link
                    </label>
                    <div className="formik-input-value undefined">
                      {this.props.listingData?.website ? (
                        <a
                          href={`https://${this.props.listingData?.website}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.props.listingData?.website}
                        </a>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </span>
              )}
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${
                keys && keys.includes("website2") && "field-changed"
              }`}
            >
              {editMode ? (
                <FormikFastInput
                  disabled={!editMode}
                  name="website2"
                  label="Website link #2"
                  labelId="website2-listing"
                  placeholder="Enter website link"
                />
              ) : (
                <span className="undefined">
                  <div className="form-group">
                    <label htmlFor="website2-listing" className="disabled">
                      Website link #2
                    </label>
                    <div className="formik-input-value undefined">
                      {this.props.listingData?.website2 ? (
                        <a
                          href={`https://${this.props.listingData?.website2}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.props.listingData?.website2}
                        </a>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </span>
              )}
            </span>
          </Col>
          <Col sm="12" md="6" lg="4" xl="3">
            <span
              className={`${
                keys && keys.includes("specials") && "field-changed"
              }`}
            >
              {editMode ? (
                <FormikFastInput
                  className="mb-md-0"
                  disabled={!editMode}
                  name="specials"
                  label="Specials"
                  labelId="specials"
                  placeholder="Enter account's specials"
                />
              ) : (
                <span className="undefined">
                  <div className="form-group">
                    <label htmlFor="website2-listing" className="disabled">
                      Specials
                    </label>
                    <div className="formik-input-value undefined">
                      {this.props.listingData?.specials ? (
                        <a
                          href={`https://${this.props.listingData?.specials}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.props.listingData?.specials}
                        </a>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </span>
              )}
            </span>
          </Col>
          {!createMode && !updateListingFromEmail && (
            <Col sm="12" md="6" lg="4" xl="3">
              <span
                className={`${
                  keys && keys.includes("seoUrlIdentifier") && "field-changed"
                }`}
              >
                <FormikFastInput
                  disabled
                  name="seoUrlIdentifier"
                  label="SEO URL"
                  labelId="seoUrlIdentifier"
                  placeholder="Enter SEO URL"
                  noMargin
                />
              </span>
            </Col>
          )}
        </Row>
      </React.Fragment>
    );
  }
}
export default BusinessDetails;
