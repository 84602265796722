import React, { PureComponent } from "react";

import CountBox from "../CountBox";
import BorderedCountBox from "../BorderedCountBox";
import { Row, Col } from "reactstrap";

class DealsSection extends PureComponent {
  render() {
    const {
      iconName,
      newBussinesCount,
      newBussinesPercentage,
      renewalsCount,
      renewalsPercentage,
      total,
    } = this.props;
    return (
      <div className="mt-2">
        <CountBox
          label="Deals closed"
          iconName={iconName}
          count={total || "-"}
          size="sm"
        />

        <hr />

        <Row>
          <Col>
            {newBussinesCount && (
              <BorderedCountBox
                label="New business:"
                count={newBussinesCount}
                percentage={newBussinesPercentage}
                color="#98CA86"
                tooltip="Compared to company avg."
              />
            )}
          </Col>
          <Col>
            {renewalsCount && (
              <BorderedCountBox
                label="Renewals:"
                count={renewalsCount}
                percentage={renewalsPercentage}
                color="#FFB25F"
                tooltip="Compared to company avg."
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default DealsSection;
