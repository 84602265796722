import React, { Component } from "react";
import { connect } from "react-redux";

import * as Yup from "yup";
import Icon from "../Icons";
import moment from "moment";
import FTSModal from "../fts-modal";
import { toast } from "react-toastify";
import TimerPicker from "../timer-picker";
import { Formik, Form, Field } from "formik";
import AccountEmailsPicker from "../account-emails-picker";
import { Button, Col, FormGroup, Row, Label, Spinner } from "reactstrap";

import AccessNotification from "../admin-notification";
import {
  getAccountAccessEmails,
  requestAccountAccess,
} from "../../../redux/actions/users";
import "../../../assets/scss/components/fts/account-access-request-modal.scss";
import { activities } from "../../../constants";
import { createActivity } from "../../../redux/actions/activities";

const formSchema = Yup.object().shape({
  users: Yup.array(Yup.object()).required(
    "Select at least one e-mail address!",
  ),
  expire: Yup.string().required("Access duration is required!"),
});
class AccountAccessRequestModal extends Component {
  state = {
    isLoading: true,
    inProgress: false,
    list: null,
    initialData: { users: [], expire: "" },
  };

  formatExpiryTimestamp(value) {
    const currentTimestamp = moment();

    switch (value) {
      case "15m":
        return currentTimestamp.add(15, "minutes").format();
      case "30m":
        return currentTimestamp.add(30, "minutes").format();
      case "1h":
        return currentTimestamp.add(1, "hour").format();
      case "1d":
        return currentTimestamp.add(1, "day").format();
      case "1w":
        return currentTimestamp.add(1, "week").format();
      case "0":
        return currentTimestamp.add(5, "year").format();
      default:
        return "null";
    }
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    const { accountId, accountLink } = this.props;

    this.props
      .getAccountAccessEmails(accountLink || accountId)
      .then(({ data }) => {
        this.setState({ list: data?.emails });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  createRemoteAccessLoginActivity = async () => {
    let activityPayload = {
      status: 1,
      userId: this.props.accountId,
      activityType: this.props.activitiesTypes?.ACCESS_REQUEST_CREATE,
      activity: activities?.accessRequestCreate.replace(
        "{{admin_name}}",
        `${this.props.userData.firstName} ${this.props.userData.lastName}`,
      ),
      adminUserId: this.props.userData?.id,
      iconName: "AccountsActivity",
    };

    await this.props.createActivity(activityPayload);
  };

  render() {
    const { isLoading, inProgress } = this.state;
    const { title, closeModal, onClose } = this.props;

    const handleSubmit = (values) => {
      if (this.state.inProgress) return;
      this.setState({ inProgress: true });

      let payload = {
        users: values?.users,
        expiresAt: values?.expire,
      };

      this.props
        .requestAccountAccess(payload)
        .then(() => {
          this.props.setAccountAccessRequests({ status: 1 });
          toast.info(
            <AccessNotification
              type="positive"
              title="Account access successfully requested."
              description="You will receive a notification when the owner of this account
              approves your request."
            />,
            {
              autoClose: false,
            },
          );
          this.props.getAccountInfo(this.props.accountId);

          this.createRemoteAccessLoginActivity();

          this.props.closeModal();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Oops! Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {
          this.setState({ inProgress: false });
        });
    };

    return (
      <FTSModal
        {...{ title, closeModal, onClose }}
        modalClasses={"fts-account-access-request-modal"}
        centered={true}
        isOpen={true}
        style={{ maxWidth: "900px" }}
        footerCTA={() => {
          return (
            <div className="fts-account-access-request-modal-footer">
              <Button
                color="primary"
                type="submit"
                form="account-access-form"
                disabled={inProgress}
              >
                {!inProgress ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Icon name="Lock" className="lock-icon" />
                    <div>Request Access</div>
                  </div>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </div>
          );
        }}
      >
        <Formik
          validateOnChange={false}
          validateOnBlur={true}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={formSchema}
          initialValues={this.state.initialData}
        >
          {({ setFieldError, setFieldTouched }) => {
            return (
              <>
                <Form id="account-access-form">
                  <div className="fts-account-access-request-modal-body">
                    <div className="fts-account-access-request-modal-body-close">
                      <Icon name="Close" size={32} onClick={closeModal} />
                    </div>
                    <div className="fts-account-access-request-modal-body-content">
                      <Icon name="Lock" size={48} id="lock-icon" />
                      <div className="w-100">
                        <h3>Request Account Access</h3>
                        <p>
                          Please choose the email address from which you would
                          like to request access.
                        </p>
                        <Row>
                          <Col md="6" sm="6" lg="6">
                            <FormGroup>
                              <Label for="users">
                                E-mail from the account *
                              </Label>

                              <Field name="users">
                                {({ meta, form, field }) => {
                                  return (
                                    <span className="w-50">
                                      <AccountEmailsPicker
                                        placeholder="Select"
                                        name="users"
                                        label="E-mail from the account *"
                                        setFieldValue={form.setFieldValue}
                                        onBlur={field.onBlur}
                                        required
                                        closeMenuOnSelect={true}
                                        showAssigned={false}
                                        list={this.state.list}
                                        isLoading={isLoading}
                                        onChange={(values) => {
                                          if (values) {
                                            setFieldError("users", undefined);
                                          }
                                          form.setFieldValue("users", values);
                                          setTimeout(() => {
                                            setFieldTouched(
                                              "users",
                                              true,
                                              true,
                                            );
                                          }, 0);
                                        }}
                                        {...{
                                          meta,
                                          setFieldTouched,
                                          setFieldError,
                                        }}
                                      />
                                    </span>
                                  );
                                }}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col md="6" sm="6" lg="6">
                            <FormGroup>
                              <Label for="expire">Duration *</Label>

                              <Field name="expire">
                                {({ meta, form, field }) => {
                                  return (
                                    <span className="w-50">
                                      <TimerPicker
                                        placeholder="Select"
                                        name="expire"
                                        setFieldValue={form.setFieldValue}
                                        onBlur={field.onBlur}
                                        required
                                        closeMenuOnSelect={true}
                                        showAssigned={false}
                                        value={field.value}
                                        list={this.state.list}
                                        isLoading={isLoading}
                                        onChange={(value) => {
                                          if (value) {
                                            setFieldError("expire", undefined);
                                          }
                                          form.setFieldValue(
                                            "expire",
                                            this.formatExpiryTimestamp(
                                              value?.value,
                                            ),
                                          );
                                          setTimeout(() => {
                                            setFieldTouched(
                                              "expire",
                                              true,
                                              true,
                                            );
                                          }, 0);
                                        }}
                                        {...{
                                          meta,
                                          setFieldTouched,
                                          setFieldError,
                                        }}
                                      />
                                    </span>
                                  );
                                }}
                              </Field>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </FTSModal>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.auth.login.values.loggedInUser,
  activitiesTypes: state.essentialLists.activitiesTypes,
});

export default connect(mapStateToProps, {
  getAccountAccessEmails,
  requestAccountAccess,
  createActivity,
})(AccountAccessRequestModal);
