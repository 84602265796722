import React, { Fragment } from "react";
import { format } from "date-fns";
import { getStoreStatus } from "./helper";

const WorkingHoursPreview = ({
  alwaysOpen,
  workingHours,
  listingCurrentTime,
}) => {
  const day = format(new Date(), "EEEE").toLowerCase();
  const currentHours = !alwaysOpen && workingHours[day];
  const storeStatus = getStoreStatus({ currentHours, listingCurrentTime });

  if (alwaysOpen) {
    return (
      <span>
        24 HR<span style={{ color: "#98CA86" }}> (Open)</span>
      </span>
    );
  }

  return (
    <Fragment>
      <span>{storeStatus}</span>
    </Fragment>
  );
};

export default WorkingHoursPreview;
