/* eslint-disable indent */
import React, { Component } from "react";
import { Label } from "reactstrap";
import { ErrorMessage } from "formik";
import { connect } from "react-redux";
import { components } from "react-select";

import {
  pickerStyles,
  controlStyles,
  searchPickerStyles,
  searchControlStyles,
} from "../picker-style";
import Select from "../Select";

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null
      )}
    </components.ValueContainer>
  );
};

class StatusFilter extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isDisabled !== this.props.isDisabled ||
      (!!this.props.meta &&
        (nextProps.meta.error !== this.props.meta.error ||
          nextProps.meta.touched !== this.props.meta.touched ||
          nextProps.meta.value !== this.props.meta.value)) ||
      nextProps.value !== this.props.value ||
      nextProps.userStatuses.length !== this.props.userStatuses
    );
  }

  render() {
    const {
      onStateChange,
      label,
      userStatuses,
      name = "adminStatus",
      meta,
      value,
      isDisabled,
      isSearch,
      placeholder,
      hideCustomer,
    } = this.props;

    let options;
    let isLoading = false;

    if (isSearch) {
      options = [{ value: "", label: "All" }, ...userStatuses];
    } else {
      options = userStatuses;
    }

    if (hideCustomer) {
      options = options.filter((e) => e.label !== "Customer");
    }
    if (!options?.length) {
      isLoading = true;
    }

    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) => controlStyles(styles, state, {}, { meta }),
    };

    const searchPickerStyle = {
      ...searchPickerStyles,
      control: (styles, state) =>
        searchControlStyles(styles, state, {}, { meta }),
    };

    const defaultValue =
      meta && meta.initialValue && options.length
        ? options.find((itm) => itm.value === meta.initialValue)
        : isSearch
        ? { value: "", label: "All" }
        : null;

    let valueInfo = typeof value !== "object" ? defaultValue : value;

    return (
      <>
        {!!label && (
          <Label className={isDisabled ? "disabled" : ""} for={name}>
            {this.props.required && !isDisabled ? `${label} *` : label}
          </Label>
        )}
        <Select
          {...{ isLoading }}
          id={name}
          isClearable
          styles={isSearch ? searchPickerStyle : pickerStyle}
          onChange={onStateChange}
          value={valueInfo}
          components={
            isSearch
              ? {
                  ValueContainer: CustomValueContainer,
                }
              : null
          }
          {...{ defaultValue, isDisabled, options, name, placeholder, label }}
        />
        {!!meta && (
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="formik-validation-error">{msg}</div>
            )}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userStatuses: state.essentialLists.userStatuses,
  };
};

export default connect(mapStateToProps, null)(StatusFilter);
