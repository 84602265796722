/* eslint-disable indent */
/* eslint-disable react/display-name */
import React from "react";
import { formatRawDate, toMomentDate } from "../../../configs/dateConfig";
import { ContextMenuTrigger } from "react-contextmenu";
import moment from "moment";
import Icon from "../../../components/fts/Icons";
import "../recurring-payments/recurring-payments-columns.scss";

export const adminStatusBasedOnID = (adminStatus) => {
  let label;
  switch (adminStatus) {
    case 1:
      label = "Free";
      break;
    case 2:
      label = "Lead";
      break;
    case 3:
      label = "Customer";
      break;
    case 4:
      label = "Trial";
      break;
    case 5:
      label = "Suspended";
      break;
    case 6:
      label = "Closed";
      break;
    case 7:
      label = "Spammer";
      break;
    case 8:
      label = "Paid";
      break;
    case 9:
      label = "Unpaid";
      break;
    case 10:
      label = "Project";
      break;
    case 11:
      label = "Other";
      break;

    case -1:
    default:
      label = "Not assigned";
      break;
  }
  return label;
};

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
      <div className="ag-date-table__time">
        {toMomentDate(date).format("[at] h:mm a")}
      </div>
    </div>
  );
};

export const defineListingColumns = (
  types,
  collectDataForCopy,
  activeTab,
  employees,
  openChangePaymentMethodSidebar
) => {
  // console.log(activeTab, "active tab");
  let columns = [
    {
      headerName: "Next payment",
      field: "renewalTimestamp",
      sortable: false,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment.unix(params.data.renewalTimestamp).format("ll")}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>
              {!!params.data.renewalTimestamp &&
                moment.unix(params.data.renewalTimestamp).format("ll")}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "CID (Account I#D)",
      field: "userId",
      sortable: false,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.userId}
          collect={collectDataForCopy}
          id="contextmenu"
        >
          <span>{params.data.userId}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Listing ID",
      field: "id",
      sortable: false,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.id}
          collect={collectDataForCopy}
          id="contextmenu"
        >
          <span>{params.data.id}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Account owner",
      field: "adminAccountOwner",
      sortable: false,
      unSortIcon: true,
      width: 180,
      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data.adminAccountOwner
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Unassigned"}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{admin?.label || "Unassigned"}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "nameForSort",
      sortable: false,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.name}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.name}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: false,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.city}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: false,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email address",
      field: "emailForSort",
      sortable: false,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: false,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.phoneNumber}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "After hours",
      field: "afterHoursForSort",
      sortable: false,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.afterHours}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.afterHours || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Amount",
      field: "afterHoursForSort",
      sortable: false,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.invoices[0]?.total}
            collect={collectDataForCopy}
            // collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.invoices[0]?.total || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Payment date",
      field: "afterHoursForSort",
      sortable: false,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        if (params.data.invoices[0]?.payment?.createdAt)
          return (
            <ContextMenuTrigger
              data={moment(params.data.invoices[0]?.payment?.createdAt).format(
                "lll"
              )}
              collect={collectDataForCopy}
              id="contextmenu"
            >
              <span>
                <TableDate
                  date={new Date(
                    params.data.invoices[0]?.payment?.createdAt
                  ).toISOString()}
                />
              </span>
            </ContextMenuTrigger>
          );
        return "";
      },
    },
    {
      headerName: "Payment method",
      field: "afterHoursForSort",
      sortable: false,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.invoices[0]?.payment?.paidMethod}
            collect={collectDataForCopy}
            // collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.invoices[0]?.payment?.paidMethod || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Card",
      field: "afterHoursForSort",
      sortable: false,
      unSortIcon: true,
      width: 200,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.paymentSubscriptionCard?.last_four_digital}
            collect={collectDataForCopy}
            // collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>
              **** / **** / **** /{" "}
              {params.data.paymentSubscriptionCard?.last_four_digital || ""}
            </span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "",
      field: "",
      sortable: false,
      pinned: "right",
      unSortIcon: true,
      width: 50,
      cellStyle: {
        "border-left": "1px solid #E5EBED !important",
        "box-shadow": "-2px 0px 5px #e5e8e9, 0px 1px 0px #e5e8e9;",
      },
      cellRendererFramework: (params) => {
        return (
          <div
            onClick={() => {
              openChangePaymentMethodSidebar(params.data);
            }}
          >
            <Icon name="Edit" size={24} />
          </div>
        );
      },
    },
  ];

  return columns;
};
