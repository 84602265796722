import * as Yup from "yup";
import { zipValidate, urlValidate } from "../../dist/types/validators/global";

export const accountCompanyRowValidator = Yup.object().shape({
  website2: urlValidate,
  website: urlValidate,
  zip: zipValidate,
  state: Yup.string().required("State"),
  city: Yup.string().required("City"),
  address: Yup.string().required("Street"),
  companyName: Yup.string().required("Company name is required"),
});
