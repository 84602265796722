import React, { Component, createRef } from "react";

import { connect } from "react-redux";
import "../../../assets/scss/pages/account-report.scss";
import classnames from "classnames";
import {
  Card,
  CardBody,
  Spinner,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import TitleRow from "../../../components/fts/TitleRow";
import Flatpickr from "react-flatpickr";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import Icon from "../../../components/fts/Icons";
import { defineColumnDefsGeneratedReports } from "../../../components/fts/account-report/generated-reports-columns";

import ExposureChart from "../../../components/fts/exposure-chart";
// import UserEngagementChart from "../../../components/fts/user-engagement-chart";
import GenerateReportConfirmation from "../../../components/fts/generate-report-confirmation";
import GenerateReportSuccess from "../../../components/fts/generate-report-success";
import { toast } from "react-toastify";
import {
  getListingsReports,
  getAccountReports,
  getListingReport,
  getAccountReport,
  generateListingReport,
  getAccountReportCounters,
  getListingReportCounters,
} from "../../../redux/actions/reports";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";
import { hideMenu } from "react-contextmenu";
import ReportDropdown from "../../../components/fts/report-dropdown";
import socketHandler from "../../../utility/socket";
import { MapPin, Phone } from "react-feather";

import {
  formatReportForChart,
  generateReportPayload,
} from "../../../utility/chart";
import moment from "moment";
import { createActivity } from "../../../redux/actions/activities";
import { activities, PERMISSIONS, SUB_PERMISSIONS } from "../../../constants";
import PermissionGate from "../../../components/fts/permission-gate/PermissionGate";
import InfoMessage from "../../../components/fts/info-message";
import classNames from "classnames";
import { checkPermissions } from "../../../utility";

class AccountReport extends Component {
  state = {
    report: null,
    reportCounters: null,
    activeTabCounters: null,
    isLoading: false,
    activeTab: "1y",
    layoutTab: "",
    reportName: "",
    startAt: moment().subtract(1, "years").toISOString().split("T")[0],
    sortModel: [],
    endAt: moment().toISOString().split("T")[0],
    isOpenGenerateReportConfirmationModal: false,
    isOpenGenerateReportSuccessfully: false,
    isOpen: false,
    generatedListingTableData: null,
    pageSize: 10,
    page: 1,
    generatedListingPageCount: 0,
    generatedListingTotalRecords: 0,

    generatedAccountTotalRecords: 0,
    activeSubMenu: null,
    defaultColDef: {
      sortable: true,
    },
    showChart: false,
    reportInProgress: false,
    includePaymentLink: true,
    errorDateRange: false,
  };

  validEventTypes = ["listingViewed", "click", "top3", "top1"];
  pageSizes = [10, 25, 50, 100];

  gridApiGeneratedListing = createRef();
  gridApiGeneratedAccount = createRef();
  dateRef = createRef();
  contextMenuRef = createRef();

  componentWillUnmount() {
    if (socketHandler?.socket?.readyState === WebSocket.OPEN) {
      socketHandler.send("usSubscribeToAnalytics", {
        listingId: this.props.listingId,
      });
    }
  }

  onMessage = (data) => {
    let {
      reports,
      activeTabCounters,
      reportCounters,
      startAt,
      endAt,
      activeTab,
    } = this.state;
    let { eventType } = data;
    if (eventType === "listing_viewed") eventType = "listingViewed";
    if (eventType.startsWith("log_call_")) eventType = "click";
    if (!this.validEventTypes.includes(eventType)) {
      return;
    }

    const isCustomRange = !activeTab;
    const diff = moment(endAt).diff(moment(startAt), "months");

    if (isCustomRange) {
      if (eventType === "listingViewed") {
        activeTabCounters.views++;
      }
      if (eventType === "click") {
        activeTabCounters.calls++;
      }
      if (eventType === "top3") {
        activeTabCounters.top3++;
      }
      if (eventType === "top1") {
        activeTabCounters.top1++;
      }
    }
    for (let key of Object.keys(reports)) {
      if (reportCounters[key] && !isCustomRange) {
        if (eventType === "listingViewed") {
          reportCounters[key].views++;
        }
        if (eventType === "click") {
          reportCounters[key].calls++;
        }
        if (eventType === "top3") {
          reportCounters[key].top3++;
        }
        if (eventType === "top1") {
          reportCounters[key].top1++;
        }
      }

      let event = reports[key][eventType]?.find(
        (e) =>
          (e.year === moment().year() && e.month === moment().format("MMM")) ||
          e.day === moment().dayOfYear(),
      );

      reports[key][`${eventType}Value`]++;

      const defaultEvent = {
        result: "_result",
        table: 1,
        _measurement: eventType,
        _value: 1,
        value: 1,
        measurement: eventType,
      };

      if (event) {
        event.value++;
        event._value++;
      }

      if (!event) {
        if (key === "3m" || key === "6m" || key === "1y" || diff > 1) {
          event = [
            {
              ...defaultEvent,
              month: moment().format("MMM"),
              year: moment().year(),
              monthOfYear: moment().month(),
            },
          ];
        }

        if (key === "1w" || key === "1m" || diff <= 1) {
          event = [
            {
              ...defaultEvent,
              day: moment().dayOfYear(),
              label: moment().toISOString(),
            },
          ];
        }
      }
    }
    this.setState({
      reports,
      activeTabCounters,
      reportCounters,
    });
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    if (this.props.accountData) {
      socketHandler.handleSocket("subscribeToAccountAnalytics", {
        userId: this.props.accountId,
      });
      this.setState({ layoutTab: "account", reportInProgress: true });
      this.getAccountReportsCounters();
      this.getAccountReport();
      this.getAccountReportsTableData();
    } else {
      socketHandler.handleSocket("subscribeToListingAnalytics", {
        listingId: this.props.listingId,
      });

      this.setState({ layoutTab: "listing", reportInProgress: true });
      this.getListingReport();
      this.getListingReportsCounters();
      this.getListingReportsTableData();
    }
  };

  getAccountReportsCounters = () => {
    this.props
      .getAccountReportCounters(this.props.accountId)
      .then(({ data }) => {
        this.setState({
          reportCounters: data?.reports,
          activeTabCounters: data?.reports[this.state.activeTab || "1y"],
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log("ERR", err);
      });
  };

  getListingReportsCounters = () => {
    this.props
      .getListingReportCounters(this.props.listingId)
      .then(({ data }) => {
        this.setState({
          reportCounters: data?.reports,
          activeTabCounters: data?.reports[this.state.activeTab || "1y"],
          isLoading: false,
        });
      });
  };

  getAccountReport(customRange) {
    let { startAt, endAt } = this.state;
    if (!customRange) {
      startAt = null;
      endAt = null;
    }
    this.props
      .getAccountReport(this.props.accountId, startAt, endAt)
      .then(({ data }) => {
        let reports = formatReportForChart(data.report);
        let { activeTabCounters } = this.state;

        let newActiveTabCounters = activeTabCounters;

        if (customRange) {
          newActiveTabCounters = {};
          newActiveTabCounters.top3 = reports.customRange.top3Value;
          newActiveTabCounters.top1 = reports.customRange.top1Value;
          newActiveTabCounters.views = reports.customRange.listingViewedValue;
          newActiveTabCounters.calls = reports.customRange.callsValue;
        }
        this.setState({
          report: reports[this.state.activeTab || "customRange"],
          reports,
          reportInProgress: false,
          activeTabCounters: newActiveTabCounters,
          isLoading: false,
        });
      });
  }

  getListingReport(customRange) {
    let { startAt, endAt } = this.state;
    if (!customRange) {
      startAt = null;
      endAt = null;
    }
    this.props
      .getListingReport(this.props.listingId, startAt, endAt)
      .then(({ data }) => {
        let reports = formatReportForChart(data.report);
        let { activeTabCounters } = this.state;

        let newActiveTabCounters = activeTabCounters;

        if (customRange) {
          newActiveTabCounters = {};
          newActiveTabCounters.top3 = reports.customRange.top3Value;
          newActiveTabCounters.top1 = reports.customRange.top1Value;
          newActiveTabCounters.views = reports.customRange.listingViewedValue;
          newActiveTabCounters.calls = reports.customRange.callsValue;
        }

        this.setState({
          report: reports[this.state.activeTab || "customRange"],
          reports,
          reportInProgress: false,
          activeTabCounters: newActiveTabCounters,
          isLoading: false,
        });
      });
  }

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  collectDataForReportMenu = (event, call) => {
    this.setState({
      collectedDataForReportMenu: event.data,
      call,
    });
  };

  getListingReportsTableData = () => {
    if (this.gridApiGeneratedListing) {
      this.gridApiGeneratedListing.showLoadingOverlay &&
        this.gridApiGeneratedListing.showLoadingOverlay();
    }

    const { page, pageSize, sortModel } = this.state;
    this.props
      .getListingsReports({
        id: this.props?.listingData?.id || this.props.listingId,
        page: page,
        resultsPerPage: pageSize,
        sortModel,
      })
      .then((response) => {
        this.setState(
          {
            generatedListingTableData: response.data.reports.rows,
            generatedListingTotalRecords: response.data.reports.count,
            generatedListingPageCount: Math.ceil(
              response.data.reports.count / pageSize,
            ),
            overlayShown: false,
            columnDefsGeneratedReports: defineColumnDefsGeneratedReports(
              this.collectDataForCopy,
              this.collectDataForReportMenu,
              this.props.listingData,
            ),
          },
          () => {
            if (this.gridApiGeneratedListing) {
              this.gridApiGeneratedListing.hideOverlay &&
                this.gridApiGeneratedListing.hideOverlay();
            }
          },
        );
      })
      .catch((err) => console.log(err));
  };

  getAccountReportsTableData = () => {
    if (this.gridApiGeneratedAccount) {
      this.gridApiGeneratedAccount.showLoadingOverlay &&
        this.gridApiGeneratedAccount.showLoadingOverlay();
    }

    const { page, pageSize, sortModel } = this.state;
    this.props
      .getAccountReports({
        id: this.props?.accountData?.id,
        page: page,
        resultsPerPage: pageSize,
        sortModel,
      })
      .then((response) => {
        this.setState(
          {
            generatedListingTableData: response.data.reports.rows,
            generatedAccountTotalRecords: response.data.reports.count,
            generatedListingPageCount: Math.ceil(
              response.data.reports.count / pageSize,
            ),
            overlayShown: false,
            columnDefsGeneratedReports: defineColumnDefsGeneratedReports(
              this.collectDataForCopy,
              this.collectDataForReportMenu,
            ),
          },
          () => {
            if (this.gridApiGeneratedAccount) {
              this.gridApiGeneratedAccount.hideOverlay &&
                this.gridApiGeneratedAccount.hideOverlay();
            }
          },
        );
      })
      .catch((err) => console.log(err));
  };

  updateActiveTab = (tab) => {
    if (this.dateRef.current) {
      this.dateRef.current.flatpickr.clear();
    }
    console.log("TAB: ", tab);
    this.getSegmentedDates(tab);
    if (!this.state.activeTab) {
      return this.setState(
        {
          activeTab: tab,
          reportInProgress: true,
          showChart: false,
          report: null,
          isLoading: true,
        },
        () => {
          if (this.props.accountData) {
            this.getAccountReport();
            this.getAccountReportsCounters();
          } else {
            this.getListingReport();
            this.getListingReportsCounters();
          }
        },
      );
    }
    this.setState({
      activeTab: tab,
      activeTabCounters: this.state.reportCounters[tab],
      report: this.state.reports ? this.state.reports[tab] : null,
    });
  };

  toggleLayoutTab = (tab) => {
    if (this.state.layoutTab !== tab) {
      this.setState({ layoutTab: tab });
    }
  };

  onGenerateReport = () => {
    if (this.props.accountData) {
      this.props.callToAction("generate_report", false, {
        reportFrom: this.state.startAt,
        reportTo: this.state.endAt,
        includePaymentLink: this.state.includePaymentLink,
      });
    } else {
      this.toggleGenerateReportConfirmationModal();
    }
  };

  generateTabs = () => {
    let tabs;

    if (this.props.accountData) {
      tabs = [
        {
          id: "account",
          label: "Account report",
          type: "account",
        },
        {
          id: "generated",
          label: "Generated account reports",
          type: "generatedAccount",
          total: this.state.generatedAccountTotalRecords,
        },
      ];
    } else {
      tabs = [
        {
          id: "listing",
          label: "Listing report",
          type: "listing",
        },
        {
          id: "generated",
          label: "Generated listing reports",
          type: "generatedListing",
          total: this.state.generatedListingTotalRecords,
        },
      ];
    }

    return tabs.map((itm) => {
      return (
        <NavItem key={itm.type}>
          <NavLink
            className={classnames({
              active: this.state.layoutTab === itm.type,
            })}
            onClick={() => {
              this.toggleLayoutTab(itm.type);
            }}
          >
            {itm.label}{" "}
            {itm.total >= 0 && (
              <span>({Number(itm.total.toFixed(1)).toLocaleString()})</span>
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  confirmGenerateReport = () => {
    this.toggleGenerateReportSuccessfully();
  };

  handleReportName = (e) => {
    this.setState({
      reportName: e.target.value,
    });
  };

  handleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggleGenerateReportConfirmationModal = () => {
    const reportName = `${moment(this.state.startAt).format("LL")} - ${moment(
      this.state.endAt,
    ).format("LL")}`;

    this.setState({
      reportName,
      isOpenGenerateReportConfirmationModal:
        !this.state.isOpenGenerateReportConfirmationModal,
    });
  };

  toggleGenerateReportSuccessfully = () => {
    if (this.state.inProgress) return;
    this.setState({
      inProgress: true,
    });

    const report = { customRange: generateReportPayload(this.state.report) };

    let reportPayload = {
      title: this.state.reportName,
      reportFrom: this.state.startAt,
      reportTo: this.state.endAt,
      userId: this.props.accountData?.id || this.props.listingData?.userId,
      adId: this.props.listingData ? this.props.listingData?.id : null,
      expirationDate: moment().add(37, "day"),
      reportData: JSON.stringify(report),
      generatedBy: `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
      includePaymentLink: this.state.includePaymentLink,
    };

    this.props
      .generateListingReport(reportPayload)
      .then((res) => {
        this.refetchReports();
        this.setState({
          isOpenGenerateReportSuccessfully:
            !this.state.isOpenGenerateReportSuccessfully,
        });

        const reportId = res.data.report.id;

        let activityPayload = {
          adId: this.props.listingData?.id,
          adReportId: reportId,
          status: 1,
          activityType: this.props.activitiesTypes.GENERATED_LISTING_REPORT,
          activity: activities.generatedListingReport.replace(
            "{{admin_name}}",
            `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
          ),
          adminUserId: this.props.userData.loggedInUser.id,
          iconName: "Report",
        };

        this.props.createActivity(activityPayload);
        this.toggleGenerateReportConfirmationModal();
      })
      .catch((err) => {
        console.log("err: ", err);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  resetApiGeneratedListing = () => {
    this.gridApiGeneratedListing = null;
  };

  onGridReadyGeneratedListing = (params) => {
    this.gridApiGeneratedListing = params.api;
    this.gridColumnApiGeneratedListing = params.columnApi;

    if (localStorage.getItem("generatedListingColumnState") !== null) {
      this.gridColumnApiGeneratedListing &&
        this.gridColumnApiGeneratedListing.setColumnState(
          JSON.parse(localStorage.getItem("generatedListingColumnState")),
        );
    }
  };

  onGridReadyGeneratedAccount = (params) => {
    this.gridApiGeneratedAccount = params.api;
    this.gridColumnApiGeneratedAccount = params.columnApi;

    if (localStorage.getItem("generatedListingColumnState") !== null) {
      this.gridColumnApiGeneratedListing &&
        this.gridColumnApiGeneratedListing.setColumnState(
          JSON.parse(localStorage.getItem("generatedListingColumnState")),
        );
    }
  };

  onPageChanged = ({ selected }) => {
    if (this.gridApiGeneratedAccount) {
      this.gridApiGeneratedAccount.showLoadingOverlay &&
        this.gridApiGeneratedAccount.showLoadingOverlay();
    }

    if (this.gridApiGeneratedListing) {
      this.gridApiGeneratedListing.showLoadingOverlay &&
        this.gridApiGeneratedListing.showLoadingOverlay();
    }

    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      if (this.state.layoutTab === "generatedListing") {
        this.getListingReportsTableData();
      }

      if (this.state.layoutTab === "generatedAccount") {
        this.getAccountReportsTableData();
      }
    });
  };

  refetchReports = () => {
    this.setState({ page: 1 }, () => {
      if (
        this.state.layoutTab === "generatedListing" ||
        this.state.layoutTab === "listing"
      ) {
        this.getListingReportsTableData();
      }

      if (
        this.state.layoutTab === "generatedAccount" ||
        this.state.layoutTab === "account"
      ) {
        this.getAccountReportsTableData();
      }
    });
  };

  saveStateGeneratedListing = () => {
    let generatedListingColumnState =
      this.gridColumnApiGeneratedListing.getColumnState();

    localStorage.setItem(
      "generatedListingColumnState",
      JSON.stringify(generatedListingColumnState),
    );
  };

  onRowClicked = (e) => {
    if (
      e.api.focusedCellController.focusedCellPosition.column.colId === "actions"
    )
      return;

    if (this.props.accountId)
      this.props.previewAccountReport(e.data.id, e.data.title);
    if (this.props.listingId)
      this.props.previewListingReport(e.data.id, e.data.title);
  };

  filterSize = (type, val) => {
    this.setState(
      {
        page: 1,
        pageSize: val,
      },
      () => {
        if (this.state.layoutTab === "generatedListing") {
          this.getListingReportsTableData();
        }

        if (this.state.layoutTab === "generatedAccount") {
          this.getAccountReportsTableData();
        }
      },
    );
  };

  handleDate = (values) => {
    if (values.length === 2) {
      if (
        Math.abs(
          moment(values[0]).diff(moment(values[1]).subtract(1, "day"), "years"),
        ) >= 1
      ) {
        this.setState({ errorDateRange: true });
        return;
      }

      this.setState(
        {
          errorDateRange: false,
          startAt: moment(values[0]).add(1, "days").toISOString().split("T")[0],
          endAt: moment(values[1]).add(1, "days").toISOString().split("T")[0],
          report: null,
          activeTab: null,
          isLoading: true,
          reportInProgress: true,
          showChart: false,
        },
        () => {
          if (this.props.accountData) return this.getAccountReport(true);
          this.getListingReport(true);
        },
      );
    }
  };

  clearFlatPickr = () => {
    if (this.dateRef.current) {
      this.dateRef.current.flatpickr.clear();
    }
    this.setState({ startAt: null, endAt: null }, () => {
      this.updateActiveTab(this.state.activeTab || "1y");
      // this.getReport();
    });
  };

  setActiveSubMenu = (value) => {
    if (value !== this.state.activeSubMenu) {
      this.setState({
        activeSubMenu: value,
      });
    } else {
      this.setState({ activeSubMenu: null });
    }
  };

  toggleVisibleToVendors = ({ value }) => {
    this.setState(
      {
        collectedDataForReportMenu: {
          ...this.state.collectedDataForReportMenu,
          visibleToVendors: value,
        },
      },
      () => {
        if (this.props.listingData) {
          this.getListingReportsTableData();
        } else {
          this.getAccountReportsTableData();
        }
      },
    );
  };

  onReportRemove = () => {
    this.setState(
      {
        page: 1,
      },
      () => {
        if (this.state.layoutTab === "generatedListing") {
          this.getListingReportsTableData();
          hideMenu();
        }

        if (this.state.layoutTab === "generatedAccount") {
          this.getAccountReportsTableData();
          hideMenu();
        }
      },
    );
  };

  onSortChanged = (params) => {
    console.log("SORT MODEL", params.api.getSortModel());

    this.setState(
      {
        sortModel: params.api.getSortModel(),
      },
      () => {
        if (this.state.layoutTab === "generatedListing") {
          this.getListingReportsTableData();
        }

        if (this.state.layoutTab === "generatedAccount") {
          this.getAccountReportsTableData();
        }
      },
    );
  };

  getSegmentedDates = (type) => {
    switch (type) {
      case "1m":
        this.setState({
          startAt: moment().subtract(1, "months").toISOString().split("T")[0],
          endAt: moment().toISOString().split("T")[0],
        });
        break;

      case "3m":
        this.setState({
          startAt: moment().subtract(3, "months").toISOString().split("T")[0],
          endAt: moment().toISOString().split("T")[0],
        });
        break;

      case "6m":
        this.setState({
          startAt: moment().subtract(6, "months").toISOString().split("T")[0],
          endAt: moment().toISOString().split("T")[0],
        });
        break;

      case "1y":
        this.setState({
          startAt: moment().subtract(1, "years").toISOString().split("T")[0],
          endAt: moment().toISOString().split("T")[0],
        });
        break;

      default:
        this.setState({
          startAt: moment().subtract(1, "weeks").toISOString().split("T")[0],
          endAt: moment().toISOString().split("T")[0],
        });
    }
  };

  getCollectedDataForReportModal = () => {
    return this.state.collectedDataForReportMenu;
  };

  handleShowChart = () => {
    this.setState({
      showChart: true,
    });
  };

  render() {
    const {
      isLoading,
      activeTab,
      layoutTab,
      reportName,
      isOpenGenerateReportConfirmationModal,
      isOpenGenerateReportSuccessfully,
      pageSize,
      page,
      generatedListingPageCount,
      generatedListingTableData,
      columnDefsGeneratedReports,
      defaultColDef,
      report,
      showChart,
      activeTabCounters,
      startAt,
      endAt,
    } = this.state;

    const tabs = [
      {
        label: "1Y",
        type: "1y",
      },
      {
        label: "6M",
        type: "6m",
      },
      {
        label: "3M",
        type: "3m",
      },
      {
        label: "1M",
        type: "1m",
      },
    ];

    const permissionDateRagePicker = PERMISSIONS.ADDITIONAL_FEATURES;
    let havePermissionForCalls = !checkPermissions(
      [PERMISSIONS.ADDITIONAL_FEATURES],
      this.props.adminUserPermissions,
      [SUB_PERMISSIONS[PERMISSIONS.ADDITIONAL_FEATURES].VIEW_CALS_ON_REPORT],
    );

    let subPermissionDateRagePicker = [
      SUB_PERMISSIONS[permissionDateRagePicker]
        .LISTING_REPORT_CUSTOM_DATE_RANGE,
    ];

    if (this.props.accountData) {
      subPermissionDateRagePicker = [
        SUB_PERMISSIONS[permissionDateRagePicker]
          .ACCOUNT_REPORT_CUSTOM_DATE_RANGE,
      ];
    }

    return (
      <>
        <Card>
          <CardBody className="fts-listing-tab-wrapper account-report">
            {isLoading ? (
              <Spinner size="sm" />
            ) : (
              <div>
                <TitleRow title="REPORTING" className="header-report-controls">
                  {(layoutTab === "account" || layoutTab === "listing") && (
                    <div className="d-flex">
                      <div className="nav">
                        {tabs.map((itm) => {
                          return (
                            <button
                              key={itm.type}
                              type="button"
                              className={`tab ${
                                activeTab === itm.type
                                  ? "selected"
                                  : "unselected"
                              }`}
                              onClick={() => {
                                this.updateActiveTab(itm.type);
                              }}
                            >
                              {itm.label}
                            </button>
                          );
                        })}
                      </div>

                      <PermissionGate
                        permissions={[PERMISSIONS.ADDITIONAL_FEATURES]}
                        subPermissions={subPermissionDateRagePicker}
                      >
                        <div className="range-picker">
                          <div className="range">
                            <div className="position-relative has-icon-right">
                              <div className="fts-date-and-time">
                                <div
                                  id="datepickerWrapperFrom"
                                  className="u-datepicker input-group"
                                >
                                  <Flatpickr
                                    ref={this.dateRef}
                                    placeholder="Select date range"
                                    // className="fts-date-picker form-control date-picker"
                                    className={classNames(
                                      "fts-date-picker form-control date-picker",
                                      {
                                        "invalid-formik-input":
                                          this.state.errorDateRange,
                                      },
                                    )}
                                    options={{
                                      mode: "range",
                                      dateFormat: "M d, Y",
                                      locale: {
                                        rangeSeparator: " - ",
                                      },
                                      defaultDate: [
                                        startAt && !activeTab
                                          ? moment(startAt).format("ll")
                                          : null,
                                        endAt && !activeTab
                                          ? moment(endAt).format("ll")
                                          : null,
                                      ],
                                    }}
                                    onChange={(values) => {
                                      if (values.length === 2) {
                                        this.handleDate(values);
                                      }
                                    }}
                                  />
                                </div>
                              </div>

                              {!activeTab ? (
                                <div
                                  className="form-control-position clear-calendar-button"
                                  onClick={() => {
                                    if (this.dateRef?.current?.flatpickr) {
                                      this.clearFlatPickr();
                                    }
                                  }}
                                >
                                  <Icon name="ClearCalendar" size={16} />
                                </div>
                              ) : (
                                <div className="form-control-position">
                                  <Icon name="Calendar" size={17} />
                                </div>
                              )}
                            </div>

                            {this.state.errorDateRange && (
                              <div className="formik-validation-error">
                                Invalid date range: Max 365 days
                              </div>
                            )}
                          </div>
                        </div>
                      </PermissionGate>
                    </div>
                  )}

                  {layoutTab === "generatedAccount" && (
                    <Button
                      onClick={() => {
                        this.onGenerateReport();
                      }}
                      color="primary"
                      className="generate-report-button"
                    >
                      <span className="d-flex align-items-center">
                        <Icon name="Plus" size="24" color={"greyscale-0"} />
                        <div>Generate Report</div>
                      </span>
                    </Button>
                  )}
                </TitleRow>

                <div className="account-report-content nav-horizontal">
                  <Nav tabs>{this.generateTabs()}</Nav>
                  <TabContent activeTab={this.state.layoutTab}>
                    <TabPane tabId={"account"}>
                      <InfoMessage
                        message={`The analytics below take into account combined active analytics for all your ${this.props.counts?.listingsCount} listings.`}
                      />
                      <div>
                        <ExposureChart
                          havePermissionForCalls={havePermissionForCalls}
                          report={report}
                          activeTab={activeTab}
                          showChart={showChart}
                          buttonAction={this.handleShowChart}
                          activeTabCounters={activeTabCounters}
                          reportFrom={startAt}
                          reportTo={endAt}
                        />
                        {/*<UserEngagementChart*/}
                        {/*  report={report}*/}
                        {/*  activeTab={activeTab}*/}
                        {/*/>*/}
                        <div className="d-flex align-items-center justify-content-end mt-1">
                          <Button
                            onClick={() => {
                              this.onGenerateReport();
                            }}
                            color="primary"
                            className="generate-report-button ml-1"
                          >
                            <span className="d-flex align-items-center">
                              <Icon
                                name="Plus"
                                size="24"
                                color={"greyscale-0"}
                              />
                              <div>Custom Report</div>
                            </span>
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={"listing"}>
                      <div className="FTS-listing-info-report">
                        <div className="FTS-chart-listing-info-wrapper-top">
                          <div>
                            <h2 className="FTS-chart-listing-header mr-1">
                              {this.props.listingData?.name}
                            </h2>
                          </div>
                        </div>
                        <div className="FTS-chart-listing-info">
                          <span className="mr-1">
                            <MapPin className="mr-1" size={20} />
                            {this.props.listingData?.city},{" "}
                            {this.props.listingData?.state}
                          </span>

                          <span>
                            {" "}
                            <Phone size={20} />{" "}
                            {this.props.listingData?.phoneNumber}
                          </span>
                        </div>
                      </div>
                      <div>
                        <ExposureChart
                          havePermissionForCalls={havePermissionForCalls}
                          report={report}
                          activeTab={activeTab}
                          showChart={showChart}
                          buttonAction={this.handleShowChart}
                          activeTabCounters={activeTabCounters}
                          reportFrom={startAt}
                          reportTo={endAt}
                        />
                        {/* <UserEngagementChart
                          report={report}
                          activeTab={activeTab}
                        /> */}
                        <div className="d-flex align-items-center justify-content-end mt-1">
                          <Button
                            onClick={() => {
                              this.onGenerateReport();
                            }}
                            color="primary"
                            className="generate-report-button ml-1"
                          >
                            <span className="d-flex align-items-center">
                              <Icon
                                name="Plus"
                                size="24"
                                color={"greyscale-0"}
                              />
                              <div>Generate Report</div>
                            </span>
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="generatedAccount">
                      <FTSDataTable
                        tableName={"generatedAccount"}
                        isSearch
                        reporting
                        pageSizes={this.pageSizes}
                        columnDefs={columnDefsGeneratedReports}
                        rowData={generatedListingTableData}
                        totalRecords={this.state.generatedAccountTotalRecords}
                        resetApi={this.resetApiGeneratedListing}
                        onGridReadyInit={this.onGridReadyGeneratedAccount}
                        saveState={this.saveStateGeneratedListing}
                        onSortChanged={this.onSortChanged}
                        currentPageSize={pageSize}
                        currentPage={page}
                        pageCount={generatedListingPageCount}
                        onRowClicked={this.onRowClicked}
                        onPageChange={this.onPageChanged}
                        onScroll={hideMenu}
                        filterSize={this.filterSize}
                        {...{
                          defaultColDef,
                        }}
                      />

                      <ContextMenu
                        style={{ minWidth: 250 }}
                        id="contextMenuAccountReports"
                        preventHideOnScroll={false}
                      >
                        <MenuItem
                          onClick={() =>
                            navigator.clipboard.writeText(
                              this.state.collectedDataForCopy,
                            )
                          }
                        >
                          <FaRegCopy size={18} className="copy" />
                          <span className={"ml-1"}>Copy</span>
                        </MenuItem>
                      </ContextMenu>

                      <ContextMenu
                        ref={this.contextMenuRef}
                        style={{ minWidth: 250 }}
                        id="contextMenuAccountReportUpdate"
                        preventHideOnScroll={false}
                        onHide={() => {
                          this.setActiveSubMenu(null);
                        }}
                      >
                        <ReportDropdown
                          adminPermissions={this.props.adminPermissions}
                          id="visibleToVendorsAccount"
                          listingData={this.props.listingData}
                          accountData={this.props.accountData}
                          collectedDataForReportMenu={
                            this.state.collectedDataForReportMenu
                          }
                          getCollectedDataForReportModal={
                            this.getCollectedDataForReportModal
                          }
                          activeSubMenu={this.state.activeSubMenu}
                          setActiveSubMenu={this.setActiveSubMenu}
                          onReportRemove={this.onReportRemove}
                          handleExtendValidityMenu={
                            this.handleExtendValidityMenu
                          }
                          getListingReportsTableData={
                            this.getListingReportsTableData
                          }
                          getAccountReportsTableData={
                            this.getAccountReportsTableData
                          }
                          toggleVisibleToVendors={this.toggleVisibleToVendors}
                        />
                      </ContextMenu>
                    </TabPane>
                    <TabPane tabId="generatedListing">
                      <FTSDataTable
                        tableName={"generatedListing"}
                        isSearch
                        reporting
                        pageSizes={this.pageSizes}
                        columnDefs={columnDefsGeneratedReports}
                        rowData={generatedListingTableData}
                        totalRecords={this.state.generatedListingTotalRecords}
                        resetApi={this.resetApiGeneratedListing}
                        onGridReadyInit={this.onGridReadyGeneratedListing}
                        saveState={this.saveStateGeneratedListing}
                        onSortChanged={this.onSortChanged}
                        currentPageSize={pageSize}
                        currentPage={page}
                        pageCount={generatedListingPageCount}
                        onRowClicked={this.onRowClicked}
                        onPageChange={this.onPageChanged}
                        onScroll={hideMenu}
                        filterSize={this.filterSize}
                        {...{
                          defaultColDef,
                        }}
                      />

                      <ContextMenu
                        style={{ minWidth: 250 }}
                        id="contextMenuListingReports"
                        preventHideOnScroll={false}
                      >
                        <MenuItem
                          onClick={() =>
                            navigator.clipboard.writeText(
                              this.state.collectedDataForCopy,
                            )
                          }
                        >
                          <FaRegCopy size={18} className="copy" />
                          <span className={"ml-1"}>Copy</span>
                        </MenuItem>
                      </ContextMenu>

                      <ContextMenu
                        ref={this.contextMenuRef}
                        style={{ minWidth: 250 }}
                        id="contextMenuListingReportUpdate"
                        preventHideOnScroll={false}
                        onHide={() => {
                          this.setActiveSubMenu(null);
                        }}
                      >
                        <ReportDropdown
                          adminPermissions={this.props.adminPermissions}
                          id="visibleToVendorsListing"
                          toggleVisibleToVendors={this.toggleVisibleToVendors}
                          listingData={this.props.listingData}
                          accountData={this.props.accountData}
                          collectedDataForReportMenu={
                            this.state.collectedDataForReportMenu
                          }
                          getCollectedDataForReportModal={
                            this.getCollectedDataForReportModal
                          }
                          activeSubMenu={this.state.activeSubMenu}
                          setActiveSubMenu={this.setActiveSubMenu}
                          onReportRemove={this.onReportRemove}
                          handleExtendValidityMenu={
                            this.handleExtendValidityMenu
                          }
                          getListingReportsTableData={
                            this.getListingReportsTableData
                          }
                          getAccountReportsTableData={
                            this.getAccountReportsTableData
                          }
                        />
                      </ContextMenu>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            )}
          </CardBody>
        </Card>

        <GenerateReportConfirmation
          isOpen={isOpenGenerateReportConfirmationModal}
          toggleGenerateReportConfirmationModal={
            this.toggleGenerateReportConfirmationModal
          }
          includePaymentLink={this.state.includePaymentLink}
          confirmModal={this.confirmGenerateReport}
          inProgress={this.state.inProgress}
          reportName={reportName}
          handleReportName={this.handleReportName}
          toggleIncludePaymentLink={() =>
            this.setState({
              includePaymentLink: !this.state.includePaymentLink,
            })
          }
        />

        <GenerateReportSuccess
          isOpen={isOpenGenerateReportSuccessfully}
          closeModal={() => {
            this.setState({
              isOpenGenerateReportSuccessfully:
                !isOpenGenerateReportSuccessfully,
            });
          }}
          confirmModal={() => {
            this.setState({
              isOpenGenerateReportSuccessfully:
                !isOpenGenerateReportSuccessfully,
            });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
    adminPermissions: state.auth.login.permissions,
    counts: state.accountCounts,
  };
};

const mapDispatchToProps = {
  getListingsReports,
  getAccountReports,
  getListingReport,
  getAccountReport,
  generateListingReport,
  createActivity,
  getAccountReportCounters,
  getListingReportCounters,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountReport);
