import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,
  TabContent,
  TabPane,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import "../../../assets/scss/pages/authentication.scss";
import Icon from "../../../components/fts/Icons";
import * as Yup from "yup";

import { connect } from "react-redux";
import { Formik } from "formik";
import { verifyMfaCode } from "../../../redux/actions/auth/loginActions";
import { toast } from "react-toastify";
import FTSAxios from "../../../axios/fts.instance";
import FTSCookies, { TEMPORARY_TOKEN } from "../../../cookies";
import { history } from "../../../history";
import collectEssentialData from "../../../axios/collect-data";

const services = [
  {
    label: "Mobile repair",
    icon: "MobileRepair",
  },
  {
    label: "Parts",
    icon: "Parts",
  },
  {
    label: "Towing",
    icon: "TowingNew",
  },
  {
    label: "Tires",
    icon: "Tires",
  },
  {
    label: "Repair",
    icon: "Repair",
  },
  {
    label: "Dealers & more",
    icon: "Dealers",
  },
];

class AdminMfa extends React.Component {
  formRef = React.createRef();

  state = {
    activeTab: "1",
  };
  constructor() {
    super();
  }

  initialData = {
    mfaCode: "",
  };

  validationSchema = Yup.object().shape({
    mfaCode: Yup.string()
      .min(6, "Invalid code")
      .required("Mfa code is required!"),
  });

  handleSubmit = async (values) => {
    try {
      if (this.state.inProgress) {
        return;
      }
      console.log(values);
      this.setState({ inProgress: true });
      await this.props.verifyMfaCode(values);
      await FTSCookies.remove(TEMPORARY_TOKEN);
      var loggedInUser;
      let res = await FTSAxios.get("admin/user");
      loggedInUser = res?.data?.user;
      this.props.mapDispatchToProps({
        type: "LOGIN_WITH_JWT",
        payload: { loggedInUser, loggedInWith: "jwt" },
      });

      await collectEssentialData(this.props.mapDispatchToProps);
      return history.push("/");
    } catch (e) {
      console.log(e);
      const { response } = e;
      toast.error(
        response?.data?.error?.message ||
          response?.data?.errors?.message ||
          response?.data?.message ||
          "Oops! Something went wrong. Please try again.",
      );
    } finally {
      this.setState({ inProgress: false });
    }
    // const token = extractParameter("token");
    // this.setState({ inProgress: true });
    //
    // this.props
    //   .updatePassword({
    //     token,
    //     password: values.password,
    //     repeatPassword: values.repeatPassword,
    //   })
    //   .then(() => {
    //     history.push("/login");
    //     toast.success("Your password has been updated");
    //   })
    //   .catch((err) => {
    //     const { response } = err;
    //     toast.error(response?.data?.error?.message || "Something went wrong!");
    //   })
    //   .finally(() => {
    //     this.setState({ inProgress: false });
    //   });
  };
  render() {
    return (
      <div className="h-100">
        <Row className="m-0 justify-content-center">
          <Col className="d-flex" md="6">
            <div className="FTS-services-wrapper">
              <div>
                <div className="FTS-services-label">
                  <div className="FTS-services-label-icon">
                    <Icon name="BgElement" size={70} strokeOpacity={0} />
                  </div>
                  <div className="FTS-services-label-text">
                    <span>Find your</span>
                    <span>nearest service</span>
                  </div>
                </div>
                <div className="FTS-services">
                  {services.map((service, index) => {
                    return (
                      <div className="FTS-single-service d-flex" key={index}>
                        <Icon name={service.icon} size={40} color={"#FFF"} />
                        <span>{service.label}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
          <Col md="4" className="d-flex">
            <Card className="bg-authentication login-card rounded-0 mb-0 w-100 justify-content-center">
              <Row className="m-0">
                <Col lg="12" md="12" className="p-0 login-card">
                  <Card className="rounded-0 mb-0 px-2 mb-2 mt-2 login-tabs-container">
                    <CardHeader className="pb-1">
                      <CardTitle>
                        <h4 className="mb-0">Two step authentication</h4>
                      </CardTitle>
                    </CardHeader>
                    <p className="px-2 auth-title">
                      To continue in dashboard, please use code from email
                    </p>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <>
                          <CardBody className="pt-1">
                            <Formik
                              initialValues={this.initialData}
                              innerRef={this.formRef}
                              onSubmit={this.handleSubmit}
                              validationSchema={this.validationSchema}
                            >
                              {({
                                touched,
                                values,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                              }) => {
                                // if (errors.password || errors.repeatPassword)
                                //   this.setState({ errors });
                                return (
                                  <Form onSubmit={handleSubmit}>
                                    <FormGroup className="form-label-group position-relative has-icon-left">
                                      <Input
                                        id="mfaCode"
                                        name={"mfaCode"}
                                        type="password"
                                        placeholder="Code"
                                        value={values?.mfaCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                      />
                                      <div className="form-control-position">
                                        <Icon name="Password" size={19} />
                                      </div>
                                      <Label>Code</Label>
                                    </FormGroup>
                                    {errors.mfaCode && touched.mfaCode ? (
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          color: "#C73A3A",
                                          marginTop: "-1rem",
                                        }}
                                      >
                                        {errors.mfaCode}
                                      </p>
                                    ) : null}
                                  </Form>
                                );
                              }}
                            </Formik>
                            <div className="d-flex justify-content-between">
                              <Button
                                onClick={() => {
                                  if (this.formRef?.errors !== {}) {
                                    this.formRef.current.handleSubmit();
                                  } else {
                                    this.handleSubmit();
                                  }
                                }}
                                color="primary"
                                type="submit"
                                className="w-100"
                                disabled={this.state.inProgress}
                              >
                                {this.state.inProgress
                                  ? "Please wait..."
                                  : "Continue"}
                              </Button>
                            </div>
                          </CardBody>
                        </>
                      </TabPane>
                    </TabContent>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => dispatch;

export default connect(null, { verifyMfaCode, mapDispatchToProps })(AdminMfa);
