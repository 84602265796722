import React, { Component } from "react";
import { Label, FormGroup, Button } from "reactstrap";
import { X } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { ChromePicker } from "react-color";
import TypePicker from "../../../components/fts/type-picker";

const formSchema = Yup.object().shape({
  description: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  color: Yup.string().required("Required"),
});

class TodoTypesSidebar extends Component {
  addNew = false;

  initialData = {
    id: "",
    description: "",
    type: "",
    color: "#000000",
  };

  componentDidUpdate() {
    this.addNew = false;
  }

  handleSubmit = this.props.onTodoTypeUpdate;

  render() {
    const { show, handleSidebar, data } = this.props;
    const initValues = data ? { ...data } : this.initialData;

    return (
      <div
        className={classnames("data-list-sidebar", {
          show: show,
        })}
      >
        <div className="data-list-sidebar-header px-2 d-flex justify-content-between">
          <h4>
            {data !== null
              ? "Update todo/note type"
              : "Submit new todo/note type"}
          </h4>
          <X size={20} onClick={() => handleSidebar(false, true)} />
        </div>
        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          validationSchema={formSchema}
          initialValues={initValues}
        >
          {({ errors, touched }) => (
            <Form style={{ height: "100%" }}>
              <PerfectScrollbar
                className="data-list-fields px-2 mt-3"
                options={{ wheelPropagation: false }}
              >
                <FormGroup>
                  <Label for="cat-id">ID</Label>
                  <Field
                    disabled
                    placeholder="eg. 16"
                    name="id"
                    className="form-control"
                    id="cat-id"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-label">Description</Label>
                  <Field
                    name="description"
                    placeholder="eg. Payment"
                    id="cat-label"
                    className={`form-control ${
                      errors.description && touched.description && "is-invalid"
                    }`}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-label">Type</Label>
                  <Field
                    name="type"
                    id="cat-label"
                    className={`form-control ${
                      errors.type && touched.type && "is-invalid"
                    }`}
                  >
                    {({ meta, form, field: { value } }) => {
                      return (
                        <TypePicker
                          placeholder="Select type..."
                          isClearable={false}
                          id="cat-label"
                          className={`form-control ${
                            errors.description &&
                            touched.description &&
                            "is-invalid"
                          }`}
                          onStateChange={(option) => {
                            form.setFieldValue("type", option?.value);
                          }}
                          {...{ meta, value }}
                        />
                      );
                    }}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Label for="cat-quick">Color</Label>
                  <Field
                    name="color"
                    className={`form-control ${
                      errors.description && touched.description && "is-invalid"
                    }`}
                  >
                    {({ meta, form, field: { value } }) => {
                      return (
                        <ChromePicker
                          color={value}
                          onChange={(updatedColor) => {
                            form.setFieldValue("color", updatedColor.hex);
                          }}
                          {...{
                            meta,
                            form,
                          }}
                        />
                      );
                    }}
                  </Field>
                </FormGroup>
              </PerfectScrollbar>

              <div className="data-list-sidebar-footer px-2 d-flex justify-content-end align-items-center mt-1">
                <Button color="primary" type="submit">
                  {data !== null ? "Update" : "Submit"}
                </Button>
                <Button
                  type="button"
                  className="ml-1"
                  color="danger"
                  outline
                  onClick={() => handleSidebar(false, true)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default TodoTypesSidebar;
