import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountAltContactValidator } from "../Validators/accountAltContactValidator";
import { AltContactForm } from "../../dist/ui/src/components/react/AltContactForm";
import {
  selectAltContact,
  selectAltContactInvalidFormFields,
  selectAltContacts,
} from "../../../../redux/reducers/v2/altContacts";
import {
  deleteAltContactAction,
  setAltContactAction,
  setAltContactInvalidFormFieldsAction,
  setAltContactsAction,
  updateAltContactAction,
} from "../../../../redux/actions/v2/altContacts";
import { updateAltContacts } from "../Helpers";
import { mobileOptIn, primaryOptIn } from "../constants";
import useAltContactCheckboxValidation from "../hooks/useAltContactCheckboxValidation";

export const AccountAltContactFormHandler = ({
  closeAltContactForm,
  submitButtonTitle,
  isEdit,
  title,
}) => {
  const invalidFormFields = useSelector(selectAltContactInvalidFormFields);
  const altContact = useSelector(selectAltContact) || {};
  const altContacts = useSelector(selectAltContacts) || [];
  const dispatch = useDispatch();

  const isMobileValid = useAltContactCheckboxValidation(
    "altContactMobileNumber",
    mobileOptIn,
  );
  const isEmailValid = useAltContactCheckboxValidation(
    "altContactEmail",
    primaryOptIn,
  );

  const handleCheckboxChange = useCallback(
    (name, value, category) => {
      if (category === "mobilePhone" && !isMobileValid) return;
      if (category === "email" && !isEmailValid) return;
      dispatch(
        setAltContactAction({
          ...altContact,
          [name]: !value,
        }),
      );
    },
    [altContact, isMobileValid, isEmailValid],
  );

  const handleOnChange = useCallback(
    (event, name) => {
      const newValue = event.target.value;
      dispatch(setAltContactAction({ ...altContact, [name]: newValue }));
    },
    [altContact],
  );

  const onRemoveAltContact = useCallback(() => {
    if (altContact.isSaved) dispatch(deleteAltContactAction(altContact.id));

    const filteredAltContacts = altContacts.filter(
      (contact) => contact.id !== altContact.id,
    );

    dispatch(setAltContactsAction(filteredAltContacts));
    closeAltContactForm();
  }, [closeAltContactForm, altContact, altContacts]);

  const onSubmit = useCallback(async () => {
    try {
      await accountAltContactValidator.validate(altContact);
      dispatch(setAltContactAction(altContact));
      if (isEdit && altContact.isSaved)
        dispatch(updateAltContactAction(altContact));
      const mappedContacts = updateAltContacts({
        isEdit,
        altContacts,
        altContact,
      });
      dispatch(setAltContactsAction(mappedContacts));
      closeAltContactForm();
    } catch (e) {
      if (e.name !== "ValidationError") return;
      dispatch(setAltContactInvalidFormFieldsAction({ [e.path]: e.errors[0] }));
    }
  }, [isEdit, closeAltContactForm, altContact, altContacts]);

  return (
    <AltContactForm
      title={title}
      isEdit={isEdit}
      onSubmit={onSubmit}
      altContactData={altContact}
      handleOnChange={handleOnChange}
      invalidFormFields={invalidFormFields}
      submitButtonTitle={submitButtonTitle}
      onRemoveAltContact={onRemoveAltContact}
      closeAltContactForm={closeAltContactForm}
      handleCheckboxChange={handleCheckboxChange}
    />
  );
};
