/* eslint-disable indent */
/* eslint-disable react/display-name */
import React from "react";
import moment from "moment";
import capitalize from "../../../utility/capitalize";

import { formatRawDate, toMomentDate } from "../../../configs/dateConfig";

import FTSBadge from "../../../components/fts/fts-badge";
import { ContextMenuTrigger } from "react-contextmenu";
import FTSSquareBadge from "../../../components/fts/fts-square-badge";
import SalesEmployeePicker from "../../../components/fts/sales-employee-picker/";
import { Col } from "reactstrap";
import { adminStatusBasedOnID } from "../accounts/columns";
import { checkPermissions } from "../../../utility/permissionsCheck";

const statusBasedOnID = (status) => (status ? "Enabled" : "Disabled");

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
      <div className="ag-date-table__time">
        {toMomentDate(date).format("[at] h:mm a")}
      </div>
    </div>
  );
};

export const defineAccountColumns = (
  types,
  collectDataForCopy,
  togglePopover,
  list,
  refreshTable,
) => {
  return [
    {
      headerName: "Account ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 125,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.id}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <span>{params.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last contact date",
      field: "adminLastContactDate",
      sortable: true,
      unSortIcon: true,
      width: 165,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const noContactDate = !params.data.lastContactedBy;

        return (
          <>
            {noContactDate ? (
              <ContextMenuTrigger
                data="No contact date"
                collect={collectDataForCopy}
                id="contextMenuLeadsPool"
              >
                <span>No contact date</span>
              </ContextMenuTrigger>
            ) : (
              <ContextMenuTrigger
                data={moment(params.data.adminLastContactDate).format("lll")}
                collect={collectDataForCopy}
                id="contextMenuLeadsPool"
              >
                <span>
                  <TableDate date={params.data.adminLastContactDate} />
                </span>
              </ContextMenuTrigger>
            )}
          </>
        );
      },
    },
    {
      headerName: "Last contact by",
      field: "lastContactedBy",
      sortable: true,
      unSortIcon: true,
      width: 165,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const firstName = params.data.lastUpdatedByAdmin?.firstName;
        const lastName = params.data.lastUpdatedByAdmin?.lastName;
        return (
          <ContextMenuTrigger
            data={`${firstName} ${lastName}`}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            {firstName} {lastName}
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Source",
      field: "sourceForSort",
      sortable: true,
      unSortIcon: true,
      width: 125,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.source}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <span>{params.data.source}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "companyNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 250,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.companyName}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <span style={{ overflow: "hidden" }}>
              {params.data.companyName}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <span>{`${params.data.city}`}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account label",
      field: "adminStatusForSort",
      sortable: true,
      unSortIcon: true,
      width: 165,
      cellRendererFramework: (params) => {
        let accountLabel;

        const now = moment().utc();
        const days = now.diff(moment(params.data.createdAt).utc(), "days");

        if (
          days <= 5 &&
          moment(params.data.createdAt).utc() >
            moment(params.data.adminLastContactDate).utc()
        ) {
          accountLabel = {
            text: "new",
            backgroundColor: "#DFF7FF",
            textColor: "#242D34",
          };
        } else {
          accountLabel = {
            text: params.data.adminStatusForSort,
            backgroundColor: "#EDF2F3",
            textColor: "#242D34",
          };
        }

        return (
          <ContextMenuTrigger
            data={capitalize(accountLabel.text)}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <FTSSquareBadge
              text={accountLabel.text}
              textColor={accountLabel.textColor}
              backgroundColor={accountLabel.backgroundColor}
            />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Assigned to",
      field: "adminAccountOwner",
      sortable: true,
      unSortIcon: true,
      width: 200,
      cellRendererFramework: (params) => {
        return (
          <Col
            style={{
              padding: "0",
            }}
          >
            <SalesEmployeePicker
              assignedTo={params.data.adminAccountOwner}
              companyName={params.data.companyName}
              userId={params.data.id}
              placeholder="Assigned to"
              list={list}
              refreshTable={refreshTable}
              hideDisabledUsers
            />
          </Col>
        );
      },
    },
    {
      headerName: "Lead status",
      field: "leadStatus",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        let leadStatus;

        switch (params.data.leadStatus) {
          case 1:
            leadStatus = {
              text: "hot",
              backgroundColor: "#FFDDDE",
              textColor: "#F74C54",
            };
            break;
          case 2:
            leadStatus = {
              text: "warm",
              backgroundColor: "#FFEFCA",
              textColor: "#F1AE10",
            };
            break;

          default:
            leadStatus = {
              text: "",
              backgroundColor: "",
              textColor: "",
            };
        }

        return (
          <ContextMenuTrigger
            data={capitalize(leadStatus.text)}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <FTSSquareBadge
              text={leadStatus.text}
              textColor={leadStatus.textColor}
              backgroundColor={leadStatus.backgroundColor}
            />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Main categories",
      field: "userCategories",
      sortable: false,
      unSortIcon: true,
      width: 310,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const userCategories = params.data.userCategories;

        return (
          <>
            {userCategories?.length === 1 ? (
              <ContextMenuTrigger
                data={userCategories[0].label}
                collect={collectDataForCopy}
                id="contextMenuLeadsPool"
              >
                <FTSSquareBadge
                  fontSize="14px"
                  text={userCategories[0].label}
                  backgroundColor="#CAF1FF"
                  textColor="#01B5F5"
                />
              </ContextMenuTrigger>
            ) : (
              <span />
            )}
            {userCategories?.length > 1 ? (
              <>
                <ContextMenuTrigger
                  data={userCategories[0].label}
                  collect={collectDataForCopy}
                  id="contextMenuLeadsPool"
                >
                  <FTSSquareBadge
                    fontSize="14px"
                    text={userCategories[0].label}
                    backgroundColor="#CAF1FF"
                    textColor="#01B5F5"
                    category
                  />
                  <div
                    onMouseEnter={() =>
                      togglePopover(
                        `Popover_${params.data.id}`,
                        params.data.userCategories?.split("|"),
                      )
                    }
                    onMouseLeave={() => togglePopover()}
                    id={`Popover_${params.data.id}`}
                  >
                    <FTSSquareBadge
                      fontSize="14px"
                      capitalize
                      text="View more"
                      backgroundColor="#FFF"
                      borderColor="#BFD3D9"
                      textColor="#242D34"
                      marginLeft="4px"
                    />
                  </div>
                </ContextMenuTrigger>
              </>
            ) : (
              <span />
            )}
          </>
        );
      },
    },
    {
      headerName: "Registered",
      field: "createdAt",
      sortable: true,
      unSortIcon: true,
      width: 150,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.createdAt).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <TableDate date={params.data.createdAt} />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email",
      field: "emailForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <span style={{ overflow: "hidden" }}>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "First Name",
      field: "firstNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.firstName}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <span>{params.data.firstName}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last Name",
      field: "lastNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.lastName}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <span>{`${params.data.lastName}`}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "addressForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.address}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <span style={{ overflow: "hidden" }}>{params.data.address}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuLeadsPool"
          >
            <span>{params.data.phoneNumber || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Secondary)",
      field: "phoneNumberSecondaryForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumberSecondary}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuLeadsPool"
          >
            <span>{params.data.phoneNumberSecondary || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Mobile",
      field: "mobilePhoneForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.mobilePhone}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuLeadsPool"
          >
            <span>{params.data.mobilePhone || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account status",
      field: "status",
      sortable: true,
      unSortIcon: true,
      width: 145,
      getQuickFilterText: (params) => statusBasedOnID(params.value),
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={statusBasedOnID(params.data.status)}
            collect={collectDataForCopy}
            id="contextMenuLeadsPool"
          >
            <FTSBadge color={params.data.status ? "success" : "danger"}>
              {statusBasedOnID(params.data.status)}
            </FTSBadge>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Todo's",
      field: "todoCounts",
      width: 300,
      sortable: false,
      cellRendererFramework: (params) => {
        return (
          <div
            className="d-flex flex-wrap"
            style={{ maxHeight: "60px", overflowY: "auto" }}
          >
            {types.map((type) => {
              if (
                params.data.todoCounts &&
                params.data.todoCounts[type.description.toLowerCase()]
              ) {
                return (
                  <FTSBadge
                    key={type.description}
                    style={{
                      backgroundColor: type.color,
                      borderColor: type.color,
                      marginRight: "4px",
                      marginTop: "1px",
                      marginBottom: "1px",
                    }}
                  >
                    {type.description} (
                    {params.data.todoCounts[type.description.toLowerCase()]})
                  </FTSBadge>
                );
              }
              return null;
            })}
          </div>
        );
      },
    },
  ];
};

export const customersColumns = [
  {
    headerName: "Last update date",
    field: "updatedAt",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    cellRendererFramework: (params) => {
      const now = moment().utc();
      const days = now.diff(moment(params.data.updatedAt).utc(), "days");

      let lastUpdateString;
      if (days === 0) {
        lastUpdateString = "Today";
      } else if (days === 1) {
        lastUpdateString = "Yesterday";
      } else if (params.data.updatedAt === "1970-01-01T00:00:00.000Z") {
        lastUpdateString = "No date";
      } else {
        lastUpdateString = `${days} days ago`;
      }

      return <div>{lastUpdateString}</div>;
    },
  },
  {
    headerName: "CID (Account #ID)",
    field: "listing.userId",
    sortable: true,
    unSortIcon: true,
    flex: 1,
  },
  {
    headerName: "Listing ID",
    field: "listing.id",
    sortable: true,
    unSortIcon: true,
    flex: 1,
  },
  {
    headerName: "Company name",
    field: "listing.name",
    sortable: true,
    unSortIcon: true,
    flex: 2,
  },
  {
    headerName: "City",
    field: "listing.city",
    sortable: true,
    unSortIcon: true,
    flex: 1,
  },
  {
    headerName: "State",
    field: "listing.state",
    sortable: true,
    unSortIcon: true,
    flex: 1,
  },
  // {
  //   headerName: "Status",
  //   field: "listing.status",
  //   sortable: true,
  //   unSortIcon: true,
  //   flex: 1,
  //   getQuickFilterText: (params) => params.value,
  //   cellRendererFramework: (params) => {
  //     const { color, label } = colorStatusID(params.value);
  //     return <div style={{ color: color }}>{label}</div>;
  //   },
  // },
  // {
  //   headerName: "Payment toggle",
  //   field: "paymentTo",
  //   sortable: true,
  //   unSortIcon: true,
  //   minWidth: 80,
  //   flex: 1,
  //   getQuickFilterText: (params) => params.value,
  //   cellRendererFramework: (params) => {
  //     const { color } = paymentStatusID(params.data.paymentTo);
  //     const className = color === "secondary" ? "dark-gray" : "";
  //     return (
  //       <div>
  //         <FTSBadge {...{ color, className }}>FREE</FTSBadge>
  //       </div>
  //     );
  //   },
  // },
];

export const defineAccountColumnsForActiveLeads = (
  collectDataForCopy,
  list = [],
  refreshTable,
  adminPermissions,
) => {
  let columns = [
    {
      headerName: "Account ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 125,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.id}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <span>{params.data.owner.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last contact date",
      field: "adminLastContactDate",
      sortable: true,
      unSortIcon: true,
      width: 165,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const noContactDate = !params.data.owner.lastContactedBy;

        return (
          <>
            {noContactDate ? (
              <ContextMenuTrigger
                data="No contact date"
                collect={collectDataForCopy}
                id="contextMenuActiveLeads"
              >
                <span>No contact date</span>
              </ContextMenuTrigger>
            ) : (
              <ContextMenuTrigger
                data={moment(params.data.owner.adminLastContactDate).format(
                  "lll",
                )}
                collect={collectDataForCopy}
                id="contextMenuActiveLeads"
              >
                <span>
                  <TableDate date={params.data.owner.adminLastContactDate} />
                </span>
              </ContextMenuTrigger>
            )}
          </>
        );
      },
    },
    {
      headerName: "Last contact by",
      field: "lastContactedBy",
      sortable: true,
      unSortIcon: true,
      width: 165,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const firstName = params.data.owner.lastUpdatedByAdmin?.firstName;
        const lastName = params.data.owner.lastUpdatedByAdmin?.lastName;
        return (
          <ContextMenuTrigger
            data={`${firstName} ${lastName}`}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            {firstName} {lastName}
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Source",
      field: "source",
      sortable: true,
      unSortIcon: true,
      width: 125,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.source}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <span>{params.data.owner.source}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "company",
      sortable: true,
      unSortIcon: true,
      width: 250,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.companyName}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <span style={{ overflow: "hidden" }}>
              {params.data.owner.companyName}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "city",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.city}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <span>{`${params.data.owner.city}`}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "state",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.state}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <span>{params.data.owner.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Assigned to",
      field: "adminAccountOwner",
      sortable: true,
      unSortIcon: true,
      width: 200,
      cellRendererFramework: (params) => {
        return (
          <Col
            style={{
              padding: "0",
            }}
          >
            <SalesEmployeePicker
              // isDisabled={true}
              assignedTo={params.data.owner.adminAccountOwner}
              companyName={params.data.owner.companyName}
              userId={params.data.owner.id}
              placeholder="Assigned to"
              list={list}
              hideCurrentAdmin
              refreshTable={refreshTable}
              hideDisabledUsers
            />
          </Col>
        );
      },
    },
    {
      headerName: "Lead status",
      field: "leadStatus",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        let leadStatus;

        switch (params.data.owner.leadStatus) {
          case 1:
            leadStatus = {
              text: "hot",
              backgroundColor: "#FFDDDE",
              textColor: "#F74C54",
            };
            break;
          case 2:
            leadStatus = {
              text: "warm",
              backgroundColor: "#FFEFCA",
              textColor: "#F1AE10",
            };
            break;

          default:
            leadStatus = {
              text: "",
              backgroundColor: "",
              textColor: "",
            };
        }

        return (
          <ContextMenuTrigger
            data={capitalize(leadStatus.text)}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <FTSSquareBadge
              text={leadStatus.text}
              textColor={leadStatus.textColor}
              backgroundColor={leadStatus.backgroundColor}
            />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account label",
      field: "adminStatus",
      sortable: true,
      unSortIcon: true,
      width: 165,
      cellRendererFramework: (params) => {
        let accountLabel = adminStatusBasedOnID(params.data.owner.adminStatus);

        return (
          <ContextMenuTrigger
            data={capitalize(accountLabel)}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <FTSSquareBadge
              text={accountLabel}
              textColor={"#242D34"}
              backgroundColor={"#EDF2F3"}
            />
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "Registered",
      field: "createdAt",
      sortable: true,
      unSortIcon: true,
      width: 150,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.createdAt).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <TableDate date={params.data.createdAt} />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.email}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <span style={{ overflow: "hidden" }}>
              {params.data.owner.email}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "First Name",
      field: "firstName",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.firstName}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <span>{params.data.owner.firstName}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last Name",
      field: "lastName",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.lastName}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <span>{`${params.data.owner.lastName}`}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "address",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.address}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <span style={{ overflow: "hidden" }}>
              {params.data.owner.address}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumber",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuActiveLeads"
          >
            <span>{params.data.owner.phoneNumber || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Secondary)",
      field: "phoneNumberSecondary",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.phoneNumberSecondary}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuActiveLeads"
          >
            <span>{params.data.owner.phoneNumberSecondary || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Mobile",
      field: "mobilePhone",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.owner.mobilePhone}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuActiveLeads"
          >
            <span>{params.data.owner.mobilePhone || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account status",
      field: "status",
      sortable: true,
      unSortIcon: true,
      width: 145,
      getQuickFilterText: (params) => statusBasedOnID(params.value),
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={statusBasedOnID(params.data.owner.status)}
            collect={collectDataForCopy}
            id="contextMenuActiveLeads"
          >
            <FTSBadge color={params.data.owner.status ? "success" : "danger"}>
              {statusBasedOnID(params.data.owner.status)}
            </FTSBadge>
          </ContextMenuTrigger>
        );
      },
    },
  ];

  if (checkPermissions([{ id: 4, subCat: [54] }], adminPermissions)) {
    columns.unshift({
      headerName: "",
      field: "selected",
      width: 50,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: () => {
        return true;
      },
    });
  }

  return columns;
};

export const defineCustomerColumns = (
  types,
  adminPermissions,
  collectDataForCopy,
  togglePopover,
  list,
  refreshTable,
) => {
  let columns = [
    {
      headerName: "Last contact date",
      field: "adminLastContactDateForSort",
      sortable: true,
      unSortIcon: true,
      width: 165,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const noContactDate = !params.data.lastContactedBy;

        return (
          <>
            {noContactDate ? (
              <ContextMenuTrigger
                data="No contact date"
                collect={collectDataForCopy}
                id="contextMenuCustomerLeads"
              >
                <span>No contact date</span>
              </ContextMenuTrigger>
            ) : (
              <ContextMenuTrigger
                data={moment(params.data.adminLastContactDate).format("lll")}
                collect={collectDataForCopy}
                id="contextMenuCustomerLeads"
              >
                <span>
                  <TableDate date={params.data.adminLastContactDate} />
                </span>
              </ContextMenuTrigger>
            )}
          </>
        );
      },
    },
    {
      headerName: "Last contact by",
      field: "lastContactedBy",
      sortable: true,
      unSortIcon: true,
      width: 165,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const firstName = params.data.lastUpdatedByAdmin?.firstName;
        const lastName = params.data.lastUpdatedByAdmin?.lastName;
        return (
          <ContextMenuTrigger
            data={`${firstName} ${lastName}`}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            {firstName} {lastName}
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Source",
      field: "source",
      sortable: true,
      unSortIcon: true,
      width: 125,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.source}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <span>{params.data.source}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "companyNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 250,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.companyName}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <span style={{ overflow: "hidden" }}>
              {params.data.companyName}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <span>{`${params.data.city}`}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account label",
      field: "adminStatusForSort",
      sortable: true,
      unSortIcon: true,
      width: 165,
      cellRendererFramework: (params) => {
        let accountLabel;

        const now = moment().utc();
        const days = now.diff(moment(params.data.createdAt).utc(), "days");

        if (
          days <= 5 &&
          moment(params.data.createdAt).utc() >
            moment(params.data.adminLastContactDate).utc()
        ) {
          accountLabel = {
            text: "new",
            backgroundColor: "#DFF7FF",
            textColor: "#242D34",
          };
        } else {
          accountLabel = {
            text: params.data.adminStatusForSort,
            backgroundColor: "#EDF2F3",
            textColor: "#242D34",
          };
        }

        return (
          <ContextMenuTrigger
            data={capitalize(accountLabel.text)}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <FTSSquareBadge
              text={accountLabel.text}
              textColor={accountLabel.textColor}
              backgroundColor={accountLabel.backgroundColor}
            />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Assigned to",
      field: "adminAccountOwner",
      sortable: true,
      unSortIcon: true,
      width: 200,
      cellRendererFramework: (params) => {
        return (
          <Col
            style={{
              padding: "0",
            }}
          >
            <SalesEmployeePicker
              assignedTo={params.data.adminAccountOwner}
              companyName={params.data.companyName}
              userId={params.data.id}
              placeholder="Assigned to"
              list={list}
              refreshTable={refreshTable}
              hideDisabledUsers
            />
          </Col>
        );
      },
    },
    {
      headerName: "Lead status",
      field: "leadStatus",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        let leadStatus;

        switch (params.data.leadStatus) {
          case 1:
            leadStatus = {
              text: "hot",
              backgroundColor: "#FFDDDE",
              textColor: "#F74C54",
            };
            break;
          case 2:
            leadStatus = {
              text: "warm",
              backgroundColor: "#FFEFCA",
              textColor: "#F1AE10",
            };
            break;

          default:
            leadStatus = {
              text: "",
              backgroundColor: "",
              textColor: "",
            };
        }

        return (
          <ContextMenuTrigger
            data={capitalize(leadStatus.text)}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <FTSSquareBadge
              text={leadStatus.text}
              textColor={leadStatus.textColor}
              backgroundColor={leadStatus.backgroundColor}
            />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Main categories",
      field: "userCategoriesForSort",
      sortable: false,
      unSortIcon: true,
      width: 310,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const userCategories = params.data.userCategories;

        return (
          <>
            {userCategories?.length === 1 ? (
              <ContextMenuTrigger
                data={userCategories[0].label}
                collect={collectDataForCopy}
                id="contextMenuCustomerLeads"
              >
                <FTSSquareBadge
                  fontSize="14px"
                  text={userCategories[0].label}
                  backgroundColor="#CAF1FF"
                  textColor="#01B5F5"
                />
              </ContextMenuTrigger>
            ) : (
              <span />
            )}
            {userCategories?.length > 1 ? (
              <>
                <ContextMenuTrigger
                  data={userCategories[0].label}
                  collect={collectDataForCopy}
                  id="contextMenuCustomerLeads"
                >
                  <FTSSquareBadge
                    fontSize="14px"
                    text={userCategories[0].label}
                    backgroundColor="#CAF1FF"
                    textColor="#01B5F5"
                    category
                  />
                  <div
                    onMouseEnter={() =>
                      togglePopover(
                        `Popover_${params.data.id}`,
                        params.data.userCategories?.split("|"),
                      )
                    }
                    onMouseLeave={() => togglePopover()}
                    id={`Popover_${params.data.id}`}
                  >
                    <FTSSquareBadge
                      fontSize="14px"
                      capitalize
                      text="View more"
                      backgroundColor="#FFF"
                      borderColor="#BFD3D9"
                      textColor="#242D34"
                      marginLeft="4px"
                    />
                  </div>
                </ContextMenuTrigger>
              </>
            ) : (
              <span />
            )}
          </>
        );
      },
    },
    {
      headerName: "Registered",
      field: "createdAt",
      sortable: true,
      unSortIcon: true,
      width: 150,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.createdAt).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <TableDate date={params.data.createdAt} />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email",
      field: "emailForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <span style={{ overflow: "hidden" }}>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "First Name",
      field: "firstNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.firstName}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <span>{params.data.firstName}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last Name",
      field: "lastNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.lastName}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <span>{`${params.data.lastName}`}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "addressForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.address}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <span style={{ overflow: "hidden" }}>{params.data.address}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuCustomerLeads"
          >
            <span>{params.data.phoneNumber || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Secondary)",
      field: "phoneNumberSecondaryForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumberSecondary}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuCustomerLeads"
          >
            <span>{params.data.phoneNumberSecondary || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Mobile",
      field: "mobilePhoneForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.mobilePhone}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuCustomerLeads"
          >
            <span>{params.data.mobilePhone || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account status",
      field: "status",
      sortable: true,
      unSortIcon: true,
      width: 145,
      getQuickFilterText: (params) => statusBasedOnID(params.value),
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={statusBasedOnID(params.data.status)}
            collect={collectDataForCopy}
            id="contextMenuCustomerLeads"
          >
            <FTSBadge color={params.data.status ? "success" : "danger"}>
              {statusBasedOnID(params.data.status)}
            </FTSBadge>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Todo's",
      field: "todoCounts",
      width: 300,
      sortable: false,
      cellRendererFramework: (params) => {
        return (
          <div
            className="d-flex flex-wrap"
            style={{ maxHeight: "60px", overflowY: "auto" }}
          >
            {types.map((type) => {
              if (
                params.data.todoCounts &&
                params.data.todoCounts[type.description.toLowerCase()]
              ) {
                return (
                  <FTSBadge
                    key={type.description}
                    style={{
                      backgroundColor: type.color,
                      borderColor: type.color,
                      marginRight: "4px",
                      marginTop: "1px",
                      marginBottom: "1px",
                    }}
                  >
                    {type.description} (
                    {params.data.todoCounts[type.description.toLowerCase()]})
                  </FTSBadge>
                );
              }
              return null;
            })}
          </div>
        );
      },
    },
  ];

  if (checkPermissions([{ id: 4, subCat: [54] }], adminPermissions)) {
    columns.unshift({
      headerName: "",
      field: "selected",
      width: 50,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: () => {
        return true;
      },
    });
  }
  return columns;
};
