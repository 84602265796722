import React, { Component } from "react";

import { Button, Row } from "reactstrap";

import FTSModal from "../fts-modal";
// import CloseButton from "../CloseButton";
import Icon from "../Icons";
import "../../../assets/scss/components/fts/rejected-reason-read-modal.scss";

class RejectedReasonReadModal extends Component {
  render() {
    const {
      isOpen,
      title,
      closeModal,
      onClose,
      toggleRejectedReasonModal,
      rejectedReason,
      actionItem,
      callToAction,
    } = this.props;

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-rejected-read-reason-modal"}
        centered={true}
        style={{ maxWidth: "716px" }}
        footerCTA={() => {
          return (
            <div className="confirm-reject-reason-modal-footer">
              <div>
                <span className="mr-1">
                  <Button
                    onClick={() => {
                      toggleRejectedReasonModal({ rejectedReason: null });
                    }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </span>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    console.log("this.props.listingId: ", this.props.listingId);
                    toggleRejectedReasonModal({ rejectedReason: null });
                    callToAction("proof_update_listing", false, {
                      id: actionItem?.listing?.id,
                    });
                  }}
                >
                  Edit listing
                </Button>
              </div>
            </div>
          );
        }}
      >
        <div className="fts-rejected-reason-list-modal-body">
          <span className="fts-rejected-reason-list-modal-body-wrapper">
            <span className="fts-rejected-reason-list-modal-title">
              <div className="title-row">
                <span className="d-flex align-items-center">
                  <Icon name="RejectReasonModal" size="56" />
                  <h3 className="ml-1">Reject reason</h3>
                </span>
              </div>

              {/* <CloseButton
                onClick={() => {
                  toggleRejectedReasonModal({ rejectedReason: null });
                }}
              /> */}
            </span>
            <Row>
              <span className="fts-rejected-modal-message">
                {rejectedReason}
              </span>
            </Row>
          </span>
        </div>
      </FTSModal>
    );
  }
}

export default RejectedReasonReadModal;
