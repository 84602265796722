import moment from "moment";

export const ANALYTICS_COLOR_PALETTE = {
  FIRST_VENDOR: "#9AAFE8",
  TOP_3: "#B1BABD",
  VIEWS: "#EFD58F",
  CALLS: "#94D1B3",
};

export const getDataForChart = (data, activeTab, labels) => {
  if (!data) return [];

  let defaultValues = Array(labels.length).fill(0);
  if (activeTab === "1w") {
    for (let itm of data) {
      let day = moment(itm.label).format("dddd");
      let index = labels.indexOf(day);
      defaultValues[index] = defaultValues[index] + itm.value;
    }
    return defaultValues;
  }

  if (activeTab === "1m") {
    for (let itm of data) {
      let index = labels.findIndex(
        (e) => e[0] === moment(itm.label).format("ll").split(",")[0],
      );
      defaultValues[index] = defaultValues[index] + itm.value;
    }

    return defaultValues;
  }

  if (activeTab === "3m") {
    for (let itm of data) {
      let index = labels.findIndex((e) => {
        let dataDate = moment(`01-${itm.month}-${itm.year}`).format("MMM");
        return e[0] === dataDate && e[1] === itm.year;
      });
      defaultValues[index] = defaultValues[index] + itm.value;
    }
    return defaultValues;
  }

  if (activeTab === "6m") {
    for (let itm of data) {
      let index = labels.findIndex((e) => {
        let dataDate = moment(`01-${itm.month}-${itm.year}`).format("MMM");
        return e[0] === dataDate && e[1] === itm.year;
      });
      defaultValues[index] = defaultValues[index] + itm.value;
    }
    return defaultValues;
  }

  for (let itm of data) {
    if (itm.month && itm.year) {
      let index = labels.findIndex((e) => {
        let dataDate = moment(`01-${itm.month}-${itm.year}`).format("MMM");
        return e[0] === dataDate && e[1] === itm.year;
      });
      defaultValues[index] = defaultValues[index] + itm.value;
    }
    if (itm.label) {
      let index = labels.findIndex(
        (e) => e[0] === moment(itm.label).format("ll").split(",")[0],
      );
      defaultValues[index] = defaultValues[index] + itm.value;
    }
  }
  return defaultValues;
};

export const formatReportForChart = (report) => {
  let reports = {
    "1w": {
      top1: [],
      top3: [],
      listingViewed: [],
      calls: [],
      top1Value: 0,
      top3Value: 0,
      listingViewedValue: 0,
      callsValue: 0,
    },
    "1m": {
      top1: [],
      top3: [],
      listingViewed: [],
      calls: [],
      top1Value: 0,
      top3Value: 0,
      listingViewedValue: 0,
      callsValue: 0,
    },
    "3m": {
      top1: [],
      top3: [],
      listingViewed: [],
      calls: [],
      top1Value: 0,
      top3Value: 0,
      listingViewedValue: 0,
      callsValue: 0,
    },
    "6m": {
      top1: [],
      top3: [],
      listingViewed: [],
      calls: [],
      top1Value: 0,
      top3Value: 0,
      listingViewedValue: 0,
      callsValue: 0,
    },
    "1y": {
      top1: [],
      top3: [],
      listingViewed: [],
      calls: [],
      top1Value: 0,
      top3Value: 0,
      listingViewedValue: 0,
      callsValue: 0,
    },
    customRange: {
      top1: [],
      top3: [],
      listingViewed: [],
      calls: [],
      top1Value: 0,
      top3Value: 0,
      listingViewedValue: 0,
      callsValue: 0,
    },
  };

  for (let [key, value] of Object.entries(report)) {
    for (let event of value) {
      if (event.measurement === "top1") {
        reports[key].top1.push(event);
        reports[key].top1Value = reports[key].top1Value + event.value;
      }
      if (event.measurement === "top3") {
        reports[key].top3.push(event);
        reports[key].top3Value = reports[key].top3Value + event.value;
      }
      if (event.measurement === "listing_viewed") {
        reports[key].listingViewed.push(event);
        reports[key].listingViewedValue =
          reports[key].listingViewedValue + event.value;
      }
      if (
        event.measurement === "view_listing_contacts" ||
        event.measurement === "log_call_listing_primary_phone_number" ||
        event.measurement === "log_call_listing_secondary_phone_number" ||
        event.measurement === "log_call_listing_after_hours_phone_number"
      ) {
        reports[key].calls.push(event);
        reports[key].callsValue = reports[key].callsValue + event.value;
      }
    }
  }
  return reports;
};

export const generateReportPayload = (report) => {
  let reportData = [];

  for (let value of Object.values(report)) {
    if (value.length) {
      reportData = reportData.concat(value);
    }
  }

  return reportData;
};

export const getCustomRangeLabels = (reportFrom, reportTo) => {
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let labels = [];
  let aMonth = new Date(reportTo).getMonth();
  let aYear = new Date(reportTo).getFullYear();

  const startDate = moment(reportFrom);
  const endDate = moment(reportTo);
  let monthsRange = endDate.diff(startDate, "months");

  if (monthsRange <= 1) {
    console.log("DIFF DAYS:", endDate.diff(startDate, "days"));
    labels = new Array(endDate.diff(startDate, "days") + 1)
      .fill("Week")
      .map((e, i) => {
        return moment(endDate).subtract(i, "days").format("ll").split(",");
      });
    return labels.reverse();
  }

  monthsRange = moment(endDate)
    .set("date", 1)
    .diff(moment(startDate).set("date", 1), "months");
  for (let i = monthsRange + 1; i > 0; i--) {
    const label = [months[aMonth], aYear];
    labels.push(label);
    aMonth--;
    if (aMonth < 0) {
      aMonth = 11;
      aYear--;
    }
  }
  return labels.reverse();
};
