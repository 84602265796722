/* eslint-disable indent */
import React, { Component } from "react";
import { Label } from "reactstrap";
import { ErrorMessage } from "formik";
import { components } from "react-select";

import {
  pickerStyles,
  controlStyles,
  searchPickerStyles,
  searchControlStyles,
} from "../picker-style";
import Select from "../Select";
import FormikInputValue from "../formik/formik-input-value";

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null,
      )}
    </components.ValueContainer>
  );
};

export const accountStatusOptions = [
  { label: "Active", value: 1 },
  { label: "Disabled", value: 0 },
  { label: "Deactivated", value: 3 },
];
class ActivationPicker extends Component {
  constructor(props) {
    super(props);

    const listingStatusOptions = [
      { label: "Active", value: 1 },
      { label: "Disabled", value: 0 },
      { label: "Inactive", value: 2 },
      { label: "Draft", value: 3 },
    ];

    if (props.account) {
      this.state = {
        statusOptions: accountStatusOptions,
      };
    } else {
      this.state = {
        statusOptions: listingStatusOptions,
      };
    }
    if (props.statusOptions) {
      this.state = {
        statusOptions: props.statusOptions,
      };
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.isSearch) {
      const hasAll = state.statusOptions.find((itm) => itm.label === "All");
      const statusOptions = hasAll
        ? state.statusOptions
        : [{ label: "All", value: "" }, ...state.statusOptions];
      return {
        ...state,
        statusOptions,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isDisabled !== this.props.isDisabled ||
      (nextProps.meta && nextProps.meta.error !== this.props.meta.error) ||
      (!!this.props.meta &&
        nextProps.meta.touched !== this.props.meta.touched) ||
      nextProps.value !== this.props.value
    );
  }

  render() {
    const { statusOptions } = this.state;
    const {
      isSearch,
      label,
      meta,
      name = "status",
      value,
      isDisabled,
      onStateChange,
      ...rest
    } = this.props;
    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) => controlStyles(styles, state, {}, { meta }),
    };

    const searchPickerStyle = {
      ...searchPickerStyles,
      control: (styles, state) =>
        searchControlStyles(styles, state, {}, { meta }),
    };
    const valueInfo =
      typeof value === "number"
        ? statusOptions.find((itm) => itm.value === value)
        : value === "" && isSearch
        ? { value: "", label: "All" }
        : value;

    let isLoading = false;

    if (!statusOptions?.length) {
      isLoading = true;
    }

    return (
      <>
        {!!label && (
          <Label
            className={isDisabled ? "disabled" : ""}
            for="activation-status"
          >
            {this.props.required && !isDisabled ? `${label} *` : label}
          </Label>
        )}
        {isDisabled ? (
          <FormikInputValue>{valueInfo?.label || "-"}</FormikInputValue>
        ) : (
          <Select
            {...{ isLoading, label }}
            isSearchable={false}
            options={statusOptions}
            styles={isSearch ? searchPickerStyle : pickerStyle}
            isDisabled={isDisabled}
            name={name}
            value={valueInfo}
            components={
              isSearch
                ? {
                    ValueContainer: CustomValueContainer,
                  }
                : null
            }
            onChange={onStateChange}
            {...rest}
          />
        )}
        {!!meta && (
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="formik-validation-error">{msg}</div>
            )}
          />
        )}
      </>
    );
  }
}

export default ActivationPicker;
