export const mapCountersToTabs = ({ tabs, counters }) => {
  let allCounter = 0;
  let mappedTabs = tabs.map((tab) => {
    if (!counters[tab.id]) return tab;

    allCounter += counters[tab.id].count;
    return { ...tab, count: counters[tab.id].count };
  });

  return { mappedTabs, allCounter };
};
