// Common
import React, { useEffect } from "react";
// UI
import { FtsWrapper } from "../dist/ui/src/FtsWrapper/FtsWrapper";
// Components
import { AmenitiesPicker } from "./Components/AmenitiesPicker";
import { CategoriesPicker } from "./Components/CategoriesPicker";
import { ListingInfoBanner } from "./Components/ListingInfoBanner";
import { ListingDetailsRow } from "./Components/ListingDetailsRow";
import { ListingMapInfoRow } from "./Components/ListingMapInfoRow";
import { ListingContactRow } from "./Components/ListingContactRow";
import { ServiceAmenitiesPicker } from "./Components/ServiceAmenitiesPicker";
import { ListingRatesRow } from "./Components/ListingRatesRow";
import { ListingWorkingHours } from "./Components/ListingWorkingHours";
import { ListingDescriptionRow } from "./Components/ListingDescriptionRow";

import { ListingLockedMessage } from "./Components/ListingLockedMessage";
import { useDispatch } from "react-redux";
import { unLockListingRequest } from "../../../redux/actions/v2/listings";

export const EditListingForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(unLockListingRequest());
  }, []);
  return (
    <FtsWrapper py={"2xl"} px={"3xl"}>
      <ListingLockedMessage />
      <ListingInfoBanner />
      <ListingDetailsRow />
      <ListingMapInfoRow />
      <ListingContactRow />
      <ListingDescriptionRow />
      <ListingRatesRow />
      <ListingWorkingHours />
      <CategoriesPicker />
      <ServiceAmenitiesPicker />
      <AmenitiesPicker />
    </FtsWrapper>
  );
};
