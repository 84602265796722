import React from "react";
import Icon from "../../fts/Icons";
import "../../../assets/scss/info-message.scss";

const InfoMessage = ({ message }) => {
  return (
    <div className="info-message">
      <div className="info-message-icon">
        <Icon name="InfoCircle" size="24" color="#32383A" />
      </div>
      <div className="info-message-text">{message}</div>
    </div>
  );
};

export default InfoMessage;
