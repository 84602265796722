import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { history } from "../../../history";
import { isRefreshing } from "../../../axios/fts.instance";

import {
  searchVendorAccounts,
  getActiveLeads,
} from "../../../redux/actions/users";
import { createActivity } from "../../../redux/actions/activities";
import {
  defineAccountColumns,
  defineAccountColumnsForActiveLeads,
} from "./sales-columns";

import { Card, Row, Col, TabPane, Input, Spinner } from "reactstrap";

import "../../../assets/scss/plugins/extensions/react-paginate.scss";
import "../../../assets/scss/pages/sales-tab.scss";
import FTSTableGroup from "../../../components/fts/fts-table-group";
import capitalize from "../../../utility/capitalize";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";
import Icons from "../../../components/fts/Icons";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import SalesEmployeePicker from "../../../components/fts/sales-employee-picker";
import SalesReassignDropdown from "../../../components/fts/sales-reassign-dropdown";

import { checkPermissions } from "../../../utility/permissionsCheck";

class ActiveAccounts extends Component {
  constructor(props) {
    super(props);

    this.accountColumnsVendor = defineAccountColumns(
      this.props.types,
      this.collectDataForCopy,
    );
  }
  pageSizes = [10, 25, 50, 100];

  state = {
    collectedDataForCopy: "",
    rowData: null,
    inProgress: false,
    isVisible: true,
    collapse: true,
    initialSearch: true,
    page: 1,
    totalRecords: 0,
    pageCount: 0,
    pageSize: 25,
    accountSearchValue: "",
    pageSizeFree: 50,
    query: this.props.storedFilter,
    defaultColDef: {
      sortable: true,
    },
    columnDefs: this.accountColumns,
    isOpenReassignDropdown: null,
    adminAccountOwner: null,
    selectedAccounts: null,
    sortAccountVendor: [],
  };

  dropdownRef = createRef();

  getSelectedActiveLeads = (event) => {
    let selectedActiveLeads = event?.api?.selectionController?.selectedNodes;

    selectedActiveLeads = Object.values(selectedActiveLeads);

    selectedActiveLeads = selectedActiveLeads
      .filter((customer) => {
        if (customer?.data?.owner?.id) {
          return customer.data?.owner?.id;
        }
        return null;
      })
      .map((customer) => customer?.data?.owner?.id);

    this.setState({
      selectedActiveLeads: selectedActiveLeads,
      tableApi: event.api,
    });
  };

  onRowClicked = ({ data, api }) => {
    if (
      api.focusedCellController.focusedCellPosition.column.colId ===
      "adminAccountOwner"
    ) {
      return;
    }
    const createActivityPayload = {
      toDoId: data.id,
      status: 1,
      // activityType: "sales",
      activityType: this.props.activitiesTypes.SALES,
      activity: "",
      iconName: "AccountsActivity",
    };

    if (!data.activities.length) {
      this.props.createActivity(createActivityPayload);
    }

    history.push(`/accounts/${data.owner.id}?toDoId=${data.id}`);
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  resetApiAccount = () => {
    this.gridApiAccount = null;
  };

  onGridReadyAccount = (params) => {
    this.gridApiAccount = params.api;
    this.gridColumnApiAccount = params.columnApi;

    if (localStorage.getItem("accountColumnState") !== null) {
      this.gridColumnApiAccount?.setColumnState(
        JSON.parse(localStorage.getItem("accountColumnState")),
      );
    }
  };

  saveStateAccount = () => {
    let accountColumnState = this.gridColumnApiAccount.getColumnState();

    localStorage.setItem(
      "accountColumnState",
      JSON.stringify(accountColumnState),
    );
  };

  debounce = setTimeout(() => {}, 300);

  updateSearchQuery = (e) => {
    if (isRefreshing) return;
    this.setState(
      {
        accountSearchValue: e.target.value,
        page: 1,
        pageSize: this.state.pageSize,
      },
      () => {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.updateAccountSearchResults();
        }, 300);
      },
    );
  };

  componentDidMount = async () => {
    const { listState } = this.props.storedFilter;
    this.setState({
      adminAccountOwner: checkPermissions(
        [{ id: 4, subCat: [54] }],
        this.props.adminPermissions,
      )
        ? "null"
        : this.props.userData.loggedInUser.id,
      inProgress: true,
    });

    this.setState(listState, async () => {
      this.setState({ inProgress: false });
    });

    window.addEventListener("mousedown", this.handleDropdownClickOutside);
  };

  handleReassignDropdown = () => {
    this.setState({
      isOpenReassignDropdown: !this.state.isOpenReassignDropdown,
    });
  };

  handleDropdownClickOutside = (e) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(e.target) &&
      this.state.isOpenReassignDropdown
    ) {
      this.handleReassignDropdown();
    }
  };

  updateAccountSearchResults = () => {
    // this.setState({
    //   inProgress: true,
    // });

    if (
      this.tableGroupRef &&
      this.tableGroupRef.apis &&
      this.tableGroupRef.apis["today"]
    ) {
      this.tableGroupRef.apis["today"].showLoadingOverlay();
    }
    if (
      this.tableGroupRef &&
      this.tableGroupRef.apis &&
      this.tableGroupRef.apis["upcoming"]
    ) {
      this.tableGroupRef.apis["upcoming"].showLoadingOverlay();
    }

    let state = this.tableGroupRef.state;
    const { sortAccountVendor } = this.state;
    let promises = [
      this.props.getActiveLeads({
        to: 1,
        searchString: rfc3986EncodeURIComponent(this.state.accountSearchValue),
        sort: sortAccountVendor,
        page: state[`page${capitalize("today", false)}`],
        resultsPerPage: state[`pageSize${capitalize("today", false)}`],
        adminAccountOwner: this.state.adminAccountOwner || "null",
      }),
      this.props.getActiveLeads({
        from: 1,
        sort: sortAccountVendor,
        searchString: rfc3986EncodeURIComponent(this.state.accountSearchValue),
        page: state[`page${capitalize("upcoming", false)}`],
        resultsPerPage: state[`pageSize${capitalize("upcoming", false)}`],
        adminAccountOwner: this.state.adminAccountOwner || "null",
      }),
    ];
    this.setState({ selectedActiveLeads: [] });
    return Promise.all(promises)
      .then(([{ data: today }, { data: upcoming }]) => {
        this.tableGroupRef.setRowData("today", today.rows);
        this.tableGroupRef.setRowData("upcoming", upcoming.rows);
        this.tableGroupRef.setTableState({ totalRecordsToday: today.count });
        this.tableGroupRef.setTableState({
          totalRecordsUpcoming: upcoming.count,
        });

        if (
          this.tableGroupRef &&
          this.tableGroupRef.apis &&
          this.tableGroupRef.apis["today"]
        ) {
          this.tableGroupRef.apis["today"].hideOverlay();
        }
        if (
          this.tableGroupRef &&
          this.tableGroupRef.apis &&
          this.tableGroupRef.apis["upcoming"]
        ) {
          this.tableGroupRef.apis["upcoming"].hideOverlay();
        }
      })
      .catch(() => {
        if (
          this.tableGroupRef &&
          this.tableGroupRef.apis &&
          this.tableGroupRef.apis["today"]
        ) {
          this.tableGroupRef.apis["today"].hideOverlay();
        }
        if (
          this.tableGroupRef &&
          this.tableGroupRef.apis &&
          this.tableGroupRef.apis["upcoming"]
        ) {
          this.tableGroupRef.apis["upcoming"].hideOverlay();
        }
      });
  };

  onSortChanged = (params) => {
    this.setState({ sortAccountVendor: params.api.getSortModel() });
    return this.updateAccountSearchResults();
  };

  getTableData = (key, index, state) => {
    if (key === "today") {
      return this.props.getActiveLeads({
        ...state.searchQuery,
        to: 1,
        searchString: this.state.accountSearchValue,
        page: state[`page${capitalize(key, false)}`],
        resultsPerPage: state[`pageSize${capitalize(key, false)}`],
        adminAccountOwner: this.state.adminAccountOwner || "null",
      });
    }
    return this.props.getActiveLeads({
      ...state.searchQuery,
      from: 1,
      searchString: this.state.accountSearchValue,
      page: state[`page${capitalize(key, false)}`],
      resultsPerPage: state[`pageSize${capitalize(key, false)}`],
      adminAccountOwner: this.state.adminAccountOwner || "null",
    });
  };

  refreshTable = () => {
    this.updateAccountSearchResults();
  };

  updateAdminAccountOwner = (value) => {
    this.setState(
      {
        adminAccountOwner: value ? value.value : null,
      },
      () => {
        this.refreshTable();
      },
    );
  };

  getTableLayouts = () => {
    if (!this.tableLayouts) {
      const defaultTableLayout = {
        onRowClicked: this.onRowClicked,
        getSelectedActiveLeads: this.getSelectedActiveLeads,
        // onCreateNew: this.onCreateNew,
        onGridApiReady: this.onGridReadyAccount,
        getTableData: this.getTableData,
        pageSizes: this.pageSizes,
        hasSearch: false,
        initialTableData: null,
        initialPage: 1,
        initialPageSize: 10,
        initialPageCount: 0,
        initialTotalRecords: 0,
        initialSearchVal: "",
      };

      this.tableLayouts = {
        today: {
          ...defaultTableLayout,
          columnDefs: defineAccountColumnsForActiveLeads(
            this.collectDataForCopy,
            this.props.list,
            this.refreshTable,
            this.props.adminPermissions,
          ),
        },
        upcoming: {
          ...defaultTableLayout,
          columnDefs: defineAccountColumnsForActiveLeads(
            this.collectDataForCopy,
            this.props.list,
            this.refreshTable,
            this.props.adminPermissions,
          ),
        },
      };
    }
    return this.tableLayouts;
  };

  filterSize = (type, val) => {
    this.setState(
      {
        [type]: val,
      },
      () => {
        this.updateAccountSearchResults();
      },
    );
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      this.updateAccountSearchResults();
    });
  };

  unselectAllAccounts = () => {
    this.state.tableApi.deselectAll();
    this.setState({ selectedAccounts: [] }, () => {
      setTimeout(() => {
        this.refreshTable();
      }, 1000);
    });
  };

  render() {
    const {
      accountSearchValue,
      inProgress,
      isOpenReassignDropdown,
      selectedActiveLeads,
    } = this.state;

    return (
      <Row className="sales-lists">
        <Col sm="12">
          <Card>
            <div>
              <TabPane tabId="vendor">
                <div className="result-header d-flex align-items-center">
                  <div>
                    <p className="result-title">Active leads</p>
                  </div>
                  <div className="ml-auto d-flex align-items-center">
                    <div className="assigned-to-dropdown">
                      {checkPermissions(
                        [{ id: 4, subCat: [55] }],
                        this.props.adminPermissions,
                      ) && (
                        <SalesEmployeePicker
                          showAssigned
                          activeLeadsPage
                          isClearable
                          placeholder="Assigned to"
                          list={this.props.list}
                          updateAdminAccountOwner={this.updateAdminAccountOwner}
                          search
                        />
                      )}
                    </div>
                    <div className="filter-section mr-1">
                      <Input
                        type="text"
                        placeholder="Search..."
                        style={{ width: "230px" }}
                        onChange={this.updateSearchQuery}
                        value={accountSearchValue}
                      />
                    </div>
                    <div>
                      <SalesReassignDropdown
                        dropdownRef={this.dropdownRef}
                        handleDropdown={this.handleReassignDropdown}
                        isOpenReassignDropdown={isOpenReassignDropdown}
                        selectedAccounts={selectedActiveLeads}
                        onReassign={() => {
                          this.unselectAllAccounts();
                        }}
                        activeLeadsPage
                        reassignTo
                      />
                    </div>
                  </div>
                </div>
              </TabPane>

              {inProgress ? (
                <Col sm="12" className="text-center mt-5">
                  <Spinner />
                </Col>
              ) : (
                <FTSTableGroup
                  onSortChanged={(params) =>
                    this.onSortChanged(params, "vendors")
                  }
                  onRef={(ref) => (this.tableGroupRef = ref)}
                  // handleSearch={this.handleSearch}
                  // saveData={this.props.saveData}
                  tableLayouts={this.getTableLayouts()}
                  rowSelection="multiple"
                  initialSearchQuery={this.state.accountSearchValue}
                  activeAccount={true}
                />
              )}
              <ContextMenu
                style={{ minWidth: 250, zIndex: 1000 }}
                id="contextMenuAccountSettings"
                preventHideOnScroll={false}
              >
                <MenuItem
                  onClick={() =>
                    navigator.clipboard.writeText(
                      this.state.collectedDataForCopy,
                    )
                  }
                >
                  <FaRegCopy size={18} className="copy" />
                  <span className="ml-1 text-dark">Copy</span>
                </MenuItem>
                {this.state.call && this.state.collectedDataForCopy && (
                  <MenuItem>
                    <a
                      className="text-decoration-none text-dark d-flex align-items-center w-100"
                      href={`tel:${this.state.collectedDataForCopy}`}
                    >
                      <Icons name="PhoneBlue" />
                      <div className="ml-1">
                        Call - {this.state.collectedDataForCopy}
                      </div>
                    </a>
                  </MenuItem>
                )}
              </ContextMenu>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.essentialLists.employees,
    storedFilter: state.searchFilter,
    types: state.essentialLists.types,
    userData: state.auth.login.values,
    adminPermissions: state.auth.login.permissions,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, {
  searchVendorAccounts,
  getActiveLeads,
  createActivity,
})(ActiveAccounts);
