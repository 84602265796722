import React from "react";
import Marketing from "../../pages/account-settings/Marketing";
import "../../../assets/scss/pages/email-templates.scss";

class TodoTypesView extends React.Component {
  render() {
    return (
      <div className="fts-email-templates">
        <Marketing meta />
      </div>
    );
  }
}

export default TodoTypesView;
