import React from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import Icon from "../../../components/fts/Icons";
import { capitalize } from "lodash";
import FTSBadge from "../../../components/fts/fts-badge";

const handleSouceIconName = ({ source }) => {
  switch (source) {
    case "iOS":
      return "MobilePhone";
    case "android":
      return "MobilePhone";
    case "desktop":
      return "Desktop";
    case "admin":
      return "Admin";
    default:
      return "Admin";
  }
};

export const defineNewAccountsColumns = (collectDataForCopy, employees) => {
  let columns = [
    {
      headerName: "Account ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 140,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.id}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "Assigned to",
      field: "adminAccountOwner",
      sortable: true,
      unSortIcon: true,
      width: 193,
      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data.adminAccountOwner,
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Unassigned"}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{admin?.label || "Unassigned"}</span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "Account type",
      field: "accountLink",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.adminStatusForSort}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            {params.data.accountLink ? (
              <FTSBadge className="dark">Sub-account</FTSBadge>
            ) : (
              <FTSBadge
                style={{
                  backgroundColor: "#E5EAEF",
                  color: "#121A22",
                }}
              >
                Main account
              </FTSBadge>
            )}
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "companyNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 386,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpgrades"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.city}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Source",
      field: "source",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        let a = handleSouceIconName({ source: params.data.source });
        console.log(a, "a");
        return (
          <div className="d-flex align-items-center">
            <span style={{ marginRight: "8px" }}>
              <Icon
                name={handleSouceIconName({ source: params.data.source })}
              />
            </span>
            <span>{capitalize(params.data.source)}</span>
          </div>
        );
      },
    },
    {
      headerName: "Address",
      field: "addressForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.address}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.address}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email",
      field: "emailForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextMenuUpgrades"
          >
            <span>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuUpgrades"
          >
            <span>{params.data.phoneNumber}</span>
          </ContextMenuTrigger>
        );
      },
    },
  ];

  return columns;
};
