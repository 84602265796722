export const InputVariants = {
    outlined: "outlined",
};
export const InputColors = {
    accent: "accent",
};
export const InputSizes = {
    sm: "sm",
    md: "md",
};
