import React, { Component } from "react";
import { Row, Col, Input } from "reactstrap";
import DataTable from "react-data-table-component";
import classnames from "classnames";
import { formatRawDate } from "../../../configs/dateConfig";
import Spinner from "../../../components/@vuexy/spinner/Loading-spinner";
import ReactPaginate from "react-paginate";
import { history } from "../../../history";
import {
  Eye,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Download,
} from "react-feather";
import { connect } from "react-redux";
import {
  getInvoicesData,
  filterInvoicesTypes,
  downloadInvoice,
} from "../../../redux/actions/invoices";
import Sidebar from "./InvoiceSidebar";

import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";
import "../../../assets/scss/plugins/extensions/react-paginate.scss";
import "../../../assets/scss/pages/data-list.scss";

const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: "rgba(115,103,240,.05)",
      color: "#7367F0 !important",
      boxShadow: "0 0 1px 0 #7367F0 !important",
      "&:hover": {
        transform: "translateY(0px) !important",
      },
    },
  },
};

const ActionsComponent = (props) => {
  return (
    <div className="data-list-action">
      <Eye
        className="cursor-pointer mr-1"
        size={20}
        onClick={() => {
          return props.currentData(props.row);
        }}
      />
      {props.row.path.length > 0 && (
        <Download
          className="cursor-pointer"
          size={20}
          onClick={() => props.download(props.row.path)}
        />
      )}
    </div>
  );
};

const CustomHeader = (props) => {
  return (
    <>
      <Row className="justify-content-between">
        <Col>
          <div className="filter-section">
            <Input type="text" onChange={(e) => props.handleFilter(e)} />
          </div>
        </Col>
      </Row>
    </>
  );
};

class InvoicesListingConfig extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.invoices.data.length !== state.data.length ||
      state.currentPage !== props.parsedFilter.page
    ) {
      return {
        data: props.invoices.data,
        allData: props.invoices.filteredData,
        totalPages: props.invoices.totalPages,
        currentPage: parseInt(props.parsedFilter.page) - 1,
        rowsPerPage: parseInt(props.parsedFilter.perPage),
        totalRecords: props.invoices.totalRecords,
        sortIndex: props.invoices.sortIndex,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  state = {
    data: [],
    totalPages: 0,
    currentPage: 0,
    columns: [
      {
        name: "ID",
        selector: "id",
        maxWidth: "40px",
        sortable: true,
        cell: (row) => (
          <p title={row.id} className="text-truncate text-bold-500 mb-0">
            {row.id}
          </p>
        ),
      },
      {
        name: "Invoice Number",
        selector: "invoiceNumber",
        sortable: true,
        cell: (row) => (
          <p
            title={row.invoiceNumber}
            className="text-truncate text-bold-500 mb-0"
          >
            {row.invoiceNumber}
          </p>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        cell: (row) => (
          <p title={row.status} className="text-truncate text-bold-500 mb-0">
            {row.status}
          </p>
        ),
      },
      {
        name: "Amount",
        selector: "amount",
        sortable: true,
        cell: (row) => (
          <p title={row.amount} className="text-truncate text-bold-500 mb-0">
            {`$${row.amount}`}
          </p>
        ),
      },
      {
        name: "Created at",
        selector: "createdAt",
        sortable: true,
        cell: (row) => (
          <p
            title={formatRawDate(row.createdAt)}
            className="text-truncate text-bold-500 mb-0"
          >
            {`${formatRawDate(row.createdAt)}`}
          </p>
        ),
      },
      {
        name: "Actions",
        maxWidth: "100px",
        cell: (row) => (
          <ActionsComponent
            row={row}
            download={this.handleDownloadInvoice}
            currentData={this.handleCurrentData}
          />
        ),
      },
    ],
    allData: [],
    value: "",
    rowsPerPage: 20,
    sidebar: false,
    inProgress: false,
    currentData: null,
    selected: [],
    totalRecords: 0,
    sortIndex: [],
    addNew: "",
  };

  thumbView = this.props.thumbView;
  adTypeToDelete = null;

  componentDidMount() {
    this.refreshList();
  }

  componentDidUpdate() {}

  refreshList = () => {
    this.setState({ inProgress: true });
    this.props.getInvoicesData(this.props.parsedFilter).finally(() => {
      this.setState({ inProgress: false });
    });
  };

  handleFilter = (e) => {
    this.setState({ value: e.target.value });
    this.props.filterInvoicesTypes(e.target.value);
  };

  handleRowsPerPage = (value) => {
    let { parsedFilter, getData } = this.props;
    let page = parsedFilter.page !== undefined ? parsedFilter.page : 1;
    history.push(`/data-list/list-view?page=${page}&perPage=${value}`);
    this.setState({ rowsPerPage: value });
    getData({ page: page, perPage: value });
  };

  handleSidebar = (boolean, addNew = false) => {
    this.setState({ sidebar: boolean });
    if (addNew === true) this.setState({ currentData: null, addNew: true });
  };

  handleDelete = () => {};

  handleCurrentData = (obj) => {
    this.setState({ currentData: obj });
    this.handleSidebar(true);
  };

  handlePagination = (page) => {
    let { parsedFilter, getInvoicesData } = this.props;
    let perPage =
      parsedFilter.perPage !== undefined ? parsedFilter.perPage : 20;
    let urlPrefix = "/invoices";
    history.push(`${urlPrefix}?page=${page.selected + 1}&perPage=${perPage}`);
    this.setState({ inProgress: true });
    getInvoicesData({ page: page.selected + 1, perPage }).finally(() => {
      this.setState({ inProgress: false, currentPage: page.selected });
    });
  };

  handleDownloadInvoice = (path) => {
    this.props
      .downloadInvoice({ path })
      .then(({ data }) => {
        window.open(data, "_blank");
      })
      .catch((error) => {
        console.log("====================================");
        console.log("error = ", error);
        console.log("====================================");
      });
  };

  render() {
    let {
      columns,
      data,
      allData,
      totalPages,
      value,
      rowsPerPage,
      currentData,
      sidebar,
      totalRecords,
      sortIndex,
      inProgress,
    } = this.state;

    return inProgress ? (
      <div className="listingSpinner" style={{ marginTop: 150 }}>
        <Spinner />
      </div>
    ) : (
      <div
        className={`data-list ${
          this.props.thumbView ? "thumb-view" : "list-view"
        }`}
      >
        <DataTable
          columns={columns}
          data={value.length ? allData : data}
          pagination
          paginationServer
          paginationComponent={() => (
            <ReactPaginate
              previousLabel={<ChevronLeft size={15} />}
              nextLabel={<ChevronRight size={15} />}
              breakLabel="..."
              breakClassName="break-me"
              pageCount={totalPages}
              containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
              activeClassName="active"
              forcePage={
                this.props.parsedFilter.page
                  ? parseInt(this.props.parsedFilter.page - 1)
                  : 0
              }
              onPageChange={(page) => this.handlePagination(page)}
            />
          )}
          noHeader
          subHeader
          responsive
          pointerOnHover
          customStyles={selectedStyle}
          subHeaderComponent={
            <CustomHeader
              handleSidebar={this.handleSidebar}
              handleFilter={this.handleFilter}
              handleRowsPerPage={this.handleRowsPerPage}
              rowsPerPage={rowsPerPage}
              total={totalRecords}
              index={sortIndex}
            />
          }
          sortIcon={<ChevronDown />}
        />
        <Sidebar
          show={sidebar}
          data={currentData}
          onInvoiceDownload={this.handleDownloadInvoice}
          handleSidebar={this.handleSidebar}
          thumbView={this.props.thumbView}
          getData={this.props.getData}
          dataParams={this.props.parsedFilter}
          addNew={this.state.addNew}
        />
        <div
          className={classnames("data-list-overlay", {
            show: sidebar,
          })}
          onClick={() => this.handleSidebar(false, true)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices,
  };
};

export default connect(mapStateToProps, {
  getInvoicesData,
  filterInvoicesTypes,
  downloadInvoice,
})(InvoicesListingConfig);
