import FTSAxios from "../../../axios/fts.instance";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import { generateFilters, serialize } from "../../../utility/serialize";
import { isBoolean } from "lodash";

export const searchListings = ({
  page,
  resultsPerPage,
  searchString,
  sort,
  range,
  ...rest
}) => {
  return () => {
    let adsServiceAmenitiesStrings = rest.adsServiceAmenitiesStrings;
    let adsSubCategoriesStrings = rest.adsSubCategoriesStrings;
    if (rest.serviceAmenities && rest.serviceAmenities.length) {
      adsServiceAmenitiesStrings = rest.serviceAmenities.map((e) => e.name);
    }
    if (rest.subCategory) {
      adsSubCategoriesStrings = rest.subCategory;
    }
    const newFilters = {
      ...rest,
      adsServiceAmenitiesStrings,
      adsSubCategoriesStrings,
      name: rest.name,
      includeInAuthorizedPromotions: rest.includeInAuthorizedPromotions
        ? 1
        : "",
      includeInFeaturedVendors: rest.includeInFeaturedVendors ? 1 : "",
      isPrivate: rest.isPrivate || "",
    };

    let { multi_match, filter } = generateFilters(newFilters);

    filter = filter.filter((e) => !!e);
    if (range) filter.push({ range });
    let newSearchString = searchString && searchString.trim();

    if (newSearchString) {
      const slugRegex = new RegExp("^[0-9]*$");
      if (slugRegex.test(newSearchString))
        newSearchString = "#" + newSearchString;

      multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(newSearchString.trim())
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            newSearchString.replace(/ /g, " AND ") +
            ")",
          fields: [
            "statusString",
            "idString",
            "userIdString",
            "paymentStatus",
            "updatedAtString",
            "renewalTimestampString",
            "email",
            "name",
            "address",
            "city",
            "state",
            "phoneNumber",
            "phoneNumberSecondary",
            "afterHours",
          ],

          type: "phrase_prefix",
        },
      });
    }

    return FTSAxios.post("admin/listings/search", {
      page: page || 1,
      pageSize: resultsPerPage,
      multi_match,
      filter,
      sort,
    });
  };
};

export const searchUpgradesListings = ({
  page,
  resultsPerPage,
  searchString,
  sort,
  range,
  ...rest
}) => {
  return () => {
    let adsServiceAmenitiesStrings = rest.adsServiceAmenitiesStrings;
    let adsSubCategoriesStrings = rest.adsSubCategoriesStrings;
    if (rest.serviceAmenities && rest.serviceAmenities.length) {
      adsServiceAmenitiesStrings = rest.serviceAmenities.map((e) => e.name);
    }
    if (rest.subCategory) {
      adsSubCategoriesStrings = rest.subCategory;
    }
    const newFilters = {
      ...rest,
      adsServiceAmenitiesStrings,
      adsSubCategoriesStrings,
      name: rfc3986EncodeURIComponent(rest.name),
      includeInAuthorizedPromotions: rest.includeInAuthorizedPromotions
        ? 1
        : "",
      includeInFeaturedVendors: rest.includeInFeaturedVendors ? 1 : "",
    };

    let { multi_match, filter } = generateFilters(newFilters);

    filter = filter.filter((e) => !!e);
    if (range) filter.push({ range });
    let newSearchString = searchString && searchString.trim();

    if (newSearchString) {
      const slugRegex = new RegExp("^[0-9]*$");
      if (slugRegex.test(newSearchString))
        newSearchString = "#" + newSearchString;

      multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(newSearchString.trim())
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            newSearchString.replace(/ /g, " AND ") +
            ")",
          fields: [
            "statusString",
            "idString",
            "userIdString",
            "paymentStatus",
            "updatedAtString",
            "renewalTimestampString",
            "email",
            "name",
            "address",
            "city",
            "state",
            "phoneNumber",
            "phoneNumberSecondary",
            "afterHours",
          ],

          type: "phrase_prefix",
        },
      });
    }

    return FTSAxios.post("admin/listings/upgrades/search", {
      page: page || 1,
      pageSize: resultsPerPage,
      multi_match,
      filter,
      sort,
    });
  };
};

export const searchNewListings = ({
  page,
  resultsPerPage,
  searchString,
  sort,
  range,
  ...rest
}) => {
  return () => {
    let adsServiceAmenitiesStrings = rest.adsServiceAmenitiesStrings;
    let adsSubCategoriesStrings = rest.adsSubCategoriesStrings;
    if (rest.serviceAmenities && rest.serviceAmenities.length) {
      adsServiceAmenitiesStrings = rest.serviceAmenities.map((e) => e.name);
    }
    if (rest.subCategory) {
      adsSubCategoriesStrings = rest.subCategory;
    }
    const newFilters = {
      ...rest,
      adsServiceAmenitiesStrings,
      adsSubCategoriesStrings,
      name: rfc3986EncodeURIComponent(rest.name),
      includeInAuthorizedPromotions: rest.includeInAuthorizedPromotions
        ? 1
        : "",
      includeInFeaturedVendors: rest.includeInFeaturedVendors ? 1 : "",
    };

    const initFilter = isBoolean(rest.isPrivate) && {
      term: { isPrivate: rest.isPrivate },
    };

    console.log("newfilters: ", newFilters);
    let { multi_match, filter } = generateFilters(newFilters, initFilter);
    console.log("filter: ", filter);
    filter = filter.filter((e) => !!e);
    if (range) filter.push({ range });
    let newSearchString = searchString && searchString.trim();

    if (newSearchString) {
      const slugRegex = new RegExp("^[0-9]*$");
      if (slugRegex.test(newSearchString))
        newSearchString = "#" + newSearchString;

      multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(newSearchString.trim())
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            newSearchString.replace(/ /g, " AND ") +
            ")",
          fields: [
            "statusString",
            "idString",
            "userIdString",
            "paymentStatus",
            "updatedAtString",
            "renewalTimestampString",
            "email",
            "name",
            "address",
            "city",
            "state",
            "phoneNumber",
            "phoneNumberSecondary",
            "afterHours",
          ],

          type: "phrase_prefix",
        },
      });
    }

    return FTSAxios.post("admin/listings/new-listings/search", {
      page: page || 1,
      pageSize: resultsPerPage,
      multi_match,
      filter,
      sort,
    });
  };
};

export const getListingsData = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);

    return FTSAxios.get(`admin/listings/all?${stringifiedParams}`);
  };
};

export const getListingData = (listingId) => {
  return () => {
    return FTSAxios.get(`admin/listing/${listingId}`);
  };
};

export const getListingCounts = (listingId) => {
  return (dispatch) => {
    return FTSAxios.get(`admin/listing/counts/${listingId}`).then(
      (response) => {
        dispatch({
          type: "GET_LISTING_COUNTS",
          data: response.data,
        });
      },
    );
  };
};

export const updateListingData = (listingId, payload) => {
  return () => {
    return FTSAxios.put(`admin/listings/${listingId}`, payload);
  };
};

export const updateMultipleListings = (data) => {
  return () => {
    return FTSAxios.put("admin/multiple/listings", data);
  };
};

export const createListing = (payload) => {
  return () => {
    return FTSAxios.post("admin/listing/create", payload);
  };
};

export const duplicateListing = (data) => {
  return () => {
    return FTSAxios.post("admin/listing/bulk-create", data);
  };
};

export const removeListing = (listingId) => {
  return () => {
    return FTSAxios.delete(`admin/listing/${listingId}`);
  };
};

export const updateListingType = (payload) => {
  return () => {
    return FTSAxios.put("admin/listings/update-listing-type", payload);
  };
};

export const getLockedListing = () => {
  return () => {
    return FTSAxios.get("admin/listings/locked");
  };
};

export const getNewListingsUnreadCount = () => {
  return () => {
    return FTSAxios.get("admin/listings/count/new-listings");
  };
};

export const setNewListingsUnreadCount = (payload) => {
  return (dispatch) => {
    return dispatch({ type: "SET_LISTINGS_UNREAD_COUNTER", payload });
  };
};

export const requestListingUpdate = (payload) => {
  return () => {
    return FTSAxios.post("admin/listing/request-update", payload);
  };
};

export const sendListingReassignedEmail = (payload) => {
  return () => {
    return FTSAxios.post("admin/listing/send-reassign-listing-email", payload);
  };
};
