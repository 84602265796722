import * as Yup from "yup";
import { phoneNumberValidate } from "../../dist/types/validators/global";

export const accountAltContactValidator = Yup.object().shape({
  altContactFirstName: Yup.string().required("First name is required"),
  altContactLastName: Yup.string().required("Last name is required"),
  altContactPhoneNumber: phoneNumberValidate,
  altContactPhoneNumberSecondary: phoneNumberValidate,
  altContactMobileNumber: phoneNumberValidate,
  altContactEmail: Yup.string().email("Invalid email"),
});
