import React, { PureComponent } from "react";

import "../../assets/scss/components/fts/divider.scss";

export default class Divider extends PureComponent {
  render() {
    const { className, children } = this.props;

    const position = this.props.position
      ? ` divider-${this.props.position}`
      : "";
    const color = this.props.color ? ` divider-${this.props.color}` : "";
    const textColor = this.props.textColor
      ? ` text-${this.props.textColor}`
      : "";
    const lineStyle = this.props.lineStyle
      ? ` divider-${this.props.lineStyle}`
      : "";
    const additionalClasses = className ? ` ${className}` : "";

    return (
      <div
        className={`fts-divider divider${position}${color}${lineStyle}${additionalClasses}`}
      >
        <div className={`divider-text ${textColor}`}>{children}</div>
      </div>
    );
  }
}
