/* eslint-disable indent */
import React from "react";

// returns device type as string based on 3 main breakpoints
const getDevice = (currentWidth) => {
  return currentWidth < 576
    ? "small"
    : currentWidth < 768
    ? "phone"
    : currentWidth < 992
    ? "tablet"
    : currentWidth < 1200
    ? "desktop"
    : "wide_desktop";
};

class DeviceTypeWrapper extends React.Component {
  state = {
    deviceType: getDevice(window.innerWidth),
  };

  // update device type when needed
  updateDeviceType = () => {
    if (this.state.deviceType === getDevice(window.innerWidth)) return;
    this.setState({ deviceType: getDevice(window.innerWidth) });
  };

  isSmallPhone = () => this.state.deviceType === "small";

  isPhone = () => this.state.deviceType === "phone";

  isTablet = () => this.state.deviceType === "tablet";

  isDesktop = () => this.state.deviceType === "desktop";

  isWideDesktop = () => this.state.deviceType === "wide_desktop";

  isWideDesktopOrBigger = () => this.isWideDesktop();

  isDesktopOrBigger = () => this.isDesktop() || this.isWideDesktop();

  isPhoneOrSmaller = () => this.isSmallPhone() || this.isPhone();

  isTabletOrSmaller = () => this.isPhoneOrSmaller() || this.isTablet();

  isTabletOrBigger = () => this.isTablet() || this.isDesktopOrBigger();

  isPhoneOrBigger = () => this.isPhone || this.isTabletOrBigger();

  // add resize event listener if window exists
  componentDidMount = () => {
    if (window !== undefined) {
      window.addEventListener("resize", this.updateDeviceType, true);
    }
  };

  // cleanup listener before component un-mounts
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDeviceType, true);
  }

  // add deviceType prop to children
  mapStateToChildrenProps = (children) => {
    return React.Children.map(children, (child) => {
      // if DeviceTypeWrapper is flagged as recursive
      //    add deviceType prop to all children of every child recursively
      //    otherwise just pass the original children again as the new children (no change)
      const childChildren = this.props.recursive
        ? this.mapStateToChildrenProps(child.props.children)
        : child.props.children;

      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          deviceType: this.state.deviceType,
          isSmallPhone: this.isSmallPhone,
          isPhone: this.isPhone,
          isTablet: this.isTablet,
          isDesktop: this.isDesktop,
          isWideDesktop: this.isWideDesktop,
          isPhoneOrBigger: this.isPhoneOrBigger,
          isTabletOrBigger: this.isTabletOrBigger,
          isPhoneOrSmaller: this.isPhoneOrSmaller,
          isTabletOrSmaller: this.isTabletOrSmaller,
          isDesktopOrBigger: this.isDesktopOrBigger,
          isWideDesktopOrBigger: this.isWideDesktopOrBigger,
          children: childChildren,
        });
      }

      return child;
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.mapStateToChildrenProps(this.props.children)}
      </React.Fragment>
    );
  }
}

export default React.memo(DeviceTypeWrapper);
