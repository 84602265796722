import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailValidator } from "../Validators/accountEmailValidator";
import { selectAccountData } from "../../../../redux/reducers/v2/account";
import { setAccountDataAction } from "../../../../redux/actions/v2/account";
import { mobilePhoneValidator } from "../Validators/accountMobilePhoneValidator";
import { additionalEmailValidator } from "../Validators/accountAdditionalEmailValidator";

const validators = {
  email: emailValidator,
  additionalEmail: additionalEmailValidator,
  mobilePhone: mobilePhoneValidator,
};

export default function useCheckboxValidation(validateField, optIns) {
  const [initialValidationDone, setInitialValidationDone] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const accountData = useSelector(selectAccountData) || {};
  const dispatch = useDispatch();
  const validator = validators[validateField];
  const data = accountData[validateField];

  useEffect(() => {
    const validateData = async () => {
      try {
        await validator.validate({ [validateField]: data });
        setIsValid(true);
      } catch {
        setIsValid(false);
      }
    };

    if (!data) return setIsValid(false);
    validateData().then(() => setInitialValidationDone(true));
  }, [data, validator]);

  useEffect(() => {
    if (isValid || !initialValidationDone) return;
    if (!optIns.find((name) => accountData[name])) return;
    let mappedAccountData = { ...accountData };
    optIns.forEach((name) => (mappedAccountData[name] = false));
    dispatch(setAccountDataAction(mappedAccountData));
  }, [isValid, dispatch, initialValidationDone, accountData]);

  return isValid;
}
