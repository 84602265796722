/* eslint-disable indent */
import React, { Component } from "react";
import { Label } from "reactstrap";
import { ErrorMessage } from "formik";

import { pickerStyles, controlStyles } from "../picker-style";
import Select from "../Select";

import { USER_TYPE, USER_TYPES_NAMED } from "../../../constants";

export const types = Object.values(USER_TYPE).map((e) => {
  return {
    value: e,
    label: USER_TYPES_NAMED[e],
    userTypeNamed: e === USER_TYPE.SERVICE_VENDOR ? "vendor" : "user",
  };
});

class AccountTypePicker extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isDisabled !== this.props.isDisabled ||
      nextProps.meta.touched !== this.props.meta.touched ||
      nextProps.meta.value !== this.props.meta.value ||
      nextProps.meta.error !== this.props.meta.error
    );
  }

  render() {
    const {
      name,
      label,
      isDisabled,
      placeholder,
      onBlur,
      setFieldTouched,
      setFieldValue,
      setFieldError,
      meta,
      value,
    } = this.props;

    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) => controlStyles(styles, state, {}, { meta }),
    };

    const defaultValue =
      meta && meta.initialValue
        ? types.find((itm) => itm.value === meta.initialValue)
        : null;

    let isLoading = false;

    if (!types?.length) {
      isLoading = true;
    }

    return (
      <>
        {!!label && (
          <Label className={isDisabled ? "disabled" : ""} for="acc-type-picker">
            {this.props.required && !isDisabled ? `${label} *` : label}
          </Label>
        )}
        <Select
          id="acc-type-picker"
          value={isNaN(value) ? value : defaultValue}
          isClearable
          isDisabled={isDisabled}
          placeholder={placeholder}
          options={types}
          isSearchable={false}
          name={name}
          styles={pickerStyle}
          onChange={(value) => {
            if (
              defaultValue?.value === USER_TYPE.SERVICE_VENDOR &&
              this.props.accountData.activeListingCount
            ) {
              setTimeout(() => {
                setFieldTouched(name, true, false);
                setFieldError(
                  name,
                  "The account type cannot be changed as long as the user has an active list",
                );
              }, 1);
              return;
            }
            if (value) {
              setFieldError(name, undefined);
            }
            setFieldValue(name, value);
            setTimeout(() => {
              setFieldTouched(name, true, true);
            }, 0);
          }}
          {...{ defaultValue, onBlur, isLoading, label }}
        />
        {!!meta && (
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="formik-validation-error">{msg}</div>
            )}
          />
        )}
      </>
    );
  }
}

export default AccountTypePicker;
