import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";
import classnames from "classnames";

class Section extends PureComponent {
  render() {
    const { id, title, notWrapped, first, hasDivider, children } = this.props;
    return (
      <React.Fragment>
        <div
          id={id}
          className={classnames("account-section", {
            "no-border": notWrapped,
            first,
          })}
        >
          {title && (
            <Row>
              <Col>
                <h3 className={notWrapped ? "mb-0" : ""}>{title}</h3>
              </Col>
            </Row>
          )}
          {children}
        </div>
        {hasDivider && <hr />}
      </React.Fragment>
    );
  }
}

export default React.memo(Section);
