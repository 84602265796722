/* eslint-disable indent */
import React, { Component } from "react";
import { Label } from "reactstrap";
import { ErrorMessage } from "formik";

import { pickerStyles, controlStyles } from "../picker-style";
import { connect } from "react-redux";
import Select from "../Select";
import { components } from "react-select";
import chroma from "chroma-js";

const ValueContainer = ({ children, showInnerLabel, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {showInnerLabel && (
          <div
            style={{
              paddingRight: "0.5rem",

              color: "#424C54",
            }}
          >
            Type:
          </div>
        )}
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};

class TodoTypesPicker extends Component {
  state = {
    types: this.props.types,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.isDisabled !== this.props.isDisabled ||
      (nextProps.meta && nextProps.meta.error !== this.props.meta.error) ||
      (!!this.props.meta &&
        nextProps.meta.touched !== this.props.meta.touched) ||
      this.state.todoTypes !== nextState.todoTypes ||
      nextProps.value !== this.props.value
    );
  }

  render() {
    const {
      label,
      meta,
      name,
      isDisabled,
      hasAll,
      value,
      taskManagement,
      ...rest
    } = this.props;

    let todoTypes = this.state.types;

    todoTypes = todoTypes.filter(function (obj) {
      if (!taskManagement) {
        return obj.type !== "note" && obj.description !== "Personal";
      }
      return obj.type !== "note";
    });
    todoTypes.forEach((obj) => {
      obj.value = obj.description?.toLowerCase();
      obj.label = obj.description;
    });

    if (hasAll || taskManagement) {
      todoTypes = [
        { label: "All", value: "", type: "both", color: "#7d8c91" },
        ...todoTypes,
      ];
    }

    const pickerStyle = {
      ...pickerStyles,
      borderWidth: 0,
      control: (styles, state) => controlStyles(styles, state, { meta }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.1).css()
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? chroma.contrast(color, "white") > 2
              ? "white"
              : "black"
            : data.color,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
          },
        };
      },

      multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: color.alpha(0.1).css(),
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ":hover": {
          backgroundColor: data.color,
          color: "white",
        },
      }),
    };

    console.log(meta);
    let defaultValue =
      meta && meta.initialValue && !meta.initialValue.value
        ? todoTypes.find(
            (itm) =>
              itm.value.toLowerCase() === meta?.initialValue?.toLowerCase(),
          )
        : null;

    if (hasAll) {
      defaultValue = todoTypes.find((itm) => itm.label === "All");
    }

    let valueInfo = typeof value !== "object" ? defaultValue : value;

    let isLoading = false;

    if (!todoTypes?.length) {
      isLoading = true;
    }
    return (
      <>
        {!!label && (
          <Label
            className={isDisabled ? "disabled" : ""}
            for="activation-status"
          >
            {this.props.required && !isDisabled ? `${label} *` : label}
          </Label>
        )}
        <Select
          {...{ defaultValue, isDisabled, isLoading, label }}
          isSearchable={true}
          options={this.props.options || todoTypes}
          styles={pickerStyle}
          name={name}
          components={{
            ValueContainer: (props) => (
              <ValueContainer showInnerLabel={hasAll} {...props} />
            ),
          }}
          value={valueInfo}
          {...rest}
        />
        <ErrorMessage
          name={name}
          render={(msg) => <div className="formik-validation-error">{msg}</div>}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.essentialLists.types,
  };
};

export default connect(mapStateToProps)(TodoTypesPicker);
