import { VariantsValues } from "@find-truck-service/types/ui/text";
export const ChipLabelSizeBySize = {
    lg: VariantsValues["text-2xs"],
    md: VariantsValues["text-3xs"],
    sm: VariantsValues["text-4xs"],
};
export const ChipGapBySizesValues = {
    lg: "sm",
    md: "xs",
    sm: "2xs",
};
