/* eslint-disable indent */
/* eslint-disable react/display-name */
import React from "react";

import { ContextMenuTrigger } from "react-contextmenu";
import moment from "moment";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues } from "@find-truck-service/types/ui/text";

const colorStatusID = (status) => {
  let color;
  let label;
  switch (status) {
    case 1:
      color = "green-success-600";
      label = "Active";
      break;

    case 2:
      color = "red-error-600";
      label = "Inactive";
      break;

    default:
      color = "greyscale-500";
      label = "Disabled";
      break;
  }

  return { label, color };
};

export const updatesColumns = (collectDataForCopy, employees) => {
  return [
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      unSortIcon: true,
      width: 95,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const { color, label } = colorStatusID(params.data.status);

        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <Span variant={VariantsValues["text-3xs"]} color={color}>
              {label}
            </Span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last updated",
      field: "updatedAt",
      sortable: true,
      unSortIcon: true,
      width: 165,
      cellRendererFramework: (params) => {
        const now = moment().utc();
        const days = now.diff(moment(params.data.updatedAt).utc(), "days");

        let lastUpdateString;
        if (days === 0) {
          lastUpdateString = "Today";
        } else if (days === 1) {
          lastUpdateString = "Yesterday";
        } else if (
          params.data.updatedAt === "1970-01-01T00:00:00.000Z" ||
          days < 0
        ) {
          lastUpdateString = "No date";
        } else {
          lastUpdateString = `${days} days ago`;
        }

        return (
          <ContextMenuTrigger
            data={lastUpdateString}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <Span
              color={ColorsValue["greyscale-800"]}
              variant={VariantsValues["text-3xs"]}
            >
              {lastUpdateString}
            </Span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Owner",
      field: "adminAccountOwnerId",
      sortable: true,
      unSortIcon: true,
      width: 140,
      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data?.user?.adminAccountOwner,
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Unassigned"}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <Span
              color={ColorsValue["greyscale-800"]}
              variant={VariantsValues["text-3xs"]}
            >
              {admin?.label || "Unassigned"}
            </Span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "CID",
      field: "userId",
      sortable: true,
      unSortIcon: true,
      width: 90,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <Span
            color={ColorsValue["greyscale-800"]}
            variant={VariantsValues["text-3xs"]}
          >
            {params.value}
          </Span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "LID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 115,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <Span
            color={ColorsValue["greyscale-800"]}
            variant={VariantsValues["text-3xs"]}
          >
            {params.value}
          </Span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Company name",
      field: "companyName",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      minWidth: 240,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.companyName}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <Span
            color={ColorsValue["greyscale-800"]}
            variant={VariantsValues["text-3xs"]}
          >
            {params.value}
          </Span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "City",
      field: "city",
      sortable: true,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <Span
            color={ColorsValue["greyscale-800"]}
            variant={VariantsValues["text-3xs"]}
          >
            {params.value}
          </Span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "ST",
      field: "state",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <Span
            color={ColorsValue["greyscale-800"]}
            variant={VariantsValues["text-3xs"]}
          >
            {params.value}
          </Span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Primary",
      field: "phoneNumber",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.value || ""}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuUpdatesTab"
          >
            <Span
              color={ColorsValue["greyscale-800"]}
              variant={VariantsValues["text-3xs"]}
            >
              {params.value || ""}
            </Span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last updated by",
      field: "adminLastUpdatedBy",
      sortable: true,
      unSortIcon: true,
      width: 180,

      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data?.user?.adminLastUpdatedBy,
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Vendor"}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <Span
              color={ColorsValue["greyscale-800"]}
              variant={VariantsValues["text-3xs"]}
            >
              {admin?.label || "Vendor"}
            </Span>
          </ContextMenuTrigger>
        );
      },
    },
  ];
};

export const updateRequestsColumns = (collectDataForCopy, employees) => {
  return [
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      unSortIcon: true,
      width: 95,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const { color, label } = colorStatusID(params.data.status);
        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div style={{ color: color }}>{label}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last update",
      field: "updatedAt",
      sortable: true,
      unSortIcon: true,
      width: 165,
      cellRendererFramework: (params) => {
        const now = moment().utc();
        const days = now.diff(moment(params.data.updatedAt).utc(), "days");

        let lastUpdateString;
        if (days === 0) {
          lastUpdateString = "Today";
        } else if (days === 1) {
          lastUpdateString = "Yesterday";
        } else if (
          params.data.updatedAt === "1970-01-01T00:00:00.000Z" ||
          days < 0
        ) {
          lastUpdateString = "No date";
        } else {
          lastUpdateString = `${days} days ago`;
        }

        return (
          <ContextMenuTrigger
            data={lastUpdateString}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div>{lastUpdateString}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Owner",
      field: "adminAccountOwnerId",
      sortable: true,
      unSortIcon: true,
      width: 140,
      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data?.user?.adminAccountOwner,
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Unassigned"}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{admin?.label || "Unassigned"}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "CID",
      field: "userId",
      sortable: true,
      unSortIcon: true,
      width: 90,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "LID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 115,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Company name",
      field: "companyName",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "City",
      field: "city",
      sortable: true,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "ST",
      field: "state",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },

    {
      headerName: "Primary",
      field: "phoneNumber",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.value || ""}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuUpdatesTab"
          >
            <span>{params.value || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
  ];
};

export const rejectedRequestsColumns = (
  togglePopOver,
  collectDataForCopy,
  employees,
) => {
  return [
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      unSortIcon: true,
      width: 95,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const { color, label } = colorStatusID(params.data.listing.status);
        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div style={{ color: color }}>{label}</div>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "Last update",
      field: "updatedAt",
      sortable: true,
      unSortIcon: true,
      width: 165,
      cellRendererFramework: (params) => {
        const now = moment().utc();
        const days = now.diff(moment(params.data.updatedAt).utc(), "days");

        let lastUpdateString;
        if (days === 0) {
          lastUpdateString = "Today";
        } else if (days === 1) {
          lastUpdateString = "Yesterday";
        } else if (
          params.data.updatedAt === "1970-01-01T00:00:00.000Z" ||
          days < 0
        ) {
          lastUpdateString = "No date";
        } else {
          lastUpdateString = `${days} days ago`;
        }

        return (
          <ContextMenuTrigger
            data={lastUpdateString}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div>{lastUpdateString}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Update requested",
      field: "sendAt",
      sortable: false,
      unSortIcon: true,
      minWidth: 180,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let date = moment(params.data.createdAt).format("lll");
        return (
          <ContextMenuTrigger
            data={date}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div>{date}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Rejected by",
      field: "rejectedByAdminId",
      sortable: false,
      unSortIcon: true,
      minWidth: 180,

      cellRendererFramework: (params) => {
        let adminName = `${params.data.rejectedByAdmin?.firstName || ""} ${
          params.data.rejectedByAdmin?.lastName || ""
        }`;
        return (
          <ContextMenuTrigger
            data={adminName}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div>{adminName}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Requested on",
      field: "updatedAt",
      sortable: false,
      unSortIcon: true,
      minWidth: 180,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let date = moment(params.data.updatedAt).format("lll");
        return (
          <ContextMenuTrigger
            data={date}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div>{date}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Reason",
      field: "reason",
      sortable: true,
      unSortIcon: true,
      minWidth: 500,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.reason}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div>{params.data.reason}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Owner",
      field: "admin_owner",
      sortable: true,
      unSortIcon: true,
      width: 140,
      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data?.listing?.user?.adminAccountOwner,
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Unassigned"}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{admin?.label || "Unassigned"}</span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "CID",
      field: "userId",
      sortable: true,
      unSortIcon: true,
      width: 90,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.listing.userId}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.data.listing.userId}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "LID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 115,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.listing.id}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.data.listing.id}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Company name",
      field: "companyName",
      sortable: true,
      unSortIcon: true,
      minWidth: 200,
      flex: 1,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.listing.name}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.data.listing.name}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "City",
      field: "city",
      sortable: true,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.listing.city}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.data.listing.city}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "ST",
      field: "state",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.listing.state}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.data.listing.state}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Primary",
      field: "phoneNumber",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.listing.phoneNumber || ""}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuUpdatesTab"
          >
            <span>{params.data.listing.phoneNumber || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
  ];
};

export const sentRequestsColumns = (
  togglePopOver,
  collectDataForCopy,
  employees,
) => {
  return [
    {
      headerName: "Last updated",
      field: "updatedAt",
      sortable: true,
      unSortIcon: true,
      width: 165,
      cellRendererFramework: (params) => {
        const now = moment().utc();
        const days = now.diff(moment(params.data.updatedAt).utc(), "days");

        let lastUpdateString;
        if (days === 0) {
          lastUpdateString = "Today";
        } else if (days === 1) {
          lastUpdateString = "Yesterday";
        } else if (
          params.data.updatedAt === "1970-01-01T00:00:00.000Z" ||
          days < 0
        ) {
          lastUpdateString = "No date";
        } else {
          lastUpdateString = `${days} days ago`;
        }

        return (
          <ContextMenuTrigger
            data={lastUpdateString}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div>{lastUpdateString}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Request sent",
      field: "sendAt",
      sortable: false,
      unSortIcon: true,
      minWidth: 180,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let date = moment(params.data.updatedAt).format("lll");
        return (
          <ContextMenuTrigger
            data={date}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div>{date}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Owner",
      field: "admin_owner",
      sortable: true,
      unSortIcon: true,
      width: 140,
      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data?.user?.adminAccountOwner,
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Unassigned"}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{admin?.label || "Unassigned"}</span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "CID",
      field: "userId",
      sortable: true,
      unSortIcon: true,
      width: 90,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Listing ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 115,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Company name",
      field: "companyName",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "City",
      field: "city",
      sortable: true,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "State",
      field: "state",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      unSortIcon: true,
      width: 95,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const { color, label } = colorStatusID(params.data.status);
        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div style={{ color: color }}>{label}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumber",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.value || ""}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuUpdatesTab"
          >
            <span>{params.value || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
  ];
};

export const onHoldColumns = (togglePopOver, collectDataForCopy, employees) => {
  return [
    {
      headerName: "Last updated",
      field: "updatedAt",
      sortable: true,
      unSortIcon: true,
      width: 165,
      cellRendererFramework: (params) => {
        const now = moment().utc();
        const days = now.diff(moment(params.data.updatedAt).utc(), "days");

        let lastUpdateString;
        if (days === 0) {
          lastUpdateString = "Today";
        } else if (days === 1) {
          lastUpdateString = "Yesterday";
        } else if (
          params.data.updatedAt === "1970-01-01T00:00:00.000Z" ||
          days < 0
        ) {
          lastUpdateString = "No date";
        } else {
          lastUpdateString = `${days} days ago`;
        }

        return (
          <ContextMenuTrigger
            data={lastUpdateString}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div>{lastUpdateString}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "On hold by",
      field: "onHoldByAdmin",
      sortable: true,
      unSortIcon: true,
      width: 130,
      cellRendererFramework: (params) => {
        const firstName = params.data.onHoldByAdmin.firstName;
        const lastName = params.data.onHoldByAdmin.lastName;
        return (
          <ContextMenuTrigger
            data={`${firstName} ${lastName}`}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            {firstName} {lastName}
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Admin owner",
      field: "admin_owner",
      sortable: true,
      unSortIcon: true,
      width: 140,
      cellRendererFramework: (params) => {
        let admin = employees?.find(
          (e) => e.value === params.data?.user?.adminAccountOwner,
        );
        return (
          <ContextMenuTrigger
            data={admin?.label || "Unassigned"}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{admin?.label || "Unassigned"}</span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "CID",
      field: "userId",
      sortable: true,
      unSortIcon: true,
      width: 90,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Listing ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 115,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Company name",
      field: "companyName",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "City",
      field: "city",
      sortable: true,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "State",
      field: "state",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id="contextMenuUpdatesTab"
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      unSortIcon: true,
      width: 95,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        const { color, label } = colorStatusID(params.data.status);
        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextMenuUpdatesTab"
          >
            <div style={{ color: color }}>{label}</div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumber",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.value || ""}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuUpdatesTab"
          >
            <span>{params.value || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
  ];
};
