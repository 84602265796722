import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import {
  getRemindersRequest,
  selectIsLoading,
  selectReminders,
  getRemindersSuccess,
  getRemindersFailed,
  getRemindersByTypeSuccess,
  selectQueryString,
  setQueryString,
  resetQueryString,
} from "../../../redux/reducers/task-managment";
import { activities } from "../../../constants";
import { createActivity } from "../../../redux/actions/activities";
import Filter from "../../../components/fts/filter";
import moment from "moment";
import queryString from "query-string";
import { toast } from "react-toastify";
import TaskWrapper from "../task-management/TaskWrapper";
import {
  updateTodo,
  getTodo,
  archiveTodo,
  unArchiveTodo,
} from "../../../redux/actions/todo";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import TodoEmpty from "../../../components/fts/todos/TodoEmpty";
import { extractParameter } from "../../../utility/param-extract";
import ConfirmationModal from "../../../components/fts/confirmation-modal";

const TABS = [
  {
    label: "All",
    type: "all",
  },
  {
    label: "Today",
    type: "today",
  },
  {
    label: "7 days",
    type: "7days",
  },
  {
    label: "30 days",
    type: "30days",
  },
];

// query += `&toDate=${today}`;
const today = moment().add(1, "days").format("YYYY-MM-DD");

const TaskManagementNew = (props) => {
  const {
    userData,
    getRemindersRequest,
    todoTypes,
    reminders,
    isLoading,
    getRemindersSuccess,
    getRemindersByTypeSuccess,
    updateTodo,
    // getTodo,
    list,
    archiveTodo,
    unArchiveTodo,
    query,
    setQueryString,
    accountId,
    listingId,
    activitiesTypes,
    createActivity,
    resetQueryString,
  } = props;

  const { todoStatus: queryTodoStatus, todoType: queryTodoType } =
    queryString.parse(query);
  const didMount = useRef(null);
  const dateRef = useRef(null);
  const userRef = useRef(null);
  const formikSidebarRef = useRef(null);
  // const [activeTab, setActiveTab] = useState("today");
  const [dateValue, setDateValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [todoType, setTodoType] = useState("");
  const [sidebarState, setSidebarState] = useState({
    sidebar: false,
    action: null,
    updating: false,
    actionItem: null,
    update: false,
    isOpen: false,
  });

  useEffect(() => {
    const todoIdParam = extractParameter("toDoId");
    if (todoIdParam) {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        action: "todo",
        actionItem: { id: parseInt(todoIdParam) },
        sidebar: true,
        updating: true,
      }));
    }
  }, [setSidebarState]);

  useMemo(() => {
    const queryObj = queryString.parse(query);
    let newQuery = { ...queryObj };
    let { fromDate, toDate } = newQuery;
    if (fromDate && toDate) {
      newQuery.activeTab = "";
    }
    if (!todoType) {
      newQuery.todoType = "";
      setQueryString(newQuery);
    }
    if (todoType) {
      delete newQuery.todoStatus;
      newQuery.todoType = todoType.value;
      setQueryString(newQuery);
    }
  }, [todoType, query, setQueryString]);

  useEffect(() => {
    const listingIdParam = listingId;
    const accountIdParam = accountId;

    let searchTodosFor;
    if (listingIdParam) {
      searchTodosFor = `listingId=${listingIdParam}`;
    } else if (accountIdParam) {
      searchTodosFor = `accountId=${accountIdParam}`;
    } else {
      searchTodosFor = `userId=${userData.loggedInUser.id}`;
    }

    if (!query) {
      setQueryString(
        queryString.parse(
          searchTodosFor + `&toDate=${today}&todoStatus=active&activeTab=today`,
        ),
      );
    }
  }, [query, setQueryString, userData.loggedInUser.id, listingId, accountId]);

  useEffect(() => {
    return () => {
      resetQueryString();
    };
  }, [resetQueryString]);

  useEffect(() => {
    setLoading(true);
    fetchTodos();
  }, [
    query,
    getRemindersSuccess,
    todoTypes,
    getRemindersRequest,
    getRemindersByTypeSuccess,
  ]);

  const handleChangeTodoStatus = useCallback(
    (todoStatus) => {
      const queryObj = queryString.parse(query);
      let newQuery = { ...queryObj };

      if (!todoStatus) {
        newQuery.todoStatus = "";
        return setQueryString({ todoStatus: "" });
      }
      if (todoStatus.value) {
        newQuery.todoStatus = todoStatus.value;
        return setQueryString({ todoStatus: todoStatus.value });
      }
    },
    [query, setQueryString],
  );

  const handleDateChange = useCallback(
    (value) => {
      if (value && value[0] && value[1]) {
        const queryObj = queryString.parse(query);
        let newQuery = { ...queryObj };
        let from = moment(value[0]).format("YYYY-MM-DD");
        let to = moment(value[1]).add(1, "days").format("YYYY-MM-DD");
        newQuery.fromDate = from;
        newQuery.toDate = to;
        newQuery.activeTab = "";
        setDateValue(value);
        setQueryString(newQuery);
      }
    },
    [query, setQueryString],
  );

  const fetchTodos = async () => {
    try {
      const queryObj = queryString.parse(query);
      if (!query) return;

      if (queryObj.todoType) {
        let { data: newData } = await getRemindersRequest(
          queryString.stringify(queryObj),
        );
        getRemindersByTypeSuccess({
          data: newData,
          todoType: queryObj.todoType,
        });
        didMount.current = true;
        return setLoading(false);
      }

      const promises = [];
      todoTypes.map((e) => {
        if (e.type !== "note") {
          queryObj.todoType = e.description.toLowerCase();
          return promises.push(
            getRemindersRequest(queryString.stringify(queryObj)),
          );
        }
        return null;
      });

      Promise.all(promises)
        .then(([...res]) => {
          res.map((e) => {
            let todoType = queryString.parse(e.config.url).todoType;
            queryObj.todoType = "";
            let { data } = e;
            getRemindersSuccess({
              data,
              todoType,
              queryString: queryString.stringify(queryObj),
            });
            didMount.current = true;
            return setLoading(false);
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Oops! Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (e) {
      console.log("ERRROR: ", e);
    }
  };

  const handleActiveTabChanged = useCallback(
    (value) => {
      if (isLoading) return;
      setDateValue("");

      const queryObj = queryString.parse(query);
      if (value === "all") {
        // let today = moment().add(1, "days").format("YYYY-MM-DD");
        queryObj.fromDate = "";
        queryObj.toDate = "";
      }
      if (value === "today") {
        let today = moment().add(1, "days").format("YYYY-MM-DD");
        queryObj.fromDate = "";
        queryObj.toDate = today;
      }
      if (value === "7days") {
        let to = moment().add(8, "days").format("YYYY-MM-DD");
        queryObj.fromDate = "";
        queryObj.toDate = to;
      }
      if (value === "30days") {
        let to = moment().add(31, "days").format("YYYY-MM-DD");
        queryObj.fromDate = "";
        queryObj.toDate = to;
      }
      queryObj.activeTab = value;

      if (!queryObj.todoStatus) queryObj.todoStatus = "active";

      setQueryString(queryObj);
      // setActiveTab(value);
      setDateValue(null);
      if (dateRef?.current?.flatpickr) {
        dateRef.current.flatpickr.clear();
      }
    },
    [setQueryString, query, isLoading],
  );

  const handleTodoTypeChange = (todoType) => {
    setTodoType(todoType);
  };

  if (!query) return null;

  const { toDate, fromDate, activeTab } = queryString.parse(query);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    const { reminders } = props;
    if (!destination || !source) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      return;
    }

    let type = todoTypes.find((e) => e.id === parseInt(source.droppableId));
    let dropToType = todoTypes.find(
      (e) => e.id === parseInt(destination.droppableId),
    );

    if (dropToType.value === "personal") return;
    if (type.value === "personal") return;

    let draggingTask = {};

    const newSourceReminders = [];
    const newDestinationReminders = reminders[dropToType.value].todos;

    reminders[type.value].todos.map((e) => {
      if (e.id === parseInt(draggableId)) return (draggingTask = e);
      return newSourceReminders.push({ ...e });
    });

    if (draggingTask.adminOwnerId !== userData.loggedInUser.id) {
      return;
    }
    if (
      !draggingTask.todoAdmins.find((e) => e === userData.loggedInUser.id) &&
      draggingTask.adminOwnerId !== userData.loggedInUser.id
    ) {
      return;
    }
    const sendPayload = {
      id: draggingTask.id,
      ownerId: draggingTask.ownerId,
      adminIds: draggingTask.todoAdmins.map((admin) => admin.id),
      adminOwnerId: draggingTask.adminOwnerId,
      reminderDate: draggingTask.reminderDate.split("T")[0],
      reminderTime: draggingTask.reminderTime,
      title: draggingTask.title,
      todo: draggingTask.todo,
      todoPriority: draggingTask.todoPriority,
      todoStatus: draggingTask.todoStatus,
      userId: draggingTask.userId,
      todoType: dropToType.value,
    };

    updateTodo(sendPayload)
      .then(() => {
        // this.startSearch();
      })
      .catch((err) => {
        console.log("err = ", err);
      });

    getRemindersSuccess({
      data: {
        todos: newSourceReminders,
        count: reminders[type.value].count - 1,
      },
      todoType: type.value,
      queryString: query,
    });

    if (
      new Date(draggingTask.reminderDate) <
        new Date(
          newDestinationReminders[
            newDestinationReminders.length - 1
          ].reminderDate,
        ) ||
      reminders[dropToType.value].todos.length ===
        reminders[dropToType.value].count
    ) {
      let targetTodo = newDestinationReminders[destination.index];
      if (
        new Date(targetTodo.reminderDate) < new Date(draggingTask.reminderDate)
      ) {
        let a = [...newDestinationReminders];
        a.splice(destination.index, 0, {
          ...draggingTask,
          transform: "down",
        });
        getRemindersSuccess({
          data: {
            todos: a,
            count: reminders[dropToType.value].count,
          },
          todoType: dropToType.value,
          queryString: query,
        });

        setTimeout(() => {
          a.sort((a, b) => new Date(a.reminderDate) - new Date(b.reminderDate));
          getRemindersSuccess({
            data: {
              todos: a,
              count: reminders[dropToType.value].count + 1,
            },
            todoType: dropToType.value,
            queryString: query,
          });
        }, 1800);
      } else {
        let a = [...newDestinationReminders];
        a.splice(destination.index, 0, {
          ...draggingTask,
          transform: "up",
        });
        getRemindersSuccess({
          data: {
            todos: a,
            count: reminders[dropToType.value].count,
          },
          todoType: dropToType.value,
          queryString: query,
        });

        setTimeout(() => {
          a.sort((a, b) => new Date(a.reminderDate) - new Date(b.reminderDate));
          getRemindersSuccess({
            data: {
              todos: a,
              count: reminders[dropToType.value].count + 1,
            },
            todoType: dropToType.value,
            queryString: query,
          });
        }, 1800);
      }
    } else {
      let a = [...newDestinationReminders];
      a.splice(destination.index, 0, {
        ...draggingTask,
        transform: "down",
      });
      getRemindersSuccess({
        data: {
          todos: a,
          count: reminders[dropToType.value].count,
        },
        todoType: dropToType.value,
        queryString: query,
      });

      setTimeout(() => {
        // newDestinationReminders.push(draggingTask);
        getRemindersSuccess({
          data: {
            todos: newDestinationReminders,
            count: reminders[dropToType.value].count,
          },
          todoType: dropToType.value,
          queryString: query,
        });
      }, 1800);
    }
  };

  const handleSidebar = (bool, update, force) => {
    if (force) {
      const queryObj = queryString.parse(query);
      let newQuery = { ...queryObj };
      newQuery.todoType = "";
      setSidebarState({
        ...sidebarState,
        sidebar: false,
        update: false,
        isOpen: false,
      });
      setLoading(true);
      // return setQueryString(queryString.stringify(queryObj));
      const promises = [];
      todoTypes.map((e) => {
        if (e.type !== "note") {
          queryObj.todoType = e.description.toLowerCase();
          return promises.push(
            getRemindersRequest(queryString.stringify(queryObj)),
          );
        }
        return null;
      });

      return Promise.all(promises)
        .then(([...res]) => {
          res.map((e) => {
            let todoType = queryString.parse(e.config.url).todoType;
            queryObj.todoType = "";
            let { data } = e;
            getRemindersSuccess({
              data,
              todoType,
              queryString: queryString.stringify(queryObj),
            });
            return setLoading(false);
          });
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
          toast.error("Oops! Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
    if (bool === false && formikSidebarRef.current?.dirty) {
      return setSidebarState({
        ...sidebarState,
        isOpen: true,
      });
    } else {
      return setSidebarState({
        ...sidebarState,
        sidebar: bool,
        updating: !!update,
      });
    }
  };

  const onTodoListUpdate = (id) => {
    let queryObj = queryString.parse(query);
    queryObj.todoType = "";
    queryObj.id = id;
    setQueryString(queryObj);
  };

  const handleTodoTypeUpdate = (data, type) => {
    let dateFromList =
      reminders[type.description.toLowerCase()].todos.length &&
      new Date(
        reminders[type.description.toLowerCase()].todos[
          reminders[type.description.toLowerCase()].todos.length - 1
        ].reminderDate,
      );
    let newDestinationReminders = [
      ...reminders[type.description.toLowerCase()].todos,
    ];

    let newSourceReminders = [];
    reminders[data.todoType].todos.map((e) => {
      if (e.id !== data.id) return newSourceReminders.push(e);
      return null;
    });

    if (
      (dateFromList && new Date(data.reminderDate) < dateFromList) ||
      reminders[type.description.toLowerCase()].count ===
        reminders[type.description.toLowerCase()].todos.length
    ) {
      newDestinationReminders.push({
        ...data,
        todoType: type.description.toLowerCase(),
      });
      newDestinationReminders.sort(
        (a, b) => new Date(a.reminderDate) - new Date(b.reminderDate),
      );
      getRemindersSuccess({
        data: {
          todos: newDestinationReminders,
          count: reminders[type.description.toLowerCase()].count + 1,
        },
        todoType: type.description.toLowerCase(),
        queryString: query,
      });
    } else {
      getRemindersSuccess({
        data: {
          todos: newDestinationReminders,
          count: reminders[type.description.toLowerCase()].count + 1,
        },
        todoType: type.description.toLowerCase(),
        queryString: query,
      });
    }
    getRemindersSuccess({
      data: {
        todos: newSourceReminders,
        count: reminders[data.todoType].count - 1,
      },
      todoType: data.todoType,
      queryString: query,
    });
    updateTodo({
      ...data,
      todoType: type.description.toLowerCase(),
      todoTypeId: type.id,
      todo_type_id: type.id,
    })
      .then(() => {})
      .catch((err) => {
        console.log("err = ", err);
      });
  };

  const handleTodoUsersUpdate = (data, employee) => {
    let previousIds = data.todoAdmins.map((admin) => admin.id);

    if (previousIds.includes(employee.value)) {
      previousIds = previousIds.filter((id) => {
        return id !== employee.value;
      });
    } else {
      previousIds.push(employee.value);
    }

    updateTodo({
      ...data,
      adminIds: previousIds,
    })
      .then((response) => {
        let updatedTasks = reminders[data.todoType].todos.map((task) => {
          if (task.id === response.data.todo.id) {
            return { ...task, todoAdmins: response.data.todo.todoAdmins };
          } else {
            return task;
          }
        });
        getRemindersSuccess({
          data: {
            todos: updatedTasks,
            count: reminders[data.todoType].count,
          },
          todoType: data.todoType,
          queryString: query,
        });

        if (userRef && userRef.current) {
          userRef.current.handleUsers(employee);
        }
      })
      .catch((err) => {
        console.log("err = ", err);
      });
  };

  const { sidebar, action, updating, actionItem } = sidebarState;

  let counts = 0;

  todoTypes.map((e) => {
    if (e.type !== "note") {
      if (reminders && reminders[e.description.toLowerCase()]) {
        counts += reminders[e.description.toLowerCase()].count;
      }
    }
    return null;
  });

  const callToAction = (action, update, item) => {
    if (!action) return;
    setSidebarState({
      ...sidebarState,
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
    });
  };

  const postArchiveTodoActivity = async (toDoId) => {
    let activityPayload = {
      status: 2,
      toDoId: toDoId,
      activityType: activitiesTypes.ARCHIVED_TODO,
      activity: activities.archiveTodo
        .replace(
          "{{admin_name}}",
          `${userData.loggedInUser.firstName} ${userData.loggedInUser.lastName}`,
        )
        .replace("{{todoId}}", `${toDoId}`),
      adminUserId: userData.loggedInUser.id,
      iconName: "Todos",
    };

    await createActivity(activityPayload);
  };

  const toggleModal = () => {
    setSidebarState({ ...sidebarState, isOpen: !sidebarState.isOpen });
  };

  const onModalClosed = () => {
    console.log("closed modal!");
  };

  const closeSideOutAction = () => {
    setSidebarState({
      ...sidebarState,
      sidebar: false,
      update: false,
      isOpen: false,
    });
  };

  let showNewBtn = true;
  if (listingId || accountId) showNewBtn = false;

  const onEmployeeUpdate = (data) => {
    console.log("Selected employee: ", data?.value);
  };

  return (
    <div className="task-management-wrapper">
      <Filter
        showNewBtn={showNewBtn}
        defaultTodoStatus={queryTodoStatus || "active"}
        dateValue={dateValue}
        defaultTodoType={queryTodoType}
        toDate={toDate}
        fromDate={fromDate}
        adminViewSelected={2}
        updateActiveTab={handleActiveTabChanged}
        dateRef={dateRef}
        handleDateChange={handleDateChange}
        handleTypeChange={handleTodoTypeChange}
        handleStatusChange={handleChangeTodoStatus}
        tabs={TABS}
        activeTab={activeTab}
        // callToAction={this.callToAction}
        callToAction={callToAction}
        onEmployeeUpdate={onEmployeeUpdate}
      />
      {!counts && !loading ? (
        <TodoEmpty />
      ) : (
        <TaskWrapper
          loading={loading}
          isLoading={false}
          reminders={reminders}
          columns={todoTypes}
          userRef={userRef}
          columnOrder={todoTypes.map((e) => e.id)}
          callToAction={callToAction}
          onDragEnd={onDragEnd}
          fetchTodos={fetchTodos}
          handleArchive={(todoId, todoType) => {
            const { todoStatus } = queryString.parse(query);
            const payload = {
              id: todoId,
            };

            let newTask = [];
            let count = reminders[todoType].count;
            if (todoStatus === "overdue" || todoStatus === "active") {
              newTask = reminders[todoType].todos.filter(
                (e) => e.id !== todoId,
              );
              count = count - 1;
            } else {
              newTask = reminders[todoType].todos.map((task) =>
                task.id === todoId ? { ...task, todoStatus: "archived" } : task,
              );
            }
            getRemindersSuccess({
              data: {
                todos: newTask,
                count,
              },
              todoType: todoType,
              queryString: query,
            });
            archiveTodo(payload)
              .then(({ data }) => {
                postArchiveTodoActivity(data.todo.id);
                toast.success("Todo successfully archived.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              })
              .catch((err) => {
                console.log("err = ", err);
                newTask = reminders[todoType].todos.map((task) =>
                  task.id === todoId ? { ...task, todoStatus: "active" } : task,
                );

                getRemindersSuccess({
                  data: {
                    todos: newTask,
                    count,
                  },
                  todoType: todoType,
                  queryString: query,
                });
                toast.error("Something went wrong. Please try again.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          }}
          handleUnArchive={(todoID, todoType) => {
            const payload = {
              id: todoID,
            };

            const { todoStatus } = queryString.parse(query);
            let count = reminders[todoType].count;
            let newTask = [];
            if (todoStatus === "archived") {
              newTask = reminders[todoType].todos.filter(
                (e) => e.id !== todoID,
              );
              count = count - 1;
            } else {
              newTask = reminders[todoType].todos.map((task) =>
                task.id === todoID ? { ...task, todoStatus: "active" } : task,
              );
            }
            getRemindersSuccess({
              data: {
                todos: newTask,
                count,
              },
              todoType: todoType,
              queryString: query,
            });

            unArchiveTodo(payload)
              .then(() => {
                toast.success("Todo successfully un-archived.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              })
              .catch((err) => {
                console.log("err = ", err);
                newTask = reminders[todoType].todos.map((task) =>
                  task.id === todoID
                    ? { ...task, todoStatus: "archived" }
                    : task,
                );
                getRemindersSuccess({
                  data: {
                    todos: newTask,
                    count,
                  },
                  todoType: todoType,
                  queryString: query,
                });
                toast.error("Something went wrong. Please try again.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          }}
          handleTodoTypeUpdate={handleTodoTypeUpdate}
          handleTodoUsersUpdate={handleTodoUsersUpdate}
          handleEmptyCols={() => {}}
          list={list}
          user={userData.loggedInUser.id}
        />
      )}

      <ActionSidebar
        setFormikSidebarRef={(ref) => (formikSidebarRef.current = ref)}
        resetFormikForm={() => formikSidebarRef.current.resetForm()}
        adminData={userData}
        show={sidebar}
        action={action}
        listings={[]}
        updating={updating}
        isPersonal={true}
        taskManagement={true}
        listingData={props.listingData}
        accountInfo={props.accountInfo}
        actionItem={actionItem}
        fetchTodos={fetchTodos}
        handleSidebar={handleSidebar}
        callToAction={callToAction}
        onPrefillListingData={() => {}}
        onTodoListUpdate={onTodoListUpdate}
        showAccountBox={true}
        forceShowAssociate={true}
      />

      <ConfirmationModal
        title="Unsaved Changes"
        message="Are you sure you want to proceed with this action?"
        onClose={onModalClosed}
        closeModal={toggleModal}
        confirmModal={closeSideOutAction}
        isOpen={sidebarState.isOpen}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    todoTypes: state.essentialLists.types,
    userData: state.auth.login.values,
    reminders: selectReminders(state),
    isLoading: selectIsLoading(state),
    list: state.essentialLists.employees,
    query: selectQueryString(state),
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

const mapDispatchToProps = {
  getRemindersRequest,
  getRemindersSuccess,
  getRemindersFailed,
  getRemindersByTypeSuccess,
  updateTodo,
  getTodo,
  archiveTodo,
  unArchiveTodo,
  setQueryString,
  createActivity,
  resetQueryString,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskManagementNew);
