/* eslint-disable indent */
import update from "immutability-helper";

import {
  SET_USER_TODOS,
  ADD_NEW_TODO,
  UPDATE_USER_TODO,
  UNARCHIVE_USER_TODO,
  REMOVE_USER_TODO,
  INCREMENT_TODO_NOTES_COUNT,
  INCREMENT_TODO_ASSETS_COUNT,
  UPDATE_TODO_ASSETS_COUNT,
  DECREMENT_TODO_NOTES_COUNT,
} from "../../actions/user-todos";

const initialState = {
  list: [],
};

const UserTodosReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TODO_ASSETS_COUNT: {
      const index = state.list.findIndex(
        (todo) => todo.id === action.data.todoId
      );
      const updatedList = update(state.list, {
        [index]: {
          assetsCount: {
            $set: action.data.count,
          },
        },
      });
      return { ...state, list: updatedList };
    }

    case INCREMENT_TODO_ASSETS_COUNT: {
      const index = state.list.findIndex(
        (todo) => todo.id === action.data.todoId
      );
      const updatedList = update(state.list, {
        [index]: {
          assetsCount: {
            $set: state.list[index].assetsCount + action.data.count,
          },
        },
      });
      return { ...state, list: updatedList };
    }

    case INCREMENT_TODO_NOTES_COUNT: {
      const index = state.list.findIndex(
        (todo) => todo.id === action.data.todoId
      );
      const updatedList = update(state.list, {
        [index]: { notesCount: { $set: state.list[index].notesCount + 1 } },
      });
      return { ...state, list: updatedList };
    }

    case DECREMENT_TODO_NOTES_COUNT: {
      const index = state.list.findIndex((todo) => todo.id === action.data.id);
      const updatedList = update(state.list, {
        [index]: { notesCount: { $set: state.list[index].notesCount - 1 } },
      });
      return { ...state, list: updatedList };
    }

    case SET_USER_TODOS:
      return { ...state, list: action.data };

    case REMOVE_USER_TODO:
      return {
        ...state,
        list: state.list.filter((todo) => todo.id !== action.data.id),
      };

    case ADD_NEW_TODO: {
      return {
        ...state,
        list: update(state.list, { $unshift: [action.data] }),
      };
    }

    case UPDATE_USER_TODO: {
      const index = state.list.findIndex((todo) => todo.id === action.data.id);

      if (index < 0) {
        return { ...state };
      }
      const updatedList = update(state.list, {
        [index]: { $merge: action.data },
      });
      return {
        ...state,
        list: updatedList,
      };
    }

    case UNARCHIVE_USER_TODO: {
      return {
        ...state,
        list: [...state.list, action.data],
      };
    }

    default:
      return state;
  }
};

export default UserTodosReducer;
