/* eslint-disable indent */
import React, { Component } from "react";
import Icon from "../Icons";
import "../../../assets/scss/components/fts/account-not-found.scss";

class AccountNotFound extends Component {
  render() {
    return (
      <div className="fts-account-not-found">
        <div className="fts-account-not-found-icon">
          <Icon name="NoAccount" size={126} />
        </div>
        <span className="fts-account-not-found-text">
          That account does not exist.
        </span>
      </div>
    );
  }
}

export default AccountNotFound;
