import uuid from "react-uuid";

export function updateAltContacts({ isEdit, altContacts, altContact }) {
  if (isEdit)
    return altContacts.map((contact) =>
      contact.id !== altContact.id ? contact : altContact,
    );
  const newContact = { ...altContact, id: uuid() };
  return [...altContacts, newContact];
}
