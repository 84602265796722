import {
  GET_ACCOUNT_DATA_BY_ID_FAILED,
  GET_ACCOUNT_DATA_BY_ID_REQUEST,
  GET_ACCOUNT_DATA_BY_ID_SUCCESS,
  SET_ACCOUNT_DATA,
  SET_ACCOUNT_DATA_INVALID_FORM_FIELDS,
  UPDATE_ACCOUNT_DATA_BY_ID_FAILED,
  UPDATE_ACCOUNT_DATA_BY_ID_REQUEST,
  UPDATE_ACCOUNT_DATA_BY_ID_SUCCESS,
} from "../../../reducers/v2/account";

//API

export const getAccountDataByIdAction = (payload) => ({
  type: GET_ACCOUNT_DATA_BY_ID_REQUEST,
  payload,
});
export const getAccountDataByIdSuccessAction = (payload) => ({
  type: GET_ACCOUNT_DATA_BY_ID_SUCCESS,
  payload,
});

export const getAccountDataByIdFiledAction = (payload) => ({
  type: GET_ACCOUNT_DATA_BY_ID_FAILED,
  payload,
});

export const updateAccountDataByIdAction = (payload) => ({
  type: UPDATE_ACCOUNT_DATA_BY_ID_REQUEST,
  payload,
});

export const updateAccountDataByIdSuccessAction = (payload) => ({
  type: UPDATE_ACCOUNT_DATA_BY_ID_SUCCESS,
  payload,
});

export const updateAccountDataByIdFailedAction = (payload) => ({
  type: UPDATE_ACCOUNT_DATA_BY_ID_FAILED,
  payload,
});

//STORE

export const setAccountInvalidFormFieldsAction = (data) => ({
  type: SET_ACCOUNT_DATA_INVALID_FORM_FIELDS,
  data,
});

export const setAccountDataAction = (data) => ({
  type: SET_ACCOUNT_DATA,
  data,
});
