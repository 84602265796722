import FTSAxios from "../../../axios/fts.instance";

export const getUpgradesCount = () => {
  return () => {
    return FTSAxios.get("admin/updates/count/upgrades");
  };
};

export const setUpgradesUnreadCount = (payload) => {
  return (dispatch) => {
    return dispatch({ type: "SET_UPGRADES_UNREAD_COUNTER", payload });
  };
};

export const markListingAsRead = (adId) => {
  return () => {
    return FTSAxios.put(`admin/listings/read/${adId}`);
  };
};
