import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../fts/Icons";
import {
  setAltContactAction,
  setAltContactInvalidFormFieldsAction,
} from "../../../../redux/actions/v2/altContacts";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { AccountAltContactItems } from "./AccountAltContactItems";
import { AccountAltContactEmpty } from "./AccountAltContactEmpty";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { FtsButton } from "@find-truck-service/ui/src/FtsButton/FtsButton";
import { AccountAltContactFormHandler } from "./AccountAltContactFormHandler";
import { selectAltContactsLength } from "../../../../redux/reducers/v2/altContacts";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonVariants,
} from "@find-truck-service/types/ui/button";
import { VariantsValues } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export const AccountAltContactContent = () => {
  const dispatch = useDispatch();
  const altContactsSize = useSelector(selectAltContactsLength);
  const [showForm, setShowForm] = useState(false);
  const [hideAddButton, setHideAddButton] = useState(false);
  const showEmpty = !altContactsSize && !showForm;

  const openAltContactForm = useCallback(() => {
    dispatch(setAltContactInvalidFormFieldsAction(null));
    dispatch(setAltContactAction(null));
    setShowForm(true);
    setHideAddButton(true);
  }, []);

  const closeAltContactForm = useCallback(() => {
    setHideAddButton(false);
    setShowForm(false);
  }, []);

  return (
    <>
      <AccountAltContactItems
        showForm={showForm}
        setHideAddButton={setHideAddButton}
      />
      <AccountAltContactEmpty show={showEmpty} />
      {showForm && (
        <AccountAltContactFormHandler
          title={"New alt contact"}
          submitButtonTitle={"Add Contact"}
          closeAltContactForm={closeAltContactForm}
        />
      )}
      {!hideAddButton && (
        <FtsColumn size={{ xs: 12 }}>
          <FtsButton
            onClick={openAltContactForm}
            variant={ButtonVariants.outlined}
            color={ButtonColorsValue.accent}
            size={ButtonSizes.sm}
          >
            <Icon
              size={20}
              name={"Plus"}
              color={ColorsValue["blue-accent-500"]}
            />
            <Span
              variant={VariantsValues["text-3xs"]}
              color={ColorsValue["blue-accent-500"]}
              ml={"xs"}
            >
              Add Contact
            </Span>
          </FtsButton>
        </FtsColumn>
      )}
    </>
  );
};
