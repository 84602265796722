import React from "react";
import { P } from "../../dist/ui/src/FtsText";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export const AccountInfoItem = ({
  label,
  value,
  valueColor = "greyscale-500",
}) => {
  return (
    <FtsColumn size={{ xs: 3 }}>
      <P
        mb={"xs"}
        variant={VariantsValues["text-3xs"]}
        color={ColorsValue["greyscale-500"]}
      >
        {label}
      </P>
      <P
        mb={"0"}
        variant={VariantsValues["text-3xs"]}
        weight={WeightSize.medium}
        color={valueColor}
      >
        {value}
      </P>
    </FtsColumn>
  );
};
