import React from "react";
import { Span } from "../../../FtsText";
import styled from "styled-components";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { FtsButton } from "../../../FtsButton/FtsButton";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { IconNames } from "../../../FtsIcons/Types/iconNames";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ButtonColorsValue, ButtonSizes, ButtonVariants } from "@find-truck-service/types/ui/button";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
const ButtonColor = {
    info: ButtonColorsValue.accent,
    neutral: ButtonColorsValue.accent,
    success: ButtonColorsValue.success,
    error: ButtonColorsValue.error,
    warning: ButtonColorsValue.warning,
};
const MessageIcon = {
    info: "Info",
    success: "Check",
    warning: "AlertTriangle",
    error: "AlertOctagon",
    neutral: "AlertOctagon",
};
const MessageIconWrapperBackgroundColor = {
    info: ColorsValue["blue-accent-500"],
    success: ColorsValue["green-success-500"],
    warning: ColorsValue["yellow-warning-300"],
    error: ColorsValue["red-error-500"],
    neutral: ColorsValue["greyscale-800"],
};
const MessageBackgroundColor = {
    info: "blue-accent-50",
    success: "green-success-50",
    warning: "yellow-warning-50",
    error: "red-error-50",
    neutral: "greyscale-50",
};
const MessageBorderColor = {
    info: ColorsValue["blue-accent-300"],
    success: ColorsValue["green-success-300"],
    warning: ColorsValue["yellow-warning-300"],
    error: ColorsValue["red-error-300"],
    neutral: ColorsValue["greyscale-100"],
};
const IconWrapper = styled(FtsWrapper) `
  background-color: ${(props) => MessageIconWrapperBackgroundColor[props.type]};
  border-radius: ${Spacing["4xxl"]};
  padding: ${Spacing["2xs"]};
  display: inline-flex;
  width: fit-content;
  height: fit-content;
`;
export const Message = (props) => {
    const showConfirmButton = !!props.confirmButtonLabel && !!props.onConfirm;
    const showCancelButton = !!props.cancelButtonLabel && !!props.onCancel;
    const showCloseButton = !!props.onClose;
    const showButtons = showConfirmButton || showCancelButton || showCloseButton;
    return (React.createElement(FtsWrapper, { py: "lg", gap: "lg", width: "100%", display: "flex", borderRadius: "2xs", justify: "space-between", bg: MessageBackgroundColor[props.type], px: { xs: "lg", sm: "2xl", md: "2xl", lg: "2xl" }, direction: { xs: "column", sm: "row", md: "row", lg: "row" }, border: `${Spacing["4xs"]} solid ${MessageBorderColor[props.type]}` },
        React.createElement(FtsWrapper, { gap: "lg", display: "flex", justify: "flex-start", align: { xs: "flex-start", sm: "center", md: "center", lg: "center" } },
            React.createElement(IconWrapper, { ...props },
                React.createElement(FtsIcons, { iconName: MessageIcon[props.type] })),
            React.createElement(FtsWrapper, { display: "flex", gap: "2xs", direction: "column" },
                props.title && (React.createElement(Span, { textAlign: "left", variant: VariantsValues["text-2xs"], weight: WeightSize.semibold }, props.title)),
                props.subtitle && (React.createElement(Span, { textAlign: "left", variant: VariantsValues["text-3xs"] }, props.subtitle))),
            showCloseButton && (React.createElement(FtsWrapper, { display: { xs: "block", sm: "none", md: "none", lg: "none" }, onClick: props.onClose },
                React.createElement(FtsIcons, { iconName: IconNames.Close })))),
        showButtons && (React.createElement(FtsWrapper, { gap: "lg", justify: "flex-end", display: "flex", align: "center", direction: { xs: "column", sm: "row", md: "row", lg: "row" } },
            showCancelButton && (React.createElement(FtsButton, { size: ButtonSizes.md, variant: ButtonVariants.outlined, color: ButtonColor[props.type], onClick: props.onCancel, width: { xs: "100%", sm: "auto", md: "auto", lg: "auto" } }, props.cancelButtonLabel)),
            showConfirmButton && (React.createElement(FtsButton, { size: ButtonSizes.md, variant: ButtonVariants.contained, color: ButtonColor[props.type], onClick: props.onConfirm, width: { xs: "100%", sm: "auto", md: "auto", lg: "auto" } }, props.confirmButtonLabel)),
            showCloseButton && (React.createElement(FtsWrapper, { display: { xs: "none", sm: "block", md: "block", lg: "block" }, onClick: props.onClose },
                React.createElement(FtsIcons, { iconName: IconNames.Close })))))));
};
