export const isAssetAdditionalAsset = (userLink = "", assetLink = "") => {
  if (!userLink || !assetLink) return false;

  let imageFileName = assetLink
    .split("/")
    .find((e) => e.toLowerCase() === userLink.toLowerCase());

  let fileName = userLink;

  if (!imageFileName) {
    imageFileName = assetLink
      .split("/")
      .slice(4, 100)
      .join("")
      .split("-")
      .slice(1, 100)
      .join("-");
    fileName = userLink.split("-").slice(1, 1000).join("-").toLowerCase();
  }

  return imageFileName.toLowerCase() === fileName.toLowerCase();
};
