/* eslint-disable indent */
/* eslint-disable react/display-name */
import React from "react";
import { formatRawDate, toMomentDate } from "../../../configs/dateConfig";
import FTSBadge from "../../../components/fts/fts-badge";
// import { FileText } from "react-feather";
// import { Autowired } from "ag-grid-community";
import TodoBadge from "../../../components/fts/todo-badge/index";
import { ContextMenuTrigger } from "react-contextmenu";
import moment from "moment";
import { capitalize } from "lodash";
const paymentStatusID = (payment_status) =>
  payment_status === "paid" ? "Paid" : "Unpaid";

const listingStatusID = (listings_status) => {
  let color;
  let label;
  switch (listings_status) {
    case 1:
      color = "#419D6D";
      label = "Active";
      break;

    case 2:
      color = "#DD6164";
      label = "Inactive";
      break;

    case 3:
      color = "#8B680F";
      label = "Draft";
      break;

    default:
      color = "#5D6D7C";
      label = "Disabled";
      break;
  }

  return { label, color };
};

const statusBasedOnID = (status) => (status ? "Enabled" : "Disabled");

export const adminStatusBasedOnID = (adminStatus) => {
  let label;
  switch (adminStatus) {
    case 1:
      label = "Free";
      break;
    case 2:
      label = "Lead";
      break;
    case 3:
      label = "Customer";
      break;
    case 4:
      label = "Trial";
      break;
    case 5:
      label = "Suspended";
      break;
    case 6:
      label = "Closed";
      break;
    case 7:
      label = "Spammer";
      break;
    case 8:
      label = "Paid";
      break;
    case 9:
      label = "Unpaid";
      break;
    case 10:
      label = "Project";
      break;
    case 11:
      label = "Other";
      break;

    case -1:
    default:
      label = "Not assigned";
      break;
  }
  return label;
};

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
      <div className="ag-date-table__time">
        {toMomentDate(date).format("[at] h:mm a")}
      </div>
    </div>
  );
};

export const defineAccountColumns = (types, collectDataForCopy) => {
  return [
    {
      headerName: "Account status",
      field: "status",
      sortable: true,
      unSortIcon: true,
      width: 145,
      // getQuickFilterText: (params) => statusBasedOnID(params.value),
      cellRendererFramework: (params) => {
        if (params.data.isDeleted) {
          return (
            <ContextMenuTrigger
              data={"Deleted"}
              collect={collectDataForCopy}
              id="contextmenu"
            >
              <span style={{ color: "#C13538" }}>Deleted</span>
            </ContextMenuTrigger>
          );
        }
        return (
          <ContextMenuTrigger
            data={statusBasedOnID(params.data.status)}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span style={{ color: params.data.status ? "#107942" : "#5D6D7C" }}>
              {statusBasedOnID(params.data.status)}
            </span>
            {/* </FTSBadge> */}
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 125,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.id}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Registered",
      field: "createdAt",
      sortable: true,
      unSortIcon: true,
      width: 150,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.createdAt).format("lll")}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <TableDate date={params.data.createdAt} />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account labels",
      field: "adminStatusForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.adminStatusForSort}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <FTSBadge
              style={{
                color: "#121A22",
                backgroundColor: "transparent",
                border: "1px solid #E5EAEF",
              }}
            >
              {params.data.adminStatusForSort}
            </FTSBadge>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account type",
      field: "accountLink",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.adminStatusForSort}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            {params.data.accountLink ? (
              <FTSBadge className="dark">Sub-account</FTSBadge>
            ) : (
              <FTSBadge
                style={{
                  backgroundColor: "#E5EAEF",
                  color: "#121A22",
                }}
              >
                Main account
              </FTSBadge>
            )}
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email",
      field: "emailForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "companyNameForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.companyName}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.companyName}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "First Name",
      field: "firstNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.firstName}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.firstName}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last Name",
      field: "lastNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.lastName}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{`${params.data.lastName}`}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "addressForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.address}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.address}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{`${params.data.city}`}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.phoneNumber || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Secondary)",
      field: "phoneNumberSecondaryForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumberSecondary}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.phoneNumberSecondary || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Mobile",
      field: "mobilePhoneForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.mobilePhone}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.mobilePhone || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last contact date",
      field: "updatedAt",
      sortable: true,
      unSortIcon: true,
      width: 165,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.updatedAt).format("lll")}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>
              <TableDate date={params.data.updatedAt} />
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Todo's",
      field: "todoCounts",
      width: 300,
      sortable: false,
      cellRendererFramework: (params) => {
        return (
          <div
            className="d-flex flex-wrap"
            style={{ maxHeight: "60px", overflowY: "auto" }}
          >
            {types.map((type) => {
              if (
                params.data.todoCounts &&
                params.data.todoCounts[type.description.toLowerCase()]
              ) {
                return (
                  <FTSBadge
                    key={type.description}
                    style={{
                      backgroundColor: type.color,
                      borderColor: type.color,
                      marginRight: "4px",
                      marginTop: "1px",
                      marginBottom: "1px",
                    }}
                  >
                    {type.description} (
                    {params.data.todoCounts[type.description.toLowerCase()]})
                  </FTSBadge>
                );
              }
              return null;
            })}
          </div>
        );
      },
    },
  ];
};

export const accountColumns = [
  {
    headerName: "Account ID",
    field: "id",
    sortable: true,
    unSortIcon: true,
    width: 125,
  },
  {
    headerName: "Registered",
    field: "createdAt",
    sortable: true,
    unSortIcon: true,
    width: 150,
    getQuickFilterText: (params) => params.value,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>
            <TableDate date={params.data.createdAt} />
          </span>
        </div>
      );
    },
  },
  {
    headerName: "Account labels",
    field: "adminStatus",
    sortable: true,
    unSortIcon: true,
    width: 150,
    getQuickFilterText: (params) => adminStatusBasedOnID(params.value),
    cellRendererFramework: (params) => {
      return (
        <div>
          <FTSBadge
            style={{
              color: "#121A22",
              backgroundColor: "transparent",
              border: "1px solid #E5EAEF",
            }}
          >
            {adminStatusBasedOnID(params.data.adminStatus)}
          </FTSBadge>
        </div>
      );
    },
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
    unSortIcon: true,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>{`${params.data.email}`}</span>
        </div>
      );
    },
  },
  {
    headerName: "Company name",
    field: "companyName",
    sortable: true,
    unSortIcon: true,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>{`${params.data.companyName}`}</span>
        </div>
      );
    },
  },
  {
    headerName: "First Name",
    field: "firstName",
    sortable: true,
    unSortIcon: true,
    width: 170,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>{`${params.data.firstName}`}</span>
        </div>
      );
    },
  },
  {
    headerName: "Last Name",
    field: "lastName",
    sortable: true,
    unSortIcon: true,
    width: 170,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>{`${params.data.lastName}`}</span>
        </div>
      );
    },
  },
  {
    headerName: "City",
    field: "city",
    sortable: true,
    unSortIcon: true,
    width: 150,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>{`${params.data.city}`}</span>
        </div>
      );
    },
  },
  {
    headerName: "State",
    field: "state",
    sortable: true,
    unSortIcon: true,
    width: 100,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>{params.data.state}</span>
        </div>
      );
    },
  },
  {
    headerName: "Phone (Primary)",
    field: "phoneNumber",
    sortable: true,
    unSortIcon: true,
    width: 160,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>{params.data.phoneNumber || ""}</span>
        </div>
      );
    },
  },
  {
    headerName: "Phone (Secondary)",
    field: "phoneNumberSecondary",
    sortable: true,
    unSortIcon: true,
    width: 170,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>{params.data.phoneNumberSecondary || ""}</span>
        </div>
      );
    },
  },
  {
    headerName: "Mobile",
    field: "mobilePhone",
    sortable: true,
    unSortIcon: true,
    width: 160,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>{params.data.mobilePhone || ""}</span>
        </div>
      );
    },
  },
  {
    headerName: "Last contact date",
    field: "updatedAt",
    sortable: true,
    unSortIcon: true,
    width: 165,
    getQuickFilterText: (params) => params.value,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>
            <TableDate date={params.data.updatedAt} />
          </span>
        </div>
      );
    },
  },
  {
    headerName: "Account status",
    field: "status",
    sortable: true,
    unSortIcon: true,
    width: 145,
    // getQuickFilterText: (params) => statusBasedOnID(params.value),
    cellRendererFramework: (params) => {
      if (params.data.isDeleted) {
        return <FTSBadge color={"danger"}>Deleted</FTSBadge>;
      }
      return (
        <div>
          <FTSBadge color={params.data.status ? "success" : "danger"}>
            {statusBasedOnID(params.data.status)}
          </FTSBadge>
        </div>
      );
    },
  },
  {
    headerName: "Todo's",
    field: "userTodos",
    width: 300,
    sortable: false,
    cellRendererFramework: (params) => {
      let counts = {};
      if (params.data.userTodos) {
        params.data.userTodos.map((todo) => {
          if (todo.todoStatus === "active") {
            const todoType =
              Array.isArray(todo.todoType) && todo.todoType?.length
                ? todo.todoType[0]
                : todo.todoType ?? "";
            if (todoType === "") return null;
            const counterVal = counts[todoType];
            return (counts[todo.todoType] = (counterVal ?? 0) + 1);
          }
          return null;
        });
      }

      return (
        <div className="d-flex flex-wrap" style={{ lineHeight: 1.8 }}>
          {counts["verify"] && counts["verify"] !== 0 ? (
            <div>
              <TodoBadge className="todo-verify">
                Verify ({counts["verify"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["renewal"] && counts["renewal"] !== 0 ? (
            <div>
              <TodoBadge className="todo-renewal">
                Renewal ({counts["renewal"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["payment"] && counts["payment"] !== 0 ? (
            <div>
              <TodoBadge className="todo-payment">
                Payment ({counts["payment"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["trial"] && counts["trial"] !== 0 ? (
            <div>
              <TodoBadge className="todo-secondary">
                Trial ({counts["trial"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["project"] && counts["project"] !== 0 ? (
            <div>
              <TodoBadge className="todo-secondary">
                Project ({counts["project"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["follow-up"] && counts["follow-up"] !== 0 ? (
            <div>
              <TodoBadge className="todo-follow_up">
                Follow Up ({counts["follow-up"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["image"] && counts["image"] !== 0 ? (
            <div>
              <TodoBadge className="todo-secondary">
                Image ({counts["image"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["report"] && counts["report"] !== 0 ? (
            <div>
              <TodoBadge className="todo-secondary">
                Report ({counts["report"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["alert"] && counts["alert"] !== 0 ? (
            <div>
              <TodoBadge className="todo-secondary">
                Alert ({counts["alert"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["reminder"] && counts["reminder"] !== 0 ? (
            <div>
              <TodoBadge className="todo-reminder">
                Reminder ({counts["reminder"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["need-to-finish"] && counts["need-to-finish"] !== 0 ? (
            <div>
              <TodoBadge className="todo-secondary">
                Need To Finish ({counts["need-to-finish"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["authorized"] && counts["authorized"] !== 0 ? (
            <div>
              <TodoBadge className="todo-secondary">
                Authorized ({counts["authorized"]})
              </TodoBadge>
            </div>
          ) : null}

          {counts["email"] && counts["email"] !== 0 ? (
            <div>
              <TodoBadge className="todo-secondary">
                Email ({counts["email"]})
              </TodoBadge>
            </div>
          ) : null}
        </div>
      );
    },
  },
];

export const defineListingColumns = (types, collectDataForCopy) => {
  return [
    {
      headerName: "Updated",
      field: "updatedAt",
      sortable: true,
      unSortIcon: true,
      width: 150,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.updatedAt).format("lll")}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <TableDate date={params.data.updatedAt} />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "CID",
      field: "userId",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.userId}
          collect={collectDataForCopy}
          id="contextmenu"
        >
          <span>{params.data.userId}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Listing ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.id}
          collect={collectDataForCopy}
          id="contextmenu"
        >
          <span>{params.data.id}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Status",
      field: "statusForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      // getQuickFilterText: (params) => listingStatusID(params.value).label,
      cellRendererFramework: (params) => {
        let { color, label } = listingStatusID(params.data.status);
        if (params.data.isDeleted) {
          color = "#C13538";
          label = "Deleted";
        }

        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span style={{ color }}>{label}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Location type",
      field: "isPrivate",
      sortable: true,
      unSortIcon: true,
      width: 140,
      // getQuickFilterText: (params) => listingStatusID(params.value).label,
      cellRendererFramework: (params) => {
        let color = "#107942";
        let label = "Public";
        if (params.data.isPrivate) {
          color = "#C13538";
          label = "Private";
        }

        console.log(params.data);
        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span style={{ color }}>{label}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Payment toggle",
      field: "paymentStatusForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      getQuickFilterText: (params) => paymentStatusID(params.value),
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={paymentStatusID(params.data.paymentStatusForSort)}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span
              style={{
                color:
                  params.data.paymentStatusForSort === "paid"
                    ? "#107942"
                    : "#C13538",
              }}
            >
              {paymentStatusID(params.data.paymentStatusForSort)}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email",
      field: "emailForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "nameForSort",
      width: 320,
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.name}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.name}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "addressForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.address}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.address}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.city}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.phoneNumber}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "After hours",
      field: "afterHoursForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.afterHours}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextmenu"
          >
            <span>{params.data.afterHours || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Renewal",
      field: "renewalTimestamp",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment.unix(params.data.renewalTimestamp).format("ll")}
            collect={collectDataForCopy}
            id="contextmenu"
          >
            <span>
              {!!params.data.renewalTimestamp &&
                moment.unix(params.data.renewalTimestamp).format("ll")}
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Todo's",
      field: "todoCounts",
      width: 300,
      sortable: false,
      cellRendererFramework: (params) => {
        return (
          <div className="d-flex flex-wrap" style={{ lineHeight: 1.8 }}>
            {types.map((type) => {
              // console.log("TYPE: ", type);
              if (
                params.data.todoCounts &&
                params.data.todoCounts[type.description.toLowerCase()]
              ) {
                return (
                  <FTSBadge
                    key={type.description}
                    style={{
                      backgroundColor: type.color,
                      borderColor: type.color,
                      marginRight: "4px",
                      marginTop: "1px",
                      marginBottom: "1px",
                    }}
                  >
                    {type.description} (
                    {params.data.todoCounts[type.description.toLowerCase()]})
                  </FTSBadge>
                );
              }
              return null;
            })}
          </div>
        );
      },
    },
  ];
};

export const listingColumns = (collectDataForCopy) => {
  return [
    {
      headerName: "Listing ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 120,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.id}
            collect={collectDataForCopy}
            id="contextMenuUser"
          >
            <span>{params.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Company name",
      field: "nameForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.name}
            collect={collectDataForCopy}
            id="contextMenuUser"
          >
            <span>{params.data.name}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "addressForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.address}
            collect={collectDataForCopy}
            id="contextMenuUser"
          >
            <span>{params.data.address}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextMenuUser"
          >
            <span>{params.data.city}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextMenuUser"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Renewal",
      field: "renewalTimestamp",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment.unix(params.data.renewalTimestamp).format("ll")}
            collect={collectDataForCopy}
            id="contextMenuUser"
          >
            <div>
              <span>
                {!!params.data.renewalTimestamp &&
                  moment.unix(params.data.renewalTimestamp).format("ll")}
              </span>
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Updated",
      field: "updatedAt",
      sortable: true,
      unSortIcon: true,
      width: 150,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.updatedAt).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuUser"
          >
            <div>
              <TableDate date={params.data.updatedAt} />
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Status",
      field: "statusForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      // getQuickFilterText: (params) => listingStatusID(params.value).label,
      cellRendererFramework: (params) => {
        let { color, label } = listingStatusID(params.data.status);

        if (params.data.isDeleted) {
          color = "#C13538";
          label = "Deleted";
        }

        return (
          <ContextMenuTrigger
            data={label}
            collect={collectDataForCopy}
            id="contextMenuUser"
          >
            <span style={{ color }}>{label}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email",
      field: "emailForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextMenuUser"
          >
            <span>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuUser"
          >
            <span>{params.data.phoneNumber}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "After hours",
      field: "afterHoursForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.afterHours}
            collect={collectDataForCopy}
            id="contextMenuUser"
          >
            <span>{params.data.afterHours || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
  ];
};

export const subAccountColumns = (collectDataForCopy) => {
  return [
    {
      headerName: "Account ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      width: 125,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.id}
            collect={collectDataForCopy}
            id="contextMenuSubUser"
          >
            <span>{params.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Registered",
      field: "createdAt",
      sortable: true,
      unSortIcon: true,
      width: 150,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.createdAt).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuSubUser"
          >
            <TableDate date={params.data.createdAt} />
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "User type",
      field: "userTypeNamed",
      sortable: true,
      unSortIcon: true,
      width: 150,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let data = params.data.userTypeNamed;
        let userTypeNamed;

        if (data === "sub-user") {
          userTypeNamed = "User";
        } else {
          userTypeNamed = "Admin";
        }

        return (
          <ContextMenuTrigger
            data={capitalize(userTypeNamed)}
            collect={collectDataForCopy}
            id="contextMenuSubUser"
          >
            {userTypeNamed}
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Email",
      field: "emailForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.email}
            collect={collectDataForCopy}
            id="contextMenuSubUser"
          >
            <span>{params.data.email}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "First Name",
      field: "firstNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.firstName}
            collect={collectDataForCopy}
            id="contextMenuSubUser"
          >
            <span>{params.data.firstName}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Last Name",
      field: "lastNameForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.lastName}
            collect={collectDataForCopy}
            id="contextMenuSubUser"
          >
            <span>{`${params.data.lastName}`}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Address",
      field: "addressForSort",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.address}
            collect={collectDataForCopy}
            id="contextMenuSubUser"
          >
            <span>{params.data.address}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "City",
      field: "cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 150,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.city}
            collect={collectDataForCopy}
            id="contextMenuSubUser"
          >
            <span>{`${params.data.city}`}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "State",
      field: "stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.state}
            collect={collectDataForCopy}
            id="contextMenuSubUser"
          >
            <span>{params.data.state}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Primary)",
      field: "phoneNumberForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumber}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuSubUser"
          >
            <span>{params.data.phoneNumber || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Phone (Secondary)",
      field: "phoneNumberSecondaryForSort",
      sortable: true,
      unSortIcon: true,
      width: 170,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.phoneNumberSecondary}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuSubUser"
          >
            <span>{params.data.phoneNumberSecondary || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Mobile",
      field: "mobilePhoneForSort",
      sortable: true,
      unSortIcon: true,
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.mobilePhone}
            // collect={collectDataForCopy}
            collect={(data) => collectDataForCopy(data, "Phone")}
            id="contextMenuSubUser"
          >
            <span>{params.data.mobilePhone || ""}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account status",
      field: "status",
      sortable: true,
      unSortIcon: true,
      width: 145,
      // getQuickFilterText: (params) => statusBasedOnID(params.value),
      cellRendererFramework: (params) => {
        if (params.data.isDeleted) {
          return (
            <ContextMenuTrigger
              data={"Deleted"}
              collect={collectDataForCopy}
              id="contextMenuSubUser"
            >
              <FTSBadge color={"danger"}>Deleted</FTSBadge>
            </ContextMenuTrigger>
          );
        }
        return (
          <ContextMenuTrigger
            data={statusBasedOnID(params.data.status)}
            collect={collectDataForCopy}
            id="contextMenuSubUser"
          >
            <FTSBadge color={params.data.status ? "success" : "danger"}>
              {statusBasedOnID(params.data.status)}
            </FTSBadge>
          </ContextMenuTrigger>
        );
      },
    },
  ];
};
