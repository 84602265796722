import { Chips } from "../../dist/ui/src/components/react/Chips";
import { ColorsValue } from "../../dist/types/ui/colors";
import React from "react";
import { IconNames } from "@find-truck-service/ui/src/FtsIcons/Types/iconNames";

export const ListingInfoBannerChip = ({ label = "" }) => {
  return (
    <Chips
      label={label}
      variant={"contained"}
      size={"sm"}
      color={ColorsValue["greyscale-100"]}
      prependIcon={{
        pathProps: {
          stroke: ColorsValue["greyscale-800"],
        },
        iconName: IconNames.Check,
      }}
    />
  );
};
