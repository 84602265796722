import React from "react";
// import { Check } from "react-feather";
import { FastField } from "formik";

// import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";
import Checkbox from "../Checkbox";

const FormikCheckbox = ({ name: fieldName, disabled, ...rest }) => {
  const additionalProps = disabled ? { disabled } : {};
  return (
    <div>
      <FastField type="checkbox" name={fieldName} {...additionalProps}>
        {({ field: { name, value }, form }) => {
          return (
            <Checkbox
              id={name}
              checked={!!value}
              onChange={(e) => {
                form.setFieldValue(name, e.target.checked);
              }}
              {...rest}
              {...additionalProps}
            />
          );
        }}
      </FastField>
    </div>
  );
};

export default FormikCheckbox;
