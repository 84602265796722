import React from "react";
import { formatRawDate } from "../../../../../configs/dateConfig";

const FileVersion = ({ data, current, onClick, ...rest }) => {
  const fileName = decodeURIComponent(data?.path?.toString().split("/").pop());
  return (
    <div
      className={`file-version${current ? " current" : ""}`}
      onClick={onClick.bind(this, data)}
      {...rest}
    >
      <div className="file-version__name">
        <strong>(v{data.version}) </strong> {fileName}
      </div>
      <small className="file-version__date">
        {formatRawDate(data.createdAt)}
      </small>
    </div>
  );
};

export default FileVersion;
