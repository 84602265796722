import React from "react";

import DeviceTypeWrapper from "./DeviceTypeWrapper";

const withDeviceType = (WrappedComponent, recursive = false) => {
  return class extends React.PureComponent {
    displayName = "DeviceTypeWrapper";
    render() {
      return (
        <DeviceTypeWrapper {...{ recursive }}>
          <WrappedComponent {...this.props} />
        </DeviceTypeWrapper>
      );
    }
  };
};

export default withDeviceType;
