import styled from "styled-components";
import { defaultStyleProps } from "../default";
import { device } from "@find-truck-service/types/ui/breakpoints";
import { Spacing } from "@find-truck-service/types/ui/spacing";
export const FtsRow = styled.div `
  ${defaultStyleProps};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${(props) => Spacing[props?.columnGap || "3xl"]};
  row-gap: ${(props) => Spacing[props?.rowGap || "4xl"]};
  @media ${device.md} {
    column-gap: ${Spacing["lg"]};
  }
  @media ${device.xs} {
    column-gap: ${Spacing["sm"]};
  }
`;
