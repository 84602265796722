import { FtsWrapper } from "../../../../FtsWrapper/FtsWrapper";
import { P } from "../../../../FtsText";
import { FtsButton } from "../../../../FtsButton/FtsButton";
import { ButtonColorsValue, ButtonSizes, ButtonVariants } from "@find-truck-service/types/ui/button";
import React from "react";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues } from "@find-truck-service/types/ui/text";
export const SideOutErrorState = (props) => {
    return (React.createElement(FtsWrapper, { py: "2xl", px: "3xl", display: "flex", align: "center", justify: "center", width: "100%", height: "100%" },
        React.createElement(FtsWrapper, null,
            React.createElement(P, { variant: VariantsValues["text-md"], color: ColorsValue["greyscale-800"] }, props.error || "Cannot find listing data!"),
            React.createElement(FtsButton, { variant: ButtonVariants["outlined"], color: ButtonColorsValue["accent"], size: ButtonSizes.sm, onClick: props.onClick }, "Re-fetch"))));
};
