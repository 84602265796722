export const checkboxOptLabels = ["Marketing", "Renewal", "Update", "Repair"];
export const mobileOptIn = [
    "mobileMarketingOptIn",
    "mobileRenewalsOptIn",
    "mobileUpdatesOptIn",
];
export const primaryOptIn = [
    "primaryMarketingOptIn",
    "primaryRenewalsOptIn",
    "primaryUpdatesOptIn",
];
