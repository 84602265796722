import React from "react";

import { connect } from "react-redux";
import "../../../assets/scss/components/fts/report-dropdown.scss";
import { CustomInput } from "reactstrap";
import DropdownItem from "./DropdownItem";
import Icon from "../Icons";
import {
  removeListingReport,
  removeUserReport,
  updateListingReport,
  updateUserReport,
} from "../../../redux/actions/reports";
import { toast } from "react-toastify";
import SendPaymentLink from "../send-payment-link";
import { hideMenu } from "react-contextmenu";
import ExtendValidityMenu from "../extend-validity-menu";
import { createActivity } from "../../../redux/actions/activities";
import { activities } from "../../../constants";
import { checkPermissions } from "../../../utility/permissionsCheck";

class ReportDropdown extends React.Component {
  state = {
    isOpenExtendValidityMenu: false,
    removeInProgress: false,
  };

  items = [
    {
      id: "sendReport",
      componentLeft: <Icon name="EmailIcon" size="16" />,
      text: "Send report",
      action: () => {
        this.props.setActiveSubMenu("hasSendMenu");
      },
      hasSendMenu: true,
    },
    {
      id: "extendValidity",
      componentLeft: <Icon name="Calendar" size="16" />,
      text: "Extend validity",
      action: () => {
        this.props.setActiveSubMenu(null);
        hideMenu();
        this.handleExtendValidityMenu();
      },
    },
    {
      id: "removeReport",
      componentLeft: <Icon name="Trash" size="16" />,
      text: "Remove Report",
      red: true,
      action: () => {
        if (this.state.removeInProgress) return;
        const havePermission = checkPermissions(
          [{ id: 4, subCat: [44] }],
          this.props.adminPermissions
        );
        if (!havePermission) {
          return toast.error("You don't have permission for this action");
        }

        this.props.setActiveSubMenu(null);

        const reportId = this.props.collectedDataForReportMenu.id;
        const reportName = this.props.collectedDataForReportMenu.title;

        let activityPayload = {
          adId: this.props.listingData?.id,
          userId: this.props.accountData?.id,
          status: 2,
          activityType: this.props.activitiesTypes.DELETED_REPORT,
          activity: activities.deletedReport
            .replace(
              "{{admin_name}}",
              `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`
            )
            .replace("{{reportName}}", reportName),
          adminUserId: this.props.userData.loggedInUser.id,
          iconName: "Report",
        };

        if (this.props.listingData) {
          activityPayload["adReportId"] = reportId;
        } else {
          activityPayload["accountReportId"] = reportId;
        }

        console.log("activityPayload: ", activityPayload);

        if (this.props.listingData) {
          this.setState({
            removeInProgress: true,
          });
          this.props
            .removeListingReport({ reportId })
            .then(() => {
              hideMenu();
              toast.success("Report successfully deleted!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              this.props.createActivity(activityPayload);
              this.props.onReportRemove();
              this.setState({
                removeInProgress: false,
              });
            })
            .catch((e) => {
              hideMenu();
              this.setState({
                removeInProgress: false,
              });
              console.log("ERROR: ", e);
              toast.error("Something went wrong. ", {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
        }

        if (this.props.accountData) {
          this.setState({
            removeInProgress: true,
          });
          this.props
            .removeUserReport({ reportId })
            .then(() => {
              hideMenu();
              toast.success("Report successfully deleted!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              this.props.createActivity(activityPayload);
              this.props.onReportRemove();
              this.setState({
                removeInProgress: false,
              });
            })
            .catch((e) => {
              hideMenu();
              this.setState({
                removeInProgress: false,
              });
              console.log("ERROR: ", e);
              toast.error("Something went wrong. ", {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
        }
      },
    },
  ];

  handleExtendValidityMenu = () => {
    this.setState({
      isOpenExtendValidityMenu: !this.state.isOpenExtendValidityMenu,
    });
  };

  handleVisibleToVendorChange = (value) => {
    this.props.toggleVisibleToVendors({ value });
    const collected = this.props.getCollectedDataForReportModal();
    const payload = {
      data: {
        visibleToVendors: value,
      },
      reportId: collected.id,
    };

    const reportId = collected.id;

    let activityPayload = {
      adId: this.props.listingData?.id,
      userId: this.props.accountData?.id,
      status: 2,
      activityType: this.props.activitiesTypes.VISIBLE_TO_VENDOR,
      activity: activities.visibleToVendor.replace(
        "{{admin_name}}",
        `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`
      ),
      adminUserId: this.props.userData.loggedInUser.id,
      iconName: "Report",
    };

    if (this.props.listingData) {
      activityPayload["adReportId"] = reportId;
    } else {
      activityPayload["accountReportId"] = reportId;
    }

    if (this.props.listingData) {
      this.props
        .updateListingReport(payload)
        .then(() => {
          toast.success("Report successfully updated!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (value) {
            this.props.createActivity(activityPayload);
          }
          // this.props.getListingReportsTableData();
        })
        .catch((e) => {
          console.log("ERROR: ", e);
          toast.error("Something went wrong. ", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      this.props
        .updateUserReport(payload)
        .then(() => {
          toast.success("Report successfully updated!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (value) {
            this.props.createActivity(activityPayload);
          }
          // this.props.getAccountReportsTableData();
        })
        .catch((e) => {
          console.log("ERROR: ", e);
          toast.error("Something went wrong. ", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  render() {
    const { isOpenExtendValidityMenu } = this.state;
    const { activeSubMenu, id } = this.props;

    return (
      <div className="report-dropdown-wrapper">
        {this.props.collectedDataForReportMenu && (
          <DropdownItem
            id={id}
            key={id}
            name={id}
            componentLeft={
              <div
                style={{
                  marginLeft: "0.6rem",
                }}
              >
                <CustomInput
                  checked={
                    this.props.collectedDataForReportMenu?.visibleToVendors
                  }
                  type="switch"
                  id={id}
                  inline
                  onChange={(e) => {
                    this.handleVisibleToVendorChange(e.target.checked);
                  }}
                />
              </div>
            }
            text="Make visible to vendor"
          />
        )}

        {this.items?.map((item) => {
          if (item.hasSendMenu) {
            return (
              <div key={Math.random() * 1000} className="report-item-with-menu">
                <DropdownItem
                  key={item.id}
                  componentLeft={item.componentLeft}
                  componentRight={item.componentRight}
                  text={item.text}
                  action={item.action}
                  red={item.red}
                />
                {activeSubMenu === "hasSendMenu" ? (
                  <div className="dropdown">
                    <div className="payment-link-button-wrapper">
                      <SendPaymentLink
                        activeSubMenu={activeSubMenu}
                        accountInfo={this.props.accountData}
                        listingData={this.props.listingData}
                        type="report"
                        collectedDataForReportMenu={
                          this.props.collectedDataForReportMenu
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            );
          } else {
            return (
              <DropdownItem
                key={item.id}
                componentLeft={item.componentLeft}
                componentRight={item.componentRight}
                text={item.text}
                action={item.action}
                red={item.red}
              />
            );
          }
        })}
        <ExtendValidityMenu
          modalOpen={isOpenExtendValidityMenu}
          closeModal={() => {
            this.handleExtendValidityMenu();
          }}
          accountData={this.props.accountData}
          listingData={this.props.listingData}
          handleExtendValidityMenu={this.handleExtendValidityMenu}
          getCollectedDataForReportModal={
            this.props.getCollectedDataForReportModal
          }
          getListingReportsTableData={this.props.getListingReportsTableData}
          getAccountReportsTableData={this.props.getAccountReportsTableData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

const mapDispatchToProps = {
  removeListingReport,
  removeUserReport,
  updateListingReport,
  updateUserReport,
  createActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDropdown);
