import FTSAxios from "../../../axios/fts.instance";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";

export const SET_ACCOUNT_INVOICES = "SET_ACCOUNT_INVOICES";
export const SET_ACCOUNT_INVOICES_ACTIVE_SUBTAB =
  "SET_ACCOUNT_INVOICES_ACTIVE_SUBTAB";
export const SET_ACCOUNT_INVOICES_IN_PROGRESS =
  "SET_ACCOUNT_INVOICES_IN_PROGRESS";
export const SET_ACCOUNT_INVOICES_CURRENT_PAGE =
  "SET_ACCOUNT_INVOICES_CURRENT_PAGE";
export const SET_ACCOUNT_INVOICES_SORT_MODEL =
  "SET_ACCOUNT_INVOICES_SORT_MODEL";
export const SET_ACCOUNT_INVOICES_RESULTS_PER_PAGE =
  "SET_ACCOUNT_INVOICES_RESULTS_PER_PAGE";
export const SET_ACCOUNT_INVOICES_SEARCH_STRING =
  "SET_ACCOUNT_INVOICES_SEARCH_STRING";
export const SET_ACCOUNT_INVOICES_LAST_VIEWED_ID =
  "SET_ACCOUNT_INVOICES_LAST_VIEWED_ID";
export const SET_ACCOUNT_PRESELECTED_INVOICE =
  "SET_ACCOUNT_PRESELECTED_INVOICE";
export const SET_ACCOUNT_INVOICES_IS_LOADING =
  "SET_ACCOUNT_INVOICES_IS_LOADING";

export const searchAccountInvoices = ({
  page,
  resultsPerPage = 100,
  sort,
  searchString,
  filter = [],
  statusFilter = [],
  searchFields,
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const queryParams = {
        searchString: "",
        page: page,
        pageSize: resultsPerPage,
      };

      let multi_match = [...statusFilter];
      if (searchString) {
        multi_match.push({
          query_string: {
            query: rfc3986EncodeURIComponent(searchString || "")
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND "),

            type: "phrase_prefix",
            default_operator: "AND",
            fields: searchFields,
          },
        });
      }

      dispatch({
        type: SET_ACCOUNT_INVOICES_IN_PROGRESS,
        payload: true,
      });

      FTSAxios.post("admin/invoices/search", {
        ...queryParams,
        multi_match,
        filter,
        sort,
      })
        .then(({ data }) => {
          resolve(data?.result);
          dispatch({
            type: SET_ACCOUNT_INVOICES,
            payload: data?.result,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const updateAccountInvoicesActiveSubTab = ({ subTab }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_INVOICES_ACTIVE_SUBTAB,
      payload: subTab,
    });
  };
};

export const updateAccountInvoicesSearchInput = ({ searchString }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_INVOICES_SEARCH_STRING,
      payload: searchString,
    });
  };
};

export const updateAccountInvoicesCurrentPage = ({ page }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_INVOICES_CURRENT_PAGE,
      payload: page,
    });
  };
};

export const updateAccountInvoicesResultsPerPage = ({ resultsPerPage }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_INVOICES_RESULTS_PER_PAGE,
      payload: resultsPerPage,
    });
  };
};

export const updateAccountInvoicesSortModel = ({ sortModel }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_INVOICES_SORT_MODEL,
      payload: sortModel,
    });
  };
};

export const setAccountInvoicesPreselectedInvoice = ({
  invoiceNumberForSort,
}) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_PRESELECTED_INVOICE,
      payload: invoiceNumberForSort,
    });
  };
};

export const setAccountInvoicesLastViewedAccount = ({ id, searchString }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_INVOICES_LAST_VIEWED_ID,
      payload: { id, searchString },
    });
  };
};

export const updateAccountInvoicesInProgress = ({ inProgress }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_INVOICES_IN_PROGRESS,
      payload: inProgress,
    });
  };
};
