import React, { Component, createRef } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Button,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "./index.scss";
import Input from "../input";
import TitleRow from "../TitleRow";
import FTSDateAndTime from "../date-picker";
import FTSTableGroup from "../fts-table-group";
import ConfirmationModal from "../confirmation-modal";

import { ftsDateFormat } from "../../../configs/dateConfig";
import FilePopover from "./file-popover";
import FileModal from "./FileModal";
import capitalize from "../../../utility/capitalize";
import {
  accountColumns,
  listingColumns,
  promotionsColumns,
  todoColumns,
} from "../../../views/pages/account-settings/user-files/data";
import FileTypesPicker from "../files/file-types-picker";
import arrayUnique from "../../../utility/arrayUnique";
import { getAccountCounts } from "../../../redux/actions/users";
import {
  getListingCounts,
  updateListingData,
} from "../../../redux/actions/listings";
import {
  addListingFile,
  addUserFile,
  removeListingFile,
  removeTodoFile,
  removeUsersFile,
} from "../../../redux/actions/files";
import { createActivity } from "../../../redux/actions/activities";
import { addUserActivity } from "../../../redux/actions/user-activities";
import { isAssetAdditionalAsset } from "../../../utility/assets";
import classnames from "classnames";
import { activities } from "../../../constants";
import { ChevronDown } from "react-feather";
import {
  generateListingFileName,
  generateUserFileName,
  getUploadUrl,
} from "../../../utility/uploadFile";

import axios from "axios";
import { history } from "../../../history";

class FilesTableGroup extends Component {
  state = {
    modalOpen: false,
    confirmationModalOpen: false,
    searchString: "",
    popoverTarget: "",
    totalFiles: 0,
    uploadMethod: "put",
    dateRange: null,
    apisReady: false,
    popoverOpen: false,
    activeTab: this.props.isListing ? "listings" : "user",
    confirmationModalForRemoveFileOpen: false,
    inProgress: false,
    showUploadOptions: false,
    deviceType: "desktop",
  };

  pageSizes = [10, 25, 50, 100];
  tableGroupRef = createRef();
  groupWrapperRef = createRef();
  uploaderRef = createRef();

  componentDidMount() {
    this.props.filesTabRef(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.accountId !== this.props.accountId && this.props.accountId) {
      this.tableGroupRef.onSubmitQuery();
    }

    if (prevState.modalOpen !== this.state.modalOpen && !this.state.modalOpen) {
      this.setState({ uploadStarted: false });
    }
  }

  formatRows = (rows) => {
    return rows.map((data) => {
      let parse = true;
      try {
        JSON.parse(data?.versions);
      } catch (err) {
        parse = false;
      }

      return {
        ...data,
        fileName: capitalize(
          decodeURIComponent(data?.path.split("/").pop()),
          false,
        ),
        versions: parse ? JSON.parse(data?.versions) : data?.versions,
      };
    });
  };

  getTableData = (key, index, state) => {
    const { accountId } = this.props;

    let dateFrom = "";
    let dateTo = "";
    let fileType = "";
    let searchString = "";
    if (this.state.dateRange?.length === 2) {
      dateFrom = this.state.dateRange[0];
      dateTo = this.state.dateRange[1];
      dateTo.setDate(dateTo.getDate() + 1);
    }
    if (this.state.fileType?.value) {
      fileType = this.state.fileType.value;
    }
    if (this.state.searchString) {
      searchString = this.state.searchString;
    }

    return this.props.getFilesByAssociation(
      key,
      accountId,
      state[`page${capitalize(key, false)}`],
      state[`pageSize${capitalize(key, false)}`],
      dateFrom,
      dateTo,
      fileType,
      searchString,
    );
  };

  saveData = (data, totalCount) => {
    this.setState({ totalFiles: totalCount });

    if (this.props.saveData) {
      this.props.saveData(data);
    }
  };
  debounce = setTimeout(() => {}, 300);

  onSearchUpdate = (e) => {
    clearTimeout(this.debounce);
    this.setState(
      {
        searchString: e.target.value,
        popoverOpen: false,
        popoverTarget: "",
      },
      () =>
        (this.debounce = setTimeout(
          () => this.tableGroupRef.onSubmitQuery(),
          300,
        )),
    );
  };

  updateDateFilter = (value) => {
    this.setState(
      { dateRange: value, popoverOpen: false, popoverTarget: "" },
      () => this.tableGroupRef.onSubmitQuery(),
    );
  };

  applyFilters = (rowData) => {
    return rowData;
  };

  fileTypeChanged = (option) => {
    this.setState(
      { fileType: option, popoverOpen: false, popoverTarget: "" },
      () => this.tableGroupRef.onSubmitQuery(),
    );
  };

  closePopover = () => {
    this.setState({
      popoverTarget: "",
      popoverOpen: false,
      modalOpen: false,
      uploadInProgress: false,
      filesAdded: [],
      currentRow: null,
    });
  };

  updateAccountListingCounts = () => {
    if (this.props.accountId) {
      this.props.getAccountCounts(this.props.accountId);
    }

    if (this.props.listingId) {
      this.props.getListingCounts(this.props.listingId);
    }
  };

  onRowClicked = (row, slugFragment, key) => {
    if (this.tableGroupRef?.state?.inProgress || this.state.inProgress) {
      return;
    }
    if (row?.event?.stopPropagation) {
      row.event.stopPropagation();
    }
    const { data } = row;
    if (this.state.popoverOpen) {
      this.setState({ popoverOpen: false });
      const previousTarget = this.state.popoverTarget;

      setTimeout(() => {
        this.closePopover();

        if (previousTarget !== `files-${key}-${data.id}`) {
          setTimeout(() => {
            this.setState({
              currentRow: { ...row },
              popoverTarget: `files-${key}-${data.id}`,
              popoverOpen: true,
            });
          }, 100);
        }
      }, 100);
      return;
    }

    this.setState({
      currentRow: { ...row },
      currentSlugFragment: slugFragment,
      popoverTarget: `files-${key}-${data.id}`,
      popoverOpen: true,
    });
  };

  onGridApiReady = () => {
    const allApisAvailable =
      this.tableGroupRef?.allApisReady && this.tableGroupRef.allApisReady();

    if (allApisAvailable) {
      this.setState({ apisReady: true });
    }
  };

  getTableLayouts = () => {
    if (!this.tableLayouts) {
      const accountTableLayout = {
        onCreateNew: this.onCreateNew,
        onGridApiReady: this.onGridApiReady,
        columnDefs: accountColumns(this.props.imageLink),
        applyFilters: this.applyFilters,
        getTableData: this.getTableData,
        formatRows: this.formatRows,
        pageSizes: this.pageSizes,
        hasSearch: false,
        initialTableData: null,
        initialPage: 1,
        initialPageSize: 10,
        initialPageCount: 0,
        initialTotalRecords: 0,
        initialSearchVal: this.state.searchString,
      };

      if (this.props.isListing) {
        this.tableLayouts = {
          listings: {
            ...accountTableLayout,
            columnDefs: listingColumns(this.props.imageLink),
            tabLabel: "Listing files",
            onRowClicked: (row) => {
              this.onRowClicked(row, "listings", "listings");
            },
          },
        };
      } else {
        this.tableLayouts = {
          user: {
            ...accountTableLayout,
            tabLabel: "Account files",
            onRowClicked: (row) => {
              this.onRowClicked(row, "users", "user");
            },
          },
          listings: {
            ...accountTableLayout,
            columnDefs: listingColumns(this.props.imageLink),
            tabLabel: "Listing files",
            onRowClicked: (row) => {
              this.onRowClicked(row, "listings", "listings");
            },
          },
          todos: {
            ...accountTableLayout,
            columnDefs: todoColumns,
            tabLabel: "Todo files",
            onRowClicked: (row) => {
              this.onRowClicked(row, "todo", "todos");
            },
          },
          promotion: {
            ...accountTableLayout,
            tabLabel: "Promotion files",
            columnDefs: promotionsColumns,
            onRowClicked: (row) => {
              this.onRowClicked(row, "todo", "todos");
            },
          },
          // assetsFiles: { ...accountTableLayout, tabLabel: "Assets" },
        };
      }
    }

    return this.tableLayouts;
  };

  closeFileModal = () => {
    if (this.state.uploadInProgress || this.state.filesAdded?.length > 0) {
      this.toggleConfirmationModal();
      return;
    }

    this.setState({
      modalOpen: false,
      uploadInProgress: false,
      filesAdded: [],
    });
  };

  cancelUploads = () => {
    this.setState({
      modalOpen: false,
      uploadInProgress: false,
      filesAdded: [],
    });
  };

  toggleUploadModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      uploadMethod: "post",
      update: false,
    });
  };

  toggleUpdateModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      uploadMethod: "put",
      update: true,
    });
  };

  toggleConfirmationModal = () => {
    this.setState({
      confirmationModalOpen: !this.state.confirmationModalOpen,
    });
  };

  toggleConfirmationModalForRemoveFile = () => {
    this.setState({
      confirmationModalForRemoveFileOpen:
        !this.state.confirmationModalForRemoveFileOpen,
    });
  };

  updateFile = async () => {
    if (!this.state.filesAdded?.length) {
      toast.warn("Oops! There is no file chosen.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    this.setState({
      uploadInProgress: true,
      uploadStarted: true,
      uploadingPercent: 1,
    });

    for (let fileForUpload of this.state.filesAdded) {
      let fileName = "";
      if (this.props.listingData) {
        fileName = generateListingFileName(
          this.props.listingData,
          fileForUpload.type.split("/").pop(),
        );
      }

      if (this.props.accountData) {
        fileName = generateUserFileName(
          this.props.accountData,
          fileForUpload.type.split("/").pop(),
        );
      }

      let mimeType = fileForUpload.type;

      const url = await getUploadUrl({
        filePath: fileName,
        mimeType,
      });

      const arrayBuffer = await fileForUpload.data.arrayBuffer();

      let blob = new Blob([new Uint8Array(arrayBuffer)], {
        type: fileForUpload.type,
      });

      const progresses = Array(this.state.filesAdded.length);

      const getTotalPercentCompleted = () => {
        const sumAll = progresses.reduce(
          (allSum, itm) => {
            allSum.loaded += itm.loaded;
            allSum.total += itm.total;
            return allSum;
          },
          { loaded: 0, total: 0 },
        );
        this.setState({
          uploadingPercent: Math.round((sumAll.loaded * 100) / sumAll.total),
        });

        return Math.round((sumAll.loaded * 100) / sumAll.total);
      };

      await axios.put(url, blob, {
        "Content-Type": mimeType,
        headers: {
          "Content-Type": mimeType,
        },
        contentType: mimeType,
        onUploadProgress: function (progressEvent) {
          progresses[0] = {
            loaded: progressEvent.loaded,
            total: progressEvent.total,
          };
          getTotalPercentCompleted();
        },
      });

      if (this.props.listingData) {
        const { listingData, notifyUser } = this.props;

        await this.props.addListingFile({
          listingId: listingData.id,
          userId: listingData.userId,
          fileUrl: fileName,
          notifyUser,
          size: fileForUpload.size / 1000000,
        });
      }

      if (this.props.accountData) {
        const { accountData, notifyUser } = this.props;

        await this.props.addUserFile({
          userId: accountData.id,
          fileUrl: fileName,
          notifyUser,
          size: fileForUpload.size / 1000000,
        });
      }
    }

    // listingId, userId, fileUrl
    this.onUploadComplete();
  };

  onFilesAdded = (files) => {
    if (files?.length) {
      const newFilesAdded = arrayUnique(
        [...(this.state.filesAdded ?? []), ...files],
        ({ id: idA }, { id: idB }) => idA === idB,
      );

      this.setState({ filesAdded: newFilesAdded });
    } else {
      const { files: uppyFiles } = this.uploaderRef.getState();
      this.setState({
        filesAdded: Object.keys(uppyFiles).map((key) => uppyFiles[key]),
      });
    }
  };

  onRemoveFile = (file) => {
    // Update state
    const newFiles = [...this.state.filesAdded];
    const fileIdxToDelete = newFiles.findIndex(
      ({ name }) => name === file?.name,
    );

    // Delete file from copied files array
    newFiles.splice(fileIdxToDelete, 1);

    // Update files in this state
    this.setState({ filesAdded: [...newFiles] });

    // Update uppy state
    const { files: uppyFiles } = this.uploaderRef.getState();
    const newUppyFiles = { ...uppyFiles };

    // Delete file from copied object
    delete newUppyFiles[file?.id];

    // Update files in uppy state
    this.uploaderRef.setState({ files: { ...newUppyFiles } });
  };

  getResponseError = (responseText, response) => {
    if (responseText?.error?.name === "TokenExpiredError") return;

    const message = `Failed to ${
      this.state.uploadMethod === "put" ? "update" : "upload"
    } file.`;
    console.group("File upload: 'getResponseError':");
    console.log("Message:", message);
    console.log("Response text:", JSON.stringify(responseText, null, 2));
    console.log("Response:", JSON.stringify(response, null, 2));
    console.groupEnd();
  };

  onUploadComplete = async () => {
    this.tableGroupRef.onSubmitQuery();

    this.updateAccountListingCounts();

    let activity;
    if (this.props.accountData) activity = await this.postAccountFileActivity();
    if (this.props.listingData) activity = await this.postListingFileActivity();

    this.props.addUserActivity(activity?.data);
    toast.success(
      `${this.state.uploadMethod === "put" ? "Update" : "Upload"} finished.`,
      {
        position: toast.POSITION.TOP_RIGHT,
      },
    );

    this.setState({
      currentRow: null,
      popoverTarget: "",
      popoverOpen: false,
      uploadInProgress: false,
      modalOpen: false,
      filesAdded: [],

      uploadStarted: false,
      uploadingPercent: 0,
    });
  };

  onSingleUploadError = () => {
    this.setState({ uploadInProgress: false });
  };

  onTabChanged = (tab) => {
    this.setState({ activeTab: tab });
  };

  shouldRenderUploadBtn = () => {
    const { isListing } = this.props;
    const { activeTab } = this.state;
    return isListing || activeTab === "user" || activeTab === "assets";
  };

  removeFileById = async () => {
    try {
      this.setState({ inProgress: true });
      const { activeTab, dataForRemove } = this.state;
      if (!dataForRemove) return;
      const { data, version } = dataForRemove;
      let newData = { ...data };
      const { removeListingFile, removeUsersFile, removeTodoFile } = this.props;
      let isImageFlagged = isAssetAdditionalAsset(
        this.props.imageLink,
        version.path,
      );

      if (isImageFlagged) {
        return toast.error("Cannot delete a file, that is already in use.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      const newVersions = data.versions.filter(
        (e) => e.version !== version.version,
      );
      newData.versions = newVersions;

      if (data.path === version.path && newVersions.length)
        newData.path = newData.versions[0].path;

      if (activeTab === "listings") {
        await removeListingFile(newData);
      }

      if (activeTab === "user") {
        await removeUsersFile(newData);
      }
      if (activeTab === "todos") {
        await removeTodoFile(newData);
      }
      this.updateAccountListingCounts();
      this.tableGroupRef.onSubmitQuery();

      this.setState({
        currentRow: null,
        popoverTarget: "",
        popoverOpen: false,
        uploadInProgress: false,
        modalOpen: false,
        filesAdded: [],
        inProgress: false,
      });

      return toast.success("File deleted", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (e) {
      console.log(e);
    }
  };

  postListingFileActivity = async () => {
    let activityPayload = {
      status: 1,
      adId: this.props?.listingData?.id,
      activityType: this.props.activitiesTypes.UPLOADED_LISTING_FILE,
      activity: activities?.uploadListingFile
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser?.firstName} ${this.props.userData.loggedInUser?.lastName}`,
        )
        .replace("{{listingId}}", this.props.listingData?.id)
        .replace("{{city}}", this.props.listingData?.city)
        .replace("{{state}}", this.props.listingData?.state),
      adminUserId: this.props.userData?.loggedInUser?.id,
      iconName: "File",
    };

    return await this.props.createActivity(activityPayload);
  };

  postAccountFileActivity = async () => {
    let activityPayload = {
      status: 1,
      userId: this.props.accountData.id,
      activityType: this.props.activitiesTypes.UPLOADED_ACCOUNT_FILE,
      activity: activities.uploadAccountFile.replace(
        "{{admin_name}}",
        `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
      ),
      adminUserId: this.props.userData.loggedInUser.id,
      iconName: "File",
    };

    return await this.props.createActivity(activityPayload);
  };

  render() {
    const {
      activeTab,
      modalOpen,
      confirmationModalOpen,
      searchString,
      apisReady,
      totalFiles,
      filesAdded,
      uploadMethod,
      popoverTarget,
      popoverOpen,
      currentRow,
      uploadStarted,
      uploadInProgress,
      currentSlugFragment,
    } = this.state;

    const { accountId, listingId, isListing, accountCompany, accountData } =
      this.props;

    const fileID = currentRow?.data && currentRow?.data?.id;
    // let accountCompany = popoverTitle;
    // if (activeTab === "promotion")
    //   accountCompany = currentRow?.data?.promotion.title;
    //
    if (!accountId) {
      return (
        <Card>
          <CardBody className="fts-account-tab-wrapper">
            <Row>
              <Col sm="12">
                <Spinner size="sm" className="float-right" />
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    }

    let title = `Listing files (${totalFiles})`;

    if (!isListing) {
      title = `Files (${totalFiles})`;
    }

    let endpoint = "";

    return (
      <FTSTableGroup
        tableLayouts={this.getTableLayouts()}
        pageSizes={this.pageSizes}
        saveData={this.saveData}
        getTableData={this.getTableData}
        onRowClicked={this.onRowClicked}
        onTabChanged={this.onTabChanged}
        initialSearchQuery={{}}
        childrenPos="above"
        withinCard
        onRef={(ref) => {
          this.tableGroupRef = ref;
          this.props.filesTableGroupRef(ref);
        }}
        wrapperRef={this.groupWrapperRef}
      >
        <TitleRow title={title} noMarker className={"fts-padded-title-row"}>
          <div className="acc-list-files-header-item">
            <Input
              className="mb-0"
              value={searchString}
              disabled={!apisReady}
              placeholder="Search files"
              onChange={this.onSearchUpdate}
            />
          </div>
          <div className="acc-list-files-header-item">
            <FormGroup className="mb-0">
              <FTSDateAndTime
                standalone
                disabled={!apisReady}
                placeholder={!apisReady ? "" : "Select date range"}
                options={{
                  mode: "range",
                  // enableTime: true,
                  dateFormat: ftsDateFormat,
                }}
                value=""
                name="acc-list-files-date-range"
                onChange={this.updateDateFilter}
              />
            </FormGroup>
          </div>
          <div className="acc-list-files-header-item">
            <FileTypesPicker
              name="fileType"
              onChange={this.fileTypeChanged}
              isDisabled={!apisReady}
              isClearable
              hasAll={true}
            />
          </div>
          {this.shouldRenderUploadBtn() && (
            <div className="acc-list-files-header-item-w-btn text-right">
              <Button
                className="account-listing-header-btn acc-list-primary-btn"
                color="primary"
                onClick={this.toggleUploadModal}
              >
                + Upload
              </Button>
            </div>
          )}
          {activeTab === "promotion" && (
            <div className="acc-list-files-header-item-w-btn text-right">
              <Button
                className="account-listing-header-btn acc-list-primary-btn"
                color="primary"
                onClick={() => {
                  this.setState({
                    popoverOpen: !this.state.popoverOpen,
                    showUploadOptions: !this.state.showUploadOptions,
                  });
                  // this.toggleUploadModal
                }}
              >
                Upload
                <span
                  className={classnames("fts-todo-save-button-arrow ml-1", {
                    opened: this.state.showUploadOptions,
                    closed: !this.state.showUploadOptions,
                  })}
                >
                  <ChevronDown size={20} />
                </span>
              </Button>
            </div>
          )}
        </TitleRow>
        <hr
          className={classnames({
            "mb-0": isListing,
            "mb-2": !isListing,
          })}
        />
        {popoverTarget !== "" && (
          <FilePopover
            onTitleClick={() => {
              accountData.id && history.push(`/accounts/${accountData.id}`);
            }}
            isListing={isListing}
            isOpen={popoverOpen}
            onToggleModal={this.toggleUpdateModal}
            onClosePopover={this.closePopover}
            target={popoverTarget}
            data={currentRow?.data}
            removeFileById={(data, version) => {
              this.setState({ dataForRemove: { data, version } }, () =>
                this.toggleConfirmationModalForRemoveFile(),
              );
            }}
            accountCompany={accountCompany || accountData.companyName}
          />
        )}

        <FileModal
          uploadingPercent={this.state.uploadingPercent}
          onRef={(ref) => (this.uploaderRef = ref)}
          onUploadComplete={this.onUploadComplete}
          onFilesAdded={this.onFilesAdded}
          closeModal={this.closeFileModal}
          updateFile={this.updateFile}
          onRemoveFile={this.onRemoveFile}
          getResponseError={this.getResponseError}
          toggleNotifyUser={this.props.toggleNotifyUser}
          notifyUser={this.props.notifyUser}
          fileID={fileID}
          isListing={isListing}
          activeTab={activeTab}
          accountId={accountId}
          listingId={listingId}
          method={uploadMethod}
          modalOpen={modalOpen}
          currentRow={currentRow}
          filesAdded={filesAdded}
          uploadStarted={uploadStarted}
          uploadInProgress={uploadInProgress}
          currentSlugFragment={currentSlugFragment}
          endpoint={endpoint}
        />
        <ConfirmationModal
          title="Confirmation"
          message="Are you sure you want cancel all file uploads?"
          closeModal={this.toggleConfirmationModal}
          confirmModal={() => {
            this.toggleConfirmationModal();
            this.cancelUploads();
          }}
          isOpen={confirmationModalOpen}
        />
        <ConfirmationModal
          title="Confirmation"
          message="Are you sure you want delete this file?"
          closeModal={this.toggleConfirmationModalForRemoveFile}
          confirmModal={() => {
            this.toggleConfirmationModalForRemoveFile();
            this.removeFileById();
          }}
          isOpen={this.state.confirmationModalForRemoveFileOpen}
        />
        {this.state.showUploadOptions && (
          <div className="upload-update-popover">
            <div className="upload-popover-wrapper">
              <button
                onClick={() => {
                  this.setState(
                    {
                      popoverOpen: false,
                      deviceType: "downloadable",
                    },
                    () => this.toggleUploadModal(),
                  );
                }}
              >
                Downloadable
              </button>
              <button
                onClick={() => {
                  this.setState(
                    {
                      popoverOpen: false,
                      logType: "desktop",
                    },
                    () => this.toggleUploadModal(),
                  );
                }}
              >
                Desktop
              </button>
            </div>
          </div>
        )}
      </FTSTableGroup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, {
  getListingCounts,
  getAccountCounts,
  updateListingData,
  removeListingFile,
  removeUsersFile,
  removeTodoFile,
  addListingFile,
  addUserFile,
  createActivity,
  addUserActivity,
})(FilesTableGroup);
