import FTSAxios from "../../../axios/fts.instance";

export const getFilesByAssociation = (
  type,
  userId,
  page,
  resultsPerPage,
  dateFrom,
  dateTo,
  fileType,
  searchString,
) => {
  return () => {
    return FTSAxios.get(
      `admin/users/files/${type}/${userId}/${page}/${resultsPerPage}`,
      { params: { dateFrom, dateTo, fileType, searchString } },
    );
  };
};

export const removeListingFile = (data) => {
  return () => {
    return FTSAxios.delete("admin/listings/assets", { data });
  };
};

export const removeUsersFile = (data) => {
  return () => {
    return FTSAxios.delete("admin/users/assets", { data });
  };
};

export const removeTodoFile = (data) => {
  return () => {
    return FTSAxios.delete("admin/todo/assets", { data });
  };
};

export const addListingFile = (data) => {
  return () => {
    return FTSAxios.post("admin/listing/asset/create", data);
  };
};

export const addUserFile = (data) => {
  return () => {
    return FTSAxios.post("admin/users/asset/create", data);
  };
};
export const addPromotionFile = (promotionId, userId = null, type, data) => {
  return () => {
    return FTSAxios.post(
      `admin/promotion/upload/${promotionId}/${type}/${userId}`,
      data,
    );
  };
};
