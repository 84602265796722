import React from "react";

import moment from "moment";
import { ContextMenuTrigger } from "react-contextmenu";
import { formatRawDate } from "../../../configs/dateConfig";
import { MoreVertical } from "react-feather";

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
    </div>
  );
};

const ContextMenuButton = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        backgroundColor: "#F2F6F9",
        borderRadius: "4px",
        width: "48px",
        height: "48px",
      }}
    >
      <MoreVertical color="#7D8C91" />
    </div>
  );
};

export const defineColumnDefsGeneratedReports = (
  collectDataForCopy,
  collectDataForReportMenu,
  listing
) => {
  let copyContextMenuId, updateContextMenuId;

  if (listing) {
    copyContextMenuId = "contextMenuListingReports";
    updateContextMenuId = "contextMenuListingReportUpdate";
  } else {
    copyContextMenuId = "contextMenuAccountReports";
    updateContextMenuId = "contextMenuAccountReportUpdate";
  }

  let columns = [
    {
      headerName: "Name",
      field: "title",
      sortable: true,
      unSortIcon: true,
      minWidth: 200,
      flex: 1,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.value}
          collect={collectDataForCopy}
          id={copyContextMenuId}
        >
          <span>{params.value}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Date Generated",
      field: "createdAt",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={moment.utc(params.data.createdAt).format("ll")}
              collect={collectDataForCopy}
              id={copyContextMenuId}
            >
              <span>
                <TableDate date={moment.utc(params.data.createdAt)} />
              </span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Expire at",
      field: "expirationDate",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={moment.utc(params.data.expirationDate).format("ll")}
              collect={collectDataForCopy}
              id={copyContextMenuId}
            >
              <span>
                <TableDate date={moment.utc(params.data.expirationDate)} />
              </span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Reporting period",
      field: "reportFrom",
      sortable: true,
      unSortIcon: true,
      minWidth: 300,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={`${moment
                .utc(params.data.reportFrom)
                .format("lll")}${" "}-  ${moment
                .utc(params.data.reportTo)
                .format("lll")}`}
              collect={collectDataForCopy}
              id={copyContextMenuId}
            >
              <div className="d-flex align-items-center">
                <TableDate date={moment.utc(params.data.reportFrom)} />
                <div className={"ml-1 mr-1"}> - </div>
                <TableDate date={moment.utc(params.data.reportTo)} />
              </div>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Generated by",
      field: "generatedBy",
      sortable: false,
      unSortIcon: false,
      minWidth: 250,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.generatedBy}
            collect={collectDataForCopy}
            id={copyContextMenuId}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div>{params.data.generatedBy}</div>
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "",
      field: "actions",
      sortable: false,
      unSortIcon: true,
      suppressMovable: true,
      maxWidth: 80,
      pinned: "right",
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={params.data}
              id={updateContextMenuId}
              holdToDisplay={0}
              collect={collectDataForReportMenu}
            >
              <ContextMenuButton />
            </ContextMenuTrigger>
          </div>
        );
      },
    },
  ];

  if (!listing) {
    columns = [
      ...columns.slice(0, 1),
      {
        headerName: "Associated with",
        field: "relatedAdsForSort",
        unSortIcon: false,
        sortable: false,
        flex: 1,
        minWidth: 150,
        cellRendererFramework: (params) => {
          return (
            <div style={{ padding: "10px 0px", width: "100%" }}>
              <div
                style={{
                  border: "1px solid #E5E8E9",
                  display: "flex",
                  justifyContent: "center",
                  padding: 6,
                  width: "100%",
                  flexDirection: "column",
                  borderRadius: "4px",
                }}
              >
                <span style={{ lineHeight: 1.5 }}>
                  {params.data?.relatedAdsCount || 0}/
                  {params.data?.allAdsCount || 0} Listings
                </span>
              </div>
            </div>
          );
        },
      },
      ...columns.slice(1, 1000),
    ];
  }

  return columns;
};
