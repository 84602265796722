/* eslint-disable indent */
import React from "react";
import Select from "react-dropdown-select";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import * as Icons from "react-feather";
import { Icon } from "../Icons";

import "../../../assets/scss/components/fts/services-picker.scss";

class ServicesPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      multi: true,
      disabled: false,
      loading: false,
      contentRenderer: true,
      dropdownRenderer: true,
      inputRenderer: false,
      itemRenderer: true,
      optionRenderer: false,
      noDataRenderer: false,
      selectValues: this.props.query.subCategory,
      searchBy: "label",
      clearable: false,
      searchable: true,
      handle: true,
      labelField: "label",
      valueField: "id",
      keepSelectedInList: true,
      dropdownPosition: "bottom",
      direction: "ltr",
      selectedCategories: [],
      selectedSubCategories: [],
      checkedCategory: null,
      dropdownHeight: "500px",
    };
  }

  collapsedCategories = [];
  subCategories = [];
  selectRef = React.createRef();

  componentDidMount() {
    const { query, allCategories } = this.props;
    if (query.subCategory) {
      for (let category of allCategories) {
        let subCategory = category.subCategories.find(
          (e) => e.value === query.subCategory,
        );
        if (subCategory) {
          this.setState({
            selectedCategories: [subCategory.categoryId],
            selectedSubCategories: [subCategory.value],
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { query, allCategories } = this.props;
    // const { query } = prevProps;
    const { selectValues } = this.state;

    if (query.subCategory !== selectValues) {
      if (!query.subCategory) {
        return this.setState({
          selectValues: "",
          selectedCategories: [],
          selectedSubCategories: [],
        });
      }
      for (let category of allCategories) {
        let subCategory = category.subCategories.find(
          (e) => e.label === query.subCategory,
        );
        if (subCategory) {
          this.setState({
            selectedCategories: [subCategory.categoryId],
            selectedSubCategories: [subCategory.label],
          });
        }
      }
      this.setState({ selectValues: prevProps.query.subCategory });
    }
  }

  setValues = (selectValues) => {
    this.props.onSelect(selectValues);
  };

  noDataRenderer = () => {
    return (
      <p style={{ textAlign: "center" }}>
        <strong>Ooops!</strong> No data found
      </p>
    );
  };

  itemRenderer = ({ item, props, methods }) => (
    <div key={item[props.valueField]} onClick={() => methods.addItem(item)}>
      <div style={{ margin: "20px" }}>
        <input type="checkbox" checked={methods.isSelected(item)} />
        &nbsp;&nbsp;&nbsp;{item[props.labelField]}
      </div>
    </div>
  );

  getCategory = (sub) => {
    let result = this.props.allSubCategories.filter((obj) => {
      return obj.label === sub;
    });
    return result[0].mainLabel;
  };

  contentRenderer = () => (
    <div
      className="content"
      onClick={() => {
        this.selectRef.current.dropDown();
      }}
    >
      <div
        className={`placeholder ${
          this.state.selectedSubCategories.length !== 0 ? "isFocused" : ""
        }`}
      >
        Service
      </div>
      {this.state.selectedSubCategories.length !== 0 ? (
        <div key={this.state.selectedSubCategories[0]} className="selected">
          {this.getCategory(this.state.selectedSubCategories[0])}:{" "}
          {this.state.selectedSubCategories[0]}
        </div>
      ) : null}
    </div>
  );

  addCategory = (id) => {
    if (this.state.selectedCategories.includes(id)) {
      this.setState({
        selectedCategories: this.state.selectedCategories.filter(
          (catId) => catId !== id,
        ),
      });
    } else {
      this.setState((prevState) => ({
        selectedCategories: [...prevState.selectedCategories, id],
      }));
    }
  };

  addSubCategory = (subcategory) => {
    if (this.state.selectedSubCategories.includes(subcategory.label)) {
      this.setState({
        selectedSubCategories: this.state.selectedSubCategories.filter(
          (lab) => lab !== subcategory.label,
        ),
        checkedCategory: null,
      });
      this.props.onSelect("");
    } else {
      this.props.onSelect(subcategory.value);
      this.setState(() => ({
        selectedSubCategories: [subcategory.label],
        checkedCategory: subcategory.categoryId,
      }));

      this.props.updates &&
        this.selectRef.current &&
        this.selectRef.current.dropDown();
    }
  };

  filterDropdown = (state, subCategories, option) => {
    return subCategories.filter((subCat) => {
      return (
        subCat.label.toLowerCase().includes(state.search.toLowerCase()) &&
        option.id === subCat.categoryId
      );
    });
  };

  emptySubcategoriesCheck = (foundSubcategories, option, state) => {
    return foundSubcategories.length === 0 &&
      option.label.toLowerCase().includes(state.search.toLowerCase())
      ? true
      : false;
  };

  dropdownRenderer = ({ props, state, methods }) => {
    this.collapsedCategories = [];
    this.subCategories = this.props.allSubCategories;

    return (
      <div className="items">
        <div>
          <div className="icon-holder">
            <Icon className="search-icon" name="Search" size={18} />
          </div>

          <input
            type="text"
            className="form-control closed"
            value={state.search}
            onChange={methods.setSearch}
            placeholder="Search for service"
          />
        </div>

        {props.options
          .filter((item) => {
            if (state.search === "") {
              this.collapsedCategories = [];
              return item;
            } else {
              let sub = [];
              item.subCategories.map((subCat) => {
                if (
                  subCat.label
                    .toLowerCase()
                    .includes(state.search.toLowerCase())
                ) {
                  sub.push({ id: subCat.category_id });
                } else {
                  this.collapsedCategories.filter(
                    (catId) => catId !== subCat.category_id,
                  );
                }
                return null;
              });
              if (
                sub.length > 0 ||
                item.label.toLowerCase().includes(state.search.toLowerCase())
              ) {
                this.collapsedCategories = this.collapsedCategories.concat(sub);
                return item;
              }
            }
            return null;
          })
          .map((option) => {
            let localSubcategories = [];
            if (!this.state.keepSelectedInList && methods.isSelected(option)) {
              return null;
            }
            let open = false;
            if (this.state.selectedCategories.includes(option.id)) {
              open = true;
            }
            this.collapsedCategories.map((cat) => {
              if (cat.id === option.id) {
                open = true;
              }
              return null;
            });

            if (state.search.length) {
              let foundSubcategories = this.filterDropdown(
                state,
                this.subCategories,
                option,
              );

              if (foundSubcategories.length !== 0) {
                localSubcategories = foundSubcategories;
              }

              if (
                this.emptySubcategoriesCheck(foundSubcategories, option, state)
              ) {
                localSubcategories = this.subCategories;
              }
            }

            let renderingArray =
              localSubcategories.length !== 0
                ? localSubcategories
                : this.subCategories;

            return (
              <div key={option.label}>
                <div
                  className={`dropdown ${open ? "open" : null}`}
                  onClick={() => {
                    this.addCategory(option.id);
                  }}
                >
                  <div className="category-label">
                    <span className="label">{option.label}</span>
                    {this.state.checkedCategory === option.id ? (
                      <Icons.Check size={22} color={"#57A14A"} />
                    ) : null}
                  </div>

                  {open ? (
                    <Icons.ChevronDown size={17} className="chevron" />
                  ) : (
                    <Icons.ChevronRight size={17} className="chevron" />
                  )}
                </div>

                <Collapse isOpen={open}>
                  {renderingArray.map((subCat) => {
                    if (subCat.mainLabel === option.label) {
                      return (
                        <div key={subCat.label} className="red-item">
                          <div className="red" />
                          <div
                            className="label"
                            onClick={() => {
                              this.addSubCategory(subCat);
                            }}
                          >
                            {subCat.label}
                            {this.state.selectedSubCategories.includes(
                              subCat.label,
                            ) ? (
                              <Icons.Check size={19} color={"#57A14A"} />
                            ) : null}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Collapse>
              </div>
            );
          })}
      </div>
    );
  };

  optionRenderer = ({ option, methods }) => (
    <React.Fragment>
      <div onClick={(event) => methods.removeItem(event, option, true)}>
        {option.label}
      </div>
    </React.Fragment>
  );

  render() {
    const { allCategories, className = "services-wrapper" } = this.props;

    return (
      <div className={className}>
        <Select
          ref={this.selectRef}
          className="select"
          readOnly={true}
          placeholder="Select service type"
          searchBy={this.state.searchBy}
          separator={this.state.separator}
          clearable={this.state.clearable}
          searchable={this.state.searchable}
          dropdownHandle={this.state.handle}
          direction={this.state.direction}
          multi={this.state.multi}
          values={[]}
          labelField={this.state.labelField}
          valueField={this.state.valueField}
          options={allCategories}
          dropdownGap={5}
          keepSelectedInList={this.state.keepSelectedInList}
          onDropdownOpen={() => undefined}
          onDropdownClose={() => undefined}
          onClearAll={() => undefined}
          onSelectAll={() => undefined}
          onChange={(values) => this.setValues(values)}
          noDataLabel="No matches found"
          closeOnSelect={this.state.closeOnSelect}
          dropdownHeight={this.state.dropdownHeight}
          noDataRenderer={
            this.state.noDataRenderer ? () => this.noDataRenderer() : undefined
          }
          dropdownPosition={this.state.dropdownPosition}
          itemRenderer={
            this.state.itemRenderer
              ? (item, itemIndex, props, state, methods) =>
                  this.itemRenderer(item, itemIndex, props, state, methods)
              : undefined
          }
          inputRenderer={
            this.state.inputRenderer
              ? (props, state, methods) =>
                  this.inputRenderer(props, state, methods)
              : undefined
          }
          optionRenderer={
            this.state.optionRenderer
              ? (option, props, state, methods) =>
                  this.optionRenderer(option, props, state, methods)
              : undefined
          }
          contentRenderer={
            this.state.contentRenderer
              ? (innerProps, innerState) =>
                  this.contentRenderer(innerProps, innerState)
              : undefined
          }
          dropdownRenderer={
            this.state.dropdownRenderer
              ? (innerProps, innerState, innerMethods) =>
                  this.dropdownRenderer(innerProps, innerState, innerMethods)
              : undefined
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allServiceAmenities: state.essentialLists.serviceAmenities,
    allCategories: state.essentialLists.categories,
    allSubCategories: state.essentialLists.subCategories,
  };
};

export default connect(mapStateToProps)(ServicesPicker);
