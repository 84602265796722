import styled from "styled-components";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
import { defaultStyleProps } from "../default";
import { Spacing } from "@find-truck-service/types/ui/spacing";
export const FtsAccordionHeader = styled(FtsWrapper) `
  ${defaultStyleProps};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.md};
  cursor: pointer;
  svg {
    transform: ${(props) => (props.show ? "rotate(180deg)" : "rotate(0deg)")};
    transition: all 0.2s ease;
  }
`;
