import styled from "styled-components";
import { TextStyle } from "../FtsText";
import { defaultStyleProps } from "../default";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { InputVariants, InputColors, InputSizes } from "@find-truck-service/types/ui/input";
const defaultStyles = `
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected,
    &:-webkit-autofill:active {
        color: ${ColorsValue["greyscale-800"]} !important;
        background-color: ${ColorsValue["greyscale-0"]} !important;
        transition: background-color 5000s ease-in-out 0s;
        ${() => TextStyle({
    weight: WeightSize.regular,
    variant: VariantsValues["text-3xs"],
    color: ColorsValue["greyscale-800"],
})};
    }

    width: 100%;
    border: ${Spacing["4xs"]} solid transparent;

    cursor: pointer;
    border-radius: ${Spacing["2xs"]};
    color: ${ColorsValue["greyscale-800"]};
    outline: ${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]};
    &::placeholder {
      color: ${ColorsValue["greyscale-500"]};
      opacity: 1; /* Firefox */
    };

    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: ${ColorsValue["greyscale-500"]};
    };
`;
const inputStyles = (props) => {
    let style = {
        [InputColors["accent"]]: {
            [InputVariants.outlined]: {
                [InputSizes.sm]: `
        ${defaultStyles}
        padding: ${Spacing["sm"]};
        &:hover {
          outline: ${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]};
        };
        &:focus {
          outline: ${Spacing["3xs"]} solid ${ColorsValue["blue-accent-500"]};
        };
         ${props.invalid &&
                    `
          outline: ${Spacing["4xs"]} solid ${ColorsValue["red-error-300"]};
          &:hover {
            outline: ${Spacing["4xs"]} solid ${ColorsValue["red-error-700"]};
          };
          &:focus {
            outline: ${Spacing["3xs"]} solid ${ColorsValue["red-error-50"]};
          };`}
      `,
                [InputSizes.md]: `
        ${defaultStyles}
        padding: ${Spacing["md"]};
        &:hover {
          outline: ${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]};
        };
        &:focus {
          outline: ${Spacing["3xs"]} solid ${ColorsValue["blue-accent-500"]};
        };
        ${props.invalid &&
                    `
          outline: ${Spacing["4xs"]} solid ${ColorsValue["red-error-300"]};
          &:hover {
            outline: ${Spacing["4xs"]} solid ${ColorsValue["red-error-700"]};
          };
          &:focus {
            outline: ${Spacing["3xs"]} solid ${ColorsValue["red-error-50"]};
          };`}
        `,
            },
        },
    };
    return style[props.color][props.variant][props.size];
};
export const FtsInput = styled.input `
  ${defaultStyleProps}
  ${inputStyles}
  ${() => TextStyle({
    weight: WeightSize.regular,
    variant: VariantsValues["text-3xs"],
    color: ColorsValue["greyscale-800"],
    lineHeightVariant: "relaxed",
})};
`;
