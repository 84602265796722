/* eslint-disable indent */
/* eslint-disable no-extra-boolean-cast */
import React, { PureComponent, createRef } from "react";
import { Row, Col, Spinner, Button } from "reactstrap";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import isObject from "lodash/isObject";
import {
  createActivity,
  fetchActivitiesAccount,
} from "../../../redux/actions/activities";
import { activities } from "../../../constants";

import { formatRawDate } from "../../../configs/dateConfig";
import { editSchema as validationSchema } from "../../../formik-validations/account-information";
import AccountInfoHeader from "./AccountInfo_Header";
import AccountInfoData from "./AccountInfo_Data";
import Section from "./Section";
import Services from "../../../components/fts/services";

import { extractFormikCategoriesData } from "../../../utility/extract";
import PromptWrapper from "../../../components/fts/PromptWrapper";
import FormValidationEffect from "../../../components/fts/form-validation-effect";

import { connect } from "react-redux";
import { resetState } from "../../../redux/actions/user-notes";

import "../../../assets/scss/components/fts/form-fields.scss";
import Images from "../listing-settings/sections/Images";

import FileItem from "../../../components/fts/files/FileItem";
import moment from "moment";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import ConfirmationModal from "../../../components/fts/confirmation-modal";
import {
  deleteAccount,
  sentRequestPasswordChange,
} from "../../../redux/actions/users";

import { setUserActivities } from "../../../redux/actions/user-activities";
import { history } from "../../../history";
import {
  generateUserFileName,
  getUploadUrl,
} from "../../../utility/uploadFile";
import axios from "axios";
import { addUserFile } from "../../../redux/actions/files";
import PaymentMethods from "../../../components/fts/account-cards";
import TitleRow from "../../../components/fts/TitleRow";

const replaceNullValues = (obj, replacer = "") => {
  Object.keys(obj).forEach((key) => (obj[key] = obj[key] ?? replacer));
};

const getFirstErrorKey = (object, keys = []) => {
  const firstErrorKey = Object.keys(object)[0];
  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join(".");
};

class AccountInfo extends PureComponent {
  state = {
    imageLink: "",
    uploadingImage: false,
    confirmModal: false,
    sendRequestChangePasswordModalOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (
      !this.state.imageLink &&
      this.props.accountData.image !== this.state.imageLink
    ) {
      this.setState({
        imageLink: "",
      });
    }
    if (prevProps.editMode !== this.props.editMode) {
      this.props
        .getFilesByAssociation("user", this.props.accountData.id, 1, 20)
        .then(({ data }) => {
          this.setState({ accountFiles: data });
        });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.accountData.image) {
      this.setState({ imageLink: "" });
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  formRef = createRef();

  onFormSubmission = (values) => {
    const { userCategories, userSubCategories } = extractFormikCategoriesData(
      values.categories,
    );

    const adminUserIds = !values.assignedAdmins
      ? []
      : Array.isArray(values.assignedAdmins)
      ? values.assignedAdmins.map((itm) => itm.value)
      : [values.assignedAdmins.value];

    const payload = {
      ...values,
      userCategories,
      userSubCategories,
      adminUserIds,
      leadStatus:
        typeof values?.leadStatus === "object"
          ? values?.leadStatus?.value
          : values?.leadStatus,
      adminStatus:
        typeof values.adminStatus === "object"
          ? values.adminStatus.value
          : values.adminStatus,
      state:
        typeof values.state === "object" ? values.state.value : values.state,
      status:
        typeof values.status === "object" ? values.status.value : values.status,
      userTypeNamed:
        typeof values.userType === "object"
          ? values.userType.userTypeNamed.toLowerCase()
          : values.userTypeNamed,
      userType:
        typeof values.userType === "object"
          ? values.userType.value
          : values.userType,
      type:
        typeof values.userType === "object"
          ? values.userType.value
          : values.type,
      image: this.state.imageLink || this.props.accountData.image,
      companyName: rfc3986EncodeURIComponent(values.companyName),
    };

    delete payload.subUserRole;

    if (!payload.adminAccountCreatedBy) {
      delete payload.adminAccountCreatedBy;
    }

    this.props.onUserUpdate(payload);
  };

  showVendorServices = (values) => {
    const userTypeNamedVal = values.userTypeNamed
      ? typeof values.userTypeNamed === "string"
        ? values.userTypeNamed
        : values.userType.userTypeNamed.toLowerCase()
      : "";
    return !!userTypeNamedVal && userTypeNamedVal === "vendor";
  };

  onSubmitError = (errors) => {
    console.log(errors);
    delete errors.userType;
    const key = getFirstErrorKey(errors);
    let element;

    if (document.getElementsByName(key).length) {
      element = document.getElementsByName(key)[0];
    } else {
      element = document.getElementById(key);
    }
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    toast.error("Unable to proceed. Invalid form!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  onImageUpdate = async (e) => {
    let { accountData } = this.props;

    this.setState({ uploadingImage: true });

    for (let fileForUpload of e.target.files) {
      let fileName = generateUserFileName(
        accountData,
        fileForUpload.type.split("/").pop(),
      );

      let mimeType = fileForUpload.type;
      const url = await getUploadUrl({
        filePath: fileName,
        mimeType,
      });

      const arrayBuffer = await fileForUpload.arrayBuffer();

      let blob = new Blob([new Uint8Array(arrayBuffer)], {
        type: fileForUpload.type,
      });

      await axios.put(url, blob, {
        "Content-Type": mimeType,
        headers: {
          "Content-Type": mimeType,
        },
        contentType: mimeType,
      });
      await this.props.addUserFile({
        listingId: accountData.id,
        userId: accountData.userId,
        fileUrl: fileName,
        size: fileForUpload.size / 1000000,
      });
      this.setState({
        imageLink: fileName,
        uploadingImage: false,
      });
    }
  };

  setFileInputRef = (ref) => {
    this.fileInput = ref;
  };

  setFileInputRefVal = (value) => {
    this.fileInput.value = value;
    this.setState({ imageLink: "" });
  };

  render() {
    const {
      inProgress,
      editMode,
      toggleEditConfirmationModal,
      accountData,
      getAltContactInfo,
      accountFormRef,
      setNewAltContacts,
      updateAltContactAction,
      removeAltContacts,
      onKeyDown,
    } = this.props;
    const { uploadingImage, accountFiles } = this.state;
    let imageLink = this.state.imageLink || accountData.image;

    const initialValues = !!accountData
      ? {
          ...accountData,
          last_contact_date: accountData.adminLastContactDate
            ? moment.unix(accountData.adminLastContactDate).format("lll")
            : "No contact date",
          createdAt: formatRawDate(accountData.createdAt),
          adminNoteType: accountData.adminNoteType
            ? accountData.adminNoteType
            : "note",
        }
      : {};

    //THIS IS A HACK: have to remove accountLink because of replaceNullValues
    delete initialValues.accountLink;

    replaceNullValues(initialValues);

    if (inProgress) {
      return (
        <React.Fragment>
          <Row>
            <Col sm="12">
              <Spinner size="sm" className="float-right" />
            </Col>
          </Row>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={true}
          innerRef={accountFormRef}
          onSubmit={this.onFormSubmission}
          {...{ initialValues }}
          {...{ validationSchema }}
        >
          {({
            dirty,
            values,
            isValid,
            resetForm,
            setFieldTouched,
            setFieldError,
            setFieldValue,
            ...rest
          }) => (
            <>
              <FormValidationEffect
                formik={rest}
                onSubmissionError={this.onSubmitError}
              />
              <PromptWrapper dirty={dirty} />
              <Form autoComplete="off" onKeyDown={onKeyDown}>
                <Section id="account-info-section" first>
                  <AccountInfoHeader
                    accountAccessRequests={this.props.accountAccessRequests}
                    onRemoveAccount={() =>
                      this.setState({ confirmModal: true })
                    }
                    onSendRequestChangePassword={() =>
                      this.setState({
                        sendRequestChangePasswordModalOpen: true,
                      })
                    }
                    isVendor={this.showVendorServices(values)}
                    validForm={isValid}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                    resetForm={resetForm}
                    callToAction={this.callToAction}
                    toggleReassignListingModal={
                      this.props.toggleReassignListingModal
                    }
                    toggleDisableAccessModal={
                      this.props.toggleDisableAccessModal
                    }
                    {...this.props}
                  />

                  <AccountInfoData
                    removeAltContacts={removeAltContacts}
                    updateAltContactAction={updateAltContactAction}
                    setNewAltContacts={setNewAltContacts}
                    noteLabelValue={values.adminNoteType}
                    accountID={accountData.id}
                    editMode={this.props.editMode}
                    setFieldValue={setFieldValue}
                    altContacts={values.altContacts}
                    setFieldError={setFieldError}
                    accountData={accountData}
                    setFieldTouched={setFieldTouched}
                    errors={rest.errors.altContacts}
                    collectDataForCopy={this.props.collectDataForCopy}
                    accountFormRef={this.props.accountFormRef}
                    {...{ getAltContactInfo }}
                  />
                  <br />
                </Section>
                {this.showVendorServices(values) ? (
                  <Section id="vendor-services">
                    <Services
                      refer={accountFormRef}
                      title={editMode ? "Select services" : "Services"}
                      editMode={this.props.editMode}
                    />
                    {values.userCategories.length === 0 &&
                      values.userSubCategories.length === 0 &&
                      !editMode &&
                      "Account does not have any services selected, yet."}
                  </Section>
                ) : null}
                <Section id="payment-methods">
                  <TitleRow title={"Previously used cards"} />
                  <PaymentMethods
                    editMode={editMode}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                    // errors={rest.errors.altContacts}
                    cards={values.userCards}
                    hideRemoveBtn={!editMode}
                  />
                </Section>
                <Section
                  id="Upload image"
                  title={editMode ? "Upload image" : "Image"}
                  notWrapped
                >
                  <Images
                    onImageUpdate={this.onImageUpdate}
                    setFileInputRef={this.setFileInputRef}
                    setFileInputRefVal={this.setFileInputRefVal}
                    inProgress={uploadingImage}
                    {...{
                      editMode,
                      imageLink,
                    }}
                    hideRemoveBtn
                  />
                  {editMode ? (
                    <div
                      className={accountFiles?.rows?.length ? "mt-3" : ""}
                      style={{ height: 200, overflow: "auto" }}
                    >
                      {accountFiles?.rows?.length
                        ? accountFiles?.rows?.map((e) => {
                            return (
                              <div
                                key={e.id}
                                className="cursor-pointer"
                                onClick={() => {
                                  this.setState({
                                    imageLink:
                                      "images" + e.path.split("images").pop(),
                                  });
                                }}
                              >
                                <FileItem
                                  isFlagged={isEqual(imageLink, e.path)}
                                  listFile={true}
                                  url={e.path}
                                  dateModified={moment(e.updatedAt).format(
                                    "lll",
                                  )}
                                  hideRemoveBtn={true}
                                />
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  ) : null}
                </Section>
                {editMode && (
                  <Row className="justify-content-between mt-2">
                    <Col sm={editMode ? 6 : 12}>
                      {/*//todo revert once is ready*/}
                      {/*<Button className="fts-account-remove-btn" color="danger">*/}
                      {/*  Remove account*/}
                      {/*</Button>*/}
                    </Col>
                    <Col sm="6" className="text-right">
                      <Button
                        color="tertiary"
                        type="button"
                        className="preview-listing-btn account-listing-header-btn mr-1"
                        onClick={() => {
                          toggleEditConfirmationModal();
                        }}
                      >
                        <span className="preview-listing-btn-label">
                          Cancel
                        </span>
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        className="account-listing-header-btn acc-list-primary-btn"
                      >
                        Save changes
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form>
            </>
          )}
        </Formik>
        <ConfirmationModal
          title="Confirmation"
          message={`Are you sure you want to ${
            accountData.isDeleted ? "enable" : "delete"
          }  this account?`}
          closeModal={() => this.setState({ confirmModal: false })}
          confirmModal={() => {
            this.props
              .deleteAccount(accountData.id)
              .then(async () => {
                toast.success("Account successfully deleted!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                let activityPayload = {
                  status: 1,
                  userId: accountData.id,
                  activityType: this.props.activitiesTypes.DELETED_ACCOUNT,
                  activity: activities.deleteAccount
                    .replace(
                      "{{admin_name}}",
                      `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
                    )
                    .replace("{{accountId}}", accountData.id),
                  adminUserId: this.props.userData.loggedInUser.id,
                  iconName: "AccountsActivity",
                };
                await this.props.createActivity(activityPayload);
                history.push("/accounts");
              })
              .catch((e) => {
                console.log("ERROR: ", e);
                toast.error("Something went wrong. ", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          }}
          isOpen={this.state.confirmModal}
        />
        <ConfirmationModal
          title="Confirmation"
          message={`Are you sure you want to sent request change password to ${accountData.email} email?`}
          closeModal={() =>
            this.setState({ sendRequestChangePasswordModalOpen: false })
          }
          confirmModal={() => {
            this.props
              .sentRequestPasswordChange({
                recipient: accountData.email,
                recipientName: accountData.firstName,
                userId: accountData.id,
                sendBy: `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
              })
              .then(async () => {
                toast.success(
                  "Request password change email sent successfully!",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  },
                );

                let activityPayload = {
                  status: 1,
                  userId: accountData.id,
                  activityType: this.props.activitiesTypes.DELETED_ACCOUNT,
                  activity: activities.sendRequestPasswordChange
                    .replace(
                      "{{admin_name}}",
                      `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
                    )
                    .replace("{{accountId}}", accountData.id)
                    .replace("{{email}}", accountData.email),
                  adminUserId: this.props.userData.loggedInUser.id,
                  iconName: "AccountsActivity",
                };
                await this.props.createActivity(activityPayload);
                this.setState({ sendRequestChangePasswordModalOpen: false });
              })
              .catch((e) => {
                console.log("ERROR: ", e);
                toast.error("Something went wrong. ", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          }}
          isOpen={this.state.sendRequestChangePasswordModalOpen}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitiesTypes: state.essentialLists.activitiesTypes,
    userData: state.auth.login.values,
  };
};

export default connect(mapStateToProps, {
  resetState,
  deleteAccount,
  createActivity,
  fetchActivitiesAccount,
  setUserActivities,
  addUserFile,
  sentRequestPasswordChange,
})(AccountInfo);

let isEqual = (left, right) => {
  let newLeft = left;
  let newRight = "images" + right.split("images").pop();
  return newLeft === newRight;
};
