import React, { Component } from "react";
import { Field } from "formik";
import { CustomInput } from "reactstrap";

import ConfirmationModal from "../confirmation-modal";

class FormikConfirmSwitch extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.isOpen !== this.state.isOpen ||
      nextProps.disabled !== this.state.disabled
    );
  }

  state = {
    isOpen: false,
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { isOpen } = this.state;
    const { fieldName, label, disabled } = this.props;

    return (
      <Field name={fieldName}>
        {({ field: { value, name }, form }) => {
          return (
            <>
              <ConfirmationModal
                title="Confirmation"
                message="Are you sure you want to proceed with this action?"
                closeModal={this.toggleModal}
                confirmModal={() => {
                  this.toggleModal();
                  form.setFieldValue(name, !value);
                }}
                {...{ isOpen }}
              />

              <div className="fts-reverse-switch">
                <CustomInput
                  checked={value}
                  type="switch"
                  id={name}
                  name={name}
                  inline
                  disabled={disabled}
                  onChange={() => {
                    this.toggleModal();
                  }}
                >
                  <span className="switch-label">{label}</span>
                </CustomInput>
              </div>
            </>
          );
        }}
      </Field>
    );
  }
}

export default FormikConfirmSwitch;
