import React, { PureComponent } from "react";
import { Row, Col, FormGroup, CustomInput } from "reactstrap";
import { Field } from "formik";

import AccountTypePicker from "../../../components/fts/account-type-picker";
import StatesPicker from "../../../components/fts/states-picker";
import StatusFilter from "../../../components/fts/status-filter";
import LeadsStatusPicker from "../../../components/fts/leads-status-picker";
import FormikInput from "../../../components/fts/formik/formik-input";
import Divider from "../../../components/fts/Divider";
import withDeviceType from "../../../components/hoc/withDeviceType";
import FormikMaskedInput from "../../../components/fts/formik/masked-input";
import { phoneNumberMask, zipCodeMask } from "../../../utility/masks";
import FTSNote from "../../../components/fts/fts-note";
import FormikConfirmActivation from "../../../components/fts/formik-confirm-activation";
import { FTSEmail } from "../../../components/fts/email";
import AltContacts from "../../../components/fts/alt-contacts";
import { ContextMenuTrigger } from "react-contextmenu";
import { urlRegex } from "../../../utility/url-validator";

class AccountInfo_Data extends PureComponent {
  state = {
    showLeadDropdown: false,
  };

  componentDidMount() {
    if (this.props.accountData.adminStatus === 2) {
      this.setState({ showLeadDropdown: true });
    }
  }

  render() {
    const {
      editMode,
      setFieldTouched,
      setFieldError,
      altContacts,
      errors,
      accountID,
      getAltContactInfo,
      noteLabelValue,
      setFieldValue,
      accountData,
      setNewAltContacts,
      updateAltContactAction,
      removeAltContacts,
    } = this.props;

    const { website: initialWebsiteValue } = accountData;

    const tripleItemColProps = {
      md: "12",
      lg: "8",
    };

    const websiteLink = (website) => {
      const isUrl = urlRegex.test(website);

      if (!website) return "-";
      if (website && !isUrl) return `${website}`;
      if (website && !website.includes("://")) website = `https://${website}`;

      return (
        <a href={website} target="_blank" rel="noopener noreferrer">
          {initialWebsiteValue}
        </a>
      );
    };

    return (
      <React.Fragment>
        {editMode && (
          <React.Fragment>
            <Divider textColor="primary" position="left">
              Account
            </Divider>
            <Row className="mb-2">
              <Col sm="6" md="3">
                <FormGroup>
                  <Field name="userType">
                    {({ meta, form, field }) => (
                      <AccountTypePicker
                        value={field.value}
                        accountData={accountData}
                        required
                        isDisabled={accountData.accountLink || !editMode}
                        label="Account type"
                        name="userType"
                        placeholder="Select account type..."
                        setFieldValue={form.setFieldValue}
                        onBlur={field.onBlur}
                        {...{
                          meta,
                          setFieldTouched: form.setFieldTouched,
                          setFieldError: form.setFieldError,
                        }}
                      />
                    )}
                  </Field>
                </FormGroup>
              </Col>
              <Col>
                <Row>
                  <Col sm="6" md="3">
                    <FormGroup>
                      <Field name="adminStatus">
                        {({ field, meta, form }) => (
                          <StatusFilter
                            hideCustomer={!accountData.paidListingCount}
                            account
                            required
                            label="Account label"
                            placeholder="Select account label..."
                            isDisabled={accountData.accountLink || !editMode}
                            onStateChange={(option) => {
                              if (option) {
                                setFieldError("adminStatus", undefined);
                              }
                              form.setFieldValue("adminStatus", option);
                              if (option?.value === 2) {
                                this.setState({ showLeadDropdown: true });
                              } else {
                                this.setState({ showLeadDropdown: false });
                              }
                              setTimeout(() => {
                                setFieldTouched("adminStatus", true, true);
                              }, 0);
                            }}
                            value={field.value}
                            {...{ field, meta }}
                          />
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  {this.state.showLeadDropdown && (
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Field name="leadStatus">
                          {({ field, meta, form }) => (
                            <LeadsStatusPicker
                              account
                              label="Lead status"
                              placeholder="Select lead status..."
                              onStateChange={(option) => {
                                if (option) {
                                  setFieldError("leadStatus", undefined);
                                }
                                form.setFieldValue("leadStatus", option);
                                setTimeout(() => {
                                  setFieldTouched("leadStatus", true, true);
                                }, 0);
                              }}
                              value={field.value}
                              isDisabled={accountData.accountLink || !editMode}
                              {...{ field, meta }}
                            />
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                  )}
                  <Col sm="6" md="3">
                    <FormGroup>
                      <FormikConfirmActivation
                        required
                        account
                        name="status"
                        label="Status"
                        isDisabled={accountData.accountLink || !editMode}
                        {...{ setFieldError, setFieldTouched }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="3">
                    <FormikInput
                      type="number"
                      disabled={accountData.accountLink || !editMode}
                      name="adminMerchantId"
                      label="Merchant ID"
                      labelId="adminMerchantId"
                      placeholder="Merchant ID"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        )}

        <div className="business-section-wrapper">
          <Divider textColor="primary" position="left">
            Business Info
          </Divider>
          <Row>
            <Col sm="6" lg="4" xl="4">
              <FormikInput
                disabled={!editMode}
                required
                name="companyName"
                label="Company name"
                labelId="companyName"
                placeholder="Company name"
              />
            </Col>
            <Col sm="8">
              <Row>
                <Col sm="6" lg="4" xl="4">
                  <FormikInput
                    disabled={!editMode}
                    required
                    name="firstName"
                    label="First name"
                    labelId="firstName"
                    placeholder="First name"
                  />
                </Col>
                <Col sm="6" lg="4" xl="4">
                  <FormikInput
                    disabled={!editMode}
                    required
                    name="lastName"
                    label="Last name"
                    labelId="lastName"
                    placeholder="Last name"
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="6" md="6" lg="4">
              <FormikInput
                disabled={!editMode}
                required
                name="address"
                label="Street address"
                labelId="address"
                placeholder="Street address"
              />
            </Col>
            <Col {...tripleItemColProps}>
              <Row>
                <Col sm="6" md="4">
                  <FormikInput
                    disabled={!editMode}
                    required
                    name="city"
                    label="City"
                    labelId="city"
                    placeholder="City"
                  />
                </Col>
                <Col sm="6" md="4">
                  <FormGroup>
                    <Field name="state">
                      {({ meta, form, field: { onBlur, value } }) => (
                        <StatesPicker
                          accountEditMode
                          isDisabled={!editMode}
                          required
                          name="state"
                          label="State"
                          placeholder="Select state..."
                          onStateChange={(option) => {
                            if (option) {
                              setFieldError("state", undefined);
                            }
                            form.setFieldValue("state", option);
                            setTimeout(() => {
                              setFieldTouched("state", true, true);
                            }, 0);
                          }}
                          {...{ meta, onBlur, value }}
                        />
                      )}
                    </Field>
                  </FormGroup>
                </Col>
                <Col sm="6" md="4">
                  <FormikMaskedInput
                    disabled={!editMode}
                    required
                    name="zip"
                    label="Zip code"
                    labelId="zip"
                    placeholder="Zip code"
                    mask={zipCodeMask}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="4">
              {editMode ? (
                <FormikInput
                  disabled={!editMode}
                  name="website"
                  label="Website"
                  labelId="website"
                  placeholder="Website link"
                />
              ) : (
                <span className="undefined">
                  <div className="form-group">
                    <label htmlFor="website-listing" className="disabled">
                      Website link
                    </label>
                    <div className="formik-input-value undefined">
                      {websiteLink(initialWebsiteValue)}
                    </div>
                  </div>
                </span>
              )}
            </Col>
            <Col sm="8">
              <Row>
                <Col sm="4">
                  {editMode ? (
                    <FormikInput
                      disabled={!editMode}
                      name="website2"
                      label="Website 2"
                      labelId="website2"
                      placeholder="Website secondary link"
                    />
                  ) : (
                    <span className="undefined">
                      <div className="form-group">
                        <label htmlFor="website2-listing" className="disabled">
                          Website 2
                        </label>
                        <div className="formik-input-value undefined">
                          {this.props.accountData?.website2 ? (
                            <a
                              href={`https://${this.props.accountData?.website2}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {this.props.accountData?.website2}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </span>
                  )}
                </Col>
                <Col sm="4">
                  <FormikInput
                    disabled={!editMode}
                    name="jobTitle"
                    label="Title / position"
                    labelId="jobTitle"
                    placeholder="Some title/position goes here"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider textColor="primary" position="left">
            Contact
          </Divider>
          <Row>
            <Col sm="6" lg="4" xl="4">
              <ContextMenuTrigger
                disable={editMode}
                data={this.props.accountFormRef?.current?.values?.mobilePhone}
                collect={() => {
                  this.props.collectDataForCopy(
                    this.props.accountFormRef?.current?.values?.mobilePhone,
                    "Phone",
                  );
                }}
                id="contextMenuAccountSettings"
              >
                <div className="cursor-pointer">
                  <FormikMaskedInput
                    disabled={!editMode}
                    name="mobilePhone"
                    label="Mobile phone"
                    labelId="mobilePhone"
                    placeholder="Mobile phone number"
                    mask={phoneNumberMask}
                    labelOptIn="Marketing Opt-in"
                    optInID="marketingMobileTel"
                  />
                </div>
              </ContextMenuTrigger>
            </Col>
            <Col sm="6" lg="4" xl="4">
              <ContextMenuTrigger
                disable={editMode}
                data={this.props.accountFormRef?.current?.values?.phoneNumber}
                collect={() => {
                  this.props.collectDataForCopy(
                    this.props.accountFormRef?.current?.values?.phoneNumber,
                    "Phone",
                  );
                }}
                id="contextMenuAccountSettings"
              >
                <div className="cursor-pointer">
                  <FormikMaskedInput
                    disabled={!editMode}
                    name="phoneNumber"
                    label="Primary phone number"
                    labelId="phoneNumber"
                    placeholder="Primary phone number"
                    mask={phoneNumberMask}
                  />
                </div>
              </ContextMenuTrigger>
            </Col>
            <Col sm="6" lg="4" xl="4">
              <ContextMenuTrigger
                disable={editMode}
                data={
                  this.props.accountFormRef?.current?.values
                    ?.phoneNumberSecondary
                }
                collect={() => {
                  this.props.collectDataForCopy(
                    this.props.accountFormRef?.current?.values
                      ?.phoneNumberSecondary,
                    "Phone",
                  );
                }}
                id="contextMenuAccountSettings"
              >
                <div className="cursor-pointer">
                  <FormikMaskedInput
                    disabled={!editMode}
                    name="phoneNumberSecondary"
                    label="Secondary phone number"
                    labelId="phoneNumberSecondary"
                    placeholder="Secondary phone number"
                    mask={phoneNumberMask}
                  />
                </div>
              </ContextMenuTrigger>
            </Col>

            <Col sm="12" md="12" lg="12" xl="12">
              <hr></hr>
            </Col>

            <Col sm="12" md="6" lg="4" xl="3">
              <FTSEmail
                disabled={!editMode}
                required
                name="email"
                label="E-mail"
                // optIn={true}
                labelOptIn="Marketing Opt-in"
                optInID="marketingEmail"
                placeholder="Enter E-mail address"
              />
            </Col>

            <Col sm="12" md="6" lg="4" xl="3">
              <FormGroup
                className="mb-1"
                style={{
                  marginTop: editMode ? "2.7rem" : "1.1rem",
                }}
              >
                <Field name="primaryMarketingOptIn">
                  {({ field: { value, name }, form }) => {
                    const accountPrimaryMarketingOptIn =
                      this.props.accountData?.primaryMarketingOptIn;

                    return (
                      <CustomInput
                        defaultChecked={accountPrimaryMarketingOptIn || value}
                        type="switch"
                        id="marketingOptIn"
                        inline
                        onChange={(e) => {
                          form.setFieldValue(name, e.target.checked);
                        }}
                        disabled={
                          // !havePermissionForMarketingOptIn || havePermissionForMarketingOptIn === 1 ||
                          !editMode
                        }
                      >
                        <span className="ml-1">Marketing Opt-in</span>
                      </CustomInput>
                    );
                  }}
                </Field>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4" xl="3">
              <FormGroup
                className="mb-1"
                style={{
                  marginTop: editMode ? "2.7rem" : "1.1rem",
                }}
              >
                <Field name="primaryUpdatesOptIn">
                  {({ field: { value, name }, form }) => {
                    const accountPrimaryUpdatesOptIn =
                      this.props.accountData?.primaryUpdatesOptIn;

                    return (
                      <CustomInput
                        defaultChecked={accountPrimaryUpdatesOptIn || value}
                        type="switch"
                        id="primaryUpdatesOptIn"
                        inline
                        onChange={(e) => {
                          form.setFieldValue(name, e.target.checked);
                        }}
                        disabled={
                          // !havePermissionForUpdatesOptIn || havePermissionForUpdatesOptIn === 1 ||
                          !editMode
                        }
                      >
                        <span className="ml-1">Updates Opt-in</span>
                      </CustomInput>
                    );
                  }}
                </Field>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4" xl="3">
              <FormGroup
                className="mb-1"
                style={{
                  marginTop: editMode ? "2.7rem" : "1.1rem",
                }}
              >
                <Field name="primaryRenewalsOptIn">
                  {({ field: { value, name }, form }) => {
                    const accountPrimaryRenewalOptIn =
                      this.props.accountData?.primaryRenewalsOptIn;

                    return (
                      <CustomInput
                        defaultChecked={accountPrimaryRenewalOptIn || value}
                        type="switch"
                        id="primaryRenewalsOptIn"
                        inline
                        onChange={(e) => {
                          form.setFieldValue(name, e.target.checked);
                        }}
                        disabled={
                          // !havePermissionForRenewalOptIn || havePermissionForRenewalOptIn === 1 ||
                          !editMode
                        }
                      >
                        <span className="ml-1">Renewal Opt-in</span>
                      </CustomInput>
                    );
                  }}
                </Field>
              </FormGroup>
            </Col>

            <Col sm="12" md="12" lg="12" xl="12">
              <hr></hr>
            </Col>

            <Col sm="12" md="6" lg="4" xl="3">
              <FTSEmail
                disabled={!editMode}
                name="additionalEmail"
                label="Additional E-mail"
                // optIn={true}
                labelOptIn="Marketing Opt-in"
                optInID="marketingAdditionalEmail"
                placeholder="Enter E-mail address"
              />
            </Col>

            <Col sm="12" md="6" lg="4" xl="3">
              <FormGroup
                className="mb-1"
                style={{
                  marginTop: editMode ? "2.7rem" : "1.2rem",
                }}
              >
                <Field name="additionalMarketingOptIn">
                  {({ field: { value, name }, form }) => {
                    const accountAdditioanlMarketingOptIn =
                      this.props.accountData?.additionalMarketingOptIn;

                    return (
                      <CustomInput
                        defaultChecked={
                          accountAdditioanlMarketingOptIn || value
                        }
                        type="switch"
                        id="additionalMarketingOptIn"
                        inline
                        onChange={(e) => {
                          form.setFieldValue(name, e.target.checked);
                        }}
                        disabled={
                          // !havePermissionForMarketingOptIn || havePermissionForMarketingOptIn === 1 ||
                          !editMode
                        }
                      >
                        <span className="ml-1">Marketing Opt-in</span>
                      </CustomInput>
                    );
                  }}
                </Field>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4" xl="3">
              <FormGroup
                className="mb-1"
                style={{
                  marginTop: editMode ? "2.7rem" : "1.1rem",
                }}
              >
                <Field name="additionalUpdatesOptIn">
                  {({ field: { value, name }, form }) => {
                    const accountAdditionalUpdatesOptIn =
                      this.props.accountData?.additionalUpdatesOptIn;

                    return (
                      <CustomInput
                        defaultChecked={accountAdditionalUpdatesOptIn || value}
                        type="switch"
                        id="additionalUpdatesOptIn"
                        inline
                        onChange={(e) => {
                          form.setFieldValue(name, e.target.checked);
                        }}
                        disabled={
                          // !havePermissionForUpdatesOptIn || havePermissionForUpdatesOptIn === 1 ||
                          !editMode
                        }
                      >
                        <span className="ml-1">Updates Opt-in</span>
                      </CustomInput>
                    );
                  }}
                </Field>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4" xl="3">
              <FormGroup
                className="mb-1"
                style={{
                  marginTop: editMode ? "2.7rem" : "1.1rem",
                }}
              >
                <Field name="additionalRenewalsOptIn">
                  {({ field: { value, name }, form }) => {
                    const accountAdditionalRenewalOptIn =
                      this.props.accountData?.additionalRenewalsOptIn;

                    return (
                      <CustomInput
                        defaultChecked={accountAdditionalRenewalOptIn || value}
                        type="switch"
                        id="additionalRenewalsOptIn"
                        inline
                        onChange={(e) => {
                          form.setFieldValue(name, e.target.checked);
                        }}
                        disabled={
                          // !havePermissionForRenewalOptIn || havePermissionForRenewalOptIn === 1 ||
                          !editMode
                        }
                      >
                        <span className="ml-1">Renewal Opt-in</span>
                      </CustomInput>
                    );
                  }}
                </Field>
              </FormGroup>
            </Col>
          </Row>
          <Divider textColor="primary" position="left">
            Alt Contacts
          </Divider>
          <Row>
            <Col>
              <AltContacts
                updateAltContactAction={updateAltContactAction}
                setNewAltContacts={setNewAltContacts}
                removeAltContacts={removeAltContacts}
                list={altContacts}
                setFieldValue={setFieldValue}
                {...{
                  editMode,
                  setFieldError,
                  setFieldTouched,
                  errors,
                  accountID,
                  getAltContactInfo,
                }}
              />
            </Col>
          </Row>
          <Divider textColor="primary" position="left">
            Website
          </Divider>
          <Row>
            <Col sm="6" lg="4" xl="4">
              <FormikInput
                disabled={!editMode}
                name="facebook"
                label="Facebook"
                labelId="facebook"
                placeholder="Facebook link"
              />
            </Col>
            <Col sm="6" lg="4" xl="4">
              <FormikInput
                disabled={!editMode}
                name="linkedIn"
                label="LinkedIn"
                labelId="linkedin"
                placeholder="Linkedin link"
              />
            </Col>
            <Col sm="6" lg="4" xl="4">
              <FormikInput
                disabled={!editMode}
                name="google"
                label="Google"
                labelId="google"
                placeholder="Google link"
              />
            </Col>
            <Col sm="6" lg="4" xl="4">
              <FormikInput
                disabled={!editMode}
                name="twitter"
                label="X"
                labelId="twitter"
                placeholder="X link"
              />
            </Col>
            <Col sm="6" lg="4" xl="4">
              <FormikInput
                disabled={!editMode}
                name="quickbook"
                label="Quickbooks"
                labelId="quickbook"
                placeholder="Quickbooks link"
              />
            </Col>
            <Col sm="6" lg="4" xl="4">
              <FormikInput
                disabled={!editMode}
                name="other"
                label="Other"
                labelId="other"
                placeholder="Other links"
              />
            </Col>
          </Row>
          <Divider textColor="primary" position="left">
            Note
          </Divider>
          <Row>
            <Col>
              <FTSNote
                noteRequired={false}
                noSticky
                name="adminNote"
                label="Account note"
                labelId="adminNote"
                typeName="adminNoteType"
                typePlaceholder="Note type..."
                placeholder="Some note goes here..."
                stickyName="adminNoteIsSticky"
                disabled={!editMode}
                isNewNote
                {...{ setFieldError, setFieldTouched, noteLabelValue }}
              />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withDeviceType(AccountInfo_Data);
