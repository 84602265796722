import React, { useCallback, useEffect, useRef } from "react";
import {
  Popover as ReactstrapPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import TitleRow from "./TitleRow";

import "../../assets/scss/components/fts/popover.scss";

const Popover = ({
  placement = "bottom",
  isOpen,
  target,
  toggle,
  close,
  title,
  header,
  children,
  ...rest
}) => {
  const backdrop = useRef();

  const addBackdrop = useCallback(() => {
    const backdropElement = document.createElement("div");
    backdropElement.className = "fts-popover__backdrop";
    backdropElement.onclick = close;

    backdrop.current = backdropElement;
    document.body.appendChild(backdropElement);
  }, [close]);

  const removeBackdrop = useCallback(() => {
    // remove backdrop from the body element
    backdrop.current.onclick = null;
    document.body.removeChild(backdrop.current);
  }, []);

  useEffect(() => {
    if (isOpen) {
      // add backdrop
      addBackdrop();
    } else {
      // remove backdrop
      removeBackdrop();
    }

    return removeBackdrop;
  }, [isOpen, addBackdrop, removeBackdrop]);

  return (
    <ReactstrapPopover
      className="fts-popover"
      placement={placement}
      isOpen={isOpen}
      target={target}
      toggle={toggle}
      {...rest}
    >
      <PopoverHeader>
        {header}
        {!header && <TitleRow title={title}></TitleRow>}
      </PopoverHeader>
      <PopoverBody>{children}</PopoverBody>
    </ReactstrapPopover>
  );
};

export default Popover;
