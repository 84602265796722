import FTSAxios from "../../../axios/fts.instance";

export const updateRenewalsFilter = (data) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_RENEWALS_FILTER", data });
  };
};

export const clearRenewalsFilter = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_RENEWALS_FILTER" });
  };
};

export const getRenewals = (payload) => {
  return () => {
    return FTSAxios.post("admin/getRenewals", payload);
  };
};
