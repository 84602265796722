import React from "react";
import * as Icon from "react-feather";
import Icons from "../components/fts/Icons";

const navigationConfig = [
  {
    id: "searchAccounts",
    title: "Search",
    type: "item",
    permissions: [{ id: 1, subCat: [1] }],
    navLink: "/accounts",
    icon: <Icons name="Search" size={20} />,
  },
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    permissions: [{ id: 1, subCat: [2] }],
    icon: <Icon.Home size={20} />,
    navLink: "/",
  },
  {
    id: "accounts",
    title: "Create Account",
    type: "item",
    permissions: [{ id: 1, subCat: [3] }],
    icon: <Icon.Users size={20} />,
    navLink: "/new-customer",
  },
  {
    id: "promotions",
    title: "Promotions",
    type: "item",
    permissions: [{ id: 1, subCat: [4] }],
    navLink: "/promotions",
    icon: <Icons name="Promotion" size={20} />,
  },
  {
    id: "activities",
    title: "Activities",
    type: "item",
    permissions: [{ id: 1, subCat: [5] }],
    navLink: "/activities",
    icon: <Icons name="ActivityWhite" size={20} />,
  },
  {
    id: "updates",
    title: "Updates",
    type: "item",
    permissions: [{ id: 1, subCat: [6] }],
    navLink: "/updates",
    icon: <Icons name="Updates" size={20} />,
  },
  {
    id: "renewals",
    title: "Renewals",
    type: "item",
    permissions: [{ id: 1, subCat: [7] }],
    navLink: "/renewals",
    icon: <Icons name="Renewals" size={20} />,
  },
  {
    id: "sales",
    title: "Sales",
    type: "item",
    permissions: [{ id: 1, subCat: [8] }],
    navLink: "/sales",
    icon: <Icons name="Sales" size={20} />,
  },
  {
    id: "invoices",
    title: "Invoices",
    type: "item",
    permissions: [{ id: 1, subCat: [9] }],
    navLink: "/invoice",
    icon: <Icons name="Invoices" size={20} />,
  },
  {
    id: "payments",
    title: "Payments",
    type: "collapse",
    permissions: [{ id: 1, subCat: [10] }],
    icon: <Icons name="Payment" size={20} />,
    children: [
      {
        id: "pastPayments",
        title: "Past payments",
        type: "item",
        permissions: [],
        navLink: "/payments",
      },
      {
        id: "recurringPayments",
        title: "Recurring payments",
        type: "item",
        permissions: [],
        navLink: "/recurring-payments",
      },
    ],
  },
  {
    id: "communications",
    title: "Communications",
    type: "item",
    permissions: [{ id: 1, subCat: [11] }],
    navLink: "/communications",
    icon: <Icons name="Marketing" size={20} />,
  },
  {
    id: "tasks-management",
    title: "Todo's",
    type: "item",
    permissions: [{ id: 1, subCat: [12] }],
    navLink: "/tasks-management",
    icon: <Icons name="Todos" size={20} />,
  },
  {
    id: "management",
    title: "Management",
    type: "collapse",
    permissions: [{ id: 1, subCat: [14] }],
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: "userManagement",
        title: "User Management",
        type: "item",
        navLink: "/user-management",
      },
      {
        id: "approved",
        title: "Approved",
        type: "item",
        navLink: "/approved",
      },
    ],
  },
  {
    id: "meta",
    title: "Meta",
    type: "collapse",
    permissions: [{ id: 1, subCat: [13] }],
    icon: <Icons name="Meta" size={20} />,
    children: [
      {
        id: "categories",
        title: "Categories",
        type: "item",
        permissions: [],
        navLink: "/categories",
      },
      {
        id: "subcategories",
        title: "Subcategories",
        type: "item",
        permissions: [],
        navLink: "/subcategories",
      },
      {
        id: "userTypes",
        title: "User Types",
        type: "item",
        permissions: [],
        navLink: "/user-types",
      },
      {
        id: "todoTypes",
        title: "Todo/Note Types",
        type: "item",
        permissions: [],
        navLink: "/todo-types",
      },
      {
        id: "serviceAmenities",
        title: "Service Amenities",
        type: "item",
        permissions: [],
        navLink: "/service-amenities",
      },
      {
        id: "amenities",
        title: "Amenities",
        type: "item",
        permissions: [],
        navLink: "/amenities",
      },
      {
        id: "adsListing",
        title: "Ads",
        type: "item",
        permissions: [],
        navLink: "/ads",
      },
      {
        id: "promocodes",
        title: "Promo codes",
        type: "item",
        permissions: [],
        navLink: "/promocodes",
      },
      // {
      //   id: "invoices",
      //   title: "Invoices",
      //   type: "item",
      //   permissions: [],
      //   navLink: "/invoices",
      // },
      {
        id: "emailTemplates",
        title: "Email templates",
        type: "item",
        permissions: [],
        navLink: "/email-templates",
      },
      {
        id: "permissions",
        title: "Permissions",
        type: "item",
        permissions: [],
        navLink: "/permissions",
      },
    ],
  },
];

export default navigationConfig;
