import React from "react";

import { ReactComponent as Activity } from "../../assets/img/svg/Icon/Activity.svg";
import { ReactComponent as ActivityWhite } from "../../assets/img/svg/ActivityWhite.svg";
import { ReactComponent as Account } from "../../assets/img/svg/Icon/Account.svg";
import { ReactComponent as Profile } from "../../assets/img/svg/Icon/ProfileAvatar.svg";
import { ReactComponent as Info } from "../../assets/img/svg/Icon/Info.svg";
import { ReactComponent as Unpin } from "../../assets/img/svg/Icon/Quick action icons/Unpin.svg";

import { ReactComponent as Check } from "../../assets/img/svg/Icon/Listing_Icons/Checkmark/Check.svg";
import { ReactComponent as Filter } from "../../assets/img/svg/Icon/filter.svg";
import { ReactComponent as CheckGreen } from "../../assets/img/svg/CheckGreen.svg";
import { ReactComponent as CheckInactive } from "../../assets/img/svg/Icon/Listing_Icons/Checkmark/Inactive.svg";
import { ReactComponent as CheckActive } from "../../assets/img/svg/Icon/Listing_Icons/Checkmark/Active.svg";
import { ReactComponent as Payment } from "../../assets/img/svg/Icon/Listing_Icons/Payment.svg";
import { ReactComponent as Attach } from "../../assets/img/svg/Icon/Listing_Icons/Attach.svg";
import { ReactComponent as Eye } from "../../assets/img/svg/Icon/Listing_Icons/Eye.svg";
import { ReactComponent as Files } from "../../assets/img/svg/Icon/Listing_Icons/Files.svg";
import { ReactComponent as Listings } from "../../assets/img/svg/Icon/Listing_Icons/Listing.svg";
import { ReactComponent as Accounts } from "../../assets/img/svg/Icon/Listing_Icons/Accounts.svg";
import { ReactComponent as AddNote } from "../../assets/img/svg/Icon/Listing_Icons/Add note.svg";
import { ReactComponent as Bookmark } from "../../assets/img/svg/Icon/Listing_Icons/Bookmark.svg";
import { ReactComponent as Calendar } from "../../assets/img/svg/Icon/Listing_Icons/Calendar.svg";
import { ReactComponent as Notifications } from "../../assets/img/svg/Icon/Notification.svg";
import { ReactComponent as Search } from "../../assets/img/svg/Icon/Listing_Icons/Search.svg";
import { ReactComponent as Phone } from "../../assets/img/svg/Icon/Listing_Icons/Phone.svg";
import { ReactComponent as PhoneBlue } from "../../assets/img/svg/Icon/Listing_Icons/PhoneBlue.svg";
import { ReactComponent as ListingActivity } from "../../assets/img/svg/Icon/Listing_Icons/Activity.svg";
import { ReactComponent as Flag } from "../../assets/img/svg/Icon/Flag.svg";
import { ReactComponent as ClearCalendar } from "../../assets/img/svg/Icon/ClearCalendar.svg";
import { ReactComponent as ReportInfo } from "../../assets/img/svg/report-info.svg";

import { ReactComponent as Delete } from "../../assets/img/svg/Icon/Delete.svg";
import { ReactComponent as Edit } from "../../assets/img/svg/Edit.svg";
import { ReactComponent as Label } from "../../assets/img/svg/Label.svg";
import { ReactComponent as Megaphone } from "../../assets/img/svg/Megaphone.svg";
import { ReactComponent as NoAccount } from "../../assets/img/svg/NoAccount.svg";
import { ReactComponent as PaperBlank } from "../../assets/img/svg/PaperBlank.svg";

import { ReactComponent as Logo } from "../../assets/img/svg/Logo.svg";
import { ReactComponent as LoginKey } from "../../assets/img/svg/Login Key.svg";
import { ReactComponent as Towing } from "../../assets/img/svg/Towing.svg";
import { ReactComponent as TowingNew } from "../../assets/img/svg/Towing_new.svg";
import { ReactComponent as Email } from "../../assets/img/svg/Email.svg";
import { ReactComponent as Password } from "../../assets/img/svg/Password.svg";

import { ReactComponent as Tires } from "../../assets/img/svg/Tires.svg";
import { ReactComponent as Repair } from "../../assets/img/svg/Repair.svg";
import { ReactComponent as Dealers } from "../../assets/img/svg/Dealers.svg";
import { ReactComponent as Diagram } from "../../assets/img/svg/Diagram.svg";
import { ReactComponent as TruckRepair } from "../../assets/img/svg/truck-repair.svg";
import { ReactComponent as Parts } from "../../assets/img/svg/Parts.svg";
import { ReactComponent as MobileRepair } from "../../assets/img/svg/Mobile repair.svg";
import { ReactComponent as BgElement } from "../../assets/img/svg/Bg element.svg";

import { ReactComponent as RejectReason } from "../../assets/img/svg/reject-reason.svg";
import { ReactComponent as PreviewProof } from "../../assets/img/svg/preview-proof.svg";

import { ReactComponent as PaperScroll } from "../../assets/img/svg/PaperScroll.svg";
import { ReactComponent as PaperText } from "../../assets/img/svg/PaperText.svg";
import { ReactComponent as Todo } from "../../assets/img/svg/Todo.svg";
import { ReactComponent as SalesDashboard } from "../../assets/img/svg/Icon/SalesDashboard.svg";
import { ReactComponent as LeadsPool } from "../../assets/img/svg/Icon/LeadsPool.svg";
import { ReactComponent as Customers } from "../../assets/img/svg/Icon/Customers.svg";
import { ReactComponent as ActiveLeads } from "../../assets/img/svg/Icon/ActiveLeads.svg";

import { ReactComponent as Todos } from "../../assets/img/svg/Icon/Quick action icons/Todos.svg";
import { ReactComponent as Promotion } from "../../assets/img/svg/Icon/Quick action icons/Promotion.svg";
import { ReactComponent as Report } from "../../assets/img/svg/Icon/Quick action icons/Report.svg";
import { ReactComponent as Marketing } from "../../assets/img/svg/Icon/Quick action icons/Marketing.svg";
import { ReactComponent as Note } from "../../assets/img/svg/Icon/Quick action icons/Add note.svg";
import { ReactComponent as Listing } from "../../assets/img/svg/Icon/Quick action icons/Listing.svg";

import { ReactComponent as BackToList } from "../../assets/img/svg/Icon/Arrow/Back To list.svg";
import { ReactComponent as Back } from "../../assets/img/svg/Icon/Back.svg";
import { ReactComponent as ArrowLeft } from "../../assets/img/svg/Icon/Listing_Icons/ArrowLeft.svg";
import { ReactComponent as CollapseLeft } from "../../assets/img/svg/Icon/CollapseLeft.svg";
import { ReactComponent as ArrowDown } from "../../assets/img/svg/Icon/Arrow/Down.svg";
import { ReactComponent as Time } from "../../assets/img/svg/Icon/time.svg";
import { ReactComponent as Reply } from "../../assets/img/svg/reply on comment.svg";
import { ReactComponent as TodoNote } from "../../assets/img/svg/Icon/Note.svg";
import { ReactComponent as NewTask } from "../../assets/img/svg/Icon/new-task.svg";
import { ReactComponent as Owner } from "../../assets/img/svg/Icon/Owner.svg";
import { ReactComponent as Drag } from "../../assets/img/svg/Icon/Icon-Drag.svg";
import { ReactComponent as Placeholder } from "../../assets/img/svg/Icon/Icon-Placeholder.svg";
import { ReactComponent as EmptySearch } from "../../assets/img/svg/Icon/EmptySearch.svg";
import { ReactComponent as Updates } from "../../assets/img/svg/Icon/Updates.svg";
import { ReactComponent as NoteBottom } from "../../assets/img/svg/Icon/NoteBottom.svg";
import { ReactComponent as DropdownEdit } from "../../assets/img/svg/Icon/DropdownEdit.svg";
import { ReactComponent as ArrowRight } from "../../assets/img/svg/Icon/ArrowRight.svg";
import { ReactComponent as Move } from "../../assets/img/svg/Icon/Move.svg";
import { ReactComponent as User } from "../../assets/img/svg/Icon/User.svg";
import { ReactComponent as DropdownArchive } from "../../assets/img/svg/Icon/DropdownArchive.svg";
import { ReactComponent as Meta } from "../../assets/img/svg/Icon/Meta.svg";
import { ReactComponent as Renewals } from "../../assets/img/svg/Icon/Renewals.svg";
import { ReactComponent as Sales } from "../../assets/img/svg/Icon/Sales.svg";
import { ReactComponent as MoreVertical } from "../../assets/img/svg/Icon/MoreVertical.svg";
import { ReactComponent as MoreHorizontal } from "../../assets/img/svg/Icon/MoreHorizontal.svg";
import { ReactComponent as Checked } from "../../assets/img/svg/Icon/Checked.svg";
import { ReactComponent as CheckCircle } from "../../assets/img/svg/Icon/CheckCircle.svg";
import { ReactComponent as CheckCircle2 } from "../../assets/img/svg/Icon/CheckCircle2.svg";
import { ReactComponent as EditAction } from "../../assets/img/svg/Icon/EditAction.svg";
import { ReactComponent as FileText } from "../../assets/img/svg/Icon/FileText.svg";
import { ReactComponent as Tag } from "../../assets/img/svg/Icon/Tag.svg";
import { ReactComponent as PieChart } from "../../assets/img/svg/Icon/PieChart.svg";
import { ReactComponent as BarChart } from "../../assets/img/svg/Icon/BarChart.svg";
import { ReactComponent as CreditCard } from "../../assets/img/svg/Icon/CreditCard.svg";
import { ReactComponent as GoTo } from "../../assets/img/svg/Icon/GoTo.svg";
import { ReactComponent as Crown } from "../../assets/img/svg/Icon/Crown.svg";
import { ReactComponent as AttachBottom } from "../../assets/img/svg/Icon/AttachBottom.svg";
import { ReactComponent as TodoArchived } from "../../assets/img/svg/Icon/TodoArchived.svg";
import { ReactComponent as ChevronDown } from "../../assets/img/svg/Icon/Chevron-down.svg";
import { ReactComponent as GenerateReport } from "../../assets/img/svg/Icon/GenerateReport.svg";
import { ReactComponent as GenerateReportSuccess } from "../../assets/img/svg/Icon/GenerateReportSuccess.svg";
import { ReactComponent as Proof } from "../../assets/img/svg/Icon/Proof.svg";

import { ReactComponent as Share } from "../../assets/img/svg/Icon/Icon-Share.svg";
import { ReactComponent as Exit } from "../../assets/img/svg/Icon/Icon-Exit.svg";
import { ReactComponent as CopyLink } from "../../assets/img/svg/Icon/Copy-link.svg";
import { ReactComponent as Archive } from "../../assets/img/svg/Icon/Icon-Archive.svg";
import { ReactComponent as AddToCalendar } from "../../assets/img/svg/Icon/Icon-Add-to-calendar.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/img/svg/duplicate.svg";
import { ReactComponent as Location } from "../../assets/img/svg/Icon/Location.svg";
import { ReactComponent as LogACall } from "../../assets/img/svg/Icon/LogACall.svg";
import { ReactComponent as LogAnEmail } from "../../assets/img/svg/Icon/LogAnEmail.svg";
import { ReactComponent as SaveWithoutLogging } from "../../assets/img/svg/Icon/SaveWithoutLogging.svg";
import { ReactComponent as ReassignAccountConfirmation } from "../../assets/img/svg/Icon/ReassignAccountConfirmation.svg";
import { ReactComponent as Reassign } from "../../assets/img/svg/Icon/Reassign.svg";
import { ReactComponent as Preview } from "../../assets/img/svg/Icon/Preview.svg";
import { ReactComponent as Update } from "../../assets/img/svg/Icon/Update.svg";
import { ReactComponent as EditAccount } from "../../assets/img/svg/Icon/EditAccount.svg";
import { ReactComponent as Invoices } from "../../assets/img/svg/Icon/Invoices.svg";
import { ReactComponent as File } from "../../assets/img/svg/Icon/File.svg";
import { ReactComponent as Card } from "../../assets/img/svg/Icon/Card.svg";
import { ReactComponent as Link } from "../../assets/img/svg/Icon/Link.svg";
import { ReactComponent as CheckIcon } from "../../assets/img/svg/Icon/Check.svg";
import { ReactComponent as InfoCircle } from "../../assets/img/svg/Icon/InfoCircle.svg";

import { ReactComponent as AmeEx } from "../../assets/img/svg/Icon/AmeEx.svg";
import { ReactComponent as Download } from "../../assets/img/svg/Icon/Download.svg";
import { ReactComponent as Plus } from "../../assets/img/svg/Icon/Plus.svg";
import { ReactComponent as CardRemove } from "../../assets/img/svg/card-remove.svg";
import { ReactComponent as Open } from "../../assets/img/svg/Icon/Open.svg";
import { ReactComponent as Send } from "../../assets/img/svg/Icon/Send.svg";
import { ReactComponent as SendAction } from "../../assets/img/svg/Icon/SendAction.svg";
import { ReactComponent as SendActivity } from "../../assets/img/svg/Icon/SendActivity.svg";
import { ReactComponent as LogACallActivity } from "../../assets/img/svg/Icon/LogACallActivity.svg";
import { ReactComponent as LogAnEmailActivity } from "../../assets/img/svg/Icon/LogAnEmailActivity.svg";
import { ReactComponent as OpenAction } from "../../assets/img/svg/Icon/OpenAction.svg";
import { ReactComponent as PreviewAction } from "../../assets/img/svg/Icon/PreviewAction.svg";
import { ReactComponent as UpdateAction } from "../../assets/img/svg/Icon/UpdateAction.svg";

import { ReactComponent as Trash } from "../../assets/img/svg/Icon/Trash.svg";
import { ReactComponent as UserAccount } from "../../assets/img/svg/Icon/UserAccount.svg";
import { ReactComponent as ACH } from "../../assets/img/svg/Icon/ACH.svg";
import { ReactComponent as Plaid } from "../../assets/img/svg/Icon/Plaid.svg";
import { ReactComponent as Paypal } from "../../assets/img/svg/Icon/Paypal.svg";

import { ReactComponent as LeadsDashboard } from "../../assets/img/svg/Icon/LeadsDashboard.svg";
import { ReactComponent as TrialsDashboard } from "../../assets/img/svg/Icon/TrialsDashboard.svg";
import { ReactComponent as RenewalsDashboard } from "../../assets/img/svg/Icon/RenewalsDashboard.svg";
import { ReactComponent as RenewalsSection } from "../../assets/img/svg/Icon/RenewalsSection.svg";
import { ReactComponent as CallsDashboard } from "../../assets/img/svg/Icon/CallsDashboard.svg";
import { ReactComponent as EmailsDashboard } from "../../assets/img/svg/Icon/EmailsDashboard.svg";
import { ReactComponent as UpdatesDashboard } from "../../assets/img/svg/Icon/UpdatesDashboard.svg";
import { ReactComponent as NewtrialsDashboard } from "../../assets/img/svg/Icon/NewtrialsDashboard.svg";
import { ReactComponent as DealsDashboard } from "../../assets/img/svg/Icon/DealsDashboard.svg";
import { ReactComponent as NewListings } from "../../assets/img/svg/Icon/NewListings.svg";
import { ReactComponent as Upgrades } from "../../assets/img/svg/Icon/Upgrades.svg";
import { ReactComponent as Downgrades } from "../../assets/img/svg/Icon/Downgrades.svg";
import { ReactComponent as EmptyTodos } from "../../assets/img/svg/Icon/EmptyTodos.svg";
import { ReactComponent as ArrowLink } from "../../assets/img/svg/Icon/ArrowLink.svg";
import { ReactComponent as Congratulations } from "../../assets/img/svg/Icon/Congratulations.svg";
import { ReactComponent as UpdateActivity } from "../../assets/img/svg/Icon/UpdateActivity.svg";

import { ReactComponent as Notification } from "../../assets/img/notification/notification.svg";
import { ReactComponent as Close } from "../../assets/img/svg/Icon/close.svg";
import { ReactComponent as ApproveTodo } from "../../assets/img/svg/Icon/ApproveTodo.svg";
import { ReactComponent as TodoActivity } from "../../assets/img/svg/Icon/TodoActivity.svg";
import { ReactComponent as NoteActivity } from "../../assets/img/svg/Icon/NoteActivity.svg";
import { ReactComponent as PromotionActivity } from "../../assets/img/svg/Icon/PromotionActivity.svg";
import { ReactComponent as UpdatesMenuItem } from "../../assets/img/svg/Icon/UpdatesMenuItem.svg";
import { ReactComponent as ListingUpdate } from "../../assets/img/svg/Icon/ListingUpdate.svg";
import { ReactComponent as ListingSuccessful } from "../../assets/img/svg/Icon/ListingSuccessful.svg";
import { ReactComponent as EmailIcon } from "../../assets/img/svg/Icon/EmailIcon.svg";
import { ReactComponent as SendProof } from "../../assets/img/svg/Icon/SendProof.svg";
import { ReactComponent as ApproveProof } from "../../assets/img/svg/Icon/ApproveProof.svg";
import { ReactComponent as ProofSuccess } from "../../assets/img/svg/Icon/ProofSuccess.svg";
import { ReactComponent as Miles } from "../../assets/img/svg/Icon/Miles.svg";
import { ReactComponent as MapPin } from "../../assets/img/svg/Icon/MapPin.svg";
import { ReactComponent as Clock } from "../../assets/img/svg/Icon/Clock.svg";
import { ReactComponent as Fuel } from "../../assets/img/svg/Icon/Fuel.svg";
import { ReactComponent as CollapseArrow } from "../../assets/img/svg/Icon/CollapseArrow.svg";
import { ReactComponent as Star } from "../../assets/img/svg/Icon/Star.svg";
import { ReactComponent as Notes } from "../../assets/img/svg/Icon/Notes.svg";
import { ReactComponent as DollarCircle } from "../../assets/img/svg/Icon/DollarCircle.svg";
import { ReactComponent as Setting } from "../../assets/img/svg/Icon/Setting.svg";
import { ReactComponent as Review } from "../../assets/img/svg/Icon/Review.svg";
import { ReactComponent as CreditCardRed } from "../../assets/img/svg/Icon/CreditCardRed.svg";
import { ReactComponent as IconInfo } from "../../assets/img/svg/Icon/IconInfo.svg";
import { ReactComponent as PromotionTag } from "../../assets/img/svg/Icon/PromotionTag.svg";
import { ReactComponent as ProofRevised } from "../../assets/img/svg/Icon/ProofRevised.svg";
import { ReactComponent as RejectReasonModal } from "../../assets/img/svg/Icon/RejectReason.svg";
import { ReactComponent as AccountsActivity } from "../../assets/img/svg/Icon/AccountsActivity.svg";
import { ReactComponent as Upgrade } from "../../assets/img/svg/Icon/Upgrade.svg";
import { ReactComponent as Downgrading } from "../../assets/img/svg/Icon/Downgrading.svg";
import { ReactComponent as Credit } from "../../assets/img/svg/Icon/Credit.svg";
import { ReactComponent as UpgradesIcon } from "../../assets/img/svg/Icon/UpgradesIcon.svg";
import { ReactComponent as MobilePhone } from "../../assets/img/svg/Icon/MobilePhone.svg";
import { ReactComponent as Desktop } from "../../assets/img/svg/Icon/Desktop.svg";
import { ReactComponent as Admin } from "../../assets/img/svg/Icon/Admin.svg";
import { ReactComponent as ShareListing } from "../../assets/img/svg/Icon/ShareListing.svg";

import { ReactComponent as FleetCarrier } from "../../assets/img/svg/Icon/FleetCarrier.svg";
import { ReactComponent as OwnerOperator } from "../../assets/img/svg/Icon/OwnerOperator.svg";
import { ReactComponent as ServiceVendor } from "../../assets/img/svg/Icon/ServiceVendor.svg";
import { ReactComponent as CompanyDriver } from "../../assets/img/svg/Icon/CompanyDriver.svg";
import { ReactComponent as OtherType } from "../../assets/img/svg/Icon/OtherType.svg";

import { ReactComponent as ActiveUser } from "../../assets/img/svg/Icon/ActiveUser.svg";
import { ReactComponent as DisabledUser } from "../../assets/img/svg/Icon/DisabledUser.svg";
import { ReactComponent as DeactivatedUser } from "../../assets/img/svg/Icon/DeactivatedUser.svg";
import { ReactComponent as Lock } from "../../assets/img/svg/Icon/Lock.svg";
import { ReactComponent as LockDisabled } from "../../assets/img/svg/Icon/LockDisabled.svg";
import { ReactComponent as ChevronRight } from "../../assets/img/svg/Icon/ChevronRight.svg";
import { ReactComponent as LockGreen } from "../../assets/img/svg/Icon/LockGreen.svg";
import { ReactComponent as LockRed } from "../../assets/img/svg/Icon/LockRed.svg";
import { ReactComponent as AlertTriangle } from "../../assets/img/svg/Icon/AlertTriangle.svg";
import { ReactComponent as InfoIcon } from "../../assets/img/svg/Icon/InfoIcon.svg";

import { ReactComponent as Alipay } from "../../assets/img/svg/cards/alipay.svg";
import { ReactComponent as Amex } from "../../assets/img/svg/cards/amex.svg";
import { ReactComponent as Diners } from "../../assets/img/svg/cards/diners.svg";
import { ReactComponent as Discover } from "../../assets/img/svg/cards/discover.svg";
import { ReactComponent as Elo } from "../../assets/img/svg/cards/elo.svg";
import { ReactComponent as Generic } from "../../assets/img/svg/cards/generic.svg";
import { ReactComponent as Hiper } from "../../assets/img/svg/cards/hiper.svg";
import { ReactComponent as Hipercard } from "../../assets/img/svg/cards/hipercard.svg";
import { ReactComponent as Jcb } from "../../assets/img/svg/cards/jcb.svg";
import { ReactComponent as Maestro } from "../../assets/img/svg/cards/maestro.svg";
import { ReactComponent as Mastercard } from "../../assets/img/svg/cards/mastercard.svg";
import { ReactComponent as Mir } from "../../assets/img/svg/cards/mir.svg";
import { ReactComponent as Unionpay } from "../../assets/img/svg/cards/unionpay.svg";
import { ReactComponent as Visa } from "../../assets/img/svg/cards/visa.svg";
import { ReactComponent as ExternalLink } from "../../assets/img/svg/ExternalLink.svg";
import { ReactComponent as EditListing } from "../../assets/img/svg/Icon/EditListing.svg";
import { ReactComponent as RenewalsV2 } from "../../assets/img/svg/Icon/Renewalsv2.svg";

import "../../assets/scss/components/fts/icon.scss";

export const Icon = ({ name, color, size, ...rest }) => {
  size = size ?? 20;

  const props = {
    ...rest,
    width: size,
    height: size,
    className: "fts-icon",
  };

  if (color) {
    props.className = "";
    props.fill = color;
    props.path = color;
  }

  const icons = {
    NewTask: <NewTask {...props} />,
    Activity: <Activity {...props} />,
    Account: <Account {...props} />,
    Check: <Check {...props} />,
    CheckInactive: <CheckInactive {...props} />,
    CheckActive: <CheckActive {...props} />,
    Payment: <Payment {...props} />,
    Delete: <Delete {...props} />,
    Edit: <Edit {...props} />,
    Eye: <Eye {...props} />,
    Files: <Files {...props} />,
    Label: <Label {...props} />,
    Megaphone: <Megaphone {...props} />,
    PaperBlank: <PaperBlank {...props} />,
    Towing: <Towing {...props} />,
    TowingNew: <TowingNew {...props} />,
    Password: <Password {...props} />,
    Email: <Email {...props} />,
    Logo: <Logo {...props} />,
    LoginKey: <LoginKey {...props} />,
    Tires: <Tires {...props} />,
    Dealers: <Dealers {...props} />,
    Repair: <Repair {...props} />,
    MobileRepair: <MobileRepair {...props} />,
    TruckRepair: <TruckRepair {...props} />,
    Parts: <Parts {...props} />,
    BgElement: <BgElement {...props} />,
    PaperScroll: <PaperScroll {...props} />,
    PaperText: <PaperText {...props} />,
    Todo: <Todo {...props} />,
    Todos: <Todos {...props} />,
    Note: <Note {...props} />,
    Listing: <Listing {...props} />,
    Listings: <Listings {...props} />,
    Promotion: <Promotion {...props} />,
    Report: <Report {...props} />,
    Marketing: <Marketing {...props} />,
    BackToList: <BackToList {...props} />,
    Back: <Back {...props} />,
    CollapseLeft: <CollapseLeft {...props} />,
    Time: <Time {...props} />,
    Attach: <Attach {...props} />,
    Reply: <Reply {...props} />,
    ArrowDown: <ArrowDown {...props} />,
    TodoNote: <TodoNote {...props} />,
    Accounts: <Accounts {...props} />,
    AddNote: <AddNote {...props} />,
    Bookmark: <Bookmark {...props} />,
    Calendar: <Calendar {...props} />,
    Notifications: <Notifications {...props} />,
    Search: <Search {...props} />,
    Owner: <Owner {...props} />,
    Share: <Share {...props} />,
    Archive: <Archive {...props} />,
    AddToCalendar: <AddToCalendar {...props} />,
    Drag: <Drag {...props} />,
    Placeholder: <Placeholder {...props} />,
    EmptySearch: <EmptySearch {...props} />,
    Updates: <Updates {...props} />,
    NoteBottom: <NoteBottom {...props} />,
    DropdownEdit: <DropdownEdit {...props} />,
    ArrowRight: <ArrowRight {...props} />,
    Move: <Move {...props} />,
    User: <User {...props} />,
    DropdownArchive: <DropdownArchive {...props} />,
    Meta: <Meta {...props} />,
    Renewals: <Renewals {...props} />,
    Sales: <Sales {...props} />,
    MoreVertical: <MoreVertical {...props} />,
    MoreHorizontal: <MoreHorizontal {...props} />,
    Checked: <Checked {...props} />,
    Phone: <Phone {...props} />,
    ListingActivity: <ListingActivity {...props} />,
    Profile: <Profile {...props} />,
    CheckCircle: <CheckCircle {...props} />,
    CheckCircle2: <CheckCircle2 {...props} />,
    EditAction: <EditAction {...props} />,
    FileText: <FileText {...props} />,
    Tag: <Tag {...props} />,
    PieChart: <PieChart {...props} />,
    BarChart: <BarChart {...props} />,
    CreditCard: <CreditCard {...props} />,
    GoTo: <GoTo {...props} />,
    Crown: <Crown {...props} />,
    AttachBottom: <AttachBottom {...props} />,
    TodoArchived: <TodoArchived {...props} />,
    ArrowLeft: <ArrowLeft {...props} />,
    Duplicate: <DuplicateIcon {...props} />,
    Info: <Info {...props} />,
    ChevronDown: <ChevronDown {...props} />,
    CopyLink: <CopyLink {...props} />,
    Unpin: <Unpin {...props} />,
    Flag: <Flag {...props} />,
    ClearCalendar: <ClearCalendar {...props} />,
    Location: <Location {...props} />,
    PhoneBlue: <PhoneBlue {...props} />,
    Trash: <Trash {...props} />,
    UserAccount: <UserAccount {...props} />,
    SalesDashboard: <SalesDashboard {...props} />,
    LeadsPool: <LeadsPool {...props} />,
    Customers: <Customers {...props} />,
    ActiveLeads: <ActiveLeads {...props} />,
    LogACall: <LogACall {...props} />,
    LogAnEmail: <LogAnEmail {...props} />,
    SaveWithoutLogging: <SaveWithoutLogging {...props} />,
    ReassignAccountConfirmation: <ReassignAccountConfirmation {...props} />,
    Reassign: <Reassign {...props} />,
    Preview: <Preview {...props} />,
    Update: <Update {...props} />,
    EditAccount: <EditAccount {...props} />,
    Invoices: <Invoices {...props} />,
    File: <File {...props} />,
    Card: <Card {...props} />,
    Link: <Link {...props} />,
    CheckIcon: <CheckIcon {...props} />,
    InfoCircle: <InfoCircle {...props} />,

    AmeEx: <AmeEx {...props} />,
    Download: <Download {...props} />,
    Plus: <Plus {...props} />,
    Exit: <Exit {...props} />,
    Open: <Open {...props} />,
    Send: <Send {...props} />,
    SendAction: <SendAction {...props} />,
    SendActivity: <SendActivity {...props} />,
    OpenAction: <OpenAction {...props} />,
    UpdateAction: <UpdateAction {...props} />,
    PreviewAction: <PreviewAction {...props} />,
    CardRemove: <CardRemove {...props} />,
    ACH: <ACH {...props} />,
    Plaid: <Plaid {...props} />,
    Paypal: <Paypal {...props} />,
    LeadsDashboard: <LeadsDashboard {...props} />,
    TrialsDashboard: <TrialsDashboard {...props} />,
    RenewalsDashboard: <RenewalsDashboard {...props} />,
    RenewalsSection: <RenewalsSection {...props} />,
    CallsDashboard: <CallsDashboard {...props} />,
    EmailsDashboard: <EmailsDashboard {...props} />,
    UpdatesDashboard: <UpdatesDashboard {...props} />,
    NewtrialsDashboard: <NewtrialsDashboard {...props} />,
    NewListings: <NewListings {...props} />,
    DealsDashboard: <DealsDashboard {...props} />,
    Upgrades: <Upgrades {...props} />,
    Downgrades: <Downgrades {...props} />,
    EmptyTodos: <EmptyTodos {...props} />,
    ArrowLink: <ArrowLink {...props} />,
    Congratulations: <Congratulations {...props} />,
    CheckGreen: <CheckGreen {...props} />,
    UpdateActivity: <UpdateActivity {...props} />,
    ActivityWhite: <ActivityWhite {...props} />,
    Notification: <Notification {...props} />,
    Close: <Close {...props} />,
    ApproveTodo: <ApproveTodo {...props} />,
    Filter: <Filter {...props} />,
    TodoActivity: <TodoActivity {...props} />,
    NoteActivity: <NoteActivity {...props} />,
    PromotionActivity: <PromotionActivity {...props} />,
    UpdatesMenuItem: <UpdatesMenuItem {...props} />,
    ListingUpdate: <ListingUpdate {...props} />,
    ListingSuccessful: <ListingSuccessful {...props} />,
    LogACallActivity: <LogACallActivity {...props} />,
    LogAnEmailActivity: <LogAnEmailActivity {...props} />,
    ReportInfo: <ReportInfo {...props} />,
    GenerateReport: <GenerateReport {...props} />,
    GenerateReportSuccess: <GenerateReportSuccess {...props} />,
    EmailIcon: <EmailIcon {...props} />,
    Proof: <Proof {...props} />,
    SendProof: <SendProof {...props} />,
    ApproveProof: <ApproveProof {...props} />,
    ProofSuccess: <ProofSuccess {...props} />,
    Miles: <Miles {...props} />,
    MapPin: <MapPin {...props} />,
    Clock: <Clock {...props} />,
    Fuel: <Fuel {...props} />,
    CollapseArrow: <CollapseArrow {...props} />,
    Star: <Star {...props} />,
    Notes: <Notes {...props} />,
    DollarCircle: <DollarCircle {...props} />,
    Setting: <Setting {...props} />,
    Review: <Review {...props} />,
    CreditCardRed: <CreditCardRed {...props} />,
    IconInfo: <IconInfo {...props} />,
    PromotionTag: <PromotionTag {...props} />,
    PreviewProof: <PreviewProof {...props} />,
    RejectReason: <RejectReason {...props} />,
    ProofRevised: <ProofRevised {...props} />,
    RejectReasonModal: <RejectReasonModal {...props} />,
    AccountsActivity: <AccountsActivity {...props} />,
    Upgrade: <Upgrade {...props} />,
    Downgrading: <Downgrading {...props} />,
    Credit: <Credit {...props} />,
    UpgradesIcon: <UpgradesIcon {...props} />,
    MobilePhone: <MobilePhone {...props} />,
    Desktop: <Desktop {...props} />,
    Admin: <Admin {...props} />,
    NoAccount: <NoAccount {...props} />,
    ShareListing: <ShareListing {...props} />,
    FleetCarrier: <FleetCarrier {...props} />,
    OwnerOperator: <OwnerOperator {...props} />,
    ServiceVendor: <ServiceVendor {...props} />,
    CompanyDriver: <CompanyDriver {...props} />,
    OtherType: <OtherType {...props} />,
    ActiveUser: <ActiveUser {...props} />,
    DisabledUser: <DisabledUser {...props} />,
    DeactivatedUser: <DeactivatedUser {...props} />,
    Diagram: <Diagram {...props} />,
    Lock: <Lock {...props} />,
    LockDisabled: <LockDisabled {...props} />,
    ChevronRight: <ChevronRight {...props} />,
    LockGreen: <LockGreen {...props} />,
    LockRed: <LockRed {...props} />,
    AlertTriangle: <AlertTriangle {...props} />,
    InfoIcon: <InfoIcon {...props} />,

    Visa: <Visa {...props} />,
    Mastercard: <Mastercard {...props} />,
    "American Express": <Amex {...props} />,
    "Diners Club": <Diners {...props} />,
    Discover: <Discover {...props} />,
    JCB: <Jcb {...props} />,
    UnionPay: <Unionpay {...props} />,
    Maestro: <Maestro {...props} />,
    Mir: <Mir {...props} />,
    Elo: <Elo {...props} />,
    Hiper: <Hiper {...props} />,
    Hipercard: <Hipercard {...props} />,
    Alipay: <Alipay {...props} />,
    Generic: <Generic {...props} />,
    ExternalLink: <ExternalLink {...props} />,
    EditListing: <EditListing {...props} />,
    RenewalsV2: <RenewalsV2 {...props} />,
  };

  const Component = icons[name] ?? null;
  return Component;
};

export default Icon;
