import React, { Component } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import classnames from "classnames";
import Spinner from "../../../components/@vuexy/spinner/Loading-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactPaginate from "react-paginate";
import { history } from "../../../history";
import { formatRawDate } from "../../../configs/dateConfig";
import {
  Edit,
  Trash,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
} from "react-feather";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getApprovedIPs,
  filterApprovedIPs,
  createApprovedIP,
  updateApprovedIP,
  deleteApprovedIP,
} from "../../../redux/actions/approvedIPs";
import Sidebar from "./ApprovedIPsSidebar";

import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";
import "../../../assets/scss/plugins/extensions/react-paginate.scss";
import "../../../assets/scss/pages/data-list.scss";

const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: "rgba(115,103,240,.05)",
      color: "#7367F0 !important",
      boxShadow: "0 0 1px 0 #7367F0 !important",
      "&:hover": {
        transform: "translateY(0px) !important",
      },
    },
  },
};

const ActionsComponent = (props) => {
  return (
    <div className="data-list-action">
      <Edit
        className="cursor-pointer mr-1"
        size={20}
        onClick={() => {
          return props.currentData(props.row);
        }}
      />
      <Trash
        className="cursor-pointer"
        size={20}
        onClick={() => {
          props.deleteRow(props.row);
        }}
      />
    </div>
  );
};

const CustomHeader = (props) => {
  return (
    <Row className="justify-content-between">
      <Col xs="6" sm="6" md="6">
        <div className="filter-section">
          <Input type="text" onChange={(e) => props.handleFilter(e)} />
        </div>
      </Col>
      <Col xs="6" sm="6" md="6">
        <Button
          type="button"
          className="p-1 float-right"
          color="primary"
          onClick={() => props.handleSidebar(true, true)}
        >
          + New IP
        </Button>
      </Col>
    </Row>
  );
};

class ApprovedViewConfig extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.approvedIPs.data.length !== state.data.length ||
      state.currentPage !== props.parsedFilter.page
    ) {
      return {
        data: props.approvedIPs.data,
        allData: props.approvedIPs.filteredData,
        totalPages: props.approvedIPs.totalPages,
        currentPage: parseInt(props.parsedFilter.page) - 1,
        rowsPerPage: parseInt(props.parsedFilter.perPage),
        totalRecords: props.approvedIPs.totalRecords,
        sortIndex: props.approvedIPs.sortIndex,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  state = {
    defaultAlert: false,
    confirmAlert: false,
    cancelAlert: false,
    data: [],
    totalPages: 0,
    currentPage: 0,
    columns: [
      {
        name: "IP Address",
        selector: "address",
        width: "320px",
        sortable: true,
        cell: (row) => (
          <p title={row.address} className="text-truncate text-bold-500 mb-0">
            {row.address}
          </p>
        ),
      },
      {
        name: "Note",
        selector: "note",
        sortable: true,
        width: "180px",
        cell: (row) => (
          <p title={row.note} className="text-truncate text-bold-500 mb-0">
            {row.note}
          </p>
        ),
      },
      {
        name: "Created by",
        selector: "createdBy",
        sortable: true,
        width: "130px",
        cell: (row) => (
          <p title={row.createdBy} className="text-truncate text-bold-500 mb-0">
            {row.createdBy}
          </p>
        ),
      },
      {
        name: "Created at",
        selector: "createdAt",
        sortable: true,
        cell: (row) => (
          <p
            title={formatRawDate(row.createdAt)}
            className="text-truncate text-bold-500 mb-0"
          >
            {formatRawDate(row.createdAt)}
          </p>
        ),
      },
      {
        name: "Actions",
        maxWidth: "150px",
        cell: (row) => (
          <ActionsComponent
            row={row}
            currentData={this.handleCurrentData}
            deleteRow={this.handleDelete}
          />
        ),
      },
    ],
    allData: [],
    value: "",
    rowsPerPage: 20,
    sidebar: false,
    inProgress: false,
    currentData: null,
    selected: [],
    totalRecords: 0,
    sortIndex: [],
    addNew: "",
  };

  thumbView = this.props.thumbView;
  addressToDelete = null;

  componentDidMount() {
    this.refreshList();
  }

  refreshList = () => {
    this.setState({ inProgress: true });
    this.props.getApprovedIPs(this.props.parsedFilter).finally(() => {
      this.setState({ inProgress: false });
    });
  };

  handleAlert = (state, value) => {
    this.setState({ [state]: value });
  };

  handleFilter = (e) => {
    this.setState({ value: e.target.value });
    this.props.filterApprovedIPs(e.target.value);
  };

  handleRowsPerPage = (value) => {
    let { parsedFilter, getData } = this.props;
    let page = parsedFilter.page !== undefined ? parsedFilter.page : 1;
    history.push(`/data-list/list-view?page=${page}&perPage=${value}`);
    this.setState({ rowsPerPage: value });
    getData({ page: page, perPage: value });
  };

  handleSidebar = (boolean, addNew = false) => {
    this.setState({ sidebar: boolean });
    if (addNew === true) this.setState({ currentData: null, addNew: true });
  };

  handleDelete = (row) => {
    if (this.state.inProgress) {
      return;
    }
    this.addressToDelete = row;
    this.handleAlert("defaultAlert", true);
  };

  deleteIPAddress = () => {
    if (this.addressToDelete) {
      this.setState({ inProgress: true, value: "" });
      this.props
        .deleteApprovedIP(this.addressToDelete)
        .then(() => {
          this.addressToDelete = null;
          this.handleAlert("confirmAlert", true);
          this.refreshList();
        })
        .catch((error) => {
          this.addressToDelete = null;
          console.log("====================================");
          console.log("error = ", error);
          console.log("====================================");
          this.setState({ inProgress: false });
        });
    }
  };

  handleCurrentData = (obj) => {
    this.setState({ currentData: obj, addNew: false });
    this.handleSidebar(true);
  };

  handlePagination = () => {};

  handleApprovedIPUpdate = (obj) => {
    if (this.state.inProgress) {
      return;
    }
    this.setState({ inProgress: true });

    if (this.state.currentData) {
      this.props
        .updateApprovedIP(obj)
        .then(() => {
          toast.success("IP Address successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.refreshList();
        })
        .catch((error) => {
          console.log("====================================");
          console.log("error = ", error);
          console.log("====================================");
        });
    } else {
      this.props
        .createApprovedIP(obj)
        .then(() => {
          toast.success("IP Address successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.refreshList();
        })
        .catch((error) => {
          console.log("====================================");
          console.log("error = ", error);
          console.log("====================================");
        });
    }

    this.handleSidebar(false, true);
  };

  render() {
    let {
      columns,
      data,
      allData,
      totalPages,
      value,
      rowsPerPage,
      currentData,
      sidebar,
      totalRecords,
      sortIndex,
      inProgress,
      addNew,
    } = this.state;

    return inProgress ? (
      <div className="listingSpinner" style={{ marginTop: 150 }}>
        <Spinner />
      </div>
    ) : (
      <div
        className={`data-list ${
          this.props.thumbView ? "thumb-view" : "list-view"
        }`}
      >
        <DataTable
          columns={columns}
          data={value.length ? allData : data}
          pagination
          paginationServer
          paginationComponent={() => (
            <ReactPaginate
              previousLabel={<ChevronLeft size={15} />}
              nextLabel={<ChevronRight size={15} />}
              breakLabel="..."
              breakClassName="break-me"
              pageCount={totalPages}
              containerClassName="vx-pagination separated-pagination pagination-end pagination-sm mb-0 mt-2"
              activeClassName="active"
              forcePage={
                this.props.parsedFilter.page
                  ? parseInt(this.props.parsedFilter.page - 1)
                  : 0
              }
              onPageChange={(page) => this.handlePagination(page)}
            />
          )}
          noHeader
          subHeader
          responsive
          pointerOnHover
          customStyles={selectedStyle}
          subHeaderComponent={
            <CustomHeader
              handleSidebar={this.handleSidebar}
              handleFilter={this.handleFilter}
              handleRowsPerPage={this.handleRowsPerPage}
              rowsPerPage={rowsPerPage}
              total={totalRecords}
              index={sortIndex}
            />
          }
          sortIcon={<ChevronDown />}
        />
        {/* TODO: finish sidebar */}
        <Sidebar
          addNew={addNew}
          updateIPAddress={this.handleApprovedIPUpdate}
          show={sidebar}
          data={currentData}
          handleSidebar={this.handleSidebar}
        />
        <div
          className={classnames("data-list-overlay", {
            show: sidebar,
          })}
          onClick={() => this.handleSidebar(false, true)}
        />

        <SweetAlert
          title="Are you sure?"
          warning
          show={this.state.defaultAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => {
            this.handleAlert("defaultAlert", false);
            this.deleteIPAddress();
          }}
          onCancel={() => {
            this.handleAlert("defaultAlert", false);
            this.handleAlert("cancelAlert", true);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>

        <SweetAlert
          title="Deleted!"
          success
          show={this.state.confirmAlert}
          confirmBtnBsStyle="success"
          onConfirm={() => {
            this.handleAlert("defaultAlert", false);
            this.handleAlert("confirmAlert", false);
          }}
        >
          <p className="sweet-alert-text">IP Address has been deleted.</p>
        </SweetAlert>

        <SweetAlert
          title="Cancelled"
          error
          show={this.state.cancelAlert}
          confirmBtnBsStyle="success"
          onConfirm={() => {
            this.handleAlert("defaultAlert", false);
            this.handleAlert("cancelAlert", false);
          }}
        >
          <p className="sweet-alert-text">IP Address data is safe :)</p>
        </SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    approvedIPs: state.approvedIPs,
  };
};

export default connect(mapStateToProps, {
  getApprovedIPs,
  filterApprovedIPs,
  createApprovedIP,
  updateApprovedIP,
  deleteApprovedIP,
})(ApprovedViewConfig);
