import React, { Component } from "react";

import "../../../assets/scss/components/fts/activity-link.scss";
import { history, historyRefresh } from "../../../history";
import Icon from "../../../components/fts/Icons";

class ActivityLink extends Component {
  state = {
    hideLink: false,
    label: "",
    action: () => {},
  };

  componentDidMount = () => {
    const {
      activity,
      globalActivityPage,
      callToAction,
      accountId,
      listingId,
      setActivityPreloadDate,
    } = this.props;

    if (activity.toDoId) {
      this.getToDoLink(activity, globalActivityPage, callToAction);
    } else if (activity.noteId) {
      this.getNoteLink(activity, globalActivityPage, callToAction);
    } else if (activity.userId) {
      this.getUserLink(
        activity,
        globalActivityPage,
        accountId,
        listingId,
        callToAction,
        setActivityPreloadDate
      );
    } else if (activity.adId) {
      this.getListingLink(
        activity,
        globalActivityPage,
        listingId,
        callToAction,
        setActivityPreloadDate
      );
    } else if (activity.promotionId) {
      this.getGlobalPromotionLink(activity, globalActivityPage);
    }
  };

  getListingLink = (activity, global, listingId) => {
    if (global) {
      this.getGlobalListingLink(activity);
    } else {
      switch (activity.activityType) {
        case "bulk_listing_update": {
          if (listingId) {
            this.setState({
              hideLink: true,
            });
          } else {
            const label = "Access Listing";
            const action = () => {
              history.push(`/listings/${activity.adId}`);
            };

            this.setState({
              label,
              action,
            });
          }
          break;
        }

        case "duplicated_listing": {
          this.setState({
            hideLink: true,
          });

          break;
        }

        case "upgradeToPaying": {
          this.setState({
            hideLink: true,
          });

          break;
        }

        case "created_listing_promotion": {
          const label = "Access Promotion";
          const action = () => {
            historyRefresh.push(
              `/listings/${activity.adId}?promotionId=${activity.promotionId}`
            );
          };

          this.setState({
            label,
            action,
          });

          break;
        }

        case "delete_report":
        case "extended_validity":
        case "shared_report": {
          this.setState({
            hideLink: true,
          });

          break;
        }

        case "visible_to_vendor":
        case "generated_listing_report": {
          const label = "See report";
          const action = () => {
            historyRefresh.push(
              `/listings/${activity.adId}?reportId=${activity.adReportId}`
            );
          };

          this.setState({
            label,
            action,
          });

          break;
        }

        case "approved_proof":
        case "rejected_proof":
        case "sent_proof":
        case "proof_revised": {
          const label = "See Proof";
          const action = () => {
            historyRefresh.push(
              `/listings/${activity.adId}?proof=${activity.proofId}`
            );
          };

          this.setState({
            label,
            action,
          });

          break;
        }

        case "changed_invoice_status": {
          const action = () => {
            history.push({
              pathname: `/account/${activity.userId}`,
              search: `?invoiceId=${activity.invoiceId}`,
            });
          };
          this.setState({
            label: "Access Invoice",
            action,
          });
          break;
        }

        default: {
          if (listingId) {
            this.setState({
              hideLink: true,
            });
          } else {
            const label = "Access Listing";
            const action = () => {
              history.push(`/listings/${activity.adId}`);
            };

            this.setState({
              label,
              action,
            });
          }
          break;
        }
      }
    }
  };

  getGlobalListingLink = (activity) => {
    switch (activity.activityType) {
      case "bulk_listing_update": {
        const label = "Access Listing";
        const action = () => {
          history.push(`/listings/${activity.adId}`);
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "duplicated_listing":
      case "upgradeToPaying": {
        const label = "Access Listing";
        const action = () => {
          history.push(`/listings/${activity.adId}`);
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "created_listing_promotion": {
        const label = "Access Promotion";
        const action = () => {
          history.push(
            `/listings/${activity.adId}?promotionId=${activity.promotionId}`
          );
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "duplicated_promotion": {
        const label = "Access Promotion";
        const action = () => {
          history.push(
            `/listings/${activity.adId}?promotionId=${activity.promotionId}`
          );
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "delete_report":
      case "extended_validity":
      case "shared_report": {
        this.setState({
          hideLink: true,
        });

        break;
      }

      case "visible_to_vendor":
      case "generated_listing_report": {
        const label = "See report";
        const action = () => {
          history.push(
            `/listings/${activity.adId}?reportId=${activity.adReportId}`
          );
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "approved_proof":
      case "rejected_proof":
      case "sent_proof":
      case "proof_revised": {
        const label = "See Proof";
        const action = () => {
          history.push(`/listings/${activity.adId}?proof=${activity.proofId}`);
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      default: {
        const label = "Access Listing";
        const action = () => {
          history.push(`/listings/${activity.adId}`);
        };

        this.setState({
          label,
          action,
        });

        break;
      }
    }
  };

  getUserLink = (
    activity,
    global,
    accountId,
    listingId,
    callToAction,
    setActivityPreloadDate
  ) => {
    if (global) {
      this.getGlobalUserLink(activity);
    } else {
      switch (activity.activityType) {
        case "created_account": {
          if (accountId) {
            this.setState({ hideLink: true });
          } else {
            const label = "Access Account";
            const action = () => {
              history.push(`/accounts/${activity.userId}`);
            };

            this.setState({
              label,
              action,
            });
          }
          break;
        }

        case "updated_account": {
          const label = "View Update";
          const action = () => {
            setActivityPreloadDate(activity.createdAt);
            callToAction("account_update_history", false, null);
          };

          this.setState({
            label,
            action,
          });

          break;
        }

        case "updated_listing": {
          if (listingId) {
            const label = "View Update";
            const action = () => {
              setActivityPreloadDate(activity.createdAt);
              callToAction("listing_update_history", false, null);
            };

            this.setState({
              label,
              action,
            });
          } else {
            const label = "View update";
            const action = () => {
              history.push({
                pathname: `/listings/${activity.adId}`,
                search: `?update-history=${activity.createdAt}`,
              });
            };

            this.setState({
              label,
              action,
            });
          }

          break;
        }

        case "created_listing": {
          if (listingId) {
            this.setState({
              hideLink: true,
            });
          } else {
            const label = "Access Listing";
            const action = () => {
              history.push(`/listings/${activity.adId}`);
            };

            this.setState({
              label,
              action,
            });
          }

          break;
        }

        case "deleted_listing": {
          this.setState({
            hideLink: true,
          });

          break;
        }

        case "bulk_listing_update": {
          if (listingId) {
            this.setState({
              hideLink: true,
            });
          } else {
            const label = "Access Listing";
            const action = () => {
              history.push(`/listings/${activity.adId}`);
            };

            this.setState({
              label,
              action,
            });
          }
          break;
        }

        case "created_alt_contact": {
          if (accountId) {
            this.setState({ hideLink: true });
          } else {
            const label = "Access Account";
            const action = () => {
              history.push(`/accounts/${activity.userId}`);
            };

            this.setState({
              label,
              action,
            });
          }
          break;
        }

        case "removed_alt_contact": {
          if (accountId) {
            this.setState({ hideLink: true });
          } else {
            const label = "Access Account";
            const action = () => {
              history.push(`/accounts/${activity.userId}`);
            };

            this.setState({
              label,
              action,
            });
          }
          break;
        }

        case "unassigned_account_user": {
          if (accountId) {
            this.setState({ hideLink: true });
          } else {
            const label = "Access Account";
            const action = () => {
              history.push(`/accounts/${activity.userId}`);
            };

            this.setState({
              label,
              action,
            });
          }
          break;
        }

        case "assigned_account_user": {
          if (accountId) {
            this.setState({ hideLink: true });
          } else {
            const label = "Access Account";
            const action = () => {
              history.push(`/accounts/${activity.userId}`);
            };

            this.setState({
              label,
              action,
            });
          }
          break;
        }

        case "convertAccount": {
          if (accountId) {
            this.setState({ hideLink: true });
          } else {
            const label = "Access Account";
            const action = () => {
              history.push(`/accounts/${activity.userId}`);
            };

            this.setState({
              label,
              action,
            });
          }
          break;
        }

        case "exclude_automatic_listing_renewals": {
          const label = "Access Listing";
          const action = () => {
            history.push(`${activity.link.split("?")[0]}`);
          };

          this.setState({
            label,
            action,
          });

          break;
        }

        case "downgradeToStandard":
        case "downgradeToBasicPlus":
        case "downgradeToBasic":
        case "downgradeToFree": {
          if (listingId) {
            this.setState({
              hideLink: true,
            });
          } else {
            const label = "Access Listing";
            const action = () => {
              history.push(`/listings/${activity.adId}`);
            };

            this.setState({
              label,
              action,
            });
          }

          break;
        }

        case "upgradeToBasic":
        case "upgradeToBasicPlus":
        case "upgradeToStandard":
        case "downgradeToPremium":
        case "upgradeToPaying": {
          if (listingId) {
            this.setState({
              hideLink: true,
            });
          } else {
            const label = "Access Listing";
            const action = () => {
              history.push(`/listings/${activity.adId}`);
            };

            this.setState({
              label,
              action,
            });
          }

          break;
        }

        case "created_account_promotion": {
          const label = "Access Promotion";
          const action = () => {
            historyRefresh.push(
              `/accounts/${activity.userId}?promotionId=${activity.promotionId}`
            );
          };

          this.setState({
            label,
            action,
          });

          break;
        }

        case "duplicated_promotion": {
          const label = "Access Promotion";
          const action = () => {
            history.push(
              `/accounts/${activity.userId}?promotionId=${activity.promotionId}`
            );
          };

          this.setState({
            label,
            action,
          });

          break;
        }

        case "delete_report":
        case "extended_validity":
        case "account_report_by_email":
        case "account_report_by_sms": {
          this.setState({
            hideLink: true,
          });

          break;
        }

        case "visible_to_vendor":
        case "generated_account_report": {
          const label = "See report";
          const action = () => {
            historyRefresh.push(
              `/accounts/${activity.userId}?reportId=${activity.accountReportId}`
            );
          };

          this.setState({
            label,
            action,
          });

          break;
        }

        case "approved_proof":
        case "rejected_proof":
        case "sent_proof":
        case "proof_revised": {
          const label = "See Proof";
          const action = () => {
            history.push(
              `/listings/${activity.adId}?proof=${activity.proofId}`
            );
          };

          this.setState({
            label,
            action,
          });

          break;
        }

        case "changed_invoice_status": {
          const action = () => {
            historyRefresh.push({
              pathname: `/accounts/${activity.userId}`,
              search: `?invoiceId=${activity.invoiceId}`,
            });
          };
          this.setState({
            label: "Access Invoice",
            action,
          });
          break;
        }

        default: {
          if (accountId) {
            this.setState({ hideLink: true });
          } else {
            const label = "Access Account";
            const action = () => {
              history.push(`/accounts/${activity.userId}`);
            };

            this.setState({
              label,
              action,
            });
          }
          break;
        }
      }
    }
  };

  getGlobalUserLink = (activity) => {
    switch (activity.activityType) {
      case "created_account": {
        const action = () => {
          history.push(`/accounts/${activity.userId}`);
        };
        this.setState({
          label: "Access Account",
          action,
        });
        break;
      }

      case "updated_account": {
        const action = () => {
          history.push({
            pathname: `/accounts/${activity.userId}`,
            search: `?update-history=${activity.createdAt}`,
          });
        };
        this.setState({
          label: "View Update",
          action,
        });
        break;
      }

      case "updated_listing": {
        const action = () => {
          history.push({
            pathname: `/listings/${activity.adId}`,
            search: `?update-history=${activity.createdAt}`,
          });
        };
        this.setState({
          label: "View Update",
          action,
        });
        break;
      }

      case "deleted_listing": {
        this.setState({
          hideLink: true,
        });

        break;
      }

      case "deleted_account": {
        this.setState({
          hideLink: true,
        });

        break;
      }

      case "created_listing": {
        const label = "Access Listing";
        const action = () => {
          history.push(`/listings/${activity.adId}`);
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "bulk_listing_update": {
        const label = "Access Listing";
        const action = () => {
          history.push(`/listings/${activity.adId}`);
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "created_alt_contact": {
        const action = () => {
          history.push(`/accounts/${activity.userId}`);
        };
        this.setState({
          label: "Access Account",
          action,
        });
        break;
      }

      case "removed_alt_contact": {
        const action = () => {
          history.push(`/accounts/${activity.userId}`);
        };
        this.setState({
          label: "Access Account",
          action,
        });
        break;
      }

      case "unassigned_account_user": {
        const action = () => {
          history.push(`/accounts/${activity.userId}`);
        };
        this.setState({
          label: "Access Account",
          action,
        });
        break;
      }

      case "exclude_automatic_listing_renewals": {
        const action = () => {
          history.push(`${activity.link.split("?")[0]}`);
        };
        this.setState({
          label: "Access Listing",
          action,
        });
        break;
      }

      case "assigned_account_user": {
        const action = () => {
          history.push(`/accounts/${activity.userId}`);
        };
        this.setState({
          label: "Access Account",
          action,
        });
        break;
      }

      case "downgradeToStandard":
      case "downgradeToBasicPlus":
      case "downgradeToBasic":
      case "downgradeToFree": {
        const label = "Access Listing";
        const action = () => {
          history.push(`/listings/${activity.adId}`);
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "upgradeToBasic":
      case "upgradeToBasicPlus":
      case "upgradeToStandard":
      case "downgradeToPremium": {
        const label = "Access Listing";
        const action = () => {
          history.push(`/listings/${activity.adId}`);
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "created_account_promotion": {
        const label = "Access Promotion";
        const action = () => {
          history.push(
            `/accounts/${activity.userId}?promotionId=${activity.promotionId}`
          );
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "duplicated_promotion": {
        const label = "Access Promotion";
        const action = () => {
          history.push(
            `/accounts/${activity.userId}?promotionId=${activity.promotionId}`
          );
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "delete_report":
      case "extended_validity":
      case "account_report_by_email":
      case "account_report_by_sms": {
        this.setState({
          hideLink: true,
        });

        break;
      }

      case "visible_to_vendor":
      case "generated_account_report": {
        const label = "See report";
        const action = () => {
          history.push(
            `/accounts/${activity.userId}?reportId=${activity.accountReportId}`
          );
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "approved_proof":
      case "rejected_proof":
      case "sent_proof":
      case "proof_revised": {
        const label = "See Proof";
        const action = () => {
          historyRefresh.push(
            `/listings/${activity.adId}?proof=${activity.proofId}`
          );
        };

        this.setState({
          label,
          action,
        });

        break;
      }

      case "changed_invoice_status": {
        const action = () => {
          history.push({
            pathname: `/accounts/${activity.userId}`,
            search: `?invoiceId=${activity.invoiceId}`,
          });
        };
        this.setState({
          label: "Access Invoice",
          action,
        });
        break;
      }

      default: {
        const action = () => {
          history.push(`/accounts/${activity.userId}`);
        };
        this.setState({
          label: "Access Account",
          action,
        });
        break;
      }
    }
  };

  getToDoLink = (activity, global, callToAction) => {
    if (global) {
      this.getGlobalToDoLink(activity);
    } else {
      const action = () => {
        callToAction("todo", true, { id: activity.toDoId });
      };

      this.setState({
        label: "Access Todo",
        action,
      });
    }
  };

  isPersonal = (todo) => {
    return todo.todoType === "personal";
  };

  getGlobalToDoLink = (activity) => {
    let action;

    if (this.isPersonal(activity.todo)) {
      action = () => {
        history.push(`/tasks-management?toDoId=${activity.todo.id}`);
      };
    }

    if (activity.adId) {
      action = () => {
        history.push(`/listings/${activity.adId}?toDoId=${activity.toDoId}`);
      };
    }

    if (activity.userId) {
      action = () => {
        history.push(`/accounts/${activity.userId}?toDoId=${activity.toDoId}`);
      };
    }

    if (!activity.adId && !activity.userId) {
      action = () => {
        history.push(`/tasks-management?toDoId=${activity.todo.id}`);
      };
    }

    this.setState({
      label: "Access Todo",
      action,
    });
  };

  getNoteLink = (activity, global, callToAction) => {
    if (global) {
      this.getGlobalNoteLink(activity);
    } else {
      const action = () => {
        callToAction("note", true, { id: activity.noteId });
      };

      this.setState({
        label: "Access Note",
        action,
      });
    }
  };

  getGlobalNoteLink = (activity) => {
    let action;

    if (activity.adId) {
      action = () => {
        history.push(`/listings/${activity.adId}?noteId=${activity.noteId}`);
      };
    }

    if (activity.userId) {
      action = () => {
        history.push(`/accounts/${activity.userId}?noteId=${activity.noteId}`);
      };
    }

    this.setState({
      label: "Access Note",
      action,
    });
  };

  getGlobalPromotionLink = () => {
    this.setState({
      hideLink: true,
    });
  };

  render() {
    const { hideLink, action, label } = this.state;
    return (
      <div>
        {!hideLink && (
          <div
            className="activity-link-wrapper"
            onClick={() => {
              action();
            }}
          >
            <div>{label}</div>
            <div>
              <Icon name="ArrowLink" color="#9CA6A9" size={25} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ActivityLink;
