import React, { Component } from "react";

import "../../../assets/scss/components/fts/todo-badge.scss";

class TodoBadge extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: this.props.color,
          borderColor: this.props.color,
        }}
        className={"todo-badge"}
      >
        {this.props.children}
      </div>
    );
  }
}

export default TodoBadge;
