import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import "../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss";
import "../../../assets/scss/components/fts/form-fields.scss";
import "../../../assets/scss/pages/invoices-page.scss";
import "flatpickr/dist/themes/light.css";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import paymentTypes from "../../../data/payment-types";
import Icon from "../../../components/fts/Icons";
import { history } from "../../../history";
import Flatpickr from "react-flatpickr";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Popover,
  PopoverBody,
  Row,
  Spinner,
} from "reactstrap";

import {
  resetDiscount,
  searchInvoices,
  setAllInvoicesCount,
  setAppliedCreditAmount,
  setCreditCardData,
  setInvoicesListTest,
  setIsCreditApplied,
  setPaymentsListTest,
  setSelectedInvoices,
} from "../../../redux/actions/invoices";
import {
  forceCreatePayment,
  payWithStripeAdmin,
  payWithStripeSuccess,
} from "../../../redux/actions/payments";
import { setPromoCode } from "../../../redux/actions/promocodes";

import { setAccountSettingsState } from "../../../redux/actions/accounts";

import { defineInvoicesColumns } from "./invoices-columns";
import moment from "moment";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";

import PaymentMethodModal from "./payment-modals/payment-method";
import ManuallyPaymentModal from "./payment-modals/log-payment-manually";
import ConfirmPaymentModal from "./payment-modals/confirm-received-payment";
import PaymentProccessedModal from "./payment-modals/payment-proccessed";
import PaymentErrorModal from "./payment-modals/payment-error-modal";
import ConfirmationModal from "../../../components/fts/confirmation-modal";
import { activities } from "../../../constants";

import { createActivity } from "../../../redux/actions/activities";
import { addUserActivity } from "../../../redux/actions/user-activities";
import { setUpgradesUnreadCount } from "../../../redux/actions/upgrades";
import queryString from "query-string";
import { toast } from "react-toastify";
import AccountInvoices from "../account-settings/AccountInvoices";
import {
  searchAccountInvoices,
  setAccountInvoicesPreselectedInvoice,
} from "../../../redux/actions/accountInvoices";
import ListingInvoices from "../account-settings/ListingInvoices";
import { searchListingInvoices } from "../../../redux/actions/listingInvoices";
import { mergeArrayWithoutDuplicates } from "../../../utility/mergeArraysWithoutDuplicates";

// import Icons from "../../../components/fts/Icons";

// import TitleRow from "../../../../TitleRow";

let searchFields = [
  "statusForString",
  "owner.assignedAdmins.firstName",
  "owner.assignedAdmins.lastName",
  "invoiceNumber",
  "totalForSort",
  "dueAtString",
  "paidAtString",
  "payments.paidMethod",
  "payments.paidSource",
  "owner.companyName",
  "ownerIdForSort",
  "owner.city",
  "owner.state",
  "listingIdForSort",
  "activities.activityNote",
];
let defaultSort = [{ colId: "dueAt", sort: "ASC" }];
class Invoices extends Component {
  state = {
    activeTab: "0-30",
    from: 0,
    to: 30,
    firstTabCount: 0,
    secondTabCount: 0,
    thirdTabCount: 0,
    inProgress: false,
    filterChips: [
      {
        chipLabel: "All",
        color: "#32383A",
        chipNumber: 0,
        active: true,
        onClickChip: this.onClickAll.bind(this),
      },
      {
        chipLabel: "Paid",
        color: "#57C990",
        chipNumber: 0,
        active: false,
        onClickChip: this.onClickChip.bind(this),
      },
      {
        chipLabel: "Overdue",
        color: "#F36565",
        chipNumber: 0,
        active: false,
        onClickChip: this.onClickChip.bind(this),
      },
      {
        chipLabel: "Pending",
        color: "#F99930",
        chipNumber: 0,
        active: false,
        onClickChip: this.onClickChip.bind(this),
      },
      {
        chipLabel: "Unsuccessful",
        color: "#647074",
        chipNumber: 0,
        active: false,
        onClickChip: this.onClickChip.bind(this),
      },
      {
        chipLabel: "Voided",
        color: "#016D93",
        chipNumber: 0,
        active: false,
        onClickChip: this.onClickChip.bind(this),
      },
    ],
    chipLoading: false,
    filter: [],

    defaultColDef: {
      sortable: true,
    },
    overlayShown: false,

    searchVal: "",
    popoverTarget: "",
    popoverData: null,
    dateRange: null,
    apisReady: false,
    popoverOpen: false,
    excludeInactive: false,
    currentRow: null,
    isOpen: false,
    sidebar: false,
    action: "",
    actionItem: null,
    updating: false,

    pageSize: 10,
    page: 1,
    pageCount: 0,
    totalRecordsInvoices: 0,
    sort: defaultSort,
    columnDefs: [],
    totalInvoiceAmount: 0,
    statusFilter: [],
    searchFields: [...searchFields],

    paymentStep: "",
    lastPaymentModalTitle: "",
    isOpenPaymentModal: false,
    paymentDate: null,
  };

  pageSizes = [10, 25, 50, 100];
  tableGroupRef = createRef();
  dateRef = createRef();
  invoicesRef = createRef();
  secondaryRef = createRef();
  formikSidebarRef = createRef();
  detailsRef = createRef();

  setDetailsRef = (ref) => {
    this.detailsRef = ref;
  };

  logFailedPaymentActivity = (payload) => {
    let invoices = payload.invoices.map((invoice) => invoice.id).toString();

    let activityPayload = {
      userId: this.props.accountData && this.props.accountData?.id,
      adId: this.props.listingData && this.props?.listingData?.id,
      status: 1,
      activityType: this.props.activitiesTypes.PAYMENT_UNSUCCESSFUL,
      activity: activities.paymentUnsuccesful
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser?.firstName} ${this.props.userData.loggedInUser?.lastName}`,
        )
        .replace("{{invoiceId}}", invoices)
        .replace("{{amount}}", payload.amount),
      adminUserId: this.props.userData.loggedInUser?.id,
      iconName: "Listings",
    };

    return this.props.createActivity(activityPayload);
  };

  logSuccessfulPayment = async (data) => {
    let paymentAmount = data.amount.toString();
    let activityPayload = {
      status: 1,
      userId: this.props.accountData && this.props.accountData?.id,
      adId: this.props.listingData && this.props?.listingData?.id,
      activityType: this.props.activitiesTypes.COLLECTED_PAYMENT,
      activity: activities.collectPayment
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser?.firstName} ${this.props.userData.loggedInUser?.lastName}`,
        )
        .replace("{{sourceId}}", data.id)
        .replace("{{amount}}", paymentAmount),
      adminUserId: this.props.userData.loggedInUser?.id,
      iconName: "Payment",
    };

    const selectedInvoices = this.props.selectedInvoices.filter(
      (invoice) => invoice.isSelected,
    );

    this.props.setAllInvoicesCount({
      allInvoicesCount: this.props.invoicesCount + selectedInvoices?.length,
    });

    const activity = await this.props.createActivity(activityPayload);
    return this.props.addUserActivity(activity.data);
  };

  componentDidMount() {
    this.invoicesRef = this;
    this.secondaryRef = this;
    const { status, hash, paymentId } = queryString.parse(location.search);
    if (status === "success" && hash) {
      this.setState({ inProgress: true });
      this.props
        .payWithStripeSuccess({ paymentId, token: hash })
        .then((data) => {
          //TODO check if this is needed?
          const requestedUpdate = this.listingData?.update?.requestedUpdate;
          const requestUpdateParsed =
            requestedUpdate && JSON.parse(requestedUpdate);
          if (
            requestUpdateParsed &&
            requestUpdateParsed.priceId < this.listingData?.priceId
          ) {
            this.props.setUpgradesUnreadCount(
              this.props.upgradesUnReadCount + 1,
            );
          }
          if (
            this.props.listingDataRef?.getListingInfo &&
            this.props.listingData?.id
          ) {
            this.props.listingDataRef.getListingInfo(this.props.listingData.id);
          }
          //****

          const invoices = data?.data.invoices.map((invoice) => ({
            ...invoice,
            isSelected: true,
          }));

          this.props.setSelectedInvoices({ selectedInvoices: invoices });
          this.setPaymentStep("paymentProccessed", paymentId, moment());
          this.props.setAppliedCreditAmount(0);
          this.props.setIsCreditApplied(false);
          this.setLastPaymentModalTitle("Payment successfully processed");
          this.logSuccessfulPayment({ ...data?.data, id: paymentId });

          this.gridApi &&
            this.gridApi.refreshTable &&
            this.gridApi.refreshTable();
          this.props.setPromoCode(null);
          this.props.resetDiscount();
          this.props.setCreditCardData(null);
        })
        .catch(({ response }) => {
          this.logFailedPaymentActivity(this.getPayWithStripePayload());
          const errorMsg = response?.data?.message || response?.data?.error;
          toast.error(errorMsg || response?.statusText, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {
          this.setState({ inProgress: false });
          this.clearStripeUrlStatus();
        });
    }
    if (status === "error") {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.clearStripeUrlStatus();
    }
    if (this.props.invoicesRef && typeof this.props.invoicesRef === "function")
      this.props.invoicesRef(this);

    if (
      this.props.secondaryRef &&
      typeof this.props.secondaryRef === "function"
    )
      this.props.secondaryRef(this);

    const { pageSize, page, filter, sort, statusFilter, filterChips } =
      this.state;
    this.setState({ inProgress: true });
    if (this.props.accountId) {
      filter.push({
        term: { userId: this.props.accountId },
      });
    } else if (this.props.listingId) {
      filter.push({
        term: { adId: this.props.listingId },
      });
    } else {
      filter.push({
        range: {
          dueAt: {
            lte:
              moment().subtract(-1, "days").toISOString().split("T")[0] +
              "T00:00:00Z",
            gte:
              moment().subtract(30, "days").toISOString().split("T")[0] +
              "T00:00:00Z",
          },
        },
      });
    }

    let searchPayload = {
      page: page,
      resultsPerPage: pageSize,
      searchString: this.state.searchVal,
      filter: this.state.filter,
      sort: sort,
      statusFilter: statusFilter,
    };
    if (this.props.accountId || this.props.listingId) {
      searchPayload.page = 1;
      searchPayload.resultsPerPage = 1000000;
    }

    this.props
      .searchInvoices(searchPayload)
      .then((invoices) => {
        let newFilterChips = filterChips.map((e) => ({
          ...e,
          chipNumber:
            invoices.data.result[
              `${e.chipLabel.toLowerCase().replace(" ", "")}Count`
            ],
        }));
        newFilterChips[0].chipNumber = invoices?.data?.result?.nbHits;

        this.props.setInvoicesListTest({
          rows: invoices?.data?.result?.hits,
          count: invoices?.data?.result?.nbHits,
        });

        this.props.setPaymentsListTest({
          rows: invoices?.data?.result?.hits.filter(
            (e) => e.statusForSort === "Paid",
          ),
          count: invoices?.data?.result?.hits.filter(
            (e) => e.statusForSort === "Paid",
          ).length,
          allPaymentsCount: invoices?.data?.result?.allPaymentsCount,
        });

        if (this.props.invoiceToOpen && !hash) {
          let preselectedInvoice = this.props.invoicesData.find(
            (invoice) => invoice.id === this.props.invoiceToOpen,
          );
          this.preselectInvoice(preselectedInvoice);
        }

        this.props.setAllInvoicesCount({
          allInvoicesCount: invoices?.data?.result?.nbHits,
        });

        this.setState({
          inProgress: false,
          pageCount:
            this.props.accountId || this.props.listingId
              ? 1
              : Math.ceil(invoices?.data?.result?.nbHits / pageSize),
          pageSize:
            this.props.accountId || this.props.listingId
              ? 1000000
              : this.state.pageSize,
          totalRecordsInvoices: invoices?.data?.result?.nbHits,
          totalInvoiceAmount: invoices.data?.result?.totalInvoiceAmount || 0,
          firstTabCount: invoices.data?.result?.from0to30Count || 0,
          secondTabCount: invoices.data?.result?.from30to90Count || 0,
          thirdTabCount: invoices.data?.result?.from90to365Count || 0,
          filterChips: newFilterChips,
          columnDefs: defineInvoicesColumns(
            this.collectDataForCopy,
            this.props.accountId,
            this.props.listingId,
            this.refreshTable,
            this.props.invoiceToOpen,
            this.togglePopOver,
          ),
        });
      })
      .catch((err) => {
        console.log("ERROR:", err);
        this.setState({ inProgress: false });
      });
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  unselectAllInvoices = () => {
    this.props.setSelectedInvoices({ selectedInvoices: [] });
    this.gridApi.deselectAll();
  };

  preselectInvoice = (preselectedInvoice) => {
    this.openInvoiceSidebar(preselectedInvoice);
  };

  clearStripeUrlStatus = () => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.delete("status");
    currentParams.delete("hash");
    currentParams.delete("paymentId");
    currentParams.delete("gateway");
    history.push({ search: currentParams.toString() });
  };

  preselectNewInvoice = (preselectedInvoice) => {
    this.callToAction("payment_overview", false, preselectedInvoice);
  };

  getSelectedInvoices = () => {
    const { selectedInvoices } = this.props;

    let selectedRows = this.gridApi?.getSelectedNodes()?.map((gridNode) => {
      return { ...gridNode.data, isSelected: true };
    });

    const selectedRowsMap = new Map(selectedRows?.map((row) => [row.id, row]));
    const currentRowData = this.getRowData();

    // This is needed because we need to keep invoices from multiple tabls and multiple pages
    const updatedSelectedInvoices = selectedInvoices?.filter((invoice) => {
      const isInvoiceInTable = currentRowData?.some(
        (row) => row.id === invoice.id,
      );
      const isInvoiceSelected = selectedRowsMap?.has(invoice.id);
      return !isInvoiceInTable || isInvoiceSelected;
    });

    const mergedInvoices = mergeArrayWithoutDuplicates({
      firstArray: updatedSelectedInvoices,
      secondArray: selectedRows,
    });

    this.props.setSelectedInvoices({ selectedInvoices: mergedInvoices });
  };

  onClickChip(selectedFilter) {
    if (this.state.chipLoading) return;
    let selectedChip = {
      ...selectedFilter,
      active: !selectedFilter.active,
    };

    this.setState({
      page: 1,
    });

    let newChips = this.state.filterChips.map((chip) =>
      chip.chipLabel !== selectedChip.chipLabel ? chip : selectedChip,
    );

    if (selectedFilter.chipLabel) newChips[0].active = false;

    let statusFilter = [{ bool: { should: [] } }];
    for (let chip of newChips) {
      if (chip.active) {
        if (chip.chipLabel === "Paid") {
          statusFilter[0].bool.should.push({
            term: { statusForSort: "Paid" },
          });
        }
        if (chip.chipLabel === "Unsuccessful") {
          statusFilter[0].bool.should.push({
            term: { statusForSort: "Unsuccessful" },
          });
        }

        if (chip.chipLabel === "Voided") {
          statusFilter[0].bool.should.push({
            term: { statusForSort: "Voided" },
          });
        }
        if (chip.chipLabel === "Pending") {
          statusFilter[0].bool.should.push({
            term: { statusForSort: "Pending" },
          });
        }

        if (chip.chipLabel === "Overdue") {
          statusFilter[0].bool.should.push({
            term: { statusForSort: "Overdue" },
          });
        }
      }
    }

    if (
      statusFilter[0].bool.should.length === 5 ||
      statusFilter[0].bool.should.length === 0
    ) {
      return this.onClickAll();
    }

    this.setState(
      {
        filterChips: newChips,
        statusFilter,
        chipLoading: true,
        page: 1,
      },
      () => {
        this.onSubmitQuery();
      },
    );
  }

  onClickAll() {
    if (this.state.chipLoading) return;
    let filterChips = this.state.filterChips
      .filter((chip) => chip.chipLabel !== "All")
      .map((chip) => {
        return { ...chip, active: false };
      });

    this.setState(
      {
        filterChips: [
          { ...this.state.filterChips[0], active: true },
          ...filterChips,
        ],
        statusFilter: [],
        chipLoading: true,
        page: 1,
      },
      () => this.onSubmitQuery(),
    );
  }

  filterSize = (type, val) => {
    this.setState(
      {
        pageSize: val,
        page: 1,
      },
      () => {
        this.onSubmitQuery();
      },
    );
  };

  componentWillUnmount() {
    this.props.setSelectedInvoices({
      selectedInvoices: [],
    });
  }

  onCancelSelection() {
    this.props.setSelectedInvoices({
      selectedInvoices: [],
    });

    this.gridApi.deselectAll();
  }

  updateSearchQuery = (type, val) => {
    clearTimeout(this.debounce);
    let newSearchFields = [...searchFields];
    if (this.props.accountId || this.props.listingId) {
      newSearchFields = newSearchFields.filter(
        (e) => e !== "owner.companyName",
      );
    }
    this.setState(
      {
        searchVal: val,
        page: 1,
        // statusFilter: [],
        searchFields: newSearchFields,
      },
      () => {
        this.debounce = setTimeout(() => this.onSubmitQuery(), 350);
      },
    );
  };

  formatNumber = (number, decimal = 2) => {
    if (!decimal) return new Intl.NumberFormat().format(number);
    return Number(number.toFixed(decimal))
      .toFixed(decimal)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  clearFlatPickr = () => {
    this.dateRef.current.flatpickr.clear();
    this.updateActiveTab("0-30");
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (localStorage.getItem("invoicesColumnState") !== null) {
      this.gridColumnApi.setColumnState(
        JSON.parse(localStorage.getItem("invoicesColumnState")),
      );
    }
  };

  resetApi = () => {
    this.gridApi = null;
  };

  // onRowSelected = (event) => {
  //   console.log(event, "EVENT");
  // };

  refreshTable = () => {
    this.clearAccountCredit();
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    this.setState({ overlayShown: true });
    setTimeout(() => {
      if (this.props.accountId) return this.refreshAccountInvoicesTable();
      if (this.props.listingId) return this.refreshListingInvoicesTable();
      this.onSubmitQuery();
    }, 1000);
  };

  refreshAccountInvoicesTable = () => {
    const { searchAccountInvoices, accountInvoices, accountId } = this.props;

    const {
      currentPage,
      resultsPerPage,
      searchString,
      activeSubTab,
      sortModel,
    } = accountInvoices;

    let payload = {
      page: currentPage,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { userId: accountId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    searchAccountInvoices(payload).then(() => {
      this.gridApi.hideOverlay();
      this.setState({
        overlayShown: false,
      });
    });
  };

  refreshListingInvoicesTable = () => {
    const { searchListingInvoices, listingInvoices, listingId } = this.props;

    const {
      currentPage,
      resultsPerPage,
      searchString,
      activeSubTab,
      sortModel,
    } = listingInvoices;

    let payload = {
      page: currentPage,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { adId: listingId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    searchListingInvoices(payload)
      .then(() => {
        console.log("DOSLOOO");
        this.gridApi.hideOverlay();
        this.setState({
          overlayShown: false,
        });
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  onSubmitQuery = () => {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    this.setState({ overlayShown: true });

    let searchPayload = {
      page: this.state.page,
      resultsPerPage: this.state.pageSize,
      searchString: this.state.searchVal,
      filter: this.state.filter,
      sort: this.state.sort,
      statusFilter: this.state.statusFilter,
      searchFields: this.state.searchFields,
    };
    if (this.props.accountId || this.props.listingId) {
      searchPayload.page = 1;
      searchPayload.resultsPerPage = 100000;
    }

    this.props.searchInvoices(searchPayload).then((invoices) => {
      let newFilterChips = this.state.filterChips.map((e) => ({
        ...e,
        chipNumber:
          invoices.data.result[
            `${e.chipLabel.toLowerCase().replace(" ", "")}Count`
          ],
      }));

      newFilterChips[0].chipNumber =
        invoices.data?.result?.pendingCount +
        invoices.data?.result?.overdueCount +
        invoices.data?.result?.paidCount +
        invoices.data?.result?.unsuccessfulCount +
        invoices.data?.result?.voidedCount;

      this.props.setInvoicesListTest({
        rows: invoices?.data?.result?.hits,
        count: invoices?.data?.result?.nbHits,
        totalInvoiceAmount: invoices.data?.result?.totalInvoiceAmount || 0,
        firstTabCount: invoices.data?.result?.from0to30Count || 0,
        secondTabCount: invoices.data?.result?.from30to90Count || 0,
        thirdTabCount: invoices.data?.result?.from90to365Count || 0,
        pendingCount: invoices.data?.result?.pendingCount || 0,
        overdueCount: invoices.data?.result?.overdueCount || 0,
        paidCount: invoices.data?.result?.paidCount || 0,
        unsuccessfulCount: invoices.data?.result?.unsuccessfulCount || 0,
        voidedCount: invoices.data?.result?.voidedCount || 0,
      });

      this.props.setPaymentsListTest({
        rows: invoices?.data?.result?.hits.filter(
          (e) => e.statusForSort === "Paid",
        ),
        count: invoices?.data?.result?.hits.filter(
          (e) => e.statusForSort === "Paid",
        ).length,
        allPaymentsCount: invoices?.data?.result?.allPaymentsCount,
      });

      this.setState({
        filterChips: newFilterChips,
        chipLoading: false,
        overlayShown: false,
        totalInvoiceAmount: invoices.data?.result?.totalInvoiceAmount || 0,

        pageCount:
          this.props.accountId || this.props.listingId
            ? 1
            : Math.ceil(invoices?.data?.result?.nbHits / this.state.pageSize),

        pageSize:
          this.props.accountId || this.props.listingId
            ? 1000000
            : this.state.pageSize,

        // pageCount: Math.ceil(
        //   invoices?.data?.result?.nbHits / this.state.pageSize
        // ),
        totalRecordsInvoices: invoices?.data?.result?.nbHits,
      });
      if (this.gridApi) this.gridApi.hideOverlay();
      this.props.setAllInvoicesCount({
        allInvoicesCount: invoices?.data?.result?.nbHits,
      });
    });
  };

  saveInvoicesColumns = () => {
    let invoicesColumnState = this.gridColumnApi.getColumnState();
    localStorage.setItem(
      "invoicesColumnState",
      JSON.stringify(invoicesColumnState),
    );
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      this.onSubmitQuery();
    });
  };

  openPaymentSidebar = (data) => {
    this.callToAction("payment_overview", false, data);
  };

  openReceiptSidebar = (data) => {
    this.callToAction("receipt_details", false, data);
  };

  openInvoiceSidebar = (data) => {
    this.callToAction("invoice_details", false, data);
  };

  scrollToSelectedInvoice = (invoiceId) => {
    if (!this.props.invoicesData || this.props.accountId) return;
    let selectedIndex = this.props.invoicesData.findIndex(
      (invoice) => invoice.id === invoiceId,
    );

    this.gridApi.ensureIndexVisible(selectedIndex, "middle");
  };

  callToAction = (action, update, item) => {
    if (!action) return;
    this.setState({
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
    });
  };

  onSidebarClosed = () => {
    this.clearAccountCredit();
  };

  handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (
      this.detailsRef?.state?.downloadingInProgress ||
      this.detailsRef?.state?.downloadingRecipientInProgress ||
      this.detailsRef?.state?.templateLoading
    ) {
      return;
    }
    if (this.state.action === "invoice_details") {
      this.unselectAllInvoices();
      this.props.setSelectedInvoices({
        selectedInvoices: [],
      });
    }
    if (bool === false && this.formikSidebarRef?.dirty && !closeWithoutPrompt) {
      return this.setState({ isOpen: true });
    } else {
      this.setState({ sidebar: bool, updating: !!update });
    }
  };

  closeSideOutAction = () => {
    this.setState({ sidebar: false, update: false, isOpen: false });
  };

  setPaymentStep = (paymentStep, sourceId, paymentDate) => {
    this.setState({ paymentStep, sourceId, paymentDate });
    if (paymentStep === "creditCard") this.openPayWithStripe();
  };

  setLastPaymentModalTitle = (title) => {
    this.setState({
      lastPaymentModalTitle: title,
    });
  };

  toggleReassignListingModal = () => {
    if (!this.state.isOpenReassignListingModal) {
      this.setState({
        page: 1,
        totalRecords: 0,
        pageCount: 0,
        accounts: [],
        accountSearchValue: "",
        mainAccount: null,
      });
    }
    this.setState(
      {
        isOpenReassignListingModal: !this.state.isOpenReassignListingModal,
      },
      () => {
        this.updateAccountSearchResults();
      },
    );
  };

  // toggleReassignConfirmationModal = () => {
  //   this.setState({
  //     isOpenReassignConfirmationModal:
  //       !this.state.isOpenReassignConfirmationModal,
  //   });
  // };

  togglePaymentModal = (check) => {
    this.props.setCreditCardData(null);
    if (!check) {
      this.setState({
        isOpenPaymentModal: !this.state.isOpenPaymentModal,
      });
    } else {
      this.confirmPaymentConfirmationModal();

      if (this.state.paymentStep === "paymentProccessed") {
        this.props.setSelectedInvoices({
          selectedInvoices: [],
        });
        this.unselectAllInvoices();
      }
    }
  };

  confirmPaymentConfirmationModal = () => {
    this.refreshTable();

    this.setState({
      paymentStep: null,
      isOpenPaymentModal: false,
    });
    this.props.setPromoCode(null);
    this.props.resetDiscount();
  };

  setFormikSidebarRef = (ref) => {
    this.formikSidebarRef = ref;
  };

  resetFormikForm = () => {
    this.formikSidebarRef.resetForm();
  };

  onConfirmAchPayment = () => {
    let { amount, number, paymentType } = this.state.manuallyFormValues;
    this.setState({
      paymentStep: "paymentProccessed",
      lastPaymentModalTitle: "Payment logged",
      sourceId: number,
    });

    let paidMethod = paymentTypes.find((e) => e.value === paymentType);

    const selectedInvoices = this.props.selectedInvoices.filter(
      (invoice) => invoice.isSelected,
    );

    this.props
      .forceCreatePayment({
        invoices: selectedInvoices,
        status: "succeeded",
        total: amount,
        sourceId: number,
        paidSource:
          paymentType === 3 ? paidMethod.label.toLowerCase() : "manually",
        paidMethod: paymentType === 3 ? "card" : paidMethod.label.toLowerCase(),
        promoCode: this.props.promoCode?.id ? this.props.promoCode.id : null,
        credit:
          this.props.appliedCreditAmount < 0
            ? 0
            : this.props.appliedCreditAmount,
      })
      .then(({ data }) => {
        this.props.setAppliedCreditAmount(0);
        this.props.setIsCreditApplied(false);
        const requestedUpdate = this.props.listingData?.update?.requestedUpdate;

        const requestUpdateParsed =
          requestedUpdate && JSON.parse(requestedUpdate);

        if (
          requestUpdateParsed &&
          requestUpdateParsed.priceId < this.props.listingData?.priceId
        ) {
          this.props.setUpgradesUnreadCount(this.props.upgradesUnReadCount + 1);
        }
        if (
          this.props.listingDataRef?.getListingInfo &&
          this.props.listingData?.id
        ) {
          this.props.listingDataRef.getListingInfo(this.props.listingData.id);
        }

        let activityPayload = {
          status: 1,
          userId: this.props.accountData && this.props.accountData?.id,
          adId: this.props.listingData && this.props?.listingData?.id,
          activityType: this.props.activitiesTypes.COLLECTED_PAYMENT,
          activity: activities.collectPayment
            .replace(
              "{{admin_name}}",
              `${this.props.userData.loggedInUser?.firstName} ${this.props.userData.loggedInUser?.lastName}`,
            )
            .replace("{{sourceId}}", number)
            .replace("{{amount}}", amount),
          adminUserId: this.props.userData.loggedInUser?.id,
          iconName: "Payment",
        };

        this.props.createActivity(activityPayload).then(({ data }) => {
          this.props.addUserActivity(data);
        });

        this.props.setAllInvoicesCount({
          allInvoicesCount: this.props.invoicesCount + data.length,
        });

        return this.props.setPromoCode(null);
      })
      .catch((e) => {
        console.log("ERROR: ", e);
      });
  };

  handleDateChange = (values) => {
    if (values.length < 2) return;

    let gte = new Date(values[0]);
    let lte = new Date(values[1]);
    lte.setDate(lte.getDate() + 1);
    //2020-08-27
    let generateFate = (date) => {
      let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      let month =
        date.getMonth() <= 8 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

      return `${date.getFullYear()}-${month}-${day}T00:00:00Z`;
    };

    let newGte = generateFate(gte);
    let newLte = generateFate(lte);

    let filter = [
      {
        range: {
          dueAt: {
            gte: newGte,
            lte: newLte,
          },
        },
      },
    ];
    this.setState(
      {
        filter,
      },
      () => this.onSubmitQuery(),
    );
  };

  updateActiveTab = (item) => {
    if (this.dateRef?.current) this.dateRef.current.flatpickr.setDate([]);

    let values = [];
    values[1] = moment().subtract(item.split("-")[0], "days"); //gte
    values[0] = moment().subtract(item.split("-")[1], "days"); //lte

    this.setState({ activeTab: item, page: 1 }, () => {
      this.handleDateChange(values);
    });
  };

  onRowClicked = ({ data, api, node }) => {
    if (this.state.overlayShown) return;
    this.unselectAllInvoices();
    node.setSelected(true);
    // return;
    let isAccount = window.location.href
      .split("/")
      .find((e) => e === "accounts");

    let isListing = window.location.href
      .split("/")
      .find((e) => e === "listings");

    if (
      api.focusedCellController.focusedCellPosition.column.colId ===
        "payments.paidSourceForSort" ||
      api.focusedCellController.focusedCellPosition.column.colId ===
        "payments.paidMethodForSort"
    ) {
      return;
    }
    if (isAccount || isListing) {
      if (data.statusForString !== "Paid") {
        this.props.setSelectedInvoices({
          selectedInvoices: [
            {
              ...data,
              isSelected: true,
            },
          ],
        });
      }
      this.openInvoiceSidebar(data);
      return;
    }

    this.props.setAccountSettingsState({
      accountId: "" + data.userId,
      activeTab: 2,
    });

    this.props.setAccountInvoicesPreselectedInvoice({
      invoiceNumberForSort: data?.invoiceNumberForSort,
    });

    this.props.history.push({
      pathname: `/accounts/${data.userId}`,
      search: `?invoiceId=${data.id}`,
    });
  };

  onSortChanged = (params) => {
    this.setState({ sort: params.api.getSortModel() });
    return this.onSubmitQuery();
  };

  togglePopOver = (popOverName, popoverData) => {
    if (popOverName) {
      return this.setState({
        popoverTarget: popOverName,
        popoverOpen: true,
        popoverData,
      });
    }
    return this.setState({
      popoverTarget: "",
      popoverOpen: false,
      popoverData: null,
    });
  };

  mapInvoice = (invoice) => {
    const {
      id,
      amount,
      promoCode,
      adId,
      userId,
      invoiceAmount,
      dueAt,
      type,
      invoiceNumber,
      creditUsed,
    } = invoice;
    return {
      invoiceNumber,
      id,
      amount,
      type,
      promoCode: promoCode && { id: promoCode.id },
      adId,
      userId,
      invoiceAmount,
      dueAt,
      creditUsed,
      "listing.id": invoice["listing.id"],
      "listing.city": invoice["listing.city"],
      "listing.state": invoice["listing.state"],
      "listing.priceId": invoice["listing.priceId"],
      "listing.update.requestedUpdate":
        invoice["listing.update.requestedUpdate"],
    };
  };

  getPayWithStripePayload = () => {
    let { selectedInvoices, totalAmount } = this.props;
    selectedInvoices = selectedInvoices.filter((invoice) => invoice.isSelected);
    const credit =
      this.props.appliedCreditAmount < 0 ? 0 : this.props.appliedCreditAmount;
    if (!selectedInvoices.length) return;
    return {
      subscription: false,
      companyName: selectedInvoices[0]["owner.companyName"],
      ownerFirstName: selectedInvoices[0]["owner.firstName"],
      ownerLastName: selectedInvoices[0]["owner.lastName"],
      city: selectedInvoices[0]["owner.city"],
      state: selectedInvoices[0]["owner.state"],
      newAccount: selectedInvoices[0]["owner.newAccount"],
      source: "card",
      redirectUri: window.location.href,
      amount: totalAmount,
      credit,
      invoices: selectedInvoices.map(this.mapInvoice),
      promoCodeId: this.props.promoCode?.id ? this.props.promoCode.id : null,
    };
  };

  openPayWithStripe = () => {
    this.setState({ inProgress: true });
    this.props
      .payWithStripeAdmin(this.getPayWithStripePayload())
      .then((res) => {
        const url = res.data?.url;
        window.open(url, "_self");
      })
      .catch(async (e) => {
        toast.error(e.response?.data?.message || e.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  clearAccountCredit = () => {
    this.props.setAppliedCreditAmount(0);
    this.props.setIsCreditApplied(false);
  };

  getRowData = () => {
    if (!this.gridApi) return;

    const rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  render() {
    const {
      activeTab,
      firstTabCount,
      secondTabCount,
      thirdTabCount,
      pageSize,
      page,
      pageCount,
      columnDefs,
      searchVal,
      defaultColDef,
      totalRecordsInvoices,
      filterChips,
      totalInvoiceAmount,
      inProgress,
      sidebar,
      action,
      updating,
      actionItem,
      paymentStep,
      isOpenPaymentModal,
      sourceId,
      paymentDate,
    } = this.state;

    const { accountId, listingId } = this.props;

    let tabs = [
      {
        label: `Last 30 days (${this.formatNumber(firstTabCount, 0)})`,
        type: "0-30",
      },
      {
        label: `30 - 90 days (${this.formatNumber(secondTabCount, 0)})`,
        type: "30-90",
      },
      {
        label: `90 - 365 days (${this.formatNumber(thirdTabCount, 0)})`,
        type: "90-365",
      },
    ];

    const shouldRenderModal = paymentStep === "manual";

    return (
      <div className="invoices-wrapper invoices-flex-wrapper">
        {!accountId && !listingId && (
          <div className="invoices-date-filter">
            <h2>Invoices</h2>
            <div className="d-flex ">
              <div className="header-invoices-buttons">
                <div className="nav">
                  {tabs.map((itm) => {
                    return (
                      <button
                        key={itm.type}
                        className={`tab ${
                          activeTab === itm.type ? "selected" : "unselected"
                        }`}
                        onClick={() => {
                          this.updateActiveTab(itm.type);
                        }}
                      >
                        {itm.label}
                      </button>
                    );
                  })}
                </div>
              </div>

              <div className="range">
                <FormGroup className="position-relative has-icon-right">
                  <div className="fts-date-and-time">
                    <div
                      id="datepickerWrapperFrom"
                      className="u-datepicker input-group"
                    >
                      <Flatpickr
                        ref={this.dateRef}
                        placeholder="Select date range"
                        className="fts-date-picker form-control date-picker"
                        options={{
                          mode: "range",
                          dateFormat: "M d, Y",
                          locale: {
                            rangeSeparator: " - ",
                          },
                        }}
                        onChange={(values) => {
                          if (values.length === 2) {
                            this.setState({ activeTab: "" });
                          }
                          this.setState(
                            {
                              page: 1,
                            },
                            () => {
                              this.handleDateChange(values);
                            },
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-control-position">
                    <Icon name="Calendar" size={17} />
                  </div>

                  {this.dateRef.current?.flatpickr.input.value ? (
                    <div
                      className="form-control-position clear-calendar-button"
                      onClick={() => {
                        if (this.dateRef?.current?.flatpickr) {
                          this.clearFlatPickr();
                        }
                      }}
                    >
                      <Icon name="ClearCalendar" size={16} />
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>
          </div>
        )}

        <Row className="app-user-list">
          <Col
            sm="12"
            style={{
              paddingLeft: 0,
            }}
          >
            {!this.props.accountId && !this.props.listingId && (
              <Card>
                {this.props.invoicesData && !inProgress ? (
                  <CardBody>
                    <FTSDataTable
                      noPagination={
                        this.props.accountId || this.props.listingId
                      }
                      totalAmount={this.formatNumber(totalInvoiceAmount)}
                      isSearch={false}
                      longSearch
                      isListing={this.props.isListing}
                      accountId={accountId}
                      listingId={listingId}
                      getSelectedInvoices={this.getSelectedInvoices}
                      searchPlaceholder="Search by Invoice #, Company Name, City and State..."
                      pageSizes={this.pageSizes}
                      filterChips={filterChips}
                      totalRecords={totalRecordsInvoices}
                      rowSelection="multiple"
                      currentPageSize={pageSize}
                      searchValType={"searchVal"}
                      pageSizePropName={"pageSize"}
                      searchVal={searchVal}
                      currentPage={page}
                      pageCount={pageCount}
                      rowData={this.props.invoicesData}
                      onGridReadyInit={this.onGridReady}
                      saveState={this.saveInvoicesColumns}
                      filterSize={this.filterSize}
                      updateSearchQuery={this.updateSearchQuery}
                      onPageChange={this.onPageChanged}
                      resetApi={this.resetApi}
                      onRowClicked={this.onRowClicked}
                      columnDefs={columnDefs}
                      onSortChanged={this.onSortChanged}
                      tableName="invoices"
                      invoicesTab
                      withinCard
                      {...{
                        defaultColDef,
                      }}
                    />
                    {this.props.selectedInvoices.length > 0 && (
                      <div className="FTS-selected-invoices-card">
                        <span>
                          {this.props.selectedInvoices.length} invoices selected
                        </span>
                        <div className="FTS-selected-invoices-buttons">
                          <Button
                            type="button"
                            className="ml-1"
                            color="tertiary"
                            outline
                            onClick={() => {
                              this.onCancelSelection();
                            }}
                          >
                            Cancel
                          </Button>

                          <Button
                            type="button"
                            color="primary"
                            onClick={() => {
                              this.openPaymentSidebar("data");
                            }}
                          >
                            Send/Collect payment
                          </Button>
                        </div>
                      </div>
                    )}
                  </CardBody>
                ) : (
                  <Spinner size="sm" className="m-1" />
                )}
              </Card>
            )}

            {this.props.accountId &&
              (!inProgress ? (
                <AccountInvoices
                  accountId={this.props.accountId}
                  onRowClicked={this.onRowClicked}
                  onGridReadyInit={this.onGridReady}
                  collectDataForCopy={this.collectDataForCopy}
                  refreshTable={this.refreshTable}
                  invoiceToOpen={this.props.invoiceToOpen}
                  togglePopOver={this.togglePopOver}
                  getSelectedInvoices={this.getSelectedInvoices}
                  numberOfInvoicesSelected={this.props.selectedInvoices.length}
                  handlePaymentSidebar={() => {
                    this.openPaymentSidebar("data");
                  }}
                  handleCancelAction={() => {
                    this.onCancelSelection();
                  }}
                  selectedRows={this.props.selectedInvoices}
                />
              ) : (
                <Card>
                  <Spinner size="sm" className="m-1" />
                </Card>
              ))}

            {this.props.listingId &&
              (!inProgress ? (
                <ListingInvoices
                  adId={this.props.listingId}
                  onRowClicked={this.onRowClicked}
                  onGridReadyInit={this.onGridReady}
                  collectDataForCopy={this.collectDataForCopy}
                  refreshTable={this.refreshTable}
                  invoiceToOpen={this.props.invoiceToOpen}
                  togglePopOver={this.togglePopOver}
                  getSelectedInvoices={this.getSelectedInvoices}
                  numberOfInvoicesSelected={this.props.selectedInvoices.length}
                  handlePaymentSidebar={() => {
                    this.openPaymentSidebar("data");
                  }}
                  handleCancelAction={() => {
                    this.onCancelSelection();
                  }}
                  selectedRows={this.props.selectedInvoices}
                />
              ) : (
                <Card>
                  <Spinner size="sm" className="m-1" />
                </Card>
              ))}

            <ContextMenu
              style={{ minWidth: 250, zIndex: 1000 }}
              id="contextMenuInvoices"
              preventHideOnScroll={false}
            >
              <MenuItem
                onClick={() =>
                  navigator.clipboard.writeText(this.state.collectedDataForCopy)
                }
              >
                <FaRegCopy size={18} className="copy" />
                <span className={"ml-1"}>Copy</span>
              </MenuItem>
            </ContextMenu>

            <ActionSidebar
              setDetailsRef={this.setDetailsRef}
              openReceiptSidebar={this.openReceiptSidebar}
              invoicesRef={this.invoicesRef}
              gridApi={this.gridApi}
              show={sidebar}
              action={action}
              updating={updating}
              actionItem={actionItem}
              handleSidebar={this.handleSidebar}
              promotionType="accountPromotion"
              noMarker={action === "payment_overview"}
              callToAction={this.callToAction}
              setPaymentStep={this.setPaymentStep}
              setLastPaymentModalTitle={this.setLastPaymentModalTitle}
              onSubmitQuery={this.onSubmitQuery}
              accountInfo={this.props.accountData}
              listingData={this.props.listingData}
              getSelectedInvoices={this.getSelectedInvoices}
              scrollToSelectedInvoice={this.scrollToSelectedInvoice}
              onSidebarClosed={this.onSidebarClosed}
            />

            <PaymentMethodModal
              closeModal={() => this.togglePaymentModal()}
              setPaymentStep={this.setPaymentStep}
              isOpen={paymentStep === "paymentMethod"}
              accountData={this.props.accountData}
            />

            {/*{paymentStep === "creditCard" && (*/}
            {/*  <CreditCardModal*/}
            {/*    invoicesRef={this.invoicesRef}*/}
            {/*    gridApi={this.gridApi}*/}
            {/*    closeModal={() => {*/}
            {/*      this.togglePaymentModal();*/}
            {/*    }}*/}
            {/*    setPaymentStep={this.setPaymentStep}*/}
            {/*    setLastPaymentModalTitle={this.setLastPaymentModalTitle}*/}
            {/*    isOpen={true}*/}
            {/*    accountData={this.props.accountData}*/}
            {/*    listingData={this.props.listingData}*/}
            {/*    listingDataRef={this.props.listingDataRef}*/}
            {/*  />*/}
            {/*)}*/}

            {shouldRenderModal && (
              <ManuallyPaymentModal
                closeModal={() => {
                  this.togglePaymentModal();
                }}
                setPaymentStep={this.setPaymentStep}
                onFormSubmit={(values) => {
                  this.setState({
                    manuallyFormValues: values,
                    paymentStep: "confirmPayment",
                  });
                }}
                total={this.props.totalAmount}
                isOpen={shouldRenderModal}
              />
            )}

            {this.state.popoverOpen && (
              <Popover
                className="bg-marketing-popover"
                placement="bottom"
                popperClassName={"bg-void-popover"}
                isOpen={this.state.popoverOpen}
                target={this.state.popoverTarget}
                toggle={() => {}}
              >
                <PopoverBody style={{ padding: 0 }}>
                  <span>{this.state.popoverData}</span>
                </PopoverBody>
              </Popover>
            )}

            <ConfirmPaymentModal
              amount={this.state.manuallyFormValues?.amount || 0}
              closeModal={() => {
                this.togglePaymentModal();
              }}
              setPaymentStep={this.setPaymentStep}
              setLastPaymentModalTitle={this.setLastPaymentModalTitle}
              isOpen={paymentStep === "confirmPayment"}
              onConfirmAchPayment={this.onConfirmAchPayment}
            />

            <PaymentErrorModal
              type="admin"
              setPaymentStep={this.setPaymentStep}
              closeModal={() => {
                this.togglePaymentModal();
              }}
              isOpen={paymentStep === "paymentError"}
            />

            {paymentStep === "paymentProccessed" && (
              <PaymentProccessedModal
                closeModal={() => {
                  this.refreshTable();
                  this.togglePaymentModal(true);
                }}
                sourceId={sourceId}
                paymentDate={paymentDate}
                setPaymentStep={this.setPaymentStep}
                lastPaymentModalTitle={this.state.lastPaymentModalTitle}
                isOpen
              />
            )}

            <ConfirmationModal
              title="Unsaved Changes"
              message="Are you sure you want to proceed with this action?"
              closeModal={() => {
                this.togglePaymentModal();
              }}
              confirmModal={this.confirmPaymentConfirmationModal}
              isOpen={isOpenPaymentModal}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    invoicesData: state.invoices.invoicesData,
    totalRecordsInvoices: state.invoices.totalRecordsInvoices,
    selectedInvoices: state.invoices.selectedInvoices,
    invoicesCount: state.invoices.allInvoicesCount,
    totalAmount: state.invoices.totalAmount,
    promoCode: state.promoCodes.promoCode,
    discountAmount: state.invoices.discountAmount,
    activitiesTypes: state.essentialLists.activitiesTypes,
    appliedCreditAmount: state.invoices.appliedCreditAmount,
    upgradesUnReadCount: state.upgrades.upgradesUnReadCount,
    accountInvoices: state.accountInvoices,
    listingInvoices: state.listingInvoices,
  };
};

export default connect(mapStateToProps, {
  searchInvoices,
  payWithStripeAdmin,
  payWithStripeSuccess,
  setInvoicesListTest,
  setSelectedInvoices,
  setAccountSettingsState,
  setAllInvoicesCount,
  setPromoCode,
  resetDiscount,
  setCreditCardData,
  forceCreatePayment,
  setPaymentsListTest,
  createActivity,
  addUserActivity,
  setAppliedCreditAmount,
  setIsCreditApplied,
  setUpgradesUnreadCount,
  searchAccountInvoices,
  setAccountInvoicesPreselectedInvoice,
  searchListingInvoices,
})(Invoices);
