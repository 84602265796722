import React, { PureComponent } from "react";
import { ErrorMessage, Field } from "formik";
import { CustomInput, FormGroup, Input, Label } from "reactstrap";

import FormikInputValue from "../formik/formik-input-value";

import "../../../assets/scss/components/fts/form-fields.scss";

class FTSEmail extends PureComponent {
  render() {
    const {
      labelId,
      name,
      label,
      optInID,
      labelOptIn,
      disabled,
      optIn,
      placeholder,
    } = this.props;

    return (
      <FormGroup className={`fts-email-wrapper ${this.props.className}`}>
        <div className="fts-optin">
          <div className="fts-optin-label">
            <Label for={labelId}>
              {this.props.required && !disabled ? `${label} *` : label}
            </Label>
          </div>
          <div className="fts-optin-switch">
            {!disabled && (
              <Field name={optInID}>
                {({ field: { value }, form }) => {
                  return (
                    <>
                      {optIn && (
                        <CustomInput
                          checked={value}
                          type="switch"
                          id={optInID}
                          {...{ disabled }}
                          onChange={(e) => {
                            form.setFieldValue(optInID, e.target.checked);
                          }}
                        >
                          <span className="switch-label">{labelOptIn}</span>
                        </CustomInput>
                      )}
                    </>
                  );
                }}
              </Field>
            )}
          </div>
        </div>
        <Field {...{ name }}>
          {({ field, form: { errors, touched } }) => (
            <>
              {disabled ? (
                <FormikInputValue>{field.value || "-"}</FormikInputValue>
              ) : (
                <Input
                  className={
                    errors[field.name] && touched[field.name]
                      ? "invalid-formik-input"
                      : ""
                  }
                  id={labelId}
                  placeholder={
                    disabled ? "-" : field.placeholder || placeholder
                  }
                  {...{ disabled }}
                  {...field}
                />
              )}
              <ErrorMessage
                name={field.name}
                render={(msg) => (
                  <div className="formik-validation-error">{msg}</div>
                )}
              />
            </>
          )}
        </Field>
      </FormGroup>
    );
  }
}

export default FTSEmail;
