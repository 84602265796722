import React from "react";
import "../../../assets/scss/components/fts/fts-upgrades-page.scss";
import { Card, FormGroup, Input } from "reactstrap";
import Icon from "../../../components/fts/Icons";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import { defineUpgradesColumns } from "./columns";
import { hideMenu } from "react-contextmenu/modules/actions";
import { connect } from "react-redux";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";
import {
  searchListings,
  searchUpgradesListings,
} from "../../../redux/actions/listings";
import moment from "moment";
import { createActivity } from "../../../redux/actions/activities";
import { history } from "../../../history";
import { activities } from "../../../constants";
import {
  markListingAsRead,
  setUpgradesUnreadCount,
} from "../../../redux/actions/upgrades";
import { toast } from "react-toastify";
import Icons from "../../../components/fts/Icons";

let tabs = [
  {
    label: "1W",
    type: "week",
    value: {
      quantity: 1,
      range: "week",
    },
  },
  {
    label: "1M",
    type: "month",
    value: {
      quantity: 1,
      range: "month",
    },
  },
  {
    label: "3M",
    type: "3Months",
    value: {
      quantity: 3,
      range: "month",
    },
  },
  {
    label: "6M",
    type: "6Months",
    value: {
      quantity: 6,
      range: "month",
    },
  },
  {
    label: "1Y",
    type: "1Year",
    value: {
      quantity: 1,
      range: "year",
    },
  },
];

class Upgrades extends React.Component {
  state = {
    activeTab: "week",
    upgradesSearchValue: "",
    inProgress: false,
    columnDefsUpgrades: null,
    upgradesTableData: null,

    totalRecords: 0,
    pageSize: 10,
    page: 1,
    pageCount: 0,
    defaultColDef: {
      sortable: true,
    },
    sortUpgrades: "",
    query: this.props.storedFilter,
    startAt: moment().subtract(1, "week").toISOString().split("T")[0],
    endAt: moment().toISOString().split("T")[0],
  };

  componentDidMount() {
    this.setState(
      {
        columnDefsUpgrades: defineUpgradesColumns(
          this.collectDataForCopy,
          this.props.employees
        ),
      },
      () => {
        this.getTableData();
      }
    );
  }

  pageSizes = [10, 25, 50, 100];
  debounce = setTimeout(() => {}, 300);

  handleSearch = (e) => {
    if (this.state.inProgress) return;
    this.setState({});

    this.setState(
      {
        upgradesSearchValue: e.target.value,
        page: 1,
      },
      () => {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.getTableData();
        }, 300);
      }
    );
  };

  handleActiveTabChanged = ({ type, value }) => {
    if (this.state.inProgress) {
      return;
    }
    let dateRange = {
      startAt: moment()
        .subtract(value.quantity, value.range)
        .toISOString()
        .split("T")[0],
      endAt: moment().toISOString().split("T")[0],
    };

    this.setState(
      {
        activeTab: type,
        startAt: dateRange.startAt,
        endAt: dateRange.endAt,
      },
      () => {
        this.getTableData();
      }
    );
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  saveStateUpgrades = () => {
    let upgradesColumnState = this.gridColumnApiUpgrades.getColumnState();
    localStorage.setItem(
      "upgradesColumnState",
      JSON.stringify(upgradesColumnState)
    );
  };

  getTableData = () => {
    if (this.state.inProgress) {
      return;
    }

    this.setState({ inProgress: true });

    this.gridApiUpgrades && this.gridApiUpgrades.showLoadingOverlay();

    const { query, page, pageSize, upgradesSearchValue, sortUpgrades } =
      this.state;

    const payload = {
      address: query.address,
      name: query.name,
      zip: query.zip,
      id: query.id,
      firstName: query.firstName,
      lastName: query.lastName,
      phoneNumber: query.phoneNumber,
      adsSubCategoriesStrings: query.subCategory ? query.subCategory : "",
      email: query.email,
      city: query.city,
      state: query.state ? query.state.value : "",
      adminStatus: query.adminStatus ? query.adminStatus.value : "",
      status: query.status ? query.status.value : "",
      includeInAuthorizedPromotions: query.includeInAuthorizedPromotions,
      excludeRenewalEmails: query.excludeRenewalEmails,
      excludeDowngrades: query.excludeDowngrades,
    };

    this.props
      .searchUpgradesListings({
        ...payload,
        page: page,
        resultsPerPage: pageSize,
        searchString: upgradesSearchValue,
        sort: sortUpgrades,
        range: {
          upgradedAt: {
            lte: this.state.endAt,
            gte: this.state.startAt,
          },
        },
      })
      .then((res) => {
        this.setState({
          upgradesTableData: res.data.result.hits,
          totalRecords: res.data.result.nbHits,
          pageCount: res.data.result.nbHits / this.state.pageSize,
          inProgress: false,
        });

        res.data.result.nbHits > 0 &&
          this.gridApiUpgrades &&
          this.gridApiUpgrades.hideOverlay();
      })
      .catch((err) => {
        console.log("====================================");
        console.log("err = ", err);
        console.log("====================================");
        this.gridApiUpgrades && this.gridApiUpgrades.hideOverlay();
      });
  };

  resetApi = () => {
    this.gridApiUpgrades = null;
  };

  onGridReady = (params) => {
    this.gridApiUpgrades = params.api;
    this.gridColumnApiUpgrades = params.columnApi;

    if (localStorage.getItem("upgradesColumnState") !== null) {
      this.gridColumnApiUpgrades.setColumnState(
        JSON.parse(localStorage.getItem("upgradesColumnState"))
      );
    }
  };

  logListingOpenedFromUpgradesListingActivity = async (listing) => {
    let activityPayload = {
      status: 2,
      adId: listing?.id,
      activityType:
        this.props.activitiesTypes
          .LISTING_OPENED_FROM_UPGRADES_LISTING_ACTIVITY,
      activity: activities.listingOpenedFromUpgradesListingActivity.replace(
        "{{admin_name}}",
        `${this.props.userData.firstName} ${this.props.userData.lastName}`
      ),
      adminUserId: this.props.userData.id,
      iconName: "Listings",
    };

    return await this.props.createActivity(activityPayload);
  };

  logListingOpenedFromUpgradesAccountActivity = async (listing) => {
    let activityPayload = {
      status: 2,
      userId: listing?.userId,
      activityType:
        this.props.activitiesTypes
          .LISTING_OPENED_FROM_UPGRADES_ACCOUNT_ACTIVITY,
      activity: activities.listingOpenedFromUpgradesAccountActivity
        .replace(
          "{{admin_name}}",
          `${this.props.userData.firstName} ${this.props.userData.lastName}`
        )
        .replace("{{listing_name}}", `${listing?.name}`),
      adminUserId: this.props.userData.id,
      iconName: "Listings",
    };

    return await this.props.createActivity(activityPayload);
  };

  onRowClicked = (e) => {
    if (e.data.adminLastViewAt <= e.data.upgradedAt) {
      this.props
        .markListingAsRead(e.data.id)
        .then(() => {
          this.logListingOpenedFromUpgradesListingActivity(e.data);
          this.logListingOpenedFromUpgradesAccountActivity(e.data);
          this.props.setUpgradesUnreadCount(this.props.upgradesUnReadCount - 1);
          history.push(`/listings/${e.data.id}`);
        })
        .catch((err) => {
          console.log("err: ", err);
          toast.error("Something went wrong!");
        });
    } else {
      history.push(`/listings/${e.data.id}`);
    }
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      this.getTableData();
    });
  };

  filterSize = (type, val) => {
    this.setState(
      {
        pageSize: val,
      },
      () => {
        this.getTableData();
      }
    );
  };

  onSortChanged = (params) => {
    this.setState({ sortUpgrades: params.api.getSortModel() });
    this.getTableData();
  };

  render() {
    const {
      upgradesSearchValue,
      activeTab,
      columnDefsUpgrades,
      defaultColDef,
      upgradesTableData,
      totalRecords,
      pageSize,
      page,
      pageCount,
    } = this.state;
    return (
      <div className="fts-upgrades-page">
        <Card>
          <div className="fts-upgrade-title-row">
            <h4>Upgrades</h4>
            <div className="fts-upgrade-filters">
              <div className="nav">
                {tabs.map((itm) => {
                  return (
                    <button
                      key={itm.type}
                      className={`tab ${
                        activeTab === itm.type ? "selected" : "unselected"
                      }`}
                      onClick={() => {
                        this.handleActiveTabChanged({
                          type: itm.type,
                          value: itm.value,
                        });
                      }}
                    >
                      {itm.label}
                    </button>
                  );
                })}
              </div>
              <FormGroup className="position-relative has-icon-left ml-1 mb-0">
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    this.handleSearch(e);
                  }}
                  value={upgradesSearchValue}
                  style={{ minWidth: 300, borderRadius: 4 }}
                  className="upgrades-search-input-field"
                />
                <div className="form-control-position">
                  <Icon name="Search" size={18} />
                </div>
              </FormGroup>
            </div>
          </div>
          <FTSDataTable
            tableName={"upgrades"}
            isSearch
            upgrades
            onSortChanged={(params) => this.onSortChanged(params, "upgrades")}
            pageSizes={this.pageSizes}
            columnDefs={columnDefsUpgrades}
            rowData={upgradesTableData}
            totalRecords={totalRecords}
            resetApi={this.resetApi}
            onGridReadyInit={this.onGridReady}
            saveState={this.saveStateUpgrades}
            currentPageSize={pageSize}
            currentPage={page}
            pageCount={pageCount}
            onRowClicked={this.onRowClicked}
            onPageChange={this.onPageChanged}
            onScroll={hideMenu}
            filterSize={this.filterSize}
            {...{
              defaultColDef,
            }}
          />
          <ContextMenu
            style={{ minWidth: 250 }}
            id="contextMenuUpgrades"
            preventHideOnScroll={false}
          >
            <MenuItem
              onClick={() =>
                navigator.clipboard.writeText(this.state.collectedDataForCopy)
              }
            >
              <FaRegCopy size={18} className="copy" />
              <span className="ml-1 text-dark">Copy</span>
            </MenuItem>
            {this.state.call && this.state.collectedDataForCopy && (
              <MenuItem>
                <Icons name="PhoneBlue" />
                <a
                  className="ml-1 text-decoration-none text-dark w-100"
                  href={`tel:${this.state.collectedDataForCopy}`}
                >
                  Call - {this.state.collectedDataForCopy}
                </a>
              </MenuItem>
            )}
          </ContextMenu>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.essentialLists.employees,
    storedFilter: state.searchFilter,
    userData: state.auth.login.values.loggedInUser,
    activitiesTypes: state.essentialLists.activitiesTypes,
    upgradesUnReadCount: state.upgrades.upgradesUnReadCount,
  };
};

export default connect(mapStateToProps, {
  searchListings,
  searchUpgradesListings,
  createActivity,
  markListingAsRead,
  setUpgradesUnreadCount,
})(Upgrades);
