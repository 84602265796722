/* eslint-disable indent */
import React, { Suspense, useEffect, useCallback } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { history } from "./history";
import { connect } from "react-redux";
import Spinner from "./components/@vuexy/spinner/Loading-spinner";
import { ContextLayout } from "./utility/context/Layout";
import { toast } from "react-toastify";
import socketHandler from "./utility/socket";

import Icon from "./components/fts/Icons";
import { activities, NOTIFICATION } from "./constants";
import FormikInputValue from "./components/fts/formik/formik-input-value";
import {
  setNotificationUnreadCount,
  getNotificationsCount,
} from "./redux/actions/notifications";
import {
  getUpgradesCount,
  setUpgradesUnreadCount,
} from "./redux/actions/upgrades";
import { requestParticipation } from "./redux/actions/todo";
import {
  fetchActivitiesAccount,
  fetchActivitiesListing,
  createActivity,
} from "./redux/actions/activities";
import { setUserActivities } from "./redux/actions/user-activities";
import {
  getNewAccountsCount,
  setNewAccountsCount,
} from "./redux/actions/users";
import {
  getNewListingsUnreadCount,
  setNewListingsUnreadCount,
} from "./redux/actions/listings";
import AdminNotification from "./components/fts/admin-notification";

import Activities from "./views/pages/activities/Activities";
import Promotions from "./views/ui-elements/data-list/promotionsView";
import Updates from "./views/ui-elements/data-list/UpdatesView";
import Renewals from "./views/pages/renewals";
import Sales from "./views/pages/sales-tab";
import Dashboard from "./views/pages/dashboard-tab";
import CategoriesView from "./views/ui-elements/data-list/CategoriesView";
import AdsView from "./views/ui-elements/data-list/adsView";
import UserManagementView from "./views/ui-elements/data-list/userManagementView";
import ApprovedView from "./views/ui-elements/data-list/approvedView";
import PromocodesView from "./views/pages/promocodes";
import InvoiceView from "./views/pages/invoices-page";
import PaymentView from "./views/pages/payments/paymentsList";
import CommunicationView from "./views/pages/account-settings/Marketing";
import EmailTemplatesView from "./views/ui-elements/data-list/EmailTemplatesView";
import InvoicesView from "./views/ui-elements/data-list/invoicesView";
import ServiceAmenitiesView from "./views/ui-elements/data-list/serviceAmenitiesView";
import Amenities from "./views/ui-elements/data-list/amenities";
import SubcategoriesView from "./views/ui-elements/data-list/SubCategoriesView";
import Error404 from "./views/pages/misc/error/404";
import Login from "./views/pages/authentication/login/Login";
import ForgotPassword from "./views/pages/authentication/ForgotPassword";
import UserTypesView from "./views/ui-elements/data-list/UserTypesView";
import TodoTypesView from "./views/ui-elements/data-list/TodoTypesView";
import PermissionsView from "./views/ui-elements/data-list/PermissionsView";
import AccountsView from "./views/pages/accounts/List";
import CreateCustomerView from "./views/pages/createCustomer";
import NotAuthorized from "./views/pages/misc/NotAuthorized.js";
import AccountInfo from "./views/pages/account-settings/AccountSettings";
import ListingInfo from "./views/pages/listing-settings/ListingSettings";
import TaskManagement from "./views/pages/task-management-new";
import RecurringPaymentView from "./views/pages/recurring-payments/recurringPaymentsList";
import Upgrades from "./views/pages/upgrades";
import NewListings from "./views/pages/new-listings";
import NewAccounts from "./views/pages/new-accounts";
import AccountEmptyState from "./views/pages/account-empty-state/AccountEmptyState";
import UpdateExpiredPassword from "./views/pages/authentication/UpdateExpiredPassword";
import AdminMfa from "./views/pages/authentication/AdminMfa";

// Set Layout and Component Using App Route
const RouteConfig = ({
  component: Component,
  fullLayout,
  isProtected,
  isLogged,
  userData,
  setNotificationUnreadCount,
  // unReadCount,
  setUpgradesUnreadCount,
  getUpgradesCount,
  requestParticipation,
  employees,
  // fetchActivitiesAccount,
  fetchActivitiesListing,
  createActivity,
  getNotificationsCount,
  getNewAccountsCount,
  setNewAccountsCount,
  setNewListingsUnreadCount,
  getNewListingsUnreadCount,
  ...rest
}) => {
  useEffect(() => {
    if (userData && userData?.id) {
      socketHandler.connect();
      socketHandler.subscribeEvent("saveConnection");
      socketHandler.events.saveConnection.onOpen = () => {
        socketHandler.send("saveConnection", { userId: userData?.id });
      };

      if (socketHandler?.socket?.readyState === WebSocket.OPEN) {
        socketHandler.send("saveConnection", { userId: userData?.id });
      }

      getNewAccountsCount()
        .then(({ data }) => {
          setNewAccountsCount(data.result);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
      getNewListingsUnreadCount()
        .then(({ data }) => {
          setNewListingsUnreadCount(data.result);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
      getUpgradesCount()
        .then((res) => {
          setUpgradesUnreadCount(res.data?.count);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }, [
    userData,
    getNewAccountsCount,
    getNewListingsUnreadCount,
    getUpgradesCount,
    setNewAccountsCount,
    setNewListingsUnreadCount,
    setUpgradesUnreadCount,
  ]);

  const handleRequestStatus = useCallback(
    (status, selectedNotification) => {
      const {
        link,
        sendBy,
        id: notificationId,
        description: todoTitle,
        title: companyName,
      } = selectedNotification;

      let todoId = link.split("?")[1].split("=")[1];
      let ownerId = link.split("/")[2].split("?")[0];

      const params = {
        todoId,
        sendBy,
        status,
        notificationId,
        todoTitle,
        companyName,
        ownerId,
      };

      requestParticipation(params);

      let activityPayload = {
        status: 1,
        toDoId: todoId,
        userId: ownerId,
        notificationId,
        activityType: this.props.activitiesTypes.HANDLED_PARTICIPATION_REQUEST,
        activity: activities.handleParticipationRequest
          .replace(
            "{{admin_name}}",
            `${this.props.userData.firstName} ${this.props.userData.lastName}`,
          )
          .replace("{{status}}", status)
          .replace("{{todoId}}", `${todoId}`),
        adminUserId: this.props.userData.id,
        iconName: "Todos",
      };

      createActivity(activityPayload).then(async () => {
        let path = window.location.pathname.split("/");
        let isListing = path.find((e) => e === "listings");
        let isAccount = path.find((e) => e === "accounts");

        if (isListing) {
          let { data } = await fetchActivitiesListing(path[2]);
          await setUserActivities(data);
        }
        if (isAccount) {
          let { data } = await this.props.fetchActivitiesAccount(path[2]);
          await setUserActivities(data);
        }
      });
    },
    [requestParticipation, fetchActivitiesListing, createActivity],
  );

  const getAdminName = useCallback(
    (id) => {
      return employees.find((employee) => employee.value === id);
    },
    [employees],
  );

  useEffect(() => {
    socketHandler.events.saveConnection.onMessage = (data) => {
      let notification = data;

      getNotificationsCount().then((res) =>
        setNotificationUnreadCount(res.data?.result),
      );

      // this is a hack, we don't have notification type
      if (
        notification?.title === "Account access granted" ||
        notification?.title === "Account access rejected"
      ) {
        const notificationType =
          notification?.title === "Account access granted"
            ? "positive"
            : "negative";

        toast.info(
          <AdminNotification
            type={notificationType}
            title={notification?.title}
            description={notification?.description}
          />,
          {
            autoClose: false,
          },
        );
      } else {
        toast.info(
          <Notification
            getAdminName={getAdminName}
            notification={notification}
            handleRequestStatus={handleRequestStatus}
          />,
          {
            autoClose: false,
          },
        );
      }
    };
  }, [
    handleRequestStatus,
    setNotificationUnreadCount,
    setUpgradesUnreadCount,
    getAdminName,
    getNotificationsCount,
  ]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isProtected && !isLogged) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <ContextLayout.Consumer>
            {(context) => {
              let LayoutTag =
                fullLayout === true
                  ? context.fullLayout
                  : context.state.activeLayout === "horizontal"
                  ? context.horizontalLayout
                  : context.VerticalLayout;
              return (
                <LayoutTag {...props} permission={props.user}>
                  <Suspense fallback={<Spinner />}>
                    <Component {...props} />
                  </Suspense>
                </LayoutTag>
              );
            }}
          </ContextLayout.Consumer>
        );
      }}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values?.loggedInUser,
    user: state.auth.login.permissions,
    isLogged: !!state.auth.login.values,
    unReadCount: state.notifications?.unReadCount,
    upgradesUnreadCount: state.upgrades?.upgradesUnreadCount,
    employees: state.essentialLists.employees,
  };
};

const AppRoute = connect(mapStateToProps, {
  setNotificationUnreadCount,
  setUpgradesUnreadCount,
  requestParticipation,
  createActivity,
  fetchActivitiesAccount,
  fetchActivitiesListing,
  setUserActivities,
  getNotificationsCount,
  getUpgradesCount,
  getNewAccountsCount,
  setNewAccountsCount,
  getNewListingsUnreadCount,
  setNewListingsUnreadCount,
})(RouteConfig);

class AppRouter extends React.Component {
  render() {
    return (
      // Set the directory path if you are deploying in sub-folder
      <Router history={history}>
        <Switch>
          <AppRoute isProtected exact path="/" component={Dashboard} />
          <AppRoute path="/login" component={Login} fullLayout />
          <AppRoute
            path="/update-password"
            component={UpdateExpiredPassword}
            fullLayout
          />{" "}
          <AppRoute path="/login-mfa" component={AdminMfa} fullLayout />
          <AppRoute
            isProtected
            path="/tasks-management"
            component={TaskManagement}
          />
          <AppRoute
            path="/forgot-password"
            component={ForgotPassword}
            fullLayout
          />
          <AppRoute isProtected path="/promotions" component={Promotions} />
          <AppRoute isProtected path="/updates" component={Updates} />
          <AppRoute isProtected path="/renewals" component={Renewals} />
          <AppRoute isProtected path="/sales" component={Sales} />
          <AppRoute
            isProtected
            path="/service-amenities"
            component={ServiceAmenitiesView}
          />
          <AppRoute isProtected path="/amenities" component={Amenities} />
          <AppRoute isProtected path="/invoice" component={InvoiceView} />
          <AppRoute isProtected path="/payments" component={PaymentView} />
          <AppRoute
            isProtected
            path="/communications"
            component={CommunicationView}
          />
          <AppRoute
            isProtected
            path="/email-templates"
            component={EmailTemplatesView}
          />
          <AppRoute isProtected path="/promocodes" component={PromocodesView} />
          <AppRoute isProtected path="/invoices" component={InvoicesView} />
          <AppRoute isProtected path="/ads" component={AdsView} />
          <AppRoute
            isProtected
            path="/user-management"
            component={UserManagementView}
          />
          <AppRoute isProtected path="/approved" component={ApprovedView} />
          <AppRoute isProtected path="/categories" component={CategoriesView} />
          <AppRoute
            isProtected
            path="/subcategories"
            component={SubcategoriesView}
          />
          <AppRoute isProtected path="/user-types" component={UserTypesView} />
          <AppRoute isProtected path="/todo-types" component={TodoTypesView} />
          <AppRoute
            isProtected
            path="/permissions"
            component={PermissionsView}
          />
          <AppRoute
            isProtected
            path="/accounts/:accountId"
            component={AccountInfo}
          />
          <AppRoute
            isProtected
            path="/listings/:listingId"
            component={ListingInfo}
          />
          <AppRoute isProtected path="/accounts" component={AccountsView} />
          <AppRoute isProtected path="/listings" component={AccountsView} />
          <AppRoute isProtected path="/activities" component={Activities} />
          <AppRoute
            isProtected
            path="/new-customer"
            component={CreateCustomerView}
          />
          <AppRoute isProtected path="/upgrades" component={Upgrades} />
          <AppRoute isProtected path="/new-listings" component={NewListings} />
          <AppRoute isProtected path="/new-accounts" component={NewAccounts} />
          <AppRoute
            isProtected
            path="/not-authorized"
            component={NotAuthorized}
            fullLayout
          />
          <AppRoute
            isProtected
            path="/recurring-payments"
            component={RecurringPaymentView}
          />
          <AppRoute
            isProtected
            path="/account-does-not-exist"
            component={AccountEmptyState}
          />
          <AppRoute component={Error404} fullLayout />
        </Switch>
      </Router>
    );
  }
}

const Notification = (props) => {
  const {
    notification = NOTIFICATION,
    handleRequestStatus,
    getAdminName,
  } = props;

  return (
    <div
      style={{
        margin: "-8px -19px -8px -8px",
        padding: 16,
        backgroundColor: "#ECF7FB",
        minWidth: 400,
      }}
    >
      <div
        style={{
          padding: "5px 0px",
        }}
        className="d-flex  "
      >
        <Icon size={40} name={notification.icon} />
        <h4 className={"ml-1"}>{notification.title}</h4>
      </div>
      <div style={{ margin: "12px 0" }}>
        <h6 className={"m-0 p-0"}>
          {getAdminName(notification.sendBy)?.label}{" "}
          {notification.notification.replace(/\+/g, " ")}
        </h6>
      </div>
      <div className={"bg-white rounded shadow"} style={{ padding: 10 }}>
        <FormikInputValue className={"text-dark"}>
          {decodeURIComponent(notification.description)}
        </FormikInputValue>
      </div>
      {notification.linkTitle === "requestParticipation" ? (
        <div
          style={{ marginTop: 16 }}
          className="d-flex justify-content-between"
        >
          <a href={notification.link}>
            View todo <Icon color={"#fff"} name={"ArrowLink"} />
          </a>
          <div className="d-flex">
            <p
              onClick={() => handleRequestStatus("Reject", notification)}
              className={"cursor-pointer d-flex align-items-center "}
              style={{ color: "#4B5457" }}
            >
              <Icon size={30} color={"#fff"} name={"Close"} />
              <span>Reject</span>
            </p>
            <p
              onClick={() => handleRequestStatus("Approve", notification)}
              className="ml-1 d-flex align-items-center  color-primary cursor-pointer"
            >
              <Icon size={30} color={"#fff"} name={"ApproveTodo"} />
              <span>Approve</span>
            </p>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: 16 }} className="d-flex justify-content-start">
          <a href={notification.link}>
            {notification.linkTitle} <Icon color={"#fff"} name={"ArrowLink"} />
          </a>
        </div>
      )}
    </div>
  );
};

export default AppRouter;
