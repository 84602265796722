import React, { PureComponent } from "react";
import { Draggable } from "react-beautiful-dnd";
import TodoSmallComponent from "../../../components/fts/todos/TodoSmallComponent";

class ColumnItem extends PureComponent {
  render() {
    const {
      item,
      index,
      column,
      callToAction,
      activeDropdown,
      setActiveDropdown,
      handleArchive,
      handleUnArchive,
      handleTodoTypeUpdate,
      handleTodoUsersUpdate,
      handleEmptyCols,
      getEmptyColumns,
      list,
      userRef,
      user,
      fetchTodos,
    } = this.props;
    return (
      <Draggable draggableId={`${item.id}`} index={index}>
        {(provided, snapshot) => (
          <div
            id={`${column.value}_${item.id}`}
            className={
              item.transform
                ? `column-item transform-column-item-${item.transform} `
                : "column-item"
            }
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <TodoSmallComponent
              fetchTodos={fetchTodos}
              name={`${item.id}`}
              userRef={userRef}
              data={item}
              column={column}
              callToAction={callToAction}
              isdragging={snapshot.isDragging}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
              handleArchive={handleArchive}
              handleUnArchive={handleUnArchive}
              handleTodoTypeUpdate={handleTodoTypeUpdate}
              handleTodoUsersUpdate={handleTodoUsersUpdate}
              handleEmptyCols={handleEmptyCols}
              getEmptyColumns={getEmptyColumns}
              list={list}
              user={user}
            />
          </div>
        )}
      </Draggable>
    );
  }
}

export default ColumnItem;
