/* eslint-disable react/display-name */
import React from "react";
import { formatRawDate, toMomentDate } from "../../../../configs/dateConfig";
import Icon from "../../../../components/fts/Icons.js";
import { isAssetAdditionalAsset } from "../../../../utility/assets";
const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
      <div className="ag-date-table__time">
        {toMomentDate(date).format("[at] h:mm a")}
      </div>
    </div>
  );
};

const stopPropagation = (e) => {
  e.stopPropagation();
};

export const accountColumns = (imageLink = "") => {
  return [
    {
      headerName: "File ID",
      field: "id",
      sortable: true,
      unSortIcon: true,
      minWidth: 100,
      maxWidth: 100,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{params.data.id}</span>
            {isAssetAdditionalAsset(imageLink, params.data.path) && (
              <Icon name={"Flag"} color={"#01B5F5"} />
            )}
          </div>
        );
      },
    },
    {
      headerName: "File name",
      field: "fileName",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <>
            <span>{`${params.data.fileName}`}</span>
            <div
              id={`files-user-${params.data.id}`}
              onClick={stopPropagation}
            />
          </>
        );
      },
    },
    {
      headerName: "Date uploaded",
      field: "createdAt",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div>
            <span>
              <TableDate date={params.data.createdAt} />
            </span>
          </div>
        );
      },
    },
    {
      headerName: "File type",
      field: "type",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      getQuickFilterText: (params) => params.value,
    },
    {
      headerName: "Uploaded by",
      field: "adminOwner",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <div>
            <span>
              {params.data.adminOwner?.firstName &&
              params.data.adminOwner?.lastName
                ? `${params.data.adminOwner?.firstName} ${params.data.adminOwner?.lastName}`
                : "Unknown"}
            </span>
          </div>
        );
      },
    },
  ];
};

export const listingColumns = (imageLink = "") => [
  {
    headerName: "File ID",
    field: "id",
    sortable: true,
    unSortIcon: true,
    minWidth: 100,
    maxWidth: 100,
    flex: 1,
    cellRendererFramework: (params) => {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{params.data.id}</span>
          {isAssetAdditionalAsset(imageLink, params.data.path) && (
            <Icon name={"Flag"} color={"#01B5F5"} />
          )}
        </div>
      );
    },
  },
  {
    headerName: "File name",
    field: "fileName",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    cellRendererFramework: (params) => {
      return (
        <>
          <span>{`${params.data.fileName}`}</span>
          <div
            id={`files-listings-${params.data.id}`}
            onClick={stopPropagation}
          />
        </>
      );
    },
  },
  {
    headerName: "Date uploaded",
    field: "createdAt",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    getQuickFilterText: (params) => params.value,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>
            <TableDate date={params.data.createdAt} />
          </span>
        </div>
      );
    },
  },
  {
    headerName: "File type",
    field: "type",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    getQuickFilterText: (params) => params.value,
  },
  {
    headerName: "Uploaded by",
    field: "adminOwner",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>
            {params.data.adminOwner?.firstName &&
            params.data.adminOwner?.lastName
              ? `${params.data.adminOwner?.firstName} ${params.data.adminOwner?.lastName}`
              : "Unknown"}
          </span>
        </div>
      );
    },
  },
];

export const todoColumns = [
  {
    headerName: "File ID",
    field: "id",
    sortable: true,
    unSortIcon: true,
    minWidth: 100,
    maxWidth: 100,
    flex: 1,
  },
  {
    headerName: "File name",
    field: "fileName",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    cellRendererFramework: (params) => {
      return (
        <>
          <span>{`${params.data.fileName}`}</span>
          <div id={`files-todos-${params.data.id}`} onClick={stopPropagation} />
        </>
      );
    },
  },
  {
    headerName: "Tied to",
    field: "todo",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    cellRendererFramework: (params) => {
      return <span>{params.data?.todo?.title}</span>;
    },
  },
  {
    headerName: "Date uploaded",
    field: "createdAt",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    getQuickFilterText: (params) => params.value,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>
            <TableDate date={params.data.createdAt} />
          </span>
        </div>
      );
    },
  },
  {
    headerName: "File type",
    field: "type",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    getQuickFilterText: (params) => params.value,
  },
  {
    headerName: "Uploaded by",
    field: "adminOwner",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>
            {params.data.adminOwner?.firstName &&
            params.data.adminOwner?.lastName
              ? `${params.data.adminOwner?.firstName} ${params.data.adminOwner?.lastName}`
              : "Unknown"}
          </span>
        </div>
      );
    },
  },
];

export const noteColumns = [
  {
    headerName: "File ID",
    field: "id",
    sortable: true,
    unSortIcon: true,
    minWidth: 100,
    maxWidth: 100,
    flex: 1,
  },
  {
    headerName: "File name",
    field: "fileName",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    cellRendererFramework: (params) => {
      return (
        <>
          <span>{`${params.data.fileName}`}</span>
          <div id={`files-notes-${params.data.id}`} onClick={stopPropagation} />
        </>
      );
    },
  },
  {
    headerName: "Date uploaded",
    field: "createdAt",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    getQuickFilterText: (params) => params.value,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>
            <TableDate date={params.data.createdAt} />
          </span>
        </div>
      );
    },
  },
  {
    headerName: "File type",
    field: "type",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    getQuickFilterText: (params) => params.value,
  },

  {
    headerName: "Uploaded by",
    field: "adminOwner",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>
            {params.data.adminOwner?.firstName &&
            params.data.adminOwner?.lastName
              ? `${params.data.adminOwner?.firstName} ${params.data.adminOwner?.lastName}`
              : "Unknown"}
          </span>
        </div>
      );
    },
  },
];

export const promotionsColumns = [
  {
    headerName: "File ID",
    field: "id",
    sortable: true,
    unSortIcon: true,
    minWidth: 100,
    maxWidth: 100,
    flex: 1,
  },
  {
    headerName: "Tied to",
    field: "promotion",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    cellRendererFramework: (params) => {
      return (
        <>
          <span>{params.data?.promotion?.title || "Not associated"}</span>
          <div id={`files-todos-${params.data.id}`} onClick={stopPropagation} />
        </>
      );
    },
  },
  {
    headerName: "Device type",
    field: "deviceType",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    // cellRendererFramework: (params) => {
    //   return <span>{params.data?.promotion?.title}</span>;
    // },
  },
  {
    headerName: "Date uploaded",
    field: "createdAt",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    getQuickFilterText: (params) => params.value,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>
            <TableDate date={params.data.createdAt} />
          </span>
        </div>
      );
    },
  },
  {
    headerName: "File type",
    field: "type",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    getQuickFilterText: (params) => params.value,
  },
  {
    headerName: "Uploaded by",
    field: "adminOwner",
    sortable: true,
    unSortIcon: true,
    flex: 1,
    cellRendererFramework: (params) => {
      return (
        <div>
          <span>
            {params.data.adminOwner?.firstName &&
            params.data.adminOwner?.lastName
              ? `${params.data.adminOwner?.firstName} ${params.data.adminOwner?.lastName}`
              : "Unknown"}
          </span>
        </div>
      );
    },
  },
];
