import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import "../../../assets/scss/components/fts/renewals-page.scss";
import {
  Row,
  Col,
  Card,
  Collapse,
  Button,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  TabPane,
} from "reactstrap";
import Icon from "../../../components/fts/Icons";
import Flatpickr from "react-flatpickr";
import AdminPicker from "../../../components/fts/admin-picker";
import SearchInput from "../../../components/fts/search-input/index";
import { phoneNumberMask } from "../../../utility/masks";
import StandaloneStatesPicker from "../../../components/fts/standalone-states-picker";
import {
  updateRenewalsFilter,
  clearRenewalsFilter,
  getRenewals,
} from "../../../redux/actions/renewals";
import moment from "moment";
import classnames from "classnames";
import Checkbox from "../../../components/fts/Checkbox";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import { defineListingColumns } from "./columns";
import { capitalizeFirstLetter } from "../../../utility/googleCalendar";
import { history } from "../../../history";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";

import { checkPermissions } from "../../../utility/permissionsCheck";
import Icons from "../../../components/fts/Icons";

const sortTypes = {
  PREMIUM: "premium",
  STANDARD: "standard",
  BASIC_PLUS: "basicPlus",
  BASIC: "basic",
  FREE: "free",
};

let defaultSortRenewed = [{ colId: "renewedTimestamp", sort: "ASC" }];
let defaultSortUpcoming = [{ colId: "renewalTimestamp", sort: "ASC" }];
let defaultSortPastDue = [{ colId: "renewalTimestamp", sort: "ASC" }];

const filters = (
  pageSize,
  page,
  sort,
  priceId,
  adminOwner,
  collectedBy,
  initialFilters = [],
  startAt,
  endAt,
) => {
  return {
    renewed: {
      today: {
        pageSize,
        page,
        sort: sort || defaultSortRenewed,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewedTimestamp: {
                lte: moment(endAt).add(1, "days").unix(),
                gte: moment(startAt).subtract(1, "days").unix(),
              },
            },
          },
        ],
      },
      week: {
        pageSize,
        page,
        sort: sort || defaultSortRenewed,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewedTimestamp: {
                lte: moment(endAt).add(1, "days").unix(),
                gte: moment(startAt).subtract(7, "days").unix(),
              },
            },
          },
        ],
      },
      month: {
        pageSize,
        page,
        sort: sort || defaultSortRenewed,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewedTimestamp: {
                lte: moment(endAt).add(1, "days").unix(),
                gte: moment(startAt).subtract(1, "month").unix(),
              },
            },
          },
        ],
      },
      custom: {
        pageSize,
        page,
        sort: sort || defaultSortRenewed,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewedTimestamp: {
                lte: moment(endAt).unix(),
                gte: moment(startAt).unix(),
              },
            },
          },
        ],
      },
    },
    pastDue: {
      today: {
        pageSize,
        page,
        sort: sort || defaultSortPastDue,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewalTimestamp: {
                lte: moment(endAt).startOf("day").add(1, "days").unix(),
                gte: moment(startAt).startOf("day").unix(),
              },
            },
          },
        ],
      },
      week: {
        pageSize,
        page,
        sort: sort || defaultSortPastDue,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewalTimestamp: {
                lte: moment(endAt).startOf("day").add(1, "days").unix(),
                gte: moment(startAt).startOf("day").subtract(7, "days").unix(),
              },
            },
          },
        ],
      },
      month: {
        pageSize,
        page,
        sort: sort || defaultSortPastDue,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewalTimestamp: {
                lte: moment(endAt).startOf("day").add(1, "days").unix(),
                gte: moment(startAt).startOf("day").subtract(1, "month").unix(),
              },
            },
          },
        ],
      },
      custom: {
        pageSize,
        page,
        sort: sort || defaultSortPastDue,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewalTimestamp: {
                lte:
                  moment(endAt).diff(moment(), "days") <= 0
                    ? moment(endAt).unix()
                    : moment().unix(),
                gte:
                  moment(startAt).diff(moment(), "days") <= 0
                    ? moment(startAt).unix()
                    : moment().unix(),
              },
            },
          },
        ],
      },
    },
    upcoming: {
      today: {
        pageSize,
        page,
        sort: sort || defaultSortUpcoming,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewalTimestamp: {
                lte: moment(endAt).startOf("day").add(2, "days").unix(),
                gte: moment(endAt).startOf("day").add(1, "days").unix(),
              },
            },
          },
        ],
      },
      week: {
        pageSize,
        page,
        sort: sort || defaultSortUpcoming,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          (collectedBy && { term: { collectedBy } }) || null,
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          {
            range: {
              renewalTimestamp: {
                lte: moment(endAt).startOf("day").add(7, "days").unix(),
                gte: moment(endAt).startOf("day").add(1, "days").unix(),
              },
            },
          },
        ],
      },
      month: {
        pageSize,
        page,
        sort: sort || defaultSortUpcoming,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewalTimestamp: {
                lte: moment(endAt).startOf("day").add(1, "month").unix(),
                gte: moment(endAt).startOf("day").add(1, "days").unix(),
              },
            },
          },
        ],
      },
      custom: {
        pageSize,
        page,
        sort: sort || defaultSortUpcoming,
        filter: [
          ...initialFilters,
          { term: { priceId } },
          adminOwner && { term: { adminAccountOwner: adminOwner } },
          (collectedBy && { term: { collectedBy } }) || null,
          {
            range: {
              renewalTimestamp: {
                lte:
                  moment(endAt).diff(moment(), "days") >= 0
                    ? moment(endAt).unix()
                    : moment().unix(),
                gte:
                  moment(startAt).diff(moment(), "days") >= 0
                    ? moment(startAt).unix()
                    : moment().unix(),
              },
            },
          },
        ],
      },
    },
  };
};

class Renewals extends Component {
  componentDidMount() {
    this.setState(
      {
        adminOwner: checkPermissions(
          [{ id: 4, subCat: [56] }],
          this.props.adminPermissions,
        )
          ? undefined
          : this.props.userData.loggedInUser.id,
      },
      () => {
        this.props.renewalsFilter.collapsed &&
          this.setState({
            searchActive: true,
          });

        this.listingColumnsPremium = defineListingColumns(
          this.props.types,
          this.collectDataForCopy,
          this.state.activeTabRenewal,
          this.props.employees,
        );
        this.listingColumnsStandard = defineListingColumns(
          this.props.types,
          this.collectDataForCopy,
          this.state.activeTabRenewal,
          this.props.employees,
        );
        this.listingColumnsBasicPlus = defineListingColumns(
          this.props.types,
          this.collectDataForCopy,
          this.state.activeTabRenewal,
          this.props.employees,
        );
        this.listingColumnsBasic = defineListingColumns(
          this.props.types,
          this.collectDataForCopy,
          this.state.activeTabRenewal,
          this.props.employees,
        );
        this.listingColumnsFree = defineListingColumns(
          this.props.types,
          this.collectDataForCopy,
          this.state.activeTabRenewal,
          this.props.employees,
        );

        this.listingColumnsPremiumUpcoming = defineListingColumns(
          this.props.types,
          this.collectDataForCopy,
          "",
          this.props.employees,
        );
        this.listingColumnsStandardUpcoming = defineListingColumns(
          this.props.types,
          this.collectDataForCopy,
          "",
          this.props.employees,
        );
        this.listingColumnsBasicPlusUpcoming = defineListingColumns(
          this.props.types,
          this.collectDataForCopy,
          "",
          this.props.employees,
        );
        this.listingColumnsBasicUpcoming = defineListingColumns(
          this.props.types,
          this.collectDataForCopy,
          "",
          this.props.employees,
        );
        this.listingColumnsFreeUpcoming = defineListingColumns(
          this.props.types,
          this.collectDataForCopy,
          "",
          this.props.employees,
        );
        this.initialLoad();
      },
    );
  }

  initialLoad = () => {
    const {
      activeTab,
      activeTabRenewal,
      manualRenewals,
      search,
      startAt,
      adminOwner,
      endAt,
    } = this.state;

    let initialFilters = [];

    for (let [key, value] of Object.entries(search)) {
      initialFilters.push({ match_phrase_prefix: { [key]: value } });
    }
    const premiumFilters = filters(
      this.state.pageSizePremium,
      this.state.pagePremium,
      this.state.sortPremiumListing,
      1,
      adminOwner,
      manualRenewals ? "admin" : "",
      initialFilters,
      startAt || moment(),
      endAt || moment(),
    );

    const standardFilters = filters(
      this.state.pageSizeStandard,
      this.state.pageStandard,
      this.state.sortStandardListing,
      2,
      adminOwner,
      manualRenewals ? "admin" : "",
      initialFilters,
      startAt || moment(),
      endAt || moment(),
    );
    const basicPlusFilters = filters(
      this.state.pageSizeBasicPlus,
      this.state.pageBasicPlus,
      this.state.sortBasicPlusListing,
      3,
      adminOwner,
      manualRenewals ? "admin" : "",
      initialFilters,
      startAt || moment(),
      endAt || moment(),
    );
    const basicFilters = filters(
      this.state.pageSizeBasic,
      this.state.pageBasic,
      this.state.sortBasicListing,
      4,
      adminOwner,
      manualRenewals ? "admin" : "",
      initialFilters,
      startAt || moment(),
      endAt || moment(),
    );
    const freeFilters = filters(
      this.state.pageSizeFree,
      this.state.pageFree,
      this.state.sortFreeListing,
      5,
      adminOwner,
      manualRenewals ? "admin" : "",
      initialFilters,
      startAt || moment(),
      endAt || moment(),
    );

    let promisess = [
      this.props.getRenewals(premiumFilters.renewed[activeTab]),
      this.props.getRenewals(premiumFilters.upcoming[activeTab]),
      this.props.getRenewals(premiumFilters.pastDue[activeTab]),

      this.props.getRenewals(standardFilters.renewed[activeTab]),
      this.props.getRenewals(standardFilters.upcoming[activeTab]),
      this.props.getRenewals(standardFilters.pastDue[activeTab]),

      this.props.getRenewals(basicPlusFilters.renewed[activeTab]),
      this.props.getRenewals(basicPlusFilters.upcoming[activeTab]),
      this.props.getRenewals(basicPlusFilters.pastDue[activeTab]),

      this.props.getRenewals(basicFilters.renewed[activeTab]),
      this.props.getRenewals(basicFilters.upcoming[activeTab]),
      this.props.getRenewals(basicFilters.pastDue[activeTab]),

      this.props.getRenewals(freeFilters.renewed[activeTab]),
      this.props.getRenewals(freeFilters.upcoming[activeTab]),
      this.props.getRenewals(freeFilters.pastDue[activeTab]),
    ];

    Promise.all(promisess).then((res) => {
      let i = 0;
      let listings = [
        "premium",
        "premium",
        "premium",
        "standard",
        "standard",
        "standard",
        "basicPlus",
        "basicPlus",
        "basicPlus",
        "basic",
        "basic",
        "basic",
        "free",
        "free",
        "free",
      ];
      let renewalTabs = [
        "renewed",
        "upcoming",
        "pastDue",
        "renewed",
        "upcoming",
        "pastDue",
        "renewed",
        "upcoming",
        "pastDue",
        "renewed",
        "upcoming",
        "pastDue",
        "renewed",
        "upcoming",
        "pastDue",
      ];
      let counts = {
        renewed: 0,
        upcoming: 0,
        pastDue: 0,
      };

      for (let { data } of res) {
        counts[renewalTabs[i]] += data.nbHits;
        if (activeTabRenewal === renewalTabs[i]) {
          this.setState({
            [`${listings[i]}ListingData`]: data.hits,
            [`totalRecords${capitalizeFirstLetter(listings[i])}`]: data.nbHits,
            [`pageCount${capitalizeFirstLetter(listings[i])}`]:
              data.nbHits /
              this.state[`pageSize${capitalizeFirstLetter(listings[i])}`],
          });
        }
        this.setState({
          [`totalRecords${capitalizeFirstLetter(renewalTabs[i])}`]:
            counts[renewalTabs[i]],
        });
        i++;
      }

      if (this.gridApiPremium) this.gridApiPremium.hideOverlay();
      if (this.gridApiStandard) this.gridApiStandard.hideOverlay();
      if (this.gridApiBasicPlus) this.gridApiBasicPlus.hideOverlay();
      if (this.gridApiBasic) this.gridApiBasic.hideOverlay();
    });
  };
  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  state = {
    inProgress: null,
    startAt: null,
    endAt: null,
    activeTab: "today",
    searchActive: false,
    query: this.props.renewalsFilter,
    activeTabRenewal: "upcoming",
    activeTabListing: "premium",
    manualRenewals: false,
    defaultColDef: {
      sortable: true,
    },
    totalRecordsPremium: 0,
    totalRecordsStandard: 0,
    totalRecordsFree: 0,
    totalRecordsBasicPlus: 0,
    totalRecordsBasic: 0,
    pageSizePremium: 10,
    pageSizeStandard: 10,
    pageSizeBasicPlus: 10,
    pageSizeBasic: 10,
    pageSizeFree: 10,
    pagePremium: 1,
    pageStandard: 1,
    pageBasicPlus: 1,
    pageBasic: 1,
    pageFree: 1,
    pageCountPremium: 0,
    pageCountStandard: 0,
    pageCountBasic: 0,
    pageCountBasicPlus: 0,
    pageCountFree: 0,
    premiumListingData: [],
    standardListingData: [],
    basicPlusListingData: [],
    basicListingData: [],
    freeListingData: [],
    search: {},
    totalRecordsUpcoming: 0,
    totalRecordsRenewed: 0,
    totalRecordsPastDue: 0,
  };

  dateRef = createRef();
  pageSizes = [20, 25, 50, 100];

  onSubmitQuery = async () => {
    const { query, inProgress } = this.state;
    if (inProgress) {
      return;
    }
    this.setState({ inProgress: true });

    this.gridApiPremium.showLoadingOverlay();
    this.gridApiStandard.showLoadingOverlay();
    this.gridApiBasicPlus.showLoadingOverlay();
    this.gridApiBasic.showLoadingOverlay();

    const selectedFilters = (({
      idString,
      name,
      userIdString,
      email,
      phoneNumberPrimary,
      phoneNumberSecondary,
      city,
      state,
      startAt,
      endAt,
      activeTab,
      accountOwner,
      from,
      to,
    }) => ({
      idString,
      name,
      userIdString,
      email,
      phoneNumberPrimary,
      phoneNumberSecondary,
      city,
      state,
      startAt,
      endAt,
      activeTab,
      accountOwner,
      from,
      to,
      collapsed: true,
    }))(query);

    this.props.updateRenewalsFilter(selectedFilters);
    this.initialLoad();
    this.setState({ inProgress: false });
  };

  clearFlatPickr = () => {
    this.dateRef.current.flatpickr.clear();
    // this.clearAllFilters();
    this.setState(
      {
        query: {
          ...this.state.query,
          state: "",
          startAt: null,
          endAt: null,
          activeTab: "today",
          activeTabRenewal: "upcoming",
          activeTabListing: "premium",
        },
        startAt: null,
        endAt: null,
        pagePremium: 1,
        pageStandard: 1,
        pageBasicPlus: 1,
        pageBasic: 1,
        pageFree: 1,
        activeTab: "today",
        activeTabRenewal: "upcoming",
        activeTabListing: "premium",
      },
      () => this.onSubmitQuery(),
    );
  };

  handleDate = (values) => {
    this.setState(
      {
        pagePremium: 1,
        pageStandard: 1,
        pageBasicPlus: 1,
        pageBasic: 1,
        pageFree: 1,
        query: {
          ...this.state.query,
          startAt: values[0],
          endAt: values[1],
          activeTab: "custom",
          from: null,
          to: null,
        },
        activeTab: "custom",
        startAt: values[0],
        endAt: values[1],
      },
      () => this.onSubmitQuery(),
    );
  };

  onStateUpdate = (data) => {
    const state = data || null;
    this.setState({
      search: {
        ...this.state.search,
        state: state?.value || "",
      },
      query: { ...this.state.query, state },
    });
  };

  onAdminUpdate = (data) => {
    const accountOwner = data || null;
    this.setState(
      {
        pagePremium: 1,
        pageStandard: 1,
        pageBasicPlus: 1,
        pageBasic: 1,
        pageFree: 1,
        adminOwner: data?.value || null,
        query: { ...this.state.query, accountOwner },
      },
      () => this.onSubmitQuery(),
    );
  };

  updateActiveTab = (type) => {
    const activeTab = type || null;
    if (this.dateRef?.current?.flatpickr) {
      this.dateRef.current.flatpickr.clear();
    }
    this.setState(
      {
        activeTab,
        startAt: null,
        endAt: null,
        query: { ...this.state.query, activeTab, startAt: null, endAt: null },
      },
      () => {
        this.onSubmitQuery();
      },
    );
  };

  clearAllFilters = () => {
    this.props.clearRenewalsFilter();
    this.dateRef.current.flatpickr.clear();
    this.setState(
      {
        query: {
          state: "",
          startAt: null,
          endAt: null,
          activeTab: "today",
          activeTabRenewal: "upcoming",
          activeTabListing: "premium",
        },
        startAt: null,
        endAt: null,
        pagePremium: 1,
        pageStandard: 1,
        pageBasicPlus: 1,
        pageBasic: 1,
        pageFree: 1,
        activeTab: "today",
        activeTabRenewal: "upcoming",
        activeTabListing: "premium",
        search: {},
        manualRenewals: false,
      },
      () => this.onSubmitQuery(),
    );
  };

  handleSearchCollapse = () => {
    this.setState({
      searchActive: !this.state.searchActive,
    });
  };

  updateQuery = (e) => {
    this.setState({
      pagePremium: 1,
      pageStandard: 1,
      pageBasicPlus: 1,
      pageBasic: 1,
      pageFree: 1,
      search: {
        ...this.state.search,
        [e.target.name]: e.target.value,
      },
      query: {
        ...this.state.query,
        [e.target.name]: e.target.value,
      },
    });
  };

  toggleRenewalTab = (tab) => {
    if (this.state.activeTabRenewal !== tab) {
      this.setState(
        {
          activeTabRenewal: tab,
          pagePremium: 1,
          pageStandard: 1,
          pageBasicPlus: 1,
          pageBasic: 1,
          pageFree: 1,
        },
        () => this.onSubmitQuery(),
      );
    }
  };

  toggleListingTab = (tab) => {
    if (this.state.activeTabListing !== tab) {
      this.setState({
        activeTabListing: tab,
        pagePremium: 1,
        pageStandard: 1,
        pageBasicPlus: 1,
        pageBasic: 1,
        pageFree: 1,
      });
      // if (this.state.listingSearchValue) {
      //   this.updateListingByType(tab);
      // }
    }
  };

  generateTabsRenewals = () => {
    const tabs = [
      {
        label: "UPCOMING",
        type: "upcoming",
        total: this.state.totalRecordsUpcoming,
      },
      {
        label: "RENEWED",
        type: "renewed",
        total: this.state.totalRecordsRenewed,
      },
      {
        label: "PAST DUE",
        type: "pastDue",
        total: this.state.totalRecordsPastDue,
      },
    ];

    return tabs.map((itm) => (
      <NavItem key={itm.type}>
        <NavLink
          className={classnames({
            active: this.state.activeTabRenewal === itm.type,
          })}
          onClick={() => {
            this.toggleRenewalTab(itm.type);
          }}
        >
          {itm.label} ({itm.total})
        </NavLink>
      </NavItem>
    ));
  };

  generateTabsListing = () => {
    const tabs = [
      {
        label: "Premium",
        type: "premium",
        total: this.state.totalRecordsPremium,
      },
      {
        label: "Standard",
        type: "standard",
        total: this.state.totalRecordsStandard,
      },
      {
        label: "Basic plus",
        type: "basicPlus",
        total: this.state.totalRecordsBasicPlus,
      },
      {
        label: "Basic",
        type: "basic",
        total: this.state.totalRecordsBasic,
      },
      {
        label: "Free",
        type: "free",
        total: this.state.totalRecordsFree,
      },
    ];

    return tabs.map((itm) => (
      <NavItem key={itm.type}>
        <NavLink
          className={classnames({
            active: this.state.activeTabListing === itm.type,
          })}
          onClick={() => {
            this.toggleListingTab(itm.type);
          }}
        >
          {itm.label} ({itm.total})
        </NavLink>
      </NavItem>
    ));
  };

  onManualRenewalsCheck = () => {
    this.setState(
      {
        manualRenewals: !this.state.manualRenewals,
      },
      () => this.initialLoad(),
    );
  };

  onGridReadyPremium = (params) => {
    this.gridApiPremium = params.api;
    this.gridColumnApiPremium = params.columnApi;
    if (localStorage.getItem("premiumColumnState") !== null) {
      this.gridColumnApiPremium.setColumnState(
        JSON.parse(localStorage.getItem("premiumColumnState")),
      );
    }
  };

  onGridReadyStandard = (params) => {
    this.gridApiStandard = params.api;
    this.gridColumnApiStandard = params.columnApi;

    if (localStorage.getItem("standardColumnState") !== null) {
      this.gridColumnApiStandard.setColumnState(
        JSON.parse(localStorage.getItem("standardColumnState")),
      );
    }
  };

  onGridReadyBasicPlus = (params) => {
    this.gridApiBasicPlus = params.api;
    this.gridColumnApiBasicPlus = params.columnApi;

    if (localStorage.getItem("basicPlusColumnState") !== null) {
      this.gridColumnApiBasicPlus.setColumnState(
        JSON.parse(localStorage.getItem("basicPlusColumnState")),
      );
    }
  };

  onGridReadyBasic = (params) => {
    this.gridApiBasic = params.api;
    this.gridColumnApiBasic = params.columnApi;

    if (localStorage.getItem("basicColumnState") !== null) {
      this.gridColumnApiBasic.setColumnState(
        JSON.parse(localStorage.getItem("basicColumnState")),
      );
    }
  };

  onGridReadyFree = (params) => {
    this.gridApiFree = params.api;
    this.gridColumnApiFree = params.columnApi;

    if (localStorage.getItem("freeColumnState") !== null) {
      this.gridColumnApiFree.setColumnState(
        JSON.parse(localStorage.getItem("freeColumnState")),
      );
    }
  };

  saveStatePremium = () => {
    let renewalsPremiumColumnState = this.gridColumnApiPremium.getColumnState();
    localStorage.setItem(
      "renewalsPremiumColumnState",
      JSON.stringify(renewalsPremiumColumnState),
    );
  };

  saveStateStandard = () => {
    let renewalsStandardColumnState =
      this.gridColumnApiStandard.getColumnState();
    localStorage.setItem(
      "renewalsStandardColumnState",
      JSON.stringify(renewalsStandardColumnState),
    );
  };

  saveStateBasicPlus = () => {
    let renewalsBasicPlusColumnState =
      this.gridColumnApiBasicPlus.getColumnState();
    localStorage.setItem(
      "renewalsBasicPlusColumnState",
      JSON.stringify(renewalsBasicPlusColumnState),
    );
  };

  saveStateBasic = () => {
    let renewalsBasicColumnState = this.gridColumnApiBasic.getColumnState();
    localStorage.setItem(
      "renewalsBasicColumnState",
      JSON.stringify(renewalsBasicColumnState),
    );
  };

  saveStateFree = () => {
    let renewalsFreeColumnState = this.gridColumnApiFree.getColumnState();
    localStorage.setItem(
      "renewalsFreeColumnState",
      JSON.stringify(renewalsFreeColumnState),
    );
  };

  onPageChangedPremium = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pagePremium: pageNum }, () => {
      //this.updatePremiumListingResults();
      this.onSubmitQuery();
    });
  };

  onPageChangedStandard = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageStandard: pageNum }, () => {
      //this.updateStandardListingResults();
      this.onSubmitQuery();
    });
  };

  onPageChangedBasicPlus = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageBasicPlus: pageNum }, () => {
      //this.updateBasicPlusListingResults();
      this.onSubmitQuery();
    });
  };

  onPageChangedBasic = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageBasic: pageNum }, () => {
      //this.updateBasicListingResults();
      this.onSubmitQuery();
    });
  };

  onPageChangedFree = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageFree: pageNum }, () => {
      //this.updateFreeListingResults();
      this.onSubmitQuery();
    });
  };

  filterSize = (tablePageSize, pageSize) => {
    this.setState({ [tablePageSize]: pageSize }, () => this.onSubmitQuery());
  };
  resetApiPremium = () => {
    this.gridApiPremium = null;
  };

  resetApiStandard = () => {
    this.gridApiStandard = null;
  };

  resetApiBasicPlus = () => {
    this.gridApiBasicPlus = null;
  };

  resetApiBasic = () => {
    this.gridApiBasic = null;
  };

  resetApiFree = () => {
    this.gridApiFree = null;
  };

  onSortChanged = (params, type) => {
    //   [{ colId: "adminAccountOwner_1", sort: "asc" }];
    if (!params?.api?.getSortModel()) return;

    let sortModel = params.api.getSortModel();
    let newSort = [];

    if (sortModel.length) {
      for (let { colId, sort } of sortModel) {
        let newColId = colId.replace(/[0-9]/g, "");
        if (newColId.endsWith("_")) newColId = newColId.replace(/_/g, "");
        newSort.push({ colId: newColId, sort });
      }
    }

    switch (type) {
      case sortTypes.PREMIUM:
        return this.setState(
          {
            pagePremium: 1,
            pageStandard: 1,
            pageBasicPlus: 1,
            pageBasic: 1,
            pageFree: 1,
            sortPremiumListing: newSort,
          },
          () => this.onSubmitQuery(),
        );

      case sortTypes.BASIC:
        return this.setState(
          {
            pagePremium: 1,
            pageStandard: 1,
            pageBasicPlus: 1,
            pageBasic: 1,
            pageFree: 1,
            sortBasicListing: newSort,
          },
          () => this.onSubmitQuery(),
        );

      case sortTypes.BASIC_PLUS:
        return this.setState(
          {
            pagePremium: 1,
            pageStandard: 1,
            pageBasicPlus: 1,
            pageBasic: 1,
            pageFree: 1,
            sortBasicPlusListing: newSort,
          },
          () => this.onSubmitQuery(),
        );

      case sortTypes.STANDARD:
        return this.setState(
          {
            pagePremium: 1,
            pageStandard: 1,
            pageBasicPlus: 1,
            pageBasic: 1,
            pageFree: 1,
            sortStandardListing: newSort,
          },
          () => this.onSubmitQuery(),
        );

      case sortTypes.FREE:
        return this.setState(
          {
            pagePremium: 1,
            pageStandard: 1,
            pageBasicPlus: 1,
            pageBasic: 1,
            pageFree: 1,
            sortFreeListing: newSort,
          },
          () => this.onSubmitQuery(),
        );

      default:
        return null;
    }
  };

  onRowClicked = ({ data }) => {
    return history.push({
      pathname: `/accounts/${data.userId}`,
      search: `?adId=${data.id}`,
    });
  };

  render() {
    const {
      searchActive,
      query,
      inProgress,
      searchType,
      manualRenewals,
      activeTabListing,
      defaultColDef,
      totalRecordsPremium,
      totalRecordsStandard,
      totalRecordsBasicPlus,
      totalRecordsBasic,
      totalRecordsFree,
      pageSizePremium,
      pageSizeStandard,
      pageSizeBasicPlus,
      pageSizeBasic,
      pageSizeFree,
      pagePremium,
      pageStandard,
      pageBasicPlus,
      pageBasic,
      pageFree,
      pageCountPremium,
      pageCountStandard,
      pageCountBasicPlus,
      pageCountBasic,
      pageCountFree,
      premiumListingData,
      standardListingData,
      basicPlusListingData,
      basicListingData,
      freeListingData,
      activeTabRenewal,
    } = this.state;

    let tabs = [
      {
        label: "Today",
        type: "today",
      },
      {
        label: "Week",
        type: "week",
      },
      {
        label: "Month",
        type: "month",
      },
    ];

    return (
      <div className="renewals-wrapper renewals-flex-wrapper">
        <Row className="app-user-list">
          <Col
            sm="12"
            style={{
              paddingLeft: 0,
            }}
          >
            <Card>
              <div>
                <div className="d-flex justify-content-between">
                  <span className="fts-renewals-header">Renewals</span>
                  <div className="header-renewals-buttons">
                    <div className="admin-picker" style={{ width: 240 }}>
                      {checkPermissions(
                        [{ id: 4, subCat: [56] }],
                        this.props.adminPermissions,
                      ) && (
                        <AdminPicker
                          name={"test"}
                          placeholder={"Specify account owner"}
                          value={query.accountOwner}
                          onChange={(option) => {
                            if (option?.value) {
                              this.onAdminUpdate(option);
                            } else {
                              this.onAdminUpdate(null);
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className="nav">
                      {tabs.map((itm) => {
                        return (
                          <button
                            key={itm.type}
                            className={`tab ${
                              query.activeTab === itm.type
                                ? "selected"
                                : "unselected"
                            }`}
                            onClick={() => {
                              this.updateActiveTab(itm.type);
                            }}
                          >
                            {itm.label}
                          </button>
                        );
                      })}
                    </div>
                    <div className="range-picker">
                      <div className="range">
                        <div className="position-relative has-icon-right">
                          <div className="fts-date-and-time">
                            <div
                              id="datepickerWrapperFrom"
                              className="u-datepicker input-group"
                            >
                              <Flatpickr
                                ref={this.dateRef}
                                placeholder="Select date range"
                                className="fts-date-picker form-control date-picker"
                                options={{
                                  mode: "range",
                                  dateFormat: "M d, Y",
                                  locale: {
                                    rangeSeparator: " - ",
                                  },
                                  defaultDate: [
                                    query.startAt
                                      ? moment(query.startAt).format("ll")
                                      : null,
                                    query.endAt
                                      ? moment(query.endAt).format("ll")
                                      : null,
                                  ],
                                }}
                                onChange={(values) => {
                                  if (values.length === 2) {
                                    this.handleDate(values);
                                  }
                                }}
                              />
                            </div>
                          </div>

                          {query.startAt ? (
                            <div
                              className="form-control-position clear-calendar-button"
                              onClick={() => {
                                if (this.dateRef?.current?.flatpickr) {
                                  this.clearFlatPickr();
                                }
                              }}
                            >
                              <Icon name="ClearCalendar" size={16} />
                            </div>
                          ) : (
                            <div className="form-control-position">
                              <Icon name="Calendar" size={17} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`renewals-search-button ${
                        searchActive ? "renewals-search-active" : ""
                      }`}
                      onClick={() => {
                        this.handleSearchCollapse();
                      }}
                    >
                      <Icon name="Search" />
                    </div>
                  </div>
                </div>
                <Collapse isOpen={searchActive} className="search-box">
                  <Row>
                    <Col>
                      <SearchInput
                        onInputUpdate={this.updateQuery}
                        type="number"
                        name="idString"
                        value={query.idString || ""}
                        placeholder="Listing ID"
                      />
                    </Col>
                    <Col>
                      <SearchInput
                        onInputUpdate={this.updateQuery}
                        type="text"
                        name="name"
                        value={query.name || ""}
                        placeholder="Company name"
                      />
                    </Col>
                    <Col>
                      <SearchInput
                        onInputUpdate={this.updateQuery}
                        type="number"
                        name="userIdString"
                        value={query.userIdString || ""}
                        placeholder="Account ID"
                      />
                    </Col>
                    <Col>
                      <SearchInput
                        onInputUpdate={this.updateQuery}
                        type="email"
                        name="email"
                        value={query.email || ""}
                        placeholder="Email address"
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginTop: "24px",
                      marginBottom: "24px",
                    }}
                  >
                    <Col>
                      <SearchInput
                        masked
                        mask={phoneNumberMask}
                        type="text"
                        name="phoneNumberPrimary"
                        onInputUpdate={this.updateQuery}
                        value={query.phoneNumberPrimary || ""}
                        placeholder="Phone (Primary)"
                      />
                    </Col>
                    <Col>
                      <SearchInput
                        masked
                        mask={phoneNumberMask}
                        type="text"
                        name="phoneNumberSecondary"
                        onInputUpdate={this.updateQuery}
                        value={query.phoneNumberSecondary || ""}
                        placeholder="Phone (Secondary)"
                      />
                    </Col>
                    <Col>
                      <SearchInput
                        onInputUpdate={this.updateQuery}
                        type="text"
                        name="city"
                        value={query.city || ""}
                        placeholder="Enter City"
                      />
                    </Col>
                    <Col>
                      <div className="states-picker">
                        <StandaloneStatesPicker
                          name={"state"}
                          value={query.state || ""}
                          placeholder="Select state"
                          onChange={(option) => {
                            this.onStateUpdate(option);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-end justify-content-end">
                    <Button
                      type="button"
                      className="clear-button"
                      color="white"
                      onClick={this.clearAllFilters}
                      disabled={inProgress}
                    >
                      Clear
                    </Button>
                    <Button
                      type="button"
                      className="float-right search-button ml-0"
                      color="primary"
                      onClick={this.onSubmitQuery}
                    >
                      Search
                    </Button>
                  </div>
                </Collapse>

                <Row className="app-user-list">
                  <Col sm="12" className="renewals-tables">
                    <TabContent activeTab={searchType}>
                      <Nav className="fts-nav-tabs mb-0" tabs>
                        {this.generateTabsRenewals()}
                      </Nav>
                      <TabContent activeTab={activeTabListing}>
                        <Nav className="fts-nav-tabs mb-0 mt-2" tabs>
                          {this.generateTabsListing()}
                          <div
                            className="manual-renewals-checkbox"
                            onClick={this.onManualRenewalsCheck}
                          >
                            <Checkbox defaultChecked={manualRenewals} />
                            <span className="checkbox-label no-select">
                              Show manual renewals only
                            </span>
                          </div>
                        </Nav>
                        <TabPane tabId="premium">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.PREMIUM)
                            }
                            isSearch
                            pageSizes={this.pageSizes}
                            totalRecords={totalRecordsPremium}
                            currentPageSize={pageSizePremium}
                            pageSizePropName={"pageSizePremium"}
                            currentPage={pagePremium}
                            pageCount={pageCountPremium}
                            onGridReadyInit={this.onGridReadyPremium}
                            saveState={this.saveStatePremium}
                            rowData={premiumListingData}
                            onPageChange={this.onPageChangedPremium}
                            resetApi={this.resetApiPremium}
                            columnDefs={
                              activeTabRenewal === "renewed"
                                ? this.listingColumnsPremium
                                : this.listingColumnsPremiumUpcoming
                            }
                            filterSize={this.filterSize}
                            onRowClicked={this.onRowClicked}
                            renewalsTab={activeTabRenewal !== "upcoming"}
                            upcomingTab={activeTabRenewal === "upcoming"}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="standard">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.STANDARD)
                            }
                            isSearch
                            pageSizes={this.pageSizes}
                            totalRecords={totalRecordsStandard}
                            currentPageSize={pageSizeStandard}
                            pageSizePropName={"pageSizeStandard"}
                            currentPage={pageStandard}
                            pageCount={pageCountStandard}
                            onGridReadyInit={this.onGridReadyStandard}
                            saveState={this.saveStateStandard}
                            rowData={standardListingData}
                            onPageChange={this.onPageChangedStandard}
                            resetApi={this.resetApiStandard}
                            columnDefs={
                              activeTabRenewal === "renewed"
                                ? this.listingColumnsStandard
                                : this.listingColumnsStandardUpcoming
                            }
                            filterSize={this.filterSize}
                            onRowClicked={this.onRowClicked}
                            renewalsTab={activeTabRenewal !== "upcoming"}
                            upcomingTab={activeTabRenewal === "upcoming"}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="basicPlus">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.BASIC_PLUS)
                            }
                            isSearch
                            pageSizes={this.pageSizes}
                            totalRecords={totalRecordsBasicPlus}
                            currentPageSize={pageSizeBasicPlus}
                            pageSizePropName={"pageSizeBasicPlus"}
                            currentPage={pageBasicPlus}
                            pageCount={pageCountBasicPlus}
                            onGridReadyInit={this.onGridReadyBasicPlus}
                            saveState={this.saveStateBasicPlus}
                            rowData={basicPlusListingData}
                            onPageChange={this.onPageChangedBasicPlus}
                            resetApi={this.resetApiBasicPlus}
                            columnDefs={
                              activeTabRenewal === "renewed"
                                ? this.listingColumnsBasicPlus
                                : this.listingColumnsBasicPlusUpcoming
                            }
                            filterSize={this.filterSize}
                            onRowClicked={this.onRowClicked}
                            renewalsTab={activeTabRenewal !== "upcoming"}
                            upcomingTab={activeTabRenewal === "upcoming"}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="basic">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.BASIC)
                            }
                            isSearch
                            pageSizes={this.pageSizes}
                            totalRecords={totalRecordsBasic}
                            currentPageSize={pageSizeBasic}
                            pageSizePropName={"pageSizeBasic"}
                            currentPage={pageBasic}
                            pageCount={pageCountBasic}
                            onGridReadyInit={this.onGridReadyBasic}
                            saveState={this.saveStateBasic}
                            rowData={basicListingData}
                            onPageChange={this.onPageChangedBasic}
                            resetApi={this.resetApiBasic}
                            columnDefs={
                              activeTabRenewal === "renewed"
                                ? this.listingColumnsBasic
                                : this.listingColumnsBasicUpcoming
                            }
                            filterSize={this.filterSize}
                            onRowClicked={this.onRowClicked}
                            renewalsTab={activeTabRenewal !== "upcoming"}
                            upcomingTab={activeTabRenewal === "upcoming"}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="free">
                          <FTSDataTable
                            onSortChanged={(params) =>
                              this.onSortChanged(params, sortTypes.FREE)
                            }
                            isSearch
                            pageSizes={this.pageSizes}
                            totalRecords={totalRecordsFree}
                            currentPageSize={pageSizeFree}
                            pageSizePropName={"pageSizeFree"}
                            currentPage={pageFree}
                            pageCount={pageCountFree}
                            onGridReadyInit={this.onGridReadyFree}
                            saveState={this.saveStateFree}
                            rowData={freeListingData}
                            onPageChange={this.onPageChangedFree}
                            resetApi={this.resetApiFree}
                            columnDefs={
                              activeTabRenewal === "renewed"
                                ? this.listingColumnsFree
                                : this.listingColumnsFreeUpcoming
                            }
                            filterSize={this.filterSize}
                            onRowClicked={this.onRowClicked}
                            renewalsTab={activeTabRenewal !== "upcoming"}
                            upcomingTab={activeTabRenewal === "upcoming"}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                      </TabContent>
                    </TabContent>
                  </Col>
                </Row>
                <ContextMenu
                  style={{ minWidth: 250, zIndex: 1000 }}
                  id="contextmenu"
                  preventHideOnScroll={false}
                >
                  <MenuItem
                    onClick={() =>
                      navigator.clipboard.writeText(
                        this.state.collectedDataForCopy,
                      )
                    }
                  >
                    <FaRegCopy size={18} className="copy" />
                    <span className="ml-1 text-dark">Copy</span>
                  </MenuItem>
                  {this.state.call && this.state.collectedDataForCopy && (
                    <MenuItem>
                      <a
                        className="text-decoration-none text-dark d-flex align-items-center w-100"
                        href={`tel:${this.state.collectedDataForCopy}`}
                      >
                        <Icons name="PhoneBlue" />
                        <div className="ml-1">
                          Call - {this.state.collectedDataForCopy}
                        </div>
                      </a>
                    </MenuItem>
                  )}
                </ContextMenu>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    renewalsFilter: state.renewalsFilter,
    employees: state.essentialLists.employees,
    adminPermissions: state.auth.login.permissions,
    userData: state.auth.login.values,
  };
};

export default connect(mapStateToProps, {
  updateRenewalsFilter,
  clearRenewalsFilter,
  getRenewals,
})(Renewals);
