import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import ReactPaginate from "react-paginate";
import { ChevronUp } from "react-feather";
import Icon from "../Icons";
import { PAGE_SIZES } from "../../../utility/constants";

const FTSAgGridDataTablePagination = ({
  currentPageSize,
  handleFilterSize,
  pageCount,
  currentPage,
  handlePageChange,
}) => {
  return (
    <div className="fts-pagination ag-grid-table-alternate">
      <div className="sort-dropdown mr-1">
        <UncontrolledDropdown className="ag-dropdown no-chevron p-0">
          <DropdownToggle tag="div" className="p-1">
            Show entries: {currentPageSize}
            <ChevronUp className="ml-50" size={15} />
          </DropdownToggle>
          <DropdownMenu right>
            {PAGE_SIZES.map((pageSize, index) => (
              <DropdownItem
                tag="div"
                onClick={() => handleFilterSize(pageSize)}
                key={index}
              >
                {pageSize}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <ReactPaginate
        previousLabel={<Icon name="ArrowLeft" size={20} />}
        nextLabel={
          <span
            style={{
              transform: "rotate(180deg)",
            }}
          >
            <Icon name="ArrowLeft" size={20} />
          </span>
        }
        breakLabel={".."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        forcePage={currentPage - 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination ml-auto"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default FTSAgGridDataTablePagination;
