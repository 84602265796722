import FTSAxios from "../../../axios/fts.instance";

export const getAllPermissions = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      FTSAxios.get("admin/lists/permissions")
        .then((response) => {
          resolve(response);
          dispatch({
            type: "GET_PERMISSIONS_DATA",
            data: response.data,
            totalPages: 1,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const filterPermissions = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_PERMISSIONS_DATA", value });
};
