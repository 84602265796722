function arrayUnique(arr, areEqual) {
  const arrCopy = arr.concat();
  for (let i = 0; i < arrCopy.length; ++i) {
    for (let j = i + 1; j < arrCopy.length; ++j) {
      if (areEqual(arrCopy[i], arrCopy[j])) {
        arrCopy.splice(j--, 1);
      }
    }
  }

  return arrCopy;
}

export default arrayUnique;
