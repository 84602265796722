import React, { Component } from "react";
import { Row, Col, FormGroup, CustomInput } from "reactstrap";
// import { Check } from "react-feather";
import { ErrorMessage, Field } from "formik";
import classnames from "classnames";

// import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";
import Checkbox from "../Checkbox";
import Divider from "../Divider";

import "../../../assets/scss/components/fts/category-dropdown-hybrid.scss";
import TextError from "./TextError";

class CategoryDropdown extends Component {
  checkSubs = (obj) => {
    let res = Object.values(obj).includes(true);
    return res;
  };

  render() {
    const { editMode, id, label, subCategories, cur, right, changedServices } =
      this.props;
    const additionalProps = !editMode ? { disabled: true } : {};

    const validateSubcategories = () => {
      let error;
      if (
        cur.current.values.categories &&
        cur.current.values.categories["category_" + id]
      ) {
        if (
          typeof cur.current.values.categories["category_" + id].subCats ===
            "undefined" &&
          cur.current.values.categories["category_" + id].active === true
        ) {
          error =
            "Please select at least one subcategory or unselect this category type.";
        } else if (
          cur.current.values.categories["category_" + id].active === true &&
          cur.current.values.categories["category_" + id].subCats
        ) {
          let arr = Object.values(
            cur.current.values.categories["category_" + id].subCats
          );
          let res = arr.includes(true);
          if (res === false) {
            error =
              "Please select at least one subcategory or unselect this category type.";
          }
        }
      }
      return error;
    };

    return (
      <Field
        name={`categories.category_${id}`}
        {...additionalProps}
        validate={validateSubcategories}
      >
        {({ field: { name, value }, form }) => {
          return (value?.active && !editMode) || editMode ? (
            <>
              <Col
                sm="6"
                xl="4"
                className={classnames("category-wrapper-col", {
                  disabled: !value?.active && !editMode,
                })}
              >
                <div
                  className={classnames("category-wrapper", {
                    disabled: !editMode,
                    active: value?.active,
                  })}
                >
                  {editMode ? (
                    <Row>
                      <Col xs="8" className="category-label">
                        {label}
                      </Col>
                      <Col xs="4">
                        <div className="float-right">
                          <CustomInput
                            id={name}
                            type="switch"
                            defaultChecked={value?.active}
                            onClick={(e) => {
                              form.setFieldValue(
                                `${name}.active`,
                                e.target.checked
                              );
                              let temp = cur.current.errors;
                              if (temp && temp.categories) {
                                delete temp.categories["category_" + id];
                                var len = Object.keys(temp.categories).length;
                                if (len === 0) {
                                  delete temp.categories;
                                }
                              }
                              cur.current.setErrors(temp);
                            }}
                            disabled={!editMode}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Divider textColor="primary" position="left">
                      {label}
                    </Divider>
                  )}

                  <div
                    className={classnames("animation-wrapper", {
                      active: value?.active || !editMode,
                    })}
                  >
                    <div
                      className={classnames("inner-wrapper", {
                        active: value?.active || !editMode,
                      })}
                    >
                      <div className="subtitle">
                        {`${label} sub categories`}{" "}
                        <ErrorMessage name={name} component={TextError} />
                      </div>

                      {subCategories.map(({ id: subCatId, label }, index) => (
                        <FormGroup
                          style={{
                            paddingTop: 3,
                            paddingBottom:
                              index === subCategories.length - 1 ? 0 : 5,
                            paddingLeft: "0.5rem",
                          }}
                          check
                          key={subCatId}
                          className={`${
                            right && changedServices.includes(subCatId)
                              ? "changed-checkbox"
                              : null
                          }`}
                        >
                          <Checkbox
                            label={label}
                            name={`${name}.subCats.subCategory_${subCatId}`}
                            defaultChecked={
                              !!value?.subCats &&
                              !!value.subCats[`subCategory_${subCatId}`]
                            }
                            checked={
                              !!value?.subCats &&
                              !!value.subCats[`subCategory_${subCatId}`]
                            }
                            onChange={(e) => {
                              form.setFieldValue(
                                `${name}.subCats.subCategory_${subCatId}`,
                                e.target.checked
                              );
                              let temp = cur.current.errors;
                              if (temp.categories) {
                                delete temp.categories["category_" + id];
                                var len = Object.keys(temp.categories).length;
                                if (len === 0) {
                                  delete temp.categories;
                                }
                              }
                              cur.current.setErrors(temp);
                            }}
                            disabled={!editMode}
                          />
                        </FormGroup>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            </>
          ) : null;
        }}
      </Field>
    );
  }
}

export default React.memo(CategoryDropdown);
