import React from "react";
import SearchInputNew from "../search-input-new";
import { Button, Spinner } from "reactstrap";
import Icon from "../Icons";

const FTSAgGridDataTableHeader = ({
  tableHeader,
  tableSubHeader,
  searchValue,
  hasSearch,
  handleSearchInput,
  searchInputName,
  searchPlaceholder,
  buttonActionHandler,
  buttonActionColor,
  buttonActionDisabled,
  buttonActionInProgress,
  buttonActionIcon,
  buttonActionLabel,
}) => {
  return (
    <div className="FTS-ag-grid-data-table-header">
      <div className="FTS-ag-grid-data-table-header-left">
        <span className="FTS-ag-grid-data-table-header-left-main-header">
          {tableHeader}
        </span>
        <span className="FTS-ag-grid-data-table-header-left-sub-header">
          {tableSubHeader}
        </span>
      </div>
      <div className="FTS-ag-grid-data-table-header-right">
        {hasSearch && (
          <SearchInputNew
            value={searchValue}
            onInputUpdate={handleSearchInput}
            type="text"
            name={searchInputName}
            placeholder={searchPlaceholder}
          />
        )}
        {buttonActionHandler && (
          <Button
            onClick={buttonActionHandler}
            color={buttonActionColor}
            className="d-flex align-items-center justify-space-center mr-1"
            disabled={buttonActionDisabled}
          >
            {buttonActionInProgress ? (
              <Spinner size={"sm"} />
            ) : (
              <>
                <Icon name={buttonActionIcon} />
                <span className={"ml-1"}>{buttonActionLabel}</span>
              </>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FTSAgGridDataTableHeader;
