import React from "react";
import FileVersion from "./FileVersion";

const FileVersions = ({ versions, previewURL, onChangePreview }) => {
  if (!versions?.length) {
    return null;
  }

  const fileVersions = versions.sort(function (a, b) {
    var keyA = new Date(a.createdAt),
      keyB = new Date(b.createdAt);

    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });

  return fileVersions.map((version) => (
    <FileVersion
      key={version.version}
      data={version}
      onClick={onChangePreview}
      current={previewURL === version.path}
    />
  ));
};

export default FileVersions;
