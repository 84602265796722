import { toast } from "react-toastify";
import FTSAxios from "../../../axios/fts.instance";
import { handleResponseError } from "../../../handlers/error";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  formatAltContactRequest,
  formatAltContactResponse,
  formatAltContactsResponse,
} from "./helpers";
import {
  deleteAltContactFailedAction,
  deleteAltContactSuccessAction,
  getAltContactsByIdFailedAction,
  getAltContactsByIdSuccessAction,
  saveAltContactsFailedAction,
  saveAltContactsSuccessAction,
  updateAltContactFailedAction,
  updateAltContactSuccessAction,
} from "../../actions/v2/altContacts";
import {
  DELETE_ALT_CONTACT_REQUEST,
  GET_ALT_CONTACTS_BY_ID_REQUEST,
  SAVE_ALT_CONTACTS_REQUEST,
  selectAltContacts,
  UPDATE_ALT_CONTACT_REQUEST,
} from "../../reducers/v2/altContacts";
import {
  ALT_CONTACT_REMOVED_SUCCESSFULLY,
  ALT_CONTACT_UPDATED_SUCCESSFULLY,
  DEFAULT_CREATE_ERROR_MSG,
  DEFAULT_DELETE_ERROR_MSG,
  DEFAULT_GET_ERROR_MSG,
  DEFAULT_UPDATE_ERROR_MSG,
} from "./constants";

function* getAltContactsByIdSaga(action) {
  try {
    const result = yield call(
      FTSAxios.get,
      `admin/contact/get/user/0/${action.payload}`,
    );
    const formattedData = formatAltContactResponse(result.data);
    yield put(getAltContactsByIdSuccessAction(formattedData));
  } catch (e) {
    const msg = handleResponseError(e, true, DEFAULT_GET_ERROR_MSG);
    yield put(getAltContactsByIdFailedAction(msg));
  }
}

function* updateAccountAltContactSaga(action) {
  try {
    const payload = formatAltContactRequest(action.payload);
    yield call(FTSAxios.put, `admin/contact/${action.payload.id}`, payload);
    yield put(updateAltContactSuccessAction());
    toast.success(ALT_CONTACT_UPDATED_SUCCESSFULLY);
  } catch (e) {
    handleResponseError(e, true, DEFAULT_UPDATE_ERROR_MSG);
    yield put(updateAltContactFailedAction());
  }
}

function* removeAccountAltContactSaga(action) {
  try {
    yield call(FTSAxios.delete, `admin/contact/${action.payload}`);
    yield put(deleteAltContactSuccessAction());
    toast.success(ALT_CONTACT_REMOVED_SUCCESSFULLY);
  } catch (e) {
    handleResponseError(e, true, DEFAULT_DELETE_ERROR_MSG);
    yield put(deleteAltContactFailedAction());
  }
}

function* saveAltContactsSaga({ payload: userId }) {
  try {
    const altContacts = yield select(selectAltContacts);
    for (const altContact of altContacts) {
      if (!altContact.isSaved) {
        const payload = formatAltContactRequest({ ...altContact, userId });
        yield call(FTSAxios.post, "admin/contact/create", payload);
      }
    }
    const contacts = formatAltContactsResponse(altContacts, userId);
    yield put(saveAltContactsSuccessAction(contacts));
  } catch (e) {
    handleResponseError(e, true, DEFAULT_CREATE_ERROR_MSG);
    yield put(saveAltContactsFailedAction());
  }
}

export default function* altContactsWatcher() {
  yield takeEvery(GET_ALT_CONTACTS_BY_ID_REQUEST, getAltContactsByIdSaga);
  yield takeEvery(UPDATE_ALT_CONTACT_REQUEST, updateAccountAltContactSaga);
  yield takeEvery(DELETE_ALT_CONTACT_REQUEST, removeAccountAltContactSaga);
  yield takeEvery(SAVE_ALT_CONTACTS_REQUEST, saveAltContactsSaga);
}
