import FTSAxios from "../../../axios/fts.instance";

export const sendProof = (payload) => {
  return () => {
    return FTSAxios.post("/admin/proofs", payload);
  };
};
export const resendPendingProofs = (userId) => {
  return () => {
    return FTSAxios.get("/admin/proofs/resend-pending", { params: { userId } });
  };
};

export const sendMultipleProofs = (payload) => {
  return () => {
    return FTSAxios.post("/admin/proofs/multiple", payload);
  };
};

export const getProofs = (payload) => {
  return () => {
    return FTSAxios.get("/admin/proofs", { params: payload });
  };
};

export const getProofsCounters = (payload) => {
  return () => {
    return FTSAxios.get("/admin/proofs/counters", { params: payload });
  };
};

export const updateProof = (params) => {
  const { proofId, data } = params;

  return () => {
    return FTSAxios.put(`/admin/proofs/${proofId}`, data);
  };
};

export const getProofById = (proofId) => {
  return () => {
    return FTSAxios.get(`/admin/proof/${proofId}`);
  };
};
