export function formatAltContactResponse(data) {
  return data.map((contact) => ({
    id: contact.id,
    userId: contact.userId,
    altContactFirstName: contact.firstName,
    altContactLastName: contact.lastName,
    altContactEmail: contact.email,
    note: contact.note,
    altContactJobTitle: contact.jobTitle,
    altContactPhoneNumberSecondary: contact.phoneNumberSecondary,
    altContactPhoneNumber: contact.phoneNumber,
    altContactMobileNumber: contact.mobileNumber,
    primaryMarketingOptIn: contact.primaryMarketingOptIn,
    primaryRenewalsOptIn: contact.primaryRenewalsOptIn,
    primaryUpdatesOptIn: contact.primaryUpdatesOptIn,
    mobileMarketingOptIn: !!contact.mobileMarketingOptIn,
    mobileRenewalsOptIn: !!contact.mobileRenewalsOptIn,
    mobileUpdatesOptIn: !!contact.mobileUpdatesOptIn,
    isSaved: true,
  }));
}

export function formatAltContactRequest(altContact) {
  return {
    firstName: altContact.altContactFirstName,
    lastName: altContact.altContactLastName,
    email: altContact.altContactEmail,
    note: altContact.note,
    noteType: "",
    jobTitle: altContact.altContactJobTitle,
    userId: altContact.userId,
    phoneNumberSecondary: altContact.altContactPhoneNumberSecondary,
    phoneNumber: altContact.altContactPhoneNumber,
    mobileNumber: altContact.altContactMobileNumber,
    primaryMarketingOptIn: altContact.primaryMarketingOptIn,
    primaryRenewalsOptIn: altContact.primaryRenewalsOptIn,
    primaryUpdatesOptIn: altContact.primaryUpdatesOptIn,
    mobileMarketingOptIn: altContact.mobileMarketingOptIn,
    mobileRenewalsOptIn: altContact.mobileRenewalsOptIn,
    mobileUpdatesOptIn: altContact.mobileUpdatesOptIn,
  };
}

export function formatAltContactsResponse(altContacts, userId) {
  return altContacts.map((contact) => {
    if (contact.isSaved) return contact;
    return { ...contact, isSaved: true, userId };
  });
}
