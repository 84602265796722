import React from "react";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "../../../components/@vuexy/breadCrumbs/BreadCrumb";
import TodoTypesViewConfig from "./TodoTypesListConfig";
import queryString from "query-string";

class TodoTypesView extends React.Component {
    render() {
      return (
        <React.Fragment>
          <Breadcrumbs
            breadCrumbTitle="Todo/Note Types"
            breadCrumbActive="All Todo/Note Types"
          />
          <Row>
            <Col sm="12">
              <TodoTypesViewConfig
                parsedFilter={queryString.parse(this.props.location.search)}
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
  
  export default TodoTypesView;