/* eslint-disable indent */
import React, { createRef } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Button,
  Popover,
  Input,
} from "reactstrap";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";

import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import ConfirmationModal from "../../../components/fts/confirmation-modal";
import {
  defineColumnsNational,
  defineColumnsAccount,
  defineColumnsListing,
  defineColumnsGeneral,
} from "./columns-promotions";
import { getUserListings } from "../../../redux/actions/users";

import {
  createPromotion,
  createPromotionAsset,
} from "../../../redux/actions/promotions";

import { createActivity } from "../../../redux/actions/activities";
import { activities } from "../../../constants";

import "../../../assets/scss/plugins/tables/_agGridStyleOverride.scss";
import "../../../assets/scss/pages/users.scss";

import {
  getFilteredPromotions,
  setAccountPromotions,
  setListingPromotions,
  setNationalPromotions,
  setGeneralPromotions,
} from "../../../redux/actions/promotions";
import { promotionsTypes } from "../../../constants";
import { searchVendorAccounts } from "../../../redux/actions/users";
import { capitalizeFirstLetter } from "../../../utility/googleCalendar";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import { toast } from "react-toastify";
import { searchListings } from "../../../redux/actions/listings";

let defaultSort = [
  { colId: "statusForSort", sort: "ASC" },
  { colId: "validTo", sort: "ASC" },
  { colId: "validFrom", sort: "DESC" },
];
class Promotions extends React.Component {
  state = {
    activeTab: this.props.accountId
      ? promotionsTypes.ACCOUNT
      : this.props.listingId
      ? promotionsTypes.LISTING
      : promotionsTypes.NATIONAL,
    nationalPromotionData: null,
    accountPromotionData: null,
    listingPromotionData: null,
    generalPromotionData: null,
    inProgress: true,
    pageNational: 1,
    pageAccount: 1,
    pageListing: 1,
    pageGeneral: 1,
    totalRecordsNational: this.props.totalRecordsNational,
    totalRecordsAccount: this.props.totalRecordsAccount,
    totalRecordsListing: this.props.totalRecordsListing,
    totalRecordsGeneral: this.props.totalRecordsGeneral,
    pageCountNational: 0,
    pageCountAccount: 0,
    pageCountListing: 0,
    pageCountGeneral: 0,
    pageSizeNational: 10,
    pageSizeAccount: 10,
    pageSizeListing: 10,
    pageSizeGeneral: 10,
    searchValNational: "",
    searchValAccount: "",
    searchValListing: "",
    searchValGeneral: "",
    sortNational: defaultSort,
    sortAccount: defaultSort,
    sortListing: defaultSort,

    listingQuery: {
      id: "",
      name: "",
      phone: "",
      address: "",
      city: "",
      zip: "",
      state: "",
      status: "",
    },
    defaultColDef: {
      sortable: true,
    },
    columnDefsNational: defineColumnsNational(
      this.collectDataForCopy,
      this.totalAuthorizedLocations,
      this.totalAuthorizedListings,
    ),
    columnDefsAccount: defineColumnsAccount(
      this.collectDataForCopy,
      this.togglePopover,
    ),
    columnDefsListing: defineColumnsListing(this.collectDataForCopy),

    sidebar: false,
    updating: false,
    action: "",
    actionItem: null,
    popoverOpen: false,
    isOpen: false,
  };

  formikSidebarRef = createRef();

  pageSizes = [10, 25, 50, 100];

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  resetFormikForm = () => {
    this.formikSidebarRef.resetForm();
  };

  totalAuthorizedLocations = 0;

  componentDidMount() {
    const {
      pageSizeNational,
      pageSizeAccount,
      pageSizeListing,
      pageSizeGeneral,
      pageNational,
      pageAccount,
      pageListing,
      pageGeneral,
    } = this.state;

    this.props
      .searchVendorAccounts({
        page: 0,
        resultsPerPage: 0,
        adminIsMerchant: 1,
      })
      .then(
        ({
          data: {
            result: { nbHits },
          },
        }) => {
          this.totalAuthorizedLocations = nbHits;

          return nbHits;
        },
      );
    this.props
      .searchListings({
        page: 0,
        resultsPerPage: 0,
        includeInAuthorizedPromotions: true,
        searchString: "",
        name: "",
      })
      .then(
        ({
          data: {
            result: { nbHits },
          },
        }) => {
          this.totalAuthorizedListings = nbHits;
          return nbHits;
        },
      );

    // const getAllPromotionsPayload = {};
    let getAllPromotions = [];

    if (!this.props.adminIsMerchant) {
      getAllPromotions[0] = async () => {};
    }
    if (!this.props.accountId && !this.props.listingId) {
      getAllPromotions[0] = this.props.getFilteredPromotions({
        page: pageNational,
        resultsPerPage: pageSizeNational,
        type: promotionsTypes.NATIONAL,
        sort: defaultSort,
      });
      getAllPromotions[1] = this.props.getFilteredPromotions({
        page: pageAccount,
        resultsPerPage: pageSizeAccount,
        type: promotionsTypes.ACCOUNT,
        userId: this.props.accountId,
        sort: defaultSort,
      });
      getAllPromotions[2] = this.props.getFilteredPromotions({
        page: pageListing,
        resultsPerPage: pageSizeListing,
        type: promotionsTypes.LISTING,
        listingId: this.props.listingId,
        sort: defaultSort,
      });
      getAllPromotions[3] = this.props.getFilteredPromotions({
        page: pageGeneral,
        resultsPerPage: pageSizeGeneral,
        type: promotionsTypes.GENERAL,
        sort: defaultSort,
      });
    }
    // { statusForSort: { order: "ASC" } }, { validFrom: { order: "ASC" } }
    if (this.props.accountId) {
      getAllPromotions[0] = async () => {};

      getAllPromotions[1] = this.props.getFilteredPromotions({
        page: pageAccount,
        resultsPerPage: pageSizeAccount,
        type: promotionsTypes.ACCOUNT,
        userId: this.props.accountId,
        sort: defaultSort,
      });
      getAllPromotions[2] = async () => {};
      getAllPromotions[3] = this.props.getFilteredPromotions({
        page: pageGeneral,
        resultsPerPage: pageSizeGeneral,
        type: promotionsTypes.GENERAL,
        sort: defaultSort,
      });
    }

    if (this.props.listingId) {
      getAllPromotions[1] = async () => {};
      getAllPromotions[2] = this.props.getFilteredPromotions({
        page: pageListing,
        resultsPerPage: pageSizeListing,
        type: promotionsTypes.LISTING,
        listingId: this.props.listingId,
        sort: defaultSort,
      });
      getAllPromotions[3] = this.props.getFilteredPromotions({
        page: pageGeneral,
        resultsPerPage: pageSizeGeneral,
        type: promotionsTypes.GENERAL,
        sort: defaultSort,
      });
    }

    Promise.all(getAllPromotions)
      .then(
        ([
          { data: nationalPromotionData },
          { data: accountPromotionData },
          { data: listingPromotionData },
          { data: generalPromotionData },
        ]) => {
          this.props.setAccountPromotions({
            rows: accountPromotionData?.result?.hits,
            count: accountPromotionData?.result?.nbHits,
            activeCount: accountPromotionData?.result?.activeCount,
          });
          this.props.setListingPromotions({
            rows: listingPromotionData?.result?.hits,
            count: listingPromotionData?.result?.nbHits,
            activeCount: listingPromotionData?.result?.activeCount,
          });
          this.props.setNationalPromotions({
            rows: nationalPromotionData?.result?.hits,
            count: nationalPromotionData?.result?.nbHits,
            activeCount: nationalPromotionData?.result?.activeCount,
          });
          this.props.setGeneralPromotions({
            rows: generalPromotionData?.result?.hits,
            count: generalPromotionData?.result?.nbHits,
            activeCount: generalPromotionData?.result?.activeCount,
          });
          this.setState({
            inProgress: false,

            nationalPromotionData: nationalPromotionData?.result?.hits || null,
            accountPromotionData: accountPromotionData?.result?.hits || null,
            listingPromotionData: listingPromotionData?.result?.hits || null,
            generalPromotionData: generalPromotionData?.result?.hits || null,

            columnDefsNational: defineColumnsNational(
              this.collectDataForCopy,
              this.totalAuthorizedLocations,
              this.totalAuthorizedListings,
            ),
            columnDefsAccount: defineColumnsAccount(
              this.collectDataForCopy,
              this.togglePopover,
            ),
            columnDefsListing: defineColumnsListing(this.collectDataForCopy),
            columnDefsGeneral: defineColumnsGeneral(
              this.collectDataForCopy,
              this.props.accountId,
              this.props.listingId,
              this.onApply,
            ),
            totalRecordsNational: nationalPromotionData?.result?.nbHits || 0,
            totalRecordsGeneral: generalPromotionData?.result?.nbHits || 0,
            totalRecordsAccount: accountPromotionData?.result?.nbHits || 0,
            totalRecordsListing: listingPromotionData?.result?.nbHits || 0,

            totalActiveRecordsNational:
              nationalPromotionData?.result?.activeCount || 0,
            totalActiveRecordsGeneral:
              generalPromotionData?.result?.activeCount || 0,
            totalActiveRecordsAccount:
              accountPromotionData?.result?.activeCount || 0,
            totalActiveRecordsListing:
              listingPromotionData?.result?.activeCount || 0,

            pageCountNational: Math.ceil(
              nationalPromotionData?.result?.nbHits / pageSizeNational,
            ),
            pageCountAccount: Math.ceil(
              accountPromotionData?.result?.nbHits / pageSizeAccount,
            ),
            pageCountListing: Math.ceil(
              listingPromotionData?.result?.nbHits / pageSizeListing,
            ),
            pageCountGeneral: Math.ceil(
              generalPromotionData?.result?.nbHits / pageSizeNational,
            ),
          });
        },
      )
      .catch((e) => {
        console.log("ERROR: ", e);
      });
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  onApply = (data) => {
    this.toggleTableOverlay(true);
    let payload = {
      // ...data,
      ownerId: this.props.accountInfo?.id || this.props.accountId || null,
      adId: this.props.listingId || null,
      type: this.props.listingId ? "listing" : "account",
      status: 0,
      impressions: 0,
      title: rfc3986EncodeURIComponent(data.title),
      validFrom: data.validFrom,
      validTo: data.validTo,
      description: rfc3986EncodeURIComponent(data.description),
      link: data.link,
      icon: "", // data.icon,
      image: data.image,
      imageMobile: "", //data.imageMobile,
      linkText: data.linkText,
      downloadButtonText: data.downloadButtonText,
      expireDateDisplay: data.expireDateDisplay,
      downloadButtonFile: data.downloadButtonFile,
      parentId: data.id,
    };
    this.props
      .createPromotion(payload)
      .then(async ({ data: { promotion } }) => {
        this.toggleTableOverlay(false);
        let assetPayload = {
          promotionId: promotion.id,
          path: promotion.image,
          deviceType: "desktop",
          versions: JSON.stringify([{ path: promotion.image, version: 1 }]),
          ownerId: promotion.ownerId,
          size: 1,
        };

        if (promotion.image) {
          assetPayload = {
            ...assetPayload,
            type: promotion.image.split(".").pop(),
          };
          await this.props.createPromotionAsset(assetPayload);
        }

        if (promotion.downloadButtonFile) {
          assetPayload = {
            ...assetPayload,
            path: promotion.downloadButtonFile,
            deviceType: "downloadable",
            type: promotion.downloadButtonFile.split(".").pop(),
            versions: JSON.stringify([
              { path: promotion.downloadButtonFile, version: 1 },
            ]),
          };
          await this.props.createPromotionAsset(assetPayload);
        }

        await this.logPromotionActivity(promotion);
        toast.success(
          "Promotion has been applied to the account. To activate and change it, please access it from the 'account promotions' tab. ",
          {
            position: toast.POSITION.TOP_RIGHT,
          },
        );

        this.setState(
          {
            activeTab: this.props.listingId ? "listing" : "account",
          },
          async () => {
            this.onSubmitQuery();
          },
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  updateListingQuery = (e) => {
    this.setState({
      listingQuery: {
        ...this.state.listingQuery,
        [e.target.name]: e.target.value,
      },
    });
  };

  togglePopover = (popOverName, popoverData) => {
    if (popOverName) {
      return this.setState({
        popoverTarget: popOverName,
        popoverOpen: true,
        popoverData,
      });
    }
    return this.setState({
      popoverTarget: "",
      popoverOpen: false,
      popoverData: null,
    });
  };

  onSubmitQuery = () => {
    let { activeTab } = this.state;
    // if (!this.props.listingId && !this.props.accountId) {
    //   activeTab = "national";
    // } else if (this.props.listingId && activeTab === "national") {
    //   activeTab = "listing";
    // } else if (this.props.accountId && activeTab === "national") {
    //   activeTab = "account";
    // }
    // this.gridApiNational
    // this.gridApiAccount
    // this.gridApiListing
    let capitalizeActiveTab =
      this.state.activeTab.charAt(0).toUpperCase() + activeTab.slice(1);

    this[`gridApi${capitalizeActiveTab}`].showLoadingOverlay();

    this.props
      .getFilteredPromotions({
        page: this.state[`page${capitalizeActiveTab}`],
        resultsPerPage: this.state[`pageSize${capitalizeActiveTab}`],
        type: activeTab,
        searchString: this.state[`searchVal${capitalizeActiveTab}`],
        listingId:
          activeTab === promotionsTypes.ACCOUNT ||
          activeTab === promotionsTypes.GENERAL
            ? null
            : this.props.listingId,
        userId:
          activeTab === promotionsTypes.LISTING ||
          activeTab === promotionsTypes.GENERAL
            ? null
            : this.props.accountId,
        sort: this.state[`sort${capitalizeActiveTab}`],
      })
      .then(({ data }) => {
        this.props[`set${capitalizeActiveTab}Promotions`]({
          rows: data?.result?.hits,
          count: data?.result?.nbHits,
          activeCount: data?.result?.activeCount,
        });
        this.setState({
          [`${activeTab}PromotionData`]: data.result.hits,
          [`totalRecords${capitalizeActiveTab}`]: data.result.nbHits,
          [`totalActiveRecords${capitalizeActiveTab}`]: data.result.activeCount,
          [`pageCount${capitalizeActiveTab}`]: Math.ceil(
            data.result.nbHits / this.state[`pageSize${capitalizeActiveTab}`],
          ),
        });
        this[`gridApi${capitalizeActiveTab}`].hideOverlay();
      });
  };

  onGridReadyNational = (params) => {
    this.gridApiNational = params.api;
    this.gridColumnApiNationalPromotions = params.columnApi;

    if (localStorage.getItem("nationalPromotionsColumnState") !== null) {
      this.gridColumnApiNationalPromotions.setColumnState(
        JSON.parse(localStorage.getItem("nationalPromotionsColumnState")),
      );
    }
  };

  onGridReadyGeneral = (params) => {
    this.gridApiGeneral = params.api;
    this.gridColumnApiGeneralPromotions = params.columnApi;

    if (localStorage.getItem("generalPromotionsColumnState") !== null) {
      this.gridColumnApiGeneralPromotions.setColumnState(
        JSON.parse(localStorage.getItem("generalPromotionsColumnState")),
      );
    }
  };

  onGridReadyAccount = (params) => {
    this.gridApiAccount = params.api;
    this.gridColumnApiAccountPromotions = params.columnApi;

    if (localStorage.getItem("accountPromotionsColumnState") !== null) {
      this.gridColumnApiAccountPromotions.setColumnState(
        JSON.parse(localStorage.getItem("accountPromotionsColumnState")),
      );
    }
  };

  onGridReadyListing = (params) => {
    this.gridApiListing = params.api;
    this.gridColumnApiListingPromotions = params.columnApi;

    if (localStorage.getItem("listingPromotionsColumnState") !== null) {
      this.gridColumnApiListingPromotions.setColumnState(
        JSON.parse(localStorage.getItem("listingPromotionsColumnState")),
      );
    }
  };

  saveStateNational = () => {
    let nationalColumnState =
      this.gridColumnApiNationalPromotions.getColumnState();
    localStorage.setItem(
      "nationalPromotionsColumnState",
      JSON.stringify(nationalColumnState),
    );
  };

  saveStateGeneral = () => {
    let generalColumnState =
      this.gridColumnApiGeneralPromotions.getColumnState();
    localStorage.setItem(
      "generalPromotionsColumnState",
      JSON.stringify(generalColumnState),
    );
  };

  saveStateAccountPromotions = () => {
    let accountPromotionsColumnState =
      this.gridColumnApiAccountPromotions.getColumnState();
    localStorage.setItem(
      "accountPromotionsColumnState",
      JSON.stringify(accountPromotionsColumnState),
    );
  };

  saveStateListingPromotions = () => {
    let listingPromotionsColumnState =
      this.gridColumnApiListingPromotions.getColumnState();
    localStorage.setItem(
      "listingPromotionsColumnState",
      JSON.stringify(listingPromotionsColumnState),
    );
  };

  resetApiNational = () => {
    this.gridApiNational = null;
  };

  resetApiGeneral = () => {
    this.gridApiGeneral = null;
  };

  resetApiAccount = () => {
    this.gridApiAccount = null;
  };

  resetApiListing = () => {
    this.gridApiListing = null;
  };

  debounce = setTimeout(() => {}, 300);

  updateSearchQuery = (type, val) => {
    clearTimeout(this.debounce);

    this.setState(
      {
        searchVal: val,
        [type]: val,
      },
      () => {
        this.debounce = setTimeout(() => this.onSubmitQuery(), 300);
      },
    );
  };

  filterSize = (type, val) => {
    this.setState(
      {
        [type]: val,
      },
      () => {
        this.onSubmitQuery();
      },
    );
  };

  onPageChangedNational = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageNational: pageNum }, () => {
      this.onSubmitQuery();
    });
  };

  onPageChangedGeneral = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageGeneral: pageNum }, () => {
      this.onSubmitQuery();
    });
  };

  onPageChangedAccount = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageAccount: pageNum }, () => {
      this.onSubmitQuery();
    });
  };

  onPageChangedListing = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageListing: pageNum }, () => {
      this.onSubmitQuery();
    });
  };

  toggle = (tab) => {
    // if (this.state.activeTab !== tab) {
    this.setState({ activeTab: tab });
    // }
  };

  callToAction = (action, update, item) => {
    if (!action) return;
    this.setState({
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
    });
  };

  setFormikSidebarRef = (ref) => {
    this.formikSidebarRef = ref;
  };

  handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (bool === false && this.formikSidebarRef?.dirty && !closeWithoutPrompt) {
      return this.setState({ isOpen: true });
    } else {
      this.setState({ sidebar: bool, updating: !!update });
    }
  };

  closeSideOutAction = () => {
    this.setState({ sidebar: false, update: false, isOpen: false });
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  generateTabs = () => {
    let tabs = [
      {
        label: "National promotions",
        type: "national",
        total: this.state.totalActiveRecordsNational,
      },
      {
        label: "Account promotions",
        type: "account",
        total: this.state.totalActiveRecordsAccount,
      },
      {
        label: "Listing promotions",
        type: "listing",
        total: this.state.totalActiveRecordsListing,
      },
      {
        label: "General promotions",
        type: "general",
        total: this.state.totalActiveRecordsGeneral,
      },
    ];

    if (this.props.accountId && this.props.adminIsMerchant) {
      tabs = [
        {
          label: "National promotions",
          type: "national",
          total: this.state.totalActiveRecordsNational,
        },
        {
          label: "Account promotions",
          type: "account",
          total: this.state.totalActiveRecordsAccount,
        },
        {
          label: "General promotions",
          type: "general",
          total: this.state.totalActiveRecordsGeneral,
        },
      ];
    }

    if (this.props.accountId) {
      tabs = [
        {
          label: "Account promotions",
          type: "account",
          total: this.state.totalActiveRecordsAccount,
        },
        {
          label: "General promotions",
          type: "general",
          total: this.state.totalActiveRecordsGeneral,
        },
      ];
    }
    if (this.props.listingId) {
      tabs = [
        {
          label: "Listing promotions",
          type: "listing",
          total: this.state.totalActiveRecordsListing,
        },
        {
          label: "General promotions",
          type: "general",
          total: this.state.totalActiveRecordsGeneral,
        },
      ];
    }

    return tabs.map((itm) => (
      <NavItem key={itm.type}>
        <NavLink
          id="price-padding"
          className={classnames({
            active: this.state.activeTab === itm.type,
          })}
          onClick={() => {
            this.toggle(itm.type);
          }}
        >
          {itm.label} ({Number(itm.total.toFixed(1)).toLocaleString()})
        </NavLink>
      </NavItem>
    ));
  };

  onRowClicked = (e) => {
    if (
      e.api.focusedCellController.focusedCellPosition.column.colId === "button"
    )
      return;

    if (
      e.api.focusedCellController.focusedCellPosition.column.colId !==
      "imageForSort"
    ) {
      this.callToAction("promotion", true, e.data);
    }
  };

  onPromotionCreate = () => {
    this.setState(
      { pageNational: 0, pageGeneral: 0, pageAccount: 0, pageListing: 0 },
      () => {
        this.onSubmitQuery();
      },
    );
  };

  onSortChanged = (params) => {
    let sort = params.api.getSortModel();
    if (sort.length) {
      let type = capitalizeFirstLetter(this.state.activeTab);
      this.setState({ [`sort${type}`]: sort }, () => this.onSubmitQuery());
    }
  };

  toggleTableOverlay = (show) => {
    let { activeTab } = this.state;

    let capitalizeActiveTab =
      this.state.activeTab.charAt(0).toUpperCase() + activeTab.slice(1);
    if (show) {
      this[`gridApi${capitalizeActiveTab}`].showLoadingOverlay();
    } else {
      this[`gridApi${capitalizeActiveTab}`].hideOverlay();
    }
  };

  logPromotionActivity = (promotion) => {
    let activityPayload = {
      userId: this.props.accountInfo?.id || this.props.accountId || null,
      adId: this.props.listingId || null,
      promotionId: promotion.id,
      status: 1,
      activityType: this.props.activitiesTypes.APPLIED_GENERAL_PROMOTION,
      activity: activities.applyGeneralPromotion
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{promotionName}}", promotion.title)
        .replace(
          "{{generalPromotionType}}",
          this.props.accountId ? "account" : "listing",
        )
        .replace(
          "{{generalPromotionTypeId}}",
          this.props.accountId ? this.props.accountId : this.props.listingId,
        ),
      adminUserId: this.props.userData.loggedInUser.id,
      iconName: "PromotionActivity",
    };

    return this.props.createActivity(activityPayload);
  };

  render() {
    const {
      activeTab,
      nationalPromotionData,
      generalPromotionData,
      pageCountNational,
      pageCountGeneral,
      pageCountAccount,
      pageCountListing,
      accountPromotionData,
      listingPromotionData,
      pageSizeNational,
      searchValNational,
      searchValGeneral,
      searchValAccount,
      searchValListing,
      pageSizeAccount,
      pageSizeListing,

      defaultColDef,
      totalRecordsNational,
      totalRecordsAccount,
      totalRecordsListing,
      totalRecordsGeneral,
      pageNational,
      pageGeneral,
      pageAccount,
      pageListing,
      pageSizeGeneral,
      inProgress,
      columnDefsNational,
      columnDefsGeneral,
      columnDefsAccount,
      columnDefsListing,
      updating,
      sidebar,
      action,
      actionItem,
      isOpen,
    } = this.state;

    const { listingId, accountId } = this.props;

    return (
      <div className="fts-promotions-list">
        <Row className="app-user-list">
          <Col sm="12" style={{ paddingLeft: 0 }}>
            <div style={{ backgroundColor: "#fff" }}>
              <div>
                {(nationalPromotionData === null ||
                  accountPromotionData === null ||
                  listingPromotionData === null) &&
                  inProgress && (
                    <Col sm="12">
                      <Spinner size="sm" className="float-right" />
                    </Col>
                  )}

                {(nationalPromotionData !== null ||
                  accountPromotionData !== null ||
                  listingPromotionData !== null) &&
                  !inProgress && (
                    <div className="nav-horizontal">
                      <TabContent activeTab="national">
                        <TabPane tabId="national">
                          <div
                            className="result-header d-flex align-items-center pt-1"
                            style={{
                              minHeight: "58px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <p className="result-title">Promotions</p>
                            </div>
                            <div className="d-flex">
                              <Input
                                className="mr-1 mb-1 mb-sm-0"
                                type="text"
                                placeholder={"Search..."}
                                onChange={(e) =>
                                  this.updateSearchQuery(
                                    "searchValNational",
                                    e.target.value,
                                    "promotion",
                                  )
                                }
                                defaultValue={searchValNational}
                              />

                              {!accountId &&
                                !listingId &&
                                activeTab !== "account" &&
                                activeTab !== "listing" && (
                                  <div className="d-flex align-items-center">
                                    <Button
                                      type="button"
                                      className="p-1"
                                      color="primary"
                                      style={{ minWidth: 120 }}
                                      outline
                                      onClick={() => {
                                        this.callToAction(
                                          "promotion",
                                          false,
                                          true,
                                        );
                                      }}
                                    >
                                      + Create New
                                    </Button>
                                  </div>
                                )}
                            </div>
                          </div>
                          <Nav tabs>{this.generateTabs()}</Nav>
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId="national">
                              <FTSDataTable
                                isSearch={true}
                                pageSizes={this.pageSizes}
                                totalRecords={totalRecordsNational}
                                currentPageSize={pageSizeNational}
                                searchValType={"searchValNational"}
                                searchPlaceholder="Search"
                                pageSizePropName={"pageSizeNational"}
                                searchVal={searchValNational}
                                currentPage={pageNational}
                                pageCount={pageCountNational}
                                rowData={nationalPromotionData}
                                onGridReadyInit={this.onGridReadyNational}
                                saveState={this.saveStateNational}
                                filterSize={this.filterSize}
                                updateSearchQuery={this.updateSearchQuery}
                                onPageChange={this.onPageChangedNational}
                                resetApi={this.resetApiNational}
                                onRowClicked={this.onRowClicked}
                                columnDefs={columnDefsNational}
                                onSortChanged={this.onSortChanged}
                                tableName="promotion"
                                {...{
                                  defaultColDef,
                                }}
                              />
                            </TabPane>
                            <TabPane tabId="general">
                              <FTSDataTable
                                isSearch={true}
                                pageSizes={this.pageSizes}
                                totalRecords={totalRecordsGeneral}
                                currentPageSize={pageSizeGeneral}
                                searchValType={"searchValGeneral"}
                                searchPlaceholder="Search"
                                pageSizePropName={"pageSizeGeneral"}
                                searchVal={searchValGeneral}
                                currentPage={pageGeneral}
                                pageCount={pageCountGeneral}
                                rowData={generalPromotionData}
                                onGridReadyInit={this.onGridReadyGeneral}
                                saveState={this.saveStateGeneral}
                                filterSize={this.filterSize}
                                updateSearchQuery={this.updateSearchQuery}
                                onPageChange={this.onPageChangedGeneral}
                                resetApi={this.resetApiGeneral}
                                onRowClicked={this.onRowClicked}
                                columnDefs={columnDefsGeneral}
                                onSortChanged={this.onSortChanged}
                                tableName="promotion"
                                {...{
                                  defaultColDef,
                                }}
                              />
                            </TabPane>
                            <TabPane tabId="account">
                              <FTSDataTable
                                isSearch={true}
                                // onCreateNewPress={() => {
                                //   //TODO: Open sidebar for new listing
                                // }}
                                pageSizes={this.pageSizes}
                                currentPageSize={pageSizeAccount}
                                searchVal={searchValAccount}
                                currentPage={pageAccount}
                                searchValType={"searchValAccount"}
                                searchPlaceholder="Search"
                                pageSizePropName={"pageSizeAccount"}
                                totalRecords={totalRecordsAccount}
                                pageCount={pageCountAccount}
                                onGridReadyInit={this.onGridReadyAccount}
                                saveState={this.saveStateAccountPromotions}
                                filterSize={this.filterSize}
                                updateSearchQuery={this.updateSearchQuery}
                                rowData={accountPromotionData}
                                onPageChange={this.onPageChangedAccount}
                                resetApi={this.resetApiAccount}
                                onRowClicked={this.onRowClicked}
                                columnDefs={columnDefsAccount}
                                onSortChanged={this.onSortChanged}
                                tableName="promotion"
                                {...{
                                  defaultColDef,
                                }}
                              />
                            </TabPane>
                            <TabPane tabId="listing">
                              <FTSDataTable
                                isSearch={true}
                                pageSizes={this.pageSizes}
                                currentPageSize={pageSizeListing}
                                searchVal={searchValListing}
                                currentPage={pageListing}
                                searchValType={"searchValListing"}
                                searchPlaceholder="Search"
                                pageSizePropName={"pageSizeListing"}
                                totalRecords={totalRecordsListing}
                                pageCount={pageCountListing}
                                onGridReadyInit={this.onGridReadyListing}
                                saveState={this.saveStateListingPromotions}
                                filterSize={this.filterSize}
                                updateSearchQuery={this.updateSearchQuery}
                                rowData={listingPromotionData}
                                onPageChange={this.onPageChangedListing}
                                resetApi={this.resetApiListing}
                                onRowClicked={this.onRowClicked}
                                columnDefs={columnDefsListing}
                                onSortChanged={this.onSortChanged}
                                tableName="promotion"
                                {...{
                                  defaultColDef,
                                }}
                              />
                            </TabPane>
                          </TabContent>
                        </TabPane>
                      </TabContent>
                    </div>
                  )}
              </div>
            </div>
          </Col>
        </Row>
        <ActionSidebar
          onSubmitQuery={this.onSubmitQuery}
          onPromotionCreate={this.onPromotionCreate}
          setFormikSidebarRef={this.setFormikSidebarRef}
          resetFormikForm={this.resetFormikForm}
          adminData={this.props.userData}
          isPromotion
          show={sidebar}
          action={action}
          updating={updating}
          setEditMode={this.setEditMode}
          actionItem={actionItem}
          listings={this.state.accountListings}
          handleSidebar={this.handleSidebar}
          promotionType={
            activeTab === "national" ? "nationalPromotion" : "generalPromotion"
          }
          getAccountInfo={this.getAccountInfo}
          filesTableGroupRef={this.filesTableGroupRef}
          toggleTableOverlay={this.toggleTableOverlay}
          onPrefillListingData={this.onPrefillListingData}
          setPromotionsCount={this.props.setPromotionsCount}
          noMarker
          isSalesPage
        />
        {this.state.popoverOpen &&
          this.state.popoverData?.length !== 0 &&
          this.state.popoverData?.length < 10 && (
            <Popover
              className="bg-promotion-popover"
              popperClassName={"bg-popover"}
              style={{ minWidth: 310 }}
              placement="bottom"
              isOpen={this.state.popoverOpen}
              target={this.state.popoverTarget}
            >
              <p className="bg-popover-title">NOT INCLUDED LISTINGS</p>

              <div className="bg-popover-wrapper">
                {this.state.popoverData?.length !== 0 &&
                  this.state.popoverData.map((listing, index) =>
                    this.state.popoverData.length === index + 1 ? (
                      <div key={listing.id} className="bg-popover-cat-name">
                        {listing.id}-{listing.companyName}-{listing.city}-
                        {listing.state}-{listing.phoneNumber}
                      </div>
                    ) : (
                      <div key={listing.id} className="bg-popover-cat-name">
                        {listing.id}-{listing.companyName}-{listing.city}-
                        {listing.state}-{listing.phoneNumber}
                      </div>
                    ),
                  )}
              </div>
            </Popover>
          )}
        <ContextMenu
          style={{ minWidth: 250 }}
          id="contextMenuPromotions"
          preventHideOnScroll={false}
        >
          <MenuItem
            onClick={() =>
              navigator.clipboard.writeText(this.state.collectedDataForCopy)
            }
          >
            <FaRegCopy size={18} className="copy" />
            <span className={"ml-1"}>Copy</span>
          </MenuItem>
        </ContextMenu>
        <ConfirmationModal
          title="Unsaved Changes"
          message="Are you sure you want to proceed with this action?"
          onClose={this.onModalClosed}
          closeModal={this.toggleModal}
          confirmModal={this.closeSideOutAction}
          {...{ isOpen }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    totalRecordsListing: state.promotions.totalRecordsListing,
    listingPromotionData: state.promotions.listingPromotionData,
    totalRecordsAccount: state.promotions.totalRecordsAccount,
    accountPromotionData: state.promotions.accountPromotionData,
    totalRecordsNational: state.promotions.totalRecordsNational,
    nationalPromotionData: state.promotions.nationalPromotionData,
    generalPromotionData: state.promotions.generalPromotionData,
    inProgressPromotions: state.promotions.inProgress,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, {
  getUserListings,
  getFilteredPromotions,
  setAccountPromotions,
  setNationalPromotions,
  setListingPromotions,
  setGeneralPromotions,
  searchVendorAccounts,
  createPromotion,
  createPromotionAsset,
  createActivity,
  searchListings,
})(Promotions);
