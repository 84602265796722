import React, { PureComponent } from "react";
import { Row, Col, FormGroup } from "reactstrap";

import HourSelect from "./HourSelect";
import { hoursListFrom, hoursListTo } from "./options";

const isClosedOr24hr = (obj) =>
  obj?.value === "24 hrs" ||
  obj?.value === "Closed" ||
  obj?.value === "By Appointment";

const weekdayAbbrs = {
  Monday: "MON",
  Tuesday: "TUE",
  Wednesday: "WED",
  Thursday: "THU",
  Friday: "FRI",
  Saturday: "SAT",
  Sunday: "SUN",
};

const labelMap = {
  "24 hrs": "24 Hours",
  Closed: "Closed",
  "By Appointment": "By Appointment",
  "12 AM": "12:00 a.m. (Midnight)",
  "12 AM (Midnight)": "12:00 a.m. (Midnight)",
  "12:30 AM": "12:30 a.m.",
  "1 AM": "1:00 a.m.",
  "1:30 AM": "1:30 a.m.",
  "2 AM": "2 a.m.",
  "2:30 AM": "2:30 a.m.",
  "3 AM": "3:00 a.m.",
  "3:30 AM": "3:30 a.m.",
  "4 AM": "4:00 a.m.",
  "4:30 AM": "4:30 a.m.",
  "5 AM": "5:00 a.m.",
  "5:30 AM": "5:30 a.m.",
  "6 AM": "6:00 a.m.",
  "6:30 AM": "6:30 a.m.",
  "7 AM": "7:00 a.m.",
  "7:30 AM": "7:30 a.m.",
  "8 AM": "8:00 a.m.",
  "8:30 AM": "8:30 a.m.",
  "9 AM": "9:00 a.m.",
  "9:30 AM": "9:30 a.m.",
  "10 AM": "10:00 a.m.",
  "10:30 AM": "10:30 a.m.",
  "11 AM": "11:00 a.m.",
  "12 PM": "12:00 p.m. (Noon)",
  "12 PM (Noon)": "12:00 p.m. (Noon)",
  "1 PM": "1:00 p.m.",
  "2 PM": "2:00 p.m.",
  "3 PM": "3:00 p.m.",
  "4 PM": "4:00 p.m.",
  "5 PM": "5:00 p.m.",
  "6 PM": "6:00 p.m.",
  "7 PM": "7:00 p.m.",
  "8 PM": "8:00 p.m.",
  "9 PM": "9:00 p.m.",
  "10 PM": "10:00 p.m.",
  "11 PM": "11:00 p.m.",
  "11:30 AM": "11:30 a.m.",
  "12:30 PM": "12:30 p.m.",
  "1:30 PM": "1:30 p.m.",
  "2:30 PM": "2:30 p.m.",
  "3:30 PM": "3:30 p.m.",
  "4:30 PM": "4:30 p.m.",
  "5:30 PM": "5:30 p.m.",
  "6:30 PM": "6:30 p.m.",
  "7:30 PM": "7:30 p.m.",
  "8:30 PM": "8:30 p.m.",
  "9:30 PM": "9:30 p.m.",
  "10:30 PM": "10:30 p.m.",
  "11:30 PM": "11:30 p.m.",
};

function generateLabel(from, to) {
  if (!from && !to) return "-";

  return isClosedOr24hr(from)
    ? labelMap[from?.label]
    : `${labelMap[from?.label]} - ${labelMap[to?.label]}`;
}

class FromTo extends PureComponent {
  render() {
    const { weekday, editMode, meta, value, name, form } = this.props;

    const objFrom = value ? value[`${weekday.day}From`] : {};
    const objTo = value ? value[`${weekday.day}To`] : {};

    if (!editMode) {
      return (
        <React.Fragment>
          <div className="weekday-hours-title">{weekdayAbbrs[weekday.day]}</div>
          <div className="weekday-hours-label">
            {generateLabel(objFrom, objTo)}
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Row>
          <Col sm="4" xl="3">
            <div className="hour-input-label">{weekday.day}:</div>
          </Col>
          <Col sm="4">
            <FormGroup>
              <HourSelect
                options={hoursListFrom}
                type={"From"}
                isDisabled={!editMode}
                onHoursChange={(option) =>
                  form.setFieldValue(`${name}.${weekday.day}From`, option)
                }
                value={value && value[`${weekday.day}From`]}
                {...{ meta }}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <HourSelect
                options={hoursListTo}
                type={"To"}
                isDisabled={!editMode}
                closedOr24hr={isClosedOr24hr(objFrom)}
                onHoursChange={(option) =>
                  form.setFieldValue(`${name}.${weekday.day}To`, option)
                }
                value={value && value[`${weekday.day}To`]}
                {...{ meta }}
              />
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default React.memo(FromTo);
