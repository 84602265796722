/* eslint-disable indent */
import React, { Component } from "react";
import ReactDOM from "react-dom";

import DropdownItem from "./DropdownItem";
import Icon from "../Icons";
import { connect } from "react-redux";
import { updateTodo } from "../../../redux/actions/todo";

import "../../../assets/scss/components/fts/dropdown.scss";
import { toast } from "react-toastify";

const bodyRoot = document.getElementsByTagName("body")[0];

class TodoDropdownWrapper extends Component {
  render() {
    return <TodoDropdown {...this.props} />;
  }
}

class TodoDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      activeSubMenu: null,
      usersItes: [],
      employee: null,
    };

    this.portalRefData = React.createRef();
  }

  items = [
    {
      id: "editTask",
      componentLeft: <Icon name="DropdownEdit" />,
      text: "Edit Task",
      action: () => {
        this.props.setActiveDropdown(null);
        this.setState({
          activeSubMenu: null,
        });
        this.props.callToAction("todo", true, { id: this.props.id });
      },
    },
    {
      id: "open",
      componentLeft: <Icon name="GoTo" />,
      text: "Open",
      update: true,
      action: () => {
        this.props.setActiveDropdown(null);
        this.setState({
          activeSubMenu: null,
        });
        this.props.callToAction("todo", true, { id: this.props.id });
      },
    },
    {
      id: "moveTo",
      componentLeft: <Icon name="Move" />,
      text: "Move To",
      componentRight: (
        <Icon name="ArrowRight" style={{ marginRight: "-5px" }} />
      ),
      hasTypesMenu: true,
    },
    {
      id: "addParticipants",
      componentLeft: <Icon name="User" className="user" />,
      text: "Add Participants",
      componentRight: (
        <Icon name="ArrowRight" style={{ marginRight: "-5px" }} />
      ),
      hasUsersMenu: true,
    },
    {
      id: "reassignTodo",
      componentLeft: <Icon name="User" className="user" />,
      text: "Reassign to",
      componentRight: (
        <Icon name="ArrowRight" style={{ marginRight: "-5px" }} />
      ),
      hasReassignMenu: true,
    },
  ];

  underlineItems = [
    {
      id: "archiveTask",
      text: "Archive Task",
      componentLeft: <Icon name="DropdownArchive" />,
      red: true,
      action: () => {
        this.props.setActiveDropdown(null);
        this.setState({
          activeSubMenu: null,
        });
        this.props.handleArchive(this.props.id, this.props.todoType);
      },
    },
    {
      id: "unArchiveTask",
      text: "Un-archive Task",
      componentLeft: <Icon name="DropdownArchive" />,
      action: () => {
        this.props.setActiveDropdown(null);
        this.setState({
          activeSubMenu: null,
        });
        this.props.handleUnArchive(this.props.id, this.props.todoType);
      },
    },
  ];

  handleActiveSubMenu = (type) => {
    if (this.state.activeSubMenu === type) {
      this.setState({
        activeSubMenu: null,
      });
    } else {
      this.setState({
        activeSubMenu: type,
      });
    }
  };

  setPortalPosition = (element) => {
    var rect = element.getBoundingClientRect();
    this.portalRefData = rect;
  };

  onDropdownClick = (e) => {
    e.stopPropagation();
    const { data, setActiveDropdown } = this.props;
    this.setPortalPosition(e.target);
    setActiveDropdown(data.id);
    this.setState({
      activeSubMenu: null,
    });
  };

  render() {
    const { id, activeDropdown, setActiveDropdown, disableUpdate } = this.props;

    let show = false;
    let typesItems = [];

    if (id === activeDropdown) {
      show = true;

      let empty = this.props.getEmptyColumns();

      typesItems = empty?.map((type) => ({
        id: type.id,
        componentLeft: (
          <div
            style={{
              borderRadius: "50%",
              backgroundColor: type.color,
              width: "12px",
              height: "12px",
              marginRight: "1rem",
            }}
          ></div>
        ),
        text: type.description,
        action: () => {
          this.props.setActiveDropdown(null);
          this.props.handleTodoTypeUpdate(this.props.data, type);
          this.props.handleEmptyCols(type);
        },
      }));
    }

    return (
      <div
        className="dropdown-wrapper"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          className={`dropdown-button ${show ? "dropdown-button-active" : ""}`}
          onClick={this.onDropdownClick}
        >
          {<Icon name="MoreHorizontal" />}
        </button>

        {show ? (
          <TodoDropdownList
            fetchTodos={this.props.fetchTodos}
            updateTodo={this.props.updateTodo}
            ref={this.props.userRef}
            items={this.items}
            underlineItems={this.underlineItems}
            handleActiveSubMenu={this.handleActiveSubMenu}
            activeSubMenu={this.state.activeSubMenu}
            userData={this.props.userData}
            portalPosition={this.portalRefData}
            handleTodoUsersUpdate={this.props.handleTodoUsersUpdate}
            list={this.props.list}
            data={this.props.data}
            disableUpdate={disableUpdate}
            {...{ typesItems, activeDropdown, setActiveDropdown }}
          />
        ) : null}
      </div>
    );
  }
}

class TodoDropdownList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.el.className = "dropdown-wrapper portal";

    this.dropdownRef = React.createRef();
    this.state = {
      usersItems: [],
    };
  }

  componentDidMount() {
    bodyRoot.appendChild(this.el);
    window.addEventListener("mousedown", this.handleDropdownClickOutside);

    let userItems = this.props.list
      .map((employee) => ({
        id: employee.value,
        text: employee.label,
        componentLeft: <Icon name="User" />,
        componentRight:
          this.props.data?.todoAdmins?.filter(
            (user) => user.id === employee.value,
          ).length > 0 ? (
            <div className="dropdown-checked">
              <Icon name="Check" className="dropdown-check" />
            </div>
          ) : null,
        action: () => {
          this.props.handleTodoUsersUpdate(this.props.data, employee);
        },
        check: true,
      }))
      .filter((user) => user.id !== this.props.userData?.loggedInUser?.id);

    this.setState({
      usersItems: userItems,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleDropdownClickOutside);
    bodyRoot.removeChild(this.el);
  }

  handleDropdownClickOutside = (e) => {
    const isSvgClicked = e.target instanceof SVGElement;
    const { activeDropdown, setActiveDropdown } = this.props;
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(e.target) &&
      !isSvgClicked &&
      activeDropdown
    ) {
      setActiveDropdown(null);
    }
  };

  handleUsers = (employee) => {
    let checkUsers = this.state.usersItems;
    checkUsers = checkUsers.map((user) => {
      if (user.id === employee.value) {
        if (user.componentRight) {
          return { ...user, componentRight: null };
        } else {
          return { ...user, componentRight: <Icon name="Check" /> };
        }
      } else {
        return user;
      }
    });

    this.setState({
      usersItems: checkUsers,
    });
  };

  reassignTodo = (item) => {
    const payload = {
      ...this.props.data,
      adminOwnerId: item.id,
    };

    this.props
      .updateTodo(payload)
      .then(() => {
        this.props.fetchTodos();
        toast.success(`Todo successfully reassigned to ${item.text}`);
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  };

  render() {
    const {
      items,
      activeSubMenu,
      typesItems,
      handleActiveSubMenu,
      underlineItems,
      portalPosition,
      disableUpdate,
    } = this.props;

    const { x, y } = portalPosition;
    const dropdownLeft = x + 400 > window.innerWidth;

    return ReactDOM.createPortal(
      <div
        className={`dropdown dropdown-custom ${
          dropdownLeft ? "dropdown-custom-left" : "dropdown-custom-right"
        } `}
        style={{ top: `${y + 12}px`, left: `${x}px` }}
        ref={this.dropdownRef}
      >
        {items?.map((item) => {
          if (item.hasUsersMenu) {
            return (
              <div key={item.id}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!disableUpdate) {
                      handleActiveSubMenu("users");
                    }
                  }}
                >
                  <DropdownItem
                    disabled={disableUpdate}
                    key={item.id}
                    componentLeft={item.componentLeft}
                    componentRight={item.componentRight}
                    text={item.text}
                    action={item.action}
                    red={item.red}
                  />
                </div>
                {activeSubMenu === "users" ? (
                  <div
                    className={`dropdown ${
                      dropdownLeft
                        ? "dropdown-users-left"
                        : "dropdown-users-right"
                    }`}
                  >
                    {this.state.usersItems.map((item) => {
                      return (
                        <DropdownItem
                          key={item.id}
                          componentLeft={item.componentLeft}
                          componentRight={item.componentRight}
                          text={item.text}
                          action={item.action}
                          red={item.red}
                          check={item.check}
                        />
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          } else if (item.hasReassignMenu) {
            return (
              <div key={item.id}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!disableUpdate) {
                      handleActiveSubMenu("reassignMenu");
                    }
                  }}
                >
                  <DropdownItem
                    disabled={disableUpdate}
                    key={item.id}
                    componentLeft={item.componentLeft}
                    componentRight={item.componentRight}
                    text={item.text}
                    action={item.action}
                    red={item.red}
                  />
                </div>
                {activeSubMenu === "reassignMenu" ? (
                  <div
                    className={`dropdown ${
                      dropdownLeft
                        ? "dropdown-users-left"
                        : "dropdown-users-right"
                    }`}
                  >
                    {this.state.usersItems.map((item) => {
                      return (
                        <DropdownItem
                          key={item.id}
                          componentLeft={item.componentLeft}
                          text={item.text}
                          action={() => this.reassignTodo(item)}
                          red={item.red}
                          check={false}
                        />
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          } else if (item.hasTypesMenu) {
            return (
              <div key={item.id}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!disableUpdate) {
                      handleActiveSubMenu("types");
                    }
                  }}
                >
                  <DropdownItem
                    disabled={!typesItems.length || disableUpdate}
                    key={item.id}
                    componentLeft={item.componentLeft}
                    componentRight={item.componentRight}
                    text={item.text}
                    action={item.action}
                    red={item.red}
                  />
                </div>
                {activeSubMenu === "types" && typesItems.length ? (
                  <div
                    className={`dropdown ${
                      dropdownLeft
                        ? "dropdown-types-left"
                        : "dropdown-types-right"
                    }`}
                  >
                    {typesItems.map((item) => {
                      return (
                        <DropdownItem
                          key={item.id}
                          componentLeft={item.componentLeft}
                          componentRight={item.componentRight}
                          text={item.text}
                          action={item.action}
                          red={item.red}
                        />
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          } else {
            if (disableUpdate && item.id === "open") {
              return (
                <DropdownItem
                  key={item.id}
                  componentLeft={item.componentLeft}
                  componentRight={item.componentRight}
                  text={item.text}
                  action={item.action}
                  red={item.red}
                />
              );
            } else if (!disableUpdate && item.id === "editTask") {
              return (
                <DropdownItem
                  key={item.id}
                  componentLeft={item.componentLeft}
                  componentRight={item.componentRight}
                  text={item.text}
                  action={item.action}
                  red={item.red}
                />
              );
            }
          }
          return null;
        })}
        {underlineItems.length ? <hr /> : null}
        {underlineItems.map((item) => {
          if (
            item.id === "archiveTask" &&
            this.props.data.todoStatus !== "archived"
          ) {
            return (
              <DropdownItem
                key={item.id}
                disabled={disableUpdate}
                id={item.id}
                componentLeft={item.componentLeft}
                componentRight={item.componentRight}
                text={item.text}
                action={item.action}
                red={item.red}
              />
            );
          } else if (
            item.id === "unArchiveTask" &&
            this.props.data.todoStatus === "archived"
          ) {
            return (
              <DropdownItem
                key={item.id}
                disabled={disableUpdate}
                id={item.id}
                componentLeft={item.componentLeft}
                componentRight={item.componentRight}
                text={item.text}
                action={item.action}
                red={item.red}
              />
            );
          }
          return null;
        })}
      </div>,
      this.el,
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.essentialLists.types,
  };
};

export default connect(mapStateToProps, {
  updateTodo,
})(TodoDropdownWrapper);
