import React, { Component } from "react";

import { Card, FormGroup, CardBody, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import TodoStatusPicker from "../todo-statuses";
import TodoTypesPicker from "../todo-types";

import { Formik, Field } from "formik";
import "../../../assets/scss/components/fts/filter.scss";
// import Switch from "../switch";

import "flatpickr/dist/themes/light.css";
import "../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss";
import "../../../assets/scss/components/fts/form-fields.scss";
import Icon from "../Icons";
import moment from "moment";
import AdminPicker from "../admin-picker";

export default class Filter extends Component {
  render() {
    const {
      dateRef,
      // adminViewSelected,
      handleDateChange,
      handleStatusChange,
      handleTypeChange,
      // handleAdminViewChange,
      callToAction,
      todoStatuses,
      tabs,
      activeTab,
      updateActiveTab,
      fromDate,
      toDate,
      defaultTodoType,
      defaultTodoStatus,
      showNewBtn,
      onEmployeeUpdate,
    } = this.props;

    return (
      <Formik
        initialValues={{ status: defaultTodoStatus, type: defaultTodoType }}
      >
        {({ setFieldError, setFieldTouched }) => {
          return (
            <div className="filter-wrapper">
              <Card>
                <CardBody>
                  <div className="wrapper">
                    <div className="left">
                      <div className="nav">
                        {tabs.map((itm) => {
                          return (
                            <button
                              key={itm.type}
                              className={`tab ${
                                activeTab === itm.type
                                  ? "selected"
                                  : "unselected"
                              }`}
                              onClick={() => {
                                updateActiveTab(itm.type);
                              }}
                            >
                              {itm.label}
                            </button>
                          );
                        })}
                      </div>
                    </div>

                    <div className="right">
                      <div className="range">
                        <FormGroup className="position-relative has-icon-right">
                          <Field name="date">
                            {() => {
                              return (
                                <div className="fts-date-and-time">
                                  <div
                                    id="datepickerWrapperFrom"
                                    className="u-datepicker input-group"
                                  >
                                    <Flatpickr
                                      ref={dateRef}
                                      value={
                                        fromDate &&
                                        toDate && [
                                          moment(fromDate).toISOString(),
                                          moment(toDate).toISOString(),
                                        ]
                                      }
                                      placeholder="Select date range"
                                      className="fts-date-picker form-control date-picker"
                                      options={{
                                        mode: "range",
                                        dateFormat: "M d, Y",
                                        locale: {
                                          rangeSeparator: " - ",
                                        },
                                      }}
                                      onChange={(values) => {
                                        handleDateChange(values);
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            }}
                          </Field>

                          <div className="form-control-position">
                            <Icon name="Calendar" size={17} />
                          </div>

                          {this.props.dateRef.current?.flatpickr.input.value ? (
                            <div
                              className="form-control-position clear-calendar-button"
                              onClick={() => {
                                if (dateRef?.current?.flatpickr) {
                                  dateRef.current.flatpickr.clear();
                                  updateActiveTab("today");
                                }
                              }}
                            >
                              <Icon name="ClearCalendar" size={16} />
                            </div>
                          ) : null}
                        </FormGroup>
                      </div>

                      <div style={{ marginLeft: "1rem" }}>
                        <AdminPicker
                          name={"test"}
                          placeholder={"Select employee"}
                          // value={query.accountOwner}
                          onChange={(option) => {
                            if (option?.value) {
                              onEmployeeUpdate(option);
                            } else {
                              onEmployeeUpdate(null);
                            }
                          }}
                        />
                      </div>

                      <div className="column">
                        <FormGroup>
                          <Field name="status">
                            {({ meta, form, field }) => (
                              <TodoStatusPicker
                                name="status"
                                isClearable
                                hasAll={!defaultTodoStatus}
                                todoStatuses={todoStatuses}
                                taskManagement={true}
                                onChange={(option) => {
                                  if (option) {
                                    setFieldError("status", undefined);
                                  }
                                  form.setFieldValue("status", option);
                                  setTimeout(() => {
                                    setFieldTouched("status", true, true);
                                  }, 0);
                                  handleStatusChange(option);
                                }}
                                value={field.value}
                                required
                                {...{ meta }}
                              />
                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      <div className="column">
                        <FormGroup>
                          <Field name="type">
                            {({ meta, form, field }) => (
                              <TodoTypesPicker
                                name="type"
                                isClearable
                                onChange={(option) => {
                                  if (option) {
                                    setFieldError("type", undefined);
                                  }
                                  form.setFieldValue("type", option);
                                  setTimeout(() => {
                                    setFieldTouched("type", true, true);
                                  }, 0);
                                  handleTypeChange(option);
                                }}
                                onBlur={field.onBlur}
                                value={field.value}
                                required
                                taskManagement={true}
                                hasAll={!defaultTodoType}
                                {...{ meta }}
                              />
                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      {/* <Switch
                        className="column"
                        first={"All"}
                        second={"Me"}
                        selected={adminViewSelected}
                        changeSelected={handleAdminViewChange}
                      /> */}
                      {showNewBtn && (
                        <Button
                          type="button"
                          className="button"
                          color="primary"
                          outline
                          onClick={() => {
                            callToAction("todo");
                          }}
                        >
                          + New personal todo
                        </Button>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          );
        }}
      </Formik>
    );
  }
}
