/* eslint-disable indent */
import React, { useState } from "react";
import * as Icons from "react-feather";
import FTSBadge from "../../fts/fts-badge/index";
import { connect } from "react-redux";

// import { formatDate } from "../../../configs/dateConfig";
import { history, historyRefresh } from "../../../history";
import Icon from "../Icons";

import Avatar from "../../@vuexy/avatar/AvatarComponent";
import TodoDropdown from "../todo-dropdown";

import "../../../assets/scss/components/fts/todo-small.scss";
import moment from "moment";
import { updateTodo } from "../../../redux/actions/todo";

const TodoSmallComponent = ({
  data,
  callToAction,
  isdragging,
  activeDropdown,
  setActiveDropdown,
  handleArchive,
  handleUnArchive,
  handleTodoTypeUpdate,
  handleTodoUsersUpdate,
  getEmptyColumns,
  handleEmptyCols,
  list,
  userRef,
  user,
  isSidebarList,
  types,
  isListing,
  listingID,
  userData,
  updateTodo,
  fetchTodos,
}) => {
  const {
    title,
    reminderDate,
    reminderTime,
    assetsCount,
    notesCount,
    adminOwner,
    owner,
    relatedUsers,
    relatedAds,
    todoType,
    todoAdmins,
  } = data;

  const [collapseArchived, setCollapseArchived] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const todoStatus = data.todoStatus.toLowerCase();
  const typeString = Array.isArray(todoType) ? todoType[0] : todoType;

  const expiringToday = (remDate) => {
    const today = new Date();
    const todayDate = today.toISOString().split("T")[0];

    return remDate[0] === todayDate;
  };

  types = types?.filter(function (obj) {
    return obj.type === "both" || obj.type === "todo";
  });

  let type = null;
  if (types) {
    type = types.find((item) => {
      return (
        item.value === typeString ||
        item.description.toLowerCase() === typeString.toLowerCase()
      );
    });
  }

  const todoOwner = adminOwner || owner;

  let red;
  let redComponent;
  let overdueComponent;
  let cardDate;

  let date = reminderDate.split("T");
  let fullDateString = date[0] + "T" + reminderTime;

  let remDate = new Date(fullDateString);
  let remDateAndTime = remDate.getTime();
  let today = new Date().getTime();

  let dateOffset = 24 * 60 * 60 * 1000 * 3;
  let days = remDate;
  days.setTime(days.getTime() - dateOffset);
  days = days.getTime();

  if (today > days) {
    red = true;
  }

  if (expiringToday(date)) {
    if (today > remDateAndTime) {
      cardDate = "Expired Today";
      redComponent = true;
    } else {
      cardDate = "Due Today";
    }
  } else {
    if (today > remDateAndTime) {
      overdueComponent = true;
    }
    cardDate = moment(reminderDate).format("ll");
  }

  const formatListingLink = (ad) => {
    let formatedListing = "";

    if (ad.id) {
      formatedListing += ad.id;
    }
    if (ad.name) {
      formatedListing += "-" + ad.name;
    }
    if (ad.city) {
      formatedListing += "-" + ad.city;
    }
    if (ad.state) {
      formatedListing += "-" + ad.state;
    }

    return formatedListing;
  };

  let mainListing = null;
  let collapseListings = [];

  if (relatedAds?.length) {
    if (isListing) {
      if (relatedAds?.length === 1) {
        mainListing = relatedAds[0];
      } else if (relatedAds?.length > 1) {
        relatedAds.forEach((listing) => {
          const ad = listing;
          if (listing?.id === parseInt(listingID)) {
            mainListing = ad;
          } else {
            collapseListings.push(ad);
          }
        });
      }
    } else {
      mainListing = relatedAds[0];
      relatedAds.forEach((listing) => {
        const ad = listing;
        if (listing.id !== mainListing.id) {
          collapseListings.push(ad);
        }
        return;
      });
    }
  }

  let accounts = "";
  if (relatedUsers) {
    relatedUsers.forEach((user) => {
      accounts += user?.company_name + " ";
    });
  }

  const disableUpdate = !(
    user === (data.adminOwnerId ?? data.owner?.id ?? data.ownerId)
  );

  const isPersonalTodo = relatedAds
    ? (!relatedAds[0] && !relatedUsers[0]) ||
      (!mainListing &&
        !collapseListings?.length &&
        !relatedUsers[0] &&
        adminOwner?.id === user)
    : false;

  const isArchived = () => {
    return data.todoStatus === "archived";
  };

  return (
    <div className="fts-todo-wrapper">
      <div
        className={`fts-todo-small no-border ${
          !isdragging ? "" : "column-item-dragging"
        }`}
        onClick={() => {
          return callToAction("todo", true, data);
        }}
      >
        <div
          className={`fts-todo-small-body
          ${isArchived() ? "archived" : ""}
          ${!isArchived() && !redComponent ? "fts-todo-shadow" : ""}
          ${
            isPersonalTodo && !isArchived() && !redComponent
              ? red
                ? "fts-personal-card-red"
                : "fts-personal-card"
              : ""
          }
          ${redComponent && !isArchived() ? "red-component" : ""}
          ${red && !isArchived() ? "red-border" : ""}
          ${overdueComponent && !isArchived() ? "overdue-component" : ""}
          `}
        >
          <div
            className={`${
              redComponent && !isArchived()
                ? "fts-todo-head-red"
                : "fts-todo-head"
            }`}
          >
            <div className="fts-todo-top-row">
              {isArchived() ? (
                <div className="archived-row">
                  <Icon name="TodoArchived" />
                  Archived
                </div>
              ) : (
                <div className={`fts-todo-date ${red ? "red" : null}`}>
                  <Icons.Calendar size={20} />
                  {cardDate}
                </div>
              )}

              {isSidebarList ? (
                <div className="header-right">
                  <FTSBadge
                    style={{
                      backgroundColor: type?.color,
                    }}
                  >
                    {type?.description}
                  </FTSBadge>
                  {isArchived() ? (
                    <button
                      className="colapse-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCollapseArchived(!collapseArchived);
                      }}
                    >
                      {collapseArchived ? (
                        <Icons.ChevronUp size={17} className="chevron" />
                      ) : (
                        <Icons.ChevronDown size={17} className="chevron" />
                      )}
                    </button>
                  ) : null}
                </div>
              ) : (
                <TodoDropdown
                  fetchTodos={fetchTodos}
                  updateTodo={updateTodo}
                  id={data.id}
                  todoType={data.todoType}
                  activeDropdown={activeDropdown}
                  setActiveDropdown={setActiveDropdown}
                  callToAction={callToAction}
                  handleArchive={handleArchive}
                  handleUnArchive={handleUnArchive}
                  handleTodoTypeUpdate={handleTodoTypeUpdate}
                  handleTodoUsersUpdate={handleTodoUsersUpdate}
                  handleEmptyCols={handleEmptyCols}
                  getEmptyColumns={getEmptyColumns}
                  list={list}
                  data={data}
                  userData={userData}
                  userRef={userRef}
                  disableUpdate={disableUpdate}
                />
              )}
            </div>

            {(todoStatus === "archived" && collapseArchived) ||
            todoStatus !== "archived" ||
            !isSidebarList ? (
              <div>
                <div>
                  {isPersonalTodo ? (
                    <div className="fts-account-listing-wrapper mt-1">
                      <div className="fts-personal">Personal</div>
                    </div>
                  ) : (
                    <div className="fts-listing-account mt-1">
                      {accounts ? (
                        <div className="fts-account-listing-wrapper">
                          <div
                            className={
                              (isSidebarList && isListing) || !isSidebarList
                                ? "fts-account"
                                : ""
                            }
                            onClick={(event) => {
                              event.stopPropagation();
                              if (!isSidebarList) {
                                history.push({
                                  pathname: `/accounts/${relatedUsers[0]?.id}`,
                                  search: `?toDoId=${data.id}`,
                                  data: data,
                                  note: false,
                                });
                              } else {
                                if (isListing) {
                                  history.push({
                                    pathname: `/accounts/${relatedUsers[0]?.id}`,
                                    search: `?toDoId=${data.id}`,
                                    data: data,
                                    note: false,
                                  });
                                }
                              }
                            }}
                          >
                            {accounts}
                          </div>
                        </div>
                      ) : null}

                      {mainListing ? (
                        <div className="fts-account-listing-wrapper">
                          <div className="listings-wrapper">
                            <div className="main-listing">
                              <div
                                className="fts-listing fts-listing-link"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (!isSidebarList) {
                                    history.push({
                                      pathname: `/listings/${mainListing?.id}`,
                                      search: `?toDoId=${data.id}`,
                                      data: data,
                                      note: false,
                                    });
                                  } else {
                                    history.push({
                                      pathname: `/listings/${mainListing?.id}`,
                                      search: `?toDoId=${data.id}`,
                                      data: data,
                                      note: false,
                                    });
                                  }
                                }}
                              >
                                {formatListingLink(mainListing)}
                              </div>
                              {collapseListings.length ? (
                                <button
                                  className={`listing-button ${
                                    collapsed ? "collapsed" : null
                                  }`}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setCollapsed(!collapsed);
                                  }}
                                >
                                  <Icon name="ChevronDown" />
                                </button>
                              ) : null}
                            </div>
                            {collapsed && (
                              <div className="collapse-listings">
                                {collapseListings.map((ad) => {
                                  return (
                                    <div
                                      key={ad.id}
                                      className="fts-listing fts-listing-link"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        if (!isSidebarList) {
                                          history.push({
                                            pathname: `/listings/${ad?.id}`,
                                            search: `?toDoId=${data.id}`,
                                            data: data,
                                            note: false,
                                          });
                                        } else {
                                          historyRefresh.push({
                                            pathname: `/listings/${ad?.id}`,
                                            search: `?toDoId=${data.id}`,
                                            data: data,
                                            note: false,
                                          });
                                        }
                                      }}
                                    >
                                      {formatListingLink(ad)}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                  <hr />
                </div>

                <div
                  className={
                    isSidebarList
                      ? "fts-todo-title cursor-pointer"
                      : !(
                          user ===
                          (data.adminOwnerId ?? data.owner?.id ?? data.ownerId)
                        )
                      ? "fts-todo-title-no-pointer"
                      : "fts-todo-title cursor-pointer"
                  }
                  onClick={() => {
                    if (isSidebarList) {
                      return callToAction("todo", true, data);
                    }
                    if (
                      user ===
                      (data.adminOwnerId ?? data.owner?.id ?? data.ownerId)
                    )
                      return callToAction("todo", true, data);
                  }}
                >
                  {title}
                </div>

                <div className="fts-todo-bottom-row">
                  <div className="fts-todo__people">
                    <div className="fts-todo__owner">
                      <div className="ml-auto fts-todo__participants">
                        {todoOwner && todoOwner.firstName && (
                          <div className="owner">
                            <span className="owner-crown">
                              <Icon name="Crown" />
                            </span>
                            <Avatar
                              fullName={`${todoOwner?.firstName} ${todoOwner?.lastName}`}
                              className="sm-size avatar-owner"
                              key={todoOwner?.id}
                              content={`${todoOwner?.firstName[0]}${todoOwner?.lastName[0]}`.toUpperCase()}
                            />
                          </div>
                        )}

                        {todoAdmins?.length > 0 && todoAdmins?.length < 3 && (
                          <div className="ml-auto fts-todo__participants">
                            {todoAdmins.map((itm) => {
                              if (itm.firstName) {
                                return (
                                  <Avatar
                                    fullName={`${itm.firstName} ${itm.lastName}`}
                                    className="sm-size"
                                    key={itm?.id}
                                    content={`${itm.firstName[0]}${itm.lastName[0]}`.toUpperCase()}
                                  />
                                );
                              }
                              return null;
                            })}
                          </div>
                        )}
                        {todoAdmins?.length > 2 && (
                          <div className="ml-auto fts-todo__participants">
                            {todoAdmins[0]?.firstName &&
                            todoAdmins[0]?.lastName ? (
                              <Avatar
                                fullName={`${todoAdmins[0]?.firstName} ${todoAdmins[0]?.lastName}`}
                                className="sm-size"
                                key={todoAdmins[0].id}
                                content={`${todoAdmins[0].firstName[0]}${todoAdmins[0].lastName[0]}`.toUpperCase()}
                              />
                            ) : null}

                            {todoAdmins[1]?.firstName &&
                            todoAdmins[1]?.lastName ? (
                              <Avatar
                                fullName={`${todoAdmins[1]?.firstName} ${todoAdmins[1]?.lastName}`}
                                className="sm-size"
                                key={todoAdmins[1]?.id}
                                content={`${todoAdmins[1]?.firstName[0]}${todoAdmins[1]?.lastName[0]}`.toUpperCase()}
                              />
                            ) : null}

                            <Avatar
                              className="sm-size"
                              key={"number"}
                              content={`+${todoAdmins?.length - 2}`}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fts-todo-meta d-flex align-items-center">
                    {notesCount > 0 ? (
                      <div className="fts-todo-attached">
                        <Icon name="NoteBottom" size={18} color="#9A6C6C" />
                        <span>{notesCount}</span>
                      </div>
                    ) : null}
                    {assetsCount > 0 ? (
                      <div className="ml-1 fts-todo-attached">
                        <Icon name="AttachBottom" size={18} color="#7D8C91" />
                        <span>{assetsCount}</span>
                      </div>
                    ) : null}
                  </div>{" "}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
  };
};
const mapDispatchToProps = () => ({
  updateTodo,
});
export default connect(mapStateToProps, mapDispatchToProps)(TodoSmallComponent);
