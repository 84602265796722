import * as Yup from "yup";
import { urlValidate } from "../../dist/types/validators/global";

export const accountSocialLinksRowValidator = Yup.object().shape({
  other: urlValidate,
  linkedin: urlValidate,
  twitter: urlValidate,
  instagram: urlValidate,
  facebook: urlValidate,
  google: urlValidate,
});
