/* eslint-disable indent */
import React, { Component } from "react";
import { Label } from "reactstrap";

import Select from "../Select";
import { components } from "react-select";

import { pickerStyles, controlStyles } from "../picker-style";
import capitalize from "../../../utility/capitalize";

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div
          style={{
            paddingRight: "0.5rem",

            color: "#9CA6A9",
          }}
        >
          Filter by:
        </div>

        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};

class NoteTypePicker extends Component {
  render() {
    const { label, name, hasAll, ...rest } = this.props;

    const noteTypes = ["all", "todo", "listing", "sticky"];
    const pickerStyle = {
      ...pickerStyles,
      borderWidth: 0,
      control: (styles, state) => controlStyles(styles, state),
    };

    let options = noteTypes.map((type) => ({
      value: type,
      label: capitalize(type),
    }));

    if (hasAll) {
      options = [{ label: "All", value: "" }, ...options];
    }

    const defaultValue = options.find((itm) => itm.label === "All");

    return (
      <div style={{ width: "100%" }}>
        <div>
          {!!label && (
            <Label className={rest.isDisabled ? "disabled" : ""} for={name}>
              {label}
            </Label>
          )}
        </div>
        <div>
          <Select
            {...{ defaultValue, label }}
            {...{ defaultValue, label }}
            isSearchable={true}
            isClearable={true}
            options={options}
            styles={pickerStyle}
            name={name}
            components={{
              ValueContainer: (props) => (
                <ValueContainer showInnerLabel={hasAll} {...props} />
              ),
            }}
            onChange={this.props.onChange}
            {...rest}
          />
        </div>
      </div>
    );
  }
}

export default NoteTypePicker;
