/* eslint-disable indent */
import { Field, Form, Formik } from "formik";
import React, { createRef } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Spinner,
  CustomInput,
} from "reactstrap";
import { connect } from "react-redux";
import isObject from "lodash/isObject";

import StatusFilter from "../../../components/fts/status-filter";
import AccountTypePicker from "../../../components/fts/account-type-picker";
import LeadsStatusPicker from "../../../components/fts/leads-status-picker";
import FormikInput from "../../../components/fts/formik/formik-input";
import StatesPicker from "../../../components/fts/states-picker";
import { FTSEmail } from "../../../components/fts/email";
import Services from "../../../components/fts/services";
import {
  postUserData,
  setNewAccountsCount,
} from "../../../redux/actions/users";
import { createNewNote } from "../../../redux/actions/notes";
import { createActivity } from "../../../redux/actions/activities";
import { history } from "../../../history";
import { extractFormikCategoriesData } from "../../../utility/extract";
import BackButton from "../../../components/fts/create-BackButton/BackButton";

import { createSchema as validationSchema } from "../../../formik-validations/account-information";

import FormikMaskedInput from "../../../components/fts/formik/masked-input";
import { phoneNumberMask, zipCodeMask } from "../../../utility/masks";

import FormikConfirmSwitch from "../../../components/fts/formik-confirm-switch";
import FTSNote from "../../../components/fts/fts-note";
import FormValidationEffect from "../../../components/fts/form-validation-effect";
import TitleRow from "../../../components/fts/TitleRow";
import PromptWrapper from "../../../components/fts/PromptWrapper";

import classNames from "classnames";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";
import "../../../assets/scss/components/fts/form-fields.scss";
import "./style.scss";
import { activities } from "../../../constants";

class CreateCustomer extends React.Component {
  state = {
    inProgress: false,
    initialValues: {
      adminCorporateAccount: false,
      adminIsMerchant: false,
      excludeRenewalEmails: false,
      excludeDowngrades: false,
      firstName: "",
      lastName: "",
      adminNote: "",
      type: 3,
      userType: 3,
      userTypeNamed: "vendor",
      source: "Phone call",
      companyName: "",
      adminStatus: this.props.isSalesPage ? 2 : "",
      leadStatus: 0,
      address: "",
      isSticky: false,
      city: "",
      zip: "",
      adminNoteType: this.props.types.filter(
        (element) => element.type === "note",
      )[0],
      state: "",
      phoneNumber: "",
      mobilePhone: "",
      yearsBusiness: "",
      email: "",
      website: "",
      otherServices: "",
      jobTitle: "",
      marketingEmail: false,
      additionalEmail: "",
      marketingAdditionalEmail: false,
      primaryMarketingOptIn: false,
      primaryUpdatesOptIn: false,
      primaryRenewalsOptIn: false,
      additionalMarketingOptIn: false,
      additionalUpdatesOptIn: false,
      additionalRenewalsOptIn: false,
    },
    showLeadsDropdown: false,
  };

  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  componentDidMount() {
    this.ref.current.resetForm();

    if (this.props.isSalesPage) {
      this.setState({
        showLeadsDropdown: true,
      });
    }
  }

  onFormSubmission = (values, { resetForm }) => {
    if (this.state.inProgress) {
      return;
    }

    window.scrollTo(0, 0);

    this.setState({ inProgress: true });

    const { userCategories, userSubCategories } = extractFormikCategoriesData(
      values.categories || {},
    );
    const payload = {
      ...values,
      adminStatus:
        typeof values.adminStatus === "number"
          ? values.adminStatus
          : values.adminStatus.value,
      leadStatus:
        typeof values?.leadStatus === "object"
          ? values?.leadStatus?.value
          : values?.leadStatus,
      state: values.state.value,
      companyName: encodeURIComponent(values?.companyName || ""),
      userCategories,
      userSubCategories,
      userTypeNamed:
        typeof values.userType === "object"
          ? values.userType.userTypeNamed.toLowerCase()
          : values.userTypeNamed,
      userType:
        typeof values.userType === "object"
          ? values.userType.value
          : values.userType,
      type:
        typeof values.userType === "object"
          ? values.userType.value
          : values.type,
    };

    let noteData = null;
    if (values.adminNote && values.adminNote.length) {
      noteData = {
        isAdmin: true,
        note: values.adminNote,
        noteType: values.adminNoteType ? [values.adminNoteType.value] : "",
        noteTypeId: values.adminNoteType.id,
        ownerId: this.props.userData.loggedInUser.id,
        isSticky: values.isSticky,
      };
    }

    this.props
      .postUserData(payload)
      .then(async ({ data }) => {
        const userId = data.user.id;
        this.props.setNewAccountsCount(this.props.newAccountUnReadCount + 1);

        let activityPayload = {
          status: 1,
          userId: data.user.id,
          activityType: this.props.activitiesTypes.CREATED_ACCOUNT,
          activity: activities.createAccount
            .replace(
              "{{admin_name}}",
              `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
            )
            .replace("{{companyName}}", data.user.companyName)
            .replace("{{city}}", data.user.city)
            .replace("{{state}}", data.user.state)
            .replace(
              "{{phonePart}}",
              data.user.phoneNumber ? `, phone: ${data.user.phoneNumber}` : "",
            ),
          adminUserId: this.props.userData.loggedInUser.id,
          iconName: "AccountsActivity",
        };

        await this.props.createActivity(activityPayload);

        if (noteData) {
          noteData.userId = userId;
          noteData.ownerId = userId;
          this.props
            .createNewNote(noteData)
            .then(() => {
              resetForm(this.state.initialValues);
              this.ref.current.resetForm();
              toast.success(data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              this.setState({ inProgress: false });
              history.push(`/accounts/${userId}`);
            })
            .catch((err) => {
              console.log(err);
              toast.error("Oops! Something went wrong. Please try again.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              this.setState({ inProgress: false });
            });
        } else {
          resetForm(this.state.initialValues);
          this.ref.current.resetForm();
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({
            inProgress: false,
          });
          history.push(`/accounts/${userId}`);
        }
      })
      .catch((err) => {
        console.log(err);
        const {
          response: { status },
        } = err;
        switch (status) {
          case 422:
            toast.error("User with this email already exists.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            break;

          default:
            toast.error("Oops! Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            break;
        }
        this.setState({
          inProgress: false,
        });
      });
  };

  getFirstErrorKey = (object, keys = []) => {
    const firstErrorKey = Object.keys(object)[0];
    if (isObject(object[firstErrorKey])) {
      return this.getFirstErrorKey(object[firstErrorKey], [
        ...keys,
        firstErrorKey,
      ]);
    }
    return [...keys, firstErrorKey].join(".");
  };

  onSubmitError = (errors) => {
    let key = this.getFirstErrorKey(errors);
    if (key === "adminNoteType") {
      key = "adminNote";
    }
    let element;

    if (document.getElementsByName(key).length) {
      element = document.getElementsByName(key)[0];
    } else {
      element = document.getElementById(key);
    }
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    toast.error("Unable to proceed. Invalid form!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  render() {
    const { inProgress, initialValues } = this.state;
    const { isSalesPage } = this.props;

    return (
      <div className="create-new-customer">
        <Card>
          {!isSalesPage && (
            <div className="create-header d-flex align-items-center">
              <div>
                <BackButton />
              </div>
              <div className="create-header__title">Create new account</div>
              {inProgress && (
                <div className="ml-auto">
                  <Spinner size="sm" className="float-right" />
                </div>
              )}
            </div>
          )}
          <CardBody style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}>
            <Formik
              innerRef={(ref) => {
                this.ref.current = ref;
                this.props.setFormikSidebarRef &&
                  this.props.setFormikSidebarRef(ref);
              }}
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={true}
              onSubmit={this.onFormSubmission}
              {...{ initialValues, validationSchema }}
            >
              {({ dirty, setFieldError, setFieldTouched, ...rest }) => (
                <>
                  <FormValidationEffect
                    formik={rest}
                    onSubmissionError={this.onSubmitError}
                  />
                  <PromptWrapper dirty={dirty} />

                  <Form autoComplete="off">
                    <Row>
                      <Col md="4" className="fts-double-switch-container">
                        <Row>
                          <Col>
                            <FormikConfirmSwitch
                              fieldName="adminIsMerchant"
                              label="Merchant"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormikConfirmSwitch
                              fieldName="adminCorporateAccount"
                              label="Corporate"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Field name="userType">
                            {({ meta, form }) => (
                              <AccountTypePicker
                                isDisabled={true}
                                label="Account type"
                                name="userType"
                                placeholder="Select account type..."
                                setFieldValue={form.setFieldValue}
                                {...{ meta }}
                              />
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <div
                        className={classNames({
                          "col-md-3 col-lg-3 col-xl-2":
                            this.state.showLeadsDropdown,
                          "col-md-6 col-xl-4": !this.state.showLeadsDropdown,
                        })}
                      >
                        <FormGroup>
                          <Field name="adminStatus">
                            {({ field, meta, form }) => (
                              <StatusFilter
                                hideCustomer={true}
                                account
                                required
                                label="Account label"
                                placeholder="Select account label..."
                                onStateChange={(option) => {
                                  if (option) {
                                    setFieldError("adminStatus", undefined);

                                    if (option.value === 2) {
                                      this.setState({
                                        showLeadsDropdown: true,
                                      });
                                    } else {
                                      this.setState({
                                        showLeadsDropdown: false,
                                      });
                                    }
                                  } else {
                                    this.setState({
                                      showLeadsDropdown: false,
                                    });
                                  }
                                  form.setFieldValue("adminStatus", option);
                                  setTimeout(() => {
                                    setFieldTouched("adminStatus", true, true);
                                  }, 0);
                                }}
                                value={field.value}
                                {...{ field, meta }}
                              />
                            )}
                          </Field>
                        </FormGroup>
                      </div>
                      {this.state.showLeadsDropdown && (
                        <Col md="3" lg="3" xl="2">
                          <FormGroup>
                            <Field name="leadStatus">
                              {({ field, meta, form }) => (
                                <LeadsStatusPicker
                                  account
                                  label="Lead status"
                                  placeholder="Select lead status..."
                                  onStateChange={(option) => {
                                    if (option) {
                                      setFieldError("leadStatus", undefined);
                                    }
                                    form.setFieldValue("leadStatus", option);
                                    setTimeout(() => {
                                      setFieldTouched("leadStatus", true, true);
                                    }, 0);
                                  }}
                                  value={field.value}
                                  {...{ field, meta }}
                                />
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col md="6" xl="4">
                        <FormikInput
                          name="firstName"
                          label="First name*"
                          labelId="firstName"
                        />
                      </Col>
                      <Col md="6" xl="4">
                        <FormikInput
                          name="lastName"
                          label="Last name*"
                          labelId="lastName"
                        />
                      </Col>
                      <Col md="6" xl="4">
                        <FormikInput
                          name="companyName"
                          label="Company name*"
                          labelId="companyName"
                        />
                      </Col>
                      <Col md="6" xl="4">
                        <FormikInput
                          name="address"
                          label="Street address*"
                          labelId="address"
                        />
                      </Col>
                      <Col md="6" xl="4">
                        <FormikInput name="city" label="City*" labelId="city" />
                      </Col>
                      <Col md="3" lg="3" xl="2">
                        <FormGroup>
                          <Field name="state">
                            {({ meta, form, field }) => (
                              <StatesPicker
                                name="state"
                                label="State*"
                                placeholder="Select state..."
                                onStateChange={(option) => {
                                  if (option) {
                                    setFieldError("state", undefined);
                                  }
                                  form.setFieldValue("state", option);
                                  setTimeout(() => {
                                    setFieldTouched("state", true, true);
                                  }, 0);
                                }}
                                value={field.value}
                                onBlur={field.onBlur}
                                {...{ meta }}
                              />
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col md="3" lg="3" xl="2">
                        <FormikMaskedInput
                          name="zip"
                          label="Zip code*"
                          labelId="zip"
                          mask={zipCodeMask}
                        />
                      </Col>
                      <Col md="6" lg="6" xl="4">
                        <FormikMaskedInput
                          name="phoneNumber"
                          label="Business phone"
                          labelId="phoneNumber"
                          mask={phoneNumberMask}
                        />
                      </Col>
                      <Col md="6" lg="6" xl="4">
                        <FormikMaskedInput
                          name="mobilePhone"
                          label="Mobile phone"
                          labelId="mobilePhone"
                          mask={phoneNumberMask}
                        />
                      </Col>
                      <Col md="6" lg="6" xl="4" />

                      <Col sm="12" md="12" lg="12" xl="12">
                        <hr></hr>
                      </Col>

                      <Col sm="12" md="6" lg="4" xl="3">
                        <FTSEmail
                          required
                          name="email"
                          label="E-mail"
                          // optIn={true}
                          labelOptIn="Marketing Opt-in"
                          optInID="marketingEmail"
                        />
                      </Col>

                      <Col sm="12" md="6" lg="4" xl="3">
                        <FormGroup
                          className="mb-1"
                          style={{
                            marginTop: "2.7rem",
                          }}
                        >
                          <Field name="primaryMarketingOptIn">
                            {({ field: { name }, form }) => {
                              // const accountPrimaryMarketingOptIn =
                              //   this.props.accountData?.primaryMarketingOptIn;

                              return (
                                <CustomInput
                                  defaultChecked={
                                    false
                                    // accountPrimaryMarketingOptIn === 1 || value
                                  }
                                  type="switch"
                                  id="marketingOptIn"
                                  inline
                                  onChange={(e) => {
                                    form.setFieldValue(name, e.target.checked);
                                  }}
                                  // disabled={
                                  //   !havePermissionForMarketingOptIn || havePermissionForMarketingOptIn === 1 ||
                                  // }
                                >
                                  <span className="ml-1">Marketing Opt-in</span>
                                </CustomInput>
                              );
                            }}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="4" xl="3">
                        <FormGroup
                          className="mb-1"
                          style={{
                            marginTop: "2.7rem",
                          }}
                        >
                          <Field name="primaryUpdatesOptIn">
                            {({ field: { name }, form }) => {
                              // const accountPrimaryUpdatesOptIn =
                              //   this.props.accountData?.primaryUpdatesOptIn;

                              return (
                                <CustomInput
                                  defaultChecked={
                                    false
                                    // accountPrimaryUpdatesOptIn === 1 || value
                                  }
                                  type="switch"
                                  id="primaryUpdatesOptIn"
                                  inline
                                  onChange={(e) => {
                                    form.setFieldValue(name, e.target.checked);
                                  }}
                                  // disabled={
                                  //   !havePermissionForUpdatesOptIn || havePermissionForUpdatesOptIn === 1 ||
                                  // }
                                >
                                  <span className="ml-1">Updates Opt-in</span>
                                </CustomInput>
                              );
                            }}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="4" xl="3">
                        <FormGroup
                          className="mb-1"
                          style={{
                            marginTop: "2.7rem",
                          }}
                        >
                          <Field name="primaryRenewalsOptIn">
                            {({ field: { name }, form }) => {
                              // const accountPrimaryRenewalOptIn =
                              //   this.props.accountData?.primaryRenewalOptIn;

                              return (
                                <CustomInput
                                  defaultChecked={
                                    false
                                    // accountPrimaryRenewalOptIn === 1 || value
                                  }
                                  type="switch"
                                  id="primaryRenewalsOptIn"
                                  inline
                                  onChange={(e) => {
                                    form.setFieldValue(name, e.target.checked);
                                  }}
                                  // disabled={
                                  //   !havePermissionForRenewalOptIn || havePermissionForRenewalOptIn === 1 ||
                                  // }
                                >
                                  <span className="ml-1">Renewal Opt-in</span>
                                </CustomInput>
                              );
                            }}
                          </Field>
                        </FormGroup>
                      </Col>

                      <Col sm="12" md="12" lg="12" xl="12">
                        <hr></hr>
                      </Col>

                      <Col sm="12" md="6" lg="4" xl="3">
                        <FTSEmail
                          name="additionalEmail"
                          label="Additional E-mail"
                          // optIn={true}
                          labelOptIn="Marketing Opt-in"
                          optInID="marketingAdditionalEmail"
                        />
                      </Col>

                      <Col sm="12" md="6" lg="4" xl="3">
                        <FormGroup
                          className="mb-1"
                          style={{
                            marginTop: "2.7rem",
                          }}
                        >
                          <Field name="additionalMarketingOptIn">
                            {({ field: { name }, form }) => {
                              // const accountAdditioanlMarketingOptIn =
                              //   this.props.accountData?.additionalMarketingOptIn;

                              return (
                                <CustomInput
                                  defaultChecked={
                                    false
                                    // accountAdditionalMarketingOptIn === 1 || value
                                  }
                                  type="switch"
                                  id="additionalMarketingOptIn"
                                  inline
                                  onChange={(e) => {
                                    form.setFieldValue(name, e.target.checked);
                                  }}
                                  // disabled={
                                  //   !havePermissionForMarketingOptIn || havePermissionForMarketingOptIn === 1 ||
                                  // }
                                >
                                  <span className="ml-1">Marketing Opt-in</span>
                                </CustomInput>
                              );
                            }}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="4" xl="3">
                        <FormGroup
                          className="mb-1"
                          style={{
                            marginTop: "2.7rem",
                          }}
                        >
                          <Field name="additionalUpdatesOptIn">
                            {({ field: { name }, form }) => {
                              // const accountAdditionalUpdatesOptIn =
                              //   this.props.accountData?.additionalUpdatesOptIn;

                              return (
                                <CustomInput
                                  defaultChecked={
                                    false
                                    // accountPrimaryUpdatesOptIn === 1 || value
                                  }
                                  type="switch"
                                  id="additionalUpdatesOptIn"
                                  inline
                                  onChange={(e) => {
                                    form.setFieldValue(name, e.target.checked);
                                  }}
                                  // disabled={
                                  //   !havePermissionForUpdatesOptIn || havePermissionForUpdatesOptIn === 1 ||
                                  // }
                                >
                                  <span className="ml-1">Updates Opt-in</span>
                                </CustomInput>
                              );
                            }}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="4" xl="3">
                        <FormGroup
                          className="mb-1"
                          style={{
                            marginTop: "2.7rem",
                          }}
                        >
                          <Field name="additionalRenewalsOptIn">
                            {({ field: { name }, form }) => {
                              // const accountAdditionalRenewalOptIn =
                              //   this.props.accountData?.additionalRenewalOptIn;

                              return (
                                <CustomInput
                                  defaultChecked={
                                    false
                                    // accountAdditionalRenewalOptIn === 1 || value
                                  }
                                  type="switch"
                                  id="additionalRenewalsOptIn"
                                  inline
                                  onChange={(e) => {
                                    form.setFieldValue(name, e.target.checked);
                                  }}
                                  // disabled={
                                  //   !havePermissionForRenewalOptIn || havePermissionForRenewalOptIn === 1 ||
                                  // }
                                >
                                  <span className="ml-1">Renewal Opt-in</span>
                                </CustomInput>
                              );
                            }}
                          </Field>
                        </FormGroup>
                      </Col>

                      <Col sm="12" md="12" lg="12" xl="12">
                        <hr></hr>
                      </Col>

                      <Col md="6" xl="4">
                        <FormikInput
                          name="website"
                          label="Website"
                          labelId="website"
                        />
                      </Col>
                      <Col md="6" xl="4">
                        <FormikInput
                          name="jobTitle"
                          label="Job title"
                          labelId="jobTitle"
                        />
                      </Col>
                      <Col md="6" xl="4">
                        <FormikInput
                          type="number"
                          name="yearsBusiness"
                          label="Years in business"
                          labelId="yearsBusiness"
                        />
                      </Col>
                    </Row>
                    <hr></hr>
                    <TitleRow
                      className="mb-0 pt-1"
                      title="Additional note to the account"
                      editMode="true"
                    ></TitleRow>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <FTSNote
                              noteRequired={false}
                              noSticky
                              name="adminNote"
                              label="Additional note"
                              labelId="todoNote"
                              typeName="adminNoteType"
                              typePlaceholder="Note type..."
                              placeholder="Some note goes here..."
                              stickyName="isSticky"
                              disabled={false}
                              {...{ setFieldError, setFieldTouched }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <br></br>
                    <Services
                      refer={this.ref}
                      title="Select services account provides"
                      editMode={true}
                    />
                    <Row>
                      <Col sm="12" className="text-right mt-1">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={inProgress}
                        >
                          {!isSalesPage ? "Create customer" : "Create new lead"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    types: state.essentialLists.types,
    activitiesTypes: state.essentialLists.activitiesTypes,
    newAccountUnReadCount: state.accountCounts.newAccountUnReadCount,
  };
};

export default connect(mapStateToProps, {
  postUserData,
  createNewNote,
  createActivity,
  setNewAccountsCount,
})(CreateCustomer);
