import React from "react";
import { Row, Col } from "reactstrap";
import classnames from "classnames";

import "../../assets/scss/components/fts/title-row.scss";
import Icons from "./Icons";
import FTSBadge from "./fts-badge";

const TitleRow = ({
  title,
  className,
  children,
  rightSection,
  noMarker,
  onTitleClick,
  isPrivateListing,
}) => {
  return (
    <Row
      className={classnames(
        `align-items-center fts-title-row ${className ?? ""}`,
        {
          "no-marker": noMarker,
        },
      )}
    >
      <Col>
        {rightSection ? (
          <div className="row-section-holder d-flex align-items-center">
            <div>
              <h4>{title}</h4>
            </div>
            <div>{rightSection()}</div>
            {isPrivateListing && <FTSBadge color={"danger"}>Private</FTSBadge>}
          </div>
        ) : (
          <div
            onClick={onTitleClick}
            className={`d-flex ${onTitleClick ? "go-to" : ""}`}
          >
            <h4 className={"mr-1"}>{title}</h4>
            {isPrivateListing && <FTSBadge color={"danger"}>Private</FTSBadge>}
            {onTitleClick && <Icons name={"GoTo"} />}
          </div>
        )}
      </Col>
      {children}
    </Row>
  );
};

export default TitleRow;
