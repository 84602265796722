export const newAdsRates = [
  "hourly_rate_hour_1",
  "afterhours_hour_2",
  "millage_mile_3",
  "service_call_fee_4",
  "minimum_charge_hrs_5",
  "truck_wash_6",
  "trailer_wash_7",
  "truck__trailer_wash_8",
];
export const formatAdsRates = (values, rates = []) => {
  values.adsRates = newAdsRates.map((e) => {
    const value = values[e];
    const rateId = e.split("_").pop();
    const rate = rates.find((rate) => rate.id === parseInt(rateId));
    return {
      adId: values.id,
      userId: values.userId,
      rateId: parseInt(rateId),
      value: value || "",
      rate,
    };
  });
  return values.adsRates;
};
