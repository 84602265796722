import FTSAxios from "../../../axios/fts.instance";

export const filterAmenities = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_AMENITIES_DATA", value });
};

export const getAmenitiesData = (queryParams = "?status=1&status=2") => {
  return (dispatch) => {
    return FTSAxios.get(`admin/lists/amenities${queryParams}`).then(
      (response) => {
        dispatch({
          type: "GET_AMENITIES_DATA",
          data: response.data,
          totalPages: 1,
        });
      },
    );
  };
};

export const updateAmenity = (payload) => {
  return () => {
    return FTSAxios.put(`admin/lists/amenities/${payload.id}`, payload);
  };
};

export const deleteAmenity = (payload) => {
  return () => {
    return FTSAxios.delete(`admin/lists/amenities/${payload.id}`);
  };
};

export const createAmenity = (payload) => {
  return () => {
    return FTSAxios.post("admin/lists/amenities/create", payload);
  };
};
