import React, { Component } from "react";

import Icon from "../Icons";
import FTSModal from "../fts-modal";
import { Button, Spinner } from "reactstrap";
import "../../../assets/scss/components/fts/disable-access-modal.scss";

class DisableAccessModal extends Component {
  render() {
    const {
      closeModal,
      onClose,
      confirmAction,
      disableAccountAccessInProgress,
    } = this.props;

    return (
      <FTSModal
        {...{ closeModal, onClose }}
        modalClasses={"fts-disable-access-modal"}
        centered={true}
        isOpen={true}
        style={{ maxWidth: "664px" }}
        footerCTA={() => {
          return (
            <div className="fts-disable-access-modal-footer">
              <Button className="mr-1" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                color="red"
                type="submit"
                disabled={disableAccountAccessInProgress}
                onClick={confirmAction}
              >
                {!disableAccountAccessInProgress ? (
                  <div>Yes, Disable</div>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </div>
          );
        }}
      >
        <div className="fts-disable-access-modal-body">
          <div className="fts-disable-access-modal-body-close">
            <Icon name="Close" size={32} onClick={closeModal} />
          </div>
          <div className="fts-disable-access-modal-body-content">
            <Icon name="LockDisabled" size={56} id="lock-icon" />
            <div className="w-100">
              <h3>Disable Remote Access</h3>
              <p>
                This will prevent Find Truck Service support from having remote
                access to this account. Are you sure you want to disable remote
                access?
              </p>
            </div>
          </div>
        </div>
      </FTSModal>
    );
  }
}

export default DisableAccessModal;
