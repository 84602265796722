/* eslint-disable indent */
/* eslint-disable no-extra-boolean-cast */
import React from "react";
import { connect } from "react-redux";

import "../../../assets/scss/pages/dashboard-tab.scss";
import { Row, Col, Spinner } from "reactstrap";
import FTSChart from "./Chart";
import ActivityChartSection from "./ActivityChartSection";
import NewBusinessSection from "./NewBusinessSection";
import RenewalsSection from "./RenewalsSection";
import DealsSection from "./DealsSection";
import UpgradesVsDowngradesSection from "./UpgradesVsDowngradesSection";
// import EmptyTodoSection from "./EmptyTodoSection";
import Icon from "../../../components/fts/Icons";
import { history } from "../../../history";
import { toast } from "react-toastify";
import TodoSection from "./TodoSection";

import {
  getLeads,
  getAdminActivities,
  getListings,
  getCustomerRenewals,
  getRenewals,
  getNewBusinesses,
  getTodos,
} from "../../../redux/actions/dashboard";

import {
  defaultChartOptions,
  formatResponseForChart,
  formatResponseForRenewals,
  formatListings,
  formatResponseForNewBusinesses,
  generateAdminActivitiesTest,
  formatResponseForTodos,
} from "./utility";
import EmptyTodoSection from "./EmptyTodoSection";

class Dashboard extends React.Component {
  state = {
    formattedNewBusinesses: {},
    formattedRenewals: {},
    activeTab: "daily",
    trialsCounter: 0,
    leadsSeries: [
      {
        name: "Leads",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    leadsOptions: { ...defaultChartOptions },
    trialsSeries: [
      {
        name: "Trials",
        data: [20, 25, 30, 15, 0, 15, 30, 50, 35, 20, 19, 18],
      },
    ],
    trialsOptions: {
      ...defaultChartOptions,
      stroke: {
        ...defaultChartOptions.stroke,
        colors: ["#F5B053"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
    renewalsSeries: [
      {
        name: "Renewals",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    renewalsOptions: {
      ...defaultChartOptions,
      stroke: {
        ...defaultChartOptions.stroke,
        colors: ["#01B5F5"],
      },

      xaxis: {
        categories: [
          "13:00",
          "15:00",
          "17:00",
          "19:00",
          "21:00",
          "23:00",
          "01:00",
          "03:00",
          "05:00",
          "07:00",
          "09:00",
          "11:00",
        ],
      },
    },

    todoSeries: [100, 50, 50],
    todoOptions: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          stroke: {
            lineCap: "round",
            color: "#F0F4F5",
          },
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              color: "#647074",
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Total", "Completed", "ToDo"],
      colors: ["#E5E8E9", "#98CA86", "#F5B053"],
      legend: {
        show: true,
        position: "bottom",
        type: "line",
        markers: {
          width: 16,
          height: 4,
          strokeWidth: 0,
          radius: 4,
        },
      },
    },
    formattedActivities: [],
    listingsData: null,
  };

  componentDidMount = () => {
    const { userData } = this.props;
    this.setState({ inProgress: true });

    Promise.all([
      this.props.getLeads(),
      this.props.getAdminActivities(),
      this.props.getCustomerRenewals(),
      this.props.getRenewals(),
      this.props.getNewBusinesses(),
      this.props.getListings(),
      this.props.getTodos(),
    ])
      .then(
        ([
          dataLeads,
          adminActivities,
          dataCustomerRenewals,
          dataRenewals,
          dataNewBusinesses,
          dataListings,
          dataTodos,
        ]) => {
          const formattedLeads = formatResponseForChart(dataLeads.data.result);

          const formattedActivities = generateAdminActivitiesTest(
            adminActivities.data.result.rows,
          );

          const formattedCustomerRenewals = formatResponseForChart(
            dataCustomerRenewals.data.result,
            "updatedAt",
          );

          const formattedRenewals = formatResponseForRenewals(
            dataRenewals.data.result,
            "dueAt",
            userData.id,
            userData.adminsCount,
          );

          const formattedNewBusinesses = formatResponseForNewBusinesses(
            dataNewBusinesses.data.result,
          );

          const formattedListings = formatListings(
            dataListings.data.result.rows,
          );

          const formattedTodos = formatResponseForTodos(
            dataTodos.data.result,
            "reminderDate",
          );

          this.setState(
            {
              formattedLeads,
              formattedActivities,
              formattedCustomerRenewals,
              formattedRenewals,
              formattedNewBusinesses,
              formattedListings,
              formattedTodos,
            },
            () => {
              this.updateCharts();
            },
          );
        },
      )
      .catch((err) => {
        console.log("err = ", err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  updateCharts = () => {
    let {
      formattedLeads,
      formattedCustomerRenewals,
      activeTab,
      formattedTodos,
    } = this.state;

    this.setState({
      todoSeries: [
        100,
        Math.floor(
          (formattedTodos[activeTab].completedTotal /
            formattedTodos[activeTab].total) *
            100,
        ) || 0,
        Math.floor(
          ((formattedTodos[activeTab].total -
            formattedTodos[activeTab].completedTotal) /
            formattedTodos[activeTab].total) *
            100,
        ) || 0,
      ],
      todoOptions: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              total: {
                show: true,
                label: "Total",
                color: "#647074",
                formatter: () => {
                  return formattedTodos[this.state.activeTab].total;
                },
              },
            },
          },
        },
      },
      trialsCounter: Math.floor(Math.random() * 120),
      leadsSeries: [
        {
          name: "Leads",
          data: Object.values(formattedLeads[activeTab]),
        },
      ],
      renewalsSeries: [
        {
          name: "Renewals",
          data: Object.values(formattedCustomerRenewals[activeTab]),
        },
      ],
      leadsOptions: {
        ...this.state.leadsOptions,
        xaxis: {
          categories: Object.keys(formattedLeads[activeTab]).map((e, i) => {
            if (Object.keys(formattedLeads[activeTab]).length > 10) {
              if (!(i % 2)) {
                if (activeTab === "monthly") {
                  return e + " week";
                }
                return e;
              }
              return "";
            }

            if (activeTab === "monthly") {
              return e + " week";
            }

            return e;
          }),
        },
      },
      renewalsOptions: {
        ...this.state.renewalsOptions,
        xaxis: {
          categories: Object.keys(formattedCustomerRenewals[activeTab]).map(
            (e, i) => {
              if (
                Object.keys(formattedCustomerRenewals[activeTab]).length > 10
              ) {
                if (!(i % 2)) {
                  if (activeTab === "monthly") {
                    return e + " week";
                  }

                  return e;
                }
                return "";
              }
              if (activeTab === "monthly") {
                return e + " week";
              }

              return e;
            },
          ),
        },
      },
    });
  };

  updateActiveTab = (tab) => {
    if (this.state.inProgress) {
      return null;
    }
    this.setState(
      {
        activeTab: tab,
      },
      () => {
        this.updateCharts();
      },
    );
  };

  render() {
    const {
      activeTab,
      inProgress,
      leadsOptions,
      leadsSeries,
      trialsOptions,
      trialsSeries,
      renewalsOptions,
      renewalsSeries,
      todoSeries,
      todoOptions,
      formattedRenewals,
      formattedListings,
      formattedNewBusinesses,
      formattedCustomerRenewals,
      formattedActivities,
      formattedLeads,
      trialsCounter,
      formattedTodos,
    } = this.state;

    const tabs = [
      {
        label: "1 Day",
        type: "daily",
      },
      {
        label: "1 Week",
        type: "weekly",
      },
      {
        label: "1 Month",
        type: "monthly",
      },
      {
        label: "1 Year",
        type: "yearly",
      },
    ];

    return (
      <div className="dashboard-tab-wrapper">
        <div className="d-flex justify-content-between align-items-center">
          <div className="heading">
            <div className="d-flex align-items-center">
              <h2>My dashboard</h2>
              {inProgress && (
                <div className="dashboard-spinner-wrapper">
                  <Spinner size="sm" />
                </div>
              )}
            </div>

            <p className="user-message">
              <span>Hi {this.props.userData.firstName}! </span>
              <span>Welcome to your space. Hope you have a great day. :) </span>
            </p>
          </div>
          <div className="nav">
            {tabs.map((itm) => {
              return (
                <button
                  key={itm.type}
                  type="button"
                  className={`tab ${
                    activeTab === itm.type ? "selected" : "unselected"
                  }`}
                  onClick={() => {
                    this.updateActiveTab(itm.type);
                  }}
                >
                  {itm.label}
                </button>
              );
            })}
          </div>
        </div>
        <Row>
          <Col xs="4">
            <div className="chart-card">
              <FTSChart
                label="Leads"
                count={formattedLeads?.counters[activeTab]}
                options={leadsOptions}
                series={leadsSeries}
              />
            </div>
          </Col>
          <Col xs="4">
            <div className="chart-card">
              <FTSChart
                label="Trials"
                count={trialsCounter}
                options={trialsOptions}
                series={trialsSeries}
              />
            </div>
          </Col>
          <Col xs="4">
            <div className="chart-card">
              <FTSChart
                label="Renewals"
                count={formattedCustomerRenewals?.counters[activeTab]}
                options={renewalsOptions}
                series={renewalsSeries}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="chart-card-white">
              <div className="title-row">ACTIVITY</div>
              <ActivityChartSection
                activitiesTypes={this.props.activitiesTypes}
                adminActivities={
                  formattedActivities &&
                  (formattedActivities[activeTab]?.newActivities || [])
                }
                pastActivities={
                  formattedActivities &&
                  (formattedActivities[activeTab]?.pastActivities || [])
                }
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="chart-card-white">
              <span className="title-row">NEW BUSINESS</span>
              <NewBusinessSection
                total={formattedNewBusinesses[activeTab]?.total || "-"}
                newPremiumCount={
                  formattedNewBusinesses[activeTab]?.premium || "-"
                }
                newBasicPlusCount={
                  formattedNewBusinesses[activeTab]?.basicPlus || "-"
                }
                newStandardCount={
                  formattedNewBusinesses[activeTab]?.standard || "-"
                }
                newBasicCount={formattedNewBusinesses[activeTab]?.basic || "-"}
                iconName="NewListings"
              />
            </div>
          </Col>
          <Col>
            <div className="chart-card-white">
              <span className="title-row">RENEWALS</span>
              <RenewalsSection
                iconName="RenewalsSection"
                renewals={formattedRenewals[activeTab]?.mine || "-"}
                renewalsWonCount={formattedRenewals[activeTab]?.mineWon || "-"}
                renewalsWonPercentage={formattedRenewals[activeTab]?.won || "-"}
                renewalsLostCount={
                  formattedRenewals[activeTab]?.mineLost || "-"
                }
                renewalsLostPercentage={
                  formattedRenewals[activeTab]?.lost || "-"
                }
              />
            </div>
          </Col>
          <Col>
            <div className="chart-card-white">
              <span className="title-row">DEALS</span>
              <DealsSection
                total={
                  formattedNewBusinesses[activeTab]?.total +
                  formattedRenewals[activeTab]?.mineWon
                }
                newBussinesCount={
                  formattedNewBusinesses[activeTab]?.total || "-"
                }
                newBussinesPercentage={
                  formattedRenewals[activeTab]?.mineWon ? 100 : "-"
                }
                renewalsCount={formattedRenewals[activeTab]?.mineWon || "-"}
                renewalsPercentage={formattedRenewals[activeTab]?.won || "-"}
                iconName="DealsDashboard"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="chart-card-white">
              <span className="title-row">UPGRADES VS DOWNGRADES</span>
              <UpgradesVsDowngradesSection
                formattedListings={
                  formattedListings && formattedListings[activeTab]
                }
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="chart-card-white mb-2">
              <span className="title-row d-flex justify-content-between">
                <span>MY TODO'S</span>
                <span
                  className="task-management-link"
                  onClick={() => {
                    history.push("/tasks-management");
                  }}
                >
                  <span>See my todo’s</span>
                  <Icon name="ArrowLink" size={24} />
                </span>
              </span>
              {formattedTodos && formattedTodos[activeTab].total ? (
                <TodoSection
                  activeTabDash={activeTab}
                  series={todoSeries}
                  options={todoOptions}
                  formattedTodos={formattedTodos}
                />
              ) : (
                <EmptyTodoSection />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values.loggedInUser,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

const mapDispatchToProps = {
  getListings,
  getLeads,
  getCustomerRenewals,
  getAdminActivities,
  getRenewals,
  getNewBusinesses,
  getTodos,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
