const size = {
    xs: "592px", // for small screen mobile
    sm: "768px", // for mobile screen
    md: "1024px", // for tablets
    lg: "1025px", // for laptops
};
export const device = {
    lg: `(min-width: ${size.lg})`,
    md: `(max-width: ${size.md})`,
    sm: `(max-width: ${size.sm})`,
    xs: `(max-width: ${size.xs})`,
};
