import React, { PureComponent } from "react";
import { Card, CardBody } from "reactstrap";
import TaskManagementNew from "../task-management-new";

class Todos extends PureComponent {
  render() {
    const { listingId, listingData } = this.props;
    return (
      <Card style={{ minHeight: "80vh" }}>
        <CardBody className="fts-listing-tab-wrapper">
          <TaskManagementNew listingData={listingData} listingId={listingId} />
        </CardBody>
      </Card>
    );
  }
}

export default Todos;
