import moment from "moment";

moment.locale("en");

export const ftsDateFormat = "F j, Y";
export const ftsTimeFormat = "h:i K";

export const formatMomentDate = (date, hasTime = true, hasWeekday = false) => {
  const weekdayFormat = hasWeekday ? "dddd, " : "";
  const dateFormat = hasWeekday ? "DD MMMM YYYY" : "MMM DD, YYYY";
  const timeFormat = hasTime ? " h:mm a" : "";
  const fullFormat = weekdayFormat + dateFormat + timeFormat;
  return date.format(fullFormat);
};

export const formatTime = (rawDate, includeAt = true) => {
  const date = toMomentDate(rawDate, "YYYY-MM-DD HH:mm Z");
  const format = includeAt ? "[at] h:mm a" : "h:mm a";
  return date.format(format);
};

export const formatRawDate = (rawDate, hasTime = true, hasWeekday = false) => {
  return formatMomentDate(
    toMomentDate(rawDate, "YYYY-MM-DD HH:mm Z"),
    hasTime,
    hasWeekday
  );
};

export const formatDueDate = (rawDate, rawTime) => {
  return `${moment(rawDate, "YYYY-MM-DD HH:mm Z").format(
    "MMMM DD, YYYY"
  )} at ${moment(rawTime, "kk:mm").format("h:mm a")}`;
};

export const formatDate = (rawDate) => {
  return `${moment(rawDate.replace(".000Z", "")).format("DD MMM")}`;
};

export const formatFromUnix = (unixTime) => {
  return formatMomentDate(moment.unix(unixTime));
};

export const toMomentDate = (date, ...args) => {
  return moment(date, ...args);
};

export const rawToUnix = (date) => {
  return moment(date).unix();
};

export const flatpickrDateConfig = (additionalOptions = {}) => {
  const config = {
    enableTime: false,
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "F j, Y",
    ...additionalOptions,
  };

  if (config.enableTime) {
    config.altFormat = `${ftsDateFormat} at ${ftsTimeFormat}`;
    config.dateFormat = `${ftsDateFormat} at ${ftsTimeFormat}`;
  }
  return config;
};
