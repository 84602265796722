/* eslint-disable indent */
import update from "immutability-helper";

import {
  SET_USER_LIST,
  SET_USER_STICKY_LIST,
  CLEAR_USER_LIST,
  CLEAR_USER_STICKY_LIST,
  ADD_USER_NOTE,
  ADD_USER_NOTE_STICKY,
  UPDATE_USER_NOTE,
  UPDATE_USER_NOTE_STICKY,
  REMOVE_USER_NOTE,
  REMOVE_USER_NOTE_STICKY,
  UNPIN_USER_NOTE,
  ADD_USER_NOTE_REPLY,
  ADD_USER_STICKY_NOTE_REPLY,
  LOAD_MORE_USERS_LIST,
  RESET_STATE,
} from "../../actions/user-notes";

const initialState = {
  list: [],
  stickyList: [],
};

const UserNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MORE_USERS_LIST: {
      const updatedList = update(state.list, { $push: action.data });
      return { ...state, list: updatedList };
    }

    case ADD_USER_NOTE_REPLY: {
      const index = state.list.findIndex(
        (note) => note.id === action.data.parentId
      );

      if (index < 0) return state;

      const updatedList = update(state.list, {
        [index]: { replies: { $push: [action.data] } },
      });
      return {
        ...state,
        list: updatedList,
      };
    }

    case ADD_USER_STICKY_NOTE_REPLY: {
      const index = state.stickyList.findIndex(
        (note) => note.id === action.data.parentId
      );
      const updatedList = update(state.stickyList, {
        [index]: { replies: { $push: [action.data] } },
      });
      return {
        ...state,
        stickyList: updatedList,
      };
    }

    case SET_USER_LIST:
      return { ...state, list: action.data };

    case SET_USER_STICKY_LIST:
      return { ...state, stickyList: action.data };

    case CLEAR_USER_LIST:
      return { ...state, list: [] };

    case CLEAR_USER_STICKY_LIST:
      return { ...state, stickyList: [] };

    case ADD_USER_NOTE: {
      let existNote = state.list.find((note) => action.data.id === note.id);
      if (existNote) return state;
      return {
        ...state,
        list: update(state.list, { $unshift: [action.data] }),
      };
    }

    case ADD_USER_NOTE_STICKY:
      return {
        ...state,
        stickyList: update(state.stickyList, { $push: [action.data] }),
      };

    case UPDATE_USER_NOTE: {
      if (action.data.parentId) {
        const index = state.list.findIndex(
          (note) => action.data.parentId === note.id
        );
        const mainNote = state.list[index];

        if (!mainNote) return state;

        let newReplies = [...mainNote.replies];
        let replyIndex = newReplies.findIndex((e) => e.id === action.data.id);

        newReplies[replyIndex] = action.data;
        mainNote.replies = newReplies;

        const updatedList = update(state.list, {
          [index]: { $set: mainNote },
        });

        return {
          ...state,
          list: updatedList,
        };
      }
      const index = state.list.findIndex(
        (note) => note.id === action.data.id || action.data.parentId === note.id
      );

      const { ...rest } = action.data;
      const updatedList = update(state.list, {
        [index]: { $set: rest },
      });
      return {
        ...state,
        list: updatedList,
      };
    }

    case UPDATE_USER_NOTE_STICKY: {
      const index = state.stickyList.findIndex(
        (note) => note.id === action.data.id
      );
      // const { replies, ...rest } = action.data;
      let updatedList;

      if (index > -1) {
        updatedList = update(state.stickyList, {
          [index]: { $set: action.data },
        });
      } else {
        updatedList = update(state.stickyList, {
          $push: [action.data],
        });
      }

      return {
        ...state,
        stickyList: updatedList,
      };
    }

    case REMOVE_USER_NOTE:
      return { ...state, list: [] };

    case REMOVE_USER_NOTE_STICKY: {
      const index = state.stickyList.findIndex(
        (note) => note.id === action.data.id
      );
      return {
        ...state,
        stickyList: update(state.stickyList, { $splice: [[index, 1]] }),
      };
    }

    case UNPIN_USER_NOTE: {
      const index = state.list.findIndex((note) => note.id === action.data.id);
      return {
        ...state,
        list: update(state.list, { $splice: [[index, 1]] }),
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default UserNotesReducer;
