import { DEFAULT_TABLE_DATA } from "../../../utility/constants";
import { formatInvoicesCounters } from "../../../utility/formatInvoicesCounters";
import {
  SET_ACCOUNT_INVOICES,
  SET_ACCOUNT_INVOICES_ACTIVE_SUBTAB,
  SET_ACCOUNT_INVOICES_CURRENT_PAGE,
  SET_ACCOUNT_INVOICES_IN_PROGRESS,
  SET_ACCOUNT_INVOICES_LAST_VIEWED_ID,
  SET_ACCOUNT_INVOICES_RESULTS_PER_PAGE,
  SET_ACCOUNT_INVOICES_SEARCH_STRING,
  SET_ACCOUNT_INVOICES_SORT_MODEL,
  SET_ACCOUNT_PRESELECTED_INVOICE,
} from "../../actions/accountInvoices";

const initialState = {
  lastViewedAccount: null,
  invoicesData: [],
  activeSubTab: { id: 1, label: "All", elasticKey: "" },
  inProgress: false,
  searchString: DEFAULT_TABLE_DATA.SEARCH_STRING,
  currentPage: DEFAULT_TABLE_DATA.CURRENT_PAGE,
  resultsPerPage: 100,
  pageCount: DEFAULT_TABLE_DATA.PAGE_COUNT,
  sortModel: DEFAULT_TABLE_DATA.SORT_MODEL,
  totalInvoiceAmount: 0,
  subTabs: [
    {
      id: 1,
      label: "All",
      elasticKey: "",
      count: 0,
    },
    {
      id: 2,
      label: "Paid",
      elasticKey: "Paid",
      count: 0,
    },
    {
      id: 3,
      label: "Overdue",
      elasticKey: "Overdue",
      count: 0,
    },
    {
      id: 4,
      label: "Pending",
      elasticKey: "Pending",
      count: 0,
    },
    {
      id: 5,
      label: "Unsuccessful",
      elasticKey: "Unsuccessful",
      count: 0,
    },
    {
      id: 6,
      label: "Voided",
      elasticKey: "Voided",
      count: 0,
    },
  ],
};

const AccountInvoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_INVOICES:
      const pageCount = Math.ceil(
        action?.payload?.nbHits / state.resultsPerPage,
      );
      const {
        overdueCount,
        paidCount,
        pendingCount,
        unsuccessfulCount,
        voidedCount,
      } = action.payload;

      return {
        ...state,
        invoicesData: action?.payload?.hits || [],
        inProgress: false,
        totalInvoiceAmount: action?.payload?.totalInvoiceAmount,
        subTabs: formatInvoicesCounters({
          subTabs: state?.subTabs,
          counts: {
            overdueCount,
            paidCount,
            pendingCount,
            unsuccessfulCount,
            voidedCount,
          },
        }),
        pageCount,
      };

    case SET_ACCOUNT_INVOICES_ACTIVE_SUBTAB:
      return {
        ...state,
        activeSubTab: action.payload,
        currentPage: 1,
      };

    case SET_ACCOUNT_INVOICES_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_ACCOUNT_INVOICES_IN_PROGRESS:
      return {
        ...state,
        inProgress: action.payload,
      };

    case SET_ACCOUNT_INVOICES_SORT_MODEL:
      return {
        ...state,
        sortModel: action.payload,
      };

    case SET_ACCOUNT_INVOICES_RESULTS_PER_PAGE:
      return {
        ...state,
        resultsPerPage: action.payload,
      };

    case SET_ACCOUNT_INVOICES_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      };

    case SET_ACCOUNT_INVOICES_LAST_VIEWED_ID:
      return {
        ...initialState,
        searchString: action.payload?.searchString,
        lastViewedAccount: action.payload?.id,
      };

    case SET_ACCOUNT_PRESELECTED_INVOICE:
      return {
        ...initialState,
        searchString: action.payload,
      };

    default:
      return state;
  }
};

export default AccountInvoicesReducer;
