import React, { PureComponent } from "react";
// import { Row, Col } from "reactstrap";
import classnames from "classnames";
import TitleRow from "../../../components/fts/TitleRow";

class Section extends PureComponent {
  render() {
    const { id, title, notWrapped, first, hasDivider, children, titleClassName } = this.props;
    return (
      <React.Fragment>
        <div
          id={id}
          className={classnames("listing-section", {
            "no-border": notWrapped,
            first,
          })}
        >
          {title && (
            <TitleRow className={titleClassName ?? ""} title={title} />
          )}
          {children}
        </div>
        {hasDivider && <hr />}
      </React.Fragment>
    );
  }
}

export default React.memo(Section);
