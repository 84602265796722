import { DEFAULT_TABLE_DATA } from "../../../utility/constants";
import { mapCountersToTabs } from "../../../utility/mapCountersToTabs";
import {
  SET_ACCOUNT_LISTINGS,
  SET_ACCOUNT_LISTINGS_ACTIVE_SUBTAB,
  SET_ACCOUNT_LISTINGS_CURRENT_PAGE,
  SET_ACCOUNT_LISTINGS_IN_PROGRESS,
  SET_DEFAULT_ACCOUNT_LISTINGS,
  SET_ACCOUNT_LISTINGS_SORT_MODEL,
  SET_ACCOUNT_LISTINGS_RESULTS_PER_PAGE,
  SET_ACCOUNT_LISTINGS_COUNTERS,
  SET_ACCOUNT_LISTINGS_SEARCH_STRING,
  SET_ACCOUNT_LISTINGS_LAST_VIEWED_ID,
} from "../../actions/accountListings";

const initialState = {
  lastViewedAccount: null,
  listingsData: [],
  activeSubTab: 1,
  allListingsCount: 0,
  inProgress: false,
  searchString: DEFAULT_TABLE_DATA.SEARCH_STRING,
  currentPage: DEFAULT_TABLE_DATA.CURRENT_PAGE,
  resultsPerPage: DEFAULT_TABLE_DATA.RESULTS_PER_PAGE,
  pageCount: DEFAULT_TABLE_DATA.PAGE_COUNT,
  sortModel: DEFAULT_TABLE_DATA.SORT_MODEL,
  subTabs: [
    {
      id: 1,
      label: "Premium",
      count: 0,
    },
    {
      id: 2,
      label: "Standard",
      count: 0,
    },
    {
      id: 3,
      label: "Basic Plus",
      count: 0,
    },
    {
      id: 4,
      label: "Basic",
      count: 0,
    },
    {
      id: 5,
      label: "Free",
      count: 0,
    },
  ],
};

const AccountListingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_ACCOUNT_LISTINGS:
      return {
        ...initialState,
      };
    case SET_ACCOUNT_LISTINGS:
      let pageCount = Math.ceil(action?.payload?.nbHits / state.resultsPerPage);
      return {
        ...state,
        listingsData: action?.payload?.hits || [],
        pageCount,
        inProgress: false,
      };

    case SET_ACCOUNT_LISTINGS_ACTIVE_SUBTAB:
      return {
        ...state,
        activeSubTab: action.payload,
        currentPage: 1,
      };

    case SET_ACCOUNT_LISTINGS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_ACCOUNT_LISTINGS_IN_PROGRESS:
      return {
        ...state,
        inProgress: action.payload,
      };

    case SET_ACCOUNT_LISTINGS_SORT_MODEL:
      return {
        ...state,
        sortModel: action.payload,
      };

    case SET_ACCOUNT_LISTINGS_RESULTS_PER_PAGE:
      return {
        ...state,
        resultsPerPage: action.payload,
      };

    case SET_ACCOUNT_LISTINGS_COUNTERS:
      const { mappedTabs, allCounter } = mapCountersToTabs({
        tabs: [...state.subTabs],
        counters: action.payload,
      });

      return {
        ...state,
        allListingsCount: allCounter,
        subTabs: mappedTabs,
      };

    case SET_ACCOUNT_LISTINGS_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      };

    case SET_ACCOUNT_LISTINGS_LAST_VIEWED_ID:
      return {
        ...initialState,
        lastViewedAccount: action.payload,
      };

    default:
      return state;
  }
};

export default AccountListingsReducer;
