import React, { PureComponent } from "react";
import { Row, Col, FormGroup } from "reactstrap";

import FormikFastInput from "../../../../components/fts/formik/formik-fast-input";
import FormikCheckbox from "../../../../components/fts/formik/checkbox";
import Divider from "../../../../components/fts/Divider";

const groupPrefix = "workingHours.";

export default class SpecialHours extends PureComponent {
  render() {
    const { editMode, open24hrs } = this.props;

    return (
      <div>
        <Divider textColor="primary" position="left">
          Special hours
        </Divider>
        <Row>
          <Col>
            <FormGroup
              style={{
                paddingLeft: 0,
                paddingTop: 10,
                paddingBottom: 5,
              }}
              check
            >
              <FormikCheckbox
                name={`${groupPrefix}towingRecoveryOpen`}
                label="24 hour Towing & Recovery"
                color="primary"
                disabled={!editMode}
              />
            </FormGroup>
            <FormGroup
              style={{
                paddingLeft: 0,
                paddingTop: 5,
                paddingBottom: 20,
              }}
              check
            >
              <FormikCheckbox
                name={`${groupPrefix}mobileRoadServiceOpen`}
                label="24 hour Mobile/Road Service"
                color="primary"
                disabled={!editMode}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={open24hrs ? "6" : "12"} xl={open24hrs ? "4" : "12"}>
            <FormikFastInput
              disabled={!editMode}
              name={`${groupPrefix}specialHours`}
              label="Special Hours"
              labelId="specialHours"
              placeholder="Eg. 24 hour Roadside Service"
            />
          </Col>
        </Row>
      </div>
    );
  }
}
