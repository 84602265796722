import React, { Component } from "react";

import { Button, Col, FormGroup, Input, Row, Spinner } from "reactstrap";
import Checkbox from "../../../components/@vuexy/checkbox/CheckboxesVuexy";
import { Check } from "react-feather";
import FTSModal from "../fts-modal";
import CloseButton from "../CloseButton";
import Icon from "../Icons";

import "../../../assets/scss/components/fts/multiple-listings-modal.scss";
import StandaloneListingTypePicker from "../standalone-listing-type-picker";
import { searchListings } from "../../../redux/actions/listings";
import { connect } from "react-redux";
import { listingTypesArray } from "../../../data/listing-types-options";
import { Scrollbars } from "react-custom-scrollbars";
import { updateSelectedListings } from "../../../redux/actions/ads";
import { setUsersListings } from "../../../redux/actions/users";

class MultipleListingsModal extends Component {
  state = {
    inProgress: false,
    allSelected: false,
    listingSearchValue: "",
    priceId: null,
    listings: [],
    selectedListings: this.props.selectedListings || [],
    accountListingsLength: 0,
    accountListings: [],
  };

  getAccountListings = (initial) => {
    this.setState({
      inProgress: true,
    });
    this.props
      .searchListings({
        name: "",
        userId: this.props.accountId,
        page: 1,
        resultsPerPage: 10000,
        searchString: this.state.listingSearchValue,
        priceId: this.state.priceId,
      })
      .then((res) => {
        this.setState({
          listings: res?.data?.result?.hits,
          inProgress: false,
        });
        this.props.setUsersListings(res?.data?.result?.hits);

        initial &&
          this.setState({
            accountListingsLength: res?.data?.result?.hits?.length - 1,
            accountListings: res?.data?.result?.hits,
          });
      });
  };

  componentDidMount() {
    this.setState({
      inProgress: true,
    });

    this.getAccountListings(true);
  }

  debounce = setTimeout(() => {}, 300);

  updateSearchQuery = (e) => {
    clearTimeout(this.debounce);
    this.setState({ listingSearchValue: e.target.value }, () => {
      this.debounce = setTimeout(() => this.getAccountListings(), 300);
    });
  };

  updateListingTypeFilter = (value) => {
    clearTimeout(this.debounce);
    this.setState({ priceId: value?.value || null }, () => {
      this.debounce = setTimeout(() => this.getAccountListings(), 300);
    });
  };

  handleSelectAll = () => {
    let selected = this.state.accountListings
      .filter((listing) => listing.id !== this.props.listingId)
      .map((listing) => listing.id);

    this.setState({
      selectedListings: this.state.allSelected ? [] : selected,
      allSelected: !this.state.allSelected,
    });

    this.props.updateSelectedListings(this.state.allSelected ? [] : selected);
  };

  getListingTypeName = (value) => {
    let key = listingTypesArray?.filter(
      (type) => type.value === parseInt(value)
    );

    return key[0].label;
  };

  render() {
    const {
      listingSearchValue,
      listings,
      selectedListings,
      accountListingsLength,
    } = this.state;
    const {
      listingId,
      toggleMultipleListingsModal,
      toggleListingUpdateConfirmationModal,
    } = this.props;

    return (
      <FTSModal
        isOpen={true}
        modalClasses={"fts-reassign-acc-list-modal"}
        centered={true}
        size="lg"
        footerCTA={() => {
          return (
            <>
              <Button
                onClick={() => {
                  toggleMultipleListingsModal();
                  this.props.updateSelectedListings([]);
                }}
                color="white"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={!selectedListings.length}
                onClick={() => {
                  toggleMultipleListingsModal();
                  toggleListingUpdateConfirmationModal();
                }}
              >
                Apply to selected listings
              </Button>
            </>
          );
        }}
      >
        <div className="fts-reassign-acc-list-modal-body">
          <span className="fts-reassign-acc-list-modal-body-wrapper">
            <span className="fts-reassign-acc-list-modal-title">
              <span className="d-flex align-items-center">
                <h3>Apply your changes to multiple listings</h3>
                {this.state.inProgress && (
                  <Spinner
                    size="sm"
                    className="ml-1"
                    style={{ marginBottom: "5px" }}
                  />
                )}
              </span>
              <CloseButton
                onClick={() => {
                  toggleMultipleListingsModal();
                  this.props.updateSelectedListings([]);
                }}
              />
            </span>
            <Row className="mt-2">
              <Col md="7">
                <FormGroup className="position-relative has-icon-left">
                  <Input
                    type="text"
                    placeholder="Search for Listing by ID, Name, City, State"
                    onChange={this.updateSearchQuery}
                    value={listingSearchValue}
                  />
                  <div
                    className="form-control-position"
                    style={{
                      paddingBottom: "6px",
                    }}
                  >
                    <Icon name="Search" size={18} />
                  </div>
                </FormGroup>
              </Col>
              <Col md="5">
                <StandaloneListingTypePicker
                  placeholder="Filter by listing type"
                  onChange={(value) => {
                    this.updateListingTypeFilter(value);
                  }}
                />
              </Col>
            </Row>

            <div className="select-all-checkbox">
              {listings.length === 0 ||
              (listings.length === 1 && listings[0].id === listingId) ? null : (
                <>
                  <Checkbox
                    className="reassign-checkbox"
                    color="#647074"
                    icon={<Check className="vx-icon" size={16} />}
                    defaultChecked={false}
                    checked={
                      this.state.selectedListings.length ===
                      accountListingsLength
                    }
                    onChange={() => {
                      this.handleSelectAll();
                    }}
                  />
                  <span>Select all {`(${accountListingsLength})`}</span>
                </>
              )}
            </div>

            <Scrollbars
              style={{ height: "350px" }}
              className="reassign-rows-wrapper"
            >
              {(listings.length === 0 ||
                (listings.length === 1 && listings[0].id === listingId)) &&
              !this.state.inProgress ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <p className="empty-text">No results found</p>
                </div>
              ) : (
                <>
                  {listings.map((listing, index) => {
                    if (listing.id !== listingId) {
                      return (
                        <div className="reassign-row" key={index + 1000}>
                          <div className="d-flex align-items-center">
                            <Checkbox
                              className="reassign-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              defaultChecked={false}
                              checked={selectedListings.includes(listing.id)}
                              onChange={() => {
                                if (!selectedListings.includes(listing.id)) {
                                  let newArray = [
                                    ...selectedListings,
                                    listing.id,
                                  ];
                                  this.setState({
                                    selectedListings: newArray,
                                    allSelected:
                                      newArray.length === listings.length - 1,
                                  });
                                  this.props.updateSelectedListings(newArray);
                                } else {
                                  let newArray = selectedListings.filter(
                                    (listingId) => listingId !== listing.id
                                  );

                                  this.setState({
                                    selectedListings: newArray,
                                    allSelected:
                                      newArray.length === listings.length - 1,
                                  });
                                  this.props.updateSelectedListings(newArray);
                                }
                              }}
                            />
                            #{listing.id}-{listing.name}-{listing.state}-
                            {listing.city}-{listing.phoneNumber}
                          </div>
                          <div className="listing-type-badge">
                            {this.getListingTypeName(listing.priceId)}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
            </Scrollbars>
          </span>
        </div>
      </FTSModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedListings: state.listingCounts.selectedListings,
  };
};

export default connect(mapStateToProps, {
  searchListings,
  updateSelectedListings,
  setUsersListings,
})(MultipleListingsModal);
