import * as Yup from "yup";
import { REQUIRED_ERROR_MESSAGE } from "../../../../constants";

export const listingDetailsRowValidator = Yup.object().shape({
  name: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  includeInAuthorizedPromotions: Yup.bool(),
  address: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  city: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  state: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  zip: Yup.number().required(REQUIRED_ERROR_MESSAGE),
  interstate: Yup.string(),
  milemarker: Yup.string(),
});
