import listingTypesArray from "../../../data/listing-types-options";

const initialState = {
  inProgress: false,
  invoicesData: null,
  invoicesPage: 1,
  totalRecordsInvoices: 0,
  allInvoicesCount: 0,
  selectedInvoices: [],
  totalAmount: 0,
  searchVal: "",
  pageSize: 10,
  pageCount: 0,
  sort: null,
  discountAmount: 0,
  creditCardData: null,
  appliedCreditAmount: 0,
  isCreditApplied: false,
  totalAccountCredit: 0,
  allPaymentsCount: 0,
};

const promoCodeType = (item, promoCode) => {
  if (!promoCode?.rules) {
    return false;
  }
  const itemPriceId = item["listing.priceId"];
  let rulesArray = promoCode.rules.split(" | ");

  let arr = [];

  rulesArray.forEach((rule) => {
    listingTypesArray.forEach((listingType) => {
      if (rule.includes(listingType.label)) {
        arr.push(listingType.value);
      }
    });
  });
  const firstTime = promoCode.rules.includes("First time customer only");

  if (
    arr.includes(itemPriceId) ||
    (item["owner.newAccount"] === 1 && firstTime)
  ) {
    return true;
  }
  return false;
};

const calculatePrices = (
  invoicesWithDiscount,
  promoCode,
  state,
  removeDiscount
) => {
  let discountAmount = 0;

  invoicesWithDiscount = invoicesWithDiscount.filter((invoice) => {
    return invoice.isSelected;
  });

  if (removeDiscount) {
    invoicesWithDiscount = invoicesWithDiscount.filter((invoice) => {
      return invoice.isSelected && invoice.promoCode;
    });
  }
  let newInvoices = [];
  invoicesWithDiscount.forEach((invoice) => {
    if (promoCode.discountType === "$") {
      discountAmount += promoCode.discount;
      invoice.amount = invoice.invoiceAmount - promoCode.discount;
    } else {
      const discountMultiplier = promoCode.discount / 100;

      discountAmount += invoice.invoiceAmount * discountMultiplier;
      invoice.amount =
        invoice.invoiceAmount - invoice.invoiceAmount * discountMultiplier;
    }
    newInvoices.push(invoice);
  });

  const subTotal = state.subTotal;
  const newTotal = state.subTotal - discountAmount - state.appliedCreditAmount;

  return { discountAmount, subTotal, newTotal, newInvoices };
};

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INVOICES_LIST":
      return {
        ...state,
        invoicesData: action.payload?.rows || null,
        totalRecordsInvoices: action.payload?.count || 0,
      };
    case "SET_PAYMENTS_LIST":
      return {
        ...state,
        paymentsData: action.payload?.rows || null,
        totalRecordsPayments: action.payload?.count || 0,
        allPaymentsCount: action.payload?.allPaymentsCount,
      };

    case "SET_ALL_INVOICES_COUNT":
      return {
        ...state,
        allInvoicesCount: action.payload?.allInvoicesCount || 0,
      };

    case "SELECT_INVOICE":
      let selectedInvoice = state.selectedInvoices.find(
        (invoice) => invoice.id === action.payload.item.id
      );

      selectedInvoice.isSelected = !selectedInvoice.isSelected;

      const objIndex = state.selectedInvoices.findIndex(
        (obj) => obj.id === selectedInvoice.id
      );

      let newInvoices = [...state.selectedInvoices];
      newInvoices[objIndex] = selectedInvoice;

      const sum = newInvoices
        .map((item) => item.isSelected && item.invoiceAmount)
        .reduce((prev, curr) => prev + curr, 0);

      let newDiscount = 0;
      newInvoices.forEach((invoice) => {
        if (invoice.promoCode && invoice.isSelected) {
          if (action.payload.promoCode.discountType === "$") {
            newDiscount += action.payload.promoCode.discount;
          } else {
            const discountMultiplier = action.payload.promoCode.discount / 100;

            newDiscount += invoice.invoiceAmount * discountMultiplier;
          }
        }
      });

      let discount = newDiscount || state.discountAmount;

      let totalAmount = sum;
      if (action.payload?.promoCode?.discount) {
        totalAmount = sum - discount;
      }

      let counter = 0;

      newInvoices.forEach((invoice) => {
        if (invoice.isSelected) {
          counter += 1;
        }
      });

      if (counter === 0) {
        discount = 0;
      }

      return {
        ...state,
        selectedInvoices: newInvoices,
        totalAmount: totalAmount,
        subTotal: sum,
        discountAmount: discount,
        isCreditApplied: !!sum && !!totalAmount && state.isCreditApplied,
        appliedCreditAmount:
          !!sum && !!totalAmount && state.isCreditApplied
            ? state.appliedCreditAmount
            : 0,
      };
    case "SET_SELECTED_INVOICES":
      const sumAll = action.payload?.selectedInvoices
        ?.map((item) => item.isSelected && item.invoiceAmount)
        ?.reduce((prev, curr) => prev + curr, 0);

      return {
        ...state,
        selectedInvoices: action.payload?.selectedInvoices || [],
        totalAmount: sumAll,
        subTotal: sumAll,
      };

    case "SET_IS_CREDIT_APPLIED":
      return {
        ...state,
        isCreditApplied: action.payload,
      };

    case "SET_TOTAL_ACCOUNT_CREDIT":
      return {
        ...state,
        totalAccountCredit: action.payload,
      };

    case "RESET_DISCOUNT":
      return {
        ...state,
        discountAmount: 0,
      };

    case "REMOVE_INVOICE_DISCOUNT":
      const selectedInvoices = state.selectedInvoices.map((invoice) => {
        if (action.payload.invoiceId !== invoice.id) {
          return invoice;
        }

        return { ...invoice, promoCode: null };
      });

      let promoCode;

      let invoicesWithDiscount = selectedInvoices.filter((invoice) => {
        if (invoice.promoCode) {
          promoCode = invoice.promoCode;
        }

        return invoice.promoCode;
      });

      invoicesWithDiscount = invoicesWithDiscount.map((invoice) => {
        if (invoice.id === action.payload) {
          let temp = invoice;
          temp.promoCode = null;
          return temp;
        }
        return invoice;
      });

      const { discountAmount, subTotal, newTotal } = calculatePrices(
        invoicesWithDiscount,
        promoCode,
        state,
        true
      );

      return {
        ...state,
        selectedInvoices,
        totalAmount: newTotal,
        subTotal,
        discountAmount,
      };

    case "SET_CREDIT_CARD_DATA": {
      // const sumAll = action.payload?.selectedInvoices
      //   ?.map((item) => item.isSelected && item.invoiceAmount)
      //   ?.reduce((prev, curr) => prev + curr, 0);

      return {
        ...state,
        creditCardData: action.payload,
      };
    }

    case "SET_DISCOUNT_FOR_SELECTED_INVOICES": {
      let invoicesWithDiscount = state.selectedInvoices.filter((invoice) => {
        return promoCodeType(invoice, action.payload);
      });

      const { discountAmount, subTotal, newTotal, newInvoices } =
        calculatePrices(invoicesWithDiscount, action.payload, state);
      const selectedInvoices = state.selectedInvoices?.map((invoice) => {
        if (
          !invoicesWithDiscount.find(
            ({ id: invoiceWithDiscountId }) =>
              invoiceWithDiscountId === invoice.id
          )
        ) {
          return { ...invoice, promoCode: null, amount: invoice.invoiceAmount };
        }

        let invoiceInDiscount = newInvoices.find((e) => e.id === invoice.id);

        return {
          ...invoice,
          promoCode: action.payload,
          amount: invoiceInDiscount?.amount > 0 ? invoiceInDiscount?.amount : 1,
        };
      });

      return {
        ...state,
        selectedInvoices,
        totalAmount: newTotal > 0 ? newTotal : 1,
        subTotal,
        discountAmount,
      };
    }

    case "ADD_NEW_INVOICE": {
      return {
        ...state,
        invoicesData: [...state.invoicesData, action.payload],
      };
    }

    case "SET_APPLIED_CREDIT_AMOUNT": {
      if (state.subTotal - state.discountAmount < action.payload) {
        // TOTAL ACCOUNT CREDIT IS LARGER THAN TOTAL ON INVOICE
        let appliedCreditAmount =
          action.payload -
          (action.payload - state.subTotal + state.discountAmount + 1);
        return {
          ...state,
          totalAmount: 1,
          appliedCreditAmount,
          totalAccountCredit: state.totalAccountCredit - appliedCreditAmount,
        };
      }

      if (action.payload > 0) {
        // TOTAL AMOUNT IS LARGER THAN TOTAL ACCOUNT CREDIT
        return {
          ...state,
          totalAmount: state.subTotal - state.discountAmount - action.payload,
          appliedCreditAmount: action.payload,
          totalAccountCredit: state.totalAccountCredit - action.payload,
        };
      } else {
        return {
          ...state,
          totalAmount: state.totalAmount + state.appliedCreditAmount,
          totalAccountCredit:
            state.totalAccountCredit + state.appliedCreditAmount,
          appliedCreditAmount: action.payload,
        };
      }
    }

    default:
      return {
        ...state,
      };
  }
};

export default invoicesReducer;
