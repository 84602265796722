import React, { Component } from "react";
import { Label } from "reactstrap";

import { pickerStyles, controlStyles } from "../picker-style";
import Select from "../Select";

class TypePicker extends Component {
  render() {
    const {
      onStateChange,
      label,
      isDisabled,
      placeholder,
      value,
      meta,
      onBlur,
      name,
    } = this.props;

    let typeOptions = [
      { value: "both", label: "Both" },
      { value: "note", label: "Note" },
      { value: "todo", label: "Todo" },
    ];

    const pickerStyle = {
      ...pickerStyles,
      control: (styles, state) => controlStyles(styles, state, {}, { meta }),
    };

    let isLoading = false;

    if (!typeOptions?.length) {
      isLoading = true;
    }

    const defaultValue =
      meta && meta.initialValue && typeOptions.length
        ? typeOptions.find((itm) => itm.value === meta.initialValue)
        : null;

    let valueInfo =
      typeof value !== "object"
        ? typeof value === "string" &&
          typeof defaultValue === "object" &&
          value !== defaultValue?.value
          ? typeOptions.find((itm) => itm.value === value)
          : defaultValue ?? typeOptions.find((itm) => itm.value === value)
        : value;

    return (
      <>
        {!!label && (
          <Label className={isDisabled ? "disabled" : ""} for="cat-type">
            {this.props.required && !isDisabled ? `${label} *` : label}
          </Label>
        )}
        <Select
          isClearable={false}
          isSearchable={false}
          options={typeOptions}
          onChange={onStateChange}
          styles={pickerStyle}
          value={valueInfo}
          {...{
            isDisabled,
            placeholder,
            defaultValue,
            onBlur,
            isLoading,
            name,
            label,
          }}
        />
      </>
    );
  }
}

export default TypePicker;
