import React from "react";
import themeConfig from "../configs/themeConfig";
import classnames from "classnames";
import Icon from "../components/fts/Icons";
import { useLocation } from "react-router-dom";

const FullPageLayout = ({ children, bgColor }) => {
  const location = useLocation();

  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={classnames(
        "full-layout wrapper bg-full-screen-image blank-page dark-layout",
        {
          "layout-dark": themeConfig.layoutDark,
          "bg-login-screen-image":
            location.pathname === "/login" ||
            location.pathname === "/login-mfa" ||
            location.pathname === "/update-password",
        },
      )}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            {(location.pathname === "/login" ||
              location.pathname === "/login-mfa" ||
              location.pathname === "/update-password") && (
              <div
                className="FTS-logo-wrapper"
                style={{ paddingTop: 30, height: "10vh" }}
              >
                <Icon
                  name="Logo"
                  size={320}
                  strokeOpacity={0}
                  style={{ maxHeight: 30 }}
                />
              </div>
            )}
            <div
              className="flexbox-container"
              style={{
                minHeight:
                  location.pathname === "/login" ||
                  location.pathname === "/login-mfa" ||
                  location.pathname === "/update-password"
                    ? "90vh"
                    : "100vh",
              }}
            >
              <main className="main w-100">{children}</main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullPageLayout;
