import React from "react";
import { Row, Col } from "reactstrap";
import CreateCustomer from "./CreateCustomer";

class CreateCustomerView extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col sm="12">
            <CreateCustomer />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default CreateCustomerView;
