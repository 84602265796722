import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyComponent } from "../../dist/ui/src/components/react/EmptyComponent";
import { cancelEditingAltContactAction } from "../../../../redux/actions/v2/altContacts";
import { AccountAltContactFormHandler } from "./AccountAltContactFormHandler";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { AccountAltContactItem } from "./AccountAltContactItem";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import {
  selectAltContactId,
  selectAltContacts,
} from "../../../../redux/reducers/v2/altContacts";

export const AccountAltContactItems = ({ showForm, setHideAddButton }) => {
  const dispatch = useDispatch();
  const altContacts = useSelector(selectAltContacts);
  const altContactId = useSelector(selectAltContactId);
  const hasAltContacts = !!altContacts?.length;

  const closeAltContactForm = useCallback(() => {
    setHideAddButton(false);
    dispatch(cancelEditingAltContactAction());
  }, []);

  if (!hasAltContacts) return <EmptyComponent />;
  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsRow columnGap={"lg"} rowGap={"lg"}>
        {altContacts.map((altContact) => {
          const altId = altContact.id;
          const isInEditMode = altContactId === altId;
          const showEditForm = !showForm && isInEditMode;
          const disableEdit = showForm || altContactId;

          if (showEditForm)
            return (
              <AccountAltContactFormHandler
                isEdit
                key={altId}
                title={"Edit alt contact"}
                submitButtonTitle={"Save Changes"}
                closeAltContactForm={closeAltContactForm}
              />
            );

          return (
            <AccountAltContactItem
              key={altId}
              disableEdit={disableEdit}
              altContact={altContact}
              setHideAddButton={setHideAddButton}
            />
          );
        })}
      </FtsRow>
    </FtsColumn>
  );
};
