import React, { PureComponent } from "react";
import { Row } from "reactstrap";

import CategoryDropdown from "./CategoryDropdown";
import "../../../assets/scss/plugins/forms/switch/react-toggle.scss";
import "react-toggle/style.css";

class CategoryCustomSelect extends PureComponent {
  render() {
    if (!this.props.categories) return null;

    const { editMode, subCategories, cur, isListing, right, changedServices } =
      this.props;
    const categories = [...this.props.categories];

    return (
      <React.Fragment>
        <div>
          <Row>
            {categories.map(({ id, label }) => (
              <CategoryDropdown
                cur={cur}
                key={id}
                isListing={isListing}
                right={right}
                changedServices={changedServices}
                subCategories={subCategories.filter(
                  ({ categoryId }) => categoryId === id
                )}
                {...{ id, editMode, label }}
              />
            ))}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default React.memo(CategoryCustomSelect);
