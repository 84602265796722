import React, { Component } from "react";
import Updates from "../../../components/fts/updates-tab/index";

export class UpdatesView extends Component {
  render() {
    return <Updates />;
  }
}

export default UpdatesView;
