import React from "react";
import classNames from "classnames";
import "../../../assets/scss/components/fts/table-tabs.scss";

const TableTabs = ({ tabs = [], handleActiveTab, activeTabId = 1 }) => {
  return (
    <div className="fts-table-tabs">
      {tabs.map((tab) => {
        return (
          <div
            onClick={() => {
              handleActiveTab(tab);
            }}
            className={classNames("fts-table-tab", {
              "fts-table-tab-active": tab?.id === activeTabId,
            })}
            key={tab?.id}
          >
            <span>
              {tab?.label} ({tab.count})
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default TableTabs;
