const initialState = {
  activeTab: "national",
  inProgress: false,
  nationalPromotionData: null,
  accountPromotionData: null,
  listingPromotionData: null,
  generalPromotionData: null,
  pageNational: 1,
  pageAccount: 1,
  pageListing: 1,
  totalRecordsGeneral: 0,
  totalRecordsNational: 0,
  totalRecordsAccount: 0,
  totalRecordsListing: 0,
  totalActiveRecordsNational: 0,
  totalActiveRecordsGeneral: 0,
  totalActiveRecordsAccount: 0,
  totalActiveRecordsListing: 0,
  pageCountNational: 0,
  pageCountAccount: 0,
  pageCountListing: 0,
  pageSizeNational: 20,
  pageSizeAccount: 20,
  pageSizeListing: 20,
  searchValNational: "",
  searchValAccount: "",
  searchValListing: "",
  sortNational: null,
  sortAccount: null,
  sortListing: null
};

const promotionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NATIONAL_PROMOTIONS":
      return {
        ...state,
        nationalPromotionData: action.payload?.rows || null,
        totalRecordsNational: action.payload?.count || 0,
        totalActiveRecordsNational: action.payload?.activeCount || 0
      };
    case "SET_ACCOUNT_PROMOTIONS":
      return {
        ...state,
        accountPromotionData: action.payload?.rows || null,
        totalRecordsAccount: action.payload?.count || 0,
        totalActiveRecordsAccount: action.payload?.activeCount || 0
      };
    case "SET_LISTING_PROMOTIONS":
      return {
        ...state,
        listingPromotionData: action.payload?.rows || null,
        totalRecordsListing: action.payload?.count || 0,
        totalActiveRecordsListing: action.payload?.activeCount || 0
      };
    case "SET_GENERAL_PROMOTIONS":
      return {
        ...state,
        generalPromotionData: action.payload?.rows || null,
        totalRecordsGeneral: action.payload?.count || 0,
        totalActiveRecordsGeneral: action.payload?.activeCount || 0
      };
    case "SET_IN_PROGRESS": {
      return { ...state, inProgress: action.payload };
    }
    default:
      return state;
  }
};

export default promotionsReducer;
