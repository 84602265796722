/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState } from "react";
import ListingDetails from "../pages/listing-settings/ListingDetails";
import { weekdays } from "../pages/listing-settings/data";
import formatHour from "../../utility/formatHour";
import logo from "../../assets/img/logo/fts-logo.png";
import FTSAxios from "../../axios/fts.instance";
import {
  hoursListFrom,
  hoursListTo,
} from "../../components/fts/formik/hour-input/options";
import {
  formatFromUnix,
  formatRawDate,
  rawToUnix,
} from "../../configs/dateConfig";
import {
  extractFormikAmenitiesData,
  extractFormikCategoriesData,
  formatFormikAmenitiesData,
  formatFormikCategoriesData,
} from "../../utility/extract";

import collectEssentialData from "../../axios/collect-data";
import { connect, useDispatch } from "react-redux";
import FTSCookies, { AUTH_KEY, TEMPORARY_TOKEN } from "../../cookies";
import { Button, Col, Card, CardBody } from "reactstrap";
import moment from "moment";
import { toast } from "react-toastify";
import isObject from "lodash/isObject";
import { formatAdsRates } from "../../utility/formatAdsRates";
import { rfc3986DecodeURIComponent } from "../../utility/encode-rfc";
import {
  formatWorkingHoursFrom,
  formatWorkingHoursTo,
} from "../../utility/formatWorkingHours";

const UpdateListing = (props) => {
  let [inProgress, setInProgress] = useState(true);
  let [listingData, setListingData] = useState(null);
  let [error, setError] = useState("");
  let [success, setSuccess] = useState(false);
  let formRef = useRef(null);
  let submitBtnRef = useRef(null);
  let dispatch = useDispatch();

  useEffect(() => {
    FTSCookies.remove(AUTH_KEY);
    FTSCookies.remove(TEMPORARY_TOKEN);
    setInProgress(true);
    let { token } = props.match.params;

    FTSCookies.set(TEMPORARY_TOKEN, token, { path: "/" });

    Promise.all([
      FTSAxios.get("admin/updates/get-listing-info"),
      collectEssentialData(dispatch),
    ])
      .then(([{ data }]) => {
        let newData = formatData(data);
        setListingData(newData);
        setInProgress(false);
      })
      .catch((e) => {
        console.log("ERROR: ", e.response);
        setError("This listing has already been updated!");
      });
  }, [dispatch, props.match.params]);

  const onFormSubmission = (values) => {
    setInProgress(true);
    const { userCategories: categories, userSubCategories: subCategories } =
      extractFormikCategoriesData(values.categories);
    const hoursOfOperation = {
      alwaysOpen: values.workingHours.open24hrs ? 1 : 0,
      specialHours: {
        towingRecovery: !!values.workingHours.towingRecoveryOpen,
        mobileRoad: !!values.workingHours.mobileRoadServiceOpen,
      },
      weekDays: weekdays.map(({ day }) => ({
        day,
        // "08:00 PM",
        from: formatWorkingHoursFrom(values, day),
        // "08:00 PM",
        to: formatWorkingHoursTo(values, day),
      })),
    };

    if (values.renewalDate) {
      if (typeof values.renewalDate === "object") {
        values.renewalTimestamp = rawToUnix(values.renewalDate[0].toString());
        values.renewalLaterDate = rawToUnix(values.renewalDate[0].toString());
      }
    }

    values.adsRates = formatAdsRates(values, props.rates);

    const payload = {
      ...values,
      updatedAt: moment().format("lll"),
      alwaysOpen: values.workingHours.open24hrs ? 1 : 0,
      ...values.workingHours,
      adsCategories: [...categories],
      adsSubCategories: [...subCategories],
      ...values.addressInfo,
      milemarker: values.mileMarker,
      latitude: values.geoLocation.lat,
      longitude: values.geoLocation.lng,
      ...extractFormikAmenitiesData(values),
      priceId:
        typeof values.priceId === "object"
          ? values.priceId.value
          : values.priceId,
      state:
        typeof values.addressInfo.state === "object"
          ? values.addressInfo.state.value
          : values.addressInfo.state,
      adminStatus:
        typeof values.adminStatus === "object"
          ? values.adminStatus.value
          : values.adminStatus,
      status:
        typeof values.status === "object" ? values.status.value : values.status,
      hoursOfOperation,
      paymentStatus: values.isItPaidFor ? "paid" : "not_paid",
    };

    FTSAxios.post("admin/request-update", {
      adId: payload.id,
      requestedUpdate: payload,
    })
      .then(() => {
        setInProgress(false);
        setSuccess(true);
        FTSCookies.remove(AUTH_KEY);
        FTSCookies.remove(TEMPORARY_TOKEN);
      })
      .catch((e) => {
        setInProgress(false);
        console.log("ERROR: ", e);
      });
  };

  const getFirstErrorKey = (object, keys = []) => {
    const firstErrorKey = Object.keys(object)[0];
    if (isObject(object[firstErrorKey])) {
      return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
    }
    return [...keys, firstErrorKey].join(".");
  };
  const onSubmitError = (errors) => {
    const key = getFirstErrorKey(errors);
    let element;

    if (document.getElementsByName(key).length) {
      element = document.getElementsByName(key)[0];
    } else {
      element = document.getElementById(key);
    }
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    toast.error("Unable to proceed. Invalid form!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  if (error) {
    return (
      <>
        <Header success={true} />
        <div className={"container mt-5"}>
          <Col xs={12} md={{ size: 10, offset: 1 }}>
            <Card>
              <CardBody className={"p-2"}>
                <h2 className="text-center">{error}</h2>
              </CardBody>
            </Card>
          </Col>
        </div>
      </>
    );
  }
  if (success) {
    return (
      <>
        <Header success={success} />
        <div className={"container mt-5"}>
          <Col xs={12} md={{ size: 10, offset: 1 }}>
            <Card>
              <CardBody className={"p-2"}>
                <h2 className="text-center">
                  Thank you for updating your listing!
                </h2>
                <p className={"text-center"}>
                  Your requested changes are being reviewed by the Find Truck
                  Service team. Once the changes are approved, the listing will
                  be updated with the new information.
                </p>
              </CardBody>
            </Card>
          </Col>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className={"container"}>
        <div className={"col-12"}>
          <h2 className={"mb-5"}>Update Listing information</h2>
          <ListingDetails
            onSubmitError={onSubmitError}
            onFormSubmission={onFormSubmission}
            updateListingFromEmail={true}
            inProgress={inProgress}
            editMode={true}
            formikRef={(e) => (formRef.current = e)}
            listingData={listingData || {}}
            submitBtnRef={(e) => {
              submitBtnRef.current = e;
            }}
          />
        </div>
      </div>
      <Footer
        formRef={formRef}
        submitBtnRef={submitBtnRef}
        onFormSubmission={onFormSubmission}
      />
    </>
  );
};

const Footer = ({ formRef }) => {
  return (
    <div
      style={{
        position: "sticky",
        bottom: 0,
        backgroundColor: "rgba(255,255,255,0.8)",
        padding: "1rem",
        zIndex: 1,
      }}
    >
      <Col
        xs={{ size: 10, offset: 1 }}
        className="d-flex justify-content-end p-0"
      >
        <Button
          onClick={() => {
            formRef.current.submitForm();
          }}
          style={{ width: 200 }}
          color="primary"
          type="submit"
          className="account-listing-header-btn acc-list-primary-btn rounded "
        >
          Submit
        </Button>
      </Col>
    </div>
  );
};
const Header = ({ success }) => {
  return (
    <>
      <div style={{ minWidth: "100vw", backgroundColor: "#CE1B3B" }}>
        <img src={logo} alt={"logo"} className={"ml-3"} />
      </div>
      {!success && (
        <div
          style={{
            backgroundColor: "#FAC02A",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 0px",
          }}
          className={"mb-5"}
        >
          <p className={"mb-0"}>
            Your requested changes are being reviewed by the Find Truck Service
            team. Once the changes are approved, the listing will be updated
            with the new information.
          </p>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rates: state.essentialLists.rates,
  };
};

export default connect(mapStateToProps)(UpdateListing);

const formatData = (data) => {
  const workingHours = {};

  weekdays.forEach(({ day }) => {
    if (!data?.workingHours || data.alwaysOpen) return;

    let [from, to] = data?.workingHours
      ? data?.workingHours[`${day.toLowerCase()}`].split("-")
      : ["", ""];
    from = formatHour(from?.trim() || "");
    to = formatHour(to?.trim() || "");

    // if day is empty string
    if (data?.workingHours[`${day.toLowerCase()}`] === "") {
      // set from & to to null
      from = null;
      to = null;
    }
    workingHours[`${day}From`] = !!from
      ? hoursListFrom.find(({ value }) => value === from) ?? ""
      : null;
    workingHours[`${day}To`] = !!to
      ? hoursListTo.find(({ value }) => value === to) ?? ""
      : null;
  });

  // this.setState({ imageLink: data.image });
  // this.setState({ initialImageLink: data.image });
  return {
    ...data,
    name: rfc3986DecodeURIComponent(data.name),
    isItPaidFor: data.paymentStatus === "paid" ? true : false,
    createdAt: formatRawDate(data.createdAt),
    renewalDate: formatFromUnix(data.renewalTimestamp),
    updatedAt: formatRawDate(data.updatedAt),
    addressInfo: {
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
    },
    mileMarker: data.milemarker,
    geoLocation: { lat: data.latitude, lng: data.longitude },
    description: rfc3986DecodeURIComponent(data.description),
    includeInAuthorizedPromotions: !!data.includeInAuthorizedPromotions,
    includeInFeaturedVendors: !!data.includeInFeaturedVendors,
    excludeRenewalEmails: !!data.excludeRenewalEmails,
    excludeDowngrades: !!data.excludeDowngrades,
    workingHours: {
      ...workingHours,
      open24hrs: !!data.alwaysOpen,
      mobileRoadServiceOpen: data.mobileRoadServiceOpen,
      towingRecoveryOpen: data.towingRecoveryOpen,
      specialHours: data.specialHours,
    },
    categories: {
      ...formatFormikCategoriesData({
        categories: data.adsCategories,
        subCategories: data.adsSubCategories,
      }),
    },
    ...formatFormikAmenitiesData(data.adsAmenities),
    ...formatFormikAmenitiesData(data.adsServiceAmenities, true),
  };
};
