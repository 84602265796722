export const updateSearchFilter = (data) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_SEARCH_FILTER", data });
  };
};

export const clearSearchFilter = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_FILTER" });
  };
};
