import React from "react";
import Icon from "../Icons";
import "../../../assets/scss/components/fts/access-requested-badge.scss";

const AccessReuqestedBadge = () => {
  return (
    <div className="fts-access-request-badge">
      <div className="icon">
        <Icon name="Lock" size={16} />
      </div>
      <div className="text">Access requested</div>
    </div>
  );
};

export default AccessReuqestedBadge;
