/* eslint-disable indent */
/* eslint-disable no-extra-boolean-cast */
import React, { createRef } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { setActiveTab } from "../../../redux/actions/sales";
// import throttle from "lodash/throttle";

import Icon from "../../../components/fts/Icons";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";

import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";
import "../../../assets/scss/pages/account-settings.scss";
import "../../../assets/scss/pages/account-listing-shared.scss";
import "../../../assets/scss/pages/sales-tab.scss";
import ConfirmationModal from "../../../components/fts/confirmation-modal";
import withDeviceType from "../../../components/hoc/withDeviceType";
import ActiveAccounts from "./ActiveAccounts";
import LeadsPool from "./LeadsPool";
import Customers from "./Customers";

class Sales extends React.Component {
  state = {
    isOpen: false,
    activeTab: this.props.activeTab,
    windowWidth: null,
    inProgress: true,
    sidebar: false,
    updating: false,
    action: "",
    actionItem: null,
    navBarCollapsed: false,
  };

  navBarHeight = null;
  navigationContainer = null;

  accountFormRef = createRef();
  formikSidebarRef = createRef();

  updateNavWidth = () => {
    if (this.accountSettingsTab) {
      if (this.props.isSmallPhone()) {
        this.navigationContainer.style.width = "34px";
      } else if (this.props.isPhoneOrSmaller()) {
        this.navigationContainer.style.width = "62px";
      } else {
        this.navigationContainer.style.width = "226px";
      }
    }
  };

  //   handleStickySidebar = () => {
  //     let actionBarHeight =
  //       document.getElementsByClassName("fts-action-bar")[0]?.clientHeight;

  //     if (this.accountSettingsTab) {
  //       this.navigationContainer.style.height = "206px";
  //       this.navigationContainer.style.minHeight = "625px";
  //       this.updateNavWidth();

  //       if (window.scrollY >= actionBarHeight) {
  //         this.accountSettingsTab.style.position = "fixed";
  //         this.accountSettingsTab.style.top = this.navBarHeight + 27 + "px";
  //         this.accountSettingsTab.style.width = "200px";
  //       } else {
  //         this.accountSettingsTab.style.position = "relative";
  //         this.accountSettingsTab.style.top = "0px";
  //       }
  //     }
  //   };

  handleNavBarCollapse = () => {
    this.setState({
      navBarCollapsed: !this.state.navBarCollapsed,
    });
  };

  componentDidMount() {
    if (window !== undefined) {
      this.updateWidth();
      window.addEventListener("resize", this.updateWidth);
    }

    this.navigationContainer =
      document.getElementsByClassName("fts-nav-container")[0];

    // window.addEventListener("scroll", throttle(this.handleStickySidebar, 20));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
    // window.removeEventListener("scroll", this.handleStickySidebar);
  }

  updateWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  toggle = (tab) => {
    if (tab !== 1 && this.filesTabRef?.closePopover) {
      this.filesTabRef.closePopover();
    }
    this.setState({
      activeTab: tab,
    });
    this.props.setActiveTab(tab);
  };

  callToAction = (action, update, item) => {
    if (!action) return;
    this.setState({
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
    });
  };

  setFormikSidebarRef = (ref) => {
    this.formikSidebarRef = ref;
  };

  handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (bool === false && this.formikSidebarRef?.dirty && !closeWithoutPrompt) {
      return this.setState({ isOpen: true });
    } else {
      this.setState({ sidebar: bool, updating: !!update });
    }
  };

  closeSideOutAction = () => {
    this.setState({ sidebar: false, update: false, isOpen: false });
  };

  setActionItem = (actionItem) => {
    this.setState({ actionItem: { ...actionItem } });
  };

  openSidebar = (data, note, updateListing) => {
    if (data) {
      if (updateListing) {
        this.callToAction("update_listing", false, { id: data.id });
      } else {
        if (!note) {
          this.callToAction("todo", true, { id: data.id });
        } else {
          this.setState({
            activeTab: 0,
          });
          this.props.setActiveTab(0);
          this.callToAction("todo", true, { id: data.toDoId });
        }
      }
    } else if (this.props.location && this.props.location.search) {
      let data = this.props.location.data;
      if (data) {
        this.setState({
          activeTab: 0,
        });
        this.props.setActiveTab(0);
        this.callToAction("todo", true, { id: data.toDoId });
      }
    }
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onModalClosed = () => {};

  render() {
    let {
      windowWidth,
      // activeTab,
      updating,
      sidebar,
      action,
      actionItem,
      isOpen,
      navBarCollapsed,
    } = this.state;
    const { activeTab } = this.props;

    const navItems = [
      {
        iconName: "Customers",
        label: "Customers",
      },
      {
        iconName: "ActiveLeads",
        label: "Active leads",
      },
      {
        iconName: "LeadsPool",
        label: "Leads pool",
      },
    ];

    return (
      <React.Fragment>
        <div className="acc-list-flex-wrapper sales-flex-wrapper">
          <div
            className={`fts-account-nav-wrapper ${
              windowWidth >= 769 ? "nav-vertical" : "account-setting-wrapper"
            }`}
          >
            <div>
              <div
                className={`${navBarCollapsed ? "fts-nav-container" : null}`}
              >
                <Nav
                  className="account-settings-tab nav-left mr-0 mr-sm-3"
                  tabs
                >
                  {navItems.map(
                    ({ iconName, label, disabled, navLink }, index) => (
                      <NavItem key={label}>
                        <NavLink
                          style={
                            disabled
                              ? { opacity: 0.2, cursor: "default" }
                              : null
                          }
                          className={classnames({
                            active: activeTab === index,
                          })}
                          onClick={() => {
                            if (disabled) return;
                            if (navLink)
                              return this.props.history.push(navLink);
                            this.toggle(index);
                          }}
                        >
                          <Icon name={iconName} size={20} />
                          <span className="d-md-inline-block d-none align-middle ml-1">
                            {navBarCollapsed ? "..." : label}
                          </span>
                        </NavLink>
                      </NavItem>
                    ),
                  )}
                  <hr />
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        this.handleNavBarCollapse();
                      }}
                      className={`${
                        navBarCollapsed ? "collapsed-button" : null
                      }`}
                    >
                      <Icon name="CollapseLeft" size={20} color={"#7D8C91"} />
                      <span
                        className="d-md-inline-block d-none align-middle ml-1"
                        style={{ color: "#8C919F" }}
                      >
                        Collapse
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>

            <TabContent activeTab={activeTab}>
              <TabPane tabId={0}>{activeTab === 0 && <Customers />}</TabPane>
              <TabPane tabId={1}>
                {activeTab === 1 && <ActiveAccounts />}
              </TabPane>
              <TabPane tabId={2}>
                <LeadsPool callToAction={this.callToAction} />
              </TabPane>
            </TabContent>

            <ActionSidebar
              setFormikSidebarRef={this.setFormikSidebarRef}
              resetFormikForm={this.resetFormikForm}
              adminData={this.props.userData}
              accountInfo={{
                ...this.state.accountData,
                userId: this.props.userData.id,
              }}
              show={sidebar}
              action={action}
              updating={updating}
              setEditMode={this.setEditMode}
              actionItem={actionItem}
              listings={this.state.accountListings}
              handleSidebar={this.handleSidebar}
              getAccountInfo={this.getAccountInfo}
              filesTableGroupRef={this.filesTableGroupRef}
              onPrefillListingData={this.onPrefillListingData}
              noMarker
              isSalesPage
            />
          </div>
        </div>

        <ConfirmationModal
          title="Unsaved Changes"
          message="Are you sure you want to proceed with this action?"
          onClose={this.onModalClosed}
          closeModal={this.toggleModal}
          confirmModal={this.closeSideOutAction}
          {...{ isOpen }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    counts: state.accountCounts,
    activeTab: state.sales.activeTab,
  };
};

const DeviceTypeWrapper = withDeviceType(Sales);
const mapDispatchToProps = {
  setActiveTab,
};
export default connect(mapStateToProps, mapDispatchToProps)(DeviceTypeWrapper);
