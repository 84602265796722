/* eslint-disable indent */
import React, { PureComponent } from "react";
import Icon from "../Icons";

import { history } from "../../../history";
import classnames from "classnames";
import FTSBadge from "../fts-badge";
import ConfirmationModal from "../confirmation-modal";
// import Label from "../Label";

class LinkedLabel extends PureComponent {
  state = {
    confirmationModalOpen: false,
  };

  toggleConfirmationModal = () => {
    this.setState({
      confirmationModalOpen: !this.state.confirmationModalOpen,
    });
  };
  render() {
    const {
      isLinked,
      isActivity,
      isAdditionalData,
      todo: data,
      isListing,
      note,
      openSidebar,
      type,
      unpinNote,
      isNotesList,
    } = this.props;

    if (!isLinked) {
      return null;
    }

    const linkedTodoOnListing = !isListing && data.adId && data.toDoId;

    const linkedListingOnAccount = data.adId && !data.toDoId;

    const linkedTodoNotListing =
      !isAdditionalData && data.toDoId !== null && !isListing && !data.adId;

    const linkedNoteFromListingTodo =
      isNotesList && !isListing && (data.adId || data.toDoId);

    // const showLinkToListingTodo = data.adId && data.toDoId;

    if (isActivity) return null;

    return (
      <div className="fts-note-small-header">
        {type && (
          <FTSBadge
            className={classnames("small-note-label", {
              "small-note-long-label": isNotesList,
            })}
            style={{
              backgroundColor: type[0]?.color,
              fontSize: "10px",
            }}
          >
            {type[0]?.description}
          </FTSBadge>
        )}
        <div className="fts-note-small-header-row">
          {linkedTodoNotListing && (
            <div
              className="fts-note-linked"
              onClick={() => {
                history.push({
                  pathname: `/accounts/${data.userId}`,
                  search: `?toDoId=${data.id}`,
                  data: data,
                  note: false,
                });
                openSidebar({ id: data.toDoId }, false);
              }}
            >
              <span className="fts-linked-text">Linked from Todo {">"} </span>
            </div>
          )}

          {linkedTodoOnListing && (
            <div
              className="fts-note-linked"
              onClick={() => {
                history.push({
                  pathname: `/listings/${data.adId}`,
                  search: `?toDoId=${data.toDoId}`,
                  data: data,
                  note: false,
                });
                openSidebar({ id: data.toDoId }, false);
              }}
            >
              <span className="fts-linked-text">Linked from Todo {">"} </span>

              {/* <Label label="LINKED TO TODO >" color="primary" inverted /> */}
            </div>
          )}

          {linkedListingOnAccount ? (
            <div
              className="fts-note-linked"
              onClick={() => {
                history.push({
                  pathname: `/listings/${data.adId}`,
                  search: `?noteId=${data.id}`,
                  data: data,
                  note: true,
                });
                openSidebar(data, note);
              }}
            >
              {/* <Label label="LINKED TO LISTING >" color="primary" inverted /> */}
              <span className="fts-linked-text">
                Linked from Listing {">"}{" "}
              </span>
            </div>
          ) : null}

          {linkedNoteFromListingTodo && (
            <div
              className="unpin-btn"
              onClick={() => {
                this.toggleConfirmationModal();
              }}
            >
              <Icon name="Unpin" size={20} />
              <span>Un-pin</span>
            </div>
          )}
        </div>
        <ConfirmationModal
          title="Confirmation"
          message="Are you sure you want to proceed with this action?"
          closeModal={() => {
            this.toggleConfirmationModal();
          }}
          confirmModal={() => {
            this.toggleConfirmationModal();

            unpinNote();
          }}
          isOpen={this.state.confirmationModalOpen}
        />
      </div>
    );
  }
}

export default LinkedLabel;
