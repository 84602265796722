/* eslint-disable indent */
import React, { Component } from "react";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";

import Select from "../Select";

import { pickerStyles, controlStyles } from "../picker-style";
import onHoldOptions from "../../../data/on-hold-options";
import { putUpdateOnHold } from "../../../redux/actions/updates";
import { toast } from "react-toastify";
import ConfirmationModal from "../confirmation-modal";

class OnHoldPicker extends Component {
  state = {
    confirmationModalOpen: false,
    displayErrorMessage: false,
  };

  closeFileModal = () => {
    this.toggleConfirmationModal();
    this.setState({
      modalOpen: false,
    });
  };

  toggleConfirmationModal = () => {
    this.setState({
      confirmationModalOpen: !this.state.confirmationModalOpen,
    });
  };

  render() {
    const {
      listingData,
      label,
      name,
      defaultValue: defaultValueProp,
      hasAll,
      handleSidebar,
      ...rest
    } = this.props;

    const pickerStyle = {
      ...pickerStyles,
      borderWidth: 0,
      control: (styles, state) => controlStyles(styles, state),
    };

    const options = onHoldOptions.map((type) => ({
      value: type.value,
      label: type.label,
    }));

    let defaultValue =
      typeof defaultValueProp === "object"
        ? defaultValueProp
        : options.find(({ value }) => value === defaultValueProp);

    if (hasAll) {
      defaultValue = options.find((itm) => itm.value === "");
    }

    return (
      <div
        style={{ display: "inline-block", width: "200px", textAlign: "left" }}
      >
        <div>
          {!!label && (
            <Label className={rest.isDisabled ? "disabled" : ""} for={name}>
              {label}
            </Label>
          )}
        </div>
        <div>
          <Select
            {...{ defaultValue, label }}
            isSearchable={true}
            options={options}
            styles={pickerStyle}
            name={name}
            placeholder={rest.placeholder ?? "Put update on hold"}
            onChange={(e) => {
              if (listingData.on_hold_count >= 5) {
                this.setState({
                  displayErrorMessage: true,
                });
                return;
              }
              this.setState({
                params: {
                  id: listingData.id,
                  onHold: e.value,
                  updatedAt: moment(listingData.updatedAt, "ll").format(
                    "YYYY-MM-DD HH:MM:SS",
                  ),
                  onHoldCount: listingData.on_hold_count,
                },
              });
              this.toggleConfirmationModal();
            }}
            {...rest}
          />
          <ConfirmationModal
            title="Confirmation"
            message="Are you sure you want to put this update on hold?"
            closeModal={this.toggleConfirmationModal}
            confirmModal={() => {
              this.toggleConfirmationModal();

              this.props
                .putUpdateOnHold(this.state.params)
                .then(() => {
                  toast.success("Update is set on hold successfuly!", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  handleSidebar(false, false, true);
                })
                .catch((err) => {
                  console.log("err = ", err);
                  toast.error("Something went wrong. Please try again.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                });
            }}
            isOpen={this.state.confirmationModalOpen}
          />
        </div>
        {this.state.displayErrorMessage && (
          <p
            style={{
              position: "absolute",
              color: "red",

              fontSize: "10px",
            }}
          >
            WARNING. Too many times on hold. Please mark this listing as
            inactive.
          </p>
        )}
      </div>
    );
  }
}

export default connect(null, {
  putUpdateOnHold,
})(OnHoldPicker);
