import {
  UPDATE_AMENITIES_SEARCH_VALUE,
  UPDATE_SERVICES_AMENITIES_SEARCH_VALUE,
} from "../../reducers/essentialLists";

export const updateServicesAmenitiesSearchValue = (payload) => ({
  type: UPDATE_SERVICES_AMENITIES_SEARCH_VALUE,
  payload,
});
export const updateAmenitiesSearchValue = (payload) => ({
  type: UPDATE_AMENITIES_SEARCH_VALUE,
  payload,
});
