import { createSelector } from "reselect";
import { SideOutEditListing } from "../../../../components/fts-components-v2/SideOutEditListing/SideOutEditListing";
import { SideOutEditAccount } from "../../../../components/fts-components-v2/SideOutEditAccount/SideOutEditAccount";

export const OPEN_EDIT_LISTING = "SIDE_OUT/OPEN_EDIT_LISTING";
export const OPEN_EDIT_ACCOUNT = "SIDE_OUT/OPEN_EDIT_ACCOUNT";
export const OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT =
  "SIDE_OUT/OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT";
export const CLOSE_SIDE_OUT = "SIDE_OUT/CLOSE_SIDEBAR";
export const CLOSE_SUB_SIDE_OUT = "SIDE_OUT/CLOSE_SUB_SIDE_OUT";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  isOpen: false,
  isSubSideOutOpen: false,
  sideOutComponent: "",
  subSideOutComponent: "",
  onSuccess: null,
};

export const SIDE_OUT_COMPONENTS_LIST = {
  [OPEN_EDIT_LISTING]: SideOutEditListing,
  [OPEN_EDIT_ACCOUNT]: SideOutEditAccount,
  [OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT]: SideOutEditAccount,
};

/// Reducer
export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_EDIT_LISTING:
      return {
        ...state,
        isLoading: false,
        isOpen: true,
        data: action.payload.data,
        onSuccess: action.payload.onSuccess,
        sideOutComponent: SIDE_OUT_COMPONENTS_LIST[action.type],
      };
    case OPEN_EDIT_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        isOpen: true,
        data: action.payload.data,
        onSuccess: action.payload.onSuccess,
        sideOutComponent: SIDE_OUT_COMPONENTS_LIST[action.type],
      };
    case OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        isSubSideOutOpen: true,
        data: action.payload.data,
        onSuccess: action.payload.onSuccess,
        subSideOutComponent: SIDE_OUT_COMPONENTS_LIST[action.type],
      };

    case CLOSE_SUB_SIDE_OUT:
      return {
        ...state,
        isLoading: false,
        data: null,
        isSubSideOutOpen: false,
        subSideOutComponent: "",
      };
    case CLOSE_SIDE_OUT:
      return initialState;
    default:
      return state;
  }
}

/// Selectors
const selectSideOutState = (state) => state.sideOutState;

export const selectIsSideOutOpen = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.isOpen;
  },
);
export const selectIsSubSideOutOpen = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.isSubSideOutOpen;
  },
);

export const selectSideOutComponent = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.sideOutComponent;
  },
);
export const selectSubSideOutComponent = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.subSideOutComponent;
  },
);

export const selectSideOutData = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.data;
  },
);

export const selectSideOutOnSuccess = createSelector(
  selectSideOutState,
  (sideOut) => {
    return sideOut.onSuccess;
  },
);
