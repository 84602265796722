class socketHandler {
  listingId = null;
  adminId = null;
  socket = null;
  subscribedEvents = [];
  events = {
    saveConnection: {
      onOpen: () => {},
      onMessage: () => {},
    },
    subscribeToListingAnalytics: {
      onOpen: () => {},
      onMessage: () => {},
    },
    unSubscribeToListingAnalytics: {
      onOpen: () => {},
      onMessage: () => {},
    },
    subscribeToAccountAnalytics: {
      onOpen: () => {},
      onMessage: () => {},
    },
    unSubscribeToAccountAnalytics: {
      onOpen: () => {},
      onMessage: () => {},
    },
    logOutUser: {
      onOpen: () => {},
      onMessage: () => {
        window.location.href = "/login";
      },
    },
    subscribeToLockListing: {
      onOpen: () => {},
      onMessage: () => {},
    },
    unSubscribeToLockListing: {
      onOpen: () => {},
      onMessage: () => {},
    },
    subscribeToAccountAccessRequest: {
      onOpen: () => {},
      onMessage: () => {},
    },
    unSubscribeToAccountAccessRequest: {
      onOpen: () => {},
      onMessage: () => {},
    },
  };

  handleSocket = (event, message) => {
    this.subscribeEvent(event);
    this.events[event].onOpen = () => {
      this.send(event, {
        ...message,
      });
      this.events[event].onMessage = this.onMessage;
    };

    if (this?.socket?.readyState === WebSocket.OPEN) {
      this.send(event, {
        ...message,
      });
      this.events[event].onMessage = this.onMessage;
    }
  };

  connect() {
    this.socket = new WebSocket(process.env.REACT_APP_WEB_SOCKET_URL);
    this.socket.onopen = () => {
      console.log("OPEN");
      for (let subscribedEvent of this.subscribedEvents) {
        this.events[subscribedEvent].onOpen();
      }
    };
    this.socket.onmessage = (event) => {
      let data = JSON.parse(event.data);

      if (data.event && this.events[data.event]) {
        this.events[data.event].onMessage(data);
      }
    };
    this.socket.onclose = () => {
      this.connect();
    };
    // setTimeout(() => {
    //   this.socket.close();
    // }, 5000);
  }
  // constructor() {
  //   this.connect();
  // }

  subscribeEvent(action) {
    this.subscribedEvents.push(action);
  }

  send(action, message) {
    this.socket.send(
      JSON.stringify({
        action,
        message,
      }),
    );
    return;
  }
}
const socket = new socketHandler();
export default socket;
