import React, { useCallback } from "react";
import { Span } from "../../dist/ui/src/FtsText";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "../../dist/ui/src/FtsWrapper/FtsWrapper";
import Checkbox from "../../dist/ui/src/components/react/Checkbox";
import { Accordion } from "../../dist/ui/src/components/react/Accordion";
import { setAccountDataAction } from "../../../../redux/actions/v2/account";
import {
  selectCategories,
  selectSubCategories,
} from "../../../../redux/reducers/essentialLists";
import {
  selectAccountCategories,
  selectAccountData,
  selectAccountSubCategories,
} from "../../../../redux/reducers/v2/account";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export const AccountCategoriesPickerRow = () => {
  const dispatch = useDispatch();
  const selectedCategories = useSelector(selectAccountCategories);
  const selectedSubCategories = useSelector(selectAccountSubCategories);
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const accountData = useSelector(selectAccountData);

  const onChangeSubCategory = useCallback(
    (subCategory) => {
      const isChecked = selectedSubCategories.find(
        (i) => i.id === subCategory.id,
      );
      if (isChecked) {
        return dispatch(
          setAccountDataAction({
            ...accountData,
            userSubCategories: selectedSubCategories.filter(
              (e) => e.id !== subCategory.id,
            ),
          }),
        );
      }
      dispatch(
        setAccountDataAction({
          ...accountData,
          userSubCategories: [...selectedSubCategories, subCategory],
        }),
      );
    },
    [accountData],
  );
  const toggleShowAccordionDescription = useCallback(
    (category) => {
      const isChecked = selectedCategories.find((i) => i.id === category.id);
      if (isChecked) {
        return dispatch(
          setAccountDataAction({
            ...accountData,
            userCategories: selectedCategories.filter(
              (e) => e.id !== category.id,
            ),
          }),
        );
      }
      dispatch(
        setAccountDataAction({
          ...accountData,
          userCategories: [...selectedCategories, category],
        }),
      );
    },
    [accountData],
  );
  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} py={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          SEARCH CATEGORIES
        </Span>
      </FtsColumn>
      {categories.map((e) => (
        <FtsColumn key={e.id} size={{ xs: 4 }}>
          <Accordion
            show={!!selectedCategories.find((i) => i.id === e.id)}
            headerLabel={e.value}
            toggleShowAccordionDescription={() =>
              toggleShowAccordionDescription(e)
            }
          >
            <FtsWrapper mt={"2xs"}>
              {subCategories
                .filter((f) => f.categoryId === e.id)
                .map((g) => (
                  <Checkbox
                    key={g.id}
                    onClick={() => onChangeSubCategory(g)}
                    checked={selectedSubCategories.find((h) => h.id === g.id)}
                    label={g.value}
                    mb={"md"}
                    mx={"md"}
                  />
                ))}
            </FtsWrapper>
          </Accordion>
        </FtsColumn>
      ))}
    </FtsRow>
  );
};
