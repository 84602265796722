import React from "react";
import Icon from "../Icons";
import "../../../assets/scss/components/fts/access-granted-badge.scss";

const AccessGrantedBadge = ({ action }) => {
  return (
    <div className="fts-access-granted-badge" onClick={action}>
      <div className="lock">
        <Icon name="Lock" size={16} />
      </div>

      <div className="text">Account access granted</div>
      <div className="chevron">
        <Icon name="ChevronRight" size={16} />
      </div>
    </div>
  );
};

export default AccessGrantedBadge;
