import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { P } from "../../dist/ui/src/FtsText";

import React from "react";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const ListingInfoItem = ({ label, value }) => {
  return (
    <FtsColumn size={{ xs: 3 }}>
      <P
        mb={"xs"}
        variant={VariantsValues["text-3xs"]}
        color={ColorsValue["greyscale-500"]}
      >
        {label}
      </P>
      <P
        mb={"0"}
        variant={VariantsValues["text-3xs"]}
        weight={WeightSize.medium}
        color={ColorsValue["greyscale-800"]}
      >
        {value}
      </P>
    </FtsColumn>
  );
};
