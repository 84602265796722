import React, { Component } from "react";
import FTSAgGridDataTable from "../../../components/fts/fts-ag-grid-data-table/FTSAgGridDataTable";
import { connect } from "react-redux";
import { defineInvoicesColumns } from "../invoices-page/invoices-columns";
import {
  searchListingInvoices,
  updateListingInvoicesActiveSubTab,
  updateListingInvoicesCurrentPage,
  updateListingInvoicesSearchInput,
  updateListingInvoicesResultsPerPage,
  updateListingInvoicesSortModel,
  setListingInvoicesLastViewedListing,
} from "../../../redux/actions/listingInvoices";
import "../../../index.scss";
import { INVOICES_SEARCH_FIELDS as searchFields } from "../../../utility/constants";

class ListingInvoices extends Component {
  state = {
    columnDefs: [],
  };

  async componentDidMount() {
    const {
      setListingInvoicesLastViewedListing,
      searchListingInvoices,
      listingInvoices,
      refreshTable,
      invoiceToOpen,
      togglePopOver,
      collectDataForCopy,
      adId,
    } = this.props;

    const {
      resultsPerPage,
      searchString,
      sortModel,
      activeSubTab,
      currentPage,
      lastViewedListing,
    } = listingInvoices;

    let payload = {
      page: currentPage,
      resultsPerPage,
      searchString,
      searchFields,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (lastViewedListing !== adId) {
      setListingInvoicesLastViewedListing({ id: adId });

      payload = {
        page: 1,
        resultsPerPage: 100,
        searchString: "",
        searchFields,
        sort: [],
        filter: [
          {
            term: { adId },
          },
        ],
      };
    }

    if (lastViewedListing === adId && activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    searchListingInvoices(payload).then(() => {
      this.setState({
        columnDefs: defineInvoicesColumns(
          collectDataForCopy,
          null,
          adId,
          refreshTable,
          invoiceToOpen,
          togglePopOver,
        ),
      });
    });
  }

  onSubTabChange = (subTab) => {
    const {
      searchListingInvoices,
      updateListingInvoicesActiveSubTab,
      listingInvoices,
      adId,
    } = this.props;

    const { resultsPerPage, searchString, sortModel } = listingInvoices;

    let payload = {
      page: 1,
      resultsPerPage,
      searchString,
      searchFields,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (subTab?.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: subTab?.elasticKey } }],
          },
        },
      ];
    }
    updateListingInvoicesActiveSubTab({ subTab });
    searchListingInvoices(payload);
  };

  handleSearchInput = (searchString) => {
    const {
      searchListingInvoices,
      updateListingInvoicesSearchInput,
      listingInvoices,
      adId,
    } = this.props;

    const { resultsPerPage, activeSubTab, sortModel } = listingInvoices;

    let payload = {
      page: 1,
      resultsPerPage,
      searchString,
      searchFields,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    updateListingInvoicesSearchInput({ searchString });
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      searchListingInvoices(payload);
    }, 300);
  };

  onPageChange = (page) => {
    const {
      searchListingInvoices,
      updateListingInvoicesCurrentPage,
      listingInvoices,
      adId,
    } = this.props;

    const { searchString, resultsPerPage, activeSubTab, sortModel } =
      listingInvoices;

    let payload = {
      page,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    updateListingInvoicesCurrentPage({ page });
    searchListingInvoices(payload);
  };

  handleFilterSize = (resultsPerPage) => {
    const {
      searchListingInvoices,
      listingInvoices,
      updateListingInvoicesCurrentPage,
      updateListingInvoicesResultsPerPage,
      adId,
    } = this.props;

    const { searchString, activeSubTab, sortModel } = listingInvoices;

    let payload = {
      page: 1,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    updateListingInvoicesResultsPerPage({ resultsPerPage });
    updateListingInvoicesCurrentPage({ page: 1 });
    searchListingInvoices(payload);
  };

  onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    const {
      updateListingInvoicesSortModel,
      searchListingInvoices,
      listingInvoices,
      adId,
    } = this.props;

    const { currentPage, resultsPerPage, searchString, activeSubTab } =
      listingInvoices;

    updateListingInvoicesSortModel({ sortModel });

    let payload = {
      page: currentPage,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    searchListingInvoices(payload);
  };

  handleRowClasses = (params) => {
    return `row-${params?.data?.statusForSort?.toLowerCase()}`;
  };

  getNoRowsMessage = () => {
    const { listingInvoices } = this.props;
    let message = "No invoices found";

    if (listingInvoices.searchString) message += " for this specific search";

    return message;
  };

  render() {
    const {
      onGridReadyInit,
      onRowClicked,
      listingInvoices,
      getSelectedInvoices,
      handlePaymentSidebar,
      handleCancelAction,
      numberOfInvoicesSelected,
      selectedRows,
    } = this.props;
    const {
      invoicesData,
      pageCount,
      resultsPerPage,
      subTabs,
      activeSubTab,
      currentPage,
      searchString,
      sortModel,
      totalInvoiceAmount,
      inProgress,
    } = listingInvoices;

    const { columnDefs } = this.state;

    return (
      <FTSAgGridDataTable
        rowData={invoicesData}
        columnDefs={columnDefs}
        isMultiTab
        subTabs={subTabs}
        tableHeader={`$${totalInvoiceAmount.toFixed(2)}`}
        tableSubHeader="Total Amount"
        onSortChanged={this.onSortChanged}
        onPageChange={this.onPageChange}
        onSubTabChange={this.onSubTabChange}
        pageCount={pageCount}
        currentPageSize={resultsPerPage}
        activeSubTabId={activeSubTab.id}
        handleSearchInput={this.handleSearchInput}
        onRowClicked={onRowClicked}
        handleFilterSize={this.handleFilterSize}
        currentPage={currentPage}
        searchValue={searchString}
        defaultSortModel={sortModel}
        getRowClass={this.handleRowClasses}
        onGridReadyInit={onGridReadyInit}
        onSelectionChanged={getSelectedInvoices}
        showActionSection={!!numberOfInvoicesSelected}
        submitButtonLabel={"Send/Collect payment"}
        tableActionSectionText={`${numberOfInvoicesSelected} selected invoices`}
        handleSubmitAction={handlePaymentSidebar}
        handleCancelAction={handleCancelAction}
        rowSelection={"multiple"}
        inProgress={inProgress}
        selectedRows={selectedRows}
        noRowsMessage={this.getNoRowsMessage()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listingInvoices: state.listingInvoices,
  };
};

export default connect(mapStateToProps, {
  searchListingInvoices,
  updateListingInvoicesActiveSubTab,
  updateListingInvoicesSearchInput,
  updateListingInvoicesCurrentPage,
  updateListingInvoicesResultsPerPage,
  updateListingInvoicesSortModel,
  setListingInvoicesLastViewedListing,
})(ListingInvoices);
