import styled from "styled-components";
import React from "react";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { InputLabel } from "../InputLabel";
const Label = styled(FtsWrapper) `
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
const Switch = styled(FtsWrapper) `
  width: 40px;
  height: 24px;
  position: relative;
  border-radius: 999px;
  padding: 4px;
  transition: 300ms all;
  outline: 1px solid #e5eaef;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: #2a343e;
    transform: translate(0, -50%);
  }
`;
const Input = styled.input `
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: ${ColorsValue["blue-accent-500"]};

    &:before {
      transform: translate(16px, -50%);
      background: ${ColorsValue["greyscale-0"]};
    }
  }

  &:disabled + ${Switch} {
    &:before {
      background: ${ColorsValue["greyscale-200"]};
    }
  }
  &:checked:disabled + ${Switch} {
    background: ${ColorsValue["blue-accent-200"]};
    &:before {
      background: ${ColorsValue["greyscale-0"]};
    }
  }
`;
export const ToggleSwitch = (props) => {
    return (React.createElement(Label, { onClick: !props.disabled && props.onClick },
        React.createElement(Input, { ...props, required: props.required, type: "checkbox" }),
        React.createElement(Switch, null),
        React.createElement(InputLabel, { label: props.label, required: props.required })));
};
