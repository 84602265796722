/* eslint-disable indent */
import React, { createRef } from "react";
import { connect } from "react-redux";
import {
  Card,
  Col,
  Button,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  Popover,
  PopoverBody,
  TabPane,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { history } from "../../../history";

import { hideMenu } from "react-contextmenu";
import Icon from "../../../components/fts/Icons";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import ConfirmationModal from "../../../components/fts/confirmation-modal";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import EmailTypePicker from "../../../components/fts/emailTypePicker";

import {
  getFolderTemplates,
  getAllSystemEmails,
  getSystemTemplates,
  getTemplatesFolders,
  resendSystemTemplate,
  updateComunicationsCounter,
  updateEmailsFolders,
} from "../../../redux/actions/marketing";

import "../../../assets/scss/plugins/tables/_agGridStyleOverride.scss";
import "../../../assets/scss/pages/users.scss";
import "../../../assets/scss/pages/account-marketing.scss";
import "../../../assets/scss/components/fts/form-fields.scss";
import {
  defineColumnsEmails,
  defineColumnsSystemEmails,
} from "../accounts/columns-emails";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";

// const sortTypes = {
//   SALES: "sales",
//   RENEWAL: "renewal",
//   UPDATE: "update",
//   PAYMENT: "payment",
//   PROMOTION: "promotion",
//   SPECIAL_OFFERS: "specialOffers",
//   OTHER: "other",
// };
class Marketing extends React.Component {
  state = {
    isOpen: false,
    activeTab: "general",
    emailType: "all",
    defaultColDef: {
      sortable: true,
    },
    initialLoad: false,
    columnDefsEmails: [],
    columnDefsSystemEmails: [],
    sidebar: false,
    updating: false,
    action: "",
    actionItem: null,
    activeTableTab: "Sales",
    popoverTarget: "",
    tableData: null,
    popoverData: null,
    totalRecords: 0,
    inProgress: false,
    currentPage: 1,
    page: 1,
    resultsPerPage: 10,
    pageSize: 10,
    currentSystemPage: 1,
    systemPage: 1,
    systemResultsPerPage: 10,
    systemPageSize: 10,
    tabs: [],
    recipientsPopoverTarget: "",
    recipientsPopoverOpen: false,
    recipientsPopoverData: null,
  };

  pageSizes = [10, 20, 25, 50, 100];

  formikSidebarRef = createRef();
  systemGridApi = createRef();
  generalGridApi = createRef();
  componentDidMount() {
    this.setState({
      initialLoad: true,
    });
    this.props
      .getTemplatesFolders({
        status: !this.props.accountId && !this.props.meta && "sent",
      })
      .then(({ data }) => {
        this.props.updateEmailsFolders(data);
        this.getTabTemplates(this.state.activeTableTab);
        this.setState({
          columnDefsEmails: defineColumnsEmails(
            this.collectDataForCopy,
            this.callToAction,
            this.togglePopOver,
            !this.props.accountData,
            this.props.meta,
            this.toggleRecipientsPopOver,
          ),
          columnDefsSystemEmails: defineColumnsSystemEmails(
            this.collectDataForCopy,
            this.callToAction,
            this.resendSystemTemplate,
            this.togglePopOver,
            this.props.meta,
            this.toggleRecipientsPopOver,
          ),
        });

        this.getSystemTemplates(true);

        this.setState({
          initialLoad: false,
        });
      });
  }

  togglePopOver = (popOverName, popoverData) => {
    if (popOverName) {
      return this.setState({
        popoverTarget: popOverName,
        popoverOpen: true,
        popoverData,
      });
    }
    return this.setState({
      popoverTarget: "",
      popoverOpen: false,
      popoverData: null,
    });
  };

  toggleRecipientsPopOver = (popOverName, recipientsPopoverData) => {
    if (popOverName) {
      return this.setState({
        recipientsPopoverTarget: popOverName,
        recipientsPopoverOpen: true,
        recipientsPopoverData,
      });
    }
    return this.setState({
      recipientsPopoverTarget: "",
      recipientsPopoverOpen: false,
      recipientsPopoverData: null,
    });
  };

  resendSystemTemplate = ({ id, recipient }) => {
    this.props
      .resendSystemTemplate(id)
      .then(() => {
        toast.success("Email sent successefully to " + recipient);
        this.getSystemTemplates();
      })
      .catch((e) => {
        toast.error("Oops! Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(e);
        console.log(e.message);
        console.log(e.response);
      });
  };
  updateActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  setFormikSidebarRef = (ref) => {
    this.formikSidebarRef = ref;
  };

  callToAction = (action, update, item) => {
    if (!action) return;
    this.setState({
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
    });
  };

  handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (bool === false && this.formikSidebarRef?.dirty && !closeWithoutPrompt) {
      return this.setState({ isOpen: true });
    } else {
      this.setState({ sidebar: bool, updating: !!update });
    }
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  generateTabs = () => {
    let counters = {};
    this.props.emailsFolders.forEach((folder) => {
      counters[folder.name] = folder.count;
    });
    const tabs = this.props.emailsFolders.map((folder) => ({
      ...folder,
      label: folder.name + " Emails",
      type: folder.name,
      total: folder.count,
    }));

    return tabs.map((itm) => {
      if (itm.total > 0) {
        return (
          <NavItem key={itm.type}>
            <NavLink
              className={classnames({
                active: this.state.activeTableTab === itm.type,
              })}
              onClick={() => {
                this.toggleTableTab(itm.type);
              }}
            >
              {itm.label} ({Number(itm.total.toFixed(1)).toLocaleString()})
            </NavLink>
          </NavItem>
        );
      }
      return null;
    });
  };

  closeSideOutAction = () => {
    this.setState({ sidebar: false, update: false, isOpen: false });
  };

  setActionItem = (actionItem) => {
    this.setState({ actionItem: { ...actionItem } });
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openTemplateSidebar = (data) => {
    this.callToAction("template_preview", false, data);
  };

  resetApi = () => {
    this.gridApi = null;
  };

  onGeneralGridReady = (params) => {
    this.generalGridApi.current = params.api;
    this.gridColumnApi = params.columnApi;

    if (localStorage.getItem("emailsColumnState") !== null) {
      this.gridColumnApi.setColumnState(
        JSON.parse(localStorage.getItem("emailsColumnState")),
      );
    }
  };
  //
  onSystemGridReady = (params) => {
    this.systemGridApi.current = params.api;
    // this.systemGridColumnApi = params.columnApi;

    // if (localStorage.getItem("emailsColumnState") !== null) {
    //   this.gridColumnApi.setColumnState(
    //     JSON.parse(localStorage.getItem("emailsColumnState"))
    //   );
    // }
  };
  //
  // onGeneralGridReady = (params) => {
  //   this.generalGridApi.current = params.api;
  //   // this.generalGridColumnApi = params.columnApi;
  //   params.api.showLoadingOverlay();
  //
  //   // if (localStorage.getItem("emailsColumnState") !== null) {
  //   //   this.gridColumnApi.setColumnState(
  //   //     JSON.parse(localStorage.getItem("emailsColumnState"))
  //   //   );
  //   // }
  // };

  onPageChanged = ({ selected }) => {
    if (this.systemGridApi?.current) {
      this.systemGridApi.current.showLoadingOverlay();
    }
    if (this.generalGridApi?.current) {
      this.generalGridApi.current.showLoadingOverlay();
    }
    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      this.getTabTemplates(this.state.activeTableTab);
    });
  };

  onSystemPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ systemPage: pageNum }, () => {
      this.getSystemTemplates();
    });
  };

  onSortChanged = (params) => {
    this.setState(
      {
        sortModel: params.api.getSortModel(),
      },
      () => {
        this.getSystemTemplates();
      },
    );
  };

  getEmailsCounter = () => {
    let counter = 0;
    this.props.emailsFolders &&
      this.props.emailsFolders.forEach((folder) => {
        counter += folder.count;
      });
    return counter;
  };

  getTabTemplates = (tab) => {
    const { page, resultsPerPage } = this.state;
    let selectedFolder = this.props.emailsFolders.find(
      (folder) => folder.name === tab,
    );

    if (!selectedFolder) return;

    this.props
      .getFolderTemplates({
        folderId: selectedFolder.id,
        page: page,
        status: this.props.meta
          ? "all"
          : !this.props.accountId
          ? "sent"
          : "undefined",
        accountId: this.props.accountId,
        resultsPerPage: resultsPerPage,
      })
      .then((response) => {
        this.setState(
          {
            tableData: response.data.result.templates,
            totalRecords: response.data.result.total_items,
            overlayShown: false,
            columnDefsEmails: defineColumnsEmails(
              this.collectDataForCopy,
              this.callToAction,
              this.togglePopOver,
              !this.props.accountData,
              this.props.meta,
              this.toggleRecipientsPopOver,
            ),
          },
          () => this.generalGridApi?.current?.hideOverlay(),
        );
      })
      .catch((err) => console.log(err));
  };

  getSystemTemplates = (dispatchCounter) => {
    if (this.systemGridApi?.current) {
      this.systemGridApi.current.showLoadingOverlay();
    }
    const { systemPage, systemResultsPerPage, sortModel } = this.state;
    this.props
      .getSystemTemplates({
        page: systemPage,
        resultsPerPage: systemResultsPerPage,
        accountId: this.props.accountId,
        emailType: this.state.emailType,
        status: this.props.meta && "all",
        sortModel,
      })
      .then((response) => {
        this.setState(
          {
            systemTableData: response.data.result.rows,
            systemTotalRecords: response.data.result.count,
            overlayShown: false,
          },
          () => this.systemGridApi?.current?.hideOverlay(),
        );

        if (dispatchCounter)
          this.props.updateComunicationsCounter({
            emailsCount: this.getEmailsCounter() + response.data.result.count,
          });
      })
      .catch((err) => console.log(err));
  };

  getAllSystemEmails = (dispatchCounter) => {
    if (this.systemGridApi?.current) {
      this.systemGridApi.current.showLoadingOverlay();
    }
    const { systemPage, systemResultsPerPage, sortModel } = this.state;
    this.props
      .getAllSystemEmails({
        page: systemPage,
        resultsPerPage: systemResultsPerPage,
        emailType: this.state.emailType,
        status: this.props.meta && "all",
        sortModel,
      })
      .then((response) => {
        this.setState(
          {
            systemTableData: response.data.result.rows,
            systemTotalRecords: response.data.result.count,
            overlayShown: false,
          },
          () => this.systemGridApi?.current?.hideOverlay(),
        );

        if (dispatchCounter)
          this.props.updateComunicationsCounter({
            emailsCount: this.getEmailsCounter() + response.data.result.count,
          });
      })
      .catch((err) => console.log(err));
  };

  toggleTableTab = (tab) => {
    if (this.generalGridApi?.current) {
      this.generalGridApi.current.showLoadingOverlay();
    }
    if (this.state.activeTableTab !== tab) {
      this.setState({ activeTableTab: tab, page: 1 }, () => {
        this.getTabTemplates(tab);
      });
    }
  };

  filterSize = (type, val) => {
    this.setState(
      {
        pageSize: val,
        resultsPerPage: val,
        page: 1,
        currentSystemPage: 1,
      },
      () => {
        this.getTabTemplates(this.state.activeTableTab);
      },
    );
  };

  filterSizeSystem = (type, val) => {
    this.setState(
      {
        systemPageSize: val,
        systemResultsPerPage: val,
      },
      () => {
        this.getSystemTemplates();
      },
    );
  };

  goToAccount = (accountId) => {
    history.push(`/accounts/${accountId}`);
  };

  previewTemplate = (e) => {
    if (
      e.api.focusedCellController.focusedCellPosition.column.colId !== "buttons"
    ) {
      this.callToAction("template_preview", false, e.data);
    }
  };

  onRowClicked = (e) => {
    if (this.state.overlayShown) return;

    if (this.props.accountData && this.state.activeTab === "system") return;

    if (!this.props.meta) {
      if (this.props.accountData) {
        this.previewTemplate(e);
      } else {
        if (
          e.api.focusedCellController.focusedCellPosition.column.colId !==
          "buttons"
        ) {
          const accountId = e.data?.userId || e.data?.accountId;

          accountId && this.goToAccount(accountId);
        }
      }
    }
  };

  saveEmailsColumns = () => {
    let emailsColumnState = this.gridColumnApi.getColumnState();
    localStorage.setItem(
      "emailsColumnState",
      JSON.stringify(emailsColumnState),
    );
  };

  render() {
    const {
      isOpen,
      activeTab,
      sidebar,
      updating,
      action,
      actionItem,
      activeTableTab,
      defaultColDef,
      columnDefsEmails,
      columnDefsSystemEmails,
      tableData,
      totalRecords,
      page,
      pageSize,
      systemPageSize,
      currentSystemPage,
      systemTotalRecords,
      systemTableData,
    } = this.state;

    const { accountData, meta } = this.props;

    let tabs;

    if (meta) {
      tabs = [
        {
          label: `General Email Templates (${this.getEmailsCounter()})`,
          type: "general",
        },
        {
          label: `System Emails (${systemTotalRecords || 0})`,
          type: "system",
        },
      ];
    } else {
      if (this.props.accountData) {
        tabs = [
          {
            label: `General Email Templates (${this.getEmailsCounter()})`,
            type: "general",
          },
          {
            label: `Sent Emails (${systemTotalRecords || 0})`,
            type: "system",
          },
        ];
      } else {
        tabs = [
          {
            label: `General Email Templates (${this.getEmailsCounter()})`,
            type: "general",
          },
          {
            label: `Sent Emails (${systemTotalRecords || 0})`,
            type: "system",
          },
        ];
      }
    }

    let generalTemplatesPageCount = this.props.emailsFolders?.length
      ? Math.ceil(
          this.props.emailsFolders.find((item) => item.name === activeTableTab)
            .count / pageSize,
        )
      : 0;

    return (
      <>
        <Card className="app-user-list account-marketing">
          {meta && (
            <div className="d-flex w-100 mb-0 align-center justify-content-between header-marketing-buttons">
              <span className="header-marketing-title">Email templates</span>
              <div className="d-flex">
                {activeTab === "system" && (
                  <div className="mr-1" style={{ width: "200px" }}>
                    <EmailTypePicker
                      name={"test"}
                      hasAll
                      placeholder={"Email types"}
                      onChange={(option) => {
                        this.setState({ emailType: option.value }, () => {
                          if (this.props.meta) {
                            this.getSystemTemplates();
                          } else {
                            this.getSystemEmails();
                          }
                        });
                      }}
                    />
                  </div>
                )}
                <div className="nav">
                  {tabs.map((itm) => {
                    return (
                      <button
                        key={itm.type}
                        type="button"
                        className={`tab ${
                          activeTab === itm.type ? "selected" : "unselected"
                        }`}
                        onClick={() => {
                          this.updateActiveTab(itm.type);
                        }}
                      >
                        {itm.label}
                      </button>
                    );
                  })}
                </div>
                <Button
                  onClick={() => {
                    window.open(
                      "https://us20.admin.mailchimp.com/templates/",
                      "_blank",
                    );
                  }}
                  color="primary"
                  className="new-template-button"
                >
                  <span className="button-body">
                    <Icon name="Open" size="24" />
                    New
                  </span>
                </Button>
              </div>
            </div>
          )}
          <Col sm="12" style={{ padding: 0 }}>
            <div>
              {this.state.initialLoad ? (
                <Spinner size="sm" className="m-1" />
              ) : (
                <div>
                  {!meta && (
                    <div className="header-marketing-buttons">
                      <span className="header-marketing-title">
                        Communications
                      </span>

                      <div className="d-flex">
                        {activeTab === "system" && (
                          <div className="mr-1" style={{ width: "200px" }}>
                            <EmailTypePicker
                              name={"test"}
                              hasAll
                              placeholder={"Email types"}
                              onChange={(option) => {
                                this.setState(
                                  { emailType: option.value },
                                  () => {
                                    this.getSystemTemplates();
                                  },
                                );
                              }}
                            />
                          </div>
                        )}
                        <div className="nav">
                          {tabs.map((itm) => {
                            return (
                              <button
                                key={itm.type}
                                type="button"
                                className={`tab ${
                                  activeTab === itm.type
                                    ? "selected"
                                    : "unselected"
                                }`}
                                onClick={() => {
                                  this.updateActiveTab(itm.type);
                                }}
                              >
                                {itm.label}
                              </button>
                            );
                          })}
                        </div>
                        <Button
                          onClick={() => {
                            window.open(
                              "https://us20.admin.mailchimp.com/templates/",
                              "_blank",
                            );
                          }}
                          color="primary"
                          className="new-template-button"
                        >
                          <span className="button-body">
                            <Icon name="Open" size="24" />
                            New
                          </span>
                        </Button>
                      </div>
                    </div>
                  )}

                  {activeTab === "system" && (
                    <FTSDataTable
                      emailsTab
                      onScroll={hideMenu}
                      isSearch
                      onSortChanged={this.onSortChanged}
                      onRowClicked={this.onRowClicked}
                      pageSizes={this.pageSizes}
                      filterSize={this.filterSizeSystem}
                      columnDefs={columnDefsSystemEmails}
                      rowData={systemTableData}
                      totalRecords={systemTotalRecords}
                      resetApi={this.resetApi}
                      tableName={"system"}
                      onGridReadyInit={this.onSystemGridReady}
                      saveState={this.saveEmailsColumns}
                      currentPageSize={systemPageSize}
                      currentPage={currentSystemPage}
                      pageCount={Math.ceil(systemTotalRecords / systemPageSize)}
                      onPageChange={this.onSystemPageChanged}
                      {...{
                        defaultColDef,
                      }}
                    />
                  )}

                  {activeTab === "general" && (
                    <div className="nav-horizontal">
                      <Nav tabs>{this.generateTabs()}</Nav>
                      <TabContent activeTab={activeTableTab}>
                        <TabPane tabId={activeTableTab}>
                          <FTSDataTable
                            tableName={"general"}
                            emailsTab
                            onScroll={hideMenu}
                            isSearch
                            onRowClicked={this.onRowClicked}
                            pageSizes={this.pageSizes}
                            filterSize={this.filterSize}
                            columnDefs={columnDefsEmails}
                            rowData={tableData}
                            totalRecords={totalRecords}
                            resetApi={this.resetApi}
                            onGridReadyInit={this.onGeneralGridReady}
                            saveState={this.saveEmailsColumns}
                            currentPageSize={pageSize}
                            currentPage={page}
                            pageCount={generalTemplatesPageCount}
                            onPageChange={this.onPageChanged}
                            {...{
                              defaultColDef,
                            }}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Card>

        <ActionSidebar
          isMeta={this.props.meta}
          activeTableTab={activeTableTab}
          onSubmitQuery={this.onSubmitQuery}
          onPromotionCreate={this.onPromotionCreate}
          setFormikSidebarRef={this.setFormikSidebarRef}
          adminData={this.props.userData}
          getSystemTemplates={this.getSystemTemplates}
          isPromotion
          handleSidebar={this.handleSidebar}
          noMarker
          isSalesPage
          show={sidebar}
          action={action}
          updating={updating}
          actionItem={actionItem}
          accountInfo={accountData}
        />

        <ContextMenu
          style={{ minWidth: 250 }}
          id="contextMenuMarketing"
          preventHideOnScroll={false}
        >
          <MenuItem
            onClick={() =>
              navigator.clipboard.writeText(this.state.collectedDataForCopy)
            }
          >
            <FaRegCopy size={18} className="copy" />
            <span className={"ml-1"}>Copy</span>
          </MenuItem>
        </ContextMenu>

        <ConfirmationModal
          title="Unsaved Changes"
          message="Are you sure you want to proceed with this action?"
          closeModal={this.toggleModal}
          confirmModal={this.closeSideOutAction}
          {...{ isOpen }}
        />

        {this.state.popoverOpen && (
          <Popover
            className="bg-marketing-popover"
            popperClassName={"bg-popover"}
            // style={{ minWidth: 600, padding: 0 }}
            placement="bottom"
            isOpen={this.state.popoverOpen}
            target={this.state.popoverTarget}
            toggle={() => {}}
          >
            <h5 style={{ color: "#fff" }}>Email activities</h5>

            <PopoverBody style={{ padding: 0 }}>
              {this.state.popoverData.length ? (
                this.state.popoverData.map((e, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        color: "#fff",
                        borderBottom:
                          i + 1 < this.state.popoverData.length
                            ? "1px solid #B1BABD"
                            : "",
                        paddingBottom:
                          i + 1 < this.state.popoverData.length ? "1rem" : "",
                      }}
                      className="d-flex mb-1 "
                    >
                      <p
                        className={"mr-1"}
                        style={{ fontSize: 12, color: "#B1BABD" }}
                      >
                        #{i + 1}
                      </p>
                      <div className={"mr-1"}>
                        <p className={"m-0 p-0"}>
                          {moment(e.updatedAt).format("ll")}
                        </p>
                        <p className={"m-0 p-0"}>
                          at {moment(e.updatedAt).format("LT")}
                        </p>
                      </div>
                      <p
                        className={"mr-1 text-center"}
                        style={{ minWidth: 60, maxWidth: 60 }}
                      >
                        {getBadge(e.activity)}
                      </p>
                      <p> by {e.sendBy}</p>
                    </div>
                  );
                })
              ) : (
                <h5 style={{ color: "#fff" }}>No activity</h5>
              )}
            </PopoverBody>
          </Popover>
        )}
        {this.state.recipientsPopoverOpen && (
          <Popover
            className="bg-marketing-popover"
            popperClassName={"bg-popover"}
            placement="bottom"
            isOpen={this.state.recipientsPopoverOpen}
            target={this.state.recipientsPopoverTarget}
            toggle={() => {}}
          >
            <>
              <h5 style={{ color: "#fff" }}>Emails</h5>
              <PopoverBody style={{ padding: 0 }}>
                {this.state.recipientsPopoverData.map((e, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        color: "#fff",
                      }}
                      className="d-flex mb-1 mt-1"
                    >
                      <p
                        className={"mr-2"}
                        style={{ fontSize: 12, color: "#B1BABD" }}
                      >
                        #{i + 1}
                      </p>
                      <div>
                        <p
                          style={{ fontSize: 16, color: "#fff" }}
                          className={"m-0 p-0"}
                        >
                          {e}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </PopoverBody>
            </>
          </Popover>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    emailsFolders: state.essentialLists.emailsFolders,
    emailTypes: state.essentialLists.emailTypes,
  };
};

const mapDispatchToProps = {
  getFolderTemplates,
  getAllSystemEmails,
  getSystemTemplates,
  resendSystemTemplate,
  updateComunicationsCounter,
  getTemplatesFolders,
  updateEmailsFolders,
};

const getBadge = (activity) => {
  switch (activity) {
    case "link_opened": {
      return (
        <div
          className={"rounded-pill "}
          style={{ padding: 5, backgroundColor: "#01B5F5", fontSize: 10 }}
        >
          OPENED
        </div>
      );
    }
    case "email_viewed": {
      return (
        <div
          className={"rounded-pill "}
          style={{ padding: 5, backgroundColor: "#F5B053", fontSize: 10 }}
        >
          VIEWED
        </div>
      );
    }
    case "sent": {
      return (
        <div
          className={"rounded-pill "}
          style={{ padding: 5, backgroundColor: "#76bf92", fontSize: 10 }}
        >
          SENT
        </div>
      );
    }
    case "created": {
      return (
        <div
          className={"rounded-pill bg-info"}
          style={{ padding: 5, fontSize: 10 }}
        >
          IN QUEUE
        </div>
      );
    }
    case "bounced": {
      return (
        <div
          className={"rounded-pill bg-warning"}
          style={{ padding: 5, fontSize: 10 }}
        >
          BOUNCED
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Marketing);
