import supportedFileTypes from "../../../../data/supported-file-types";

export const defaultOptions = {
  restrictions: {
    maxFileSize: null,
    minFileSize: null,
    maxTotalFileSize: null,
    maxNumberOfFiles: null,
    minNumberOfFiles: null,
    allowedFileTypes: supportedFileTypes.map((type) => `.${type.value}`),
  },
  meta: {},
  infoTimeout: 4000,
  showLinkToFileUploadResult: true,
  showProgressDetails: true,
  hideUploadButton: false,
  hideRetryButton: false,
  hidePauseResumeButton: false,
  hideCancelButton: false,
  hideProgressAfterFinish: false,
  allowMultipleUploads: true,
  width: "100%",
  height: "35rem",
};

export const dashboardDefaultOptions = {
  inline: false,
  thumbnailWidth: 280,
  showLinkToFileUploadResult: true,
  showProgressDetails: false,
  hideUploadButton: false,
  hideRetryButton: false,
  hidePauseResumeButton: false,
  hideCancelButton: false,
  hideProgressAfterFinish: false,
  note: null,
  closeModalOnClickOutside: false,
  closeAfterFinish: false,
  disableStatusBar: false,
  disableInformer: false,
  disableThumbnailGenerator: false,
  disablePageScrollWhenModalOpen: true,
  animateOpenClose: true,
  fileManagerSelectionType: "files",
  proudlyDisplayPoweredByUppy: false,
  showSelectedFiles: true,
  showRemoveButtonAfterComplete: false,
  browserBackButtonClose: false,
};
