/* eslint-disable indent */
import React from "react";
import { ChevronDown, X } from "react-feather";
import { default as ReactSelect, components } from "react-select";

import { pickerStyles as styles } from "./picker-style";

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <X size={16} />
    </components.ClearIndicator>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown size={18} />
    </components.DropdownIndicator>
  );
};

const Input = ({ ...props }) => (
  <components.Input {...props} autoComplete="new-password" />
);

const Select = ({
  components: customComponents,
  placeholder,
  noPlaceholder,
  value,
  isDisabled,
  menuPlacement,
  ...rest
}) => {
  return (
    <ReactSelect
      className="React"
      classNamePrefix="select"
      components={{
        ClearIndicator,
        DropdownIndicator,
        Input,
        ...customComponents,
      }}
      placeholder={
        noPlaceholder
          ? ""
          : (!value || typeof value !== "object") && isDisabled
          ? "-"
          : placeholder
      }
      {...{ styles, value, isDisabled, menuPlacement }}
      {...rest}
    />
  );
};

export default Select;
