import React, { useEffect, useState } from "react";
import FullPageLayout from "../../../layouts/FullpageLayout";
import { Button, Col, Row, Spinner } from "reactstrap";
import Icon from "../../../components/fts/Icons";
import axios from "axios";
import appConfig from "../../../configs/appConfig";
import { formatResponse } from "../../recipient";
import queryString from "query-string";

const PaymentStatus = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (props.match.params.status === "success") {
      setMessage("Payment successfully proceeded");
      setIsLoading(true);
      axios
        .get(appConfig.apiURL + "admin/payment-by-link-hash", {
          params: { hash: props.match.params.token },
        })
        .then(({ data }) => {
          let newData = formatResponse(
            decodeURIComponent(JSON.stringify(data.results))
          );

          setInvoices(JSON.parse(newData));
          const { paymentId } = queryString.parse(props.location.search);
          if (paymentId) {
            axios
              .post(appConfig.apiURL + "admin/payment/create", {
                invoices: JSON.parse(newData),
                status: "succeeded",
                paidMethod: "card",
                paidSource: "paypal",
                sourceId: paymentId,
              })
              .then((e) => {
                console.log(e);
              })
              .catch((e) => {
                console.log(e.response);
              });
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.log("ERROR: ", e);
          setError("Oops! Something went wrong. Please try again.");
        });
    }
  }, [
    props.match.params.status,
    props.location.search,
    props.match.params.token,
  ]);
  if (error) {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <h2>{error}</h2>
      </div>
    );
  }
  if (isLoading || !invoices.length) {
    return (
      <div
        style={{ height: "100vh", width: "100vw" }}
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <Spinner size={"lg"} color="primary" />
      </div>
    );
  }
  return (
    <FullPageLayout bgColor={"#CE1B3B"}>
      <Row>
        <Col
          md={{ size: 6, offset: 3 }}
          className="bg-white p-4 shadow-lg rounded-lg"
        >
          <Col md={12} className={"text-center mb-5"}>
            <Icon name="CheckCircle2" size={200} />
            <h3 style={{ color: "#86D5A7" }}>{message}</h3>
          </Col>
          <Row>
            <Col md={{ size: 6 }}>
              <Button className={"w-100"} color="secondary" onClick={() => {}}>
                Download invoice
              </Button>
            </Col>{" "}
            <Col md={{ size: 6 }}>
              <Button className={"w-100"} color="primary " onClick={() => {}}>
                Download receipt
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </FullPageLayout>
  );
};

export default PaymentStatus;
