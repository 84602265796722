import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import Icon from "../../../fts/Icons";
import { Span } from "../../dist/ui/src/FtsText";
import { Spacing } from "../../dist/types/ui/spacing";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { ColorsValue } from "../../dist/types/ui/colors";
import { FtsButton } from "../../dist/ui/src/FtsButton/FtsButton";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "../../dist/ui/src/FtsWrapper/FtsWrapper";
import { setAltContactAction } from "../../../../redux/actions/v2/altContacts";
import {
  ButtonColorsValue,
  ButtonSizes,
  ButtonVariants,
} from "../../dist/types/ui/button";
import { mobileOptIn, primaryOptIn } from "../constants";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";

export const AccountAltContactItem = ({
  altContact,
  setHideAddButton,
  disableEdit,
}) => {
  const phone = altContact.altContactPhoneNumber;
  const allOptedIn = [...mobileOptIn, ...primaryOptIn];
  const showOptedIn = allOptedIn.find((optIn) => altContact[optIn]);
  const showDot = altContact.altContactEmail && phone;
  const dispatch = useDispatch();
  const name =
    altContact.altContactFirstName + " " + altContact.altContactLastName;

  const onEdit = useCallback(() => {
    setHideAddButton(true);
    dispatch(setAltContactAction(altContact));
  }, [altContact]);

  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsRow
        columnGap={"lg"}
        rowGap={"lg"}
        borderRadius={"2xs"}
        py={"lg"}
        px={"2xl"}
        border={`${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]}`}
      >
        <FtsColumn size={{ xs: 8 }} display={"flex"} align={"center"}>
          <Icon name={"User"} size={32} />
          <FtsRow ml={"lg"} rowGap={"0"}>
            <FtsColumn size={{ xs: 12 }} textAlign={"left"}>
              <Span
                variant={VariantsValues["text-2xs"]}
                color={ColorsValue["greyscale-800"]}
                weight={WeightSize.medium}
              >
                {name}
              </Span>
            </FtsColumn>
            <FtsColumn size={{ xs: 12 }} textAlign={"left"}>
              <Span
                variant={VariantsValues["text-3xs"]}
                color={ColorsValue["greyscale-500"]}
              >
                {altContact.altContactEmail}
              </Span>
              {showDot && (
                <Span
                  variant={VariantsValues["text-3xs"]}
                  color={ColorsValue["greyscale-500"]}
                  px={"xs"}
                >
                  &bull;
                </Span>
              )}
              <Span
                variant={VariantsValues["text-3xs"]}
                color={ColorsValue["greyscale-500"]}
              >
                {phone}
              </Span>
            </FtsColumn>
          </FtsRow>
        </FtsColumn>
        <FtsColumn
          size={{ xs: 4 }}
          py={"3xs"}
          justify={"flex-end"}
          align={"center"}
          display={"flex"}
        >
          {showOptedIn && (
            <>
              <FtsWrapper
                bg={ColorsValue["blue-accent-100"]}
                borderRadius={"md"}
                height={Spacing["xl"]}
                width={Spacing["xl"]}
                align={"center"}
                justify={"center"}
                display={"flex"}
              >
                <Icon
                  name={"Check"}
                  size={16}
                  color={ColorsValue["greyscale-800"]}
                />
              </FtsWrapper>
              <Span
                variant={VariantsValues["text-3xs"]}
                color={ColorsValue["greyscale-800"]}
                ml="sm"
                mr={"lg"}
              >
                Opted-in
              </Span>
            </>
          )}
          <FtsButton
            onClick={onEdit}
            disabled={disableEdit}
            variant={ButtonVariants.outlined}
            color={ButtonColorsValue.accent}
            size={ButtonSizes.sm}
          >
            <Icon size={20} name={"EditListing"} />
            <Span
              variant={VariantsValues["text-3xs"]}
              color={ColorsValue["blue-accent-500"]}
              ml={"xs"}
            >
              Edit
            </Span>
          </FtsButton>
        </FtsColumn>
      </FtsRow>
    </FtsColumn>
  );
};
