import React from "react";
import "../../../assets/scss/components/fts/fts-upgrades-page.scss";
import { Card, FormGroup, Input } from "reactstrap";
import Icon from "../../../components/fts/Icons";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import { defineNewListingsColumns } from "./columns";
import { hideMenu } from "react-contextmenu/modules/actions";
import { connect } from "react-redux";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";
import TableTabs from "../../../components/fts/table-tabs";
import moment from "moment";
import {
  searchListings,
  searchNewListings,
  setNewListingsUnreadCount,
} from "../../../redux/actions/listings";
import { getCounters } from "../../../redux/actions/new-listings";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import { markListingAsRead } from "../../../redux/actions/upgrades";
import { toast } from "react-toastify";
import { history } from "../../../history";
import { activities } from "../../../constants";
import { createActivity } from "../../../redux/actions/activities";
import Icons from "../../../components/fts/Icons";

let tabs = [
  {
    label: "1W",
    type: "week",
    value: {
      quantity: 1,
      range: "week",
    },
  },
  {
    label: "1M",
    type: "month",
    value: {
      quantity: 1,
      range: "month",
    },
  },
  {
    label: "3M",
    type: "3Months",
    value: {
      quantity: 3,
      range: "month",
    },
  },
  {
    label: "6M",
    type: "6Months",
    value: {
      quantity: 6,
      range: "month",
    },
  },
  {
    label: "1Y",
    type: "1Year",
    value: {
      quantity: 1,
      range: "year",
    },
  },
];

class NewListings extends React.Component {
  state = {
    activeTab: "week",
    searchValue: "",
    inProgress: false,
    columnDefsNewListings: null,
    newListingsTableData: null,

    totalRecords: 0,
    pageSize: 20,
    page: 1,
    pageCount: 0,
    defaultColDef: {
      sortable: true,
    },
    sortNewListings: "",
    query: "",
    activeSubTabId: 1,
    subTabs: [
      {
        id: 1,
        label: "All Listings",
        count: 0,
      },
      {
        id: 2,
        label: "Vendor Posted",
        count: 0,
      },
      {
        id: 3,
        label: "FTS Posted",
        count: 0,
      },
      {
        id: 4,
        label: "Private locations",
        count: 0,
      },
    ],

    dateRange: {
      startAt: moment().subtract(1, "week").toISOString().split("T")[0],
      endAt: moment().toISOString().split("T")[0],
    },

    createdByAdmin: null,
  };

  debounce = setTimeout(() => {}, 300);

  componentDidMount() {
    this.getTableData();
    this.setState({
      columnDefsNewListings: defineNewListingsColumns(
        this.collectDataForCopy,
        this.props.employees,
      ),
    });
  }

  pageSizes = [10, 25, 50, 100];

  handleSearch = (e) => {
    clearTimeout(this.debounce);
    this.setState(
      {
        searchValue: e.target.value,
      },
      () => {
        this.debounce = setTimeout(() => {
          this.setState(
            {
              page: 1,
            },
            () => {
              this.getTableData();
            },
          );
        }, 300);
      },
    );
  };

  getListingsCountes = () => {
    const { subTabs, dateRange, searchValue } = this.state;
    let payload = {
      filter: [
        {
          range: {
            createdAt: {
              lte: dateRange.endAt,
              gte: dateRange.startAt,
            },
          },
        },
      ],
      multi_match: [],
      sort: "",
    };

    if (searchValue) {
      payload.multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(searchValue.trim())
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            searchValue.replace(/ /g, " AND ") +
            ")",
          fields: [
            "statusString",
            "idString",
            "userIdString",
            "paymentStatus",
            "updatedAtString",
            "renewalTimestampString",
            "email",
            "name",
            "address",
            "city",
            "state",
            "phoneNumber",
            "phoneNumberSecondary",
            "afterHours",
          ],
          type: "phrase_prefix",
        },
      });
    }

    this.props.getCounters(payload).then(({ data }) => {
      const {
        ftsPostedCount,
        allListings,
        vendorPostedCount,
        privateListingsCount,
      } = data.result;
      let counters = {
        vendorPosted: vendorPostedCount,
        ftsPosted: ftsPostedCount,
        allListings,
        privateListingsCount,
      };
      let newSubTabs = [...subTabs];

      newSubTabs[0].count = allListings || 0;
      newSubTabs[1].count = counters.vendorPosted || 0;
      newSubTabs[2].count = counters.ftsPosted || 0;
      newSubTabs[3].count = counters.privateListingsCount || 0;

      this.setState({
        subTabs: newSubTabs,
      });
    });
  };

  handleActiveTabChanged = ({ type, value }) => {
    if (this.state.inProgress) {
      return;
    }
    let dateRange = {
      startAt: moment()
        .subtract(value.quantity, value.range)
        .toISOString()
        .split("T")[0],
      endAt: moment().toISOString().split("T")[0],
    };

    this.setState(
      {
        activeTab: type,
        dateRange,
      },
      () => {
        this.getTableData();
      },
    );
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  saveStateNewListings = () => {
    let newListingsColumnState = this.gridColumnApiNewListings.getColumnState();
    localStorage.setItem(
      "newListingsColumnState",
      JSON.stringify(newListingsColumnState),
    );
  };

  getTableData = () => {
    const {
      searchValue,
      page,
      pageSize,
      sortNewListings,
      dateRange,
      createdByAdmin,
      activeSubTabId,
    } = this.state;
    console.log(activeSubTabId);
    if (this.gridApiNewListings) this.gridApiNewListings.showLoadingOverlay();

    let payload = {
      name: "",
      page: page,
      resultsPerPage: pageSize,
      searchString: searchValue,
      sort: sortNewListings?.length
        ? sortNewListings
        : [{ colId: "createdAt", sort: "desc" }],
      range: {
        createdAt: {
          lte: dateRange.endAt,
          gte: dateRange.startAt,
        },
      },
    };

    if (createdByAdmin !== null) {
      payload.createdByAdmin = createdByAdmin;
    }
    console.log(activeSubTabId);
    if (activeSubTabId === 1) {
      payload.isPrivate = undefined;
    }
    if (activeSubTabId === 2 || activeSubTabId === 3) {
      payload.isPrivate = false;
    }
    if (activeSubTabId === 4) {
      payload.isPrivate = true;
    }
    console.log(payload);

    this.getListingsCountes();
    this.props
      .searchNewListings(payload)
      .then((res) => {
        this.setState({
          newListingsTableData: res.data.result?.hits,
          pageCount: res.data.result?.nbHits / pageSize,
        });

        if (this.gridApiNewListings) this.gridApiNewListings.hideOverlay();
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  resetApi = () => {
    this.gridApiNewListings = null;
  };

  onGridReady = (params) => {
    this.gridApiNewListings = params.api;
    this.gridColumnApiNewListings = params.columnApi;

    if (localStorage.getItem("newListingsColumnState") !== null) {
      this.gridColumnApiNewListings.setColumnState(
        JSON.parse(localStorage.getItem("newListingsColumnState")),
      );
    }
  };

  onRowClicked = (e) => {
    if (
      !moment(e.data.createdAt).diff(moment(e.data.adminLastViewAt), "minutes")
    ) {
      this.props
        .markListingAsRead(e.data.id)
        .then(() => {
          this.logListingOpenedFromNewListingsListingActivity(e.data);
          this.logListingOpenedFromNewListingsAccountActivity(e.data);
          this.props.setNewListingsUnreadCount(
            this.props.newListingUnReadCount - 1,
          );
          history.push(`/listings/${e.data.id}`);
        })
        .catch((err) => {
          console.log("err: ", err);
          toast.error("Something went wrong!");
        });
    } else {
      history.push(`/listings/${e.data.id}`);
    }
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      this.getTableData();
    });
  };

  filterSize = (type, val) => {
    this.setState(
      {
        pageSize: val,
        page: 1,
      },
      () => {
        this.getTableData();
      },
    );
  };

  handleActiveSubTab = ({ id }) => {
    this.setState(
      {
        activeSubTabId: id,
        createdByAdmin: this.handleCreatedByAdmin({ id }),
      },
      () => {
        this.getTableData();
      },
    );
  };

  handleCreatedByAdmin = ({ id }) => {
    let createdByAdmin = null;
    if (id === 2) createdByAdmin = 0;
    if (id === 3) createdByAdmin = 1;

    return createdByAdmin;
  };

  onSortChanged = (params) => {
    this.setState({ sortNewListings: params.api.getSortModel() }, () => {
      this.getTableData();
    });
  };

  logListingOpenedFromNewListingsListingActivity = async (listing) => {
    let activityPayload = {
      status: 2,
      adId: listing?.id,
      activityType:
        this.props.activitiesTypes
          .LISTING_OPENED_FROM_NEW_LISTINGS_LISTING_ACTIVITY,
      activity: activities.listingOpenedFromNewListingsListingActivity.replace(
        "{{admin_name}}",
        `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
      ),
      adminUserId: this.props.userData.id,
      iconName: "Listings",
    };

    return await this.props.createActivity(activityPayload);
  };

  logListingOpenedFromNewListingsAccountActivity = async (listing) => {
    let activityPayload = {
      status: 2,
      userId: listing?.userId,
      activityType:
        this.props.activitiesTypes
          .LISTING_OPENED_FROM_NEW_LISTINGS_ACCOUNT_ACTIVITY,
      activity: activities.listingOpenedFromNewListingsAccountActivity
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{listing_name}}", `${listing?.name}`),
      adminUserId: this.props.userData.loggedInUser.id,
      iconName: "Listings",
    };

    return await this.props.createActivity(activityPayload);
  };

  render() {
    const {
      searchValue,
      activeTab,
      columnDefsNewListings,
      defaultColDef,
      newListingsTableData,
      totalRecords,
      pageSize,
      page,
      pageCount,
      activeSubTabId,
      subTabs,
    } = this.state;
    return (
      <div className="fts-upgrades-page fts-new-listings">
        <Card>
          <div className="fts-upgrade-title-row">
            <h4>New Listings</h4>
            <div className="fts-upgrade-filters">
              <div className="nav">
                {tabs.map((itm) => {
                  return (
                    <button
                      key={itm.type}
                      className={`tab ${
                        activeTab === itm.type ? "selected" : "unselected"
                      }`}
                      onClick={() => {
                        this.handleActiveTabChanged({
                          type: itm.type,
                          value: itm.value,
                        });
                      }}
                    >
                      {itm.label}
                    </button>
                  );
                })}
              </div>
              <FormGroup className="position-relative has-icon-left ml-1 mb-0">
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    this.handleSearch(e);
                  }}
                  value={searchValue}
                  style={{ minWidth: 300, borderRadius: 4 }}
                  className="upgrades-search-input-field"
                />
                <div className="form-control-position">
                  <Icon name="Search" size={18} />
                </div>
              </FormGroup>
            </div>
          </div>

          <TableTabs
            tabs={subTabs}
            handleActiveTab={this.handleActiveSubTab}
            activeTabId={activeSubTabId}
          />
          <FTSDataTable
            tableName={"upgrades"}
            isSearch
            newListings
            pageSizes={this.pageSizes}
            columnDefs={columnDefsNewListings}
            rowData={newListingsTableData}
            totalRecords={totalRecords}
            resetApi={this.resetApi}
            onGridReadyInit={this.onGridReady}
            saveState={this.saveStateNewListings}
            onSortChanged={(params) =>
              this.onSortChanged(params, "new-listings")
            }
            currentPageSize={pageSize}
            currentPage={page}
            pageCount={pageCount}
            onRowClicked={this.onRowClicked}
            onPageChange={this.onPageChanged}
            onScroll={hideMenu}
            filterSize={this.filterSize}
            {...{
              defaultColDef,
            }}
          />
          <ContextMenu
            style={{ minWidth: 250 }}
            id="contextMenuUpgrades"
            preventHideOnScroll={false}
          >
            <MenuItem
              onClick={() =>
                navigator.clipboard.writeText(this.state.collectedDataForCopy)
              }
            >
              <FaRegCopy size={18} className="copy" />
              <span className="ml-1 text-dark">Copy</span>
            </MenuItem>
            {this.state.call && this.state.collectedDataForCopy && (
              <MenuItem>
                <Icons name="PhoneBlue" />
                <a
                  className="ml-1 text-decoration-none text-dark w-100"
                  href={`tel:${this.state.collectedDataForCopy}`}
                >
                  Call - {this.state.collectedDataForCopy}
                </a>
              </MenuItem>
            )}
          </ContextMenu>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.essentialLists.employees,
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
    newListingUnReadCount: state.listingCounts.newListingUnReadCount,
  };
};

export default connect(mapStateToProps, {
  searchListings,
  searchNewListings,
  getCounters,
  markListingAsRead,
  setNewListingsUnreadCount,
  createActivity,
})(NewListings);
