import React from "react";
import { formatRawDate } from "../../../configs/dateConfig";
import { ContextMenuTrigger } from "react-contextmenu";
import moment from "moment";
import { Col } from "reactstrap";
// import SalesEmployeePicker from "../../../components/fts/sales-employee-picker";
import PaidMethodPicker from "../../../components/fts/paid-method-picker";
import PaidSourcePicker from "../../../components/fts/paid-source-picker";
import { capitalize } from "lodash";

import { Eye } from "react-feather";
import Icon from "../../../components/fts/Icons";

const TableDate = ({ date }) => {
  return (
    <div className="ag-date-table">
      <div className="ag-date-table__date">{formatRawDate(date, false)}</div>
    </div>
  );
};

const colorStatusID = (status) => {
  let backgroundColor;
  let textColor;
  let label;
  switch (status) {
    case "Paid":
      textColor = "#107942";
      label = "Paid";
      break;

    case "Overdue":
      textColor = "#C13538";
      label = "Overdue";
      break;

    case "Voided":
      textColor = "#0077CD";
      label = "Voided";
      break;

    case "Unsuccessful":
      textColor = "#5D6D7C";
      label = "Unsuccessful";
      break;

    default:
      textColor = "#8B680F";
      label = "Pending";
      break;
  }

  return { backgroundColor, textColor, label };
};

export const defineInvoicesColumns = (
  collectDataForCopy,
  accountId,
  listingId,
  refreshTable,
  invoiceToOpen,
  togglePopOver,
) => {
  let columnsInvoices = [
    {
      headerName: "",
      field: "selected",
      width: 50,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: (params) => {
        if (params.data.id === invoiceToOpen) params.node.setSelected(true);
        return params.data.statusForSort !== "Paid" ? true : false;
      },
    },
    {
      headerName: "Status",
      field: "statusForSort",
      sortable: true,
      unSortIcon: true,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let { textColor } = colorStatusID(params.data.statusForSort);

        return (
          <ContextMenuTrigger
            data={params.data.statusForSort}
            collect={collectDataForCopy}
            id="contextMenuInvoices"
          >
            <div className="d-flex align-items-center">
              <span style={{ color: textColor, fontSize: 14 }}>
                {params.data.statusForSort}
              </span>
              {params.data.statusForSort === "Voided" && (
                <span
                  onMouseEnter={() => {
                    params.data.statusForSort === "Voided" &&
                      togglePopOver(
                        `InvoiceStatus_${params.data.id}`,
                        params?.data["activities.activityNote"],
                      );
                  }}
                  onMouseLeave={() => togglePopOver()}
                  id={`InvoiceStatus_${params.data.id}`}
                  style={{ marginLeft: "10px", zIndex: "10000" }}
                >
                  <Icon
                    name="InfoCircle"
                    size="20"
                    style={{
                      stroke: "none",
                    }}
                  />
                </span>
              )}
            </div>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "TID",
      field: "payments.sourceIdForSort",
      width: 270,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["payments.sourceId"]}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data["payments.sourceId"]}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Assigned to",
      field: "adminOwnerForSort",
      width: 170,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={
            params.data["owner.assignedAdmins.firstName"] +
            params.data["owner.assignedAdmins.lastName"]
          }
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>
            {params.data["owner.assignedAdmins.firstName"]}{" "}
            {params.data["owner.assignedAdmins.lastName"]}
          </span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Invoice number",
      field: "invoiceNumberForSort",
      width: 205,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data.invoiceNumber}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data.invoiceNumber}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Amount",
      field: "total",
      width: 90,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["payments.total"] || params.data.total}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>${params.data["payments.total"] || params.data.total}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "Due date",
      field: "dueAt",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data.dueAt).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuInvoices"
          >
            <span>
              <TableDate date={params.data.dueAt} />
            </span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Payment date",
      field: "payments.updatedAt",
      sortable: true,
      unSortIcon: true,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={moment(params.data["payments.updatedAt"]).format("lll")}
            collect={collectDataForCopy}
            id="contextMenuInvoices"
          >
            <span>
              {params.data["payments.updatedAt"] ? (
                <TableDate date={params.data["payments.updatedAt"]} />
              ) : null}
            </span>
          </ContextMenuTrigger>
        );
      },
    },

    {
      headerName: "Payment method",
      field: "payments.paidMethodForSort",
      minWidth: 230,
      cellRendererFramework: (params) => {
        if (params.data["payments.paidMethodForSort"] !== "unknown") {
          return (
            <ContextMenuTrigger
              data={params.data["payments.paidMethod"]}
              collect={collectDataForCopy}
              id="contextMenuInvoices"
            >
              <span>
                {params.data["payments.paidMethod"] === "ach"
                  ? params.data["payments.paidMethod"].toUpperCase()
                  : capitalize(params.data["payments.paidMethod"])}
              </span>
            </ContextMenuTrigger>
          );
        }
        return (
          <Col
            style={{
              padding: "0",
            }}
          >
            <PaidMethodPicker
              placeholder={"Select payment method"}
              refreshTable={refreshTable}
              paymentId={params.data["payments.id"]}
            />
            {/*<SalesEmployeePicker*/}
            {/*  // isDisabled={true}*/}
            {/*  assignedTo={params.data.owner.adminAccountOwner}*/}
            {/*  companyName={params.data.owner.companyName}*/}
            {/*  userId={params.data.owner.id}*/}
            {/*  placeholder="Assigned to"*/}
            {/*  // list={list}*/}
            {/*  // refreshTable={refreshTable}*/}
            {/*/>*/}
          </Col>
        );
      },
    },

    {
      headerName: "Payment source",
      field: "payments.paidSourceForSort",
      minWidth: 225,
      cellRendererFramework: (params) => {
        if (params.data["payments.paidSourceForSort"] !== "unknown") {
          return (
            <ContextMenuTrigger
              data={params.data["payments.paidSource"]}
              collect={collectDataForCopy}
              id="contextMenuInvoices"
            >
              <span
                onClick={() => {
                  let url;
                  const paymentSource = params.data["payments.paidSource"];
                  if (paymentSource === "stripe") {
                    url = "https://dashboard.stripe.com/login";
                  } else if (paymentSource === "paypal") {
                    url = "https://www.paypal.com/us/signin";
                  } else if (paymentSource === "quickbooks") {
                    url =
                      "https://merchantcenter.intuit.com/msc/portal/login?service=https%3A%2F%2Fmerchantcenter.intuit.com%2Fmsc%2Fportal%2Fhome";
                  }

                  if (url) {
                    const win = window.open(url, "_blank");
                    win.focus();
                  }
                }}
              >
                {capitalize(params.data["payments.paidSource"])}
              </span>
            </ContextMenuTrigger>
          );
        }

        return (
          <Col className={"p-0"}>
            <PaidSourcePicker
              refreshTable={refreshTable}
              placeholder={"Select payment source"}
              paymentId={params.data["payments.id"]}
            />
          </Col>
        );
      },
    },
    {
      headerName: "Company name",
      field: "owner.companyNameForSort",
      sortable: true,
      unSortIcon: true,
      minWidth: 250,
      maxWidth: 600,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data["owner.companyName"]}
            collect={collectDataForCopy}
            id="contextMenuInvoices"
          >
            <div
              style={{
                overflow: "auto",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginTop: "5%" }}>
                {params.data["owner.companyName"]}
              </span>
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Account ID",
      field: "owner.id",
      sortable: true,
      unSortIcon: true,
      width: 205,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["owner.id"]}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data["owner.id"]}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "City",
      field: "owner.cityForSort",
      sortable: true,
      unSortIcon: true,
      width: 125,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["owner.city"]}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data["owner.city"]}</span>
        </ContextMenuTrigger>
      ),
    },
    {
      headerName: "State",
      field: "owner.stateForSort",
      sortable: true,
      unSortIcon: true,
      width: 125,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["owner.state"]}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data["owner.state"]}</span>
        </ContextMenuTrigger>
      ),
    },

    {
      headerName: "Listing ID",
      field: "listing.id",
      sortable: true,
      unSortIcon: true,
      width: 125,
      cellRendererFramework: (params) => (
        <ContextMenuTrigger
          data={params.data["listing.id"]}
          collect={collectDataForCopy}
          id="contextMenuInvoices"
        >
          <span>{params.data["listing.id"]}</span>
        </ContextMenuTrigger>
      ),
    },
  ];

  if (accountId || listingId) {
    columnsInvoices = columnsInvoices.filter(
      (e) => e.field !== "owner.companyNameForSort",
    );
  } else {
    columnsInvoices = columnsInvoices.filter((e) => e.field !== "selected");
  }

  return columnsInvoices;
};

export const defineInvoicesColumnsForPaymentLinkCheckBox = (callToAction) => {
  const formatMoney = (number) => {
    return number
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const listing = ["", "Premium", "Standard", "Basic Plus", "Basic"];
  let columnsInvoices = [
    {
      headerName: "",
      field: "selected",
      width: 50,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: (params) => {
        if (params.data.isSelected) params.node.setSelected(true);
        return params.data.statusForSort !== "Paid" ? true : false;
      },
    },

    {
      headerName: "Listings",
      field: "invoiceNumberForSort",
      width: 350,
      cellRendererFramework: (params) => (
        <div>
          <span style={{ lineHeight: "25px" }} className="d-block">
            {params.data["listing.name"]}
          </span>
          <span style={{ lineHeight: "25px" }} className="d-block">
            {params.data["listing.city"]}, {params.data["listing.state"]},
            {params.data["listing.address"]}
          </span>
        </div>
      ),
    },
    {
      headerName: "Type",
      field: "total",
      width: 100,
      cellRendererFramework: (params) => (
        <div className="d-flex w-100 h-100 align-items-baseline">
          <span style={{ lineHeight: "42px" }}>
            {listing[params.data["listing.priceId"]]}
          </span>
        </div>
      ),
    },
    {
      headerName: "Invoice number",
      field: "total",
      width: 200,
      cellRendererFramework: (params) => {
        console.log(params.data.invoiceNumber);
        return (
          <div>
            <span className="d-block" style={{ lineHeight: "25px" }}>
              {params.data.invoiceNumber}
            </span>
            <span
              className="d-flex align-items-center "
              style={{ lineHeight: "25px", color: "#00b5f4" }}
              // href="#"
              onClick={() => callToAction(params.data)}
            >
              <Eye size={20} />
              <span className={"ml-1"}> Preview invoice </span>
            </span>
          </div>
        );
      },
    },
    {
      headerName: "Due date",
      field: "dueAt",
      minWidth: 100,
      cellRendererFramework: (params) => {
        return (
          <div className="d-flex w-100 h-100 align-items-baseline">
            <span style={{ marginTop: 10 }}>
              <TableDate date={params.data.dueAt} />
            </span>
          </div>
        );
      },
    },
    {
      headerName: "Amount",
      field: "total",
      width: 90,
      cellRendererFramework: (params) => (
        <div className="d-flex w-100 h-100 align-items-baseline">
          <span style={{ lineHeight: "42px" }}>
            ${formatMoney(params.data["payments.total"] || params.data.total)}
          </span>
        </div>
      ),
    },
  ];

  return columnsInvoices;
};
