import React from "react";
import { Row, Col, Button, FormGroup } from "reactstrap";

import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import Geocode from "react-geocode";
// import mapStyle from "./fts-map-style";

import appConfig from "../../../configs/appConfig";

import FormikInput from "./formik-input";
import { handleError } from "../../../handlers/error";

Geocode.setApiKey(appConfig.googleApiKey);

Geocode.setLanguage("en");

const style = {
  width: "100%",
  height: "100%",
};

const containerStyle = {
  position: "relative",
  marginLeft: "1rem",
  marginRight: "1rem",
  width: "100%",
  height: "70vh",
};

export class MapContainer extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.editMode !== nextProps.editMode ||
      this.props.keys !== nextProps.keys ||
      (this.props.values &&
        nextProps.values &&
        this.props.values.geoLocation &&
        (this.props.values.geoLocation?.lat !==
          nextProps.values.geoLocation?.lat ||
          this.props.values.geoLocation?.lng !==
            nextProps.values.geoLocation?.lng)) ||
      (this.props.values.addressInfo &&
        this.props.values.addressInfo?.address !==
          nextProps.values.addressInfo?.address &&
        this.props.values.addressInfo?.city !==
          nextProps.values.addressInfo?.city) ||
      this.props.mapPinSet !== nextProps?.mapPinSet ||
      this.props.mapPinNotSetError !== nextProps?.mapPinNotSetError
    );
  }

  componentDidUpdate(prevProps) {
    // if (
    //   (this.props.values.geoLocation.lat !== prevProps.values.geoLocation.lat &&
    //     this.props.values.geoLocation.lng ===
    //       prevProps.values.geoLocation.lng) ||
    //   (this.props.values.geoLocation.lat === prevProps.values.geoLocation.lat &&
    //     this.props.values.geoLocation.lng !== prevProps.values.geoLocation.lng)
    // ) {
    //   const lat = this.props.values?.geoLocation?.lat ?? "0";
    //   const lng = this.props.values?.geoLocation?.lng ?? "0";
    //   this.fetchAddress(lat, lng);
    // }

    if (
      this.props.values.addressInfo?.address !==
        prevProps.values.addressInfo?.address &&
      this.props.values.addressInfo?.city !==
        prevProps.values.addressInfo?.city &&
      this.props.values?.name !== prevProps.values?.name &&
      this.props.values?.zip !== prevProps.values?.zip
    ) {
      this.fetchPosition();
    }
  }

  fetchPosition = () => {
    const address = this.props.values?.addressInfo?.address ?? "";
    const city = this.props.values?.addressInfo?.city ?? "";
    const state = this.props.values?.addressInfo?.state ?? "";
    const zip = this.props.values?.addressInfo?.zip ?? "";
    const concatenated = city + " " + address + " " + state + " " + zip;
    const inputString =
      !concatenated || concatenated === "undefined" ? "Chicago" : concatenated;

    Geocode.fromAddress(inputString)
      .then((response) => {
        this.setLatLng(response.results[0].geometry.location);
        this.props.handleMapPinError(null);
        this.props.handleMapPinSet(true);
      })
      .catch(handleError);
  };

  fetchAddress = (lat, lng) => {
    Geocode.fromLatLng(lat, lng)
      .then((response) => {
        this.setAddressInfo(response.results[0]);
      })
      .catch(handleError);
  };

  setAddressInfo = (result) => {
    const addressInfo = result?.formatted_address?.split(",");

    const address = addressInfo[0]?.trim();
    const city = addressInfo[1]?.trim();

    const stateInfo = addressInfo[2]?.trim().split(" ");

    const state = stateInfo[0]?.trim();
    const zip = stateInfo[1]?.trim();

    const newAddressInfo = { address, city, state, zip };

    this.props.setFieldValue("addressInfo", newAddressInfo, false);
  };

  setLatLng = (position) => {
    this.props.setFieldValue("geoLocation", { ...position }, false);
  };

  moveMarker = (_, marker) => {
    const lat = marker.position.lat();
    const lng = marker.position.lng();

    this.setLatLng({ lat, lng });
    // this.fetchAddress(lat, lng);
  };

  render() {
    const {
      editMode,
      mapPinSet,
      createListing,
      mapPinNotSetError,
      values = {},
      // disableMapScroll,
    } = this.props;

    const { geoLocation = {} } = values;
    const { lat = 0, lng = 0 } = geoLocation;

    const center = {
      lat: isNaN(lat) ? 0 : lat,
      lng: isNaN(lng) ? 0 : lng,
    };

    const initialCenter = { ...center };
    const position = { ...center };

    const _mapLoaded = (mapProps, map) => {
      map.setOptions({
        // styles: mapStyle,
        clickableIcons: false,
      });
    };

    return (
      <React.Fragment>
        <Row>
          <Col sm="6" lg="6" xl="4">
            <span
              className={`${
                this.props.keys &&
                this.props.keys.includes("latitude") &&
                "field-changed"
              }`}
            >
              <FormikInput
                disabled={!editMode}
                name="geoLocation.lat"
                label="Latitude"
                labelId="lat"
                placeholder="Enter latitude"
              />
            </span>
          </Col>
          <Col sm="6" lg="6" xl="4">
            <span
              className={`${
                this.props.keys &&
                this.props.keys.includes("longitude") &&
                "field-changed"
              }`}
            >
              <FormikInput
                disabled={!editMode}
                name="geoLocation.lng"
                label="Longitude"
                labelId="lng"
                placeholder="Enter longitude"
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm="6" lg="4" xl="4">
            <FormGroup>
              {editMode && (
                <Button
                  className="convert-address-btn"
                  disabled={!editMode}
                  color="secondary"
                  type="button"
                  onClick={this.fetchPosition}
                >
                  Convert Address to Map
                </Button>
              )}
            </FormGroup>
          </Col>
          {mapPinNotSetError && (
            <Col sm="6" lg="6" xl="6">
              <div
                style={{
                  color: "#f20933",
                  display: "flex",
                  alignItems: "center",
                  height: "48px",
                  marginTop: "25.62px",
                }}
                id="mapPinError"
              >
                Please click "Convert Address to Map" and move pin if needed.
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {editMode && (
            <div className="map-title pl-1">
              VERIFY LOCATION ON MAP (DRAG PIN ON MAP TO CORRECT)
            </div>
          )}
          <Map
            google={this.props.google}
            zoom={14}
            {...{ style, containerStyle, initialCenter, center }}
            onClick={this.onMapClick}
            scrollwheel={false}
            onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
          >
            {!createListing && (
              <Marker
                draggable={editMode}
                name={"Current location"}
                onClick={this.onMarkerClick}
                onDragend={this.moveMarker.bind(this)}
                {...{ position }}
              />
            )}
            {createListing && mapPinSet && (
              <Marker
                draggable={editMode}
                name={"Current location"}
                onClick={this.onMarkerClick}
                onDragend={this.moveMarker.bind(this)}
                {...{ position }}
              />
            )}

            <InfoWindow onClose={null}>
              <p>test</p>
            </InfoWindow>
          </Map>
        </Row>
      </React.Fragment>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: appConfig.googleApiKey,
})(MapContainer);
