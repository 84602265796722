import React, { Component } from "react";
import FTSAgGridDataTable from "../../../components/fts/fts-ag-grid-data-table/FTSAgGridDataTable";
import { connect } from "react-redux";
import { defineInvoicesColumns } from "../invoices-page/invoices-columns";
import {
  searchAccountInvoices,
  updateAccountInvoicesActiveSubTab,
  updateAccountInvoicesCurrentPage,
  updateAccountInvoicesSearchInput,
  updateAccountInvoicesResultsPerPage,
  updateAccountInvoicesSortModel,
  setAccountInvoicesLastViewedAccount,
} from "../../../redux/actions/accountInvoices";
import "../../../index.scss";
import { INVOICES_SEARCH_FIELDS as searchFields } from "../../../utility/constants";

class AccountInvoices extends Component {
  state = {
    columnDefs: [],
  };
  async componentDidMount() {
    const {
      searchAccountInvoices,
      accountInvoices,
      accountId,
      invoiceToOpen,
      setAccountInvoicesLastViewedAccount,
    } = this.props;

    const {
      lastViewedAccount,
      searchString,
      resultsPerPage,
      sortModel,
      activeSubTab,
      currentPage,
    } = accountInvoices;

    let payload = {
      page: currentPage,
      resultsPerPage,
      searchString,
      searchFields,
      sort: sortModel,
      filter: [
        {
          term: { userId: accountId },
        },
      ],
    };

    if (lastViewedAccount !== accountId) {
      setAccountInvoicesLastViewedAccount({
        id: accountId,
        searchString: invoiceToOpen ? searchString : "",
      });

      payload = {
        page: 1,
        resultsPerPage: 100,
        searchString: invoiceToOpen ? searchString : "",
        searchFields,
        sort: [],
        filter: [
          {
            term: { userId: accountId },
          },
        ],
      };
    }

    if (lastViewedAccount === accountId && activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    searchAccountInvoices(payload).then(() => {
      this.setState({
        columnDefs: defineInvoicesColumns(
          this.props.collectDataForCopy,
          this.props.accountId,
          null,
          this.props.refreshTable,
          this.props.invoiceToOpen,
          this.props.togglePopOver,
        ),
      });
    });
  }

  onSubTabChange = (subTab) => {
    const {
      searchAccountInvoices,
      updateAccountInvoicesActiveSubTab,
      accountInvoices,
    } = this.props;

    const { resultsPerPage, searchString, sortModel } = accountInvoices;

    let payload = {
      page: 1,
      resultsPerPage,
      searchString,
      searchFields,
      sort: sortModel,
      filter: [
        {
          term: { userId: this.props.accountId },
        },
      ],
    };

    if (subTab?.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: subTab?.elasticKey } }],
          },
        },
      ];
    }
    updateAccountInvoicesActiveSubTab({ subTab });
    searchAccountInvoices(payload);
  };

  handleSearchInput = (searchString) => {
    const {
      searchAccountInvoices,
      updateAccountInvoicesSearchInput,
      accountInvoices,
    } = this.props;

    const { resultsPerPage, activeSubTab, sortModel } = accountInvoices;

    let payload = {
      page: 1,
      resultsPerPage,
      searchString,
      searchFields,
      sort: sortModel,
      filter: [
        {
          term: { userId: this.props.accountId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    updateAccountInvoicesSearchInput({ searchString });
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      searchAccountInvoices(payload);
    }, 300);
  };

  onPageChange = (page) => {
    const {
      searchAccountInvoices,
      updateAccountInvoicesCurrentPage,
      accountInvoices,
    } = this.props;

    const { searchString, resultsPerPage, activeSubTab, sortModel } =
      accountInvoices;

    let payload = {
      page,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { userId: this.props.accountId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    updateAccountInvoicesCurrentPage({ page });
    searchAccountInvoices(payload);
  };

  handleFilterSize = (resultsPerPage) => {
    const {
      searchAccountInvoices,
      accountInvoices,
      updateAccountInvoicesCurrentPage,
      updateAccountInvoicesResultsPerPage,
      accountId,
    } = this.props;

    const { searchString, activeSubTab, sortModel } = accountInvoices;

    let payload = {
      page: 1,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { userId: accountId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    updateAccountInvoicesResultsPerPage({ resultsPerPage });
    updateAccountInvoicesCurrentPage({ page: 1 });
    searchAccountInvoices(payload);
  };

  onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    const {
      updateAccountInvoicesSortModel,
      searchAccountInvoices,
      accountInvoices,
      accountId,
    } = this.props;

    const { currentPage, resultsPerPage, searchString, activeSubTab } =
      accountInvoices;

    updateAccountInvoicesSortModel({ sortModel });

    let payload = {
      page: currentPage,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { userId: accountId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    searchAccountInvoices(payload);
  };

  handleRowClasses = (params) => {
    return `row-${params?.data?.statusForSort?.toLowerCase()}`;
  };

  getNoRowsMessage = () => {
    const { accountInvoices } = this.props;
    let message = "No invoices found";

    if (accountInvoices.searchString) message += " for this specific search";

    return message;
  };

  render() {
    const {
      onGridReadyInit,
      onRowClicked,
      accountInvoices,
      getSelectedInvoices,
      handlePaymentSidebar,
      handleCancelAction,
      numberOfInvoicesSelected,
      selectedRows,
    } = this.props;

    const {
      invoicesData,
      pageCount,
      resultsPerPage,
      subTabs,
      activeSubTab,
      currentPage,
      searchString,
      sortModel,
      totalInvoiceAmount,
      inProgress,
    } = accountInvoices;

    const { columnDefs } = this.state;

    return (
      <FTSAgGridDataTable
        rowData={invoicesData}
        columnDefs={columnDefs}
        isMultiTab
        subTabs={subTabs}
        tableHeader={`$${totalInvoiceAmount.toFixed(2)}`}
        tableSubHeader="Total Amount"
        onSortChanged={this.onSortChanged}
        onPageChange={this.onPageChange}
        onSubTabChange={this.onSubTabChange}
        pageCount={pageCount}
        currentPageSize={resultsPerPage}
        activeSubTabId={activeSubTab.id}
        handleSearchInput={this.handleSearchInput}
        onRowClicked={onRowClicked}
        handleFilterSize={this.handleFilterSize}
        currentPage={currentPage}
        searchValue={searchString}
        defaultSortModel={sortModel}
        getRowClass={this.handleRowClasses}
        onGridReadyInit={onGridReadyInit}
        onSelectionChanged={getSelectedInvoices}
        showActionSection={!!numberOfInvoicesSelected}
        submitButtonLabel={"Send/Collect payment"}
        tableActionSectionText={`${numberOfInvoicesSelected} selected invoices`}
        handleSubmitAction={handlePaymentSidebar}
        handleCancelAction={handleCancelAction}
        rowSelection={"multiple"}
        inProgress={inProgress}
        selectedRows={selectedRows}
        noRowsMessage={this.getNoRowsMessage()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountInvoices: state.accountInvoices,
  };
};

export default connect(mapStateToProps, {
  searchAccountInvoices,
  updateAccountInvoicesActiveSubTab,
  updateAccountInvoicesSearchInput,
  updateAccountInvoicesCurrentPage,
  updateAccountInvoicesResultsPerPage,
  updateAccountInvoicesSortModel,
  setAccountInvoicesLastViewedAccount,
})(AccountInvoices);
