import React, { Component, createRef } from "react";
import { Row, Col, Button, CustomInput, Card } from "reactstrap";
import Icon from "../../../components/fts/Icons";
import { connect } from "react-redux";
import "../../../assets/scss/pages/data-list.scss";
import "../../../assets/scss/pages/promocodes.scss";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import ConfirmationModal from "../../../components/fts/confirmation-modal";

import { definePromoCodesColumns } from "./promocodes-columns";
import {
  getPromoCodes,
  setPromoCodesList,
} from "../../../redux/actions/promocodes";

class Promocodes extends Component {
  state = {
    sidebar: false,
    totalRecords: this.props.totalRecords,
    columnDefs: definePromoCodesColumns(this.collectDataForCopy),
    defaultColDef: {
      sortable: true,
    },
    pageSize: 10,
    page: 1,
    pageCount: 0,
    status: 1,
  };

  formatNumber = (number) => {
    return Number(number.toFixed(2))
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  formikSidebarRef = createRef();

  setFormikSidebarRef = (ref) => {
    this.formikSidebarRef = ref;
  };

  resetFormikForm = () => {
    this.formikSidebarRef.resetForm();
  };

  closeSideOutAction = () => {
    this.setState({ sidebar: false, update: false, isOpen: false });
  };

  callToAction = (action, update, item) => {
    if (!action) return;
    this.setState({
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
    });
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  pageSizes = [10, 25, 50, 100];

  componentDidMount() {
    const { page, pageSize, status } = this.state;
    this.props
      .getPromoCodes(page, pageSize, status)
      .then((response) => {
        this.props.setPromoCodesList({
          rows: response.data.rows,
          totalRecordsPromoCodes: response.data.count,
        });

        this.setState({
          pageCount: Math.ceil(response.data.count / this.state.pageSize),
          totalRecords: response.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getPromoCodes = () => {
    const { page, pageSize, status } = this.state;
    this.props
      .getPromoCodes(page, pageSize, status)
      .then((response) => {
        this.props.setPromoCodesList({
          rows: response.data.rows,
          totalRecordsPromoCodes: response.data.count,
        });

        this.setState({
          pageCount: Math.ceil(response.data.count / this.state.pageSize),
          totalRecords: response.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (bool === false && this.formikSidebarRef?.dirty && !closeWithoutPrompt) {
      return this.setState({ isOpen: true });
    } else {
      this.setState({ sidebar: bool, updating: !!update });
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApiPromoCodes = params.columnApi;

    if (localStorage.getItem("promoCodesColumnState") !== null) {
      this.gridColumnApiPromoCodes.setColumnState(
        JSON.parse(localStorage.getItem("promoCodesColumnState")),
      );
    }
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  saveState = () => {
    let promoCodesColumnState = this.gridColumnApiPromoCodes.getColumnState();
    localStorage.setItem(
      "promoCodesColumnState",
      JSON.stringify(promoCodesColumnState),
    );
  };

  filterSize = (type, val) => {
    this.setState(
      {
        pageSize: val,
      },
      () => {
        this.props
          .getPromoCodes(this.state.page, this.state.pageSize)
          .then((response) => {
            this.props.setPromoCodesList({
              rows: response.data.rows,
              totalRecordsPromoCodes: response.data.count,
            });

            this.setState(
              {
                pageCount: Math.ceil(response.data.count / this.state.pageSize),
                totalRecords: response.data.count,
              },
              () => {
                console.log(this.state.pageCount);
              },
            );
          })
          .catch((err) => {
            console.log(err);
          });
      },
    );
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;

    this.setState({ page: pageNum }, () => {
      this.props
        .getPromoCodes(this.state.page, this.state.pageSize)
        .then((response) => {
          this.props.setPromoCodesList({
            rows: response.data.rows,
            totalRecordsPromoCodes: response.data.count,
          });

          this.setState({
            pageCount: Math.ceil(response.data.count / this.state.pageSize),
            totalRecords: response.data.count,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  resetApi = () => {
    this.gridApi = null;
  };

  onRowClicked = (e) => {
    this.callToAction("promocode", true, e.data);
  };

  onPromoCodeCreate = () => {
    this.getPromoCodes();
  };

  onPromoCodeUpdate = () => {
    this.getPromoCodes();
  };

  handleExcludeInactive = () => {
    this.setState(
      {
        status: this.state.status === 1 ? 0 : 1,
      },
      () => {
        this.getPromoCodes();
      },
    );
  };

  render() {
    const {
      sidebar,
      action,
      updating,
      actionItem,
      defaultColDef,
      columnDefs,
      pageSize,
      page,
      pageCount,
      totalRecords,
      isOpen,
    } = this.state;

    return (
      <div className="promocodes-page">
        <div className="d-flex w-100 align-center justify-content-between">
          <h1>Promo codes</h1>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center mr-2">
              <CustomInput
                checked={this.state.status ? 1 : 0}
                type="switch"
                id="includeInAuthorizedPromotions"
                name="includeInAuthorizedPromotions"
                className="updates-switch"
                inline
                onChange={this.handleExcludeInactive}
              />
              <span className="header-switch-text">Active/Inactive</span>
            </div>
            <Button
              onClick={() => {
                this.callToAction("promocode", false, null);
              }}
              color="primary"
              className="card-button"
            >
              <span className="d-flex align-center justify-content-between">
                <Icon name="Plus" size="24" color={"greyscale-0"} />
                <div className="ml-1">Create new Coupon</div>
              </span>
            </Button>
          </div>
        </div>
        <Row>
          <Col sm="12" className="mt-1">
            <Card className={"p-2"}>
              <FTSDataTable
                isSearch={true}
                pageSizes={this.pageSizes}
                totalRecords={totalRecords}
                currentPageSize={pageSize}
                pageSizePropName={"pageSize"}
                currentPage={page}
                pageCount={pageCount}
                rowData={this.props.promoCodesData}
                onGridReadyInit={this.onGridReady}
                saveState={this.saveState}
                filterSize={this.filterSize}
                onPageChange={this.onPageChanged}
                resetApi={this.resetApi}
                onRowClicked={this.onRowClicked}
                columnDefs={columnDefs}
                onSortChanged={this.onSortChanged}
                tableName="promoCodes"
                promoCodesTab
                {...{
                  defaultColDef,
                }}
              />
            </Card>
          </Col>
        </Row>

        <ActionSidebar
          show={sidebar}
          action={action}
          updating={updating}
          noMarker={true}
          handleSidebar={this.handleSidebar}
          setFormikSidebarRef={this.setFormikSidebarRef}
          resetFormikForm={this.resetFormikForm}
          onPromoCodeCreate={this.onPromoCodeCreate}
          onPromoCodeUpdate={this.onPromoCodeUpdate}
          actionItem={actionItem}
        />
        <ConfirmationModal
          title="Unsaved Changes"
          message="Are you sure you want to proceed with this action?"
          closeModal={this.toggleModal}
          confirmModal={this.closeSideOutAction}
          {...{ isOpen }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    totalRecords: state.promoCodes.totalRecordsPromoCodes,
    promoCodesData: state.promoCodes.promoCodesData,
  };
};

export default connect(mapStateToProps, { getPromoCodes, setPromoCodesList })(
  Promocodes,
);
