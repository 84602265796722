import React, { PureComponent, createRef } from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import Flatpickr from "react-flatpickr";

import { fetchActivities } from "../../../redux/actions/activities";
import { connect } from "react-redux";
import moment from "moment";
import Icon from "../../../components/fts/Icons";
import TitleRow from "../../../components/fts/TitleRow";
import InfiniteScroll from "react-infinite-scroll-component";
import ActivityTypePicker from "../../../components/fts/activity-type-picker";
import AdminPicker from "../../../components/fts/admin-picker";
import ActivityItem from "../../../components/fts/activity-item";
import "../../../assets/scss/components/fts/date-range.scss";

class ActivityHistory extends PureComponent {
  state = {
    inProgress: false,
    markerTitle: "Today",
    activities: null,
    activitiesLength: 0,
    lastActivity: null,
    startAt: null,
    endAt: null,
    adminUserId: null,
    activityType: null,
    tableLoading: false,
  };

  dateRef = createRef();

  componentDidMount() {
    this.setState({ inProgress: true, tableLoading: true });
    (async () => {
      try {
        await this.fetchActivities();
      } catch (e) {
        console.log(e);
      }
    })();
  }

  clearFlatPickr = () => {
    this.dateRef.current.flatpickr.clear();
    this.setState(
      {
        startAt: null,
        endAt: null,
        activities: [],
        activitiesLength: 0,
        lastActivity: null,
        tableLoading: true,
      },
      () => {
        this.fetchActivities();
      }
    );
  };

  handleDate = (values) => {
    this.setState(
      {
        activities: [],
        activitiesLength: 0,
        lastActivity: null,
        startAt: values[0],
        endAt: values[1],
        tableLoading: true,
      },
      () => {
        this.fetchActivities();
      }
    );
  };

  fetchActivities = async () => {
    const { fetchActivities, listingId, accountId } = this.props;
    const {
      activitiesLength,
      lastActivity,
      startAt,
      endAt,
      activityType,
      adminUserId,
      activities,
    } = this.state;

    let payload = {};

    if (listingId) payload.adId = listingId;
    if (accountId) payload.userId = accountId;
    if (activitiesLength && lastActivity) {
      payload.createdAt = lastActivity.createdAt;
    }

    payload.activityType = activityType;
    if (adminUserId === "system") {
      payload.isSystem = true;
    } else {
      payload.adminUserId = adminUserId;
    }

    if (startAt && endAt) {
      payload.startAt = moment(startAt).toISOString();
      payload.endAt = moment(endAt).add(1, "days").toISOString();
      delete payload.createdAt;
    }

    let { data } = await fetchActivities(payload);
    if (
      !data.length &&
      (payload.activityType ||
        (payload.startAt && payload.endAt) ||
        payload.adminUserId)
      // !this.state.activities
    ) {
      return this.setState({
        hasMore: false,
        inProgress: false,
        tableLoading: false,
        activities: [],
      });
    }

    if (!data.length) {
      return this.setState({
        hasMore: false,
        inProgress: false,
        tableLoading: false,
        // activities: [],
      });
    }

    let newData = {
      Today: [],
      Yesterday: [],
      Older: [],
    };

    if (activities) {
      newData = {
        Today: [...(activities?.Today || [])],
        Yesterday: [...(activities?.Yesterday || [])],
        Older: [...(activities?.Older || [])],
      };
    }

    let lastActivityObj = data.pop();
    data.push(lastActivityObj);
    for (let activity of data) {
      if (!moment().diff(moment(activity.createdAt.split("T")[0]), "days")) {
        newData.Today.push(activity);
        continue;
      }

      if (
        moment().diff(moment(activity.createdAt.split("T")[0]), "days") === 1
      ) {
        newData.Yesterday.push(activity);
        continue;
      }

      newData.Older.push(activity);
    }

    this.setState({
      lastActivity: lastActivityObj,
      activities: newData,
      inProgress: false,
      tableLoading: false,
      activitiesLength: this.state.activitiesLength + data.length,
      hasMore: startAt && endAt ? false : data.length === 20, // 20 is limit defined on api
    });
  };

  render() {
    const { activities, inProgress, activitiesLength, hasMore, tableLoading } =
      this.state;
    const {
      accountId,
      listingId,
      globalActivityPage,
      callToAction,
      setActivityPreloadDate,
    } = this.props;

    // if (!inProgress && !activitiesLength)
    //   return (
    //     <Card>
    //       <CardBody>
    //         <div className="d-flex flex-column justify-content-between pl-1">
    //           <TitleRow title={"Activity History"} className={"mb-3"} />
    //           <div className="d-flex justify-content-center aligin-items-center w-100">
    //             <p style={{ color: "#7d8c91" }}>No activities yet</p>
    //           </div>
    //         </div>
    //       </CardBody>
    //     </Card>
    //   );
    if (inProgress || !activities)
      return (
        <Card>
          <CardBody style={{ minHeight: "75vh" }}>
            <div className="d-flex justify-content-between ml-1">
              <TitleRow title={"Activity History"} className={"mb-5"} />
              {inProgress && <Spinner size="sm" />}
            </div>
            {!activities && !inProgress && (
              <div className="d-flex flex-column align-items-center">
                <Icon
                  name="EmptySearch"
                  style={{ marginBottom: "1rem" }}
                  size={182}
                />
                <p className="empty-text">No results found.</p>
                <p className="empty-small-text">
                  We can’t find any item matching your search.
                </p>
              </div>
            )}
          </CardBody>
        </Card>
      );

    return (
      <>
        <Card style={{ height: "92vh" }}>
          <div
            className="d-flex justify-content-between"
            style={{ padding: "2.5rem 0 0 2.5rem" }}
          >
            <TitleRow title={"Activity History"} />
            <div className="d-flex ">
              <div
                className="fts-date-and-time"
                style={{ paddingRight: 20, width: 270 }}
              >
                <div className="range-picker">
                  <div className="range">
                    <div className="position-relative has-icon-right">
                      <div className="fts-date-and-time">
                        <div
                          id="datepickerWrapperFrom"
                          className="u-datepicker input-group"
                        >
                          <Flatpickr
                            ref={this.dateRef}
                            placeholder="Select date range"
                            className="fts-date-picker form-control date-picker"
                            options={{
                              mode: "range",
                              dateFormat: "M d, Y",
                              locale: {
                                rangeSeparator: " - ",
                              },
                            }}
                            onChange={(values) => {
                              if (values.length === 2) {
                                this.handleDate(values);
                              }
                            }}
                          />
                        </div>
                      </div>

                      {this.state.startAt ? (
                        <div
                          className="form-control-position clear-calendar-button"
                          onClick={() => {
                            if (this.dateRef?.current?.flatpickr) {
                              this.clearFlatPickr();
                            }
                          }}
                        >
                          <Icon name="ClearCalendar" size={16} />
                        </div>
                      ) : (
                        <div className="form-control-position">
                          <Icon name="Calendar" size={17} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!accountId && !listingId && (
                <div style={{ marginRight: 20, width: 240 }}>
                  <AdminPicker
                    name={"test"}
                    placeholder={"Select user"}
                    haveSystem
                    onChange={(option) => {
                      this.setState(
                        {
                          adminUserId: option ? option.value : null,
                          tableLoading: true,
                        },
                        () => {
                          this.setState(
                            {
                              activities: [],
                              lastActivity: null,
                            },
                            () => this.fetchActivities()
                          );
                        }
                      );
                    }}
                  />
                </div>
              )}

              <div style={{ marginRight: 30, width: 300 }}>
                <ActivityTypePicker
                  name={"test"}
                  placeholder={"Select type"}
                  onChange={(option) => {
                    this.setState(
                      { activityType: option ? option.value : null },
                      () => {
                        this.setState(
                          {
                            activities: [],
                            // activitiesLength: 0,
                            lastActivity: null,
                            tableLoading: true,
                          },
                          () => this.fetchActivities()
                        );
                      }
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <CardBody
            id={"scrollableDiv"}
            style={{
              height: "60vh",
              overflow: "auto",
              margin: "1.5rem",
              padding: "0 1rem",
            }}
          >
            {tableLoading ? (
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <Spinner size={"lg"} />
              </div>
            ) : activities.length === 0 ? (
              <div className="d-flex flex-column align-items-center mt-2">
                <Icon
                  name="EmptySearch"
                  style={{ marginBottom: "1rem" }}
                  size={182}
                />
                <p className="empty-text">No results found.</p>
                <p className="empty-small-text">
                  We can’t find any item matching your search.
                </p>
              </div>
            ) : (
              <InfiniteScroll
                style={{ display: "contents" }}
                dataLength={activitiesLength}
                next={async () => {
                  await this.fetchActivities();
                }}
                hasMore={hasMore}
                loader={
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "2rem",
                    }}
                  >
                    <span className={"mr-1"}>
                      <Spinner size="sm" />
                    </span>
                  </div>
                }
                scrollableTarget={"scrollableDiv"}
              >
                {Object.keys(activities).map((e, index) => {
                  if (!activities[e].length) return null;
                  return (
                    <div key={index}>
                      <TitleRow
                        title={e}
                        noMarker
                        className={index === 0 ? "mb-2" : "mt-2 mb-2"}
                      />
                      {activities[e].map((activity, i) => (
                        <ActivityItem
                          key={i + 100}
                          activity={activity}
                          callToAction={callToAction}
                          markerTitle={e}
                          accountId={this.props.accountId}
                          listingId={this.props.listingId}
                          globalActivityPage={globalActivityPage}
                          setActivityPreloadDate={setActivityPreloadDate}
                        />
                      ))}
                    </div>
                  );
                })}
              </InfiniteScroll>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activities: state.activities,
  };
};

const mapDispatchToProps = {
  fetchActivities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityHistory);
