import React, { PureComponent } from "react";
import { history } from "../../../../history";
import { connect } from "react-redux";

import "../../../../assets/scss/components/fts/todo-dashboard-section.scss";
import Icon from "../../../../components/fts/Icons";
import { setActiveTab } from "../../../../redux/actions/sales";

class EmptyTodoSection extends PureComponent {
  render() {
    return (
      <div className="empty-todo-section-wrapper">
        <span className="empty-icon">
          <span className="empty-icon-inner">
            <Icon name="EmptyTodos" size="48" />
          </span>
        </span>
        <div className="messages-section">
          <h3 className="title">You have no Todo’s.</h3>
          <span className="message">
            Once created, Todo’s will appear here. In the meantime,
          </span>
          <span
            className="leads-pool-link"
            onClick={() => {
              this.props.setActiveTab(2);
              history.push("/sales");
            }}
          >
            Jump into the Leads Pool!
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.sales.activeTab,
  };
};

const mapDispatchToProps = {
  setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyTodoSection);
