export const checkPermissions = (routePermissions, userPermissions) => {
  let authorized = true;
  if (routePermissions.length === 0) {
    return authorized;
  }

  if (!userPermissions || !userPermissions.length) return authorized;
  const routePermission = routePermissions[0];
  const userPermission = userPermissions.find(
    (itm) => itm.id === routePermission.id
  );

  if (userPermission) {
    if (routePermission.subCat.length === 0 && !userPermission.active) {
      authorized = false;
    } else {
      authorized = routePermission.subCat.some((r) =>
        userPermission.subCat.includes(r)
      );
    }
  }

  return authorized;
};
