import React from "react";
import { Row, Col, Button, Spinner } from "reactstrap";

import FTSModal from "../fts-modal";
import FileList from "../files/FileList";
import FTSFileUploader from "../files/file-uploader";
import {
  accountFileTypes,
  listingPromotionFileTypes,
} from "../../../data/supported-file-types";

import Checkbox from "../Checkbox";

function getItemID(slugFragment, data) {
  const itemIDMap = {
    listings: (data) => data?.listingId ?? data?.adId,
    users: (data) => data?.ownerId,
    todo: (data) => data?.toDoId,
    note: (data) => data?.noteId,
  };

  return itemIDMap[slugFragment] ? itemIDMap[slugFragment](data) : null;
}

function getUploadSlug(key) {
  const uploadSlugMap = {
    user: "users",
    listings: "listing",
    todos: "todo",
    notes: "note",
  };

  return uploadSlugMap[key] ?? "users";
}

const FileModal = ({
  method,
  fileID,
  isListing,
  activeTab,
  accountId,
  listingId,
  modalOpen,
  currentRow,
  filesAdded,
  uploadStarted,
  uploadInProgress,
  currentSlugFragment,
  closeModal,
  updateFile,
  onFilesAdded,
  onUploadComplete,
  getResponseError,
  onRemoveFile,
  onRef,
  uploadingPercent,
  toggleNotifyUser,
  notifyUser,
  ...rest
}) => {
  let itemID = getItemID(currentSlugFragment, currentRow?.data);
  const updating = method === "put";
  if (!updating) {
    itemID = accountId;

    if (isListing) {
      itemID = listingId;
    }
  }

  if (updating && isListing) {
    itemID = listingId;
  }

  const endpoint = updating
    ? `admin/${currentSlugFragment}/upload/${itemID}/${fileID}`
    : `admin/${getUploadSlug(activeTab)}/upload/${itemID}`;

  const maxNumOfFiles = updating ? 1 : 50;

  let allowedFileTypes;

  if (accountId) {
    allowedFileTypes = accountFileTypes.map((type) => `.${type.value}`);
  }

  if (listingId) {
    allowedFileTypes = listingPromotionFileTypes.map(
      (type) => `.${type.value}`
    );
  }

  const notifyUserHandler = (e) => {
    toggleNotifyUser(e.target.checked);
    e.stopPropagation();
  };

  return (
    <FTSModal
      isOpen={modalOpen}
      closeModal={closeModal}
      title={updating ? "Update file" : "Upload file"}
      footerCTA={() => {
        return (
          <>
            <Button
              onClick={closeModal}
              color="flat-danger"
              disabled={uploadInProgress}
            >
              Cancel
            </Button>
            <Button
              onClick={updateFile}
              color="primary"
              disabled={uploadInProgress}
            >
              {uploadInProgress ? (
                <Row>
                  <Col>
                    <Spinner size="sm" color="white" />
                  </Col>
                </Row>
              ) : uploadStarted ? (
                "Retry"
              ) : updating ? (
                "Update"
              ) : (
                "Upload"
              )}
            </Button>
          </>
        );
      }}
    >
      <Row>
        <Col>
          <FTSFileUploader
            uploadingPercent={uploadingPercent}
            as="dragdrop"
            height="10rem"
            onFilesAdded={onFilesAdded}
            onUploadComplete={onUploadComplete}
            onRef={onRef}
            options={{
              restrictions: {
                maxNumberOfFiles: maxNumOfFiles,
                allowedFileTypes: allowedFileTypes,
              },
            }}
            xhrOptions={{
              getResponseError: getResponseError,
            }}
            statusBarProps={{
              hidePauseResumeButton: true,
              hideUploadButton: true,
              hideCancelButton: true,
            }}
            method={method}
            endpoint={rest.endpoint || endpoint}
            endpointIsDynamic
          />
        </Col>
      </Row>
      <Checkbox
        className="btn-check mt-1"
        label="Notify user"
        defaultChecked={notifyUser}
        onClick={notifyUserHandler}
      />
      <Row className="mt-1">
        <Col>
          <FileList list={filesAdded} onRemove={onRemoveFile} />
        </Col>
      </Row>
    </FTSModal>
  );
};

export default FileModal;
