import React, { Component } from "react";
import classnames from "classnames";

export default class SwitchFilter extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.listingTotal !== this.props.listingTotal ||
      nextProps.accountTotal !== this.props.accountTotal ||
      nextProps.searchType !== this.props.searchType
    );
  }

  render() {
    const { searchType, updateSearchType, listingTotal, accountTotal } =
      this.props;
    return (
      <div className="switch-filter">
        <div className="switch-filter__wrapper">
          <div>
            <button
              type="button"
              className={classnames({ active: searchType === "listing" })}
              onClick={() => {
                updateSearchType("listing");
              }}
            >
              Listing Results (
              {Number(listingTotal.toFixed(1)).toLocaleString()})
            </button>
          </div>
          <div>
            <button
              type="button"
              className={classnames({ active: searchType === "account" })}
              onClick={() => {
                updateSearchType("account");
              }}
            >
              Account Results (
              {Number(accountTotal.toFixed(1)).toLocaleString()})
            </button>
          </div>
        </div>
      </div>
    );
  }
}
