import styled from "styled-components";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Spacing } from "@find-truck-service/types/ui/spacing";
export const FtsCheckbox = styled(FtsWrapper) `
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${(props) => (props.checked ? ColorsValue["blue-accent-500"] : ColorsValue["greyscale-0"])};
  border-radius: ${Spacing["2xs"]};
  outline: ${Spacing["4xs"]} solid
    ${(props) => (props.checked ? ColorsValue["blue-accent-500"] : ColorsValue["greyscale-100"])};
  transition: all 150ms;
  text-align: center;
  svg {
    margin-bottom: 3px;
  }
`;
