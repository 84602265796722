import React, { Component } from "react";

import { Button, Spinner } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { FTSEmail } from "../../../components/fts/email";
import Checkbox from "../../../components/@vuexy/checkbox/CheckboxesVuexy";
import { Check } from "react-feather";
import { phoneNumberMask } from "../../../utility/masks";
import FormikMaskedInput from "../../../components/fts/formik/masked-input";
import { toast } from "react-toastify";
import { sendLinkMessage } from "../../../redux/actions/invoices";
import { connect } from "react-redux";
import Icon from "../Icons";
import { sendTemplate } from "../../../redux/actions/marketing";

import { activities } from "../../../constants";
import { createActivity } from "../../../redux/actions/activities";
import { addUserActivity } from "../../../redux/actions/user-activities";
import { sendProof } from "../../../redux/actions/proofs";
import { sendReport } from "../../../redux/actions/reports";

class SendPaymentLink extends Component {
  state = {
    activeTab: "email",
    errorMessage: "",
    inProgress: false,
  };

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  initialFormData = {
    addedEmail: "",
    email: "",
    listingEmail: "",
    additionalEmail: "",
    additionalPhoneNumber: "",
    mobilePhone: "",
    phoneNumber: "",
    phoneNumberSecondary: "",
    lisitngPhoneNumber: "",
    listingPhoneNumberSecondary: "",
    afterHours: "",
  };

  formSchema = Yup.object().shape({
    addedEmail: Yup.string().email("Invalid email"),
    email: Yup.string().email("Invalid email"),
    additionalEmail: Yup.string().email("Invalid email"),
  });

  updateActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
      errorMessage: "",
    });
  };

  handleLoading = (isLoading) => {
    this.setState({
      inProgress: isLoading,
    });
  };

  extractEmails = (text) => {
    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
  };

  checkDuplicatePhoneNumber = (value) => {
    const accountPhoneNumbers = [];
    const accountInfo = this.props.accountInfo;
    if (accountInfo?.mobilePhone) {
      accountPhoneNumbers.push(accountInfo.mobilePhone);
    }
    if (accountInfo?.primaryPhoneNumber) {
      accountPhoneNumbers.push(accountInfo.primaryPhoneNumber);
    }
    if (accountInfo?.phoneNumberSecondary) {
      accountPhoneNumbers.push(accountInfo.phoneNumberSecondary);
    }

    if (accountPhoneNumbers.includes(value)) {
      return true;
    }
    return false;
  };

  checkDuplicateEmail = (value) => {
    if (!this.extractEmails(value)?.length) {
      return true;
    }
    const accountEmails = [];
    const accountInfo = this.props.accountInfo;

    if (accountInfo?.email) {
      accountEmails.push(accountInfo.email);
    }
    if (accountInfo?.additionalEmail) {
      accountEmails.push(accountInfo.additionalEmail);
    }

    if (accountEmails.includes(value)) {
      return true;
    }
    return false;
  };

  checkSelectedValues = (values) => {
    if (
      !values.addedEmail &&
      !values.email &&
      !values.additionalEmail &&
      !values.listingEmail &&
      this.state.activeTab === "email"
    ) {
      this.setState({
        errorMessage: "Please provide or select an email address",
      });
      return false;
    } else if (
      !values.mobilePhone &&
      !values.phoneNumber &&
      !values.phoneNumberSecondary &&
      !values.additionalPhoneNumber &&
      !values.listingPhoneNumber &&
      !values.listingPhoneNumberSecondary &&
      !values.afterHours &&
      this.state.activeTab === "message"
    ) {
      this.setState({
        errorMessage: "Please provide or select an phone number",
      });
    } else {
      this.setState({
        errorMessage: "",
      });
      return true;
    }
  };

  handleSubmit = (values) => {
    if (this.state.inProgress) {
      return;
    }

    if (this.checkSelectedValues(values)) {
      if (this.props.sendProof) {
        this.props.sendProof({ activeTab: this.state.activeTab, values });
        return;
      }

      this.setState({
        inProgress: true,
      });

      let phoneNumbers = [
        values.mobilePhone,
        values.phoneNumber,
        values.phoneNumberSecondary,
        values.additionalPhoneNumber,
        values.listingPhoneNumber,
        values.listingPhoneNumberSecondary,
        values.afterHours,
      ].filter(Boolean);

      let emailsList = [
        values.addedEmail,
        values.additionalEmail,
        values.email,
        values.listingEmail,
      ].filter(Boolean);

      if (this.props.type === "report") {
        let payload = {
          type: !this.props.collectedDataForReportMenu?.adId
            ? "accountReport"
            : "listingReport",
          recipientName: this.props.accountInfo
            ? this.props.accountInfo?.firstName
            : this.props.listingData?.user?.firstName,
          userId: this.props.listingData
            ? this.props.listingData?.userId
            : this.props.accountInfo?.id,
          listings: this.props.listingData
            ? [this.props.listingData]
            : this.props.collectedDataForReportMenu?.relatedListings,
          reportId: this.props.collectedDataForReportMenu?.id,
          accountReportId: !this.props.collectedDataForReportMenu?.adId
            ? this.props.collectedDataForReportMenu?.id
            : null,
          listingReportId: this.props.collectedDataForReportMenu.adId
            ? this.props.collectedDataForReportMenu?.id
            : null,
          emailsList: this.state.activeTab === "email" && emailsList,
          phoneNumbersList: this.state.activeTab === "message" && phoneNumbers,
          includePaymentLink:
            this.props.collectedDataForReportMenu.includePaymentLink,
        };

        this.props
          .sendReport(payload)
          .then(() => {
            toast.success("Report successfully sent!", {
              position: toast.POSITION.TOP_RIGHT,
            });

            const emailsListString = emailsList.join(", ");
            const phoneNumbersString = phoneNumbers.join(", ");

            this.postReportActivity(
              emailsListString,
              phoneNumbersString,
              payload.reportId,
            );
          })
          .catch((err) => {
            console.log("ERROR", err);
            toast.error("Something went wrong!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .finally(() => {
            this.setState({
              inProgress: false,
            });
          });

        return;
      }

      if (this.state.activeTab === "message") {
        let payload = {
          type: this.props.type,
          phoneNumbersList: phoneNumbers,
          userId: this.props.accountInfo.id,
          sendBy:
            this.props.userData.loggedInUser.firstName +
            " " +
            this.props.userData.loggedInUser.lastName,
        };

        this.props
          .sendLinkMessage(payload)
          .then(() => {
            this.props.handleMenu();
            toast.success("Link sent succesfully!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });

            this.handleLinkActivity({ phoneNumbers });
            this.setState({
              inProgress: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (this.props.type === "send-proof") {
          let proofPayload = {
            userId: this.props.listingData.userId,
            adId: this.props.listingData.id,
            type: 2,
            status: 3,
            adStatus: this.props.listingData?.status,
            emailsList,
            sentBy: `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
            companyName:
              this.props.companyName || this.props.listingData?.name || "",
            city: this.props.listingData?.city || "",
            state: this.props.listingData?.state || "",
            adType: this.props.listingData?.adType?.toUpperCase() || "",
            firstName: this.props?.accountInfo?.firstName || "",
          };

          this.props
            .sendProofAction(proofPayload)
            .then(async (res) => {
              let activityPayload = {
                status: 1,
                userId: this.props.listingData.userId,
                adId: this.props.listingData.id,
                activityType: this.props.activitiesTypes.SENT_PROOF,
                proofId: res.data.id,
                activity: activities.sentProof
                  .replace(
                    "{{admin_name}}",
                    `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
                  )
                  .replace("{{proofRecievers}}", proofPayload.emailsList),
                adminUserId: this.props.userData.loggedInUser.id,
                iconName: "Listings",
              };

              const proofActivity =
                await this.props.createActivity(activityPayload);
              this.props.addUserActivity(proofActivity.data);

              this.props.fetchProofs && this.props.fetchProofs();
              this.props.fetchProofsCounters &&
                this.props.fetchProofsCounters();

              toast.success("Proof successfully sent!", {
                position: toast.POSITION.TOP_RIGHT,
              });

              // this.sendReportRef.handleLoading(false);
              this.setState({
                isSendProofOpen: false,
                inProgress: false,
              });
              this.props.handleMenu && this.props.handleMenu();
            })
            .catch((err) => {
              console.log(err);
              toast.error("Something went wrong!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              // this.sendReportRef.handleLoading(false);
              this.setState({
                isSendProofOpen: false,
              });
            });
          return;
        }
        if (this.props.type === "email") {
          let payload = {
            emailsList,
            html: encodeURIComponent(this.props.template),
            templateId: this.props.templateId,
            userId: this.props.accountInfo.id,
            templateFolderName: this.props.activeTableTab,
          };

          return this.props
            .sendTemplate(payload)
            .then(() => {
              this.props.handleMenu();
              this.postEmailSentActivity(emailsList);
              toast.success("Email sent successfully!", {
                position: toast.POSITION.TOP_RIGHT,
              });

              if (this.props.getSystemTemplates)
                this.props.getSystemTemplates();
            })
            .catch((err) => {
              this.setState({
                inProgress: false,
              });
              console.log(err);
            });
        }
        let payload = {
          userId: this.props.accountInfo?.id,
          adId: this.props.listingData?.id,
          type: this.props.type,
          emailsList,
          selectedInvoices: [this.props.actionItem],
          sourceId: this.props?.actionItem?.["payments?.sourceId"],
        };

        this.props
          .sendLinkMessage(payload)
          .then(() => {
            this.props.handleMenu();
            this.postInvoiceSendActivity(emailsList);
            toast.success("Link sent successfully!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            this.setState({ inProgress: false });
          })
          .catch((err) => {
            this.setState({
              inProgress: false,
            });
            console.log(err);
          });
      }
      return;
    } else {
      toast.error("Unable to proceed. Invalid form!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  postEmailSentActivity = async (emailsList) => {
    const emails = emailsList.toString();
    let activityPayload = {
      status: 1,
      userId: this.props.accountInfo.id,
      activityType: this.props.activitiesTypes.EMAIL_SENT,
      iconName: "SendActivity",
      activity: activities.emailSent
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{emailType}}", "Email")
        .replace("{{recipientEmail}}", `${emails}`),
      adminUserId: this.props.userData.loggedInUser.id,
    };
    await this.props.createActivity(activityPayload);
  };

  handleLinkActivity = async ({ phoneNumbers }) => {
    const numbers = phoneNumbers.toString();

    let activityPayload = {
      status: 1,
      userId: this.props.actionItem.userId,
      iconName: "SendActivity",
      adminUserId: this.props.userData.loggedInUser.id,
      // invoiceId: this.props.invoiceId,
    };

    if (this.props.type === "receipt") {
      activityPayload.activity = activities.sendReceiptLink
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{invoiceId}}", `${this.props.invoiceId}`)
        .replace("{{numbers}}", `${numbers}`);
    } else {
      activityPayload.activityType =
        this.props.activitiesTypes.SHARED_PAYMENT_LINK;
      activityPayload.activity = activities.sendPaymentLink
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{invoiceId}}", `#${this.props.invoiceId}`)
        .replace("{{numbers}}", `${numbers}`);
    }

    await this.props.createActivity(activityPayload);
  };

  postInvoiceSendActivity = async (emailsList) => {
    const emails = emailsList.toString();
    let activityPayload = {
      status: 1,
      invoiceId: this.props.invoiceId,
      userId: this.props.actionItem.userId,
      activityType: this.props.activitiesTypes.SEND_INVOICE,
      iconName: "SendActivity",
      activity: activities.sendInvoice
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{invoiceId}}", `${this.props.invoiceId}`)
        .replace("{{recipientEmail}}", `${emails}`),
      adminUserId: this.props.userData.loggedInUser.id,
    };

    await this.props.createActivity(activityPayload);
  };

  postReportActivity = async (
    emailsListString,
    phoneNumbersString,
    reportId,
  ) => {
    let activityType;
    let activity;

    if (this.state.activeTab === "email" && this.props.accountInfo) {
      activityType = this.props.activitiesTypes.ACCOUNT_REPORT_BY_EMAIL;
      activity = activities.accountReportByEmail
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{emails}}", `${emailsListString}`);
    }

    if (this.state.activeTab === "message" && this.props.accountInfo) {
      activityType = this.props.activitiesTypes.ACCOUNT_REPORT_BY_SMS;
      activity = activities.accountReportBySMS
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{phoneNumbers}}", `${phoneNumbersString}`);
    }

    if (this.state.activeTab === "email" && this.props.listingData) {
      activityType = this.props.activitiesTypes.SHARED_REPORT;
      activity = activities.listingReportByEmail
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{emails}}", `${emailsListString}`);
    }

    if (this.state.activeTab === "message" && this.props.listingData) {
      activityType = this.props.activitiesTypes.SHARED_REPORT;
      activity = activities.listingReportBySMS
        .replace(
          "{{admin_name}}",
          `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        )
        .replace("{{phoneNumbers}}", `${phoneNumbersString}`);
    }

    let activityPayload = {
      adId: this.props.listingData?.id,
      userId: this.props.accountInfo?.id,
      status: 1,
      activityType: activityType,
      activity: activity,
      adminUserId: this.props.userData.loggedInUser.id,
      iconName: "Report",
    };

    if (this.props.listingData) {
      activityPayload["adReportId"] = reportId;
    } else {
      activityPayload["accountReportId"] = reportId;
    }

    await this.props.createActivity(activityPayload);
  };

  render() {
    const { activeTab, errorMessage, inProgress } = this.state;
    const {
      accountInfo,
      listingData,
      type,
      showIcon = true,
      isDisabled,
    } = this.props;

    let buttonText;
    switch (type) {
      case "paymentLink": {
        buttonText = "Send link";
        break;
      }
      case "receipt": {
        buttonText = "Send receipt";
        break;
      }
      case "report": {
        buttonText = "Send Report";
        break;
      }
      case "send-proof": {
        buttonText = "Send proof";
        break;
      }

      case "send-liting-proof": {
        buttonText = "Send Listing proof";
        break;
      }
      default: {
        buttonText = "Send";
      }
    }

    let tabs = [
      {
        label: "By email",
        type: "email",
      },
      {
        label: "By text message",
        type: "message",
      },
    ];

    return (
      <div className="payment-link-invoice-details">
        <div className="payment-link-popover">
          {type !== "email" && (
            <div className="nav">
              {tabs.map((itm) => {
                return (
                  <button
                    key={itm.type}
                    type="button"
                    className={`tab ${
                      activeTab === itm.type ? "selected" : "unselected"
                    }`}
                    onClick={() => {
                      this.updateActiveTab(itm.type);
                    }}
                  >
                    {itm.label}
                  </button>
                );
              })}
            </div>
          )}
          <Formik
            initialValues={this.initialFormData}
            validationSchema={this.formSchema}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={this.handleSubmit}
          >
            {() => {
              return (
                <Form id="payment-link-form">
                  {activeTab === "email" && (
                    <div>
                      <div className="payment-popover-title">
                        RECEIVING E-MAIL ADDRESS
                      </div>

                      <FTSEmail
                        name="addedEmail"
                        placeholder="Additional email address"
                      />

                      {(accountInfo?.email || accountInfo?.additionalEmail) && (
                        <div className="phone-email-section ">
                          Account emails
                        </div>
                      )}

                      {accountInfo?.email && (
                        <Field name="email">
                          {({ field: { value }, form }) => (
                            <Checkbox
                              className="link-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              name="email"
                              label={accountInfo.email}
                              checked={value}
                              onChange={() => {
                                if (value) {
                                  form.setFieldValue("email", "");
                                } else {
                                  form.setFieldValue(
                                    "email",
                                    accountInfo.email,
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}

                      {accountInfo?.additionalEmail && (
                        <Field name="additionalEmail">
                          {({ field: { value }, form }) => (
                            <Checkbox
                              className="link-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              name="additionalEmail"
                              label={accountInfo.additionalEmail}
                              checked={value}
                              onChange={() => {
                                if (value) {
                                  form.setFieldValue("additionalEmail", "");
                                } else {
                                  form.setFieldValue(
                                    "additionalEmail",
                                    accountInfo.additionalEmail,
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}

                      {listingData?.email &&
                        !this.checkDuplicateEmail(listingData?.email) && (
                          <div className="phone-email-section ">
                            Listing email
                          </div>
                        )}

                      {listingData?.email &&
                        !this.checkDuplicateEmail(listingData?.email) && (
                          <Field name="listingEmail">
                            {({ field: { value }, form }) => (
                              <Checkbox
                                className="link-checkbox"
                                color="#647074"
                                icon={<Check className="vx-icon" size={16} />}
                                name="listingEmail"
                                label={this.extractEmails(listingData.email)[0]}
                                checked={value}
                                onChange={() => {
                                  if (value) {
                                    form.setFieldValue("listingEmail", "");
                                  } else {
                                    form.setFieldValue(
                                      "listingEmail",
                                      this.extractEmails(listingData.email)[0],
                                    );
                                  }
                                }}
                              />
                            )}
                          </Field>
                        )}
                    </div>
                  )}

                  {activeTab === "message" && (
                    <div>
                      <div className="payment-popover-title mb-1">
                        RECEIVING PHONE NUMBER
                      </div>

                      <FormikMaskedInput
                        name="additionalPhoneNumber"
                        placeholder="Additional phone number"
                        mask={phoneNumberMask}
                      />

                      {(accountInfo?.mobilePhone ||
                        accountInfo?.phoneNumber ||
                        accountInfo?.phoneNumberSecondary) && (
                        <div className="phone-email-section ">
                          Account phone numbers
                        </div>
                      )}

                      {accountInfo?.mobilePhone && (
                        <Field name="mobilePhone">
                          {({ field: { value }, form }) => (
                            <Checkbox
                              className="link-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              name="mobilePhone"
                              label={accountInfo.mobilePhone}
                              checked={value}
                              onChange={() => {
                                if (value) {
                                  form.setFieldValue("mobilePhone", "");
                                } else {
                                  form.setFieldValue(
                                    "mobilePhone",
                                    accountInfo.mobilePhone,
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}

                      {accountInfo?.phoneNumber && (
                        <Field name="phoneNumber">
                          {({ field: { value }, form }) => (
                            <Checkbox
                              className="link-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              name="phoneNumber"
                              label={accountInfo.phoneNumber}
                              checked={value}
                              onChange={() => {
                                if (value) {
                                  form.setFieldValue("phoneNumber", "");
                                } else {
                                  form.setFieldValue(
                                    "phoneNumber",
                                    accountInfo.phoneNumber,
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}

                      {accountInfo?.phoneNumberSecondary && (
                        <Field name="phoneNumberSecondary">
                          {({ field: { value }, form }) => (
                            <Checkbox
                              className="link-checkbox"
                              color="#647074"
                              icon={<Check className="vx-icon" size={16} />}
                              name="phoneNumberSecondary"
                              label={accountInfo.phoneNumberSecondary}
                              checked={value}
                              onChange={() => {
                                if (value) {
                                  form.setFieldValue(
                                    "phoneNumberSecondary",
                                    "",
                                  );
                                } else {
                                  form.setFieldValue(
                                    "phoneNumberSecondary",
                                    accountInfo.phoneNumberSecondary,
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}

                      {((listingData?.phoneNumber &&
                        !this.checkDuplicatePhoneNumber(
                          listingData?.phoneNumber,
                        )) ||
                        (listingData?.phoneNumberSecondary &&
                          !this.checkDuplicatePhoneNumber(
                            listingData?.phoneNumberSecondary,
                          )) ||
                        (listingData?.afterHours &&
                          !this.checkDuplicatePhoneNumber(
                            listingData?.afterHours,
                          ))) && (
                        <div className="phone-email-section ">
                          Listing phone numbers
                        </div>
                      )}

                      {listingData?.phoneNumber &&
                        !this.checkDuplicatePhoneNumber(
                          listingData?.phoneNumber,
                        ) && (
                          <Field name="listingPhoneNumber">
                            {({ field: { value }, form }) => (
                              <Checkbox
                                className="link-checkbox"
                                color="#647074"
                                icon={<Check className="vx-icon" size={16} />}
                                name="listingPhoneNumber"
                                label={listingData.phoneNumber}
                                checked={value}
                                onChange={() => {
                                  if (value) {
                                    form.setFieldValue(
                                      "listingPhoneNumber",
                                      "",
                                    );
                                  } else {
                                    form.setFieldValue(
                                      "listingPhoneNumber",
                                      listingData.phoneNumber,
                                    );
                                  }
                                }}
                              />
                            )}
                          </Field>
                        )}

                      {listingData?.phoneNumberSecondary &&
                        !this.checkDuplicatePhoneNumber(
                          listingData?.phoneNumberSecondary,
                        ) && (
                          <Field name="listingPhoneNumberSecondary">
                            {({ field: { value }, form }) => (
                              <Checkbox
                                className="link-checkbox"
                                color="#647074"
                                icon={<Check className="vx-icon" size={16} />}
                                name="listingPhoneNumberSecondary"
                                label={listingData.phoneNumberSecondary}
                                checked={value}
                                onChange={() => {
                                  if (value) {
                                    form.setFieldValue(
                                      "listingPhoneNumberSecondary",
                                      "",
                                    );
                                  } else {
                                    form.setFieldValue(
                                      "listingPhoneNumberSecondary",
                                      listingData.phoneNumberSecondary,
                                    );
                                  }
                                }}
                              />
                            )}
                          </Field>
                        )}

                      {listingData?.afterHours &&
                        !this.checkDuplicatePhoneNumber(
                          listingData?.afterHours,
                        ) && (
                          <Field name="afterHours">
                            {({ field: { value }, form }) => (
                              <Checkbox
                                className="link-checkbox"
                                color="#647074"
                                icon={<Check className="vx-icon" size={16} />}
                                name="afterHours"
                                label={listingData.afterHours}
                                checked={value}
                                onChange={() => {
                                  if (value) {
                                    form.setFieldValue("afterHours", "");
                                  } else {
                                    form.setFieldValue(
                                      "afterHours",
                                      listingData.afterHours,
                                    );
                                  }
                                }}
                              />
                            )}
                          </Field>
                        )}
                    </div>
                  )}

                  {errorMessage && (
                    <span className="error-message">{errorMessage}</span>
                  )}

                  <Button
                    type="submit"
                    disabled={isDisabled}
                    color="primary"
                    className="w-100 mt-1"
                  >
                    {inProgress || this.props.inProgress ? (
                      <Spinner size="sm" />
                    ) : (
                      <>
                        {showIcon && (
                          <span className="button-icon">
                            <Icon name="Send" />
                          </span>
                        )}
                        {buttonText}
                      </>
                    )}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
    activitiesTypes: state.essentialLists.activitiesTypes,
    userData: state.auth.login.values,
    selectedInvoices: state.invoices.selectedInvoices,
  };
};

const mapDispatchToProps = {
  sendLinkMessage,
  sendTemplate,
  createActivity,
  addUserActivity,
  sendProofAction: sendProof,
  sendReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendPaymentLink);
