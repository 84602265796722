/* eslint-disable indent */
import React from "react";
import { connect } from "react-redux";

import { listingColumns } from "../accounts/columns";
import { getUserListings } from "../../../redux/actions/users";
import { history } from "../../../history";

import "../../../assets/scss/plugins/tables/_agGridStyleOverride.scss";
import "../../../assets/scss/pages/users.scss";
import "../../../index.scss";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";
import { setAccountSettingsState } from "../../../redux/actions/accounts";
import { searchListings } from "../../../redux/actions/listings";
import Icons from "../../../components/fts/Icons";
import FTSAgGridDataTable from "../../../components/fts/fts-ag-grid-data-table/FTSAgGridDataTable";
import {
  getAccountListings,
  updateAccountListingsResultsPerPage,
  updateAccountListingsSortModel,
  updateActiveSubTab,
  updateCurrentPage,
  countAccountListings,
  updateAccountListingsSearchInput,
  updateLastViewedAccount,
} from "../../../redux/actions/accountListings";

class UserListings extends React.PureComponent {
  state = {
    collectedDataForCopy: "",
  };

  componentDidMount() {
    const {
      getAccountListings,
      accountId,
      countAccountListings,
      accountListings,
      updateLastViewedAccount,
    } = this.props;

    const { lastViewedAccount } = accountListings;

    if (lastViewedAccount !== accountId) {
      updateLastViewedAccount({ id: accountId });
      getAccountListings({
        userId: accountId,
        searchString: "",
        priceId: 1,
        page: 1,
        resultsPerPage: 10,
        sort: [],
      });

      countAccountListings({
        userId: accountId,
        searchString: "",
      });
    }
  }

  onRowClicked = ({ data }) => {
    history.push(`/listings/${data.id}`, { url: data.seo_url_identifier });
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  handleSearchInput = (searchString) => {
    const {
      getAccountListings,
      accountListings,
      accountId,
      countAccountListings,
      updateAccountListingsSearchInput,
    } = this.props;
    const { activeSubTab, resultsPerPage } = accountListings;

    updateAccountListingsSearchInput({ searchString });
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      getAccountListings({
        userId: accountId,
        searchString,
        priceId: activeSubTab,
        page: 1,
        resultsPerPage,
        sort: [],
      });

      countAccountListings({
        userId: accountId,
        searchString,
      });
    }, 300);
  };

  onSortChanged = (params) => {
    const {
      updateAccountListingsSortModel,
      getAccountListings,
      accountListings,
      accountId,
    } = this.props;

    const { activeSubTab, currentPage, resultsPerPage, searchString } =
      accountListings;

    let sort = params.api.getSortModel();

    updateAccountListingsSortModel({ sortModel: sort });
    getAccountListings({
      userId: accountId,
      priceId: activeSubTab,
      page: currentPage,
      searchString,
      resultsPerPage,
      sort,
    });
  };

  onPageChange = (page) => {
    const {
      updateCurrentPage,
      accountListings,
      getAccountListings,
      accountId,
    } = this.props;
    const { activeSubTab, resultsPerPage, searchString, sortModel } =
      accountListings;

    updateCurrentPage({ page });
    getAccountListings({
      userId: accountId,
      searchString,
      priceId: activeSubTab,
      page,
      resultsPerPage,
      sort: sortModel,
    });
  };

  onSubTabChange = (subTab) => {
    const {
      getAccountListings,
      updateActiveSubTab,
      accountListings,
      accountId,
    } = this.props;

    const { resultsPerPage, searchString, sortModel } = accountListings;

    updateActiveSubTab({ priceId: subTab?.id });
    getAccountListings({
      userId: accountId,
      searchString,
      priceId: subTab.id,
      page: 1,
      resultsPerPage,
      sort: sortModel,
    });
  };

  handleFilterSize = (resultsPerPage) => {
    const {
      updateAccountListingsResultsPerPage,
      accountListings,
      getAccountListings,
      accountId,
      updateCurrentPage,
    } = this.props;

    const { sortModel, searchString, activeSubTab } = accountListings;

    updateAccountListingsResultsPerPage({ resultsPerPage });
    updateCurrentPage({ page: 1 });

    getAccountListings({
      userId: accountId,
      searchString,
      priceId: activeSubTab,
      page: 1,
      resultsPerPage,
      sort: sortModel,
    });
  };

  handleRowClasses = (params) => {
    let classes = [];

    if (params.data.status === 0) classes.push("row-disabled");
    if (params.data.status === 1) classes.push("row-active");

    return classes.join(" ");
  };

  getNoRowsMessage = () => {
    const { accountListings } = this.props;
    let message = "No listings found";

    if (accountListings.searchString) message += " for this specific search";

    return message;
  };

  render() {
    const {
      listingsData,
      pageCount,
      resultsPerPage,
      subTabs,
      allListingsCount,
      activeSubTab,
      currentPage,
      searchString,
      sortModel,
      inProgress,
    } = this.props.accountListings;

    return (
      <>
        <FTSAgGridDataTable
          rowData={listingsData}
          columnDefs={listingColumns(this.collectDataForCopy)}
          isMultiTab
          subTabs={subTabs}
          tableHeader={`Listings (${allListingsCount})`}
          onSortChanged={this.onSortChanged}
          onPageChange={this.onPageChange}
          onSubTabChange={this.onSubTabChange}
          pageCount={pageCount}
          currentPageSize={resultsPerPage}
          activeSubTabId={activeSubTab}
          handleSearchInput={this.handleSearchInput}
          onRowClicked={this.onRowClicked}
          handleFilterSize={this.handleFilterSize}
          currentPage={currentPage}
          searchValue={searchString}
          defaultSortModel={sortModel}
          getRowClass={this.handleRowClasses}
          noRowsMessage={this.getNoRowsMessage()}
          inProgress={inProgress}
        />
        <ContextMenu id="contextMenuUser" preventHideOnScroll={false}>
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(this.state.collectedDataForCopy);
            }}
          >
            <FaRegCopy className="copy" />
            <span className="text-dark ml-1">Copy</span>
          </MenuItem>
          {this.state.call && this.state.collectedDataForCopy && (
            <MenuItem>
              <Icons name="PhoneBlue" />
              <a
                className="ml-1 text-decoration-none text-dark w-100"
                href={`tel:${this.state.collectedDataForCopy}`}
              >
                Call - {this.state.collectedDataForCopy}
              </a>
            </MenuItem>
          )}
        </ContextMenu>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountSettingsState: state.accountCounts.accountSettingsState,
    accountListings: state.accountListings,
  };
};
export default connect(mapStateToProps, {
  getUserListings,
  setAccountSettingsState,
  searchListings,
  getAccountListings,
  updateActiveSubTab,
  updateCurrentPage,
  updateAccountListingsSortModel,
  updateAccountListingsResultsPerPage,
  countAccountListings,
  updateAccountListingsSearchInput,
  updateLastViewedAccount,
})(UserListings);
