export const LOAD_MORE_USERS_LIST = "LOAD_MORE_USERS_LIST";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_USER_STICKY_LIST = "SET_USER_STICKY_LIST";
export const CLEAR_USER_LIST = "CLEAR_USER_LIST";
export const CLEAR_USER_STICKY_LIST = "CLEAR_USER_STICKY_LIST";
export const ADD_USER_NOTE = "ADD_USER_NOTE";
export const ADD_USER_NOTE_STICKY = "ADD_USER_NOTE_STICKY";
export const UPDATE_USER_NOTE = "UPDATE_USER_NOTE";
export const UPDATE_USER_NOTE_STICKY = "UPDATE_USER_NOTE_STICKY";
export const REMOVE_USER_NOTE = "REMOVE_USER_NOTE";
export const REMOVE_USER_NOTE_STICKY = "REMOVE_USER_NOTE_STICKY";
export const ADD_USER_NOTE_REPLY = "ADD_USER_NOTE_REPLY";
export const ADD_USER_STICKY_NOTE_REPLY = "ADD_USER_STICKY_NOTE_REPLY";
export const UNPIN_USER_NOTE = "UNPIN_USER_NOTE";
export const RESET_STATE = "RESET_STATE";

export const addUserNoteReply = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_USER_NOTE_REPLY,
      data,
    });
  };
};

export const addUserStickyNoteReply = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_USER_STICKY_NOTE_REPLY,
      data,
    });
  };
};

export const setUserNotesList = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_LIST,
      data,
    });
  };
};

export const loadMoreUserNotesList = (data) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_MORE_USERS_LIST,
      data,
    });
  };
};

export const setUserNotesStickyList = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_STICKY_LIST,
      data,
    });
  };
};

export const clearUserNotesList = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_LIST,
    });
  };
};

export const clearUserNotesStickyList = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_STICKY_LIST,
    });
  };
};

export const addUserNote = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_USER_NOTE,
      data,
    });
  };
};

export const addUserNoteSticky = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_USER_NOTE_STICKY,
      data,
    });
  };
};

export const updateUserNote = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_NOTE,
      data,
    });
  };
};

export const updateUserNoteSticky = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_NOTE_STICKY,
      data,
    });
  };
};

export const removeUserNote = (data) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_USER_NOTE,
      data,
    });
  };
};

export const unpinUserNote = (data) => {
  return (dispatch) => {
    dispatch({
      type: UNPIN_USER_NOTE,
      data,
    });
  };
};

export const removeUserNoteSticky = (data) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_USER_NOTE_STICKY,
      data,
    });
  };
};

export const resetState = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_STATE,
    });
  };
};
