import FTSAxios from "../../../axios/fts.instance";
import { serialize, generateFilters } from "../../../utility/serialize";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import { GET_ACCOUNT_COUNTS } from "../../reducers/accounts";

export const searchVendorAccounts = ({
  page,
  resultsPerPage,
  sort,
  searchString,
  fields,
  ...rest
}) => {
  return () => {
    const queryParams = {
      searchString: "",
      page: page || 1,
      pageSize: resultsPerPage,
    };

    const newFilters = {
      ...rest,
      companyName: encodeURIComponent(rest.companyName || ""),
      adminIsMerchant: rest.adminIsMerchant ? 1 : "",
      havePrivateLocations: rest.havePrivateLocations,
    };

    const { multi_match, filter } = generateFilters(newFilters, {
      term: {
        userTypeNamed: "vendor",
      },
    });

    let newSearchString = searchString && searchString.trim();

    if (newSearchString) {
      const slugRegex = new RegExp("^[0-9]*$");
      if (slugRegex.test(newSearchString))
        newSearchString = "#" + newSearchString;

      multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(newSearchString)
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            newSearchString.replace(/ /g, " AND ") +
            ")",
          // rfc3986EncodeURIComponent(searchString || "")
          // .replace(/%40/g, "@")
          // .replace(/\+/g, " AND "),
          fields,
          type: "phrase_prefix",
        },
      });
    }

    return FTSAxios.post("admin/users/search", {
      ...queryParams,
      multi_match,
      filter,
      sort,
    });
  };
};

export const searchVendorAccountsUser = ({
  page,
  resultsPerPage,
  sort,
  searchString,
  fields,
  ...rest
}) => {
  return () => {
    console.log(rest);
    const queryParams = {
      searchString: "",
      page: page || 1,
      pageSize: resultsPerPage,
    };

    let newFilters = {
      ...rest,
      companyName: encodeURIComponent(rest.companyName || ""),
      adminIsMerchant: rest.adminIsMerchant ? 1 : "",
      havePrivateLocations: rest.havePrivateLocations,
    };
    // delete newFilters.companyName;

    const { multi_match, filter } = generateFilters(newFilters, {
      match_phrase_prefix: {
        userTypeNamed: "user",
      },
    });
    let newSearchString = searchString && searchString.trim();

    if (newSearchString) {
      multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(newSearchString.trim())
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            newSearchString.replace(/ /g, " AND ") +
            ")",
          // rfc3986EncodeURIComponent(searchString)
          // .replace(/%40/g, "@")
          // .replace(/\+/g, " AND "),
          fields,
          type: "phrase_prefix",
        },
      });
    }

    return FTSAxios.post("admin/users/search", {
      ...queryParams,
      multi_match,
      filter,
      sort,
    });
  };
};

export const getUsersData = (params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);

    return FTSAxios.get(`admin/users/all?${stringifiedParams}`);
  };
};
export const createAccountAltContact = (data) => {
  return () => {
    return FTSAxios.post("admin/contact/create", data);
  };
};

export const updateAccountAltContact = (data, contactId) => {
  return () => {
    return FTSAxios.put(`admin/contact/${contactId}`, data);
  };
};

export const removeAccountInfoAltContact = (contactId) => {
  return () => {
    return FTSAxios.delete(`admin/contact/${contactId}`);
  };
};

export const getUserPhoneCalls = (userId) => {
  return () => {
    return FTSAxios.get(`admin/user-calls/${userId}`);
  };
};

export const filterUserTypes = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_USER_TYPES_DATA", value });
};

export const getUserTypesData = () => {
  return (dispatch) => {
    return FTSAxios.get("admin/lists/user-types").then((response) => {
      dispatch({
        type: "GET_USER_TYPES_DATA",
        data: response.data,
        totalPages: 1,
      });
    });
  };
};

export const setUsersListings = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: "SET_USERS_LISTINGS",
      payload,
    });
  };
};

export const updateUserTypeData = (payload) => {
  return () => {
    return FTSAxios.put(`admin/lists/user-type/${payload.id}`, payload);
  };
};

export const createUserTypeData = (payload) => {
  return () => {
    return FTSAxios.post("admin/lists/user-type/create", payload);
  };
};

export const deleteUserTypeData = (payload) => {
  return () => {
    return FTSAxios.delete(`admin/lists/user-type/${payload.id}`);
  };
};

export const getUserData = (userId) => {
  return () => {
    return FTSAxios.get(`admin/users/${userId}`);
  };
};

export const getAccountCounts = (userId) => {
  return (dispatch) => {
    return FTSAxios.get(`admin/users/${userId}`).then((response) => {
      dispatch({
        type: GET_ACCOUNT_COUNTS,
        data: response.data,
      });
    });
  };
};

export const postUserData = (payload) => {
  return () => {
    return FTSAxios.post("admin/users", payload);
  };
};

export const getUserTypes = () => {
  return () => {
    return FTSAxios.get("admin/lists/user-types");
  };
};

export const getAccountAltContacts = (accountId) => {
  return () => {
    return FTSAxios.get(`admin/contact/get/user/0/${accountId}`);
  };
};

export const getUserListings = (userId, params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);
    return FTSAxios.get(`admin/users/listings/${userId}?${stringifiedParams}`);
  };
};
export const getAccountSubUser = (userId, params) => {
  return () => {
    const page = params.page || 1;
    const queryParams = { ...params, page };
    const stringifiedParams = serialize(queryParams);
    return FTSAxios.get(`admin/users/sub-user/${userId}?${stringifiedParams}`);
  };
};

export const updateUserData = (data) => {
  return () => {
    return FTSAxios.put(`admin/users/${data.id}`, data);
  };
};

export const sentRequestPasswordChange = (data) => {
  return () => {
    return FTSAxios.post("admin/users/reset-password", data);
  };
};

export const createUserData = (data) => {
  return () => {
    return FTSAxios.post("admin/user/create", data);
  };
};

export const filterData = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_USERS_DATA", value });
};

export const updateUserFilter = (data) => {
  return (dispatch) => dispatch({ type: "UPDATE_USER_FILTER", data });
};

export const clearUserFilters = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_USER_FILTERS" });
    return Promise.resolve();
  };
};

export const reassignUser = (data) => {
  return () => {
    return FTSAxios.put(`admin/users/reassign/${data.userId}`, data);
  };
};

export const reassignTo = (data) => {
  return () => {
    return FTSAxios.post("admin/users/bulk/assign", data);
  };
};

export const getActiveLeads = (params) => {
  return () => {
    return FTSAxios.get("admin/sales/active", { params });
  };
};

export const deleteAccount = (userId) => {
  return () => {
    return FTSAxios.delete(`admin/users/${userId}`);
  };
};

export const setDefaultPayment = (id) => {
  return () => {
    return FTSAxios.put(`admin/users/payment-method/${id}`);
  };
};

export const deletePayment = (id) => {
  return () => {
    return FTSAxios.delete(`admin/users/payment-method/${id}`);
  };
};

export const createPayment = (data) => {
  return () => {
    return FTSAxios.post("admin/payment", data);
  };
};

export const setRecurringPayment = (data) => {
  return () => {
    return FTSAxios.put("admin/payment/update/recurring", data);
  };
};

export const getUserCards = (id) => {
  return () => {
    return FTSAxios.get(`admin/users/get-user-cards/${id}`);
  };
};

export const getNewAccountsCount = () => {
  return () => {
    return FTSAxios.get("admin/users/count/new-account");
  };
};

export const setNewAccountsCount = (payload) => {
  return (dispatch) => {
    return dispatch({ type: "SET_NEW_ACCOUNT_UNREAD_COUNTER", payload });
  };
};

export const searchAccounts = ({
  page,
  resultsPerPage,
  sort,
  searchString,
  fields,
  range,
  ...rest
}) => {
  return () => {
    const queryParams = {
      searchString: "",
      page: page || 1,
      pageSize: resultsPerPage,
    };

    const newFilters = {
      ...rest,
      companyName: rfc3986EncodeURIComponent(rest.companyName || ""),
      adminIsMerchant: rest.adminIsMerchant ? 1 : "",
    };

    const { multi_match, filter } = generateFilters(newFilters);
    if (range) filter.push({ range });

    let newSearchString = searchString && searchString.trim();

    if (newSearchString) {
      const slugRegex = new RegExp("^[0-9]*$");
      if (slugRegex.test(newSearchString))
        newSearchString = "#" + newSearchString;

      multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(newSearchString)
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            newSearchString.replace(/ /g, " AND ") +
            ")",
          fields,
          type: "phrase_prefix",
        },
      });
    }

    return FTSAxios.post("admin/users/search", {
      ...queryParams,
      multi_match,
      filter,
      sort,
    });
  };
};

export const searchNewAccounts = ({
  page,
  resultsPerPage,
  sort,
  searchString,
  fields,
  range,
  isDeleted = false,
  ...rest
}) => {
  return () => {
    let must_not = {};

    const queryParams = {
      searchString: "",
      page: page || 1,
      pageSize: resultsPerPage,
    };

    const newFilters = {
      ...rest,
      companyName: rfc3986EncodeURIComponent(rest.companyName || ""),
      adminIsMerchant: rest.adminIsMerchant ? 1 : "",
    };

    const { multi_match, filter } = generateFilters(newFilters);
    if (range) filter.push({ range });

    let newSearchString = searchString && searchString.trim();

    if (newSearchString) {
      const slugRegex = new RegExp("^[0-9]*$");
      if (slugRegex.test(newSearchString))
        newSearchString = "#" + newSearchString;

      multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(newSearchString)
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            newSearchString.replace(/ /g, " AND ") +
            ")",
          fields,
          type: "phrase_prefix",
        },
      });
    }

    if (!isDeleted) must_not["exists"] = { field: "deletedAt" };

    if (isDeleted) {
      multi_match.push({
        exists: {
          field: "deletedAt",
        },
      });
    }

    return FTSAxios.post("admin/users/new-account/search", {
      ...queryParams,
      multi_match,
      must_not: !isDeleted ? must_not : null,
      filter,
      sort,
    });
  };
};

export const getAccountAccessEmails = (userId) => {
  return () => {
    return FTSAxios.get(`admin/users/get-account-access-emails/${userId}`);
  };
};

export const requestAccountAccess = (payload) => {
  return () => {
    return FTSAxios.post("admin/account-access-request", payload);
  };
};
