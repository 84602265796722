import React, { Component } from "react";
import { Label, FormGroup, Button } from "reactstrap";
import { X } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

const formSchema = Yup.object().shape({
  type: Yup.string().required("Required"),
  price: Yup.string().required("Required"),
  serviceNumbers: Yup.string().required("Required"),
});

class AdsTypesSidebar extends Component {
  addNew = false;

  initialData = {
    id: "",
    typeId: "",
    type: "",
    serviceNumbers: "",
    price: "",
  };

  componentDidUpdate() {
    this.addNew = false;
  }

  handleSubmit = this.props.onUserAdTypeUpdate;

  render() {
    const { show, handleSidebar, data } = this.props;
    const initValues = data ? { ...data } : this.initialData;

    return (
      <div
        className={classnames("data-list-sidebar", {
          show: show,
        })}
      >
        <div className="data-list-sidebar-header px-2 d-flex justify-content-between">
          <h4>{data !== null ? "Update user type" : "Submit new user type"}</h4>
          <X size={20} onClick={() => handleSidebar(false, true)} />
        </div>
        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          validationSchema={formSchema}
          initialValues={initValues}
        >
          {({ errors, touched }) => (
            <Form style={{ height: "100%" }}>
              <PerfectScrollbar
                className="data-list-fields px-2 mt-3"
                options={{ wheelPropagation: false }}
              >
                <FormGroup>
                  <Label for="cat-id">ID</Label>
                  <Field
                    disabled
                    placeholder="eg. 12"
                    name="id"
                    className="form-control"
                    id="cat-id"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-label">Type ID</Label>
                  <Field
                    name="typeId"
                    type="number"
                    disabled
                    placeholder="eg. 23"
                    id="cat-label"
                    className={`form-control ${
                      errors.typeId && touched.typeId && "is-invalid"
                    }`}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-type">Type</Label>
                  <Field
                    name="type"
                    placeholder="eg. Standard"
                    id="cat-type"
                    className={`form-control ${
                      errors.type && touched.type && "is-invalid"
                    }`}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-price">Price</Label>
                  <Field
                    name="price"
                    type="number"
                    placeholder="eg. 230"
                    id="cat-price"
                    className={`form-control ${
                      errors.price && touched.price && "is-invalid"
                    }`}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="cat-service">Service Numbers</Label>
                  <Field
                    name="serviceNumbers"
                    type="number"
                    placeholder="eg. 521"
                    id="cat-service"
                    className={`form-control ${
                      errors.serviceNumbers &&
                      touched.serviceNumbers &&
                      "is-invalid"
                    }`}
                  />
                </FormGroup>
              </PerfectScrollbar>

              <div className="data-list-sidebar-footer px-2 d-flex justify-content-end align-items-center mt-1">
                <Button color="primary" type="submit">
                  {data !== null ? "Update" : "Submit"}
                </Button>
                <Button
                  type="button"
                  className="ml-1"
                  color="danger"
                  outline
                  onClick={() => handleSidebar(false, true)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default AdsTypesSidebar;
