import React from "react";

import "../../../assets/scss/components/fts/activity-item.scss";

import moment from "moment";
import { formatDate } from "../../../configs/dateConfig";

import FormikInputValue from "../../../components/fts/formik/formik-input-value";
import Icon from "../../../components/fts/Icons";
import ActivityLink from "../activity-link";

const ActivityItem = ({
  activity,
  accountId,
  listingId,
  callToAction,
  globalActivityPage,
  setActivityPreloadDate,
}) => {
  return (
    <>
      <div className="pt-2 d-flex align-items-center">
        <div
          className="mr-1 mb-2 rounded-circle d-flex align-items-center justify-content-center activity-icon"
          style={{
            padding: 5,
            minWidth: 40,
            height: 40,
            backgroundColor: "#F9FAFC",
          }}
        >
          <Icon name={activity.iconName} size={24} />
        </div>
        <div
          className={
            "pb-2 w-100 d-flex align-items-center justify-content-between"
          }
          style={{ borderBottom: "1px solid #E5E8E9" }}
        >
          <div>
            <span>
              {moment(activity.createdAt).format("ddd")}, {}
              {formatDate(activity.createdAt)}, at{" "}
              {moment(activity.createdAt).format("h:mmA")}
            </span>
            <FormikInputValue>{activity.activity}</FormikInputValue>{" "}
          </div>
          <ActivityLink
            activity={activity}
            activityId={accountId}
            accountId={accountId}
            listingId={listingId}
            callToAction={callToAction}
            globalActivityPage={globalActivityPage}
            setActivityPreloadDate={setActivityPreloadDate}
          />
        </div>
      </div>
    </>
  );
};

export default ActivityItem;
