import React from "react";
import "../../../assets/scss/components/fts/fts-upgrades-page.scss";
import { Card, FormGroup, Input } from "reactstrap";
import Icon from "../../../components/fts/Icons";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import { defineNewAccountsColumns } from "./columns";
import { hideMenu } from "react-contextmenu/modules/actions";
import { connect } from "react-redux";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";
import TableTabs from "../../../components/fts/table-tabs";
import moment from "moment";
import {
  searchAccounts,
  searchNewAccounts,
  setNewAccountsCount,
} from "../../../redux/actions/users";
import {
  getNewAccountsCounters,
  markNewAccountAsRead,
} from "../../../redux/actions/new-accounts";
import { createActivity } from "../../../redux/actions/activities";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import { activities } from "../../../constants";
import { history } from "../../../history";
import { toast } from "react-toastify";
import Icons from "../../../components/fts/Icons";

const defaultSearchFields = [
  "statusString",
  "idString",
  "userIdString",
  "paymentStatus",
  "updatedAtString",
  "renewalTimestampString",
  "email",
  "name",
  "address",
  "city",
  "state",
  "phoneNumber",
  "phoneNumberSecondary",
  "afterHours",
];
let tabs = [
  {
    label: "1W",
    type: "week",
    value: {
      quantity: 1,
      range: "week",
    },
  },
  {
    label: "1M",
    type: "month",
    value: {
      quantity: 1,
      range: "month",
    },
  },
  {
    label: "3M",
    type: "3Months",
    value: {
      quantity: 3,
      range: "month",
    },
  },
  {
    label: "6M",
    type: "6Months",
    value: {
      quantity: 6,
      range: "month",
    },
  },
  {
    label: "1Y",
    type: "1Year",
    value: {
      quantity: 1,
      range: "year",
    },
  },
];

class NewAccounts extends React.Component {
  state = {
    activeTab: "week",
    searchValue: "",
    inProgress: false,
    columnDefsNewAccounts: null,
    newAccountsTableData: null,

    totalRecords: 0,
    pageSize: 10,
    page: 1,
    pageCount: 0,
    defaultColDef: {
      sortable: true,
    },
    sortNewAccounts: "",
    query: "",
    activeSubTabId: 1,
    subTabs: [
      {
        id: 1,
        label: "Vendor Accounts",
        count: 0,
      },
      {
        id: 2,
        label: "User Accounts",
        count: 0,
      },
      {
        id: 3,
        label: "Deactivated Accounts",
        total: 0,
      },
      {
        id: 4,
        label: "Deleted Accounts",
        total: 0,
      },
    ],

    dateRange: {
      startAt: moment().subtract(1, "week").toISOString().split("T")[0],
      endAt: moment().toISOString().split("T")[0],
    },

    userTypeNamed: "vendor",
    status: 1,
    isDeleted: false,
  };

  debounce = setTimeout(() => {}, 300);

  componentDidMount() {
    this.getTableData();
    this.setState({
      columnDefsNewAccounts: defineNewAccountsColumns(
        this.collectDataForCopy,
        this.props.employees
      ),
    });
  }

  onSortChanged = (params) => {
    this.setState({ sortNewAccounts: params.api.getSortModel() }, () => {
      this.getTableData();
    });
  };

  pageSizes = [10, 25, 50, 100];

  handleSearch = (e) => {
    clearTimeout(this.debounce);
    this.setState(
      {
        searchValue: e.target.value,
      },
      () => {
        this.debounce = setTimeout(() => this.getTableData(), 300);
      }
    );
  };

  getAccountsCounter = () => {
    const { subTabs, dateRange, searchValue } = this.state;
    let newSubTabs = [...subTabs];

    let payload = {
      filter: [
        {
          range: {
            createdAt: {
              lte: dateRange.endAt,
              gte: dateRange.startAt,
            },
          },
        },
      ],
      multi_match: [],
      sort: "",
    };

    if (searchValue) {
      payload.multi_match.push({
        query_string: {
          query:
            "(" +
            rfc3986EncodeURIComponent(searchValue.trim())
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND ") +
            ") OR (" +
            searchValue.replace(/ /g, " AND ") +
            ")",
          fields: defaultSearchFields,
          type: "phrase_prefix",
        },
      });
    }

    this.props
      .getNewAccountsCounters(payload)
      .then(({ data }) => {
        newSubTabs[0].count = data.result.vendorsCounts;
        newSubTabs[1].count = data.result.usersCounts;
        newSubTabs[2].count = data.result.deactivatedCount;
        newSubTabs[3].count = data.result.deletedUsersCount;

        this.setState({
          subTabs: newSubTabs,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  handleActiveTabChanged = ({ type, value }) => {
    if (this.state.inProgress) {
      return;
    }
    let dateRange = {
      startAt: moment()
        .subtract(value.quantity, value.range)
        .toISOString()
        .split("T")[0],
      endAt: moment().toISOString().split("T")[0],
    };

    this.setState(
      {
        activeTab: type,
        dateRange,
      },
      () => {
        this.getTableData();
      }
    );
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  saveStateNewAccounts = () => {
    let newAccountsColumnState = this.gridColumnApiNewAccounts.getColumnState();
    localStorage.setItem(
      "newAccountsColumnState",
      JSON.stringify(newAccountsColumnState)
    );
  };

  getTableData = () => {
    const {
      searchValue,
      page,
      pageSize,
      dateRange,
      userTypeNamed,
      sortNewAccounts,
      status,
      isDeleted,
    } = this.state;

    if (this.gridApiNewAccounts) this.gridApiNewAccounts.showLoadingOverlay();
    this.getAccountsCounter();

    let payload = {
      fields: defaultSearchFields,
      searchString: searchValue,
      page,
      resultsPerPage: pageSize,
      userTypeNamed,
      status,
      isDeleted,
      range: {
        createdAt: {
          lte: dateRange.endAt,
          gte: dateRange.startAt,
        },
      },
      sort: sortNewAccounts?.length
        ? sortNewAccounts
        : [{ colId: "createdAt", sort: "desc" }],
    };

    this.props
      .searchNewAccounts(payload)
      .then((res) => {
        if (this.gridApiNewAccounts) this.gridApiNewAccounts.hideOverlay();
        this.setState({
          newAccountsTableData: res?.data?.result?.hits,
          pageCount: res.data.result?.nbHits / pageSize,
        });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  resetApi = () => {
    this.gridApiNewAccounts = null;
  };

  onGridReady = (params) => {
    this.gridApiNewAccounts = params.api;
    this.gridColumnApiNewAccounts = params.columnApi;

    if (localStorage.getItem("newAccountsColumnState") !== null) {
      this.gridColumnApiNewAccounts.setColumnState(
        JSON.parse(localStorage.getItem("newAccountsColumnState"))
      );
    }
  };

  onRowClicked = (e) => {
    if (!(e.data?.adminLastViewAt > e.data?.createdAt)) {
      this.handleOnClickActivity({ account: e.data });
      this.props
        .markNewAccountAsRead({ id: e.data.id })
        .then(() => {
          this.props.setNewAccountsCount(this.props.newAccountUnReadCount - 1);
          history.push(`/accounts/${e.data.id}`);
        })
        .catch((err) => {
          console.log("ERROR", err);
          toast.error("Something went wrong");
        });
    } else {
      history.push(`/accounts/${e.data.id}`);
    }
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      this.getTableData();
    });
  };

  filterSize = (type, val) => {
    this.setState(
      {
        pageSize: val,
        page: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };

  handleActiveSubTab = ({ id }) => {
    let payload = {
      activeSubTabId: id,
    };

    if (id === 1) {
      payload = {
        ...payload,
        userTypeNamed: "vendor",
        status: 1,
        isDeleted: false,
      };
    }

    if (id === 2) {
      payload = {
        ...payload,
        userTypeNamed: "user",
        status: 1,
        isDeleted: false,
      };
    }

    if (id === 3) {
      payload = {
        ...payload,
        userTypeNamed: null,
        status: 3,
      };
    }

    if (id === 4) {
      payload = {
        ...payload,
        userTypeNamed: null,
        status: null,
        isDeleted: true,
      };
    }

    this.setState(
      {
        ...payload,
      },
      () => {
        this.getTableData();
      }
    );
  };

  handleOnClickActivity = async ({ account }) => {
    let activityPayload = {
      status: 2,
      userId: account?.id,
      activityType: this.props.activitiesTypes.NEW_ACCOUNT_OPENED,
      activity: activities.newAccountOpened
        .replace(
          "{{admin_name}}",
          `${this.props.userData.firstName} ${this.props.userData.lastName}`
        )
        .replace("{{account_id}}", `${account?.id}`),
      adminUserId: this.props.userData.id,
      iconName: "Accounts",
    };

    return await this.props.createActivity(activityPayload);
  };

  render() {
    const {
      searchValue,
      activeTab,
      columnDefsNewAccounts,
      defaultColDef,
      newAccountsTableData,
      totalRecords,
      pageSize,
      page,
      pageCount,
      activeSubTabId,
      subTabs,
    } = this.state;
    return (
      <div className="fts-upgrades-page fts-new-accounts">
        <Card>
          <div className="fts-upgrade-title-row">
            <h4>New Accounts</h4>
            <div className="fts-upgrade-filters">
              <div className="nav">
                {tabs.map((itm) => {
                  return (
                    <button
                      key={itm.type}
                      className={`tab ${
                        activeTab === itm.type ? "selected" : "unselected"
                      }`}
                      onClick={() => {
                        this.handleActiveTabChanged({
                          type: itm.type,
                          value: itm.value,
                        });
                      }}
                    >
                      {itm.label}
                    </button>
                  );
                })}
              </div>
              <FormGroup className="position-relative has-icon-left ml-1 mb-0">
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    this.handleSearch(e);
                  }}
                  value={searchValue}
                  style={{ minWidth: 300, borderRadius: 4 }}
                  className="upgrades-search-input-field"
                />
                <div className="form-control-position">
                  <Icon name="Search" size={18} />
                </div>
              </FormGroup>
            </div>
          </div>

          <TableTabs
            tabs={subTabs}
            handleActiveTab={this.handleActiveSubTab}
            activeTabId={activeSubTabId}
          />
          <FTSDataTable
            tableName={"new-accounts"}
            onSortChanged={(params) =>
              this.onSortChanged(params, "new-accounts")
            }
            isSearch
            globalNewAccounts
            pageSizes={this.pageSizes}
            columnDefs={columnDefsNewAccounts}
            rowData={newAccountsTableData}
            totalRecords={totalRecords}
            resetApi={this.resetApi}
            onGridReadyInit={this.onGridReady}
            saveState={this.saveStateNewAccounts}
            currentPageSize={pageSize}
            currentPage={page}
            pageCount={pageCount}
            onRowClicked={this.onRowClicked}
            onPageChange={this.onPageChanged}
            onScroll={hideMenu}
            filterSize={this.filterSize}
            {...{
              defaultColDef,
            }}
          />
          <ContextMenu
            style={{ minWidth: 250 }}
            id="contextMenuUpgrades"
            preventHideOnScroll={false}
          >
            <MenuItem
              onClick={() =>
                navigator.clipboard.writeText(this.state.collectedDataForCopy)
              }
            >
              <FaRegCopy size={18} className="copy" />
              <span className="ml-1 text-dark">Copy</span>
            </MenuItem>
            {this.state.call && this.state.collectedDataForCopy && (
              <MenuItem>
                <Icons name="PhoneBlue" />
                <a
                  className="ml-1 text-decoration-none text-dark w-100"
                  href={`tel:${this.state.collectedDataForCopy}`}
                >
                  Call - {this.state.collectedDataForCopy}
                </a>
              </MenuItem>
            )}
          </ContextMenu>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.essentialLists.employees,
    activitiesTypes: state.essentialLists.activitiesTypes,
    userData: state.auth.login.values.loggedInUser,
    newAccountUnReadCount: state.accountCounts.newAccountUnReadCount,
  };
};

export default connect(mapStateToProps, {
  searchAccounts,
  searchNewAccounts,
  getNewAccountsCounters,
  markNewAccountAsRead,
  createActivity,
  setNewAccountsCount,
})(NewAccounts);
