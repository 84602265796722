import React, { Component } from "react";

import { Button } from "reactstrap";
import FTSModal from "../fts-modal";
import CloseButton from "../CloseButton";
import Icon from "../Icons";

import "../../../assets/scss/components/fts/reassign-modal.scss";

class ReassignModal extends Component {
  render() {
    const {
      isOpen,
      title,
      closeModal,
      confirmModal,
      onClose,
      reassignListing,
      reassignAccount,
      oldAccount,
      newAccount,
    } = this.props;

    return (
      <FTSModal
        {...{ isOpen, title, closeModal, onClose }}
        modalClasses={"fts-reassign-modal"}
        centered={true}
        size={"lg"}
        footerCTA={() => {
          return (
            <div className="reassign-modal-footer">
              <span className="mr-1">
                <Button onClick={closeModal} color="secondary">
                  Cancel
                </Button>
              </span>
              <Button onClick={confirmModal} color="primary">
                Confirm
              </Button>
            </div>
          );
        }}
      >
        <div className="reassign-modal-body">
          {reassignAccount && (
            <Icon name="ReassignAccountConfirmation" size="56" />
          )}
          <span className="reassign-modal-body-wrapper">
            <span className="reassign-modal-title">
              <h3>Reassign confirmation</h3>
              <CloseButton onClick={closeModal} />
            </span>
            {reassignListing && (
              <span className="reassign-modal-message">
                Are you sure you want to reasign from account ID{" "}
                <span style={{ fontWeight: "bold" }}>#{oldAccount?.id}</span>,{" "}
                {oldAccount?.companyName}, {oldAccount?.city},{" "}
                {oldAccount?.state} to account ID{" "}
                <span style={{ fontWeight: "bold" }}>#{newAccount?.id}</span>,{" "}
                {newAccount?.companyName}, {newAccount?.city},{" "}
                {newAccount?.state}?
              </span>
            )}
            {reassignAccount && (
              <span className="reassign-modal-message">
                Please confirm that you want to proceed with this action. If you
                confirm, this account{" "}
                <span style={{ fontWeight: "bold" }}>#{oldAccount?.id}</span>,{" "}
                {oldAccount?.companyName}, {oldAccount?.city},{" "}
                {oldAccount?.state} will be disabled and will become a
                sub-account of account{" "}
                <span style={{ fontWeight: "bold" }}>#{newAccount?.id}</span>,{" "}
                {newAccount?.companyName}, {newAccount?.city},{" "}
                {newAccount?.state}.
              </span>
            )}
          </span>
        </div>
      </FTSModal>
    );
  }
}

export default ReassignModal;
