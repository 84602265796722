import React, { Component } from "react";
import { X } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ListGroup, ListGroupItem, Button } from "reactstrap";
import classnames from "classnames";
import { formatRawDate } from "../../../configs/dateConfig";

class AdsTypesSidebar extends Component {
  addNew = false;

  componentDidUpdate() {
    this.addNew = false;
  }

  render() {
    const { show, handleSidebar, data, onInvoiceDownload } = this.props;

    if (!data) {
      return null;
    }

    return (
      <div
        className={classnames("data-list-sidebar", {
          show: show,
        })}
      >
        <div className="data-list-sidebar-header px-2 d-flex justify-content-between">
          <h4>Invoice: {data.invoiceNumber}</h4>
          <X size={20} onClick={() => handleSidebar(false, true)} />
        </div>

        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation: false }}
        >
          <ListGroup>
            <ListGroupItem>
              <strong>ID</strong>: {data.id}
            </ListGroupItem>
            {!!data.AdId && <ListGroupItem>Ad ID: {data.AdId}</ListGroupItem>}
            <ListGroupItem>
              <strong>Ad Type</strong>: {data.ad_type}
            </ListGroupItem>
            <ListGroupItem>
              <strong>User ID</strong>: {data.UserId}
            </ListGroupItem>
            <ListGroupItem>
              <strong>Status</strong>: {data.status}
            </ListGroupItem>
            <ListGroupItem>
              <strong>Amount</strong>: ${data.amount}
            </ListGroupItem>
            <ListGroupItem>
              <strong>Created at</strong>: {formatRawDate(data.createdAt)}
            </ListGroupItem>
            <ListGroupItem>
              <strong>Updated at</strong>: {formatRawDate(data.updatedAt)}
            </ListGroupItem>
            <ListGroupItem>
              <strong>Renewal</strong>: {formatRawDate(data.renewal)}
            </ListGroupItem>
          </ListGroup>
          <br></br>
          <Button
            type="button"
            className="p-1 float-right"
            color="primary"
            onClick={() => onInvoiceDownload(data.path)}
          >
            Download Invoice
          </Button>
        </PerfectScrollbar>
      </div>
    );
  }
}
export default AdsTypesSidebar;
