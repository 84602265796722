import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAltContact } from "../../../../redux/reducers/v2/altContacts";
import { setAltContactAction } from "../../../../redux/actions/v2/altContacts";
import { altContactEmailValidator } from "../Validators/altContactEmailValidator";
import { altContactMobilePhoneValidator } from "../Validators/altContactMobilePhoneValidator";

const validators = {
  altContactEmail: altContactEmailValidator,
  altContactMobileNumber: altContactMobilePhoneValidator,
};

export default function useAltContactCheckboxValidation(validateField, optIns) {
  const [initialValidationDone, setInitialValidationDone] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const altContact = useSelector(selectAltContact) || {};
  const dispatch = useDispatch();
  const validator = validators[validateField];
  const data = altContact[validateField];

  useEffect(() => {
    const validateData = async () => {
      try {
        await validator.validate({ [validateField]: data });
        setIsValid(true);
      } catch (e) {
        setIsValid(false);
      }
    };

    if (!data) return setIsValid(false);
    validateData().then(() => setInitialValidationDone(true));
  }, [data, validator]);

  useEffect(() => {
    if (isValid || !initialValidationDone) return;
    if (!optIns.find((name) => altContact[name])) return;
    let altContactData = { ...altContact };
    optIns.forEach((name) => (altContactData[name] = false));
    dispatch(setAltContactAction(altContactData));
  }, [isValid, dispatch, initialValidationDone, altContact]);

  return isValid;
}
