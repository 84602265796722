import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import "../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss";
import "../../../assets/scss/components/fts/form-fields.scss";
import "../../../assets/scss/pages/invoices-page.scss";
import "flatpickr/dist/themes/light.css";

import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";
import ActionSidebar from "../../../components/fts/action-bar/action-sidebar";
import paymentTypes from "../../../data/payment-types";
import Icon from "../../../components/fts/Icons";
import Flatpickr from "react-flatpickr";
import { FormGroup, Row, Col, Card, CardBody, Spinner } from "reactstrap";

import {
  searchPayments,
  setInvoicesListTest,
  setSelectedInvoices,
  setAllInvoicesCount,
  resetDiscount,
  setCreditCardData,
  setPaymentsListTest,
} from "../../../redux/actions/invoices";
import { forceCreatePayment } from "../../../redux/actions/payments";
import { setPromoCode } from "../../../redux/actions/promocodes";

import { setAccountSettingsState } from "../../../redux/actions/accounts";

import { defineInvoicesColumns } from "./payments-columns";
import moment from "moment";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { FaRegCopy } from "react-icons/fa";

import ConfirmationModal from "../../../components/fts/confirmation-modal";
import AccountPayments from "../account-settings/AccountPayments";
import ListingPayments from "../listing-settings/ListingPayments";
import { searchAccountPayments } from "../../../redux/actions/accountPayments";
import { searchListingPayments } from "../../../redux/actions/listingPayments";

let searchFields = [
  "statusForString",
  "owner.assignedAdmins.firstName",
  "owner.assignedAdmins.lastName",
  "invoiceNumber",
  "totalForSort",
  "dueAtString",
  "paidAtString",
  "payments.paidMethod",
  "payments.paidSource",
  "owner.companyName",
  "ownerIdForSort",
  "owner.city",
  "owner.state",
  "listingIdForSort",
  "activities.activityNote",
];
let defaultSort = [{ colId: "payments.updatedAt", sort: "ASC" }];
class PaymentsList extends Component {
  state = {
    activeTab: "0-30",
    from: 0,
    to: 30,
    firstTabCount: 0,
    secondTabCount: 0,
    thirdTabCount: 0,
    inProgress: false,
    filterChips: [
      {
        chipLabel: "All",
        color: "#32383A",
        chipNumber: 0,
        active: true,
        onClickChip: this.onClickAll.bind(this),
      },
      {
        chipLabel: "Paid",
        color: "#57C990",
        chipNumber: 0,
        active: false,
        onClickChip: this.onClickChip.bind(this),
      },
      {
        chipLabel: "Unsuccessful",
        color: "#647074",
        chipNumber: 0,
        active: false,
        onClickChip: this.onClickChip.bind(this),
      },
    ],
    chipLoading: false,
    filter: [],

    defaultColDef: {
      sortable: true,
    },
    overlayShown: false,

    searchVal: "",
    popoverTarget: "",
    popoverData: null,
    dateRange: null,
    apisReady: false,
    popoverOpen: false,
    excludeInactive: false,
    currentRow: null,
    isOpen: false,
    sidebar: false,
    action: "",
    actionItem: null,
    updating: false,

    pageSize: 10,
    page: 1,
    pageCount: 0,
    totalRecordsPayments: 0,
    sort: defaultSort,
    columnDefs: [],
    totalPaymentsAmount: 0,
    statusFilter: [
      {
        bool: {
          should: [
            { term: { statusForSort: "Paid" } },
            { term: { statusForSort: "Unsuccessful" } },
          ],
        },
      },
    ],
    searchFields: [...searchFields],

    paymentStep: "",
    lastPaymentModalTitle: "",
    isOpenPaymentModal: false,
  };

  pageSizes = [10, 25, 50, 100];
  tableGroupRef = createRef();
  sidebarChild = createRef();
  dateRef = createRef();
  invoicesRef = createRef();
  formikSidebarRef = createRef();
  detailsRef = createRef();

  setDetailsRef = (ref) => {
    this.detailsRef = ref;
  };

  componentDidMount() {
    this.invoicesRef = this;
    if (this.props.invoicesRef && typeof this.props.invoicesRef === "function")
      this.props.invoicesRef(this);
    const { pageSize, page, filter, sort, statusFilter, filterChips } =
      this.state;
    this.setState({ inProgress: true });
    if (this.props.accountId) {
      filter.push({
        term: { userId: this.props.accountId },
      });
      // filter.push({
      //   range: {
      //     dueAt: {
      //       lte:
      //         moment().subtract(-1, "days").toISOString().split("T")[0] +
      //         "T00:00:00Z",
      //       gte:
      //         moment().subtract(30, "days").toISOString().split("T")[0] +
      //         "T00:00:00Z",
      //     },
      //   },
      // });
    } else if (this.props.listingId) {
      filter.push({
        term: { adId: this.props.listingId },
      });
      // filter.push({
      //   range: {
      //     dueAt: {
      //       lte:
      //         moment().subtract(-1, "days").toISOString().split("T")[0] +
      //         "T00:00:00Z",
      //       gte:
      //         moment().subtract(30, "days").toISOString().split("T")[0] +
      //         "T00:00:00Z",
      //     },
      //   },
      // });
    } else {
      filter.push({
        range: {
          "payments.createdAt": {
            lte:
              moment().subtract(-1, "days").toISOString().split("T")[0] +
              "T00:00:00Z",
            gte:
              moment().subtract(30, "days").toISOString().split("T")[0] +
              "T00:00:00Z",
          },
        },
      });
    }

    let searchPayload = {
      page: page,
      resultsPerPage: pageSize,
      searchString: this.state.searchVal,
      filter: this.state.filter,
      sort: sort,
      statusFilter: statusFilter,
    };
    if (this.props.accountId || this.props.listingId) {
      searchPayload.page = 1;
      searchPayload.resultsPerPage = 1000000;
    }

    this.props
      .searchPayments(searchPayload)
      .then((invoices) => {
        let newFilterChips = filterChips.map((e) => ({
          ...e,
          chipNumber:
            invoices.data.result[
              `${e.chipLabel.toLowerCase().replace(" ", "")}Count`
            ],
        }));
        newFilterChips[0].chipNumber = invoices?.data?.result?.nbHits;

        this.props.setPaymentsListTest({
          rows: invoices?.data?.result?.hits,
          count: invoices?.data?.result?.nbHits,
          allPaymentsCount: invoices?.data?.result?.allPaymentsCount,
        });

        if (this.props.invoiceToOpen) {
          let preselectedInvoice = this.props.paymentsData.find(
            (invoice) => invoice.id === this.props.invoiceToOpen,
          );

          if (preselectedInvoice) {
            this.preselectInvoice(preselectedInvoice);
          }
        }

        this.setState({
          inProgress: false,
          pageCount:
            this.props.accountId || this.props.listingId
              ? 1
              : Math.ceil(invoices?.data?.result?.nbHits / pageSize),
          pageSize:
            this.props.accountId || this.props.listingId
              ? 1000000
              : this.state.pageSize,
          totalRecordsPayments: invoices?.data?.result?.nbHits,
          totalPaymentsAmount: invoices.data?.result?.totalInvoiceAmount || 0,
          firstTabCount: invoices.data?.result?.from0to30Count || 0,
          secondTabCount: invoices.data?.result?.from30to90Count || 0,
          thirdTabCount: invoices.data?.result?.from90to365Count || 0,
          filterChips: newFilterChips,
          columnDefs: defineInvoicesColumns(
            this.collectDataForCopy,
            this.props.accountId,
            this.props.listingId,
            this.refreshTable,
            this.props.invoiceToOpen,
          ),
        });
      })
      .catch((err) => {
        console.log("ERROR:", err);
        this.setState({ inProgress: false });
      });
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  unselectAllInvoices = () => {
    this.props.setSelectedInvoices({ selectedInvoices: [] });
    this.gridApi.deselectAll();
  };

  preselectInvoice = (preselectedInvoice) => {
    this.openInvoiceSidebar(preselectedInvoice);
  };

  getSelectedInvoices = () => {
    let selectedInvoices = this.gridApi?.getSelectedNodes().map((gridNode) => {
      return { ...gridNode.data, isSelected: true };
    });

    this.props.setSelectedInvoices({ selectedInvoices });
  };

  onClickChip(selectedFilter) {
    if (this.state.chipLoading) return;
    let selectedChip = {
      ...selectedFilter,
      active: !selectedFilter.active,
    };

    this.setState({
      page: 1,
    });

    let newChips = this.state.filterChips.map((chip) =>
      chip.chipLabel !== selectedChip.chipLabel ? chip : selectedChip,
    );

    if (selectedFilter.chipLabel) newChips[0].active = false;

    let statusFilter = [
      {
        bool: {
          should: [],
        },
      },
    ];
    for (let chip of newChips) {
      if (chip.active) {
        if (chip.chipLabel === "Paid") {
          statusFilter[0].bool.should.push({
            term: { statusForSort: "Paid" },
          });
        }
        if (chip.chipLabel === "Unsuccessful") {
          statusFilter[0].bool.should.push({
            term: { statusForSort: "Unsuccessful" },
          });
        }
      }
    }

    if (
      statusFilter[0].bool.should.length === 2 ||
      statusFilter[0].bool.should.length === 0
    ) {
      return this.onClickAll();
    }

    this.setState(
      {
        filterChips: newChips,
        statusFilter,
        chipLoading: true,
        page: 1,
      },
      () => {
        this.onSubmitQuery();
      },
    );
  }

  onClickAll() {
    if (this.state.chipLoading) return;
    let filterChips = this.state.filterChips
      .filter((chip) => chip.chipLabel !== "All")
      .map((chip) => {
        return { ...chip, active: false };
      });

    this.setState(
      {
        filterChips: [
          { ...this.state.filterChips[0], active: true },
          ...filterChips,
        ],
        statusFilter: [
          {
            bool: {
              should: [
                { term: { statusForSort: "Paid" } },
                { term: { statusForSort: "Unsuccessful" } },
              ],
            },
          },
        ],
        chipLoading: true,
        page: 1,
      },
      () => this.onSubmitQuery(),
    );
  }

  filterSize = (type, val) => {
    this.setState(
      {
        pageSize: val,
        page: 1,
      },
      () => {
        this.onSubmitQuery();
      },
    );
  };

  componentWillUnmount() {
    this.props.setSelectedInvoices({
      selectedInvoices: [],
    });
  }

  onCancelSelection() {
    this.props.setSelectedInvoices({
      selectedInvoices: [],
    });

    this.gridApi.deselectAll();
  }

  updateSearchQuery = (type, val) => {
    clearTimeout(this.debounce);
    let newSearchFields = [...searchFields];
    if (this.props.accountId || this.props.listingId) {
      newSearchFields = newSearchFields.filter(
        (e) => e !== "owner.companyName",
      );
    }
    this.setState(
      {
        searchVal: val,
        page: 1,
        // statusFilter: [],
        searchFields: newSearchFields,
      },
      () => {
        this.debounce = setTimeout(() => this.onSubmitQuery(), 350);
      },
    );
  };

  formatNumber = (number, decimal = 2) => {
    if (!decimal) return new Intl.NumberFormat().format(number);
    return Number(number.toFixed(decimal))
      .toFixed(decimal)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (localStorage.getItem("invoicesColumnState") !== null) {
      this.gridColumnApi.setColumnState(
        JSON.parse(localStorage.getItem("invoicesColumnState")),
      );
    }
  };

  resetApi = () => {
    this.gridApi = null;
  };

  onRowSelected = (event) => {
    console.log(event, "EVENT");
  };

  refreshTable = () => {
    console.log("RESRESH TABLE");
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    this.setState({ overlayShown: true });
    setTimeout(() => {
      if (this.props.accountId) return this.refreshAccountPaymentsTable();
      if (this.props.listingId) return this.refreshListingPaymentsTable();

      this.onSubmitQuery();
    }, 1000);
  };
  onSubmitQuery = () => {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    this.setState({ overlayShown: true });

    let searchPayload = {
      page: this.state.page,
      resultsPerPage: this.state.pageSize,
      searchString: this.state.searchVal,
      filter: this.state.filter,
      sort: this.state.sort,
      statusFilter: this.state.statusFilter,
      searchFields: this.state.searchFields,
    };
    if (this.props.accountId || this.props.listingId) {
      searchPayload.page = 1;
      searchPayload.resultsPerPage = 100000;
    }

    this.props.searchPayments(searchPayload).then((invoices) => {
      let newFilterChips = this.state.filterChips.map((e) => ({
        ...e,
        chipNumber:
          invoices.data.result[
            `${e.chipLabel.toLowerCase().replace(" ", "")}Count`
          ],
      }));

      newFilterChips[0].chipNumber =
        invoices.data?.result?.paidCount +
        invoices.data?.result?.unsuccessfulCount;

      this.props.setPaymentsListTest({
        rows: invoices?.data?.result?.hits,
        count: invoices?.data?.result?.nbHits,
        totalPaymentsAmount: invoices.data?.result?.totalInvoiceAmount || 0,
        firstTabCount: invoices.data?.result?.from0to30Count || 0,
        secondTabCount: invoices.data?.result?.from30to90Count || 0,
        thirdTabCount: invoices.data?.result?.from90to365Count || 0,
        paidCount: invoices.data?.result?.paidCount || 0,
        unsuccessfulCount: invoices.data?.result?.unsuccessfulCount || 0,
        allPaymentsCount: invoices?.data?.result?.allPaymentsCount,
      });

      this.setState({
        filterChips: newFilterChips,
        chipLoading: false,
        overlayShown: false,
        totalPaymentsAmount: invoices.data?.result?.totalInvoiceAmount || 0,

        pageCount:
          this.props.accountId || this.props.listingId
            ? 1
            : Math.ceil(invoices?.data?.result?.nbHits / this.state.pageSize),

        pageSize:
          this.props.accountId || this.props.listingId
            ? 1000000
            : this.state.pageSize,

        // pageCount: Math.ceil(
        //   invoices?.data?.result?.nbHits / this.state.pageSize
        // ),
        totalRecordsPayments: invoices?.data?.result?.nbHits,
      });
      if (this.gridApi) this.gridApi.hideOverlay();
    });
  };

  refreshAccountPaymentsTable = () => {
    const { searchAccountPayments, accountPayments, accountId } = this.props;

    const {
      currentPage,
      resultsPerPage,
      searchString,
      activeSubTab,
      sortModel,
    } = accountPayments;

    let payload = {
      page: currentPage,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { userId: accountId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    searchAccountPayments(payload).then(() => {
      this.gridApi.hideOverlay();
      this.setState({
        overlayShown: false,
      });
    });
  };

  refreshListingPaymentsTable = () => {
    const { searchListingPayments, listingPayments, listingId } = this.props;

    const {
      currentPage,
      resultsPerPage,
      searchString,
      activeSubTab,
      sortModel,
    } = listingPayments;

    let payload = {
      page: currentPage,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { adId: listingId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    searchListingPayments(payload)
      .then(() => {
        this.gridApi.hideOverlay();
        this.setState({
          overlayShown: false,
        });
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  saveInvoicesColumns = () => {
    let invoicesColumnState = this.gridColumnApi.getColumnState();
    localStorage.setItem(
      "invoicesColumnState",
      JSON.stringify(invoicesColumnState),
    );
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ page: pageNum }, () => {
      this.onSubmitQuery();
    });
  };

  openPaymentSidebar = (data) => {
    this.callToAction("payment_overview", false, data);
  };

  openReceiptSidebar = (data) => {
    this.callToAction("receipt_details", false, data);
  };

  openInvoiceSidebar = (data) => {
    this.callToAction("invoice_details", false, data);
  };

  callToAction = (action, update, item) => {
    if (!action) return;
    this.setState({
      action,
      actionItem: item,
      sidebar: true,
      updating: !!update,
    });
  };

  clearFlatPickr = () => {
    this.dateRef.current.flatpickr.clear();
    this.updateActiveTab("0-30");
  };

  handleSidebar = (bool, update, closeWithoutPrompt) => {
    if (
      this.detailsRef?.state?.downloadingInProgress ||
      this.detailsRef?.state?.downloadingRecipientInProgress ||
      this.detailsRef?.state?.templateLoading
    ) {
      return;
    }
    if (this.state.action === "invoice_details") {
      this.unselectAllInvoices();
      this.props.setSelectedInvoices({
        selectedInvoices: [],
      });
    }
    if (bool === false && this.formikSidebarRef?.dirty && !closeWithoutPrompt) {
      return this.setState({ isOpen: true });
    } else {
      this.setState({ sidebar: bool, updating: !!update });
    }
  };

  closeSideOutAction = () => {
    this.setState({ sidebar: false, update: false, isOpen: false });
  };

  setPaymentStep = (paymentStep) => {
    this.setState({
      paymentStep: paymentStep,
    });
  };

  setLastPaymentModalTitle = (title) => {
    this.setState({
      lastPaymentModalTitle: title,
    });
  };

  toggleReassignListingModal = () => {
    if (!this.state.isOpenReassignListingModal) {
      this.setState({
        page: 1,
        totalRecords: 0,
        pageCount: 0,
        accounts: [],
        accountSearchValue: "",
        mainAccount: null,
      });
    }
    this.setState(
      {
        isOpenReassignListingModal: !this.state.isOpenReassignListingModal,
      },
      () => {
        this.updateAccountSearchResults();
      },
    );
  };

  toggleReassignConfirmationModal = () => {
    this.setState({
      isOpenReassignConfirmationModal:
        !this.state.isOpenReassignConfirmationModal,
    });
  };

  togglePaymentModal = (check) => {
    this.props.setCreditCardData(null);
    if (!check) {
      this.setState({
        isOpenPaymentModal: !this.state.isOpenPaymentModal,
      });
    } else {
      this.confirmPaymentConfirmationModal();

      if (this.state.paymentStep === "paymentProccessed") {
        this.props.setSelectedInvoices({
          selectedInvoices: [],
        });
        this.unselectAllInvoices();
      }
    }
  };

  confirmPaymentConfirmationModal = () => {
    this.refreshTable();

    this.setState({
      paymentStep: null,
      isOpenPaymentModal: false,
    });
    this.props.setPromoCode(null);
    this.props.resetDiscount();
  };

  setFormikSidebarRef = (ref) => {
    this.formikSidebarRef = ref;
  };

  resetFormikForm = () => {
    this.formikSidebarRef.resetForm();
  };

  onConfirmAchPayment = () => {
    this.setState({
      paymentStep: "paymentProccessed",
      lastPaymentModalTitle: "Payment logged",
    });

    let { amount, number, paymentType } = this.state.manuallyFormValues;
    let paidMethod = paymentTypes.find((e) => e.value === paymentType);
    this.props
      .forceCreatePayment({
        invoices: this.props.selectedInvoices,
        status: "succeeded",
        total: amount,
        sourceId: number,
        paidSource:
          paymentType === 3 ? paidMethod.label.toLowerCase() : "manually",
        paidMethod: paymentType === 3 ? "card" : paidMethod.label.toLowerCase(),
        promoCode: this.props.promoCode?.id ? this.props.promoCode.id : null,
      })
      .then(() => {
        this.props.setPromoCode(null);
      })
      .catch((e) => {
        console.log("ERROR: ", e);
      });
  };

  handleDateChange = (values) => {
    if (values.length < 2) return;

    let gte = new Date(values[0]);
    let lte = new Date(values[1]);
    lte.setDate(lte.getDate() + 1);
    //2020-08-27
    let generateFate = (date) => {
      let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      let month =
        date.getMonth() <= 8 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

      return `${date.getFullYear()}-${month}-${day}T00:00:00Z`;
    };

    let newGte = generateFate(gte);
    let newLte = generateFate(lte);

    let filter = [...this.state.filter];
    let i = filter.findIndex((e) => {
      return e["range"];
    });
    if (i !== -1) {
      filter[i] = {
        range: {
          "payments.createdAt": {
            gte: newGte,
            lte: newLte,
          },
        },
      };
    } else {
      filter.push({
        range: {
          "payments.createdAt": {
            gte: newGte,
            lte: newLte,
          },
        },
      });
    }
    this.setState(
      {
        filter,
      },
      () => this.onSubmitQuery(),
    );
  };

  updateActiveTab = (item) => {
    if (this.dateRef?.current) this.dateRef.current.flatpickr.setDate([]);

    let values = [];
    values[1] = moment().subtract(item.split("-")[0], "days"); //gte
    values[0] = moment().subtract(item.split("-")[1], "days"); //lte

    this.setState({ activeTab: item, page: 1 }, () => {
      this.handleDateChange(values);
    });
  };

  onRowClicked = ({ data, api, node }) => {
    if (this.state.overlayShown) return;
    this.unselectAllInvoices();
    node.setSelected(true);
    // return;
    let isAccount = window.location.href
      .split("/")
      .find((e) => e === "accounts");

    let isListing = window.location.href
      .split("/")
      .find((e) => e === "listings");

    if (
      api.focusedCellController.focusedCellPosition.column.colId ===
        "payments.paidSourceForSort" ||
      api.focusedCellController.focusedCellPosition.column.colId ===
        "payments.paidMethodForSort"
    ) {
      return;
    }
    if (isAccount || isListing) {
      if (data.statusForString !== "Paid") {
        this.props.setSelectedInvoices({
          selectedInvoices: [
            {
              ...data,
              isSelected: true,
            },
          ],
        });
      }
      this.openReceiptSidebar(data);
      return;
    }

    this.props.setAccountSettingsState({
      accountId: "" + data.userId,
      activeTab: 2,
    });

    return this.props.history.push({
      pathname: `/accounts/${data.userId}`,
      search: `?invoiceId=${data.id}`,
    });
  };

  onSortChanged = (params) => {
    this.setState({ sort: params.api.getSortModel() });
    return this.onSubmitQuery();
  };
  render() {
    const {
      activeTab,
      firstTabCount,
      secondTabCount,
      thirdTabCount,
      pageSize,
      page,
      pageCount,
      columnDefs,
      searchVal,
      defaultColDef,
      totalRecordsPayments,
      filterChips,
      totalPaymentsAmount,
      inProgress,
      sidebar,
      action,
      updating,
      actionItem,
      isOpenPaymentModal,
    } = this.state;

    const { accountId, listingId } = this.props;

    let tabs = [
      {
        label: `Last 30 days (${this.formatNumber(firstTabCount, 0)})`,
        type: "0-30",
      },
      {
        label: `30 - 90 days (${this.formatNumber(secondTabCount, 0)})`,
        type: "30-90",
      },
      {
        label: `90 - 365 days (${this.formatNumber(thirdTabCount, 0)})`,
        type: "90-365",
      },
    ];

    return (
      <div className="invoices-wrapper invoices-flex-wrapper">
        <Row className="app-user-list">
          <Col
            sm="12"
            style={{
              paddingLeft: 0,
            }}
          >
            {!accountId && !listingId && (
              <Card>
                {this.props.paymentsData && !inProgress ? (
                  <CardBody>
                    {!accountId && !listingId ? (
                      <div className="invoices-date-filter">
                        <h2>Payments</h2>
                        <div className="d-flex ">
                          <div className="header-invoices-buttons">
                            <div className="nav">
                              {tabs.map((itm) => {
                                return (
                                  <button
                                    key={itm.type}
                                    className={`tab ${
                                      activeTab === itm.type
                                        ? "selected"
                                        : "unselected"
                                    }`}
                                    onClick={() => {
                                      this.updateActiveTab(itm.type);
                                    }}
                                  >
                                    {itm.label}
                                  </button>
                                );
                              })}
                            </div>
                          </div>

                          <div className="range">
                            <FormGroup className="position-relative has-icon-right">
                              <div className="fts-date-and-time">
                                <div
                                  id="datepickerWrapperFrom"
                                  className="u-datepicker input-group"
                                >
                                  <Flatpickr
                                    ref={this.dateRef}
                                    // value={
                                    //   fromDate &&
                                    //   toDate && [
                                    //     moment(fromDate).toISOString(),
                                    //     moment(toDate).toISOString(),
                                    //   ]
                                    // }
                                    placeholder="Select date range"
                                    className="fts-date-picker form-control date-picker"
                                    options={{
                                      mode: "range",
                                      dateFormat: "M d, Y",
                                      locale: {
                                        rangeSeparator: " - ",
                                      },
                                    }}
                                    onChange={(values) => {
                                      if (values.length === 2) {
                                        this.setState({ activeTab: "" });
                                      }
                                      this.setState(
                                        {
                                          page: 1,
                                        },
                                        () => {
                                          this.handleDateChange(values);
                                        },
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="form-control-position">
                                <Icon name="Calendar" size={17} />
                              </div>

                              {this.dateRef.current?.flatpickr.input.value ? (
                                <div
                                  className="form-control-position clear-calendar-button"
                                  onClick={() => {
                                    if (this.dateRef?.current?.flatpickr) {
                                      this.clearFlatPickr();
                                    }
                                  }}
                                >
                                  <Icon name="ClearCalendar" size={16} />
                                </div>
                              ) : null}
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="result-title-payments">Payments</p>
                    )}
                    <FTSDataTable
                      noPagination={
                        this.props.accountId || this.props.listingId
                      }
                      totalAmount={this.formatNumber(totalPaymentsAmount)}
                      isSearch={false}
                      longSearch
                      accountId={accountId}
                      listingId={listingId}
                      getSelectedInvoices={this.getSelectedInvoices}
                      searchPlaceholder="Search by Invoice #, Company Name, City and State..."
                      pageSizes={this.pageSizes}
                      filterChips={filterChips}
                      totalRecords={totalRecordsPayments}
                      rowSelection="multiple"
                      currentPageSize={pageSize}
                      searchValType={"searchVal"}
                      pageSizePropName={"pageSize"}
                      searchVal={searchVal}
                      currentPage={page}
                      pageCount={pageCount}
                      rowData={this.props.paymentsData}
                      onGridReadyInit={this.onGridReady}
                      saveState={this.saveInvoicesColumns}
                      filterSize={this.filterSize}
                      updateSearchQuery={this.updateSearchQuery}
                      onPageChange={this.onPageChanged}
                      resetApi={this.resetApi}
                      onRowClicked={this.onRowClicked}
                      columnDefs={columnDefs}
                      onSortChanged={this.onSortChanged}
                      tableName="invoices"
                      invoicesTab
                      withinCard
                      {...{
                        defaultColDef,
                      }}
                    />
                    {/* {this.props.selectedInvoices.length > 0 && (
                    <div className="FTS-selected-invoices-card">
                      <span>
                        {this.props.selectedInvoices.length} invoices selected
                      </span>
                      <div className="FTS-selected-invoices-buttons">
                        <Button
                          type="button"
                          className="ml-1"
                          color="tertiary"
                          outline
                          onClick={() => {
                            this.onCancelSelection();
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          type="button"
                          color="primary"
                          onClick={() => {
                            this.openPaymentSidebar("data");
                          }}
                        >
                          Send/Collect payment
                        </Button>
                      </div>
                    </div>
                  )} */}
                  </CardBody>
                ) : (
                  <Spinner size="sm" className="m-1" />
                )}
              </Card>
            )}

            {accountId && (
              <AccountPayments
                accountId={this.props.accountId}
                onRowClicked={this.onRowClicked}
                onGridReadyInit={this.onGridReady}
                collectDataForCopy={this.collectDataForCopy}
                invoiceToOpen={this.props.invoiceToOpen}
                refreshTable={this.refreshTable}
              />
            )}

            {listingId && (
              <ListingPayments
                adId={this.props.listingId}
                onRowClicked={this.onRowClicked}
                onGridReadyInit={this.onGridReady}
                collectDataForCopy={this.collectDataForCopy}
                invoiceToOpen={this.props.invoiceToOpen}
                refreshTable={this.refreshTable}
              />
            )}
            <ContextMenu
              style={{ minWidth: 250, zIndex: 1000 }}
              id="contextMenuPayments"
              preventHideOnScroll={false}
            >
              <MenuItem
                onClick={() =>
                  navigator.clipboard.writeText(this.state.collectedDataForCopy)
                }
              >
                <FaRegCopy size={18} className="copy" />
                <span className={"ml-1"}>Copy</span>
              </MenuItem>
            </ContextMenu>

            <ActionSidebar
              setDetailsRef={this.setDetailsRef}
              openReceiptSidebar={this.openReceiptSidebar}
              invoicesRef={this.invoicesRef}
              gridApi={this.gridApi}
              show={sidebar}
              action={action}
              updating={updating}
              actionItem={actionItem}
              handleSidebar={this.handleSidebar}
              promotionType="accountPromotion"
              noMarker={action === "payment_overview"}
              callToAction={this.callToAction}
              setPaymentStep={this.setPaymentStep}
              setLastPaymentModalTitle={this.setLastPaymentModalTitle}
              onSubmitQuery={this.onSubmitQuery}
              accountInfo={this.props.accountData}
            />

            <ConfirmationModal
              title="Unsaved Changes"
              message="Are you sure you want to proceed with this action?"
              closeModal={() => {
                this.togglePaymentModal();
              }}
              confirmModal={this.confirmPaymentConfirmationModal}
              isOpen={isOpenPaymentModal}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    paymentsData: state.invoices.paymentsData,
    totalRecordsPayments: state.invoices.totalRecordsPayments,
    selectedInvoices: state.invoices.selectedInvoices,
    totalAmount: state.invoices.totalAmount,
    promoCode: state.promoCodes.promoCode,
    discountAmount: state.invoices.discountAmount,
    accountPayments: state.accountPayments,
    listingPayments: state.listingPayments,
  };
};

export default connect(mapStateToProps, {
  searchPayments,
  setInvoicesListTest,
  setSelectedInvoices,
  setAccountSettingsState,
  setAllInvoicesCount,
  setPromoCode,
  resetDiscount,
  setCreditCardData,
  forceCreatePayment,
  setPaymentsListTest,
  searchListingPayments,
  searchAccountPayments,
})(PaymentsList);
