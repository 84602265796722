import { rfc3986DecodeURIComponent } from "./encode-rfc";

export const formatImageLink = (fileUrl) => {
  fileUrl = fileUrl.replace(process.env.REACT_APP_IMAGE_URL + "/", "");
  const awsLink = process.env.REACT_APP_IMAGE_URL + "/";
  return awsLink + fileUrl.replace(/\.\.\//gi, "");
};

export const checkPermissions = (
  permissions = [],
  userPermissions = [],
  subPermissions = [],
) => {
  let userPermission;
  let passed = true;
  for (let permission of permissions) {
    userPermission = userPermissions.find(
      (e) => e.id === permission && e.active,
    );
    if (!userPermission) passed = false;
  }
  //If all permissions passed then check sub permissions :)
  if (passed) {
    for (let subPermission of subPermissions) {
      let userSubPermission = userPermission.subCat.find(
        (e) => e === subPermission,
      );
      if (!userSubPermission) passed = false;
    }
  }

  return passed;
};

export const generateListingSlug = (id, name = "", city = "", state = "") => {
  if (!id || !name || !city || !state) return;
  name = rfc3986DecodeURIComponent(name);
  name = name.replace(/[^\w\s]/gi, "");
  let url = `${name.trim()}-${city.trim()}-${state.trim()}-${id}`
    .trim()
    .replace(/ /g, "-")
    .replace(/--/g, "-")
    .toLowerCase();

  return url;
};
