import React, { PureComponent } from "react";
import { Field, ErrorMessage } from "formik";
import classnames from "classnames";
import MaskedInput from "react-text-mask";
import { FormGroup, Label, CustomInput } from "reactstrap";
import FormikInputValue from "../formik-input-value";

class FormikMaskedInput extends PureComponent {
  render() {
    const {
      name,
      label,
      className,
      noMargin,
      disabled,
      labelId,
      mask,
      placeholder,
      required,
      optInID,
      labelOptIn,
      ...rest
    } = this.props;
    return (
      <FormGroup className={classnames(className, { "mb-0": !!noMargin })}>
        {!!label && (
          <div className="fts-optin">
            <div className="fts-optin-label">
              <Label className={disabled ? "disabled" : ""} for={labelId}>
                {required && !disabled ? `${label} *` : label}
              </Label>
            </div>
            <div className="fts-optin-switch">
              {!disabled && labelOptIn && optInID && (
                <Field name={optInID}>
                  {({ field: { value }, form }) => {
                    return (
                      <>
                        <CustomInput
                          checked={value}
                          type="switch"
                          id={optInID}
                          {...{ disabled }}
                          onChange={(e) => {
                            form.setFieldValue(optInID, e.target.checked);
                          }}
                        >
                          <span className="switch-label">{labelOptIn}</span>
                        </CustomInput>
                      </>
                    );
                  }}
                </Field>
              )}
            </div>
          </div>
        )}
        <Field {...{ name }}>
          {({ field, form: { touched, errors } }) => (
            <>
              {disabled ? (
                <FormikInputValue>{field.value || "-"}</FormikInputValue>
              ) : (
                <MaskedInput
                  {...field}
                  {...{ mask, disabled }}
                  id={name}
                  type="text"
                  className={classnames("form-control", {
                    "invalid-formik-input":
                      errors[field.name] && touched[field.name],
                  })}
                  autoComplete="new-password"
                  placeholder={disabled ? "-" : placeholder}
                  {...rest}
                />
              )}
            </>
          )}
        </Field>
        <ErrorMessage
          name={name}
          render={(msg) => <div className="formik-validation-error">{msg}</div>}
        />
      </FormGroup>
    );
  }
}

export default FormikMaskedInput;
