/* eslint-disable indent */
import * as Yup from "yup";
import { urlValidate } from "../components/fts-components-v2/dist/types/validators/global";

const schema = {
  firstName: Yup.string().trim().required("This field is required"),
  email: Yup.string().email("Invalid email").required("This field is required"),
  additionalEmail: Yup.string().email("Invalid email"),
  primaryMarketingOptIn: Yup.bool(),
  primaryUpdatesOptIn: Yup.bool(),
  primaryRenewalsOptIn: Yup.bool(),
  additionalMarketingOptIn: Yup.bool(),
  additionalUpdatesOptIn: Yup.bool(),
  additionalRenewalsOptIn: Yup.bool(),
  lastName: Yup.string().trim().required("This field is required"),
  city: Yup.string().trim().required("This field is required"),
  companyName: Yup.string().trim().required("This field is required"),
  address: Yup.string().trim().required("This field is required"),
  zip: Yup.number()
    .required("This field is required")
    .typeError("Invalid zip code"),
  altContacts: Yup.array().of(
    Yup.object().shape({
      isOpen: Yup.bool(),
      name: Yup.string(),
      id: Yup.mixed(),
      firstName: Yup.string().trim().required("Field is required"),
      lastName: Yup.string().trim().required("Field is required"),
      email: Yup.string().email("Invalid email"),
      jobTitle: Yup.string(),
      mobileNumber: Yup.string(),
      phoneNumber: Yup.string(),
      phoneNumberSecondary: Yup.string(),
      noteType: Yup.mixed()
        .when(
          ["adminNotes"],
          {
            is: (val) => val && val.length > 0,
            then: Yup.mixed(),
          },
          ["adminNotes", "noteType"],
        )
        .nullable(),
      adminNotes: Yup.string(),
    }),
  ),
  userType: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select type for this account")
      : Yup.string()
          .nullable()
          .required("Select account type for this account"),
  ),
  adminNote: Yup.string(),
  adminNoteType: Yup.mixed()
    .when(
      ["adminNote"],
      {
        is: (val) => val && val.length > 0,
        then: Yup.mixed().required("Note type is required"),
      },
      ["adminNote", "adminNoteType"],
    )
    .nullable(),
  adminStatus: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select account label")
      : Yup.number().nullable().required("Select account label"),
  ),
  state: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select state")
      : Yup.string().nullable().required("Select state"),
  ),
  accountLink: Yup.number().nullable(),
  website: urlValidate,
  website2: urlValidate,
  facebook: urlValidate,
  linkedIn: urlValidate,
  google: urlValidate,
  twitter: urlValidate,
  quickbook: urlValidate,
  other: urlValidate,
};

export const createSchema = Yup.object().shape(schema);

export const editSchema = Yup.object().shape({
  status: Yup.lazy((val) =>
    typeof val === "object" && val !== null
      ? Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required("Select account status")
      : Yup.number().nullable().required("Select account status"),
  ),
  ...schema,
});
