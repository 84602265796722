import React, { Component } from "react";
import { Button } from "reactstrap";
import { nanoid } from "nanoid";

import {
  createAccountAltContact,
  updateAccountAltContact,
  removeAccountInfoAltContact,
} from "../../../redux/actions/users";
import AltItem from "./item";
import "../../../assets/scss/alt-contacts.scss";
import { FieldArray } from "formik";
import { connect } from "react-redux";

class AltContacts extends Component {
  shouldComponentUpdate(prevProps) {
    return (
      prevProps.editMode !== this.props.editMode ||
      prevProps.list !== this.props.list ||
      JSON.stringify(prevProps.list) !== JSON.stringify(this.props.list) ||
      prevProps.errors !== this.props.errors
    );
  }

  addNewAltContact = (arrayHelper) => {
    const nanoID = nanoid(3);
    arrayHelper.insert(this.props.list.length, {
      isOpen: true,
      name: "New alt contact",
      id: nanoID,
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      mobileNumber: "",
      phoneNumber: "",
      phoneNumberSecondary: "",
      noteType: "",
      adminNotes: "",
    });
  };

  createNewAltContact = (id, index) => {
    const altContact = this.props.list.find((itm) => itm.id === id);
    if (!altContact) return;

    const {
      firstName,
      lastName,
      email,
      adminNotes,
      noteType,
      jobTitle,
      phoneNumberSecondary,
      phoneNumber,
      position,
      mobileNumber,
      primaryMarketingOptIn,
      primaryRenewalsOptIn,
      primaryUpdatesOptIn,
    } = altContact;

    const payload = {
      firstName,
      lastName,
      email,
      position: position ? position : index + 1,
      note: adminNotes,
      noteType: noteType ? [noteType.value] : "",
      jobTitle,
      userId: this.props.accountID,
      phoneNumberSecondary,
      phoneNumber,
      mobileNumber,
      primaryMarketingOptIn,
      primaryRenewalsOptIn,
      primaryUpdatesOptIn,
    };
    return this.props.createAccountAltContact(payload);
  };

  removeAltContact = (id) => {
    this.props.removeAltContacts(id);
    return this.props.removeAccountInfoAltContact(id);
  };

  updateAltContactAction = (id) => {
    const altContact = this.props.list.find((itm) => itm.id === id);
    if (!altContact) return;
    console.log(altContact);
    const {
      firstName,
      lastName,
      email,
      adminNotes,
      noteType,
      jobTitle,
      phoneNumberSecondary,
      phoneNumber,
      mobileNumber,
      primaryMarketingOptIn,
      primaryUpdatesOptIn,
      primaryRenewalsOptIn,
    } = altContact;

    const note = noteType
      ? typeof noteType === "object"
        ? [noteType.value]
        : noteType
      : "";

    const payload = {
      firstName,
      lastName,
      email,
      note: adminNotes,
      noteType: note,
      jobTitle,
      phoneNumberSecondary,
      phoneNumber,
      mobileNumber,
      primaryMarketingOptIn,
      primaryUpdatesOptIn,
      primaryRenewalsOptIn,
    };
    this.props.updateAltContactAction(payload, id);
    return this.props.updateAccountAltContact(payload, id);
  };

  render() {
    const {
      editMode,
      setFieldError,
      setFieldTouched,
      list,
      errors,
      getAltContactInfo,
      setFieldValue,
    } = this.props;

    return (
      <div className="alt-contacts">
        {list?.length === 0 && <p>There are no alt contacts added yet.</p>}

        <div className="alt-contacts__holder">
          <FieldArray
            name="altContacts"
            render={(arrayHelpers) => {
              return (
                <div>
                  {list?.map((itm, index) => (
                    <AltItem
                      key={itm?.id}
                      item={itm}
                      setFieldValue={setFieldValue}
                      onRemove={this.removeAltContact}
                      onCreate={this.createNewAltContact}
                      setNewAltContacts={this.props.setNewAltContacts}
                      onUpdate={this.updateAltContactAction}
                      userId={this.props.accountID}
                      {...{
                        errors,
                        editMode,
                        setFieldError,
                        setFieldTouched,
                        index,
                        arrayHelpers,
                        getAltContactInfo,
                      }}
                    />
                  ))}

                  {editMode && (
                    <Button
                      outline
                      block
                      type="button"
                      className="p-1 mt-2"
                      color="primary"
                      onClick={() => {
                        this.addNewAltContact(arrayHelpers);
                      }}
                    >
                      + Add additional contact
                    </Button>
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect(null, {
  createAccountAltContact,
  updateAccountAltContact,
  removeAccountInfoAltContact,
})(AltContacts);
