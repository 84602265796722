import React from "react";
import Skeleton from "react-loading-skeleton";
import "../../../assets/scss/components/fts/skeleton-card.scss";

const SkeletonCard = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="skeleton">
        <section className="header">
          <Skeleton className="icon" duration={1} height={15} width={15} />
          <Skeleton duration={1} height={16} width={"25%"} />
        </section>

        <section>
          <Skeleton duration={1} height={25} width={"100%"} />

          <hr />

          <Skeleton duration={1} height={16} width={"70%"} />

          <div className="bottom">
            <section>
              <Skeleton
                className="owner"
                circle={true}
                duration={1}
                height={25}
                width={25}
              />
            </section>
            <section>
              <Skeleton duration={1} height={19} width={19} />
            </section>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SkeletonCard;
