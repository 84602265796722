import React, { Component } from "react";
import FilesTableGroup from "../../../../components/fts/account-listing-files-tab";

class UserFiles extends Component {
  render() {
    return <FilesTableGroup {...this.props} />;
  }
}

export default UserFiles;
