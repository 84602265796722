import React, { PureComponent } from "react";
import { Droppable } from "react-beautiful-dnd";

import ColumnHeader from "./ColumnHeader";
import ColumnTasks from "./ColumnTasks";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  getRemindersRequest,
  getRemindersSuccess,
  selectQueryString,
  selectReminders,
  setQueryString,
} from "../../../redux/reducers/task-managment";
import { Spinner } from "reactstrap";

class TaskColumn extends PureComponent {
  state = { reminders: [], counts: 0, isLoading: false };
  onScrollStart = () => {
    const { activeDropdown, setActiveDropdown } = this.props;

    if (activeDropdown) {
      setActiveDropdown(null);
    }
  };
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevProps.tasks.length !== this.props.tasks.length) {
  //     this.setState({
  //       reminders: this.props.tasks,
  //       counts: this.props.tasks.length,
  //     });
  //   }
  // }

  // componentDidMount() {
  //   this.setState({
  //     reminders: this.props.reminders,
  //     counts: this.props.reminders.length,
  //   });
  // }

  loadMore = (todoType, reminderDate) => {
    let query = this.props.queryString;
    const queryObj = queryString.parse(query);
    const newQueryObj = { ...queryObj };

    newQueryObj.todoType = todoType;
    newQueryObj.reminderDate = reminderDate;
    (async () => {
      try {
        let { data } = await this.props.getRemindersRequest(
          queryString.stringify(newQueryObj),
        );
        this.props.getRemindersSuccess({
          data: {
            todos: [
              ...this.props.reduxReminders[todoType].todos,
              ...data.todos,
            ],
            count: data.count,
          },
          todoType,
          // queryString: queryString.stringify(newQueryObj),
        });
        this.setState({ isLoading: false });
      } catch (e) {
        console.log(e);
      }
    })();
  };

  render() {
    const {
      column,
      callToAction,
      loading,
      activeDropdown,
      setActiveDropdown,
      handleArchive,
      handleUnArchive,
      handleTodoTypeUpdate,
      handleTodoUsersUpdate,
      handleEmptyCols,
      getEmptyColumns,
      list,
      userRef,
      user,
      reduxReminders,
      fetchTodos,
    } = this.props;
    const { isLoading } = this.state;
    if (!reduxReminders[column.value]) return null;
    let hasMore =
      reduxReminders[column.value]?.todos.length !==
      reduxReminders[column.value]?.count;

    const countOf = reduxReminders[column.value].count;
    return (
      <div className="tasks-column" name={column.id}>
        <ColumnHeader
          isLoading={isLoading}
          count={reduxReminders[column.value].todos.length}
          countOf={countOf}
          {...{ column }}
        />
        <div
          style={{ height: "74vh", overflow: "auto" }}
          id={`scrollableDiv_${column.value}`}
        >
          <InfiniteScroll
            onScroll={() => setActiveDropdown(null)}
            dataLength={reduxReminders[column.value].todos.length}
            next={() => {
              let reminderDate = [...reduxReminders[column.value].todos];
              reminderDate = reminderDate.pop().reminderDate;
              this.setState({ isLoading: true });
              this.loadMore(column.value, reminderDate);
            }}
            hasMore={hasMore}
            loader={
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "2rem",
                }}
              >
                <span style={{ marginRight: "1rem", color: column.color }}>
                  <Spinner size="sm" />
                </span>
                <span style={{ color: column.color }}>Loading more items</span>
              </div>
            }
            scrollableTarget={`scrollableDiv_${column.value}`}
          >
            <Droppable
              // direction={"horizontal"}
              isDropDisabled={column.value === "personal"}
              droppableId={column.id.toString()}
              name={column}
            >
              {(provided, snapshot) => (
                <div>
                  <ColumnTasks
                    fetchTodos={fetchTodos}
                    innerRef={provided.innerRef}
                    userRef={userRef}
                    provided={provided}
                    loading={loading}
                    callToAction={callToAction}
                    isDraggingOver={snapshot.isDraggingOver}
                    activeDropdown={activeDropdown}
                    setActiveDropdown={setActiveDropdown}
                    handleArchive={handleArchive}
                    handleUnArchive={handleUnArchive}
                    handleTodoTypeUpdate={handleTodoTypeUpdate}
                    handleTodoUsersUpdate={handleTodoUsersUpdate}
                    handleEmptyCols={handleEmptyCols}
                    getEmptyColumns={getEmptyColumns}
                    list={list}
                    tasks={reduxReminders[column.value].todos}
                    user={user}
                    {...{ column }}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    reduxReminders: selectReminders(state),
    queryString: selectQueryString(state),
  }),
  { getRemindersRequest, getRemindersSuccess, setQueryString },
)(TaskColumn);
