import styled from "styled-components";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
import FtsIcons from "../FtsIcons/FtsIcons";
export const InputWrapper = styled(FtsWrapper) `
  position: relative;
  cursor: pointer;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${(props) => props.appendIcon || props.prependIcon ? `${FtsIcons} {position: absolute;}` : ""}
`;
