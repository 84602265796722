import { toast } from "react-toastify";

export const handleError = (error) => {
  console.error("INTERNAL ERROR = ", error);
};

export const handleResponseError = (error, showError, defaultMsg = "") => {
  const message = error.response?.data?.message || defaultMsg;
  if (message && showError) toast.error(message);
  return message;
};
