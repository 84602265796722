import React from "react";
import { Button, Spinner } from "reactstrap";
import Icon from "../Icons";
import "../../../assets/scss/components/fts/skeleton-empty-state.scss";

const SkeletonEmptyState = ({
  iconName,
  iconSize = 20,
  title,
  buttonTitle,
  buttonColor,
  buttonAction,
  inProgress,
}) => {
  return (
    <div className="FTS-skeleton-empty-state">
      <Icon name={iconName} size={iconSize} />
      <span className="FTS-skeleton-empty-state-title">{title}</span>
      <Button color={buttonColor} onClick={buttonAction}>
        {!inProgress ? buttonTitle : <Spinner size={"sm"} />}
      </Button>
    </div>
  );
};

export default SkeletonEmptyState;
