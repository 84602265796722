/* eslint-disable indent */
/* eslint-disable react/display-name */
import React from "react";
import { formatRawDate } from "../../../configs/dateConfig";
import "../../../assets/scss/pages/account-marketing.scss";

import { ContextMenuTrigger } from "react-contextmenu";
import FTSBadge from "../../../components/fts/fts-badge";
import Icon from "../../../components/fts/Icons";
import { capitalizeFirstLetter } from "../../../utility/googleCalendar";
import moment from "moment";

export const defineColumnsEmails = (
  collectDataForCopy,
  callToAction,
  togglePopOver,
  accountData,
  isMeta,
  toggleRecipientsPopOver,
) => {
  let columns = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.id}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <span>{params.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Status",
      field: "active",
      sortable: true,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.active}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <FTSBadge color={params.value === true ? "success" : "danger"}>
              {params.value === true ? "Active" : "Inactive"}
            </FTSBadge>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      unSortIcon: false,
      minWidth: 250,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.name}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <span>{params.data.name}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Created by",
      field: "created_by",
      sortable: true,
      unSortIcon: false,
      minWidth: 200,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.created_by}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <span>{params.data.created_by}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Created on",
      field: "createdAt",
      sortable: true,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={formatRawDate(params.data.createdAt, false)}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <span>{formatRawDate(params.data.createdAt, false)}</span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Edited by",
      field: "edited_by",
      sortable: true,
      unSortIcon: false,
      minWidth: 200,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.created_by}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <span>{params.data.created_by}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Edited on",
      field: "updatedAt",
      sortable: true,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={formatRawDate(params.data.updatedAt, false)}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <span>{formatRawDate(params.data.updatedAt, false)}</span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Last sent",
      field: "lastSendDate",
      sortable: true,
      unSortIcon: false,
      minWidth: 200,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        if (!params.data.lastSendDate) {
          return "";
        }

        if (!params.data.emailActivities) {
          return (
            <ContextMenuTrigger
              data={formatRawDate(params.data.lastSendDate, false)}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <span>{formatRawDate(params.data.lastSendDate, false)}</span>
            </ContextMenuTrigger>
          );
        }
        return (
          <div
            onMouseEnter={() =>
              togglePopOver(
                `Popover_${params.data.id}`,
                params.data.emailActivities,
              )
            }
            onMouseLeave={() => togglePopOver()}
            id={`Popover_${params.data.id}`}
          >
            <ContextMenuTrigger
              data={formatRawDate(params.data.lastSendDate, false)}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <span>{formatRawDate(params.data.lastSendDate, false)}</span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Count",
      field: "count",
      sortable: false,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.count}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <span>{params.data.count}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Actions",
      field: "buttons",
      sortable: false,
      unSortIcon: false,
      minWidth: 220,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div className="account-marketing-actions">
            <span
              onClick={() => {
                callToAction("template_preview", false, params.data);
              }}
            >
              <Icon name="OpenAction" />
              Open
            </span>
            <span
              onClick={() => {
                window.open(
                  `https://us20.admin.mailchimp.com/templates/edit?id=${params.data.id}`,
                  "_blank",
                );
              }}
            >
              <Icon name="UpdateAction" />
              Edit
            </span>
          </div>
        );
      },
    },
  ];
  if (accountData && !isMeta) {
    let firstColumns = columns.slice(0, 2);
    let secondColumns = columns.slice(2, 20);
    let newColumns = [
      {
        headerName: "Recipient",
        field: "recipient",
        sortable: true,
        unSortIcon: false,
        minWidth: 250,
        flex: 1,
        cellRendererFramework: (params) => {
          const recipients = params.data.recipient.split(",");
          const numberOfRecipient = recipients.length;
          return (
            <ContextMenuTrigger
              data={params.data.recipient}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <div
                className="d-flex align-items-center"
                onMouseEnter={() => {
                  if (recipients.length > 1)
                    toggleRecipientsPopOver(
                      `Popover_${params.data.id}${numberOfRecipient}`,
                      recipients,
                    );
                }}
                onMouseLeave={() => toggleRecipientsPopOver()}
                id={`Popover_${params.data.id}${numberOfRecipient}`}
              >
                <span>{recipients[0]}</span>
                {numberOfRecipient > 1 && (
                  <span
                    style={{
                      color: "white",
                      borderRadius: 1000,
                      background: "#444",
                      padding: 10,
                      height: 30,
                      width: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 12,
                      display: "flex",
                    }}
                  >
                    +{numberOfRecipient - 1}
                  </span>
                )}
              </div>
            </ContextMenuTrigger>
          );
        },
      },
      {
        headerName: "Account id",
        field: "accountId",
        sortable: true,
        unSortIcon: false,
        minWidth: 150,
        flex: 1,
        cellRendererFramework: (params) => {
          return (
            <ContextMenuTrigger
              data={params.data.accountId}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <span>{params.data.accountId}</span>
            </ContextMenuTrigger>
          );
        },
      },
      {
        headerName: "Sent by",
        field: "sentBy",
        sortable: true,
        unSortIcon: false,
        minWidth: 150,
        flex: 1,
        cellRendererFramework: (params) => {
          return (
            <ContextMenuTrigger
              data={params.data.sendBy}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <span>{params.data.sendBy}</span>
            </ContextMenuTrigger>
          );
        },
      },
    ];
    return [...firstColumns, ...newColumns, ...secondColumns];
  }
  return columns;
};

export const defineColumnsSystemEmails = (
  collectDataForCopy,
  callToAction,
  resend,
  togglePopOver,
  isMeta,
  toggleRecipientsPopOver,
) => {
  const formatType = (type) => {
    return type
      .split("_")
      .map((e) => capitalizeFirstLetter(e))
      .join(" ");
  };
  let columns = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      unSortIcon: false,
      minWidth: 50,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.id}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <span>{params.data.id}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        let status = "In queue";
        let color = "info";
        if (isMeta) {
          status = "Active";
          color = "success";
        }
        let lastActivity = [...params.data.activities].pop();

        if (moment().diff(moment(params.data.createdAt), "days") > 3) {
          status = "Bounced";
          color = "danger";
        }

        if (lastActivity && lastActivity.activity === "sent") {
          status = "Sent";
          color = "success";
        }
        return (
          <ContextMenuTrigger
            data={status}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <div
              onMouseEnter={() =>
                togglePopOver(
                  `Popover_${params.data.id}`,
                  params.data.activities,
                )
              }
              onMouseLeave={() => togglePopOver()}
              id={`Popover_${params.data.id}`}
            >
              <FTSBadge color={color}>{status}</FTSBadge>
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={formatType(params.data.type)}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            {formatType(params.data.type)}
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Recipient",
      field: "recipient",
      sortable: true,
      unSortIcon: false,
      minWidth: 300,
      flex: 1,
      cellRendererFramework: (params) => {
        const recipients = params.data.recipient.split(",");
        const numberOfRecipient = recipients.length;
        return (
          <ContextMenuTrigger
            data={params.data.recipient}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <div
              className="d-flex align-items-center"
              onMouseEnter={() => {
                if (recipients.length > 1)
                  toggleRecipientsPopOver(
                    `Popover_${params.data.id}${numberOfRecipient}`,
                    recipients,
                  );
              }}
              onMouseLeave={() => toggleRecipientsPopOver()}
              id={`Popover_${params.data.id}${numberOfRecipient}`}
            >
              <span>{recipients[0]}</span>
              {numberOfRecipient > 1 && (
                <span
                  style={{
                    color: "white",
                    borderRadius: 1000,
                    background: "#444",
                    padding: 10,
                    height: 30,
                    width: 30,
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 12,
                    display: "flex",
                  }}
                >
                  +{numberOfRecipient - 1}
                </span>
              )}
            </div>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Initiated on",
      field: "createdAt",
      sortable: true,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={formatRawDate(params.data.createdAt, false)}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <span>{formatRawDate(params.data.createdAt, false)}</span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Sent on",
      field: "updatedAt",
      sortable: true,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        let sentAt = params.data.activities.length
          ? formatRawDate(params.data.activities[0].createdAt, false)
          : "";
        return (
          <div>
            <ContextMenuTrigger
              data={sentAt}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <span>{sentAt}</span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Sent by",
      field: "sendBy",
      sortable: true,
      unSortIcon: false,
      minWidth: 200,
      flex: 1,
      cellRendererFramework: (params) => {
        let { sendBy } = params.data;
        if (!sendBy) return "";

        return (
          <ContextMenuTrigger
            data={sendBy}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <span>{capitalizeFirstLetter(sendBy)}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Count",
      field: "count",
      sortable: true,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.emailSendCount}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <span>{params.data.emailSendCount}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Actions",
      field: "buttons",
      sortable: true,
      unSortIcon: false,
      minWidth: 230,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div className="account-marketing-actions">
            <span
              onClick={() => {
                callToAction("system_template_preview", false, params.data);
              }}
            >
              <Icon name="PreviewAction" />
              Preview
            </span>
            {!isMeta && (
              <span
                onClick={() => {
                  resend(params.data);
                }}
              >
                <Icon name="SendAction" />
                Resend
              </span>
            )}
          </div>
        );
      },
    },
  ];
  if (isMeta) {
    return [
      ...columns.slice(0, 3),
      ...columns.slice(4, 5),
      ...columns.slice(8, 10),
    ];
  }
  return columns;
};
