import React, { useCallback } from "react";
import { Span } from "../../dist/ui/src/FtsText";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { selectAccountData } from "../../../../redux/reducers/v2/account";
import { setAccountDataAction } from "../../../../redux/actions/v2/account";
import { RichTextEditor } from "../../dist/ui/src/components/react/RichTextEditor";
import { VariantsValues, WeightSize } from "@find-truck-service/types/ui/text";
import { ColorsValue } from "@find-truck-service/types/ui/colors";

export default function AccountNoteRow() {
  const dispatch = useDispatch();
  const accountData = useSelector(selectAccountData);

  const onChange = useCallback(
    (note) => {
      dispatch(setAccountDataAction({ ...accountData, adminNotes: note }));
    },
    [accountData],
  );

  return (
    <FtsRow rowGap={"lg"} pt={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span
          weight={WeightSize.medium}
          variant={VariantsValues["text-3xs"]}
          color={ColorsValue["blue-accent-500"]}
        >
          NOTE
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 12 }}>
        <RichTextEditor
          value={accountData.adminNotes}
          onChange={onChange}
          label="Add note (note will be saved on the account)"
        />
      </FtsColumn>
    </FtsRow>
  );
}
