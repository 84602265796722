import React from "react";
import { Span } from "../../../FtsText";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { FtsChips } from "../../../FtsChips/FtsChips";
import { ChipGapBySizesValues, ChipLabelSizeBySize } from "./Types";
export const Chips = (props) => {
    const { labelProps, label, appendIcon, prependIcon, variant, size, color } = props;
    return (React.createElement(FtsChips, { size: size, variant: variant, color: color, gap: ChipGapBySizesValues[size] },
        prependIcon && React.createElement(FtsIcons, { ...prependIcon }),
        React.createElement(Span, { display: "flex", variant: ChipLabelSizeBySize[size], lineHeightVariant: "relaxed", ...labelProps }, label),
        appendIcon && React.createElement(FtsIcons, { ...appendIcon })));
};
