import styled from "styled-components";
import { LineHeightByDevice, VariantByDevice, VariantsValues, WeightSize, } from "@find-truck-service/types/ui/text";
import { device } from "@find-truck-service/types/ui/breakpoints";
import { defaultStyleProps } from "../default";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
export const TextStyle = ({ weight = WeightSize["regular"], variant = VariantsValues["text-md"], color = ColorsValue["greyscale-1000"], lineHeightVariant = "tight", }) => {
    let res = `
                color:${color || ColorsValue["red-brand-50"]};
                font-weight: ${weight};
                line-height: ${LineHeightByDevice[variant][lineHeightVariant].lg};
                font-size: ${VariantByDevice[variant].lg};`;
    for (let [key, val] of Object.entries(device))
        res += `@media ${val} {
                font-size: ${VariantByDevice[variant][key]};
                line-height: ${LineHeightByDevice[variant][lineHeightVariant][key]};
              };`;
    return res;
};
export const H1 = styled.h1 `
  ${defaultStyleProps};
  ${TextStyle};
`;
export const H2 = styled.h2 `
  ${defaultStyleProps};
  ${TextStyle}
`;
export const H3 = styled.h3 `
  ${defaultStyleProps};
  ${TextStyle}
`;
export const H4 = styled.h4 `
  ${defaultStyleProps};
  ${TextStyle}
`;
export const H5 = styled.h5 `
  ${defaultStyleProps};
  ${TextStyle}
`;
export const H6 = styled.h6 `
  ${defaultStyleProps};
  ${TextStyle}
`;
export const Span = styled.span `
  ${defaultStyleProps};
  ${TextStyle}
`;
export const P = styled.p `
  ${defaultStyleProps};
  ${TextStyle}
`;
export const Text = styled.text `
  ${defaultStyleProps};
  ${TextStyle}
`;
