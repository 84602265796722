/*************************************
 *
 * CATEGORIES AND SUBCATEGORIES
 *
 *************************************/

const CAT_PREFIX = "category_";
const SUBCAT_PREFIX = "subCategory_";

export const extractFormikCategoriesData = (data) => {
  const userCategories = [];
  const userSubCategories = [];

  for (const [key, value] of Object.entries(data)) {
    if (key.indexOf(CAT_PREFIX) > -1 && value.active) {
      userCategories.push(
        parseInt(key.substring(key.indexOf("_") + 1, key.length))
      );

      if (value.subCats) {
        for (const [sKey, sValue] of Object.entries(value.subCats)) {
          if (sKey.indexOf(SUBCAT_PREFIX) > -1 && sValue) {
            userSubCategories.push(
              parseInt(sKey.substring(sKey.indexOf("_") + 1, sKey.length))
            );
          }
        }
      }
    }
  }
  return { userCategories, userSubCategories };
};

export const formatFormikCategoriesData = ({ categories, subCategories }) => {
  const data = {};
  if (categories?.length) {
    for (let index = 0; index < categories.length; index++) {
      const element = categories[index];
      const subCats = {};

      for (let i = 0; i < subCategories?.length; i++) {
        const subElement = subCategories[i];
        if (element.id === subElement.category_id) {
          subCats[`${SUBCAT_PREFIX}${subElement.id}`] = true;
        }
      }

      const subData = { active: true, subCats };

      data[`${CAT_PREFIX}${element.id}`] = subData;
    }
  }
  return data;
};

/*************************************
 *
 * AMENITIES AND SERVICE AMENITIES
 *
 *************************************/

const AMENITY_PREFIX = "amenity_";
const SERVICE_AMENITY_PREFIX = "serviceAmenity_";

// FORMATTED FOR BACKEND SERVER
export const extractFormikAmenitiesData = (data) => {
  const adsAmenities = [];
  const adsServiceAmenities = [];

  if (data?.amenities && typeof data?.amenities === "object") {
    for (const [key, value] of Object.entries(data.amenities)) {
      if (key.indexOf(AMENITY_PREFIX) > -1 && value) {
        adsAmenities.push(
          parseInt(key.substring(key.indexOf("_") + 1, key.length))
        );
      }
    }
  }

  if (data?.serviceAmenities && typeof data?.serviceAmenities === "object") {
    for (const [key, value] of Object.entries(data.serviceAmenities)) {
      if (key.indexOf(SERVICE_AMENITY_PREFIX) > -1 && value) {
        adsServiceAmenities.push(
          parseInt(key.substring(key.indexOf("_") + 1, key.length))
        );
      }
    }
  }

  return { adsAmenities, adsServiceAmenities };
};

// FOR LOCAL USE
export const formatFormikAmenitiesData = (
  amenitiesData = [],
  areService = false
) => {
  const amenities = {};
  const PREFIX = areService ? SERVICE_AMENITY_PREFIX : AMENITY_PREFIX;
  const key = areService ? "serviceAmenities" : "amenities";

  if (amenitiesData?.length) {
    for (let index = 0; index < amenitiesData.length; index++) {
      const { id } = amenitiesData[index];

      amenities[`${PREFIX}${id}`] = true;
    }
  }
  return { [key]: amenities };
};
