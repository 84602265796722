import React, { Component } from "react";

import moment from "moment";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormikMaskedInput from "../../../components/fts/formik/masked-input";

import { Button, Collapse, Spinner } from "reactstrap";

import Icon from "../../../components/fts/Icons";
import "../../../assets/scss/components/fts/payment-modals.scss";
import { connect } from "react-redux";
import { payWithStripe } from "../../../redux/actions/payments";
import { toast } from "react-toastify";
import FormikInput from "../../../components/fts/formik/formik-input";
import { setPromoCode } from "../../../redux/actions/promocodes";
import {
  resetDiscount,
  setCreditCardData,
} from "../../../redux/actions/invoices";
import { zipCodeMask } from "../../../utility/masks";

class CreditCard extends Component {
  state = {
    isLoading: false,
    activeTab: "oneTime",
    today: moment(),
    todayFormatted: moment().format("MMM DD, YYYY"),
    disablePayment: false,
    initialFormData: {
      cardHolder: "",
      cardNumber: "",
      expirationDate: "",
      cvv: "",
      zipCode: "",
      startDate: moment().toISOString(),
    },
  };

  formSchema = Yup.object().shape(
    {
      cardHolder: Yup.string().trim().required("Cardholder name is required"),
      cardNumber: Yup.string()
        .test(
          "cardNumber",
          "Card number must be exactly 16 characters",
          (cardNumber) => {
            return (
              cardNumber?.replace(/_/g, "").replace(/-/g, "").length === 15 ||
              cardNumber?.replace(/_/g, "").replace(/-/g, "").length === 16
            );
          },
        )
        .required("Card number is required"),
      expirationDate: Yup.string()
        .required("Expiration date is required")
        .test(
          "len",
          "Invalid expiration date",
          (val) => val?.replace("_", "").replace("/", "").length >= 4,
        )
        .test("len2", "Invalid expiration date", (val) => {
          const year = new Date().getFullYear() - 2000;
          const newVal = val?.split("/")[1];
          if (parseInt(newVal) < year) {
            return false;
          }
          return true;
        })
        .test("len3", "Invalid expiration date", (val) => {
          const year = new Date().getFullYear() - 2000;
          const month = new Date().getMonth();
          const valMonth = val?.split("/")[0];
          const valYear = val?.split("/")[1];
          if (parseInt(valYear) === year && parseInt(valMonth) < month) {
            return false;
          }
          return true;
        }),
      cvv: Yup.number()
        .required("CVV is required")
        .test(
          "len",
          "CVV must be 3 or 4 digits",
          (val) => val?.toString().length <= 4,
        )
        .test(
          "len",
          "CVV must be 3 or 4 digits",
          (val) => val?.toString().length >= 3,
        ),
      zipCode: Yup.number()
        .required("Zip code is required")
        .typeError("Invalid zip code"),

      startDate: Yup.lazy((val) =>
        typeof val === "string"
          ? Yup.string().nullable().required("Start date is required")
          : Yup.array()
              .of(Yup.date())
              .nullable()
              .required("Start date is required"),
      ),
    },
    [["cardNumber"]],
  );

  now = new Date();

  updateActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  formatMoney = (number) => {
    return number
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  handleSubmit = (values) => {
    this.setState({
      isLoading: true,
    });

    let { selectedInvoices, totalAmount } = this.props;

    selectedInvoices = selectedInvoices.filter((invoice) => invoice.isSelected);

    let payload = {
      subscription: this.state.activeTab === "recurring",
      number: values.cardNumber,
      exp_month: values.expirationDate.split("/")[0],
      exp_year: values.expirationDate.split("/")[1],
      cvc: values.cvv.replace("_", ""),
      companyName: selectedInvoices[0]["owner.companyName"],
      ownerFirstName: selectedInvoices[0]["owner.firstName"],
      ownerLastName: selectedInvoices[0]["owner.lastName"],
      city: selectedInvoices[0]["owner.city"],
      state: selectedInvoices[0]["owner.state"],
      newAccount: selectedInvoices[0]["owner.newAccount"],
      source: "card",
      amount: totalAmount,
      credit:
        this.props.appliedCreditAmount < 0 ? 0 : this.props.appliedCreditAmount,
      invoices: selectedInvoices.map(
        ({
          id,
          amount,
          promoCode,
          adId,
          userId,
          invoiceAmount,
          dueAt,
          type,
          invoiceNumber,
          creditUsed,
        }) => ({
          invoiceNumber,
          id,
          amount,
          type,
          promoCode: promoCode && { id: promoCode.id },
          adId,
          userId,
          invoiceAmount,
          dueAt,
          creditUsed,
        }),
      ),
      promoCodeId: this.props.promoCode?.id ? this.props.promoCode.id : null,
      cardHolder: values.cardHolder,
      zipCode: values.zipCode,
    };

    this.props
      .payWithStripe(payload)
      .then(() => {
        // this.props.setPaymentStep("paymentProccessed");
        // this.props.setLastPaymentModalTitle(
        //   "Payment successfully proccessed"
        // );
        // this.props.gridApi.refreshTable();
        this.props.setIsPaymentCompleted(true);
        if (typeof this.props.onSubmit === "function") {
          this.props.onSubmit();
        }

        toast.success("Payment successfully processed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((e) => {
        console.log("ERROR: ", e.response);
        this.setState({
          isLoading: false,
        });
        toast.error("Oops, Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  componentDidMount = () => {
    let creditCardData = this.props.creditCardData;
    if (creditCardData) {
      this.setState({
        disablePayment: true,
        activeTab: creditCardData.activeTab,
        initialFormData: {
          cardHolder: creditCardData.cardHolder,
          cardNumber: creditCardData.number,
          expirationDate: `${creditCardData.exp_month}/${creditCardData.exp_year}`,
          cvv: creditCardData.cvc,
          zipCode: creditCardData.zipCode,
          startDate: creditCardData.startDate,
        },
      });
    }
  };

  render() {
    const { activeTab, todayFormatted, isLoading } = this.state;
    let tabs = [
      {
        label: "Recurring",
        type: "recurring",
      },
      {
        label: "One - time",
        type: "oneTime",
      },
    ];

    return (
      <Formik
        initialValues={this.state.initialFormData}
        validationSchema={this.formSchema}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={this.handleSubmit}
      >
        {() => {
          return (
            <Form id="credit-card-form">
              <div className="payment-modal-body">
                <span className="payment-modal-body-wrapper">
                  {/*<span className="payment-modal-title border-bottom-none">*/}
                  {/*  <span className="d-flex align-items-center">*/}
                  {/*    <span*/}
                  {/*      className=" link-button"*/}
                  {/*      onClick={() => {*/}
                  {/*        setPaymentStep("paymentMethod");*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      <Icon name="Link" size="24" />*/}
                  {/*    </span>*/}

                  {/*    <h3 className="mb-0">Credit card payment</h3>*/}
                  {/*    {this.state.isLoading && (*/}
                  {/*      <Spinner size="sm" className="ml-1" />*/}
                  {/*    )}*/}
                  {/*  </span>*/}
                  {/*  <CloseButton onClick={closeModal} />*/}
                  {/*</span>*/}
                  <div className="amount-box">
                    <span className="amount">
                      ${this.formatMoney(this.props.totalAmount)}
                    </span>
                    <span className="amount-box-date">
                      Due on {todayFormatted}
                    </span>
                  </div>

                  <div className="nav">
                    {tabs.map((itm) => {
                      return (
                        <button
                          key={itm.type}
                          type="button"
                          className={`tab ${
                            activeTab === itm.type ? "selected" : "unselected"
                          }`}
                          onClick={() => {
                            this.updateActiveTab(itm.type);
                          }}
                        >
                          {itm.label}
                        </button>
                      );
                    })}
                  </div>
                  <Collapse isOpen={activeTab === "recurring"}>
                    <div className="payment-start">
                      <div>
                        Start{" "}
                        <span className="start-date">
                          <Icon name="CheckIcon" size="12" />
                          Immediately - {todayFormatted}
                        </span>
                      </div>
                      <hr />
                      <div className="payment-info">
                        <span>
                          <Icon name="InfoCircle" />
                        </span>
                        <span className="info-message">
                          Annually recurring payment will start on{" "}
                          {todayFormatted}. This amount will automatically be
                          charged on this day every year. You can always switch
                          between One-time and Reccurring payment type.
                        </span>
                      </div>
                    </div>
                  </Collapse>

                  <div className="accepted-credit-cards">
                    <span className="label">Accepted credit cards</span>
                    <div className="credit-cards">
                      <Icon name="Visa" size="33" />
                      <Icon name="Mastercard" size="34" />
                      <Icon name="AmeEx" size="40" />
                      <Icon name="Discover" size="40" />
                    </div>
                  </div>

                  <div className="credit-card-form">
                    <span className="d-flex">
                      <div className="credit-card-fields">
                        <span className="labels">Card number</span>

                        <FormikMaskedInput
                          name="cardNumber"
                          placeholder="0000-0000-0000-0000"
                          required
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            "-",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            "-",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            "-",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                        />
                      </div>

                      <div className="credit-card-fields">
                        <span className="labels">Cardholder name</span>

                        <FormikInput
                          name="cardHolder"
                          placeholder="Cardholder name"
                        />
                      </div>
                    </span>

                    <span className="d-flex">
                      <div className="credit-card-fields">
                        <span className="labels">Expiration date</span>
                        <FormikMaskedInput
                          name="expirationDate"
                          placeholder="00/00"
                          required
                          mask={[/[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/]}
                        />
                      </div>
                      <div className="credit-card-fields">
                        <span className="labels">CVV</span>
                        <FormikMaskedInput
                          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                          name="cvv"
                          placeholder="CVV"
                          required
                        />
                      </div>
                      <div className="credit-card-fields">
                        <span className="labels">Billing zip code</span>
                        <FormikMaskedInput
                          name="zipCode"
                          placeholder="000000"
                          mask={zipCodeMask}
                        />
                      </div>
                    </span>
                  </div>

                  <Button
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                    onClick={() => {
                      this.setState({
                        isOpen: false,
                      });
                    }}
                  >
                    {isLoading ? (
                      <Spinner size={"sm"} color={"white"} />
                    ) : (
                      `Pay $(${this.formatMoney(this.props.totalAmount)})`
                    )}
                  </Button>
                </span>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedInvoices: state.invoices.selectedInvoices,
    totalAmount: state.invoices.totalAmount,
    discountAmount: state.invoices.discountAmount,
    promoCode: state.promoCodes.promoCode,
    creditCardData: state.invoices.creditCardData,
  };
};

const mapDispatchToProps = {
  payWithStripe,
  setPromoCode,
  resetDiscount,
  setCreditCardData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);
