import styled from "styled-components";
import { defaultStyleProps } from "../default";
import { ButtonVariants, ButtonSizes } from "@find-truck-service/types/ui/button";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
const defaultStyles = `
        line-height: ${Spacing["lg"]};
        border: unset;
        cursor: pointer;
        &:disabled {
          cursor: default;
        };
        transition: all .15s ease;
        outline: unset;
        border-radius: ${Spacing["2xs"]};
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: fit-content;
`;
const defaultStylesMd = `${defaultStyles};padding: ${Spacing["md"]} ${Spacing["2xl"]};`;
const defaultStylesSm = `${defaultStyles};padding: ${Spacing.sm} ${Spacing["lg"]};`;
const baseColor = {
    accent: "blue-accent",
    error: "red-error",
    success: "green-success",
    warning: "yellow-warning",
    gray: "greyscale",
    neutral: "greyscale",
};
const buttonStyles = (props) => {
    const style = {
        [ButtonVariants.contained]: {
            [ButtonSizes.sm]: `
        ${defaultStylesSm};
        background: ${ColorsValue[`${baseColor[props.color]}-500`]};
        color: ${ColorsValue["greyscale-0"]};
        &:hover {
          background: ${ColorsValue[`${baseColor[props.color]}-600`]};
        };
        &:active {
          background: ${ColorsValue[`${baseColor[props.color]}-700`]};
        };
        &:focus-visible {
          background: ${ColorsValue[`${baseColor[props.color]}-500`]};
          outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:disabled {
          background: ${ColorsValue[`${baseColor[props.color]}-200`]};
        };
        `,
            [ButtonSizes.md]: `
        ${defaultStylesMd};
        background: ${ColorsValue[`${baseColor[props.color]}-500`]};
        color: ${ColorsValue["greyscale-0"]};
        &:hover {
          background: ${ColorsValue[`${baseColor[props.color]}-600`]};
        };
        &:active {
          background: ${ColorsValue[`${baseColor[props.color]}-700`]};
        };
        &:focus-visible {
          background: ${ColorsValue[`${baseColor[props.color]}-500`]};
          outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:disabled {
          background: ${ColorsValue[`${baseColor[props.color]}-200`]};
        };
        `,
        },
        [ButtonVariants.outlined]: {
            [ButtonSizes.sm]: `
      ${defaultStylesSm};
      background: transparent;
      border-radius: ${Spacing["2xs"]};
      outline: ${Spacing["4xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
      color: ${ColorsValue[`${baseColor[props.color]}-500`]};
      &:hover {
        background: ${ColorsValue[`${baseColor[props.color]}-50`]};
      }
      &:active {
        background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        outline: ${Spacing["4xs"]} solid ${ColorsValue[`${baseColor[props.color]}-700`]};
        color: ${ColorsValue[`${baseColor[props.color]}-700`]};

      };
      &:focus {
        background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        outline: ${Spacing["4xs"]} solid ${ColorsValue[`${baseColor[props.color]}-700`]} !important;
        color: ${ColorsValue[`${baseColor[props.color]}-700`]};

      };
      &:focus-visible {
        outline: ${Spacing["4xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
      };
      &:disabled {
        background: transparent;
        outline: ${Spacing["3xs"]} solid ${ColorsValue["greyscale-50"]};
        color: ${ColorsValue[`${baseColor[props.color]}-200`]};
      };
      `,
            [ButtonSizes.md]: `
      ${defaultStylesMd};
      background: transparent;
      border-radius: ${Spacing["2xs"]};
      outline: ${Spacing["4xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
      color: ${ColorsValue[`${baseColor[props.color]}-500`]};
      &:hover {
        background: ${ColorsValue[`${baseColor[props.color]}-50`]};
      }
      &:active {
        background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        outline: ${Spacing["4xs"]} solid ${ColorsValue[`${baseColor[props.color]}-700`]};
        color: ${ColorsValue[`${baseColor[props.color]}-700`]};

      };
      &:focus-visible {
        outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
      };
      &:disabled {
        background: transparent;
        outline: ${Spacing["4xs"]} solid ${ColorsValue["greyscale-50"]};
        color: ${ColorsValue[`${baseColor[props.color]}-200`]};
      };
      `,
        },
        [ButtonVariants.text]: {
            [ButtonSizes.sm]: `
      ${defaultStylesSm};
      background: transparent;
      border-radius: ${Spacing["2xs"]};
      color: ${ColorsValue[`${baseColor[props.color]}-500`]};
      &:hover {
        background: ${ColorsValue[`${baseColor[props.color]}-50`]};
      }
      &:active {
        background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        outline: ${Spacing["4xs"]} solid ${ColorsValue[`${baseColor[props.color]}-700`]};
        color: ${ColorsValue[`${baseColor[props.color]}-700`]};

      };
      &:focus-visible {
        outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
      };
      &:disabled {
        background: transparent;
        color: ${ColorsValue[`${baseColor[props.color]}-200`]};
        outline: unset;
      };
      `,
            [ButtonSizes.md]: `
      ${defaultStylesMd};
      background: transparent;
      border-radius: ${Spacing["2xs"]};
      color: ${ColorsValue[`${baseColor[props.color]}-500`]};
      &:hover {
        background: ${ColorsValue[`${baseColor[props.color]}-50`]};
      }
      &:active {
        background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        outline: ${Spacing["4xs"]} solid ${ColorsValue[`${baseColor[props.color]}-700`]};
        color: ${ColorsValue[`${baseColor[props.color]}-700`]};

      };
      &:focus-visible {
        outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
      };
      &:disabled {
        background: transparent;
        color: ${ColorsValue[`${baseColor[props.color]}-200`]};

      };
      `,
        },
        [ButtonVariants.googleAuth]: {
            [ButtonSizes.sm]: `
        ${defaultStylesSm};
        background: ${ColorsValue[`${baseColor[props.color]}-100`]};
        color: ${ColorsValue["greyscale-800"]};
        &:hover {
          background: ${ColorsValue[`${baseColor[props.color]}-200`]};
        };
        &:active {
          background: ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:focus-visible {
          background: ${ColorsValue[`${baseColor[props.color]}-100`]};
          outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:disabled {
          background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        };
        `,
            [ButtonSizes.md]: `
        ${defaultStylesMd};
        background: ${ColorsValue[`${baseColor[props.color]}-100`]};
        color: ${ColorsValue["greyscale-800"]};
        &:hover {
          background: ${ColorsValue[`${baseColor[props.color]}-200`]};
        };
        &:active {
          background: ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:focus-visible {
          background: ${ColorsValue[`${baseColor[props.color]}-100`]};
          outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:disabled {
          background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        };
        `,
        },
        [ButtonVariants.facebookAuth]: {
            [ButtonSizes.sm]: `
        ${defaultStylesSm};
        background: ${ColorsValue[`${baseColor[props.color]}-100`]};
        color: ${ColorsValue["greyscale-800"]};
        &:hover {
          background: ${ColorsValue[`${baseColor[props.color]}-200`]};
        };
        &:active {
          background: ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:focus-visible {
          background: ${ColorsValue[`${baseColor[props.color]}-100`]};
          outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:disabled {
          background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        };
        `,
            [ButtonSizes.md]: `
        ${defaultStylesMd};
        background: ${ColorsValue[`${baseColor[props.color]}-100`]};
        color: ${ColorsValue["greyscale-800"]};
        &:hover {
          background: ${ColorsValue[`${baseColor[props.color]}-200`]};
        };
        &:active {
          background: ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:focus-visible {
          background: ${ColorsValue[`${baseColor[props.color]}-100`]};
          outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:disabled {
          background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        };
        `,
        },
        [ButtonVariants.payPal]: {
            [ButtonSizes.sm]: `
        ${defaultStylesSm};
        background: ${ColorsValue[`${baseColor[props.color]}-100`]};
        color: ${ColorsValue["greyscale-800"]};
        &:hover {
          background: ${ColorsValue[`${baseColor[props.color]}-200`]};
        };
        &:active {
          background: ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:focus-visible {
          background: ${ColorsValue[`${baseColor[props.color]}-100`]};
          outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:disabled {
          background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        };
        `,
            [ButtonSizes.md]: `
        ${defaultStylesMd};
        background: ${ColorsValue[`${baseColor[props.color]}-100`]};
        color: ${ColorsValue["greyscale-800"]};
        &:hover {
          background: ${ColorsValue[`${baseColor[props.color]}-200`]};
        };
        &:active {
          background: ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:focus-visible {
          background: ${ColorsValue[`${baseColor[props.color]}-100`]};
          outline: ${Spacing["3xs"]} solid ${ColorsValue[`${baseColor[props.color]}-300`]};
        };
        &:disabled {
          background: ${ColorsValue[`${baseColor[props.color]}-50`]};
        };
        `,
        },
    };
    return style[props.variant][props.size];
};
export const FtsButton = styled.button `
  ${buttonStyles};
  ${defaultStyleProps};
`;
