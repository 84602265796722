import React, { Component } from "react";
import { Spinner } from "reactstrap";

export default class ColumnHeader extends Component {
  render() {
    const { column, count, countOf, isLoading, ...rest } = this.props;
    return (
      <div className="tasks-column__header" {...rest}>
        <div className="top-bar" style={{ backgroundColor: column.color }} />
        {count === 0 ? (
          <span className="tasks-column__null">
            <span style={{ color: column.color }}>{column.description}</span>
          </span>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                color: "#242d34",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "24px",
              }}
            >
              {column.description}
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isLoading && (
                <span style={{ marginRight: "1rem", color: column.color }}>
                  <Spinner size={"sm"} />
                </span>
              )}
              <span
                style={{
                  border: `1px solid ${column.color}`,
                  padding: "0.5rem",
                  borderRadius: 5,
                  display: "flex",
                  minWidth: 50,
                  justifyContent: "center",
                  color: column.color,
                  fontSize: "18px",
                  lineHeight: "16px",
                  fontWeight: 500,
                }}
              >
                {countOf}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
