import React from "react";
import { Button, Col } from "reactstrap";
import CloseButton from "../CloseButton";
import FTSModal from "../fts-modal";

export default function UpgradeInProgressModal({
  isOpen,
  toggleUpgradeInProgressModal,
}) {
  return (
    <FTSModal
      isOpen={isOpen}
      title="Upgrade In Progress"
      centered
      headerCTA={() => {
        return (
          <div className={"d-flex"}>
            <h4> Upgrade in progress </h4>
            <Col className="text-right pr-0">
              <CloseButton onClick={toggleUpgradeInProgressModal} />
            </Col>
          </div>
        );
      }}
      onClose={toggleUpgradeInProgressModal}
      closeModal={toggleUpgradeInProgressModal}
      footerCTA={() => {
        return (
          <>
            <Button onClick={toggleUpgradeInProgressModal} color="secondary">
              Cancel
            </Button>
          </>
        );
      }}
    >
      The edit listing is currently disabled. This listing has an active
      upgrade. Please cancel the upgrade for editing listing
    </FTSModal>
  );
}
