import React, { useEffect, useRef, useState } from "react";
import { InputWrapper } from "../../../FtsInput/InputWrapper";
import { FtsInput } from "../../../FtsInput/FtsInput";
import { Span } from "../../../FtsText";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import styled from "styled-components";
import { InputLabel } from "../InputLabel";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { SvgIconSizeValues } from "@find-truck-service/types/ui/icons";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { VariantsValues } from "@find-truck-service/types/ui/text";
export const Input = (props) => {
    const { errorMessage, onBlur = () => { }, size, required, appendIcon, prependIcon, onPrependIconClick, invalid, ...rest } = props;
    const ref = useRef();
    const [errorMessageInternal, setErrorMessageInternal] = useState("");
    const onBlurInternal = (event) => {
        if (event && event.target && event.target.value) {
            if (event.target.value.length >= 1024) {
                return setErrorMessageInternal("Input cannot be more than 1024 characters");
            }
        }
        setErrorMessageInternal("");
        onBlur();
    };
    // images/2024/08/24/PA-Truck-Center-Dover-DE-445906-1724515494488.gif
    useEffect(() => {
        if (errorMessageInternal || errorMessage) {
            if (ref.current) {
                ref.current.scrollIntoView({ block: "center", behavior: "auto" });
            }
        }
    }, [errorMessage, errorMessageInternal]);
    return (React.createElement(InputWrapper, { appendIcon: appendIcon, prependIcon: prependIcon },
        React.createElement(InputLabel, { label: props.label, mb: "2xs", required: required }),
        React.createElement(StyledFtsWrapper, { appendIcon: appendIcon, prependIcon: prependIcon, size: size },
            React.createElement(PrependWrapper, { size: size, onClick: onPrependIconClick }, prependIcon && React.createElement(FtsIcons, { ...prependIcon })),
            React.createElement(FtsInput, { variant: "outlined", color: "accent", ref: ref, size: size, onBlur: onBlurInternal, invalid: !!invalid || !!errorMessageInternal || !!errorMessage, ...rest }),
            React.createElement(AppendWrapper, { size: size }, appendIcon && React.createElement(FtsIcons, { ...appendIcon }))),
        React.createElement(Span, { variant: VariantsValues["text-3xs"], color: ColorsValue["red-error-500"], mt: "2xs" }, errorMessageInternal || errorMessage)));
};
const AppendWrapper = styled(FtsWrapper) `
  position: absolute;
  top: 0;
  height: 100%;
  align-items: center;
  display: flex;
  svg {
    width: ${(props) => SvgIconSizeValues[props.size]};
    height: ${(props) => SvgIconSizeValues[props.size]};
    margin-left: ${(props) => Spacing[props.size]};
  }
`;
const PrependWrapper = styled(FtsWrapper) `
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  align-items: center;
  display: flex;
  svg {
    width: ${(props) => SvgIconSizeValues[props.size]};
    height: ${(props) => SvgIconSizeValues[props.size]};
    margin-right: ${(props) => Spacing[props.size]};
  }
`;
const StyledFtsWrapper = styled(FtsWrapper) `
  position: relative;
  display: flex;
  width: 100%;
  input {
    padding-left: ${(props) => props.appendIcon
    ? `calc(${Spacing[props.size]} + ${Spacing[props.size]} + 20px)`
    : ""};
    padding-right: ${(props) => props.prependIcon
    ? `calc(${Spacing[props.size]} + ${Spacing[props.size]} + 20px)`
    : ""};
  }
`;
