import React from "react";
import { connect } from "react-redux";
import { Label } from "reactstrap";

import { pickerStyles, controlStyles } from "../picker-style";
import { components } from "react-select";
import Select from "../Select";

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};

const StandaloneStatesPicker = ({ label, name, states, onChange, ...rest }) => {
  const pickerStyle = {
    ...pickerStyles,
    borderWidth: 0,
    control: (styles, state) => controlStyles(styles, state),
  };

  let options = states;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div>
        {!!label && (
          <Label className={rest.isDisabled ? "disabled" : ""} for={name}>
            {label}
          </Label>
        )}
      </div>
      <div>
        <Select
          {...{ label }}
          isSearchable={true}
          isClearable={true}
          options={options}
          styles={pickerStyle}
          name={name}
          components={{
            ValueContainer: (props) => <ValueContainer {...props} />,
          }}
          onChange={onChange}
          {...rest}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  states: state.essentialLists.states,
});

export default connect(mapStateToProps, null)(StandaloneStatesPicker);
