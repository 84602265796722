import React, { useState } from "react";
import { connect } from "react-redux";

import { formatRawDate } from "../../../configs/dateConfig";
import { getUserData } from "../../../redux/actions/users";
import Avatar from "../../@vuexy/avatar/AvatarComponent";
import Icon from "../Icons";

import classnames from "classnames";
import "../../../assets/scss/components/fts/notes.scss";
import { deleteNote } from "../../../redux/actions/notes";
import moment from "moment";
import FormikInputValue from "../formik/formik-input-value";

let NOTE_EDITABLE_DAYS = 3;

const Reply = ({
  reply,
  hasHR,
  long,
  userData,
  updateTodo,
  handleTodoNoteReplyRemoved,
  openNoteInfo,
}) => {
  const { createdAt, owner, adminOwner, note } = reply;
  const [showButton] = useState(note.length > 135);
  const [showLess, setShowLess] = useState(note.length > 135);

  const replyAdmin = adminOwner ?? owner;

  let createdDate = moment(reply.createdAt);
  let now = moment();

  const permisionToEditNote =
    adminOwner.id === userData.id &&
    now.diff(createdDate, "days") < NOTE_EDITABLE_DAYS;
  if (!replyAdmin) return null;

  const isEdited = moment(reply.createdAt) < moment(reply.updatedAt);

  return (
    <>
      <div className="fts-note-small nested">
        <div
          className={classnames(
            "fts-note-small-created-by fts-note-reply-created-by",
            {
              long,
            }
          )}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <div className="reply-info">
              <div className="small-note-user-info justify-content-between">
                <div className="d-flex">
                  <Avatar
                    content={`${replyAdmin.firstName[0]}${replyAdmin.lastName[0]}`.toUpperCase()}
                    color={replyAdmin.color}
                  />
                  <div>
                    <div className="note-info">
                      <span className="owner">
                        {`${replyAdmin.firstName} ${replyAdmin.lastName}`}{" "}
                        {isEdited && <small>(Edited)</small>}
                      </span>
                      <small>{formatRawDate(createdAt)}</small>
                    </div>
                  </div>
                </div>
                {permisionToEditNote && updateTodo && (
                  <span
                    onClick={() => {
                      handleTodoNoteReplyRemoved &&
                        handleTodoNoteReplyRemoved(reply);
                    }}
                  >
                    <span className="d-flex flex-row align-items-center justify-content-center remove-note-btn">
                      <Icon name="Trash" size="18" />
                      <span>Remove</span>
                    </span>
                  </span>
                )}
              </div>

              <div
                className="reply-text"
                onClick={() => {
                  if (permisionToEditNote) {
                    openNoteInfo(reply);
                  }
                }}
              >
                <FormikInputValue>
                  {showLess ? `${note.slice(0, 135)}...` : note}
                </FormikInputValue>

                {showButton && (
                  <button
                    type="button"
                    className="toggle-text"
                    onClick={() => setShowLess(!showLess)}
                  >
                    &nbsp;See {showLess ? "more" : "less"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {hasHR && <hr />}
    </>
  );
};

function mapStateToProps(state) {
  return {
    userData: state.auth.login.values.loggedInUser,
  };
}

export default connect(mapStateToProps, {
  getUserData,
  deleteNote,
})(Reply);
