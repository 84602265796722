import React from "react";

import { Button } from "reactstrap";
import FTSModal from "../fts-modal";
import CloseButton from "../CloseButton";
import Icon from "../Icons";
import moment from "moment";
import { listingTypesArray } from "../../../data/listing-types-options";

import "../../../assets/scss/components/fts/downgrading-listing-modal.scss";

class DowngradingListing extends React.Component {
  formatMoney = (number) => {
    return number
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  getListingTypeName = (value) => {
    let key = listingTypesArray?.filter(
      (type) => type.value === parseInt(value)
    );

    return key[0].label;
  };

  render() {
    const { isOpen, closeModal, confirmModal, onClose, listingData } =
      this.props;

    const price = [0, 498, 378, 258, 138];

    const oldTotal = price[listingData?.priceId];

    const restOfListingPrice =
      moment(moment.unix(listingData.renewalTimestamp)).diff(moment(), "days") *
      (oldTotal / 365);

    return (
      <FTSModal
        {...{ isOpen, closeModal, onClose }}
        modalClasses={"fts-reassign-modal"}
        centered={true}
        style={{ maxWidth: "665px" }}
        footerCTA={() => {
          return (
            <div className="reassign-modal-footer">
              <Button onClick={closeModal} color="secondary" className="mr-1">
                Cancel
              </Button>
              <Button onClick={confirmModal} className="downgrade-button">
                Downgrade
              </Button>
            </div>
          );
        }}
      >
        <div className="reassign-modal-body">
          <span className="reassign-modal-body-wrapper">
            <span className="reassign-modal-title">
              <div className="d-flex update-listing-confirmation-title">
                <div>
                  <Icon name="Downgrading" size={50} />
                </div>

                <div>
                  <span className="d-flex align-center">
                    <h3>Downgrading Listing</h3>
                  </span>

                  <span>
                    {restOfListingPrice && listingData?.isItPaidFor > 0
                      ? `This listing will be downgraded. The remaining credit in the amount of $${this.formatMoney(
                          restOfListingPrice
                        )} will be stored on the account and can be used for future payments if current listing has been paid for.`
                      : `This listing will be downgraded from ${this.getListingTypeName(
                          listingData?.priceId
                        )} to ${this.getListingTypeName(
                          this.props.updateListingTypePayload?.newListingType
                        )}.`}
                  </span>
                </div>
              </div>

              <CloseButton onClick={this.props.closeModal} />
            </span>
          </span>
        </div>
      </FTSModal>
    );
  }
}

export default DowngradingListing;
