import FTSAxios from "../../../axios/fts.instance";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";

export const SET_LISTING_INVOICES = "SET_LISTING_INVOICES";
export const SET_LISTING_INVOICES_ACTIVE_SUBTAB =
  "SET_LISTING_INVOICES_ACTIVE_SUBTAB";
export const SET_LISTING_INVOICES_IN_PROGRESS =
  "SET_LISTING_INVOICES_IN_PROGRESS";
export const SET_LISTING_INVOICES_CURRENT_PAGE =
  "SET_LISTING_INVOICES_CURRENT_PAGE";
export const SET_LISTING_INVOICES_SORT_MODEL =
  "SET_LISTING_INVOICES_SORT_MODEL";
export const SET_LISTING_INVOICES_RESULTS_PER_PAGE =
  "SET_LISTING_INVOICES_RESULTS_PER_PAGE";
export const SET_LISTING_INVOICES_SEARCH_STRING =
  "SET_LISTING_INVOICES_SEARCH_STRING";
export const SET_LISTING_INVOICES_LAST_VIEWED_ID =
  "SET_LISTING_INVOICES_LAST_VIEWED_ID";

export const searchListingInvoices = ({
  page,
  resultsPerPage = 100,
  sort,
  searchString,
  filter = [],
  statusFilter = [],
  searchFields,
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const queryParams = {
        searchString: "",
        page: page,
        pageSize: resultsPerPage,
      };

      let multi_match = [...statusFilter];
      if (searchString) {
        multi_match.push({
          query_string: {
            query: rfc3986EncodeURIComponent(searchString || "")
              .replace(/%40/g, "@")
              .replace(/\+/g, " AND "),

            type: "phrase_prefix",
            default_operator: "AND",
            fields: searchFields,
          },
        });
      }

      dispatch({
        type: SET_LISTING_INVOICES_IN_PROGRESS,
        payload: true,
      });

      FTSAxios.post("admin/invoices/search", {
        ...queryParams,
        multi_match,
        filter,
        sort,
      })
        .then(({ data }) => {
          resolve(data?.result);
          dispatch({
            type: SET_LISTING_INVOICES,
            payload: data?.result,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const updateListingInvoicesActiveSubTab = ({ subTab }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_LISTING_INVOICES_ACTIVE_SUBTAB,
      payload: subTab,
    });
  };
};

export const updateListingInvoicesSearchInput = ({ searchString }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_LISTING_INVOICES_SEARCH_STRING,
      payload: searchString,
    });
  };
};

export const updateListingInvoicesCurrentPage = ({ page }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_LISTING_INVOICES_CURRENT_PAGE,
      payload: page,
    });
  };
};

export const updateListingInvoicesResultsPerPage = ({ resultsPerPage }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_LISTING_INVOICES_RESULTS_PER_PAGE,
      payload: resultsPerPage,
    });
  };
};

export const updateListingInvoicesSortModel = ({ sortModel }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_LISTING_INVOICES_SORT_MODEL,
      payload: sortModel,
    });
  };
};

export const setListingInvoicesLastViewedListing = ({ id }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_LISTING_INVOICES_LAST_VIEWED_ID,
      payload: id,
    });
  };
};
