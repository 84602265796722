import FTSAxios from "../../../axios/fts.instance";

export const getPromoCodes = (page, offset, status) => {
  return () => FTSAxios.get(`admin/promo-codes/${page}/${offset}/${status}`);
};

export const getPromocode = (id) => {
  return () => FTSAxios.get(`admin/promo-code/${id}`);
};

export const setPromoCodesList = (payload) => {
  return (dispatch) => dispatch({ type: "SET_PROMOCODES_LIST", payload });
};

export const createPromocode = (data) => {
  return () => {
    return FTSAxios.post("admin/promo-codes/create", data);
  };
};

export const updatePromocode = (data) => {
  return () => {
    return FTSAxios.put("admin/promo-codes/update", data);
  };
};

export const setPromoCode = (payload) => {
  return (dispatch) => dispatch({ type: "SET_PROMO_CODE", payload });
};
