import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSideOutData } from "../../../redux/reducers/v2/sideOut";
import {
  selectAccountData,
  selectAccountDataError,
  selectIsLoadingAccountData,
} from "../../../redux/reducers/v2/account";
import { getAccountDataByIdAction } from "../../../redux/actions/v2/account";
import { SideOutEditAccountHeader } from "./SideOutEditAccountHeader";
import { SideOutEditAccountFooter } from "./SideOutEditAccountFooter";
import { EditAccountForm } from "../EditAccountForm";
import { SideOutLoadingState } from "../dist/ui/src/components/react/SideOut/Components/SideOutLoadingState";
import { SideOutErrorState } from "../dist/ui/src/components/react/SideOut/Components/SideOutErrorState";
import { getAltContactsByIdAction } from "../../../redux/actions/v2/altContacts";
// Components

export const SideOutEditAccount = () => {
  const dispatch = useDispatch();
  const accountData = useSelector(selectAccountData);
  const sideOutData = useSelector(selectSideOutData);
  const error = useSelector(selectAccountDataError);
  const isLoading = useSelector(selectIsLoadingAccountData);

  useEffect(() => {
    if (sideOutData?.id) dispatch(getAccountDataByIdAction(sideOutData.id));
    dispatch(getAltContactsByIdAction(sideOutData.id));
  }, [sideOutData]);

  if (isLoading) {
    return <SideOutLoadingState />;
  }

  if (error) {
    return (
      <SideOutErrorState
        error={error}
        onClick={() => dispatch(getAccountDataByIdAction(sideOutData.id))}
      />
    );
  }

  if (!accountData) {
    return (
      <SideOutErrorState
        error={error}
        onClick={() => dispatch(getAccountDataByIdAction(sideOutData.id))}
      />
    );
  }

  return (
    <>
      <SideOutEditAccountHeader />
      <EditAccountForm />
      <SideOutEditAccountFooter />
    </>
  );
};
