import React, { Component } from "react";
import { connect } from "react-redux";

import { Card, NavLink, NavItem, Button, Spinner } from "reactstrap";
import { toast } from "react-toastify";

import RejectedReasonReadModal from "../../../components/fts/rejected-reason-read-modal";
import FTSDataTable from "../../../components/fts/fts-data-table/FTSTable";

import { defineColumnsProofs } from "./proofs-columns";
import {
  getProofs,
  getProofsCounters,
  resendPendingProofs,
} from "../../../redux/actions/proofs";

import classnames from "classnames";
import "../../../assets/scss/pages/proofs-list.scss";
import ListingPreviewModal from "../../../components/fts/listing-preview-modal";
import Icons from "../../../components/fts/Icons";
import { listingTypesArray } from "../../../data/listing-types-options";

class SentProofs extends Component {
  state = {
    totalRecords: 0,
    pageSize: 10,
    currentPage: 1,
    pageCount: 0,
    proofStatus: null,
    proofsData: null,
    columnDefsProofs: null,
    defaultColDef: {
      sortable: true,
    },
    activeTab: null,
    showRejectReason: false,
    rejectedReason: null,
    modalType: null,
    listingPreviewModalTitle: null,
    isOpenListingPreviewModal: false,
    approvedProofsCount: 0,
    pendingProofsCount: 0,
    rejectedProofsCount: 0,
  };
  pageSizes = [10, 20, 25, 50, 100];
  tabs = [
    {
      label: "Approved",
      value: 1,
    },
    {
      label: "Rejected",
      value: 2,
    },
    {
      label: "Pending",
      value: 3,
    },
  ];

  componentDidMount() {
    this.props.sentProofsRef(this);

    if (this.props?.accountData) {
      this.setState(
        {
          activeTab: 1,
          proofStatus: 1,
          columnDefsProofs: defineColumnsProofs(
            this.collectDataForCopy,
            this.toggleRejectReasonModal,
            this.togglePreviewListing,
          ),
        },
        () => {
          this.fetchProofs();
          this.fetchProofsCounters();
        },
      );
    }

    if (this.props.listingData) {
      this.setState(
        {
          columnDefsProofs: defineColumnsProofs(
            this.collectDataForCopy,
            this.toggleRejectReasonModal,
            this.togglePreviewListing,
          ),
        },
        () => {
          this.fetchProofs();
          this.fetchProofsCounters();
        },
      );
    }
  }

  fetchProofs = () => {
    let reqQuery = {
      page: this.state.currentPage,
      pageSize: this.state.pageSize,
      adId: this.props?.listingData?.id,
      userId: this.props?.accountData?.id,
      status: this.state?.proofStatus,
    };

    if (this.gridApi) this.gridApi.showLoadingOverlay();

    this.props
      .getProofs(reqQuery)
      .then((res) => {
        this.setState(
          {
            proofsData: res.data.rows,
            columnDefsProofs: defineColumnsProofs(
              this.collectDataForCopy,
              this.toggleRejectReasonModal,
              this.togglePreviewListing,
            ),
            pageCount: Math.ceil(res.data.count / this.state.pageSize),
          },
          () => {
            if (res.data.count > 0) {
              if (this.gridApi) this.gridApi.hideOverlay();
            }
          },
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  fetchProofsCounters = () => {
    if (this.gridApi) this.gridApi.showLoadingOverlay();

    let reqQuery = {
      adId: this.props?.listingData?.id,
      userId: this.props?.accountData?.id,
    };

    this.props
      .getProofsCounters(reqQuery)
      .then((res) => {
        let counters = res.data;
        this.setState({ ...counters }, () => {
          if (
            counters.approvedProofsCount > 0 ||
            counters.pendingProofsCount > 0 ||
            counters.rejectedProofsCount > 0
          ) {
            if (this.gridApi) this.gridApi.hideOverlay();
          }
        });
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (localStorage.getItem("proofsColumnState") !== null) {
      this.gridColumnApi.setColumnState(
        JSON.parse(localStorage.getItem("proofsColumnState")),
      );
    }
  };

  saveColumnsState = () => {
    let columnState = this.gridColumnApi.getColumnState();
    localStorage.setItem("proofsColumnState", JSON.stringify(columnState));
  };

  filterSize = (type, val) => {
    this.setState(
      {
        currentPage: 1,
        pageSize: val,
      },
      () => {
        this.fetchProofs();
      },
    );
  };

  onPageChanged = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ currentPage: pageNum }, () => {
      this.fetchProofs();
    });
  };

  resetApi = () => {
    this.gridApi = null;
  };

  onRowClicked = () => {};

  collectDataForCopy = (event, call) => {
    this.setState({
      collectedDataForCopy: event.data,
      call,
    });
  };

  updateActiveTab = (tab) => {
    this.setState(
      {
        activeTab: tab,
        proofStatus: tab,
        currentPage: 1,
      },
      () => {
        this.fetchProofs();
      },
    );
  };

  toggleRejectReasonModal = ({ data }) => {
    if (data) {
      this.setState({
        actionItem: data,
        showRejectReason: !this.state.showRejectReason,
        rejectedReason: data.rejectedReason,
      });
    } else {
      this.setState({
        showRejectReason: !this.state.showRejectReason,
      });
    }
  };

  togglePreviewListing = (data) => {
    let actionItem = { ...data };

    if (actionItem.listing) {
      actionItem.listing.adType = this.getListingTypeName(data.listing.priceId);
    }

    this.setState({ actionItem }, () => {
      this.setListingPreviewModalTitle("listingProofPreview");
      this.toggleListingPreviewModal();
    });
  };

  setListingPreviewModalTitle = (modalType) => {
    this.setState({
      modalType: modalType,
    });

    if (modalType === "sendListingProof") {
      this.setState({
        listingPreviewModalTitle: "Send Listing proof",
      });
    } else if (modalType === "listingProofPreview") {
      this.setState({
        listingPreviewModalTitle: "Listing proof preview",
      });
    }
  };

  toggleListingPreviewModal = () => {
    this.setState({
      isOpenListingPreviewModal: !this.state.isOpenListingPreviewModal,
    });
  };

  getCounter = (value) => {
    if (value === 1) return this.state.approvedProofsCount;
    if (value === 2) return this.state.rejectedProofsCount;
    if (value === 3) return this.state.pendingProofsCount;
  };

  setActionItem = (newActionItem) => {
    this.setState({ actionItem: newActionItem });
  };
  resendPendingProofs = async () => {
    try {
      this.setState({ resendPendingProofsInProgress: true });
      await this.props.resendPendingProofs(this.props.accountData.id);
      toast.success("Pending proofs successfully sent ");
      this.setState({ resendPendingProofsInProgress: false });
      this.fetchProofs();
      this.fetchProofsCounters();
    } catch (e) {
      this.setState({ resendPendingProofsInProgress: false });
      console.log("ERROR: ", e);
      toast.error("Something went wrong");
    }
  };

  getListingTypeName = (value) => {
    let key = listingTypesArray?.filter(
      (type) => type.value === parseInt(value),
    );

    return key[0]?.label;
  };

  render() {
    const {
      totalRecords,
      pageSize,
      currentPage,
      pageCount,
      proofsData,
      columnDefsProofs,
      defaultColDef,
      activeTab,
      showRejectReason,
      rejectedReason,
      listingPreviewModalTitle,
      pendingProofsCount,
    } = this.state;

    const { accountData } = this.props;
    return (
      <>
        <Card>
          <div className="d-flex p-2 justify-content-between">
            <span className="fts-sent-proofs-table-title">Sent proofs</span>

            {accountData && (
              <Button
                onClick={this.resendPendingProofs}
                color={"secondary"}
                className="d-flex align-items-center justify-space-center mr-1"
                disabled={!pendingProofsCount}
              >
                {this.state.resendPendingProofsInProgress ? (
                  <Spinner size={"sm"} />
                ) : (
                  <>
                    <Icons name={"Reassign"} />
                    <span className={"ml-1"}>Resend Pending</span>
                  </>
                )}
              </Button>
            )}
          </div>
          {accountData && (
            <>
              <div className="nav proofs-nav">
                {this.tabs.map((itm, index) => {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === itm.value,
                        })}
                        onClick={() => {
                          this.updateActiveTab(itm.value);
                        }}
                      >
                        {`${itm.label} (${this.getCounter(itm.value)})`}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </div>
            </>
          )}
          <FTSDataTable
            isSearch
            pageSizes={this.pageSizes}
            totalRecords={totalRecords}
            currentPageSize={pageSize}
            pageSizePropName={"pageSize"}
            currentPage={currentPage}
            pageCount={pageCount}
            rowData={proofsData}
            onGridReadyInit={this.onGridReady}
            saveState={this.saveColumnsState}
            filterSize={this.filterSize}
            onPageChange={this.onPageChanged}
            resetApi={this.resetApi}
            onRowClicked={this.onRowClicked}
            columnDefs={columnDefsProofs}
            // onSortChanged={this.onSortChanged}
            tableName="proofs"
            {...{
              defaultColDef,
            }}
          />
          {this.state.isOpenListingPreviewModal && (
            <ListingPreviewModal
              modalTitle={listingPreviewModalTitle}
              isOpen={true}
              toggleListingPreviewModal={this.toggleListingPreviewModal}
              listingData={
                this.state.actionItem?.listing || this.props.listingData
              }
              accountData={this.state.actionItem?.user}
              actionItem={this.state.actionItem}
              originalWorkingHours={this.state.actionItem?.workingHours}
              modalType={this.state.modalType}
              fetchProofs={this.fetchProofs}
              fetchProofsCounters={this.fetchProofsCounters}
              hasUpdate={!!this.props?.listingData?.update}
            />
          )}

          <RejectedReasonReadModal
            isOpen={showRejectReason}
            toggleRejectedReasonModal={this.toggleRejectReasonModal}
            rejectedReason={rejectedReason}
            callToAction={this.props.callToAction}
            actionItem={this.state.actionItem}
          />
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
  };
};

const mapDispatchToProps = {
  getProofs,
  getProofsCounters,
  resendPendingProofs,
};

export default connect(mapStateToProps, mapDispatchToProps)(SentProofs);
