import React from "react";
import { Message } from "../../dist/ui/src/components/react/Message";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { EmptyComponent } from "../../dist/ui/src/components/react/EmptyComponent";
import { useSelector } from "react-redux";
import { selectLockedListingMessage } from "../../../../redux/reducers/v2/listing";

export const ListingLockedMessage = () => {
  const lockedListingMessage = useSelector(selectLockedListingMessage);
  if (!lockedListingMessage) return <EmptyComponent />;
  return (
    <FtsColumn size={{ xs: 12 }} mb={"lg"}>
      <Message
        type={"warning"}
        title={"Listing Being Edited"}
        subtitle={lockedListingMessage}
      />
    </FtsColumn>
  );
};
