import React, { useEffect } from "react";
import { Dropdown, DropdownToggle } from "reactstrap";
import { ChevronRight } from "react-feather";
import { useState } from "react";

import UserDropdown from "./user-dropdown";
import packageJson from "../../../../package.json";

import "../../../assets/scss/components/fts/user-info.scss";

const UserInfo = (props) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const { sidebarState } = props;
  const toggle = () => {
    setIsDropdownOpened(!isDropdownOpened);
  };

  useEffect(() => {
    if (sidebarState && isDropdownOpened) {
      setIsDropdownOpened(false);
    }
  }, [sidebarState, isDropdownOpened]);

  return (
    <Dropdown
      isOpen={isDropdownOpened}
      toggle={toggle}
      className="dropdown-user nav-item"
    >
      <DropdownToggle tag="a" className="nav-link dropdown-user-link">
        <div className="user-info-container no-select">
          <div className="main-user-info">
            <div data-tour="user" className="user-avatar">
              <div className="inner-circle">
                {
                  props.userFullname.login.values.loggedInUser.firstName.toString()[0]
                }
                {
                  props.userFullname.login.values.loggedInUser.lastName.toString()[0]
                }
              </div>
            </div>
            {!sidebarState || !props.collapsed ? (
              <div className="user-text-content">
                <span className="full-name">
                  {props.userFullname.login.values.loggedInUser.firstName}{" "}
                  {props.userFullname.login.values.loggedInUser.lastName}
                </span>
                <span className="role-text">v{packageJson.version}</span>
              </div>
            ) : null}
          </div>
          <ChevronRight
            className={`menu-toggle-icon ${
              isDropdownOpened ? "opened" : "closed"
            }`}
            size={15}
          />
        </div>
      </DropdownToggle>

      <UserDropdown isOpen={isDropdownOpened} toggle={toggle} {...props} />
    </Dropdown>
  );
};

export default UserInfo;
