import React from "react";
import "../../../assets/scss/components/fts/filter-chip.scss";

const FilterChip = ({ color, onClickChip, active, chipLabel, chipNumber }) => {
  return (
    <div
      className="fts-filter-chip"
      style={{
        border: !active ? "1px solid #cbd1d3" : `1px solid ${color}`,
        backgroundColor: !active ? "" : color,
        color: !active ? "#4B5457" : "#fff",
      }}
      onClick={() => {
        onClickChip({ color, onClickChip, active, chipLabel, chipNumber });
      }}
    >
      <span>
        {chipLabel} ({chipNumber})
      </span>
    </div>
  );
};
export default FilterChip;
