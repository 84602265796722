import React from "react";

const TextError = (props) => {
  return (
    <div style={{ color: "red", fontSize: "12px", marginBottom: "15px" }}>
      {props.children}
    </div>
  );
};

export default TextError;
