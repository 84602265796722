import React from "react";
import FileItem from "./FileItem";
import uuid from "react-uuid";

const FileList = ({
  list,
  onRemove,
  taskManagement,
  removeFileFromListHandler,
  arrayName,
  hideRemoveBtn,
}) => {
  if (!list?.length) return null;

  if (list?.length && typeof list[0] === "object") {
    if (list[0]?.id) {
      // eslint-disable-next-line no-prototype-builtins
      if (list[0].hasOwnProperty("adminOwnerId")) {
        return list.map((file) => (
          <FileItem
            key={uuid()}
            file={file}
            isUploadedFile
            arrayName={arrayName}
            taskManagement={taskManagement}
            removeFileHandler={removeFileFromListHandler}
            hideRemoveBtn={hideRemoveBtn}
            {...{ onRemove }}
          />
        ));
      } else {
        return list.map((file) => (
          <FileItem
            key={uuid()}
            file={file}
            isLocalFile
            arrayName={arrayName}
            isUppyFile
            hideRemoveBtn={hideRemoveBtn}
            {...{ onRemove }}
          />
        ));
      }
    } else {
      return list.map((file) => (
        <FileItem
          key={uuid()}
          file={file}
          isLocalFile
          arrayName={arrayName}
          hideRemoveBtn={hideRemoveBtn}
          {...{ onRemove }}
        />
      ));
    }
  }

  if (list?.length && typeof list[0] === "string") {
    return list.map((url) => (
      <FileItem
        key={url}
        url={url}
        hideRemoveBtn={hideRemoveBtn}
        arrayName
        {...{ onRemove }}
      />
    ));
  }

  return null;
};

export default FileList;
