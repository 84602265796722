import React from "react";
// import { Row, Col } from "reactstrap";
// import queryString from "query-string";

// import Breadcrumbs from "../../../../components/@vuexy/breadCrumbs/BreadCrumb";
// import PromotionsViewConfig from "./PromotionsListConfig";
import Promotions from "../../../pages/listing-settings/Promotions";

class PromotionsView extends React.Component {
  render() {
    return <Promotions />;
  }
}

export default PromotionsView;

//////////////////////////////
// class PromotionsView extends React.Component {
//   render() {
//     return (
//       <React.Fragment>
//         <Breadcrumbs
//           breadCrumbTitle="Promotions"
//           breadCrumbActive="All Promotions"
//         />
//         <Row>
//           <Col sm="12">
//             <PromotionsViewConfig
//               parsedFilter={queryString.parse(this.props.location.search)}
//             />
//           </Col>
//         </Row>
//       </React.Fragment>
//     );
//   }
// }

// export default PromotionsView;
