import React from "react";
import * as Icons from "./index";
import { EmptyComponent } from "../components/react/EmptyComponent";
const FtsIcons = (props) => {
    if (!props.iconName)
        return React.createElement(EmptyComponent, null);
    const Component = Icons[props.iconName];
    if (!Component)
        return React.createElement(EmptyComponent, null);
    return React.createElement(Component, { ...props });
};
export default FtsIcons;
