import {
  OPEN_EDIT_LISTING,
  CLOSE_SIDE_OUT,
  OPEN_EDIT_ACCOUNT,
  CLOSE_SUB_SIDE_OUT,
  OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT,
} from "../../../reducers/v2/sideOut";

export const openEditListingSideOutAction = (payload) => ({
  type: OPEN_EDIT_LISTING,
  payload,
});
export const openEditAccountSideOutAction = (payload) => ({
  type: OPEN_EDIT_ACCOUNT,
  payload,
});
export const openEditAccountSubSideOutAction = (payload) => ({
  type: OPEN_SUB_SIDE_OUT_EDIT_ACCOUNT,
  payload,
});

export const closeSideOutAction = () => ({
  type: CLOSE_SIDE_OUT,
});

export const closeSubSideOutAction = () => ({
  type: CLOSE_SUB_SIDE_OUT,
});
