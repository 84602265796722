import { Field } from "formik";
import React, { Component } from "react";

import ActivationPicker from "../../../components/fts/activation-picker";
import ConfirmationModal from "../confirmation-modal";

class FormikConfirmActivation extends Component {
  state = {
    isOpen: false,
  };

  nextValue = null;
  previousValue = null;

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.isOpen !== this.state.isOpen ||
      nextProps.isDisabled !== this.props.isDisabled
    );
  }

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onModalClosed = () => {
    this.nextValue = null;
    this.previousValue = null;
  };

  render() {
    const {
      setFieldError,
      setFieldTouched,
      isDisabled,
      label = "Status",
      required,
      name,
      account,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <Field name={name}>
        {({ meta, form, field }) => (
          <>
            <ConfirmationModal
              title="Confirmation"
              message="Are you sure you want to proceed with this action?"
              onClose={this.onModalClosed}
              closeModal={this.toggleModal}
              confirmModal={() => {
                this.toggleModal();
                if (this.nextValue) {
                  setFieldError(name, undefined);
                }
                form.setFieldValue(name, this.nextValue);
                setTimeout(() => {
                  setFieldTouched(name, true, true);
                }, 0);
                form.setFieldValue(name, this.nextValue);
              }}
              {...{ isOpen }}
            />

            <ActivationPicker
              name={name}
              label={label}
              isClearable
              placeholder="Select status..."
              account={account}
              onChange={(option) => {
                this.toggleModal();
                this.nextValue = option;
                this.previousValue = field.value;
              }}
              value={field.value}
              {...{ field, meta, isDisabled, required }}
            />
          </>
        )}
      </Field>
    );
  }
}

export default FormikConfirmActivation;
