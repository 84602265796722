/* eslint-disable indent */
import { createSelector } from "reselect";

export const login = (state = { permissions: [] }, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_JWT": {
      return {
        ...state,
        values: action.payload,
        permissions: action.payload.loggedInUser.permissions,
      };
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload };
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload };
    }
    case "CHANGE_ROLE": {
      return { ...state, permissions: action.permissions };
    }
    default: {
      return state;
    }
  }
};

// const selectAuthState = (state) => state.auth;
const selectAuthLoginState = (state) => state.auth.login;

export const selectLoggedUser = createSelector(
  selectAuthLoginState,
  (loginState) => {
    return loginState.values.loggedInUser;
  },
);
