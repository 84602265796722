import axios from "axios";

export const getApprovedIPs = () => {
  return async (dispatch) => {
    await axios
      .get("admin/lists/approvedIPs")
      .then(({ data }) => {
        dispatch({
          type: "GET_APPROVED_IPS",
          data,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getApprovedIPInfo = (addressId) => {
  return () => {
    return axios.get("admin/lists/approvedIPs", { params: { id: addressId } });
  };
};

export const filterApprovedIPs = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_APPROVED_IP_DATA", value });
};

export const deleteApprovedIP = (payload) => {
  return () => {
    return axios.delete("admin/lists/approvedIPs", {
      params: { id: payload.id },
    });
  };
};

export const updateApprovedIP = (payload) => {
  return () => {
    return axios.put("admin/lists/approvedIPs", payload, {
      params: { id: payload.id },
    });
  };
};

export const createApprovedIP = (payload) => {
  return () => {
    return axios.post("admin/lists/approvedIPs/create", payload);
  };
};
