import FTSAxios from "../../../axios/fts.instance";
import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";

export const getFilteredPromotions = (params) => {
  let { page, resultsPerPage, searchString, sort, type, listingId, userId } =
    params;
  let multi_match = [];
  let filter = [{ term: { type } }];
  if (listingId) {
    filter.push({ term: { adId: listingId } });
  }
  if (userId) {
    filter.push({ term: { ownerId: userId } });
  }
  if (searchString) {
    multi_match.push({
      query_string: {
        query: rfc3986EncodeURIComponent(searchString),
        fields: [
          "title",
          "idForSearch",
          "account.idForSearch",
          "account.companyName",
          "account.city",
          "account.state",
          "account.address",
          "listing.idForSearch",
          "listing.companyName",
          "listing.city",
          "listing.state",
          "listing.address",
        ],

        type: "phrase_prefix",
        default_operator: "AND",
      },
    });
  }

  return () =>
    FTSAxios.post("admin/promotions/search", {
      page: page || 1,
      pageSize: resultsPerPage,
      multi_match,
      filter,
      sort,
    });
  // return () => FTSAxios.get("admin/promotion/filter", { params });
};

export const createPromotion = (payload) => {
  return () => FTSAxios.post("admin/promotion/create", payload);
};

export const getPromotionById = (id) => {
  return () => FTSAxios.get(`admin/promotion/get/${id}`);
};

export const updatePromotion = (payload) => {
  return () => FTSAxios.put(`admin/promotion/${payload.id}`, payload);
};

export const setAccountPromotions = (payload) => {
  return (dispatch) => dispatch({ type: "SET_ACCOUNT_PROMOTIONS", payload });
};

export const setNationalPromotions = (payload) => {
  return (dispatch) => dispatch({ type: "SET_NATIONAL_PROMOTIONS", payload });
};

export const setListingPromotions = (payload) => {
  return (dispatch) => dispatch({ type: "SET_LISTING_PROMOTIONS", payload });
};

export const setGeneralPromotions = (payload) => {
  return (dispatch) => dispatch({ type: "SET_GENERAL_PROMOTIONS", payload });
};

export const setInProgressPromotions = (payload) => {
  return (dispatch) => dispatch({ type: "SET_IN_PROGRESS", payload });
};

export const duplicatePromotion = (data) => {
  return () => {
    return FTSAxios.post("admin/promotion/bulk-create", data);
  };
};

export const createPromotionAsset = (data) => {
  return () => {
    return FTSAxios.post("admin/promotion/assets/create", data);
  };
};
