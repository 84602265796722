import React, { Component } from "react";

import { connect } from "react-redux";

import FTSModal from "../fts-modal";
import SendPaymentLink from "../send-payment-link";
import CloseButton from "../CloseButton";

import { activities } from "../../../constants";
import { createActivity } from "../../../redux/actions/activities";

import { Button, Spinner } from "reactstrap";
import { ChevronDown } from "react-feather";

import "../../../assets/scss/components/fts/listing-preview-modal.scss";
import classnames from "classnames";
import Icon from "../Icons";
import ListingItem from "../listing-item";
import {
  sendProof,
  updateProof,
  getProofById,
} from "../../../redux/actions/proofs";
import { toast } from "react-toastify";
import ListingProofSentSuccessfully from "../listing-proof-sent-succesfully";
import PermissionToApproveModal from "../permission-to-approve-modal";
import FTSBadge from "../fts-badge";
import ProofRevisedModal from "../proof-revised-modal";
import { addUserActivity } from "../../../redux/actions/user-activities";

class ListingPreviewModal extends Component {
  state = {
    isSendProofOpen: false,
    proofRevised: false,
    reviseInProgress: false,
    isOpenListingProofSentSuccessfully: false,
    isOpenPermissionToApproveModal: false,
    proofData: null,
  };

  sendReportRef = React.createRef();

  componentDidMount() {
    const proofToLoad = this.props.proofToLoad;
    console.log("PROPS LD", this.props.companyName);
    if (proofToLoad) {
      this.props
        .getProofById(proofToLoad)
        .then(({ data }) => {
          this.setState({
            proofData: data.proof,
          });
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }

  closeModal = () => {
    this.props.toggleListingPreviewModal();
    this.setState({
      isSendProofOpen: false,
    });
  };

  confirmModal = () => {
    this.props.toggleListingPreviewModal();
  };

  handleListingProofSentSuccessfully = () => {
    this.setState({
      isOpenListingProofSentSuccessfully:
        !this.state.isOpenListingProofSentSuccessfully,
    });
  };

  handleProofRevisedModal = () => {
    this.setState({
      isOpenProofRevisedModal: !this.state.isOpenProofRevisedModal,
    });
  };

  handleSendProofContext = () => {
    this.setState({
      isSendProofOpen: !this.state.isSendProofOpen,
    });
  };

  onProofRevisedCheck = () => {
    this.setState({ reviseInProgress: true });

    const payload = {
      proofId: this.props.actionItem?.id || this.state.proofData?.id,
      data: {
        status: 3,
        sendBy: `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
        lastUpdatedBy: `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
      },
    };

    this.props
      .updateProof(payload)
      .then(() => {
        this.setState({
          proofRevised: !this.state.proofRevised,
          reviseInProgress: false,
        });
        this.handleProofRevisedModal();

        const activityPayload = {
          status: 1,
          proofId: payload.proofId,
          userId:
            this.props.listingData.userId || this.props.actionItem?.userId,
          adId: this.props.listingData.id || this.props.actionItem?.adId,
          activityType: this.props.activitiesTypes.PROOF_REVISED,
          activity: activities.proofRevised
            .replace(
              "{{admin_name}}",
              `${this.props.userData.loggedInUser.firstName} ${this.props.userData.loggedInUser.lastName}`,
            )
            .replace("{{proofId}}", payload.proofId),
          adminUserId: this.props.userData.loggedInUser.id,
          iconName: "Listings",
        };

        this.props.createActivity(activityPayload);
        this.props.fetchProofs && this.props.fetchProofs();
        this.props.fetchProofsCounters && this.props.fetchProofsCounters();
      })
      .catch((err) => {
        console.log("err = ", err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  handlePermissionToApproveModal = () => {
    this.setState({
      isOpenPermissionToApproveModal:
        !this.state.isOpenPermissionToApproveModal,
    });
  };

  render() {
    const {
      isOpen,
      title,
      onClose,
      accountData,
      listingData,
      actionItem,
      modalTitle,
      companyName,
      // inProgress,
    } = this.props;

    const { isSendProofOpen } = this.state;
    return (
      <>
        <FTSModal
          {...{ isOpen, title, onClose }}
          modalClasses={"fts-listing-proof-modal"}
          centered={true}
          size="lg"
          style={{ maxWidth: "1300px" }}
          footerCTA={() => {
            return (
              <div className="fts-listing-proof-confrimation-modal-cta">
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex">
                    <Button
                      onClick={() => {
                        const seoUrlIdentifier =
                          this.props.listingData?.seoUrlIdentifier;

                        window.open(
                          `${process.env.REACT_APP_WEBSITE_URL}/page/${seoUrlIdentifier}`,
                          "_blank",
                        );
                      }}
                      color={"secondary"}
                      className="d-flex align-items-center mr-1"
                    >
                      <Icon name={"Eye"} />
                      <span className={"ml-1"}>View Vendor Page</span>
                    </Button>
                    {this.props.modalType !== "sendListingProof" &&
                      !this.props.hasUpdate && (
                        <div className="d-flex">
                          {(actionItem?.status === 3 ||
                            this.state.proofData?.status === 3) && (
                            <Button
                              type="button"
                              color="danger"
                              className="mr-1"
                              onClick={() => {
                                this.handlePermissionToApproveModal();
                              }}
                            >
                              <div className="d-flex align-center">
                                <span className="checked">
                                  <Icon name="Checked" size="20" />
                                </span>
                                <span>Approve</span>
                              </div>
                            </Button>
                          )}

                          {(actionItem?.status === 2 ||
                            this.state.proofData?.status === 2) && (
                            <div className="d-flex align-center">
                              {!this.state.proofRevised &&
                                !this.state.reviseInProgress && (
                                  <Button
                                    type="button"
                                    color="danger"
                                    className={`proof-revised-button mr-2 ${
                                      this.state.proofRevised &&
                                      !this.state.reviseInProgress
                                        ? "proof-revised-button-checked"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      this.onProofRevisedCheck();
                                    }}
                                  >
                                    {this.state.reviseInProgress ? (
                                      <Spinner size="sm" />
                                    ) : (
                                      <div className="d-flex align-center">
                                        <span className="checked">
                                          <Icon name="Checked" size="20" />
                                        </span>
                                        <span>Proof Revised</span>
                                      </div>
                                    )}
                                  </Button>
                                )}

                              {this.state.proofRevised &&
                                !this.state.reviseInProgress && (
                                  <div className="d-flex align-items-center">
                                    <div
                                      style={{
                                        marginRight: "0.5rem",
                                      }}
                                    >
                                      The status of this proof has been changed
                                      to
                                    </div>
                                    <FTSBadge
                                      style={{
                                        backgroundColor: "#F5B053",
                                        color: "#fff",
                                      }}
                                    >
                                      Pending
                                    </FTSBadge>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      )}
                  </div>

                  <div>
                    <Button
                      color="tertiary"
                      type="button"
                      className="preview-listing-btn account-listing-header-btn mr-1"
                      onClick={() => {
                        this.closeModal();
                      }}
                    >
                      <span className="preview-listing-btn-label">Cancel</span>
                    </Button>

                    {isSendProofOpen && (
                      <div className="fts-send-listing-proof-context">
                        <SendPaymentLink
                          onRef={(ref) => (this.sendReportRef = ref)}
                          accountInfo={accountData}
                          listingData={listingData}
                          actionItem={actionItem || this.state.proofData}
                          companyName={companyName}
                          type="send-proof"
                          showIcon={false}
                          handleMenu={this.handleSendProofContext}
                        />
                      </div>
                    )}

                    <Button
                      id="todo-save-button"
                      color="primary"
                      className="ml-1"
                      onClick={() => {
                        this.handleSendProofContext();
                      }}
                    >
                      <div className="button-content-wrapper">
                        <span>Send listing proof</span>

                        <span
                          className={classnames("payment-link-button-arrow", {
                            opened: isSendProofOpen,
                            closed: !isSendProofOpen,
                          })}
                        >
                          <ChevronDown size={20} />
                        </span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            );
          }}
        >
          <div className="listing-proof-modal-body">
            <div className="listing-proof-modal-body-wrapper">
              <div className="listing-proof-modal-title">
                <h2>{modalTitle}</h2>
                <div>
                  <CloseButton onClick={this.closeModal} />
                </div>
              </div>

              <ListingItem
                data={listingData}
                workingHours={this.props.originalWorkingHours}
              />
            </div>
          </div>
        </FTSModal>

        <ListingProofSentSuccessfully
          isOpen={this.state.isOpenListingProofSentSuccessfully}
          closeModal={this.handleListingProofSentSuccessfully}
        />

        <ProofRevisedModal
          isOpen={this.state.isOpenProofRevisedModal}
          closeModal={this.handleProofRevisedModal}
        />

        <PermissionToApproveModal
          proofId={actionItem?.id || this.state.proofData?.id}
          adId={this.props.listingData?.id}
          userId={this.props.listingData?.userId}
          isOpen={this.state.isOpenPermissionToApproveModal}
          closeModal={this.handlePermissionToApproveModal}
          closePreviewModal={this.closeModal}
          fetchProofs={this.props.fetchProofs}
          fetchProofsCounters={this.props.fetchProofsCounters}
          handleListingProofSentSuccessfully={
            this.handleListingProofSentSuccessfully
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

const mapDispatchToProps = {
  sendProof,
  createActivity,
  updateProof,
  getProofById,
  addUserActivity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingPreviewModal);
