import React from "react";

import Icon from "../Icons";
import "../../../assets/scss/components/fts/admin-notification.scss";

const AdminNotification = ({ type, title, description }) => {
  const getNotificationIconName = (type) => {
    switch (type) {
      case "positive":
        return "LockGreen";
      case "negative":
        return "LockRed";
      case "warning":
        return "AlertTriangle";
      case "info":
        return "InfoIcon";
      default:
        return "AlertTriangle";
    }
  };

  return (
    <div
      className={`fts-admin-notification fts-admin-notification-${type}`}
      style={{ margin: "-8px -20px -8px -8px" }}
    >
      <div className="fts-admin-notification-content">
        <div className="fts-admin-notification-content-title">
          <Icon name={getNotificationIconName(type)} size={40} />
          <div>{title}</div>
        </div>
        <div className="fts-admin-notification-content-notification">
          {description}
        </div>
      </div>
    </div>
  );
};

export default AdminNotification;
