import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountAltContactWrapper } from "./AccountAltContactWrapper";
import { AccountAltContactContent } from "./AccountAltContactContent";
import { selectSideOutData } from "../../../../redux/reducers/v2/sideOut";
import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { getAltContactsByIdAction } from "../../../../redux/actions/v2/altContacts";
import { Message } from "@find-truck-service/ui/src/components/react/Message";
import { Skeleton } from "@find-truck-service/ui/src/components/react/Skeleton";
import {
  selectAltContactsError,
  selectAltContactsLoading,
} from "../../../../redux/reducers/v2/altContacts";

export const AccountAltContactsRow = () => {
  const dispatch = useDispatch();
  const sideOutData = useSelector(selectSideOutData);
  const error = useSelector(selectAltContactsError);
  const isLoading = useSelector(selectAltContactsLoading);

  if (isLoading) {
    return (
      <AccountAltContactWrapper>
        <FtsColumn size={{ xs: 12 }}>
          <Skeleton />
        </FtsColumn>
      </AccountAltContactWrapper>
    );
  }

  if (error) {
    return (
      <AccountAltContactWrapper>
        <FtsColumn size={{ xs: 12 }}>
          <Message
            type="error"
            title="Unable to load alt contacts"
            subtitle="Click the button to refresh"
            confirmButtonLabel="Refresh"
            onConfirm={() => dispatch(getAltContactsByIdAction(sideOutData.id))}
          />
        </FtsColumn>
      </AccountAltContactWrapper>
    );
  }

  return (
    <AccountAltContactWrapper>
      <AccountAltContactContent />
    </AccountAltContactWrapper>
  );
};
