import FTSAxios from "../../../axios/fts.instance";

export const getNewAccountsCounters = (data) => {
  return () => {
    return FTSAxios.post("admin/users/count/new-account", data);
  };
};

export const markNewAccountAsRead = ({ id }) => {
  return () => {
    return FTSAxios.put(`admin/users/read/${id}`);
  };
};
