import React from "react";

import CountBox from "../CountBox";
import { Row, Col } from "reactstrap";

class ActivityChartSection extends React.PureComponent {
  getPercentage(a, b) {
    return a - b === 0
      ? 0
      : 100 * ((a - b) / b) !== Infinity
      ? Math.round(100 * ((a - b) / b))
      : "No data";
  }

  render() {
    return (
      <Row className="mt-2 d-flex justify-content-between">
        <Col>
          <CountBox
            label="Calls"
            count={
              this.props.adminActivities.filter(
                (activity) =>
                  activity.activityType ===
                  this.props.activitiesTypes.LOGGED_CALL
              ).length
            }
            percentage={this.getPercentage(
              this.props.adminActivities.filter(
                (activity) =>
                  activity.activityType ===
                  this.props.activitiesTypes.LOGGED_CALL
              ).length,
              this.props.pastActivities.filter(
                (activity) =>
                  activity.activityType ===
                  this.props.activitiesTypes.LOGGED_CALL
              ).length
            )}
            size="md"
          />
        </Col>

        <Col>
          <CountBox
            label="Emails"
            count={
              this.props.adminActivities.filter(
                (activity) =>
                  activity.activityType ===
                  this.props.activitiesTypes.LOGGED_EMAIL //"email"
              ).length
            }
            percentage={this.getPercentage(
              this.props.adminActivities.filter(
                (activity) =>
                  activity.activityType ===
                  this.props.activitiesTypes.LOGGED_EMAIL //"email"
              ).length,
              this.props.pastActivities.filter(
                (activity) =>
                  activity.activityType ===
                  this.props.activitiesTypes.LOGGED_EMAIL //"email"
              ).length
            )}
            size="md"
          />
        </Col>
        <Col>
          <CountBox
            label="Updates"
            count={
              this.props.adminActivities.filter(
                (activity) =>
                  activity.activityType ===
                    this.props.activitiesTypes.UPDATED_LISTING ||
                  activity.activityType ===
                    this.props.activitiesTypes.UPDATED_ACCOUNT
              ).length
            }
            percentage={this.getPercentage(
              this.props.adminActivities.filter(
                (activity) =>
                  activity.activityType ===
                    this.props.activitiesTypes.UPDATED_LISTING ||
                  activity.activityType ===
                    this.props.activitiesTypes.UPDATED_ACCOUNT
              ).length,
              this.props.pastActivities.filter(
                (activity) =>
                  activity.activityType ===
                    this.props.activitiesTypes.UPDATED_LISTING ||
                  activity.activityType ===
                    this.props.activitiesTypes.UPDATED_ACCOUNT
              ).length
            )}
            size="md"
          />
        </Col>
        <Col>
          <CountBox label="New trials" count={83} percentage={1} size="md" />
        </Col>
      </Row>
    );
  }
}

export default ActivityChartSection;
