/* eslint-disable indent */
/* eslint-disable react/display-name */
import React from "react";

import { ContextMenuTrigger } from "react-contextmenu";
import { formatRawDate } from "../../../configs/dateConfig";
import FTSBadge from "../../../components/fts/fts-badge";
import Icon from "../../../components/fts/Icons";

export const defineColumnsProofs = (
  collectDataForCopy,
  toggleRejectReasonModal,
  togglePreviewListing
) => {
  let columns = [
    {
      headerName: "Status",
      field: "status",
      sortable: false,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.status}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            {getBadge({ status: params.data.status })}
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Date sent",
      field: "createdAt",
      sortable: false,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={formatRawDate(params.data.createdAt, false)}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <span>{formatRawDate(params.data.createdAt, false)}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Sent by",
      field: "sentBy",
      sortable: false,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <ContextMenuTrigger
            data={params.data.sentBy}
            collect={collectDataForCopy}
            id="contextMenuMarketing"
          >
            <span>{params.data.sentBy}</span>
          </ContextMenuTrigger>
        );
      },
    },
    {
      headerName: "Times sent",
      field: "timesSent",
      sortable: false,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={params.data.timesSent}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <span>{params.data.timesSent}</span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },

    {
      headerName: "Responded by",
      field: "lastUpdatedBy",
      sortable: false,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div>
            <ContextMenuTrigger
              data={params.data.lastUpdatedBy}
              collect={collectDataForCopy}
              id="contextMenuMarketing"
            >
              <span>{params.data.lastUpdatedBy}</span>
            </ContextMenuTrigger>
          </div>
        );
      },
    },

    {
      headerName: "",
      field: "actions",
      sortable: false,
      unSortIcon: false,
      minWidth: 150,
      flex: 1,
      getQuickFilterText: (params) => params.value,
      cellRendererFramework: (params) => {
        return (
          <div style={{ width: 100 }} className="d-flex justify-content-end">
            {params.data.status === 2 && (
              <span
                className="mr-1"
                onClick={() => {
                  toggleRejectReasonModal(params);
                }}
              >
                <Icon name="RejectReason" stroke={"none"} size={40} />
              </span>
            )}

            <span onClick={() => togglePreviewListing(params.data)}>
              <Icon name="PreviewProof" stroke={"none"} size={40} />
            </span>
          </div>
        );
      },
    },
  ];

  return columns;
};

const getBadge = ({ status }) => {
  switch (status) {
    case 1:
      return <FTSBadge color={"success"}>Approved</FTSBadge>;

    case 2:
      return <FTSBadge color={"danger"}>Rejected</FTSBadge>;

    case 3:
      return <FTSBadge color={"warning"}>Pending</FTSBadge>;

    default:
      return <FTSBadge color={"warning"}>Pending</FTSBadge>;
  }
};
