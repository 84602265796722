import React from "react";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Span } from "../../dist/ui/src/FtsText";
import { ColorsValue } from "../../dist/types/ui/colors";
import { EmptyComponent } from "../../dist/ui/src/components/react/EmptyComponent";
import { VariantsValues } from "@find-truck-service/types/ui/text";

export const AccountAltContactEmpty = ({ show }) => {
  if (!show) return <EmptyComponent />;
  return (
    <FtsColumn size={{ xs: 12 }}>
      <Span
        variant={VariantsValues["text-2xs"]}
        color={ColorsValue["greyscale-800"]}
      >
        There are no alt contacts added yet
      </Span>
    </FtsColumn>
  );
};
