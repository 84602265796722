import React, { Component } from "react";
import ActivityHistory from "../listing-settings/ActivityHistory";

class Activities extends Component {
  render() {
    return (
      <div className={"nav-vertical listing-setting-wrapper"}>
        <ActivityHistory globalActivityPage />
      </div>
    );
  }
}

export default Activities;
