export const SET_ACCOUNT_LISTINGS = "SET_ACCOUNT_LISTINGS";
export const SET_ACCOUNT_LISTINGS_ACTIVE_SUBTAB =
  "SET_ACCOUNT_LISTINGS_ACTIVE_SUBTAB";
export const SET_ACCOUNT_LISTINGS_IN_PROGRESS =
  "SET_ACCOUNT_LISTINGS_IN_PROGRESS";
export const SET_DEFAULT_ACCOUNT_LISTINGS = "SET_DEFAULT_ACCOUNT_LISTINGS";
export const SET_ACCOUNT_LISTINGS_CURRENT_PAGE =
  "SET_ACCOUNT_LISTINGS_CURRENT_PAGE";
export const SET_ACCOUNT_LISTINGS_SORT_MODEL =
  "SET_ACCOUNT_LISTINGS_SORT_MODEL";
export const SET_ACCOUNT_LISTINGS_RESULTS_PER_PAGE =
  "SET_ACCOUNT_LISTINGS_RESULTS_PER_PAGE";
export const SET_ACCOUNT_LISTINGS_COUNTERS = "SET_ACCOUNT_LISTINGS_COUNTERS";
export const SET_ACCOUNT_LISTINGS_SEARCH_STRING =
  "SET_ACCOUNT_LISTINGS_SEARCH_STRING";

export const SET_ACCOUNT_LISTINGS_LAST_VIEWED_ID =
  "SET_ACCOUNT_LISTINGS_LAST_VIEWED_ID";

import { rfc3986EncodeURIComponent } from "../../../utility/encode-rfc";
import { generateFilters } from "../../../utility/serialize";
import FTSAxios from "../../../axios/fts.instance";

export const updateLastViewedAccount = ({ id }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_LISTINGS_LAST_VIEWED_ID,
      payload: id,
    });
  };
};

export const getAccountListings = ({
  searchString,
  page,
  resultsPerPage,
  sort,
  range,
  ...rest
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let adsServiceAmenitiesStrings = rest.adsServiceAmenitiesStrings;
      let adsSubCategoriesStrings = rest.adsSubCategoriesStrings;
      if (rest.serviceAmenities && rest.serviceAmenities.length) {
        adsServiceAmenitiesStrings = rest.serviceAmenities.map((e) => e.name);
      }
      if (rest.subCategory) {
        adsSubCategoriesStrings = rest.subCategory;
      }
      const newFilters = {
        ...rest,
        adsServiceAmenitiesStrings,
        adsSubCategoriesStrings,
        name: rest.name,
        includeInAuthorizedPromotions: rest.includeInAuthorizedPromotions
          ? 1
          : "",
        includeInFeaturedVendors: rest.includeInFeaturedVendors ? 1 : "",
        isPrivate: rest.isPrivate || "",
      };

      let { multi_match, filter } = generateFilters(newFilters);

      filter = filter.filter((e) => !!e);
      if (range) filter.push({ range });
      let newSearchString = searchString && searchString.trim();

      if (newSearchString) {
        const slugRegex = new RegExp("^[0-9]*$");
        if (slugRegex.test(newSearchString))
          newSearchString = "#" + newSearchString;

        multi_match.push({
          query_string: {
            query:
              "(" +
              rfc3986EncodeURIComponent(newSearchString.trim())
                .replace(/%40/g, "@")
                .replace(/\+/g, " AND ") +
              ") OR (" +
              newSearchString.replace(/ /g, " AND ") +
              ")",
            fields: [
              "statusString",
              "idString",
              "userIdString",
              "paymentStatus",
              "updatedAtString",
              "renewalTimestampString",
              "email",
              "name",
              "address",
              "city",
              "state",
              "phoneNumber",
              "phoneNumberSecondary",
              "afterHours",
            ],

            type: "phrase_prefix",
          },
        });
      }

      dispatch({
        type: SET_ACCOUNT_LISTINGS_IN_PROGRESS,
        payload: true,
      });

      FTSAxios.post("admin/listings/search", {
        page: page || 1,
        pageSize: resultsPerPage,
        multi_match,
        filter,
        sort,
      })
        .then(({ data }) => {
          resolve(data);
          dispatch({
            type: SET_ACCOUNT_LISTINGS,
            payload: data?.result,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const updateActiveSubTab = ({ priceId }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_LISTINGS_ACTIVE_SUBTAB,
      payload: priceId,
    });
  };
};

export const updateCurrentPage = ({ page }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_LISTINGS_CURRENT_PAGE,
      payload: page,
    });
  };
};

export const updateAccountListingsSortModel = ({ sortModel }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_LISTINGS_SORT_MODEL,
      payload: sortModel,
    });
  };
};

export const updateAccountListingsResultsPerPage = ({ resultsPerPage }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_LISTINGS_RESULTS_PER_PAGE,
      payload: resultsPerPage,
    });
  };
};
export const updateAccountListingsSearchInput = ({ searchString }) => {
  return (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_LISTINGS_SEARCH_STRING,
      payload: searchString,
    });
  };
};

export const countAccountListings = ({ searchString, range, ...rest }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const newFilters = {
        ...rest,
        name: rest.name,
        includeInAuthorizedPromotions: rest.includeInAuthorizedPromotions
          ? 1
          : "",
        includeInFeaturedVendors: rest.includeInFeaturedVendors ? 1 : "",
        isPrivate: rest.isPrivate || "",
      };

      let { multi_match, filter } = generateFilters(newFilters);

      filter = filter.filter((e) => !!e);
      if (range) filter.push({ range });
      let newSearchString = searchString && searchString.trim();

      if (newSearchString) {
        const slugRegex = new RegExp("^[0-9]*$");
        if (slugRegex.test(newSearchString))
          newSearchString = "#" + newSearchString;

        multi_match.push({
          query_string: {
            query:
              "(" +
              rfc3986EncodeURIComponent(newSearchString.trim())
                .replace(/%40/g, "@")
                .replace(/\+/g, " AND ") +
              ") OR (" +
              newSearchString.replace(/ /g, " AND ") +
              ")",
            fields: [
              "statusString",
              "idString",
              "userIdString",
              "paymentStatus",
              "updatedAtString",
              "renewalTimestampString",
              "email",
              "name",
              "address",
              "city",
              "state",
              "phoneNumber",
              "phoneNumberSecondary",
              "afterHours",
            ],

            type: "phrase_prefix",
          },
        });
      }

      FTSAxios.post("admin/listings/search/count", {
        multi_match,
        filter,
      })
        .then(({ data }) => {
          resolve(data);
          dispatch({
            type: SET_ACCOUNT_LISTINGS_COUNTERS,
            payload: data?.result,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
