import React from "react";

import "../../../assets/scss/components/fts/dropdown.scss";

const DropdownItem = ({
  id,
  disabled,
  componentLeft,
  componentRight,
  text,
  action,
  red,
  check,
}) => {
  return (
    <div
      key={id}
      className={`dropdown-item ${red ? "dropdown-red" : ""} ${
        disabled ? "disabled" : ""
      }`}
      onClick={(e) => {
        if (action) {
          e.stopPropagation();
          action();
        }
      }}
    >
      <div>
        <div
          className={`${
            !red
              ? disabled
                ? "dropdown-disabled-icons"
                : "dropdown-icons"
              : ""
          }`}
        >
          {componentLeft}
        </div>
        <div>{text}</div>
      </div>
      <div
        className={`component-right ${
          check
            ? "dropdown-check"
            : disabled
            ? "dropdown-disabled-icons"
            : "dropdown-icons"
        }`}
      >
        {componentRight}
      </div>
    </div>
  );
};

export default DropdownItem;
