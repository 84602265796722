import React from "react";

import { Button } from "reactstrap";
import FTSModal from "../fts-modal";
import CloseButton from "../CloseButton";
import Icon from "../Icons";

import "../../../assets/scss/components/fts/reassign-modal.scss";

class GenerateReportSuccess extends React.Component {
  render() {
    const { isOpen, closeModal, confirmModal, onClose } = this.props;

    return (
      <FTSModal
        {...{ isOpen, closeModal, onClose }}
        modalClasses={"fts-reassign-modal"}
        centered={true}
        style={{ maxWidth: "665px" }}
        footerCTA={() => {
          return (
            <div className="reassign-modal-footer">
              <Button onClick={confirmModal} type="button" color="primary">
                OK
              </Button>
            </div>
          );
        }}
      >
        <div className="reassign-modal-body">
          <span className="reassign-modal-body-wrapper">
            <span className="reassign-modal-title">
              <div className="d-flex update-listing-confirmation-title">
                <Icon name="ListingSuccessful" size={56} />
                <div>
                  <span className="d-flex align-center">
                    <h3>Your report has been generated.</h3>
                  </span>

                  <span>
                    Please go to Generated Reports to view and send this report.
                  </span>
                </div>
              </div>

              <CloseButton onClick={this.props.closeModal} />
            </span>
          </span>
        </div>
      </FTSModal>
    );
  }
}

export default GenerateReportSuccess;
