import React from "react";
import { FtsWrapper } from "../dist/ui/src/FtsWrapper/FtsWrapper";
import { AccountInfoBannerRow } from "./Components/AccountInfoBannerRow";
import { AccountCompanyRow } from "./Components/AccountCompanyRow";
import { AccountContactRow } from "./Components/AccountContactRow";
import { AccountSocialRow } from "./Components/AccountSocialRow";
import { AccountCategoriesPickerRow } from "./Components/AccountCategoriesPickerRow";
import { AccountDetailsRow } from "./Components/AccountDetailsRow";
import AccountNoteRow from "./Components/AccountNoteRow";
import { AccountTogglesRow } from "./Components/AccountTogglesRow";
import { AccountAltContactsRow } from "./Components/AccountAltContactsRow";

export const EditAccountForm = () => {
  return (
    <FtsWrapper py={"2xl"} px={"3xl"}>
      <AccountInfoBannerRow />
      <AccountTogglesRow />
      <AccountDetailsRow />
      <AccountCompanyRow />
      <AccountContactRow />
      <AccountAltContactsRow />
      <AccountSocialRow />
      <AccountCategoriesPickerRow />
      <AccountNoteRow />
    </FtsWrapper>
  );
};
