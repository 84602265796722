import React, { PureComponent } from "react";

import CountBox from "../CountBox";
import BorderedCountBox from "../BorderedCountBox";
import { Row, Col } from "reactstrap";

class NewBusinessSection extends PureComponent {
  render() {
    const {
      iconName,
      newPremiumCount,
      newBasicPlusCount,
      newStandardCount,
      newBasicCount,
      total,
    } = this.props;
    return (
      <div className="mt-2">
        <CountBox
          label="New listings"
          iconName={iconName}
          count={total}
          size="sm"
        />

        <hr />

        <Row>
          <Col>
            {newPremiumCount && (
              <BorderedCountBox
                label="Premium"
                count={newPremiumCount}
                color="#F36565"
              />
            )}
          </Col>
          <Col>
            {newBasicPlusCount && (
              <BorderedCountBox
                label="Basic plus"
                count={newBasicPlusCount}
                color="#F5B053"
              />
            )}
          </Col>
          <Col>
            {newStandardCount && (
              <BorderedCountBox
                label="Standard"
                count={newStandardCount}
                color="#47A7F2"
              />
            )}
          </Col>
          <Col>
            {newBasicCount && (
              <BorderedCountBox
                label="Basic"
                count={newBasicCount}
                color="#CBD1D3"
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewBusinessSection;
