import React, { useCallback } from "react";
import moment from "moment/moment";
import { AccountInfoItem } from "./AccountInfoItem";
import { Spacing } from "../../dist/types/ui/spacing";
import { P } from "@find-truck-service/ui/src/FtsText";
import { useDispatch, useSelector } from "react-redux";
import { ColorsValue } from "../../dist/types/ui/colors";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "../../dist/ui/src/FtsWrapper/FtsWrapper";
import { selectAccountData } from "../../../../redux/reducers/v2/account";
import { selectAdminUsers } from "../../../../redux/reducers/essentialLists";
import { Dropdown } from "../../dist/ui/src/components/react/Dropdown";
import { setAccountDataAction } from "../../../../redux/actions/v2/account";
import { VariantsValues } from "@find-truck-service/types/ui/text";

export const AccountInfoBannerRow = () => {
  const dispatch = useDispatch();
  const accountData = useSelector(selectAccountData);
  const adminUsers = useSelector(selectAdminUsers);
  const isValidDate = moment(accountData.adminLastContactDate).isValid();
  const isInLastYear = moment(accountData.adminLastContactDate).isAfter(
    moment().subtract(1, "year"),
  );

  const createdBy = accountData.adminAccountCreatedBy
    ? adminUsers.find(
        (user) => user.value === accountData.adminAccountCreatedBy,
      )?.label
    : `${accountData.firstName} ${accountData.lastName}`;

  const dateValue = isValidDate
    ? moment(accountData.adminLastContactDate).format("lll")
    : "No date";

  const dateColor = isValidDate
    ? isInLastYear
      ? ColorsValue["greyscale-500"]
      : ColorsValue["red-brand-500"]
    : ColorsValue["greyscale-300"];

  const onAccountOwnerChange = useCallback(
    (option) => {
      dispatch(
        setAccountDataAction({
          ...accountData,
          adminAccountOwner: option?.value || 0,
          adminUserIds: [option?.value || 0],
        }),
      );
    },
    [accountData],
  );

  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsWrapper
        border={`${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]};`}
        borderRadius={"2xs"}
      >
        <FtsRow p={"lg"} borderRadius={"2xs"} columnGap={"lg"} rowGap={"lg"}>
          <FtsColumn size={{ xs: 3 }}>
            <P
              mb={"xs"}
              variant={VariantsValues["text-3xs"]}
              color={ColorsValue["greyscale-500"]}
            >
              Account owner"
            </P>
            <Dropdown
              size={"sm"}
              variant="text"
              isClearable={false}
              options={adminUsers}
              value={adminUsers.find(
                (e) => e.value === accountData.adminAccountOwner,
              )}
              onChange={onAccountOwnerChange}
            />
          </FtsColumn>
          <AccountInfoItem label={"Created by"} value={createdBy} />
          <AccountInfoItem
            label={"Created on"}
            value={moment(accountData.createdAt).format("lll")}
          />

          <AccountInfoItem
            label={"Last contact"}
            value={dateValue}
            valueColor={dateColor}
          />
        </FtsRow>
      </FtsWrapper>
    </FtsColumn>
  );
};
