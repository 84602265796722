/* eslint-disable indent */
import React, { Component } from "react";
import { Label } from "reactstrap";

import { pickerStyles, controlStyles } from "../picker-style";
import Select from "../Select";
import chroma from "chroma-js";
import { connect } from "react-redux";

class NoteTypesPicker extends Component {
  componentDidMount() {
    const { name, setFieldValue } = this.props;
    if (!this.props.value) {
      setFieldValue(name, this.props.types[0]);
    }
  }

  render() {
    const { label, meta, name, value, isDisabled, menuPlacement, ...rest } =
      this.props;
    let types = this.props.types;

    types = types.filter(function (obj) {
      return obj.type === "both" || obj.type === "note";
    });
    types.forEach((obj) => {
      obj.value = obj.description.toLowerCase();
      obj.label = obj.description;
    });

    const pickerStyle = {
      ...pickerStyles,
      borderWidth: 0,
      control: (styles, state) =>
        controlStyles(
          styles,
          state,
          { borderWidth: "0px", backgroundColor: "transparent" },
          { meta }
        ),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.1).css()
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? chroma.contrast(color, "white") > 2
              ? "white"
              : "black"
            : data.color,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
          },
        };
      },

      multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: color.alpha(0.1).css(),
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ":hover": {
          backgroundColor: data.color,
          color: "white",
        },
      }),
    };

    const defaultValue =
      meta && meta.initialValue
        ? types.find((itm) => itm.value === meta.initialValue)
        : null;

    let isLoading = false;

    let valueInfo = typeof value !== "object" ? defaultValue : value;

    if (!types?.length) {
      isLoading = true;
    }

    if (valueInfo && !valueInfo.value) {
      valueInfo.value = valueInfo.type;
    }

    return (
      <div className="note-type-wrapper">
        <div>
          {!!label && (
            <Label
              className={rest.isDisabled ? "disabled" : ""}
              for="activation-status"
            >
              {this.props.required && !isDisabled ? `${label} *` : label}
            </Label>
          )}
        </div>
        <div>
          <Select
            {...{ defaultValue, isLoading, label }}
            isSearchable={true}
            options={types}
            styles={pickerStyle}
            name={name}
            value={valueInfo}
            menuPlacement={menuPlacement}
            {...rest}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.essentialLists.types,
  };
};

export default connect(mapStateToProps)(NoteTypesPicker);
