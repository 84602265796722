export const addGapsToNumber = (number, gaps) => {
    let formattedNumber = "";
    let gapsIndex = 0;
    number?.split("").forEach((digit, index) => {
        formattedNumber += digit;
        if (gapsIndex < gaps.length && index + 1 === gaps[gapsIndex]) {
            if (index + 1 !== number.length)
                formattedNumber += " ";
            gapsIndex++;
        }
    });
    return formattedNumber;
};
export const cleanCardNumberInput = (event) => {
    let number = event.target.value;
    return number.replace(/[^0-9]/g, "");
};
export const formatExpirationDate = (value) => {
    return value
        .replace(/[^0-9]/g, "")
        .replace(/^([2-9])$/g, "0$1")
        .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
        .replace(/^0{1,}/g, "0")
        .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
};
export const getErrorMsg = (values, key) => {
    if (values && typeof values === "object")
        return values[key];
    return "";
};
