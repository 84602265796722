import React, { Component } from "react";
import FTSAgGridDataTable from "../../../components/fts/fts-ag-grid-data-table/FTSAgGridDataTable";
import { connect } from "react-redux";
import { defineInvoicesColumns } from "../payments/payments-columns";
import {
  searchListingPayments,
  updateListingPaymentsActiveSubTab,
  updateListingPaymentsCurrentPage,
  updateListingPaymentsSearchInput,
  updateListingPaymentsResultsPerPage,
  updateListingPaymentsSortModel,
  setListingPaymentsLastViewedListing,
} from "../../../redux/actions/listingPayments";
import "../../../index.scss";
import { INVOICES_SEARCH_FIELDS as searchFields } from "../../../utility/constants";

class ListingPayments extends Component {
  state = {
    columnDefs: [],
  };
  async componentDidMount() {
    const {
      searchListingPayments,
      listingPayments,
      adId,
      invoiceToOpen,
      setListingPaymentsLastViewedListing,
    } = this.props;

    const {
      lastViewedListing,
      searchString,
      resultsPerPage,
      sortModel,
      activeSubTab,
      currentPage,
    } = listingPayments;

    let payload = {
      page: currentPage,
      resultsPerPage,
      searchString,
      searchFields,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (lastViewedListing !== adId) {
      setListingPaymentsLastViewedListing({
        id: adId,
        searchString: invoiceToOpen ? searchString : "",
      });

      payload = {
        page: 1,
        resultsPerPage,
        searchString: invoiceToOpen ? searchString : "",
        searchFields,
        sort: [],
        filter: [
          {
            term: { adId },
          },
        ],
      };
    }

    if (lastViewedListing === adId && activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    searchListingPayments(payload).then(() => {
      this.setState({
        columnDefs: defineInvoicesColumns(
          this.props.collectDataForCopy,
          null,
          adId,
          this.props.refreshTable,
          this.props.invoiceToOpen,
        ),
      });
    });
  }

  onSubTabChange = (subTab) => {
    const {
      searchListingPayments,
      updateListingPaymentsActiveSubTab,
      listingPayments,
      adId,
    } = this.props;

    const { resultsPerPage, searchString, sortModel } = listingPayments;

    let payload = {
      page: 1,
      resultsPerPage,
      searchString,
      searchFields,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (subTab?.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: subTab?.elasticKey } }],
          },
        },
      ];
    }
    updateListingPaymentsActiveSubTab({ subTab });
    searchListingPayments(payload);
  };

  handleSearchInput = (searchString) => {
    const {
      searchListingPayments,
      updateListingPaymentsSearchInput,
      listingPayments,
      adId,
    } = this.props;

    const { resultsPerPage, activeSubTab, sortModel } = listingPayments;

    let payload = {
      page: 1,
      resultsPerPage,
      searchString,
      searchFields,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    updateListingPaymentsSearchInput({ searchString });
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      searchListingPayments(payload);
    }, 300);
  };

  onPageChange = (page) => {
    const {
      searchListingPayments,
      updateListingPaymentsCurrentPage,
      listingPayments,
      adId,
    } = this.props;

    const { searchString, resultsPerPage, activeSubTab, sortModel } =
      listingPayments;

    let payload = {
      page,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    updateListingPaymentsCurrentPage({ page });
    searchListingPayments(payload);
  };

  handleFilterSize = (resultsPerPage) => {
    const {
      searchListingPayments,
      listingPayments,
      updateListingPaymentsCurrentPage,
      updateListingPaymentsResultsPerPage,
      adId,
    } = this.props;

    const { searchString, activeSubTab, sortModel } = listingPayments;

    let payload = {
      page: 1,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    updateListingPaymentsResultsPerPage({ resultsPerPage });
    updateListingPaymentsCurrentPage({ page: 1 });
    searchListingPayments(payload);
  };

  onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    const {
      updateListingPaymentsSortModel,
      searchListingPayments,
      listingPayments,
      adId,
    } = this.props;

    const { currentPage, resultsPerPage, searchString, activeSubTab } =
      listingPayments;

    updateListingPaymentsSortModel({ sortModel });

    let payload = {
      page: currentPage,
      searchString,
      searchFields,
      resultsPerPage,
      sort: sortModel,
      filter: [
        {
          term: { adId },
        },
      ],
    };

    if (activeSubTab.elasticKey) {
      payload.statusFilter = [
        {
          bool: {
            should: [{ term: { statusForSort: activeSubTab?.elasticKey } }],
          },
        },
      ];
    }

    searchListingPayments(payload);
  };

  handleRowClasses = (params) => {
    return `row-${params?.data?.statusForSort?.toLowerCase()}`;
  };

  getNoRowsMessage = () => {
    const { listingPayments } = this.props;
    let message = "No payments found";

    if (listingPayments.searchString) message += " for this specific search";

    return message;
  };

  render() {
    const { onGridReadyInit, onRowClicked, listingPayments } = this.props;

    const {
      paymentsData,
      pageCount,
      resultsPerPage,
      subTabs,
      activeSubTab,
      currentPage,
      searchString,
      sortModel,
      inProgress,
    } = listingPayments;

    const { columnDefs } = this.state;

    return (
      <FTSAgGridDataTable
        rowData={paymentsData}
        columnDefs={columnDefs}
        isMultiTab
        subTabs={subTabs}
        tableHeader={"Payments"}
        onSortChanged={this.onSortChanged}
        onPageChange={this.onPageChange}
        onSubTabChange={this.onSubTabChange}
        pageCount={pageCount}
        currentPageSize={resultsPerPage}
        activeSubTabId={activeSubTab.id}
        handleSearchInput={this.handleSearchInput}
        onRowClicked={onRowClicked}
        handleFilterSize={this.handleFilterSize}
        currentPage={currentPage}
        searchValue={searchString}
        defaultSortModel={sortModel}
        getRowClass={this.handleRowClasses}
        onGridReadyInit={onGridReadyInit}
        inProgress={inProgress}
        noRowsMessage={this.getNoRowsMessage()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listingPayments: state.listingPayments,
  };
};

export default connect(mapStateToProps, {
  searchListingPayments,
  updateListingPaymentsActiveSubTab,
  updateListingPaymentsCurrentPage,
  updateListingPaymentsSearchInput,
  updateListingPaymentsResultsPerPage,
  updateListingPaymentsSortModel,
  setListingPaymentsLastViewedListing,
})(ListingPayments);
