import React, { createRef } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import TitleRow from "../../../components/fts/TitleRow";
import { FormGroup, Spinner, Button } from "reactstrap";
import { toast } from "react-toastify";
import { isEqual } from "lodash";

import NoteTypes from "../../../components/fts/notes/NoteTypes";
import NoteForm from "../../../components/fts/note-form/small-form";
import Note from "../../../components/fts//notes/NoteSmall";
import StickyNote from "../../../components/fts/sticky-note";
import NoteTypePicker from "../../../components/fts/notes/NoteTypePicker";
import {
  createNewNote,
  fetchNotesAccount,
  fetchNotesListing,
} from "../../../redux/actions/notes";

import { addUserNoteSticky } from "../../../redux/actions/user-notes";
import { createActivity } from "../../../redux/actions/activities";
// import { activities } from "../../../constants";
import {
  addUserNote,
  loadMoreUserNotesList,
} from "../../../redux/actions/user-notes";

import "../../../assets/scss/components/fts/user-notes.scss";
import "../../../assets/scss/pages/account-listing-shared.scss";
import { getListingCounts } from "../../../redux/actions/listings";
import { getAccountCounts } from "../../../redux/actions/users";

class Notes extends Component {
  state = {
    inProgress: false,
    noteType: null,
    type: null,
    accountNotes: [],
    listingNotes: [],
    notes: [],
    showPerPage: 10,
    pageNumber: 1,
    isEmpty: false,
  };

  formRef = createRef();

  isListing = !this.props.accountData;

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  };

  componentDidUpdate() {
    if (!this.props.isListing) {
      if (!isEqual(this.props.notes, this.state.accountNotes)) {
        this.setState({
          notes: this.props.notes,
          accountNotes: this.props.notes,
        });
      }
    } else {
      if (!isEqual(this.props.notes, this.state.listingNotes)) {
        this.setState({
          notes: this.props.notes,
          listingNotes: this.props.notes,
        });
      }
    }
  }

  updateSidebarNotesList = () => {
    this.props.sidebarChild.fetchNotes(
      this.props.state,
      this.props.setActionItem,
    );
  };

  handleShowMore = () => {
    if (this.state.inProgress) {
      return;
    }

    this.setState({ inProgress: true });

    if (this.props.isListing) {
      this.loadMoreListingNotes();
    } else {
      this.loadMoreAccountNotes();
    }
  };

  loadMoreListingNotes = () => {
    Promise.all([
      this.props.fetchNotesListing(
        this.state.pageNumber,
        this.props.listingId,
        this.state.notes.length,
      ),
    ])
      .then(([notesRes]) => {
        this.props.loadMoreUserNotesList(notesRes.data);
        this.setState({
          pageNumber: this.state.pageNumber + 1,
          isEmpty: notesRes.data.length === 0,
        });
      })
      .catch((err) => {
        console.log("err = ", err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  loadMoreAccountNotes = () => {
    Promise.all([
      this.props.fetchNotesAccount(
        this.state.pageNumber,
        this.props.accountId,
        this.state.notes.length,
      ),
    ])
      .then(([notesRes]) => {
        this.props.loadMoreUserNotesList(notesRes.data);
        this.setState({
          pageNumber: this.state.pageNumber + 1,
          isEmpty: notesRes.data.length === 0,
        });
      })
      .catch(() => {
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  onFormSubmission = (values) => {
    if (this.state.inProgress) {
      return;
    }
    const adminID = this.props.adminData.loggedInUser.id;
    if (!adminID) {
      toast.error("Failed to fetch your user data, please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    this.setState({ inProgress: true });

    let payload = {
      note: values.note,
      noteType: [values.noteType.value],
      noteTypeId: values.noteType.id,
      isSticky: values.stickyName,
      isPinned: values.isPinToAccount,
      adminOwnerId: adminID,
      adId: this.props.listingId,
      userId: this.props.userId,
    };

    if (this.props.listingId) {
      payload = {
        ...payload,
        userId: this.props.accountData.id,
        isPinned: true,
      };
    }

    this.props
      .createNewNote(payload)
      .then(({ data: { note } }) => {
        if (note.isSticky) {
          this.props.addUserNoteSticky(note);
        }
        this.props.addUserNote(note);

        let element;
        if (document.getElementsByName("notes").length) {
          element = document.getElementsByName("notes")[0];
          element.scrollTo(0, 0);
          window.scrollTo(0, 0);
        }

        if (this.props.listingId) {
          this.props.getListingCounts(this.props.listingId);
        }

        if (this.props.userId) {
          this.props.getAccountCounts(this.props.userId);
        }

        this.setState({ noteInProgress: false, inProgress: false }, () => {
          this.filter();
        });
        this.formRef.current.resetForm();

        toast.success("Note successfully created.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        console.log("err = ", err);
        toast.error("Something went wrong. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ inProgress: false });
      });
  };

  addReply = async ({ note }) => {
    if (note) {
      this.setState(
        {
          notes: this.state.notes.map((el) =>
            note.parentId === el.id
              ? { ...el, replies: [note, ...el.replies] }
              : el,
          ),
        },
        () => {
          this.updateSidebarNotesList();
        },
      );
    }
  };

  filter = () => {
    let filteredItems = this.state.notes;
    let noteType = this.state.noteType;
    let type = this.state.type;

    if (this.props.isListing) {
      this.setState({
        notes: this.state.listingNotes,
      });
    } else {
      this.setState({
        notes: this.state.accountNotes,
      });
    }

    if (noteType) {
      if (noteType === "sticky") {
        filteredItems = this.state.notes.filter(
          (note) => note.isSticky === true,
        );
      } else if (noteType === "listing") {
        if (this.isListing) {
          filteredItems = this.state.notes.filter(
            (note) => note.adId && !note.toDoId,
          );
        } else {
          filteredItems = this.state.notes.filter(
            (note) => note.adId && !note.toDoId,
          );
        }
      } else if (noteType === "todo") {
        if (this.isListing) {
          filteredItems = this.state.notes.filter(
            (note) => note.toDoId || (note.toDoId && note.adId),
          );
        } else {
          filteredItems = this.state.notes.filter((note) => note.toDoId);
        }
      }

      this.setState({
        notes: filteredItems,
      });
    }

    if (type) {
      let temp = filteredItems.filter(
        (note) => note.noteType && note.noteType[0] === type,
      );

      this.setState({
        notes: temp,
      });
    }
  };

  noteTypeChange = (type) => {
    if (type) {
      if (this.props.isListing) {
        this.setState(
          {
            notes: this.state.listingNotes,
            noteType: type.value,
          },
          () => {
            this.filter();
          },
        );
      } else {
        this.setState(
          {
            notes: this.state.accountNotes,
            noteType: type.value,
          },
          () => {
            this.filter();
          },
        );
      }
    } else {
      if (this.props.isListing) {
        this.setState(
          {
            notes: this.state.listingNotes,
            noteType: null,
          },
          () => {
            this.filter();
          },
        );
      } else {
        this.setState(
          {
            notes: this.state.accountNotes,
            noteType: null,
          },
          () => {
            this.filter();
          },
        );
      }
    }
  };

  typeChange = (type) => {
    if (type) {
      if (this.props.isListing) {
        this.setState(
          {
            notes: this.state.listingNotes,
            type: type.value,
          },
          () => {
            this.filter();
          },
        );
      } else {
        this.setState(
          {
            notes: this.state.accountNotes,
            type: type.value,
          },
          () => {
            this.filter();
          },
        );
      }
    } else {
      if (this.props.isListing) {
        this.setState(
          {
            notes: this.state.listingNotes,
            type: null,
          },
          () => {
            this.filter();
          },
        );
      } else {
        this.setState(
          {
            notes: this.state.accountNotes,
            type: null,
          },
          () => {
            this.filter();
          },
        );
      }
    }
  };

  render() {
    const { callToAction, isListing, accountId, updateAccountCounts } =
      this.props;
    const { inProgress, notes } = this.state;
    const accountData = this.props.accountData;
    const adminID = this.props.adminData.loggedInUser.id;
    const openSidebar = this.props.openSidebar;
    const title = this.props.title;
    let labelId;

    if (this.props.isListing) {
      labelId = "userNotes";
    } else {
      labelId = "listingNotes";
    }

    notes.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    // notes.sort(function (a, b) {
    //   return b.isSticky - a.isSticky;
    // });

    return (
      <div className="card user-notes-wrapper">
        <div className="user-notes-header">
          <TitleRow title={title} noMarker>
            {inProgress ? (
              <div className="pr-1">
                <Spinner size="sm" />
              </div>
            ) : (
              ""
            )}
            {!this.props.isListing && (
              <div className="notes-header-item">
                <FormGroup className="mb-0">
                  <NoteTypePicker onChange={this.noteTypeChange} />
                </FormGroup>
              </div>
            )}
            <div className="notes-header-item">
              <FormGroup className="mb-0">
                <NoteTypes hasAll={true} onChange={this.typeChange} />
              </FormGroup>
            </div>
          </TitleRow>
          <hr className="user-notes-hr" />
        </div>

        <div name="notes" className="user-notes-data user-notes-data-list">
          {notes?.length > 0 ? (
            notes.map((note) => {
              if (note.isAdmin) return null;
              if (note.isSticky) {
                return (
                  <StickyNote
                    isListing={isListing}
                    key={note.id}
                    data={note}
                    item={note}
                    isNotesList
                    openSidebar={openSidebar}
                    onReplyAdded={this.addReply}
                    {...{ adminID, accountData, callToAction }}
                  />
                );
              } else {
                return (
                  <Note
                    key={note.id}
                    item={note}
                    data={note}
                    isNotesList
                    isListing={this.props.isListing}
                    openSidebar={openSidebar}
                    onReplyAdded={this.addReply}
                    hideNoteLabel={true}
                    callToAction={this.props.callToAction}
                    accountId={accountId}
                    updateAccountCounts={updateAccountCounts}
                    {...{ adminID, accountData, callToAction }}
                  />
                );
              }
            })
          ) : (
            <span className="empty-text">
              We can’t find any item matching your search.
            </span>
          )}
          {!this.state.isEmpty && notes?.length >= 10 ? (
            <div className="show-more-wrapper">
              <Button
                color="primary"
                onClick={() => {
                  this.handleShowMore();
                }}
              >
                Show more
              </Button>
            </div>
          ) : null}
        </div>

        <hr className="mb-0 mt-0" />

        <div className="user-notes-bottom">
          <NoteForm
            labelId={labelId}
            pinToAccountName="AccountNotes"
            noSticky={this.props.isListing}
            innerRef={this.formRef}
            handleSubmit={this.onFormSubmission}
            menuPlacement="top"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notes: state.accountNotes.list,
    userData: state.auth.login.values,
    activitiesTypes: state.essentialLists.activitiesTypes,
  };
};

export default connect(mapStateToProps, {
  createNewNote,
  addUserNote,
  fetchNotesAccount,
  fetchNotesListing,
  loadMoreUserNotesList,
  addUserNoteSticky,
  getAccountCounts,
  getListingCounts,
  createActivity,
})(Notes);
