import FTSAxios from "../../../axios/fts.instance";

export const getUpdatesHistory = (data) => {
  return () => {
    return FTSAxios.get("admin/updatesHistory", { params: data });
  };
};

export const updateUpdatesHistory = (payload) => {
  return () => {
    return FTSAxios.put("admin/updatesHistory", payload);
  };
};
