import React, { PureComponent } from "react";

import "../../../../assets/scss/components/fts/count-box.scss";
import PercentageBox from "../PercentageBox";
import { UncontrolledTooltip } from "reactstrap";

class BorderedCountBox extends PureComponent {
  render() {
    const { label, count, percentage, color, tooltip } = this.props;

    let target = `PercentageBox${label}`.replace(" ", "");
    target = target.slice(0, -1);

    return (
      <div
        className="bordered-count-box"
        style={{
          borderLeft: `2px solid ${color || "black"}`,
        }}
      >
        <div className="d-flex flex-column justify-content-between align-items-start">
          <span className="label">{label}</span>
          <span className="count">
            {count > 0 ? count : <span className="empty">-</span>}
          </span>
        </div>
        {percentage && (
          <span id={target}>
            <PercentageBox percentage={percentage} />
          </span>
        )}
        {tooltip && (
          <UncontrolledTooltip
            id="percentage-tooltip"
            placement="top"
            target={target}
          >
            {tooltip}
          </UncontrolledTooltip>
        )}
      </div>
    );
  }
}

export default BorderedCountBox;
